<template>
  <form
    @submit.prevent="handleSubmit"
    class="h-auto w-full bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">New User</h2>

      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>

    <div class="flex items-start justify-between">
      <div class="w-[500px] p-5 flex items-start justify-between gap-3">
        <div class="bg-white p-5 rounded border w-full space-y-3">
          <div class="flex justify-center mb-1">
            <label for="imageUpload" class="cursor-pointer relative">
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                class="hidden"
                @change="handleImageUpload"
              />

              <div
                class="flex w-20 h-20 justify-center items-center overflow-hidden bg-gray-100 rounded-full border relative"
              >
                <img
                  v-if="imagePreview ? imagePreview : imageURL"
                  :src="
                    imageURL ? getImageUrl('users', imageURL) : imagePreview
                  "
                  alt="រូបភាព"
                  class="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                />

                <div
                  v-else
                  class="flex space-x-2 items-center justify-center w-full h-full text-gray-500 text-center font-semibold"
                >
                  <span class="text-gray-500">Photo</span>
                  <font-awesome-icon icon="fa-solid fa-camera" class=" " />
                </div>
              </div>
            </label>
          </div>
          <p v-if="fileError" class="text-red-500 text-sm">{{ fileError }}</p>

          <div
            :class="
              isUsernameValid || isAlreadyUsername
                ? 'flex items-start justify-between gap-3'
                : 'flex items-center justify-between gap-3'
            "
          >
            <label for="name" class="flex gap-1">
              <span class="text-red-500">*</span> Username</label
            >
            <div>
              <input
                required
                v-model="username"
                type="text"
                class="w-[250px]"
                placeholder="Username"
              />

              <div class="w-[250px] mt-1" v-if="isAlreadyUsername">
                <el-alert
                  title="Username already use"
                  type="error"
                  :closable="false"
                />
              </div>
              <div v-if="isUsername" class="mt-1 w-[250px]">
                <el-alert
                  title="Please enter a username"
                  type="error"
                  :closable="false"
                />
              </div>
              <div
                class="w-[250px] mt-1 text-start"
                v-if="isUsernameValid === true"
              >
                <el-alert
                  title="Username must be at least 3 characters long"
                  type="error"
                  :closable="false"
                />
              </div>
            </div>
          </div>
          <div
            :class="
              isPassowrdLenght || isPassowrdAndConfirmPassword
                ? 'flex items-start justify-between gap-3'
                : 'flex items-center justify-between gap-3'
            "
          >
            <label for="name" class="flex gap-1">
              <span class="text-red-500">*</span> Password</label
            >
            <div>
              <input
                required
                type="password"
                class="w-[250px]"
                v-model="password"
                placeholder="Password"
              />
              <div v-if="isPassowrdLenght" class="w-[250px] mt-1">
                <el-alert
                  title="Password must be at least 6"
                  type="error"
                  :closable="false"
                />
              </div>
            </div>
          </div>
          <div
            :class="
              isPassowrdLenght || isPassowrdAndConfirmPassword
                ? 'flex items-start justify-between gap-3'
                : 'flex items-center justify-between gap-3'
            "
          >
            <label for="name" class="flex gap-1">
              <span class="text-red-500">*</span> Confirm Password</label
            >
            <div>
              <input
                required
                v-model="confirmPassword"
                type="password"
                class="w-[250px]"
                placeholder="Confirm Password"
              />

              <div v-if="isPassowrdAndConfirmPassword" class="w-[250px] mt-1">
                <el-alert
                  title="Passowrd is not match"
                  type="error"
                  :closable="false"
                />
              </div>
            </div>
          </div>

          <div
            v-if="userData[0]?.role === 'Super Admin'"
            class="flex items-center justify-between gap-3 text-nowrap"
          >
            <label for="Supplier"
              ><span class="text-primary4 mr-2">*</span>Select Role</label
            >

            <div class="flex flex-col space-y-2">
              <Select
                class="w-[250px] h-10 text-start flex items-center"
                :options="roles"
                filter
                placeholder="Select Role"
                option-label="name"
                v-model="role"
                option-value="name"
                show-clear
                checkmark
                size="medium"
              />

              <Message severity="error" class="text-body2 mt-1" v-if="isRole"
                >Please Select Main Role !</Message
              >
            </div>
          </div>
          <div>
            <div class="flex items-center justify-end gap-3">
              <label for="Description">Tags</label>
              <div
                class="w-[250px] py-2 overflow-auto px-[12px] bg-none border rounded placeholder:text-primary5 outline-none flex flex-wrap items-center"
              >
                <button
                  v-for="(tag, index) in tags"
                  :key="index"
                  type="button"
                  class="ml-1 text-whitw bg-white text-primary2 border space-x-2 space-y-2 px-2 hover:bg-default"
                >
                  {{ tag }}
                  <div @click="removeTag(index)" class="text-primary5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="size-5"
                    >
                      <path
                        d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"
                      />
                    </svg>
                  </div>
                </button>

                <input
                  v-model="tag"
                  @keydown.space.prevent="addTag"
                  placeholder="Type Tag here"
                  class="outline-none border-none"
                />
              </div>
            </div>

            <h2 class="text-end py-2">Type and press space to add a tag</h2>
          </div>

          <div class="flex items-center gap-3">
            <label for="status" class="flex gap-1 text-nowrap">Status</label>
            <el-switch style="--el-switch-on-color: #4caf50" v-model="status" />
          </div>
        </div>
      </div>
      <div class="w-[500px] p-5 flex items-start justify-between gap-3">
        <div class="bg-white p-5 rounded border w-full space-y-8">
          <div class="flex items-center justify-between gap-3">
            <label for="name" class="flex gap-1">
              <span class="text-red-500"></span> Profile Name</label
            >
            <input
              required
              type="text"
              class="w-[250px]"
              placeholder="Profile Name"
              v-model="profilename"
            />
          </div>
          <div>
            <div
              :class="
                isAlreadyEmail || isUsernameValid
                  ? 'flex items-start justify-between gap-3'
                  : 'flex items-center justify-between gap-3'
              "
            >
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span> Email</label
              >
              <div>
                <input
                  required
                  v-model="email"
                  type="text"
                  class="w-[250px]"
                  placeholder="Email"
                />
                <div v-if="isAlreadyEmail" class="w-[250px] mt-1">
                  <el-alert
                    title="email is already used"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between gap-3">
            <label for="name" class="flex gap-1">
              <span class="text-red-500"></span> Phone</label
            >
            <input
              v-model="phonenumber"
              type="text"
              class="w-[250px]"
              placeholder="Phone"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center gap-3 p-5">
      <button type="submit" v-if="isAdding === true" :disabled="isAdding">
        Saving
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="white"
              stroke-dasharray="15"
              stroke-dashoffset="15"
              stroke-linecap="round"
              stroke-width="2.25"
              d="M12 3C16.9706 3 21 7.02944 21 12"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                dur="0.3s"
                values="15;0"
              />
              <animateTransform
                attributeName="transform"
                dur="1.5s"
                repeatCount="indefinite"
                type="rotate"
                values="0 12 12;360 12 12"
              />
            </path></svg
        ></span>
      </button>
      <button v-else>{{ isUpdate ? "Update" : "Save" }}</button>
      <button
        type="button"
        class="btncancel"
        @click="SaveAndNew"
        :disabled="isUpdating"
        v-if="isUpdating === true"
      >
        Saving
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#168AF4"
              stroke-dasharray="15"
              stroke-dashoffset="15"
              stroke-linecap="round"
              stroke-width="2.25"
              d="M12 3C16.9706 3 21 7.02944 21 12"
            >
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                dur="0.3s"
                values="15;0"
              />
              <animateTransform
                attributeName="transform"
                dur="1.5s"
                repeatCount="indefinite"
                type="rotate"
                values="0 12 12;360 12 12"
              />
            </path>
          </svg>
        </span>
      </button>
      <button v-else @click="SaveAndNew" type="button" class="btncancel">
        {{ isUpdate ? "Update & New" : "Save & New" }}
      </button>
      <button type="button" class="btncancel" @click="handleClose">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import SaveButton from "@/components/SaveButton.vue";
import CancelBotton from "@/components/CancelBotton.vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import { getImageUrl } from "@/composables/getImage";
import { uploadImage } from "@/composables/upload";

export default {
  props: ["datatoedit"],
  components: { SaveButton, CancelBotton },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const isUpdate = props.datatoedit;
    const isAdding = ref(false);
    const isUpdating = ref(false);
    const userData = ref([]);
    const authUserId = ref(null);
    const isUsername = ref("");

    const isConfirmPassword = ref("");
    const fetchUserByID = async (id) => {
      try {
        const tableName = ref("users");
        let url = `${apirURL}/weighing/api/getDataByID/${tableName.value}/${id}`;
        const response = await axios.get(url);

        userData.value = response.data;
        console.log("userData", userData.value);
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const originalUserName = ref(null);
    const originalEmail = ref(null);
    const handleClear = () => {
      username.value = "";
      isUsernameValid.value = false;

      email.value = "";
      password.value = "";
      confirmPassword.value = "";
      phonenumber.value = "";
      profile.value = null;
      profilename.value = "";
      imageURL.value = null;
      tags.value = []; // Clear all tags
      tag.value = "";
      role.value = null;
      isRole.value = false;
      isAlreadyUsername.value = "";
      isAlreadyEmail.value = "";
      isPassowrdLenght.value = "";
      isUsername.value = "";
      isPassowrdAndConfirmPassword.value = "";
    };

    const isPassowrdAndConfirmPassword = ref("");
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const phonenumber = ref("");
    const profile = ref(null);
    const imageURL = ref(null);
    const profilename = ref("");
    const roles = [{ name: "Super Admin" }, { name: "User" }];
    const role = ref(null);
    const status = ref(true);

    const imgURL = ref(null);
    const tags = ref([]);
    const tag = ref("");
    const isRole = ref(false);
    const addTag = () => {
      const trimmedTag = tag.value.trim();
      if (trimmedTag !== "" && !tags.value.includes(trimmedTag)) {
        tags.value.push(trimmedTag);
        tag.value = ""; // Clear the input after adding the tag
      }
    };
    const removeTag = (index) => {
      tags.value.splice(index, 1);
    };

    const imagePreview = ref(null);
    const fileError = ref(null);
    const imageFile = ref(null);
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
    const allowedExtensions = ["jpg", "jpeg", "png"];

    // Method to handle image upload and preview
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageURL.value = null;
        const extension = file.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(extension)) {
          fileError.value =
            "Invalid file extension. Only jpg, jpeg, and png are allowed.";
          imageFile.value = null;
          imagePreview.value = null;
          return;
        }

        if (file.size > maxSizeInBytes) {
          fileError.value = "File size cannot be greater than 2MB";
          imageFile.value = null;
          imagePreview.value = null;
          return;
        }

        // File is valid
        imageFile.value = file;
        fileError.value = null;
        imagePreview.value = URL.createObjectURL(file);
      } else {
        imageFile.value = null;
        imagePreview.value = null;
      }
    };

    const handleSubmit = async () => {
      try {
        if (
          isAlreadyUsername.value ||
          isAlreadyEmail.value ||
          isPassowrdLenght.value ||
          isPassowrdAndConfirmPassword.value ||
          isUsernameValid.value
        ) {
          console.log("part error validate simple user");
          return;
        } else if (!role.value && userData.value[0]?.role === "Super Admin") {
          console.log("part error validate super admin");
          isRole.value = true;
          return;
        } else {
          console.log("part submit");
          const timestamp = await fetchTimestamp();
          const requestBody = {
            tableName: "users",
            fields: {
              username: username.value,
              name_lowercase: username.value.toLowerCase(),
              password: password.value,
              email: email.value,
              profile_name: profilename.value,
              role:
                userData.value[0]?.role === "Super Admin" ? role.value : "User",
              tag_name: tags.value,
              phone_number: phonenumber.value,
              status: status.value,
            },
          };

          if (props.datatoedit) {
            requestBody.fields.updated_at = timestamp;
            requestBody.fields.updated_by = authUserId.value;
            // Editing an existing entry - Use PUT method
            if (password.value === confirmPassword.value) {
              if (imageFile.value) {
                imageURL.value = await uploadImage(imageFile.value, "users");
              }

              if (imageURL.value) {
                requestBody.fields.image_url = imageURL.value;
              }

              await axios.patch(
                `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
                requestBody
              );

              emit("toast", "update");

              handleClear();
              handleClose();
            } else {
              emit("toast", "passworderror");
            }
          } else {
            requestBody.fields.created_at = timestamp;
            requestBody.fields.created_by = authUserId.value;

            if (imageFile.value) {
              imageURL.value = await uploadImage(imageFile.value, "users");
            }
            if (imageURL.value) {
              requestBody.fields.image_url = imageURL.value;
            }
            await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);

            emit("toast", "create");
            handleClear();
            handleClose();
          }
        }

        // Clear and close after successful operation
      } catch (error) {
        console.log("Error during submission:", error);
        emit("toast", "errorcreateuser");
      }
    };

    const SaveAndNew = async () => {
      // Start the loading state
      setTimeout(() => {
        isUpdating.value = true;
        setTimeout(() => {
          isUpdating.value = false;
        }, 1000);
      }, 0);

      try {
        // Perform validation checks
        if (isUsernameValid.value) {
          console.log("Invalid username, submission prevented.");
          return;
        }

        if (
          isAlreadyUsername.value ||
          isAlreadyEmail.value ||
          isPassowrdLenght.value ||
          isPassowrdAndConfirmPassword.value
        ) {
          console.log("part error validate simple user");
          return;
        }

        if (!role.value && userData.value[0]?.role === "Super Admin") {
          console.log("part error validate super admin");
          isRole.value = true;
          return;
        }
        console.log("part submit");

        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "users",
          fields: {
            username: username.value,
            name_lowercase: username.value.toLowerCase(),
            password: password.value,
            email: email.value,
            profile_name: profilename.value,
            role:
              userData.value[0]?.role === "Super Admin" ? role.value : "User",
            tag_name: tags.value,
            phone_number: phonenumber.value,
            status: status.value,
          },
        };

        if (props.datatoedit) {
          requestBody.fields.updated_at = timestamp;
          requestBody.fields.updated_by = authUserId.value;

          // Check for password confirmation and image upload
          if (password.value === confirmPassword.value) {
            if (imageFile.value) {
              imageURL.value = await uploadImage(imageFile.value, "users");
            }

            if (imageURL.value) {
              requestBody.fields.image_url = imageURL.value;
            }

            await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              requestBody
            );

            emit("toast", "update");
            isUsernameValid.value = false;
            handleClear();
          } else {
            emit("toast", "passworderror");
          }
        } else {
          requestBody.fields.created_at = timestamp;
          requestBody.fields.created_by = authUserId.value;

          if (imageFile.value) {
            imageURL.value = await uploadImage(imageFile.value, "users");
          }
          if (imageURL.value) {
            requestBody.fields.image_url = imageURL.value;
          }
          await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);

          emit("toast", "create");
          handleClear();
          isUsernameValid.value = false;
        }
      } catch (error) {
        console.log("Error during submission:", error);
        emit("toast", "errorcreateuser");
      }
    };

    const fetchTotalData = async (name) => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "username",
              operator: "=", //use for array
              value: name, //use for nested object
              typeTable: "table",
            },
          ]),
        };

        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        return res.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    const fetchduplicateEmail = async (email) => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "email",
              operator: "=", //use for array
              value: email, //use for nested object
              typeTable: "table",
            },
          ]),
        };

        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        return res.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };
    const isUsernameValid = ref(false);
    const isAlreadyUsername = ref("");
    watch(username, async (newValue) => {
      if (newValue && newValue.length < 3) {
        isUsernameValid.value = true;
      } else {
        isUsernameValid.value = false;
      }
      if (newValue === originalUserName.value) {
        isAlreadyUsername.value = "";
      } else {
        originalUserName.value = "";
        const data = await fetchTotalData(newValue);
        if (data.length > 0) {
          isAlreadyUsername.value =
            "Username already been used by another user";
        } else {
          isAlreadyUsername.value = "";
        }
      }
    });

    const isAlreadyEmail = ref("");
    watch(email, async (newValue) => {
      if (newValue === originalEmail.value) {
        isAlreadyEmail.value = "";
      } else {
        originalEmail.value = "";
        const data = await fetchduplicateEmail(newValue);
        if (data.length > 0) {
          isAlreadyEmail.value = "Email already been used by another user";
        } else {
          isAlreadyEmail.value = "";
        }
      }
    });
    const isPassowrdLenght = ref("");
    watch(password, (newValue) => {
      if (newValue) {
        if (newValue.length < 6) {
          isPassowrdLenght.value = "Password must be 6 characters long ";
        } else {
          isPassowrdLenght.value = "";
        }
      } else {
        isPassowrdLenght.value = "";
      }
    });

    watch(confirmPassword, (newValue) => {
      if (newValue === password.value) {
        isPassowrdAndConfirmPassword.value = "";
      } else {
        isPassowrdAndConfirmPassword.value =
          "Confirm Password not match Password!";
      }
    });

    watch(role, (newValue) => {
      if (newValue) {
        isRole.value = false;
      }
    });

    const handleDatabaseUpdate = () => {
      fetchUserByID(authUserId.value);
    };

    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);

      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }

      fetchUserByID(authUserId.value);

      if (props.datatoedit) {
        username.value = props.datatoedit.username;
        phonenumber.value = props.datatoedit.phone_number;
        email.value = props.datatoedit.email;

        imageURL.value = props.datatoedit?.image_url;

        role.value = props.datatoedit.role;
        tags.value = props.datatoedit.tag_name;
        status.value = props.datatoedit.status;
        phonenumber.value = props.datatoedit.phone_number;
        profilename.value = props.datatoedit.profile_name;
        originalUserName.value = props.datatoedit.username;
        originalEmail.value = props.datatoedit.email;

        const response = await axios.post(
          `${apirURL}/weighing/api/decryptPassword`,
          {
            encryptedPassword: props.datatoedit.password,
          }
        );

        password.value = response.data.decryptedPassword;
        confirmPassword.value = response.data.decryptedPassword;

        socket.on("database_realTime", handleDatabaseUpdate);
      }
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });

    return {
      isUsernameValid,
      isRole,
      handleClose,
      username,
      phonenumber,
      email,
      password,
      handleSubmit,
      confirmPassword,
      profilename,
      imageFile,
      handleImageUpload,
      fileError,
      imagePreview,
      imageURL,
      status,
      tag,
      userData,
      roles,
      role,
      imageURL,
      getImageUrl,
      profile,
      addTag,
      fileError,
      tags,
      removeTag,
      SaveAndNew,
      isAdding,
      isUpdating,
      isUpdate,
      isAlreadyUsername,
      isAlreadyEmail,
      isPassowrdLenght,
      isUsername,
      isConfirmPassword,
      isPassowrdAndConfirmPassword,
    };
  },
};
</script>

<style></style>
