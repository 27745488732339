<template>
  <Toast ref="toast" />
  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div class="w-full flex items-center justify-between mt-3">
      <div>
        <h2>Generate Invoice Transaction</h2>
      </div>
    </div>
    <div class="flex items-center w-full gap-4">
      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody v-if="weighing_rice.length > 0">
            <tr class="text-body text-primary5">
              <td colspan="9" class="border-b bg-white sticky top-0">
                <div class="text-start flex items-center justify-between">
                  <div class="text-body flex items-center gap-3">
                    <div class="flex items-center gap-3">
                      <h2>Status</h2>
                      <el-button
                        :type="getStatus ? 'warning' : 'success'"
                        round
                        >{{ getStatus ? "Processing" : "Complete" }}</el-button
                      >
                    </div>
                    <div class="flex items-center gap-3">
                      <h2>Total Amount</h2>
                      <span class="text-primary2 font-semibold">
                        {{ numeralFormat(grandTotalSackExpense, "0,0[.]00") }}
                        ៛</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <button class="btncancel border" @click="handleAdd">
                      Preview
                    </button>
                    <button @click="handleDailog(weighing_rice)">
                      Mark Completed
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">Created At</td>
              <td
                @click="sortHistoryData('id')"
                class="border-b text-start toggle_sort min-w-[100px]"
              >
                No
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortHistoryColumns === 'id' &&
                        sortHistoryDirection === 'asc'
                          ? 'sort-up'
                          : sortHistoryColumns === 'id' &&
                            sortHistoryDirection === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortHistoryData('totalSackExpense')"
                class="border-b text-start toggle_sort min-w-[100px]"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortHistoryColumns === 'totalSackExpense' &&
                        sortHistoryDirection === 'asc'
                          ? 'sort-up'
                          : sortHistoryColumns === 'totalSackExpense' &&
                            sortHistoryDirection === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="weighing_rice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="(data, i) in weighing_rice"
              :key="data"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">
                {{ numeralFormat(totalSackExpense(data.weighing), "0,0[.]00") }}
                ៛
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="weighing_rice.length > 0"
      class="w-full flex items-center justify-between mt-8"
    >
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :totalRecord="weighing_rice.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :prevData="prevData"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    :prevData="prevData"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <el-dialog
    class="!text-start !w-[500px]"
    v-model="centerDialogVisible"
    title="Confirming Sack Expense as Completed "
  >
    <span class="leading-[6px]"> Are you sure to mark as commplete </span>
    <template #footer>
      <div class="flex gap-3 justify-start">
        <button class="btncancel" @click="centerDialogVisible = false">
          Cancel
        </button>

        <button type="button" @click="handleSubmitSaveprint">
          Save & print
        </button>
        <button type="primary" @click="handleSubmit">Save</button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import SackSlipPrewiewVue from "@/form/SackSlipPrewiew.vue";
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import moment from "moment-timezone";
import { formatDate2 } from "@/composables/formatDateTime";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import { useRouter } from "vue-router";
import socket from "@/services/socket";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
export default {
  components: {
    SackSlipPrewiewVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    Toast,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const prevData = ref(null);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "SackSlipPrewiewVue";
      prevData.value = weighing_rice.value;
    };
    const router = useRouter();
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      currentComponent.value = "GenerateLabelSlipVue";
      isPopup.value = true;
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const centerDialogVisible = ref(false);
    const handleDailog = (doc) => {
      weighing_rice.value = doc;
      centerDialogVisible.value = true;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const authUserId = ref(null);
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchWeighingHistory(), fetchUserByID()]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([fetchWeighingHistory(), fetchUserByID()]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      socket.on("database_realTime", handleDatabaseUpdate);
    });
    onUnmounted(() => {
      socket.off("database_realTime");
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      return classes;
    });

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const weighing_rice = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("supplier_invoice");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const fetchWeighingHistory = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        // columnSearch: "name, phone_number",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          // {
          //   field: "created_at",
          //   operator: ">=",
          //   value: fromDateFormatted.value,
          //   typeTable: "table",
          // },
          // {
          //   field: "created_at",
          //   operator: "<=",
          //   value: toDateFormatted.value,
          //   typeTable: "table",
          // },
          {
            field: "is_sack_slip",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          ...(fromDate && toDate
            ? [
                {
                  field: "created_at",
                  operator: ">=",
                  value: fromDate,
                },
                {
                  field: "created_at",
                  operator: "<=",
                  value: toDate,
                },
              ]
            : []),
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing_rice.value = response.data.data;
      console.log("date ", weighing_rice);
      totalPages.value = response.data.pagination.totalPages;
    };
    const handleFilter = async () => {
      await fetchWeighingHistory();
    };
    const totalSackExpense = (weighings) => {
      return weighings.reduce((total, item) => total + item.sack_expense, 0);
    };
    const grandTotalSackExpense = computed(() => {
      return weighing_rice.value.reduce((total, data) => {
        return total + totalSackExpense(data.weighing);
      }, 0);
    });

    const getStatus = computed(() => {
      return weighing_rice.value[0]?.is_sack_slip;
    });

    const handleSubmit = async () => {
      try {
        // Fetch the current timestamp
        const timestamp = await fetchTimestamp();

        // Prepare the request object
        weighing_rice.value?.forEach((data) => {
          data.weighing.forEach(async (weighingData) => {
            const req = {
              tableName: "fee_bag_invoice",
              fields: {
                supplier_invoice_id: weighing_rice.value[0]?.id, // Send supplier invoice ID
                status: "paid",
                amount_paid: weighingData.sack_expense, // Send sack expense amount as amount_paid
                weigh_type: "kilo",
                created_by: authUserId.value,
                created_at: timestamp,
                sakes: JSON.stringify([
                  {
                    name: "general",
                  },
                ]),
                weighing: JSON.stringify(
                  weighing_rice.value.map((data) => {
                    const total_sack_expense = data.weighing.reduce(
                      (total, weigh) => {
                        return total + weigh.sack_expense; // Sum up sack_expense for each weighing
                      },
                      0
                    );

                    return {
                      supplier_invoice: data.id, // Each invoice's ID
                      sack_expense: total_sack_expense, // Total sack expense for this invoice
                    };
                  })
                ),
              },
            };

            // Send a POST request for each sack expense
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              req
            );
            const update_labor = {
              tableName: "supplier_invoice",
              fields: {
                is_sack_slip: true,
              },
            };
            weighing_rice.value.forEach(async (data) => {
              try {
                if (data.id) {
                  // Send the update request for each record
                  await axios.patch(
                    `${apirURL}/weighing/api/updateData/${data.id}`,
                    update_labor
                  );
                  console.log(`Updated labor invoice for ID: ${data.id}`);
                }
              } catch (error) {
                console.error(
                  `Failed to update labor invoice for ID: ${data.id}`,
                  error
                );
              }
            });
            console.log(
              "Request for sack expense:",
              weighingData.sack_expense,
              "Response:",
              response.data
            );
            if (response.data && response.data.insertedId) {
              console.log(
                "Navigating to printsackslip with ID:",
                response.data.insertedId
              );
              showSuccessToast("create", "infor");
            } else {
              console.log(
                "Response does not contain insertedId:",
                response.data
              );
            }

            centerDialogVisible.value = false;
          });
        });
      } catch (error) {
        console.log("Error:", error);
      }
    };

    const handleSubmitSaveprint = async () => {
      try {
        // Fetch the current timestamp
        const timestamp = await fetchTimestamp();

        // Prepare the request object
        weighing_rice.value?.forEach((data) => {
          data.weighing.forEach(async (weighingData) => {
            const req = {
              tableName: "fee_bag_invoice",
              fields: {
                supplier_invoice_id: weighing_rice.value[0]?.id, // Send supplier invoice ID
                status: "paid",
                amount_paid: weighingData.sack_expense, // Send sack expense amount as amount_paid
                weigh_type: "kilo",
                created_by: authUserId.value,
                created_at: timestamp,
                sakes: JSON.stringify([
                  {
                    name: "general",
                  },
                ]),
                weighing: JSON.stringify(
                  weighing_rice.value.map((data) => {
                    const total_sack_expense = data.weighing.reduce(
                      (total, weigh) => {
                        return total + weigh.sack_expense; // Sum up sack_expense for each weighing
                      },
                      0
                    );

                    return {
                      supplier_invoice: data.id, // Each invoice's ID
                      sack_expense: total_sack_expense, // Total sack expense for this invoice
                    };
                  })
                ),
              },
            };

            // Send a POST request for each sack expense
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              req
            );
            const update_labor = {
              tableName: "supplier_invoice",
              fields: {
                is_sack_slip: true,
              },
            };
            weighing_rice.value.forEach(async (data) => {
              try {
                if (data.id) {
                  // Send the update request for each record
                  await axios.patch(
                    `${apirURL}/weighing/api/updateData/${data.id}`,
                    update_labor
                  );
                  console.log(`Updated labor invoice for ID: ${data.id}`);
                }
              } catch (error) {
                console.error(
                  `Failed to update labor invoice for ID: ${data.id}`,
                  error
                );
              }
            });
            console.log(
              "Request for sack expense:",
              weighingData.sack_expense,
              "Response:",
              response.data
            );

            // Check if response contains the expected data
            if (response.data && response.data.insertedId) {
              console.log(
                "Navigating to printsackslip with ID:",
                response.data.insertedId
              );
              router.push({
                name: "printsackslip",
                params: { id: response.data.insertedId },
              });
              showSuccessToast("create", "infor");
            } else {
              console.warn(
                "Response does not contain insertedId:",
                response.data
              );
            }

            centerDialogVisible.value = false;
          });
        });
      } catch (error) {
        console.log("Error:", error);
      }
    };
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighingHistory();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighingHistory();
    };
    const sortClient = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "totalSackExpense") {
        const sortedData = weighing_rice.value.map((data) => ({
          ...data,
          totalExpense: totalSackExpense(data.weighing),
        }));

        // Sort based on the total expense
        sortedData.sort((a, b) => {
          return sortDirection === "asc"
            ? a.totalExpense - b.totalExpense
            : b.totalExpense - a.totalExpense;
        });

        // Update the original array or state with sorted data
        // Assuming you have a method to set the sorted data
        weighing_rice.value = sortedData; // Update the reactive variable directly
      }
    };

    const sortHistoryDirection = ref("asc");
    const sortHistoryColumns = ref(null);
    const sortHistoryData = (field) => {
      const direction =
        sortHistoryColumns.value === field &&
        sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";
      sortHistoryDirection.value = direction;
      sortHistoryColumns.value = field;
      if (field === "id") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "totalSackExpense") {
        // Sort by computed totalSackExpense
        const sortedData = weighing_rice.value
          .map((data) => ({
            ...data,
            totalSackExpense: totalSackExpense(data.weighing), // Compute totalSackExpense
          }))
          .sort((a, b) => {
            return direction === "asc"
              ? a.totalSackExpense - b.totalSackExpense
              : b.totalSackExpense - a.totalSackExpense;
          });

        weighing_rice.value = sortedData.map(
          ({ totalSackExpense, ...rest }) => rest
        ); // Remove computed field
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          case "amount":
            message = "Please Enter the amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      centerDialogVisible,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      weighing_rice,
      totalSackExpense,
      formatDate2,
      prevData,
      grandTotalSackExpense,
      getStatus,
      handleSubmit,
      handleSubmitSaveprint,
      sortClient,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      handleDailog,
      sortHistoryDirection,
      sortHistoryColumns,
      sortHistoryData,
      selectDateRage,
      handleFilter,
      authUserRole,
      toast,
      showSuccessToast,
    };
  },
};
</script>

<style></style>
