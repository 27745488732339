<template>

    <transition name="toast">
        <div v-if="visible" @mouseenter="stopAutoClose" @mouseleave="resumeAutoClose"
            class="fixed  flex justify-center top-14 text-left left-1/2 transform -translate-x-1/2 px-4 py-2 rounded shadow-lg text-white cursor-pointer min-w-fit" :class="isSuccess ? 'bg-primary3' : 'bg-red-500'">
            {{ informMessage }}
        </div>
    </transition>




</template>

<script>
import { onMounted, ref, watch } from 'vue';

export default {
    props: ['informMessage', 'showToast','isSuccess'],
    setup(props, { emit }) {
        const visible = ref(false);
        let autoCloseTimeout = null;
        const initialDuration = 2000
        
        const remainingTime = ref(initialDuration);
        let startTimestamp;

        const startAutoClose = () => {
            // Clear any existing timeout before starting a new one
            if (autoCloseTimeout) clearTimeout(autoCloseTimeout);

            startTimestamp = Date.now();
            autoCloseTimeout = setTimeout(() => {
                visible.value = false;
                emit('onClose');
            }, remainingTime.value);
        };

        const stopAutoClose = () => {
            if (autoCloseTimeout) {
                clearTimeout(autoCloseTimeout);
                remainingTime.value -= Date.now() - startTimestamp;
            }
        };

        const resumeAutoClose = () => {
            startAutoClose();
        };

        watch(() => props.showToast, (newValue) => {
            if (newValue) {
                // Clear any existing timeout and reset remaining time
                if (autoCloseTimeout) clearTimeout(autoCloseTimeout);
                remainingTime.value = initialDuration;
                visible.value = true;
                startAutoClose();
            }
        });

        onMounted(() => {
            if (props.showToast) {
                remainingTime.value = initialDuration;
                visible.value = true;
                startAutoClose();
            }
        });

        return {
            visible,
            stopAutoClose,
            resumeAutoClose,
        };
    },
};
</script>

<style scoped>
.toast-enter-active,
.toast-leave-active {
    transition: all 0.3s ease-out;
}

.toast-enter-from {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
}

.toast-leave-to {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
}

.toast-enter-to,
.toast-leave-from {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

.toast {
    transition: all 0.3s ease-in-out;
}

.toast.error {
    @apply bg-red-500;
}

.toast.infor {
    @apply bg-primary3;
}
</style>