// timestampService.js

import axios from 'axios';
import apirURL from './apiURL';


// Function to fetch the current timestamp from the server
export const fetchTimestamp = async () => {
    try {
        const response = await axios.get(`${apirURL}/weighing/api/timestamp`);


        return response.data.timestamp;
        

    } catch (error) {
        console.error('Error fetching timestamp:', error);
        return null;
    }
};
