<template>
  <Toast ref="toast" />
  <div class="bg-default overflow-hidden relative">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Customer</label
        >
        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          :options="customers"
          filter
          v-model="selectCustomer"
          placeholder="Select Customer"
          option-label="name"
          option-value="id"
          show-clear
          checkmark
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div>
      <div>
        <h2 class="py-2">Customer Invoice Transaction</h2>
      </div>
      <div :class="tableClasses">
        <table>
          <tbody>
            <tr class="header_table">
              <td
                @click="sortData('invoice_id')"
                class="w-[120px] text-start text-nowrap toggle_sort"
              >
                Invoice ID
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'invoice_id' && sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="text-nowrap text-start">Created At</td>
              <td
                @click="sortData('customer_name')"
                class="w-[120px] text-start text-nowrap toggle_sort"
              >
                Customer
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'customer_name' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('total_weight')"
                class="w-[120px] text-start text-nowrap toggle_sort"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'total_weight' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('total_amount')"
                class="text-start text-nowrap toggle_sort"
              >
                Total Amount
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'total_amount' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>

              <td
                @click="sortData('amount_paid')"
                class="text-start text-nowrap toggle_sort"
              >
                Total Paid
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'amount_paid' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="min-w-[130px] text-nowrap text-start">
                Amount Owe
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>

              <td class="">Status</td>
              <td class="border">Acion</td>
            </tr>
          </tbody>

          <tbody v-if="customerInvoiceData.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in customerInvoiceData"
              :key="data.invoice_id"
            >
              <td class="border-b">{{ data.invoice_id }}</td>
              <td class="border-b">{{ formatDate(data.invoice_date) }}</td>
              <td class="border-b">{{ data.customer_name }}</td>
              <td class="border-b">
                {{ numeralFormat(data.total_weight, "0,0[.]0") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.total_amount, "0,0[.]00") }} đ
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_paid, "0,0[.]00") }} đ
              </td>
              <td class="border-b">
                {{
                  numeralFormat(
                    data.total_amount - data.amount_paid,
                    "0,0[.]00"
                  )
                }}
                đ
              </td>

              <td class="border-b">
                <el-button type="warning" round>{{
                  data.customer_invoice_status
                }}</el-button>
              </td>
              <td class="border">
                <div class="flex items-center gap-3">
                  <svg-icon
                    @click="handlePrint(data.invoice_id)"
                    :path="mdiEyeOutline"
                    type="mdi"
                    class="info"
                  ></svg-icon>

                  <svg-icon
                    @click="openModal(data)"
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_transaction?.supplier_invoice
                          ?.delete === true)
                    "
                    :path="mdiArrowULeftTop"
                    type="mdi"
                    class="delete"
                  ></svg-icon>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="100%">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-for="data in customerInvoiceData"
        :key="data.invoice_id"
        class="w-full max-w-[1480px] flex items-center justify-between mt-8"
      >
        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          @page-change="handlePageChange"
          :totalRecord="totalRecord"
          @page-size-change="handlePageSizeChange"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
                <DialogTitle
                  class="text-lg text-start font-medium text-gray-900"
                >
                  Confirm Delete
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-nowrap text-start">
                    Are You sure you want to delete invoice ID [
                    {{ selectedDoc.invoice_id }} ]
                  </p>
                </div>
                <div class="mt-4 flex justify-end gap-2">
                  <button
                    class="bg-gray-200 px-4 py-2 rounded text-primary2"
                    @click="closeModal"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-red-600 text-white px-4 py-2 rounded"
                    @click="confirmDelete"
                  >
                    Delete
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import InvoiceTransactionDetailsVue from "./InvoiceTransactionDetails.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import Toast from "@/components/Toast.vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import axios from "axios";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import { mdiEyeOutline, mdiArrowULeftTop } from "@mdi/js";
import { useRouter } from "vue-router";
import { decodeJwt } from "@/composables/decodeJWT";
import moment from "moment-timezone";
import { useCustomerPayment } from "@/composables/usePartnerTransaction";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    InvoiceTransactionDetailsVue,
    NoDataComponet,
    Pagination,
    Toast,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const customerInvoiceData = ref([]);
    const totalPages = ref(0);
    const pageSize = ref(50);
    const currentPage = ref(1);
    const selectCustomer = ref(null);
    // Define your ref variables
    const currentDate = new Date();

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const formatDate = (date) => {
      const validDate = new Date(date);

      if (isNaN(validDate)) {
        console.error("Invalid date:", date);
        return null;
      }
      return validDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const totalRecord = ref(0);
    const fetchCustomerInvoice = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;

      try {
        const params = {
          tableName: "customer_invoice",
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: "id",
          sortDirection: "DESC",
          columnAliases: JSON.stringify({
            invoice_id: { table: "customer_invoice", column: "id" },
            invoice_date: { table: "customer_invoice", column: "created_at" },
            total_weight: {
              table: "customer_invoice",
              column: "total_weight_drop",
            },
            amount_paid: { table: "customer_invoice", column: "amount_paid" },
            total_amount: { table: "customer_invoice", column: "total_amount" },
            customer_name: { table: "customers", column: "name" },
            customer_invoice_status: {
              table: "customer_invoice",
              column: "status",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "customers",
              joinColumnChild: "customer_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            // Filter by customer if selected
            ...(selectCustomer.value
              ? [
                  {
                    field: "customer_id",
                    operator: "=",
                    value: selectCustomer.value,
                    typeTable: "table",
                  },
                ]
              : []),

            // Filter by date range if selected
            ...(fromDate && toDate
              ? [
                  { field: "created_at", operator: ">=", value: fromDate },
                  { field: "created_at", operator: "<=", value: toDate },
                ]
              : []),
          ]),
        };

        // API request to fetch the filtered data
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );

        // Store the fetched data
        customerInvoiceData.value = response.data.data;
        console.log("customerInvoiceData", customerInvoiceData.value);
        totalRecord.value = customerInvoiceData.value.length;
        // Update pagination
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Error fetching customer invoices:", error);
      }
    };
    const handleFilter = () => {
      fetchCustomerInvoice(); // Fetch customer invoices when filter changes
    };
    const customers = ref([]);
    const fetchSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };

        const response = await axios.get(apiUrl, { params });
        customers.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const router = useRouter();
    const handlePrint = (id) => {
      router.push({
        name: "printclientinvoice",
        params: { id },
      });
    };
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "InvoiceTransactionDetailsVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const invoice_last_record = ref([]);
    const fetchInvoiceLastRecord = async () => {
      try {
        const params = {
          tableName: "customer_invoice",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        invoice_last_record.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchCustomerInvoice(),
        fetchSupplier(),
        fetchUserByID(),
        fetchInvoiceLastRecord(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([
        fetchCustomerInvoice(),
        fetchSupplier(),
        fetchUserByID(),
        fetchInvoiceLastRecord(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      socket.off("database_realTime");
    });

    const sortColumns = ref("invoice_id");
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      customerInvoiceData.value = toggleSort(
        direction,
        customerInvoiceData.value,
        field
      );
    };

    const isModalOpen = ref(false);
    const selectedDoc = ref(null);

    const openModal = (doc) => {
      console.log("doc", doc);

      if (doc.invoice_id) {
        selectedDoc.value = doc;
        isModalOpen.value = true;
      } else {
        toast.value.showToast("Can not delete ", "error");
        return;
      }
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const confirmDelete = async () => {
      if (!selectedDoc.value) return;
      try {
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${selectedDoc.value.invoice_id}`,
          {
            data: { tableName: "customer_invoice" },
          }
        );

        // update remove trasaction from wallet
        if (
          selectedDoc.value.partner_id !== null ||
          selectedDoc.value.partner_id !== undefined
        ) {
          await useCustomerPayment(
            selectedDoc.value,
            selectedDoc.value.invoice_id,
            "remove"
          );
        }

        if (
          selectedDoc.value.repayments &&
          Array.isArray(selectedDoc.value.repayments)
        ) {
          for (const repayment of selectedDoc.value.repayments) {
            if (repayment.boat_id && Array.isArray(repayment.boat_id)) {
              for (const boat of repayment.boat_id) {
                if (boat.boat_enter_parking_id) {
                  const req_update_weight = {
                    tableName: "boat_enter_parking",
                    fields: { is_customer_invoice: false },
                  };
                  await axios.patch(
                    `${apirURL}/weighing/api/updateData/${boat.boat_enter_parking_id}`,
                    req_update_weight
                  );
                  console.log(
                    `Updated is_customer_invoice for boat_enter_parking_id ${boat.boat_enter_parking_id}`
                  );
                }
              }
            }
          }
        }
        showSuccessToast("delete", "error");
        closeModal();
      } catch (error) {
        showSuccessToast("cannotdelete", "error");
        console.error("Error deleting supplier invoice:", error);
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchCustomerInvoice();
    };
    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      fetchCustomerInvoice();
    };
    return {
      authUserRole,
      permissions,
      formatDate,
      handleAdd,
      isOpen,
      currentComponent,
      handleClose,
      tableClasses,
      navClasses,
      selectClasses,
      customerInvoiceData,

      customers,
      handleFilter,
      selectCustomer,
      selectDateRage,
      mdiEyeOutline,
      mdiArrowULeftTop,
      handlePrint,
      sortData,
      sortColumns,
      sortDirections,
      handlePageChange,
      handlePageSizeChange,
      currentPage,
      pageSize,
      totalPages,
      totalRecord,
      toast,
      isModalOpen,
      openModal,
      closeModal,
      confirmDelete,
      selectedDoc,
      invoice_last_record,
    };
  },
};
</script>

<style></style>
