<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[750px] rouded-[4px] h-auto bg-white"
  >
    <div class="details_title">
      <h2 class="ml-4">
        Fill Parking with Truck:
        {{ getParkingName(parking_fill_of_parking_id) }} ,
        {{ boat_name.boat_name }}
      </h2>
      <div class="flex items-center gap-2">
        <div
          @click.stop="handleClose"
          class="bg-none cursor-pointer text-primary2 mr-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-3 mt-7">
      <div>
        <h2 class="text-start">
          <!-- Parking: {{ parking_fill[0].parking_id }} - Boat -->
          {{ getParkingName(parking_fill_of_parking_id) }} ,
          {{ boat_name.boat_name }}
        </h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="text-body text-primary5">
              <td class="min-w-[70px] bg-white border-b text-start">No</td>
              <td class="min-w-[70px] bg-white border-b text-start">
                Truck No
              </td>

              <td class="min-w-[100px] bg-white border-b text-start">Weight</td>
              <td class="min-w-[100px] bg-white border-b text-start">Note</td>
              <td class="min-w-[100px] bg-white border-b text-start">Action</td>
            </tr>
          </tbody>

          <tbody>
            <tr
              v-for="(data, i) in row"
              class="text-body font-normal text-primary2 bg-white"
            >
              <td class="border-b">{{ i + 1 }}</td>
              <td class="border-b">
                <input
                  v-model="data.truck"
                  class="w-[150px]"
                  type="text"
                  placeholder="Truck "
                />
              </td>
              <td class="border-b">
                <InputNumber
                  style="width: 150px"
                  placeholder="Amount"
                  v-model="data.weight"
                  :maxFractionDigits="2"
                  step="0.01"
                />
              </td>
              <td class="border-b">
                <input
                  v-model="data.note"
                  class="w-[150px]"
                  type="text"
                  placeholder="Note"
                />
              </td>
              <td class="border-b" @click="removeRow(i)">
                <p v-if="row.length > 1" class="cursor-pointer">delete</p>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="text-body font-normal text-primary2 bg-white">
              <td colspan="5" @click="addRow" class="border-b cursor-pointer">
                New
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4 flex items-center gap-2">
        <button class="btncancel">Close</button>
        <button class="btnprimary" type="submit">Submit</button>
      </div>
    </div>
  </form>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { decodeJwt } from "@/composables/decodeJWT";
import { fetchTimestamp } from "@/services/timestamp";

export default {
  props: [
    "parking_fill",
    "id",
    "weighing_data",
    "parking_fill_of_parking_id",
    "boat_name",
    "isId_of_weighing",
  ],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const row = ref([
      {
        truck: null,
        weight: null,
        note: null,
      },
    ]);
    const addRow = () => {
      row.value.push({
        truck: null,
        weight: null,
        note: null,
      });
    };
    const removeRow = (index) => {
      if (row.value.length > 1) {
        row.value.splice(index, 1);
      }
    };
    const authUserId = ref(null);
    const total_weight = computed(() => {
      return row.value.reduce((acc, item) => acc + item.weight, 0);
    });

    const parkings = ref([]);

    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parkings.value = response.data;
      } catch (error) {
        console.error("Error fetching parkings:", error);
      }
    };
    const getParkingName = (id) => {
      const parking = parkings.value.find((item) => item?.id === id);
      return parking?.name;
    };

    onMounted(async () => {
      console.log("parking_fill", props.parking_fill);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.id) {
        console.log("id", props.id);
      }
      if (props.isId_of_weighing) {
        console.log("isId_of_weighing", props.isId_of_weighing);
      }

      if (props.boat_name) {
        console.log("boat_name", props.boat_name);
      }
      if (props.parking_fill_of_parking_id) {
        console.log(
          "parking_fill_of_parking_id",
          props.parking_fill_of_parking_id
        );
      }
      if (
        props.weighing_data &&
        Array.isArray(props.weighing_data.rental_parking)
      ) {
        const targetParking = props.weighing_data.rental_parking.find(
          (entry) => entry.parking_id === props.parking_fill_of_parking_id
        );
        // row.value = targetParking.TruckList || [];
        if (targetParking) {
          console.log("Found rental_parking object:", targetParking);
          // You can now use targetParking as needed
        } else {
          console.warn("No matching parking_id found in rental_parking array.");
        }
      }
      await Promise.allSettled([fetchParking()]);
    });

    const handleSubmit = async () => {
      try {
        const timestamp = await fetchTimestamp();

        // Prepare truck data to be updated
        const truck = row.value.map((item) => ({
          truck: item.truck,
          weight: item.weight,
          note: item.note,
        }));

        const parking_Id = props.parking_fill_of_parking_id;
        let existingRentalParking = [];

        // Parse the existing rental_parking data
        console.log(
          "Initial rental_parking data:",
          props.weighing_data.rental_parking
        );
        if (typeof props.weighing_data.rental_parking === "string") {
          try {
            existingRentalParking = JSON.parse(
              props.weighing_data.rental_parking
            );
          } catch (parseError) {
            console.error("Error parsing rental_parking data:", parseError);
            existingRentalParking = []; // Fallback to an empty array
          }
        } else if (Array.isArray(props.weighing_data.rental_parking)) {
          existingRentalParking = props.weighing_data.rental_parking;
        }

        console.log("Parsed existingRentalParking:", existingRentalParking);

        // Update only the TruckList of the object with the matching parking_id
        const updatedRentalParking = existingRentalParking.map((entry) => {
          if (entry.parking_id === parking_Id) {
            console.log("Updating TruckList for parking_id:", parking_Id);
            return {
              ...entry,
              TruckList: truck, // Only update the TruckList
              weight: truck.reduce((acc, item) => acc + (item.weight || 0), 0), // Update the weight
              status: "Completed", // Update the status
            };
          }
          return entry;
        });

        console.log("Updated Rental Parking:", updatedRentalParking);

        const req = {
          tableName: "weighing_rice_history",
          fields: {
            weighing_id: props.isId_of_weighing || props.id,
            rental_parking: JSON.stringify(updatedRentalParking),
            created_by: authUserId.value,
            created_at: timestamp,
            service_type: "external",
          },
        };

        console.log("Request Data:", req);

        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          req
        );

        if (response.data) {
          const requestBody = {
            tableName: "weighing",
            fields: {
              rental_parking: JSON.stringify(updatedRentalParking),
            },
          };

          const response_weighing_update = await axios.patch(
            `${apirURL}/weighing/api/updateData/${
              props.isId_of_weighing || props.id
            }`,
            requestBody
          );

          console.log(
            "response_weighing_update",
            response_weighing_update.data
          );
          if (response_weighing_update.data) {
            emit("close");
          }
        } else {
          console.error("Error: No response data");
        }
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response ? error.response.data : error.message
        );
      }
    };

    return {
      handleClose,
      row,
      addRow,
      removeRow,
      handleSubmit,
      getParkingName,
    };
  },
};
</script>
