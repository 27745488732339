<template>
  <div class="w-[592px] h-[650px]">
    <div class="details_title">
      <h2 class="ml-4">
        Supplier {{ getSupplierName(datatoedit.supplier_id) }}
      </h2>
      <div class="flex items-center gap-2">
        <!-- <div>
          <h2 class="mr-4">Purchase Order Detail</h2>
        </div> -->
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="py-4 space-y-3 border-b-2 border-primary6">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>{{ getSupplierName(datatoedit.supplier_id) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit.order_status }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Note</h2>
          <h2 v-if="datatoedit.note">{{ datatoedit.note }}</h2>
          <h2 v-else>No data</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Rice Detail</h2>
        <div class="w-full border h-[240px] overflow-auto">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="border-b text-start">Rice Type</td>
                <td class="border-b text-start">Amount</td>
                <td class="border-b text-start">Estimate Price</td>
                <td class="border-b text-start">Estimate Total</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="rice in datatoedit.type_of_rice"
                :key="rice.selectedRice.value"
              >
                <td class="border-b text-start">
                  {{ rice.selectedRice }}
                </td>
                <td class="border-b text-start">
                  {{ numeralFormat(rice.amount, "0,0[.]0") }} ៛
                </td>
                <td class="border-b text-start">
                  {{ numeralFormat(rice.estimatePrice, "0,0[.]0") }} ៛
                </td>
                <td class="border-b text-start">
                  {{
                    formatCurrency(
                      rice.amount * rice.estimatePrice,
                      " ៛ ",
                      "after"
                    )
                  }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td class="border-b text-end font-semibold" colspan="2">
                  <h2 class="mr-12">
                    {{ numeralFormat(totalAmount, "0,0[.]0") }} Kg
                  </h2>
                </td>
                <td class="border-b text-end font-semibold" colspan="2">
                  <h2 class="mr-5">
                    {{ formatCurrency(totalEstimatePrice, " ៛ ", "after") }}
                  </h2>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4">
          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import { formatCurrency } from "@/composables/formatCurrency";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    const suppliers = ref([]);

    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching customer:", error);
      }
    };
    const getSupplierName = (id) => {
      const supplier = suppliers.value.find((item) => item?.id === id);
      return supplier?.name;
    };
    onMounted(() => {
      fetchSuppliers();
      fetchRiceType();
    });

    const getCustomerName = (id) => {
      const supplier = customer.value.find((item) => item?.id === id);
      return supplier?.name;
    };
    const totalAmount = computed(() => {
      return props.datatoedit.type_of_rice.reduce(
        (sum, rice) => sum + rice.amount,
        0
      );
    });

    const totalEstimatePrice = computed(() => {
      return props.datatoedit.type_of_rice.reduce(
        (sum, rice) => sum + rice.amount * rice.estimatePrice,
        0
      );
    });

    const rices = ref([]);

    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rices.value = response.data.map((rice) => ({
          id: rice.id,
          name: rice.name,
        }));
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const getRiceName = (id) => {
      const rice = rices.value.find((item) => item?.id === id);
      return rice?.name;
    };

    return {
      handleClose,
      getSupplierName,
      formatDate2,
      totalAmount,
      totalEstimatePrice,
      formatCurrency,
      getRiceName,
    };
  },
};
</script>

<style></style>
