<template>
  <div class="w-full">
    <div class="flex">
      <div
        :class="{
          ' tab2 cursor-pointer': true,
          'text-primary': activeTab === 'saleOrder',
        }"
        @click="setActiveTab('saleOrder')"
      >
        Sale Order
      </div>
      <div
        :class="{
          ' tab2 cursor-pointer': true,
          'text-primary': activeTab === 'purchase',
        }"
        @click="setActiveTab('purchase')"
      >
        Purchase Order
      </div>
    </div>
    <div v-if="activeTab === 'saleOrder'">
      <!-- Content for Sale Order tab -->
      <SaleOrderListVue />
    </div>

    <div v-if="activeTab === 'purchase'">
      <PunchaseOrder />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import SaleOrderListVue from "../admin/SaleOrderList.vue";
import PunchaseOrder from "../admin/PurchaseOrder.vue";
export default {
  components: {
    SaleOrderListVue,
    PunchaseOrder,
  },
  setup() {
    const activeTab = ref("saleOrder");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
/* .tab2 {
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}
*/
.text-primary {
  color: #168af4; /* Use your primary text color */
}
</style>
