<template>
  <div class="w-full mb-3">
    <div v-if="activeTab === 'wallet'">
      <WalletList />
    </div>
  </div>
  <div
    class="w-full overflow-auto relative animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div class="w-auto">
      <div class="flex items-start gap-3">
        <div :class="selectClasses">
          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Partner
            </label>

            <Select
              v-model="selectedPartner"
              :options="partnerData"
              optionLabel="name"
              optionValue="id"
              filter
              id="partner"
              showClear
              placeholder="select partner"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Wallet
            </label>

            <Select
              v-model="selectedWallet"
              :options="walletData"
              optionLabel="name"
              optionValue="value"
              filter
              id="wallet"
              showClear
              placeholder="select wallet"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Type
            </label>
            <Select
              v-model="selectedType"
              :options="transactionTypes"
              optionLabel="label"
              optionValue="value"
              filter
              id="type"
              showClear
              placeholder="select type"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Date Range
            </label>
            <DatePicker
              v-model="dateRange"
              class="w-full md:w-56 h-4 text-start flex items-center"
              dateFormat="dd/M/yy"
              placeholder="start date"
              showIcon
              iconDisplay="input"
              showButtonBar
              selectionMode="range"
              :manualInput="false"
              inputId="dd"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="opacity-0 mt-2">
              <span class="text-primary4 mr-2">*</span>Receiver
            </label>
            <button class="btn_filter" @click="handleFilter">Filter</button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div :class="navClasses">
        <h2 class="py-2 text-start">Wallet Transactions</h2>
        <div class="flex items-center gap-3">
          <button @click="handleTransfer" class="btncancel">Exchange</button>
          <button @click="handleDrawing" class="btncancel">Drawing</button>
          <button @click="handleAddDeposit">Deposit</button>
        </div>
      </div>

      <div :class="tableClasses">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start text-nowrap">
                Created At
              </td>
              <td class="min-w-[120px] border-b text-start text-nowrap">
                <div class="flex items-center justify-start gap-3">
                  <h2>Transaction Id</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start text-nowrap">
                <div class="flex items-center justify-start gap-3">
                  <h2>Partner</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortClient('name', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortClient('name', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td>
              <td class="min-w-[70px] border-b text-start text-nowrap">
                Wallet
              </td>
              <td class="min-w-[120px] border-b text-start text-nowrap">
                Type
              </td>
              <td class="min-w-[120px] border-b text-start text-nowrap">
                Invoice ID
              </td>
              <td class="min-w-[70px] border-b text-start text-nowrap">
                <div class="flex items-center justify-start gap-3">
                  <h2>Amount</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortClient('amount', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortClient('amount', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td>

              <td class="min-w-[70px] border-b text-start text-nowrap">
                Exchange Rate
              </td>
              <!-- <td class="min-w-[70px] border text-start">Action</td> -->
            </tr>
          </tbody>

          <tbody
            v-if="walletTransactionData && walletTransactionData.length > 0"
          >
            <tr
              class="text-body font-normal text-primary2 bg-white whitespace-nowrap"
              v-for="data in walletTransactionData"
              :key="data.id"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ getPartnerName(data.partner_id) }}</td>
              <td class="border-b">
                <template v-if="data.transaction_type === 'exchange balance'">
                  {{ data.from_wallet }} → {{ data.to_wallet }}
                </template>
                <template v-else>
                  {{ data.wallet }}
                </template>
              </td>

              <!-- Transaction Type Buttons -->
              <td v-if="data.transaction_type === 'deposit'" class="border-b">
                <span class="px-4 py-1 rounded-full bg-green-500 text-white"
                  >Deposit</span
                >
              </td>
              <td
                v-else-if="data.transaction_type === 'withdrawal'"
                class="border-b"
              >
                <span class="px-4 py-1 rounded-full bg-red-600 text-white"
                  >Drawing</span
                >
              </td>
              <td
                v-else-if="data.transaction_type === 'exchange balance'"
                class="border-b"
              >
                <span class="px-4 py-1 rounded-full bg-yellow-600 text-white"
                  >Exchange</span
                >
              </td>
              <td
                v-else-if="data.transaction_type === 'preloan'"
                class="border-b"
              >
                <span class="px-4 py-1 rounded-full bg-blue-600 text-white"
                  >Preloan</span
                >
              </td>

              <td
                v-else-if="data.transaction_type === 'customer payment'"
                class="border-b whitespace-nowrap"
              >
                <span class="px-4 py-1 rounded-full bg-purple-600 text-white"
                  >Customer Payment</span
                >
              </td>
              <td
                v-else-if="data.transaction_type === 'supplier payment'"
                class="border-b whitespace-nowrap"
              >
                <span class="px-4 py-1 rounded-full bg-pink-600 text-white"
                  >Supplier Payment</span
                >
              </td>

              <td class="border-b whitespace-nowrap">
                <span class="px-4 py-1">{{
                  data.transaction_type === "customer payment"
                    ? data.customer_invoice
                    : data.supplier_invoice
                }}</span>
              </td>

              <!-- Amount Column Based on Transaction Type -->
              <td
                class="border-b font-bold text-red-500 whitespace-nowrap"
                :class="{
                  'text-red-500': data.transaction_type === 'deposit',
                  'text-red-500':
                    data.transaction_type === 'withdrawal' ||
                    data.transaction_type === 'exchange balance',
                  'text-red-500': data.transaction_type === 'preloan',
                }"
              >
                <template
                  v-if="
                    data.transaction_type === 'deposit' ||
                    data.transaction_type === 'withdrawal' ||
                    data.transaction_type === 'customer payment' ||
                    data.transaction_type === 'supplier payment'
                  "
                >
                  {{
                    data.transaction_type === "deposit" ||
                    data.transaction_type === "customer payment" ||
                    data.transaction_type === "supplier payment"
                      ? numeralFormat(data.amount, "0,0[.]00")
                      : numeralFormat(-data.amount, "0,0[.]00")
                  }}
                  {{
                    data.wallet === "KHR"
                      ? "៛"
                      : data.wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
                <template v-if="data.transaction_type === 'exchange balance'">
                  {{ numeralFormat(data.amount_before_exchange, "0,0[.]00") }}
                  {{
                    data.from_wallet === "KHR"
                      ? "៛"
                      : data.from_wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                  = {{ numeralFormat(data.amount_after_exchange, "0,0[.]00") }}
                  {{
                    data.to_wallet === "KHR"
                      ? "៛"
                      : data.to_wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>

                <template v-if="data.transaction_type === 'preloan'">
                  {{ numeralFormat(data.amount, "0,0[.]00") }}
                  {{ data.currency?.symbol_currency }}
                </template>
              </td>

              <td class="border-b font-bold text-red-500 text-center">
                <template v-if="data.transaction_type === 'exchange balance'">
                  {{ numeralFormat(data.exchange_rate, "0,0[.]00") }}
                  {{
                    data.to_wallet === "KHR"
                      ? "៛"
                      : data.to_wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
              </td>
              <!-- <td class="border">
                <div class="flex gap-3">
                  <svg-icon
                    :path="mdiTrashCanOutline"
                    type="mdi"
                    class="delete"
                    @click="openDeleteConfirmation(data)"
                  >
                  </svg-icon>
                </div>
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8" class="text-center">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="w-full mt-8">
      <div class="">
        <PaginationVue
          :currentPage="currentPage"
          :isFilter="isFilter"
          :totalPages="totalPages"
          :pageSize="pageSize"
          @page-change="handlePageChange"
          @page-size-change="handlePageSizeChange"
          :tableName="tableName"
          :totalRecord="totalRecord"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  >
                  </component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="handleClsoeDrawing" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClsoeDrawing"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isTransfer" as="template">
      <Dialog as="div" @close="handleCloseTransfer" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseTransfer"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
    <Toast ref="toast" />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted } from "vue";
import {
  getResponsiveTable,
  getResponsiveSelect,
  getResponsiveTableNav,
} from "@/composables/useResponsive";
import WalletTransactiomDepositFormVue from "@/form/WalletTransactiomDepositForm.vue";
import WalletTransactionDrawingFormVue from "@/form/WalletTransactionDrawingForm.vue";
import WalletExchange from "@/form/WalletTransactionTransfer.vue";
import { useRoute, useRouter } from "vue-router";
import PaginationVue from "@/components/Pagination.vue";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import Toast from "@/components/Toast.vue";
import toggleSort from "@/composables/sortData";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { remove } from "@/composables/usePartnerTransaction";
import { formatDateWithTimezone } from "@/utils/formatDateWithTimeZone";
import { mdiTrashCanOutline } from "@mdi/js";
export default {
  components: {
    PaginationVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    WalletTransactiomDepositFormVue,
    WalletTransactionDrawingFormVue,
    WalletExchange,
    DeleteConfrimation,
    Toast,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("partner_transaction_history");
    const dateRange = ref(null);
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("DESC"); // default sort direction
    const walletTransactionData = ref([]);

    const walletData = ref([
      { value: "KHR", name: "KHR" },
      { value: "VND", name: "VND" },
    ]);

    const activeTab = ref("wallet");
    const router = useRouter();
    const route = useRoute();

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
      if (tabName === "wallet") {
        router.push({ name: "wallet" });
      } else if (tabName === "wallet-transaction") {
        router.push({ name: "wallet-transaction" });
      }
    };

    const toast = ref(null);
    const isSuccess = ref(false);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          case "amount":
            message = "Please Enter the amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      if (item.transaction_type === "deposit") {
        currentComponent.value = "WalletTransactiomDepositFormVue";
      } else if (item.transaction_type === "withdrawal") {
        currentComponent.value = "WalletTransactionDrawingFormVue";
      } else if (item.transaction_type === "exchange_balance") {
        currentComponent.value = "WalletExchange";
      }
      datatoedit.value = item;
    };

    const isFilter = ref(false);
    const fetchWalletTransaction = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        let dynamicConditions = [];

        dynamicConditions = [
          ...(selectedPartner.value
            ? [
                {
                  field: "partner_id",
                  operator: "=",
                  value: selectedPartner.value,
                },
              ]
            : []),
          ...(selectedWallet.value
            ? [{ field: "wallet", operator: "=", value: selectedWallet.value }]
            : []),
          ...(selectedType.value
            ? [
                {
                  field: "transaction_type",
                  operator: "=",
                  value: selectedType.value,
                },
              ]
            : []),
          ...(dateRange.value?.[0] && dateRange.value?.[1]
            ? [
                {
                  field: "created_at",
                  operator: ">=",
                  value: formatDateWithTimezone(dateRange.value[0]),
                },
                {
                  field: "created_at",
                  operator: "<=",
                  value: formatDateWithTimezone(dateRange.value[1], true),
                },
              ]
            : []),
        ];

        const params = {
          tableName: table.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: "DESC",
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const response = await axios.get(apiUrl, { params });

        // Update data in one atomic operation
        walletTransactionData.value = response.data.data;
        console.log("walletTransactionData", walletTransactionData.value);

        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Error fetching wallet transactions:", error);
        walletTransactionData.value = [];
        totalPages.value = 0;
      }
    };

    const last_transaction = ref([]);
    const fetchLastTransaction = async () => {
      try {
        const params = {
          tableName: "partner_transaction_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        last_transaction.value = response.data;
        console.log("last_transaction", last_transaction.value);
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const sortClient = (field, sortDirection) => {
      if (field === "name") {
        walletTransactionData.value = walletTransactionData.value.sort(
          (a, b) => {
            const nameA = getPartnerName(a.partner_id).toUpperCase();
            const nameB = getPartnerName(b.partner_id).toUpperCase();
            if (nameA < nameB) {
              return sortDirection === "asc" ? -1 : 1;
            }
            if (nameA > nameB) {
              return sortDirection === "asc" ? 1 : -1;
            }
            return 0;
          }
        );
      } else if (field === "id") {
        toggleSort(sortDirection, walletTransactionData.value, field);
      }
      if (field === "amount") {
        toggleSort(sortDirection, walletTransactionData.value, field);
      }
    };
    const partnerData = ref([]);
    const fetchPartner = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };

        const response = await axios.get(apiUrl, { params });
        partnerData.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };

    const getPartnerName = (id) => {
      const findPartnername = partnerData.value.find((p) => p.id === id);
      return findPartnername ? findPartnername.name : "";
    };

    const handleAddDeposit = () => {
      isOpen.value = true;
      datatoedit.value = null;
      currentComponent.value = "WalletTransactiomDepositFormVue";
    };

    const totalRecord = ref([]);
    const fetchTotalData = async () => {
      try {
        const req = {
          tableName: "partner_transaction_history",
          sortColumn: "id",
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        totalRecord.value = res.data.length;
      } catch (error) {
        console.log(error);
      }
    };

    const handleClose = async () => {
      currentComponent.value = "";
      isOpen.value = false;
      await fetchTotalData();
    };
    const handleDrawing = () => {
      isPopup.value = true;
      datatoedit.value = null;
      currentComponent.value = "WalletTransactionDrawingFormVue";
    };
    const handleClsoeDrawing = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const isTransfer = ref(false);
    const handleTransfer = () => {
      isTransfer.value = true;
      datatoedit.value = null;
      currentComponent.value = "WalletExchange";
    };
    const handleCloseTransfer = () => {
      isTransfer.value = false;
      currentComponent.value = "";
    };

    const windowWidth = ref(window.innerWidth);
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchWalletTransaction(),
        fetchPartner(),
        fetchTotalData(),
        fetchLastTransaction(),
      ]);
    };

    const tableName = ref("");
    onMounted(async () => {
      await Promise.allSettled([
        fetchWalletTransaction(),
        fetchPartner(),
        fetchTotalData(),
        fetchLastTransaction(),
      ]);
      tableName.value = "partner_transaction_history";
      window.addEventListener("resize", handleResize);
      handleResize();
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });

    // delete
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);

    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const response = await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.id}`,
          {
            data: { tableName: table.value },
          }
        );

        if (response.status === 200) {
          //update partner wallet transaction
          remove(deletedata.value);

          if (
            walletTransactionData.value.length <= 1 &&
            currentPage.value > 1
          ) {
            handlePageChange(currentPage.value - 1);
          } else if (currentPage.value === 1) {
            await fetchWalletTransaction();
          }

          closeDeleteDialog();
          deletedata.value = null;
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    // filter
    const selectedPartner = ref(null);
    const selectedWallet = ref(null);
    const selectedType = ref(null);

    const transactionTypes = ref([
      { value: "deposit", label: "Deposit" },
      { value: "withdrawal", label: "Withdrawal" },
      { value: "customer payment", label: "Customer Payment" },
      { value: "supplier payment", label: "Supplier Payment" },
      { value: "preloan", label: "Preloan" },
      { value: "exchange balance", label: "Exchange Balance" },
    ]);

    const shortcuts = [
      {
        text: "Today",
        value() {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[0].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Month",
        value() {
          const start = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          const end = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          );
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[1].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Week",
        value() {
          const today = new Date();
          const startOfWeek = new Date(
            today.setDate(today.getDate() - today.getDay())
          ); // Sunday
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date();
          endOfWeek.setDate(startOfWeek.getDate() + 6); // Saturday
          endOfWeek.setHours(23, 59, 59, 999);
          return [startOfWeek, endOfWeek];
        },
        onClick(picker) {
          const [start, end] = shortcuts[2].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 3 Months",
        value() {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 3);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[3].value();
          picker.$emit("pick", [start, end]);
        },
      },
    ];

    const handlePageChange = async (page) => {
      currentPage.value = page;
      await fetchWalletTransaction();
    };

    const handlePageSizeChange = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await fetchWalletTransaction();
    };

    const handleFilter = async () => {
      isFilter.value = true;
      currentPage.value = 1;
      await fetchWalletTransaction();
    };

    return {
      isFilter,
      handleFilter,
      dateRange,
      walletData,
      totalRecord,
      isSuccess,

      handlePageChange,
      handlePageSizeChange,
      showSuccessToast,
      handleEditData,
      datatoedit,
      formatDate2,
      getPartnerName,
      currentPage,
      pageSize,
      totalPages,
      tableName,
      table,
      walletTransactionData,
      route,
      setActiveTab,
      activeTab,
      handleClose,
      isPopup,
      isOpen,
      handleAddDeposit,
      currentComponent,
      handleDrawing,
      handleClsoeDrawing,
      handleTransfer,
      isTransfer,
      handleCloseTransfer,
      tableClasses,
      selectClasses,
      navClasses,
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deletedata,
      sortClient,
      toast,
      partnerData,
      transactionTypes,
      selectedPartner,
      selectedWallet,
      selectedType,
      dateRange,

      shortcuts,
      mdiTrashCanOutline,
      last_transaction,
    };
  },
};
</script>

<style scoped>
.text-primary {
  color: #168af4;
  /* Use your primary text color */
}
</style>
