<template>
  <form @submit.prevent="handleUpdate" class="w-[792px] h-auto relative">
    <div class="details_title">
      <h2 class="ml-4">
        Client: {{ getSupplierName(datatoedit?.supplier_id) }}
      </h2>
      <div class="flex items-center gap-2">
        <div>Parking Rental Detail</div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="border-b-2 grid grid-cols-2 gap-3 py-2">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Customer Name</h2>
          <h2>{{ getSupplierName(datatoedit?.supplier_id) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(datatoedit?.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Phone Number</h2>
          <h2>
            {{
              datatoedit?.phone_number ? datatoedit?.phone_number : "No Data"
            }}
          </h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit?.is_hold ? "Completed" : "Pending" }}</h2>
        </div>
      </div>
    </div>
    <div class="p-5">
      <p class="text-start py-2">Parking Occupied</p>
      <div>
        <div class="w-full h-[240px] overflow-auto border">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Parking</td>
                <td class="border-b text-start text-nowrap">Boat</td>
                <td class="border-b text-start text-nowrap">Weight</td>
                <td class="border-b text-start text-nowrap">Date out</td>
                <td class="border-b text-start text-nowrap">Status</td>
                <td class="border text-start text-nowrap">Action</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="(data, i) in weighing[0]?.rental_parking"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">{{ i + 1 }}</td>
                <td class="border-b text-start text-nowrap">
                  {{ formatDate2(data.created_at) }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ getParkingName(data.parking_id) }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ data.boat_name }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ formatDate2(data.data_in) }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ data.status }}
                </td>
                <td class="border text-start text-nowrap">
                  <button
                    type="button"
                    class="dont_btn"
                    v-if="data.weight === 0"
                    @click="handleAdd(data.parking_id)"
                  >
                    Done
                  </button>
                  <div v-else class="flex items-center gap-2">
                    <svg-icon
                      :path="mdiRotateRight"
                      type="mdi"
                      @click="
                        handleReset(data, data.parking_id, data.boat_name)
                      "
                      class="delete"
                    ></svg-icon>
                    <svg-icon
                      :path="mdiPencil"
                      class="edit"
                      type="mdi"
                      @click="handleEdit(data, data.parking_id, data.boat_name)"
                    ></svg-icon>
                    <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-edit-3 edit"
                      >
                        <path d="M12 20h9"></path>
                        <path
                          d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                        ></path>
                      </svg> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-between mt-4 gap-3">
          <div class="flex gap-3">
            <div class="flex items-center gap-1">
              <input
                v-model="is_completed"
                type="radio"
                name="completionStatus"
                :value="false"
                id="pending"
              />
              <label for="pending">Pending</label>
            </div>
            <div class="flex items-center gap-1">
              <input
                v-model="is_completed"
                type="radio"
                name="completionStatus"
                :value="true"
                id="completed"
              />
              <label for="completed">Completed</label>
            </div>
          </div>
        </div>
        <div
          v-if="is_completed"
          class="w-full space-y-2 flex justify-between mt-3"
        >
          <div class="space-y-2">
            <div class="w-fit flex items-center gap-3 justify-between">
              <div class="flex flex-col gap-1">
                <div class="space-y-4">
                  <label for="name" class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span> Unit Price
                  </label>
                  <InputNumber
                    suffix=" ៛"
                    style="width: 250px"
                    placeholder=" Cost"
                    :maxFractionDigits="2"
                    v-model="cost"
                    step="0.01"
                    class="h-3 flex items-center"
                  />
                </div>
                <div v-if="isValidateCost" class="w-[250px] mt-2 float-end">
                  <el-alert
                    title="Please enter the unit price"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
            <div class="w-fit flex items-center gap-3 justify-between">
              <div class="flex flex-col gap-1">
                <label for="name" class="flex gap-1 text-nowrap">
                  <span class="text-red-500">*</span> Total Price
                </label>
                <InputNumber
                  suffix=" ៛"
                  style="width: 250px"
                  placeholder=" Total Price"
                  disabled
                  :maxFractionDigits="2"
                  v-model="total_cost"
                  step="0.01"
                />
              </div>
            </div>
          </div>
          <div class="w-fit flex items-start gap-3 justify-between py-1">
            <label for="">Note</label>
            <textarea
              class="input-text-area"
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Note"
              v-model="note"
            ></textarea>
          </div>
        </div>
        <div class="w-full flex items-end justify-between">
          <div class="w-full flex items-center justify-start mt-4 gap-3">
            <button type="submit">Save</button>
            <button type="button" @click="handleSaveAndPrint">
              Save & Print
            </button>
            <button @click="handleClose" class="btncancel">Close</button>
          </div>
          <div class="text-start space-y-3">
            <div class="flex items-center justify-between">
              <p class="text-body2">Total Weight</p>
              <p class="text-body">
                {{ numeralFormat(total_weight, "0,0[.]00") }} Kg
              </p>
            </div>
            <div class="flex items-center justify-between">
              <p class="text-body2">Unit Price</p>
              <p class="text-body">{{ cost }} KHR</p>
            </div>
            <div
              class="bg-blue-500/25 text-nowrap w-full px-4 py-3 flex items-end space-x-6"
            >
              <p class="text-start text-blue-600 text-body2">Grand Total</p>
              <p class="text-start text-blue-600 font-bold text-body">
                Total Weight {{ numeralFormat(total_cost, "0,0[.]00") }} ៛
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="outerVisible === true"
      @click="isClose"
      class="w-full h-screen bg-black/25 fixed flex items-center justify-center top-0 right-0"
    >
      <div @click.stop>
        <component
          @close="isClose"
          :parking_fill="parking_fill"
          :id="weighing_id"
          :weighing_data="weighing_data"
          :parking_fill_of_parking_id="parking_fill_of_parking_id"
          :boat_name="boat_name"
          :isId_of_weighing="isId_of_weighing"
          :is="currentComponent"
        ></component>
      </div>
    </div>
  </form>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import NewPaymentWhileRental from "./NewPaymentWhileRental.vue";
import { useRouter } from "vue-router";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { onMounted, ref, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import FillParkingwithTruck from "./FillParkingwithTruck.vue";
import ResetTruckForm from "./ResetTruckForm.vue";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import { fetchTimestamp } from "@/services/timestamp";
import { mdiPencil, mdiRotateRight } from "@mdi/js";
export default {
  props: ["datatoedit", "doc_id"],
  components: {
    NewPaymentWhileRental,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    FillParkingwithTruck,
    ResetTruckForm,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const is_completed = ref(false);
    const outerVisible = ref(false);
    const currentComponent = ref("");
    const weighing_id = ref(null);

    const data_of_weighing = ref([]);
    const boat_name = ref(null);
    const handleAdd = (doc) => {
      isId_of_weighing.value = props.datatoedit.id;
      outerVisible.value = true;
      currentComponent.value = "FillParkingwithTruck";
      weighing_id.value = doc.id;
      data_of_weighing.value = doc;
      parking_fill_of_parking_id.value = doc;
      boat_name.value = props.datatoedit.rental_parking;
      console.log(
        "parking_fill_of_parking_id",
        parking_fill_of_parking_id.value
      );
    };
    const isId_of_weighing = ref(null);
    const handleEdit = (data, parking_id) => {
      outerVisible.value = true;
      currentComponent.value = "FillParkingwithTruck";
      weighing_id.value = data;
      weighing_data.value = props.datatoedit;
      isId_of_weighing.value = props.datatoedit.id;
      parking_fill_of_parking_id.value = parking_id;
      boat_name.value = data;
      console.log(
        "parking_fill_of_parking_id",
        parking_fill_of_parking_id.value
      );
    };
    const isClose = () => {
      outerVisible.value = false;
      currentComponent.value = "";
      data_of_weighing.value = [];
      weighing_id.value = null;
      parking_fill_of_parking_id.value = null;
      boat_name.value = null;
    };
    const router = useRouter();
    const handleGotoPrint = () => {
      router.push({ path: "/printrantalparking" });
    };
    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
        console.log("weighing", weighing.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const parking = ref([]);
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
      } catch (error) {
        console.log("error", error);
      }
    };
    const getParkingName = (id) => {
      const parkings = parking.value.find((item) => item.id === id);
      return parkings ? parkings.name : "No Data";
    };
    const parking_fill = ref([]);
    const parking_fill_of_parking_id = ref(null);
    const total_weight = computed(() => {
      return weighing.value[0]?.rental_parking.reduce(
        (acc, item) => acc + item.weight,
        0
      );
    });
    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = response.data;
      } catch (error) {
        console.log("error", error);
      }
    };
    const getSupplierName = (id) => {
      const supplier = suppliers.value.find((item) => item.id === id);
      return supplier ? supplier.name : "No Data";
    };
    const weighing_data = ref([]);
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchWeighing()]);
    };

    const authUserId = ref(null);

    onMounted(async () => {
      console.log("datatoedit", props.datatoedit.id);
      await Promise.all([fetchWeighing(), fetchSupplier(), fetchParking()]);
      parking_fill.value = props.datatoedit.rental_parking;
      weighing_data.value = props.datatoedit;
      socket.on("database_realTime", handleDatabaseUpdate);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.doc_id) {
        console.log("doc_id", props.doc_id);
      }
      console.log("parking", props.datatoedit.rental_parking);
    });
    const handleReset = (data, parking_id) => {
      outerVisible.value = true;
      currentComponent.value = "ResetTruckForm";
      weighing_id.value = data;
      weighing_data.value = props.datatoedit;
      isId_of_weighing.value = props.datatoedit.id;
      parking_fill_of_parking_id.value = parking_id;
      boat_name.value = data;
      console.log(
        "parking_fill_of_parking_id",
        parking_fill_of_parking_id.value
      );
    };
    const note = ref("");
    const cost = ref(null);

    const total_cost = computed(() => {
      return cost.value * total_weight.value;
    });
    const isValidateCost = ref("");
    const validation = () => {
      if (cost.value === null || !cost.value) {
        isValidateCost.value = "Please enter the unit price";
        return false;
      } else {
        isValidateCost.value = "";
        return true;
      }
    };
    watch(cost, () => {
      validation();
    });

    const updateParkingRice = async (parkingId) => {
      try {
        const request = {
          tableName: "parking_rice",
          fields: {
            is_rental: true,
            is_available: true,
          },
        };

        const response = await axios.patch(
          `${apirURL}/weighing/api/updateData/${parkingId}`,
          request
        );

        if (response.data) {
          console.log(
            `Parking rice updated successfully for parking ID ${parkingId}:`,
            response.data
          );
        } else {
          console.error(
            `Failed to update parking rice for parking ID ${parkingId}.`
          );
        }
      } catch (error) {
        console.error(
          `Error updating parking rice for parking ID ${parkingId}:`,
          error
        );
      }
    };

    const updateAllParkingRice = () => {
      const uniqueParkingIds = new Set(
        props.datatoedit.rental_parking.map((item) => item.parking_id)
      );
      uniqueParkingIds.forEach((parkingId) => {
        updateParkingRice(parkingId);
      });
    };

    const handleUpdate = async () => {
      if (!validation()) {
        return;
      }
      const timestamp = await fetchTimestamp();
      try {
        const updatedRentalParking = weighing.value[0]?.rental_parking.map(
          (item) => ({
            ...item,
            status: "Completed",
            cost: cost.value,
            total_cost: total_cost.value,
          })
        );

        const req = {
          tableName: "weighing",
          fields: {
            is_hold: true,
            rental_parking: JSON.stringify(updatedRentalParking),
            updated_by: authUserId.value,
            updated_at: timestamp,
            is_external_invoice: true,
            is_weight: false,
          },
        };

        const response = await axios.patch(
          `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
          req
        );

        if (response.data) {
          const resquest_to_service_external = {
            tableName: "service_external",
            fields: {
              parkings: JSON.stringify(updatedRentalParking),
              supplier_id: props.datatoedit.supplier_id,
              weighing_id: [props.datatoedit.id],
              weighings: JSON.stringify(weighing.value[0]),
              amount_paid: total_cost.value,
              service_external_type: "parking_rental",
              note: note.value,
              weight_type: "kg",
              phone_number: props.datatoedit.phone_number,
              price: cost.value,
              total_weight: total_weight.value,
              created_by: authUserId.value,
              created_at: timestamp,
              status: "Completed",
            },
          };
          const res = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            resquest_to_service_external
          );
          console.log("res", res.data);
          if (res.data) {
            updateAllParkingRice();
          }
          handleClose();
          emit("toast", "update");
        }
        console.log("req", req);
      } catch (error) {
        console.log("error", error);
      }
    };

    const handleSaveAndPrint = async () => {
      if (!validation()) {
        return;
      }

      const timestamp = await fetchTimestamp();

      try {
        // Update rental parking data
        const updatedRentalParking = weighing.value[0]?.rental_parking.map(
          (item) => ({
            ...item,
            status: "Completed",
            cost: cost.value,
            total_cost: total_cost.value,
          })
        );

        // Prepare request for updating the weighing table
        const updateWeighingRequest = {
          tableName: "weighing",
          fields: {
            is_hold: true,
            rental_parking: JSON.stringify(updatedRentalParking),
            updated_by: authUserId.value,
            updated_at: timestamp,
            is_external_invoice: true,
            is_weight: false,
          },
        };

        // Send update request to the weighing table
        const response = await axios.patch(
          `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
          updateWeighingRequest
        );

        if (response.data) {
          // Prepare request for inserting into the service_external table
          const serviceExternalRequest = {
            tableName: "service_external",
            fields: {
              parkings: JSON.stringify(updatedRentalParking),
              supplier_id: props.datatoedit.supplier_id,
              weighing_id: [props.datatoedit.id],
              weighings: JSON.stringify(weighing.value[0]),
              amount_paid: total_cost.value,
              service_external_type: "parking_rental",
              note: note.value,
              weight_type: "kg",
              phone_number: props.datatoedit.phone_number,
              price: cost.value,
              total_weight: total_weight.value,
              created_by: authUserId.value,
              created_at: timestamp,
              status: "Completed",
            },
          };

          // Send insert request to the service_external table
          const res = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            serviceExternalRequest
          );

          if (res.data) {
            // Navigate to the print page
            router.push({
              name: "printrantalparking",
              params: { id: res.data.insertedId },
            });
            updateAllParkingRice();
          }

          console.log("Service External Response:", res.data);
          emit("toast", "update");
          // handleClose(); // Uncomment if you want to close the form after saving
        }

        console.log("Weighing Update Request:", updateWeighingRequest);
      } catch (error) {
        console.error("Error during save and print operation:", error);
      }
    };
    return {
      handleClose,
      setActiveTab,
      activeTab,
      outerVisible,
      handleAdd,
      currentComponent,
      isClose,
      handleGotoPrint,
      formatDate2,
      is_completed,
      parking_fill,
      weighing_id,
      total_weight,
      handleEdit,
      weighing_data,
      weighing,
      data_of_weighing,
      parking_fill_of_parking_id,
      boat_name,
      isId_of_weighing,
      handleReset,
      getSupplierName,
      getParkingName,
      handleUpdate,
      cost,
      note,
      total_cost,
      isValidateCost,
      handleSaveAndPrint,
      mdiPencil,
      mdiRotateRight,
    };
  },
};
</script>

<style></style>
