<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[560px] h-auto bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Exchange</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div
        class="flex bg-white p-5 rounded border items-center gap-3 justify-between"
      >
        <label for="name" class="flex gap-1 text-nowrap">
          <span class="text-red-500" :class="{ 'mb-12': isSelectPartner }"
            >*</span
          >
          Partner</label
        >
        <div class="flex flex-col space-y-1">
          <Select
            :invalid="isSelectPartner"
            v-model="selectedPartner"
            :options="partnerData"
            optionLabel="name"
            optionValue="id"
            filter
            id="pro"
            showClear
            placeholder="select partner"
            checkmark
            :highlightOnSelect="true"
            class="w-full md:w-60 h-9 text-start flex items-center"
          />
          <div v-if="isSelectPartner === true" class="mt-1">
            <el-alert
              title="Please Select Partner"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>
      <div
        class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3"
      >
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500" :class="{ 'mb-12': isSelectFromWallet }"
              >*</span
            >From Wallet</label
          >
          <div class="flex flex-col space-y-1">
            <Select
              :invalid="isSelectFromWallet === true"
              v-model="selectedFromWallet"
              :options="walletData"
              optionLabel="name"
              optionValue="value"
              filter
              id="wallet"
              showClear
              placeholder="select wallet"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-60 h-9 text-start flex items-center"
            />
            <div v-if="isSelectFromWallet === true" class="">
              <el-alert
                title="Please Select Wallet"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="flex items-center justify-between gap-3">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Amount
            </label>
            <div class="flex items-start gap-2">
              <InputNumber
                :invalid="isFromAmount === true"
                class="md:w-56 h-1 mt-2 text-start flex items-center"
                style="width: 240px"
                placeholder="Amount"
                :maxFractionDigits="2"
                :min="1"
                v-model="amountFromWallet"
                step="0.01"
              />
            </div>
          </div>

          <!-- Adjust this part for el-alert positioning -->
          <div
            v-if="isFromAmount === true"
            class="flex justify-end mt-5 text-center"
          >
            <el-alert
              title="Please Enter Amount"
              class="whitespace-nowrap mt-2"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>

      <div
        class="w-full items-center gap-3 bg-white py-10 px-5 rounded border space-y-3"
      >
        <div class="flex items-center justify-between gap-3">
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500" :class="{ 'mb-12': isSelectToWallet }"
              >*</span
            >
            To Wallet</label
          >

          <div class="flex flex-col space-y-1">
            <Select
              :invalid="isSelectToWallet === true"
              v-model="selectedToWallet"
              :options="walletData"
              optionLabel="name"
              optionValue="value"
              filter
              id="wallet"
              showClear
              placeholder="select wallet"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-60 h-9 text-start flex items-center"
            />
            <div v-if="isSelectToWallet === true" class="mt-1">
              <el-alert
                title="Please Select Wallet"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Amount
            </label>
            <InputNumber
              class="md:w-56 h-1 mt-3 text-start flex items-center"
              style="width: 240px"
              placeholder="Amount"
              :maxFractionDigits="2"
              :min="1"
              v-model="amountTowallet"
              step="0.01"
            />
          </div>
          <div
            v-if="isToAmount === true"
            class="flex justify-end mt-5 text-center"
          >
            <el-alert
              title="Please Enter Amount"
              class="whitespace-nowrap mt-2"
              type="error"
              :closable="false"
            />
          </div>
        </div>

        <div class="flex items-center gap-3 justify-between space-y-4">
          <label for="name" class="text-start mt-5">
            Exchange Rate (KHR-VND)</label
          >
          <InputNumber
            class="md:w-56 h-1 text-start flex items-center"
            style="width: 240px"
            placeholder="Exchange Rate"
            :maxFractionDigits="3"
            step="0.001"
            v-model="exchangeRate"
          />
        </div>
      </div>

      <div class="flex w-full items-center gap-3">
        <button type="submit">Save</button>
        <button class="btncancel" type="button" @click="handleSaveNew">
          Save & New
        </button>
        <button class="btncancel" type="button" @click="handleClose">
          Cencel
        </button>
      </div>
    </div>
  </form>

  <component
    :is="currentComponent"
    :showToast="showToast"
    :informMessage="informMessage"
    @onClose="handleClearToast"
  />
</template>

<script>
import { validateInputNUmber } from "@/composables/ValidateInput";
import { add, update } from "@/composables/usePartnerTransaction";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { decodeJwt } from "@/composables/decodeJWT";
import { ref, onMounted, watch } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import ToastVue from "@/components/ToastVue.vue";
import { calculateBalance } from "@/utils/calculateWallet";
import numeral from "numeral";
export default {
  props: ["datatoedit"],
  components: {
    ToastVue,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const partnerData = ref([]);
    const selectedPartner = ref(null);
    const selectedFromWallet = ref(null);
    const selectedToWallet = ref(null);
    const authUserId = ref(null);
    const isSelectToWallet = ref(false);
    const isSelectFromWallet = ref(false);
    const isSelectPartner = ref(false);
    const isFromAmount = ref(false);
    const isToAmount = ref(false);

    const amountFromWallet = ref(null);
    const amountTowallet = ref(null);
    const exchangeRate = ref(null);

    const walletData = ref([
      {
        name: "KHR",
        value: "KHR",
      },
      {
        name: "VND",
        value: "VND",
      },
    ]);

    watch(amountFromWallet, (newValue) => {
      if (newValue) {
        isFromAmount.value = false;
      }
    });
    watch(amountTowallet, (newValue) => {
      if (newValue) {
        isToAmount.value = false;
      }
    });

    watch(selectedPartner, async (newV) => {
      if (newV) {
        await fetchPartnerByID(newV);
        isSelectPartner.value = false;
      }
    });

    watch(selectedFromWallet, (newV) => {
      if (newV) {
        isSelectFromWallet.value = false;
      }
    });

    watch(selectedToWallet, (newV) => {
      if (newV) {
        isSelectToWallet.value = false;
      }
    });

    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partnerData.value = response.data;
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    const partnerByIDData = ref([]);
    const fetchPartnerByID = async (id) => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partnerByIDData.value = response.data;
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    const handleClear = () => {
      selectedPartner.value = null;
      selectedFromWallet.value = null;
      selectedToWallet.value = null;
      amountFromWallet.value = null;
      amountTowallet.value = null;
      isSelectToWallet.value = false;
      isSelectFromWallet.value = false;
      isSelectPartner.value = false;
      isFromAmount.value = false;
      isToAmount.value = false;
      exchangeRate.value = null;
    };

    const handleClearToast = () => {
      currentComponent.value = "";
      informMessage.value = "";
      showToast.value = false;
    };

    const showToast = ref(false);
    const informMessage = ref("");
    const currentComponent = ref("");
    const isPending = ref(false);
    const handleSubmit = async () => {
      try {
        isPending.value = true;
        const timestamp = await fetchTimestamp();
        const walletDatas = ref([
          {
            name: "KHR",
            value: {
              id: 1,
              status: true,
              created_at: null,
              currency_name: "KHR",
              symbol_currency: "៛",
              currency_name_lowercase: "khr",
            },
          },
          {
            name: "VND",
            value: {
              id: 2,
              status: true,
              created_at: null,
              currency_name: "VND",
              symbol_currency: "₫",
              currency_name_lowercase: "vnd",
            },
          },
        ]);
        const requestBody = {
          tableName: "partner_transaction_history",
          fields: {
            partner_id: selectedPartner.value,
            transaction_type: "exchange balance",
            from_wallet: selectedFromWallet.value,
            wallet: selectedFromWallet.value,
            to_wallet: selectedToWallet.value,
            amount_before_exchange: amountFromWallet.value,
            exchange_rate: exchangeRate.value,
            amount_after_exchange: amountTowallet.value,
            from_currency:
              selectedFromWallet.value === "KHR"
                ? JSON.stringify(walletDatas.value[0].value)
                : JSON.stringify(walletDatas.value[1].value),
            to_currency:
              selectedToWallet.value === "KHR"
                ? JSON.stringify(walletDatas.value[0].value)
                : JSON.stringify(walletDatas.value[1].value),
          },
        };

        if (!selectedPartner.value) {
          isSelectPartner.value = true;
          isPending.value = false;
          return;
        } else if (!selectedFromWallet.value) {
          isSelectFromWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amountFromWallet.value) {
          isFromAmount.value = true;
          isPending.value = false;
          return;
        } else if (!selectedToWallet.value) {
          isSelectToWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amountTowallet.value) {
          isToAmount.value = true;
          isPending.value = false;
          return;
        } else if (selectedFromWallet.value === selectedToWallet.value) {
          currentComponent.value = "ToastVue";
          informMessage.value = "From wallet and to wallet is same";
          showToast.value = true;
          isPending.value = false;
          return;
        } else {
          if (partnerByIDData.value.length > 0) {
            const wallet = partnerByIDData.value[0]?.wallet[0];
            const balancePartner = calculateBalance(
              wallet,
              selectedPartner.value && selectedFromWallet.value === "KHR"
                ? "KH"
                : "VN"
            );
            if (amountFromWallet.value > balancePartner) {
              currentComponent.value = "ToastVue";
              informMessage.value = `Amount Exchange must be less or equal than remaining balance [${numeral(
                balancePartner
              ).format("0,0")} ${
                selectedFromWallet.value === "KHR" ? "៛" : "₫"
              }] `;
              showToast.value = true;
              isPending.value = false;
              return;
            } else {
              if (props.datatoedit) {
                requestBody.fields.updated_at = timestamp;
                requestBody.fields.updated_by = authUserId.value;
                const response = await axios.patch(
                  `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
                  requestBody
                );
                if (response.data.message) {
                  update(props.datatoedit, requestBody.fields);
                  handleClose();
                  handleClear();
                  emit("toast", "update");
                }
              } else {
                requestBody.fields.created_at = timestamp;
                requestBody.fields.created_by = authUserId.value;

                const response = await axios.post(
                  `${apirURL}/weighing/api/insertData`,
                  requestBody
                );

                if (response.data.message) {
                  add(
                    requestBody.fields,
                    "exchange balance",
                    response.data.insertedId
                  );
                  emit("close");
                  emit("toast", "create");
                }
              }
            }
          }
        }
      } catch (err) {
        console.log("failed to submit data", err);
      }
    };

    const handleSaveNew = async () => {
      try {
        isPending.value = true;
        const timestamp = await fetchTimestamp();
        const walletDatas = ref([
          {
            name: "KHR",
            value: {
              id: 1,
              status: true,
              created_at: null,
              currency_name: "KHR",
              symbol_currency: "៛",
              currency_name_lowercase: "khr",
            },
          },
          {
            name: "VND",
            value: {
              id: 2,
              status: true,
              created_at: null,
              currency_name: "VND",
              symbol_currency: "₫",
              currency_name_lowercase: "vnd",
            },
          },
        ]);

        const requestBody = {
          tableName: "partner_transaction_history",
          fields: {
            partner_id: selectedPartner.value,
            transaction_type: "exchange balance",
            from_wallet: selectedFromWallet.value,
            wallet: selectedFromWallet.value,
            to_wallet: selectedToWallet.value,
            amount_before_exchange: amountFromWallet.value,
            exchange_rate: exchangeRate.value,
            from_currency:
              selectedFromWallet.value === "KHR"
                ? JSON.stringify(walletDatas.value[0].value)
                : JSON.stringify(walletDatas.value[1].value),
            to_currency:
              selectedToWallet.value === "KHR"
                ? JSON.stringify(walletDatas.value[0].value)
                : JSON.stringify(walletDatas.value[1].value),
            amount_after_exchange: amountTowallet.value,
          },
        };

        if (!selectedPartner.value) {
          isSelectPartner.value = true;
          isPending.value = false;
          return;
        } else if (!selectedFromWallet.value) {
          isSelectFromWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amountFromWallet.value) {
          isFromAmount.value = true;
          isPending.value = false;
          return;
        } else if (!selectedToWallet.value) {
          isSelectToWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amountTowallet.value) {
          isToAmount.value = true;
          isPending.value = false;
          return;
        } else if (selectedFromWallet.value === selectedToWallet.value) {
          currentComponent.value = "ToastVue";
          informMessage.value = "From wallet and to wallet is same";
          showToast.value = true;
          isPending.value = false;
          return;
        } else {
          if (partnerByIDData.value.length > 0) {
            const wallet = partnerByIDData.value[0]?.wallet[0];
            const balancePartner = calculateBalance(
              wallet,
              selectedPartner.value && selectedFromWallet.value === "KHR"
                ? "KH"
                : "VN"
            );

            if (amountFromWallet.value > balancePartner) {
              currentComponent.value = "ToastVue";
              informMessage.value = `Amount Exchange must be less or equal than remaining balance [${numeral(
                balancePartner
              ).format("0,0")} ${
                selectedFromWallet.value === "KHR" ? "៛" : "₫"
              }] `;
              showToast.value = true;
              isPending.value = false;
              return;
            } else {
              if (props.datatoedit) {
                requestBody.fields.updated_at = timestamp;
                requestBody.fields.updated_by = authUserId.value;
                const response = await axios.patch(
                  `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
                  requestBody
                );
                if (response.data.message) {
                  update(props.datatoedit, requestBody.fields);

                  handleClear();
                  emit("toast", "update");
                }
              } else {
                requestBody.fields.created_at = timestamp;
                requestBody.fields.created_by = authUserId.value;

                const response = await axios.post(
                  `${apirURL}/weighing/api/insertData`,
                  requestBody
                );

                if (response.data.message) {
                  add(
                    requestBody.fields,
                    "exchange balance",
                    response.data.insertedId
                  );

                  emit("toast", "create");
                }
              }
            }
          }
        }
      } catch (err) {
        console.log("failed to submit data", err);
      }
    };

    onMounted(async () => {
      await fetchPartner();
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.datatoedit) {
        setTimeout(() => {
          selectedPartner.value = props.datatoedit
            ? props.datatoedit.partner_id
            : null;
        }, 100);
        selectWalletFrom.value = props.datatoedit.from_wallet;
        amountFromWallet.value = props.datatoedit.amount_after_exchange;
        amountTowallet.value = props.datatoedit.amount_before_exchange;
        exchangeRate.value = props.datatoedit.exchange_rate;
        selectWalletTo.value = props.datatoedit.to_wallet;
      }
    });

    return {
      handleSaveNew,
      handleClearToast,
      showToast,
      informMessage,
      currentComponent,
      isPending,
      isSelectToWallet,
      isSelectFromWallet,
      isSelectPartner,
      isFromAmount,
      isToAmount,
      walletData,
      handleClose,
      validateInputNUmber,
      partnerData,
      handleSubmit,
      selectedPartner,
      selectedToWallet,
      selectedFromWallet,
      amountTowallet,
      amountFromWallet,
      exchangeRate,
    };
  },
};
</script>

<style></style>
