<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[450px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ isEdit ? "Update Customer" : "New Customer" }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center gap-3 justify-between">
        <label for="name" class="flex gap-1"
          ><span class="text-red-500">*</span> Name</label
        >
        <input
          class="w-[250px]"
          required
          type="text"
          v-model="name"
          placeholder="Enter Name"
        />
      </div>
      <div class="flex items-center gap-3 justify-between text-nowrap">
        <label for="phone_number" class="flex gap-1"
          ><span class="text-red-500"></span> Phone Number</label
        >
        <input
          class="w-[250px]"
          type="text"
          v-model="phone_number"
          placeholder="Phone Number"
          @keydown="formatPhoneNumber"
        />
      </div>

      <div class="flex w-full items-center gap-3">
        <button type="submit" v-if="isAdding === true" :disabled="isAdding">
          Saving
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path></svg
          ></span>
        </button>
        <button v-else>{{ isUpdate ? "Update" : "Save" }}</button>
        <button
          type="button"
          class="btncancel"
          @click="SaveAndNew"
          :disabled="isUpdatig"
          v-if="isUpdatig === true"
        >
          Saving
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="#168AF4"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </span>
        </button>
        <button v-else @click="SaveAndNew" type="button" class="btncancel">
          {{ isUpdate ? "Update & New" : "Save & New" }}
        </button>
        <button type="button" class="btncancel" @click="handleClose">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import apirURL from "@/services/apiURL";
import axios from "axios";
import socket from "@/services/socket";
import { formatPhoneNumber } from "@/composables/ValidateInput";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const name = ref("");
    const phone_number = ref("");
    const status = ref(true);
    const isUpdatig = ref(false);
    const isAdding = ref(false);
    const handleClose = () => {
      emit("close");
    };

    const handleClear = () => {
      name.value = "";
      phone_number.value = "";
      status.value = "";
    };
    const currencies = ref([]);
    const fetchCurrency = async () => {
      try {
        const req = {
          tableName: "currencies",
          sortColumn: "id",
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        currencies.value = res.data;
      } catch (error) {
        console.log(error);
      }
    };
    const handleDatabaseUpdate = () => {
      socket.on("database_realTime", handleDatabaseUpdate);
      fetchCurrency();
    };
    onUnmounted(() => {
      socket.off("database_realTime", handleDatabaseUpdate);
    });

    const handleSubmit = async () => {
      isAdding.value = true;

      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "customers",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            phone_number: phone_number.value,
            status: status.value,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          requestBody.fields.updated_at = timestamp;
          requestBody.fields.updated_by = authUserId.value;

          if (
            walletCustomer.value.customer_invoice_owe.original_customer_owe
              .lenght <= 0 &&
            walletCustomer.value.customer_repayment.original_customer_owe
              .lenght <= 0
          ) {
            requestBody.fields.wallet = {
              customer_invoice_owe: {
                original_customer_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
              },

              customer_repayment: {
                original_customer_owe: [], // sign -  // note [{"customer_repayment_id":"","amount":}]
              },
            };
          }
          await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );

          emit("toast", "update");
          handleClear();
          handleClose();
        } else {
          requestBody.fields.created_at = timestamp;
          requestBody.fields.created_by = authUserId.value;
          requestBody.fields.wallet = {
            customer_invoice_owe: {
              original_customer_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
            },

            customer_repayment: {
              original_customer_owe: [], // sign -  // note [{"customer_repayment_id":"","amount":}]
            },
          };

          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );

          emit("toast", "create");
          handleClear();
          handleClose();
        }
        emit("close");
      } catch (error) {
        console.error(error);
      }
    };
    const walletCustomer = ref({});
    const authUserId = ref(null);

    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }

      if (props.datatoedit) {
        name.value = props.datatoedit.name || "";
        phone_number.value = props.datatoedit.phone_number || "";
        status.value =
          props.datatoedit.status !== undefined
            ? props.datatoedit.status
            : true;
        walletCustomer.value = props.datatoedit.wallet;
      }
      fetchCurrency();
    });

    const isEdit = props.datatoedit;

    // save and new
    const SaveAndNew = async () => {
      setTimeout(() => {
        isUpdatig.value = true;
        setTimeout(() => {
          isUpdatig.value = false;
        }, 1000);
      }, 0);
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "customers",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            phone_number: phone_number.value,
            status: status.value,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          requestBody.fields.updated_at = timestamp;
          requestBody.fields.updated_by = authUserId.value;

          if (
            walletCustomer.value.customer_invoice_owe.original_customer_owe
              .lenght <= 0 &&
            walletCustomer.value.customer_repayment.original_customer_owe
              .lenght <= 0
          ) {
            requestBody.fields.wallet = {
              customer_invoice_owe: {
                original_customer_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
              },

              customer_repayment: {
                original_customer_owe: [], // sign -  // note [{"customer_repayment_id":"","amount":}]
              },
            };
          }
          await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );

          emit("toast", "update");
          handleClear();
        } else {
          requestBody.fields.created_at = timestamp;
          requestBody.fields.created_by = authUserId.value;
          requestBody.fields.wallet = {
            customer_invoice_owe: {
              original_customer_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
            },

            customer_repayment: {
              original_customer_owe: [], // sign -  // note [{"customer_repayment_id":"","amount":}]
            },
          };

          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );

          emit("toast", "create");
          handleClear();
        }
      } catch (error) {
        console.error(error);
      }
    };
    return {
      handleClose,
      name,
      phone_number,
      status,
      handleSubmit,
      isEdit,
      SaveAndNew,
      isUpdatig,
      isAdding,
      currencies,
      formatPhoneNumber,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
