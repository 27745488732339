<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[400px] h-auto bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Deposit</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 grid grid-cols-1 gap-4">
      <div class="grid grid-cols-[120px,1fr] items-center">
        <div class="flex gap-1" :class="{ 'mb-12': isSelectPartner }">
          <span class="font-semibold text-red-500">*</span>
          <label for="pro" class="font-semibold">Partner</label>
        </div>
        <div>
          <Select
            :invalid="isSelectPartner"
            v-model="selectedPartner"
            :options="partnerData"
            optionLabel="name"
            optionValue="id"
            filter
            id="pro"
            showClear
            placeholder="select partner"
            checkmark
            :highlightOnSelect="true"
            class="w-full md:w-56 h-9 text-start flex items-center"
          />

          <div v-if="isSelectPartner" class="mt-1">
            <el-alert
              title="Please Select Partner"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[120px,1fr] items-center">
        <div class="flex gap-1" :class="{ 'mb-12': isSelectWallet }">
          <span class="font-semibold text-red-500">*</span>
          <label for="wallet" class="font-semibold">Wallet</label>
        </div>
        <div>
          <Select
            :invalid="isSelectWallet"
            v-model="selectedWallet"
            :options="walletData"
            optionLabel="name"
            optionValue="value"
            filter
            id="wallet"
            showClear
            placeholder="select wallet"
            checkmark
            :highlightOnSelect="true"
            class="w-full md:w-56 h-9 text-start flex items-center"
            :class="{ 'mb-2': isSelectWallet }"
          />

          <div v-if="isSelectWallet" class="mt-1">
            <el-alert
              title="Please Select Wallet"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-[120px,1fr] items-center mt-1">
        <div class="flex gap-1" :class="{ 'mb-12': isAmount }">
          <span class="font-semibold text-red-500">*</span>
          <label for="am" class="font-semibold">Amount</label>
        </div>
        <div>
          <InputNumber
            v-model="amount"
            :invalid="isAmount"
            :maxFractionDigits="2"
            inputId="am"
            placeholder="amount"
            class="w-full md:w-56 h-4 text-start flex items-center"
          />

          <div v-if="isAmount" class="mt-3">
            <el-alert
              title="Please Enter Amount"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>

      <div class="flex w-full items-center gap-3">
        <button type="submit">Save</button>
        <button class="btncancel" type="button" @click="handleSaveNew">
          Save & New
        </button>
        <button class="btncancel" type="button" @click="handleClose">
          Cencel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { decodeJwt } from "@/composables/decodeJWT";
import { add, update } from "@/composables/usePartnerTransaction";
import { validateInputNUmber } from "@/composables/ValidateInput";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    const partnerData = ref([]);
    const selectedPartner = ref(null);
    const walletByPartner = ref([]);
    const selectedWallet = ref(null);
    const authUserId = ref(null);
    const isSelectPartner = ref(false);
    const isSelectWallet = ref(false);
    const amount = ref(null);
    const isPending = ref(false);
    const isAmount = ref(false);
    const isAmountError = ref(false);

    const walletData = ref([
      {
        name: "KHR",
        value: "KHR",
      },
      {
        name: "VND",
        value: "VND",
      },
    ]);

    watch(
      amount,
      (newValue) => {
        isAmount.value = newValue > 0 ? false : undefined;
      },
      { flush: "post" }
    );

    watch(selectedWallet, (newV) => {
      if (newV) {
        isSelectWallet.value = false;
      }
    });

    watch(selectedPartner, (newV) => {
      if (newV) {
        isSelectPartner.value = false;
      }
    });

    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partnerData.value = response.data;
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        if (!selectedPartner.value) {
          isSelectPartner.value = true;
          isPending.value = false;
          return;
        } else if (!selectedWallet.value) {
          isSelectWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amount.value) {
          isAmount.value = true;
          isPending.value = false;
          return;
        } else if (amount.value && amount.value <= 0) {
          isAmount.value = false;
          currentComponent.value = "ErrorTost";
          informMessage.value = "Amount Deposit must be greater than 0";
          isPending.value = false;
          return;
        } else {
          const timestamp = await fetchTimestamp();

          const walletDatas = ref([
            {
              name: "KHR",
              value: {
                id: 1,
                status: true,
                created_at: null,
                currency_name: "KHR",
                symbol_currency: "៛",
                currency_name_lowercase: "khr",
              },
            },
            {
              name: "VND",
              value: {
                id: 2,
                status: true,
                created_at: null,
                currency_name: "VND",
                symbol_currency: "₫",
                currency_name_lowercase: "vnd",
              },
            },
          ]);

          const requestBody = {
            tableName: "partner_transaction_history",
            fields: {
              partner_id: selectedPartner.value,
              partner_id: selectedPartner.value ? selectedPartner.value : null,
              transaction_type: "deposit",
              wallet: selectedWallet.value,
              currency:
                selectedWallet.value === "KHR"
                  ? JSON.stringify(walletDatas.value[0].value)
                  : JSON.stringify(walletDatas.value[1].value),
              amount: amount.value > 0 ? amount.value : 0,
            },
          };

          if (props.datatoedit && props.datatoedit.id) {
            requestBody.fields.updated_at = timestamp;
            requestBody.fields.updated_by = authUserId.value;
            const response = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              requestBody
            );
            if (response.data.message) {
              update(props.datatoedit, requestBody.fields);
              handleClose();
              handleClear();
              emit("toast", "update");
            }
          } else {
            requestBody.fields.created_at = timestamp;
            requestBody.fields.created_by = authUserId.value;
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              requestBody
            );

            if (response.data.message) {
              add(requestBody.fields, "deposit", response.data.insertedId);
              isPending.value = false;
              handleClose();
              handleClear();
              emit("toast", "create");
            }
          }
        }
      } catch (err) {
        isPending.value = false;
        console.log("failed to submit data", err);
      }
    };

    const handleSaveNew = async () => {
      try {
        console.log("handle save new");

        isPending.value = true;
        if (!selectedPartner.value) {
          isSelectPartner.value = true;
          isPending.value = false;
          return;
        } else if (!selectedWallet.value) {
          isSelectWallet.value = true;
          isPending.value = false;
          return;
        } else if (!amount.value) {
          isAmount.value = true;
          isPending.value = false;
          return;
        } else if (amount.value && amount.value <= 0) {
          isAmount.value = false;
          currentComponent.value = "ErrorTost";
          informMessage.value = "Amount Deposit must be greater than 0";
          isPending.value = false;
          return;
        } else {
          const timestamp = await fetchTimestamp();
          const walletDatas = ref([
            {
              name: "KHR",
              value: {
                id: 1,
                status: true,
                created_at: null,
                currency_name: "KHR",
                symbol_currency: "៛",
                currency_name_lowercase: "khr",
              },
            },
            {
              name: "VND",
              value: {
                id: 2,
                status: true,
                created_at: null,
                currency_name: "VND",
                symbol_currency: "₫",
                currency_name_lowercase: "vnd",
              },
            },
          ]);

          const requestBody = {
            tableName: "partner_transaction_history",
            fields: {
              partner_id: selectedPartner.value,
              partner_id: selectedPartner.value ? selectedPartner.value : null,
              transaction_type: "deposit",
              wallet: selectedWallet.value,
              currency:
                selectedWallet.value === "KHR"
                  ? JSON.stringify(walletDatas.value[0].value)
                  : JSON.stringify(walletDatas.value[1].value),
              amount: amount.value > 0 ? amount.value : 0,
            },
          };

          if (props.datatoedit && props.datatoedit.id) {
            requestBody.fields.updated_at = timestamp;
            requestBody.fields.updated_by = authUserId.value;
            const response = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              requestBody
            );
            if (response.data.message) {
              update(props.datatoedit, requestBody.fields);

              handleClear();
              emit("toast", "update");
            }
          } else {
            requestBody.fields.created_at = timestamp;
            requestBody.fields.created_by = authUserId.value;
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              requestBody
            );

            if (response.data.message) {
              add(requestBody.fields, "deposit", response.data.insertedId);
              isPending.value = false;

              handleClear();
              emit("toast", "create");
            }
          }
        }
      } catch (err) {
        isPending.value = false;
        console.log("failed to submit data", err);
      }
    };

    const handleClear = () => {
      isSelectPartner.value = false;
      isSelectWallet.value = false;
      isAmount.value = false;

      amount.value = null;
      selectedPartner.value = null;
      selectedWallet.value = null;
    };

    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);

      if (props.datatoedit) {
        setTimeout(() => {
          selectedPartner.value = props.datatoedit
            ? props.datatoedit.partner_id
            : null;
        }, 100);
        amount.value = props.datatoedit.amount;

        selectedWallet.value = props.datatoedit.wallet;
      }

      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      await fetchPartner();
    });

    return {
      handleSaveNew,
      isPending,
      isSelectPartner,
      isAmount,
      isSelectWallet,
      walletData,
      selectedWallet,
      handleSubmit,
      walletByPartner,
      selectedPartner,
      partnerData,
      handleClose,
      validateInputNUmber,
      amount,
      isAmount,
    };
  },
};
</script>
