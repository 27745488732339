<template>
  <div class="w-full overflow-hidden">
    <!-- Content based on activeTab -->
    <div v-if="activeTab === 'weighing'">
      <WeighingList />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import WeighingList from "./WeighingList.vue";
import FromTruckListVue from "../admin/FromTruckList.vue";
import ToParkingListVue from "./ToParkingList.vue";
import { useRouter } from "vue-router";
export default {
  components: {
    WeighingList,
    FromTruckListVue,
    ToParkingListVue,
  },
  setup() {
    const activeTab = ref("weighing");
    const router = useRouter();
    const setActiveTab = (tabName, path) => {
      activeTab.value = tabName;
      router.push(path);
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>
