//use preloan
import apirURL from "@/services/apiURL";
import axios from "axios";

const fetchSupplierByID = async (supplierId) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/getAllData`;
    const params = {
      tableName: "suppliers",
      sortColumn: "id",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: supplierId,
          typeTable: "table",
        },
      ]),
    };

    const response = await axios.get(apiUrl, { params });
    return response.data[0]?.wallet;
  } catch (error) {
    console.error("Error fetching supplier data:", error);
    return null;
  }
};

const fetchPartnerTransactionbyPreloanId = async (preloanId) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/getAllData`;
    const params = {
      tableName: "partner_transaction_history",
      sortColumn: "id",
      dynamicConditions: JSON.stringify([
        {
          field: "preloan_id",
          operator: "=",
          value: preloanId,
          typeTable: "table",
        },
      ]),
    };

    const response = await axios.get(apiUrl, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching supplier data:", error);
    return null;
  }
};



const fetchPartnerByID = async (id) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/getAllData`;
    const params = {
      tableName: "partners",
      sortColumn: "id",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: id,
          typeTable: "table",
        },
      ]),
    };

    const response = await axios.get(apiUrl, { params });
    return response.data[0]?.wallet[0];
  } catch (error) {
    console.error("Error fetching supplier data:", error);
    return null;
  }
};




const fetchCurrencyById = async (id) => {
  try {
    const req = {
      tableName: "currencies",
      sortColumn: "id",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: id,
          typeTable: "table",
        },
      ]),
    };

    const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
      params: req,
    });


    return res.data[0];

  } catch (error) {
    console.log(error);
  }
};


const updateSupplierWallet = async (supplierId, newWalletAmount) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/updateData/${supplierId}`;
    const requestBody = {
      tableName: "suppliers",
      fields: {
        wallet: newWalletAmount,
      },
    };
    const response = await axios.patch(apiUrl, requestBody);
    return response ? response.data.message : '';
  } catch (error) {
    console.error("Error updating supplier wallet:", error);
  }
};

const updatePartnerWallet = async (partnerId, newWalletAmount) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/updateData/${partnerId}`;
    const requestBody = {
      tableName: "partners",
      fields: {
        wallet: JSON.stringify(newWalletAmount),
      },
    };
    const response = await axios.patch(apiUrl, requestBody);
    return response ? response.data.message : '';
  } catch (error) {
    console.error("Error updating supplier wallet:", error);
  }
};


export const addPreloan = async (formDoc, preloan_id) => {

  try {
    let partnerPreloan = Number(formDoc.amount);
    let supplierPreloan = Number(formDoc.amount);


    if (formDoc.get_preloan_by === 'partner') {
      const currency = JSON.parse(formDoc.currency)

      // Use Promise.all to run all fetch operations in parallel
      const [walletSupplier, walletPartner, currencyData] = await Promise.all([
        fetchSupplierByID(formDoc.supplier_id),
        fetchPartnerByID(formDoc.partner_id),
        fetchCurrencyById(currency.id),

      ]);

      console.log("currencyData", currencyData);




      //part partner
      if (currencyData.symbol_currency === '៛') {
        walletPartner.KH.preloan['original_partner_owe'].push({
          preloan_id: preloan_id ? preloan_id : null,
          amount: partnerPreloan * -1
        });
      }




      // part supplier
      walletSupplier.preloan['original_supplier_owe'].push({
        preloan_id: preloan_id ? preloan_id : null,
        amount: supplierPreloan
      })




      let partnerWallet = [];
      partnerWallet.push(walletPartner);


      // update wallet supplier and partner
      await Promise.allSettled([
        updateSupplierWallet(formDoc.supplier_id, walletSupplier),
        updatePartnerWallet(formDoc.partner_id, partnerWallet)
      ]);



      // add preloan to partner_transaction_history
      const requestBody = {
        tableName: "partner_transaction_history",
        fields: {
          partner_id: formDoc.partner_id,
          amount: formDoc.amount,
          wallet: currency.symbol_currency === "៛" ? "KHR" : currency.symbol_currency === "đ" ? "VND" : currency.symbol_currency === "฿" ? "THB" : "USD",
          preloan_id: preloan_id ? preloan_id : null,
          transaction_type: "preloan",
          currency: JSON.stringify(currency),
          created_at: formDoc.created_at,
          created_by: formDoc.created_by
        }
      }

      const success = await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);

      console.log("insert to partner_transaction_history", success);

      return {
        supplierWallet: walletSupplier,
        partnerWallet: partnerWallet
      }
    } else {

      // Use Promise.all to run all fetch operations in parallel
      const [walletSupplier] = await Promise.all([
        fetchSupplierByID(formDoc.supplier_id),
        // fetchPartnerByID(formDoc.partner_id),
        // fetchCurrencyById(currency.id),

      ]);










      // part supplier
      walletSupplier.preloan['original_supplier_owe'].push({
        preloan_id: preloan_id ? preloan_id : null,
        amount: supplierPreloan
      })




      // update wallet supplier and partner
      await Promise.allSettled([
        updateSupplierWallet(formDoc.supplier_id, walletSupplier),

      ]);



      return {
        supplierWallet: walletSupplier,

      }
    }

  } catch (err) {
    console.log("failed to add preloan", err)
  }


}


export const updatePreloan = async (oldDoc, NewDoc) => {
  try {
    console.log("oldDoc", oldDoc);
    console.log("NewDoc", NewDoc);
    let newPartnerPreloan = Number(NewDoc.amount);
    const currency = JSON.parse(NewDoc.currency)

    // Use Promise.all to run all fetch operations in parallel
    const [oldwalletSupplier, oldwalletPartner, oldcurrencyData, oldPartnerTransaction] = await Promise.all([
      fetchSupplierByID(oldDoc.supplier_id),
      fetchPartnerByID(oldDoc.partner_id),
      fetchCurrencyById(oldDoc.currency?.id),
      fetchPartnerTransactionbyPreloanId(oldDoc.preloan_id)
    ]);







    //update old supplier
    const preloanIndex = oldwalletSupplier.preloan['original_supplier_owe'].findIndex((p) => p.preloan_id === oldDoc.preloan_id)
    if (preloanIndex !== -1) {
      // If the object is found, remove it from the array
      oldwalletSupplier.preloan['original_supplier_owe'].splice(preloanIndex, 1);
    }


    //update old partner
    let oldWalletPartner = [];
    let NewWalletPartner = [];



    if (oldDoc.get_preloan_by === "partner") {
      if (oldcurrencyData.symbol_currency === '៛') {
        const preloanIndex = oldwalletPartner.KH.preloan['original_partner_owe'].findIndex((p) => p.preloan_id === oldDoc.preloan_id);

        if (preloanIndex !== -1) {
          // If the object is found, remove it from the array
          oldwalletPartner.KH.preloan['original_partner_owe'].splice(preloanIndex, 1);
        }


        oldWalletPartner.push(oldwalletPartner)

      }
    }








    const successOldSup = await updateSupplierWallet(oldDoc.supplier_id, oldwalletSupplier);
    if (successOldSup) {
      const [newwalletSupplier] = await Promise.all([
        fetchSupplierByID(NewDoc.supplier_id),
      ]);

      //update new supplier

      newwalletSupplier.preloan['original_supplier_owe'].push({
        preloan_id: oldDoc.preloan_id,
        amount: NewDoc.amount
      })

      await updateSupplierWallet(NewDoc.supplier_id, newwalletSupplier);

    }





    if (oldDoc.get_preloan_by === "partner") {

      console.log("part old doc get preloan by partner")

      await updatePartnerWallet(oldDoc.partner_id, oldWalletPartner)

      if (oldPartnerTransaction.length > 0) {
        const success = await axios.delete(`${apirURL}/weighing/api/deleteData/${oldPartnerTransaction[0].id}`, {
          data: { tableName: "partner_transaction_history" },
        });

        console.log("delete from partner_transaction_history", success);

      }
    }


    //update new
    const [newwalletPartner, newcurrencyData] = await Promise.all([
      fetchPartnerByID(NewDoc.partner_id),
      fetchCurrencyById(currency.id)
    ]);



    if (NewDoc.get_preloan_by === "partner") {
      console.log("part newDoc get_preloan_by partner")
      if (newcurrencyData.symbol_currency === '៛') {
        newwalletPartner.KH.preloan['original_partner_owe'].push({
          preloan_id: oldDoc.preloan_id,
          amount: newPartnerPreloan * -1
        });

        NewWalletPartner.push(newwalletPartner);

        await updatePartnerWallet(NewDoc.partner_id, NewWalletPartner)



        // add preloan to partner_transaction_history
        const requestBody = {
          tableName: "partner_transaction_history",
          fields: {
            partner_id: NewDoc.partner_id,
            amount: NewDoc.amount,
            wallet: currency.symbol_currency === "៛" ? "KHR" : currency.symbol_currency === "đ" ? "VND" : currency.symbol_currency === "฿" ? "THB" : "USD",
            preloan_id: oldDoc.preloan_id,
            transaction_type: "preloan",
            currency: JSON.stringify(currency),
            created_at: NewDoc.updated_at,
            created_by: NewDoc.updated_by
          }
        }

        const success = await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);

        console.log("insert to partner_transaction_history", success);

      }

    }
  } catch (err) {
    console.log("failed to update preloan", err)
  }
}





export const deletePreloan = async (oldDoc) => {
  try {





    // Use Promise.all to run all fetch operations in parallel
    const [oldwalletSupplier, oldwalletPartner, oldcurrencyData, oldPartnerTransaction] = await Promise.all([
      fetchSupplierByID(oldDoc.supplier_id),
      fetchPartnerByID(oldDoc.partner_id),
      fetchCurrencyById(oldDoc.currency?.id),
      fetchPartnerTransactionbyPreloanId(oldDoc.preloan_id)

    ]);





    //update old supplier
    const preloanIndex = oldwalletSupplier.preloan['original_supplier_owe'].findIndex((p) => p.preloan_id === oldDoc.preloan_id)
    if (preloanIndex !== -1) {
      // If the object is found, remove it from the array
      oldwalletSupplier.preloan['original_supplier_owe'].splice(preloanIndex, 1);
    }







    //update old partner
    let oldWalletPartner = [];
    if (oldDoc.get_preloan_by === "partner") {
      if (oldcurrencyData.symbol_currency === '៛') {
        const preloanIndex = oldwalletPartner.KH.preloan['original_partner_owe'].findIndex((p) => p.preloan_id === oldDoc.preloan_id);

        if (preloanIndex !== -1) {
          // If the object is found, remove it from the array
          oldwalletPartner.KH.preloan['original_partner_owe'].splice(preloanIndex, 1);
        }


        oldWalletPartner.push(oldwalletPartner)

      }

      await updatePartnerWallet(oldDoc.partner_id, oldWalletPartner)
    }



    await Promise.allSettled([
      updateSupplierWallet(oldDoc.supplier_id, oldwalletSupplier),

    ]);



    if (oldDoc.get_preloan_by === "partner") {

      if (oldPartnerTransaction.length > 0) {
        // delete preloan from partner_transaction_history
        const success = await axios.delete(`${apirURL}/weighing/api/deleteData/${oldPartnerTransaction[0].id}`, {
          data: { tableName: "partner_transaction_history" },
        });
        console.log("delete from partner_transaction_history", success);
      }

    }



  } catch (err) {
    console.log("failed to delete preloan", err);
  }

}





const fetchAllPreloanBySupplierId = async (supplierId) => {
  try {
    const req = {
      tableName: "preloans",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "supplier_id",
          operator: "=",
          value: supplierId,
          typeTable: "table",
        },
        {
          field: "status_paid",
          operator: "!=",
          value: 'paid',
          typeTable: "table",
        }
      ]),
    };

    const res = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });


    return res.data;

  } catch (error) {
    console.log(error);
  }
};




const fetchAllPreloanByInvoiceId = async (supplierInvoiceId) => {
  try {
    const req = {
      tableName: "preloans",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "supplier_invoice_id",
          operator: "&&",
          type: 'integer',
          value: [supplierInvoiceId],
          typeTable: "table",
        },

      ]),
    };

    const res = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });


    return res.data;

  } catch (error) {
    console.log(error);
  }
};






export const cutPreloan = async (formDoc, supplier_invoice_id, amountPaid) => {
  try {
    // Fetch all preloans sorted by oldest first
    const preloanData = await fetchAllPreloanBySupplierId(formDoc.supplier_id);
    const walletSupplier = await fetchSupplierByID(formDoc.supplier_id);

    if (preloanData.length > 0) {
      // Initialize remainingAmount with the amount paid by the user
      let remainingAmount = amountPaid;
      console.log("amount Paid", remainingAmount);

      // Variable to keep track of the total amount paid in cutPreloan
      let totalAmountPaidInCutPreloan = 0;

      for (const preloan of preloanData) {
        if (remainingAmount <= 0) break; // Stop if there's no remaining amount to apply

        // Initialize a new cutPreloan array for each preloan record
        let cutPreloan = preloan.cut_preloans || [];
        let supplierInvoiceId = preloan.supplier_invoice_id || [];

        if (preloan.amount <= remainingAmount) {
          supplierInvoiceId.push(supplier_invoice_id);
          // Fully pay off this preloan
          cutPreloan.push({
            supplier_invoice_id: supplier_invoice_id,
            amount_paid: preloan.amount,
            amount_remaining: 0,
            total_amount_owed: 0,
            status: 'paid'
          });
          remainingAmount -= preloan.amount; // Deduct paid amount
          totalAmountPaidInCutPreloan += preloan.amount; // Track the total amount paid

          // Prepare the request body for a fully paid record
          let requestBody = {
            tableName: "preloans",
            fields: {
              supplier_invoice_id: supplierInvoiceId,
              cut_preloans: JSON.stringify(cutPreloan),
              amount: 0,
              status_paid: 'paid'
            }
          };

          console.log(`Fully paid record ${preloan.id} updated`, requestBody);
          // Update the preloan record in the database
          await axios.patch(`${apirURL}/weighing/api/updateData/${preloan.id}`, requestBody);

        } else {
          supplierInvoiceId.push(supplier_invoice_id);
          // Partially pay this preloan, with some remaining balance
          cutPreloan.push({
            supplier_invoice_id: supplier_invoice_id,
            amount_paid: remainingAmount,
            amount_remaining: preloan.amount - remainingAmount,
            total_amount_owed: preloan.amount - remainingAmount,
            status: 'partial'
          });

          totalAmountPaidInCutPreloan += remainingAmount; // Track the partial payment
          remainingAmount = 0; // No remaining amount after partial payment

          // Prepare the request body for a partially paid record
          let requestBody = {
            tableName: "preloans",
            fields: {
              supplier_invoice_id: supplierInvoiceId,
              cut_preloans: JSON.stringify(cutPreloan),
              amount: preloan.amount - remainingAmount,
              status_paid: 'partial'
            }
          };

          console.log(`Partially paid record ${preloan.id} updated`, requestBody);
          // Update the preloan record in the database
          await axios.patch(`${apirURL}/weighing/api/updateData/${preloan.id}`, requestBody);
        }
      }

      // After the loop, update the supplier wallet with the total amount paid in cutPreloan
      walletSupplier.supplier_invoice_cut_preloan['original_supplier_owe'].push({
        invoice_id: supplier_invoice_id ? supplier_invoice_id : null,
        amount: totalAmountPaidInCutPreloan * -1
      });

      console.log("Updated walletSupplier with total amount paid", walletSupplier.supplier_invoice_cut_preloan);

      await updateSupplierWallet(formDoc.supplier_id, walletSupplier);

    }

  } catch (err) {
    console.log("Failed to cut preloan:", err);
  }
};







export const deleteCutPreloan = async (supplierInvoiceId, supplierId) => {
 

  try {
    const [preloanAssoicateSupplierInvoice, walletSupplier] = await Promise.allSettled([
      fetchAllPreloanByInvoiceId(supplierInvoiceId),
      fetchSupplierByID(supplierId),
    ]);

   
 
    if (preloanAssoicateSupplierInvoice.value.length > 0) {
      for (const preloan of preloanAssoicateSupplierInvoice.value) {
        let cutPreloan = preloan.cut_preloans || [];

        let supplierInvoiceIdArray = preloan.supplier_invoice_id || [];

        let cutPreloanIndex = cutPreloan.findIndex((cut) => cut.supplier_invoice_id === supplierInvoiceId);

        console.log("supplier Invoice Id Array before",supplierInvoiceIdArray)
        console.log("cut preloans before",cutPreloan)
        let index = supplierInvoiceIdArray.findIndex((id) => id === supplierInvoiceId);
        if (index !== -1) {
            supplierInvoiceIdArray.splice(index, 1);
        }

        console.log("supplier Invoice Id Array after remove",supplierInvoiceIdArray)

        if (cutPreloanIndex !== -1) {
          cutPreloan.splice(cutPreloanIndex, 1);
        }

        console.log("cut Preloan after remove",cutPreloan)
 

        const requestBody = {
          tableName: "preloans",
          fields: {
            supplier_invoice_id: supplierInvoiceIdArray,
            amount: cutPreloan[cutPreloan.length - 1].amount_remaining, // take the last length of cutPreloan remaining amount
            cut_preloans: JSON.stringify(cutPreloan),
            status_paid: cutPreloan[cutPreloan.length - 1].status // take the last length of cutPreloan status
          }
        };
    
        console.log(`preloan ${preloan.id} updated`, requestBody);

        await axios.patch(`${apirURL}/weighing/api/updateData/${preloan.id}`, requestBody);

    
      }
    }



    let supplierWallet = walletSupplier.value;
    // update supplier wallet
    if (supplierWallet) {
      //update old supplier
      const preloanIndex = supplierWallet.supplier_invoice_cut_preloan['original_supplier_owe'].findIndex((p) => p.invoice_id === supplierInvoiceId)
      if (preloanIndex !== -1) {
        // If the object is found, remove it from the array
        supplierWallet.supplier_invoice_cut_preloan['original_supplier_owe'].splice(preloanIndex, 1);
      }
      const success = await updateSupplierWallet(supplierId, supplierWallet);
      console.log("success update supplier wallet", success);

    }



  } catch (err) {
    console.log("Failed to delete cut preloan:", err);
  }

}














