<template>
  <div class="w-full relative text-primary6">
    <div :class="navClasses">
      <div>
        <h2>Warehouse List</h2>
      </div>
      <div>
        <IconField class="w-[100%] placeholder:text-body2">
          <InputText v-model="searchValue" placeholder="Search | Warehouse" />
          <InputIcon class="pi pi-search" />
        </IconField>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_warehouse?.warehouse_list?.create === true)
        "
      >
        <button @click="handleAdd">New Warehouse</button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[600px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td
              @click="sortData('id')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'id' && sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[95px] border-b text-nowrap text-start">
              Created At
            </td>
            <td
              @click="sortData('name')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Name
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'name' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'name' && sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('capacity')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Capacity
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'capacity' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'capacity' &&
                          sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="border-b text-nowrap">Status</td>
            <td class="w-[126px] border text-nowrap">Acion</td>
          </tr>
        </tbody>
        <tbody v-if="warehouse.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in warehouse"
            :key="data"
          >
            <td class="border-b text-nowrap">{{ data.id }}</td>
            <td class="border-b text-nowrap">
              {{ formatDate2(data.createdAt) }}
            </td>
            <td class="border-b text-nowrap">{{ data.name }}</td>
            <td class="border-b text-nowrap">
              {{ numeralFormat(data.capacity, "0,0[.]00") }} Kg
            </td>
            <td class="border-b" v-if="data.status === true">
              <el-button @click="handleUpdateStatus(data)" type="success" round
                >Acive</el-button
              >
            </td>
            <td class="border-b text-nowrap" v-else>
              <el-button @click="handleUpdateStatus(data)" type="warning" round
                >Incative</el-button
              >
            </td>
            <td class="border text-nowrap">
              <div class="flex items-center gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_warehouse?.warehouse_list?.update ===
                        true)
                  "
                  type="mdi"
                  :path="mdiPencil"
                  @click="handleEditData(data)"
                  class="edit"
                >
                </svg-icon>
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_warehouse?.warehouse_list?.delete ===
                        true)
                  "
                  type="mdi"
                  :path="mdiTrashCanOutline"
                  @click="openDeleteConfirmation(data)"
                  class="delete"
                >
                </svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <th colspan="6" class="text-center"><NoDataComponet /></th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full mt-8">
      <div class="">
        <PaginationVue
          :currentPage="currentPage"
          :totalPages="totalPages"
          :totalRecord="warehouse.length"
          :pageSize="pageSize"
          @page-change="handlePageChange"
          @page-size-change="handlePageSizeChange"
          isLastRecordOnPage="isLastRecordOnPage"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isUpdateStatus" as="template">
      <Dialog as="div" @close="handleCloseStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseStatus"
                    :is="currentComponent"
                    :isStatus="isStatus"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <ToastVue ref="toast" />
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import WareHouseFormVue from "@/form/WareHouseForm.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import PaginationVue from "@/components/Pagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import ToastVue from "@/components/Toast.vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { useI18n } from "vue-i18n";
import UpdateStatus from "@/components/UpdateStatus.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";

import { mdiTrashCanOutline, mdiPencil } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    WareHouseFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    DeleteConfrimation,
    PaginationVue,
    ToastVue,
    UpdateStatus,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "WareHouseFormVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    // fetch api
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("warehouse");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const warehouse = ref([]);
    const fetchwarehouse = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "name",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([]),
      };
      const response = await axios.get(apiUrl, { params });
      warehouse.value = response.data.data;

      totalPages.value = response.data.pagination.totalPages;
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.all([fetchwarehouse(), fetchUserByID()]);
    };

    onMounted(async () => {
      await Promise.all([fetchwarehouse(), fetchUserByID()]);
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      socket.on("database_realTime", handleDatabaseUpdate);
    });
    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });
    watch(searchValue, () => {
      fetchwarehouse();
    });
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = `${t("message.addsucess")}`;
            type = "infor";
            break;
          case "update":
            message = `${t("message.updatesucess")}`;
            type = "success";
            break;
          case "delete":
            message = `${t("message.deletesuccess")}`;
            type = "error";
            break;
          case "cannotdelete":
            message = `${t("message.cannotdelete")}`;
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    // delete
    const isDeleteOpen = ref(false);
    const deletedata = ref(null);
    const itemIdToDelete = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };
    const tableName = ref(null);
    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: table.value },
        });
        await fetchwarehouse();
        if (isLastPage && customer.value.length === 0) {
          currentPage.value = 1;
          await fetchwarehouse(); // Fetch data for page 1
        }
        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
        showSuccessToast("cannotdelete", "error");
      }
    };
    // edit

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "WareHouseFormVue";
      datatoedit.value = item;
      console.log("item:", datatoedit);
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchwarehouse();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchwarehouse();
    };
    // sort
    const sortClient = (feild, sortDirection) => {
      if (feild === "name") {
        toggleSort(sortDirection, warehouse.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, warehouse.value, feild);
      }
    };
    //translate
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };
    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      if (
        authUserRole.value === "Super Admin" ||
        (authUserRole.value === "User" &&
          permissions.value.module_warehouse?.warehouse_list?.update === true)
      ) {
        isUpdateStatus.value = true;
        isStatus.value = doc;
        currentComponent.value = "UpdateStatus";
      }
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };

    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      warehouse.value = toggleSort(direction, warehouse.value, field);
    };

    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      navClasses,
      tableClasses,
      warehouse,
      formatDate2,
      showSuccessToast,
      toast,
      searchValue,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deletedata,
      // edit
      datatoedit,
      handleEditData,
      // pagination
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      // sort
      sortClient,
      // translate
      t,
      // update Status
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      mdiTrashCanOutline,
      mdiPencil,
      sortData,
      sortDirections,
      sortColumns,
    };
  },
};
</script>

<style></style>
