<template>
  <div
    class="w-full bg-default overflow-hidden animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div
      v-if="authUserRole === 'Super Admin'"
      class="filter_type border rounded"
    >
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Supplier</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectSupplier"
          :options="suppliers"
          filter
          placeholder="Select Supplier"
          option-label="name"
          option-value="id"
          show-clear
          checkmark
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          show-button-bar
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
        >
        <el-button @click="handleApplyFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div>
      <div>
        <h2 class="py-2">Supplier Invoice Transactions</h2>
      </div>
      <div :class="tableClasses">
        <table>
          <tbody>
            <tr class="header_table">
              <td
                @click="sortData('invoice_id')"
                class="w-[120px] border-b text-start text-nowrap toggle_sort"
              >
                Invoice ID
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'invoice_id' && sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="text-nowrap border-b text-start">Created At</td>
              <td
                @click="sortData('supplier_name')"
                class="border-b text-start text-nowrap toggle_sort"
              >
                Supplier
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'supplier_name' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('total_amount')"
                class="border-b text-start text-nowrap toggle_sort"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'total_amount' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('amount_paid')"
                class="border-b text-start text-nowrap toggle_sort"
              >
                Total Cost
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'amount_paid' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weighing.total_sack_expense')"
                class="border-b text-start text-nowrap toggle_sort"
              >
                Sack Expense
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'amount_paid' &&
                        sortDirections === 'weighing.total_sack_expense'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>

              <td
                @click="
                  sortData('wallet.preloan.original_supplier_owe.0.amount')
                "
                class="border-b text-start text-nowrap toggle_sort"
              >
                Pre-Loan
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns ===
                          'wallet.preloan.original_supplier_owe.0.amount' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>

              <td class="border-b">Status</td>
              <td class="border">Acion</td>
            </tr>
          </tbody>
          <tbody v-if="invoice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in invoice"
              :key="data.invoice_id"
            >
              <td class="border-b text-start">{{ data.invoice_id }}</td>
              <td class="border-b text-nowrap">
                {{ formatDate2(data.created_at) }}
              </td>
              <td class="border-b">{{ data.supplier_name }}</td>
              <td class="border-b">
                {{
                  formatCurrency(
                    calculateWeightDrop(data.rices),
                    " Kg ",
                    "after"
                  )
                }}
              </td>
              <td class="border-b">
                {{ formatCurrency(data.amount_paid, " ៛ ", "after") }}
              </td>
              <td class="border-b">
                {{
                  data.weighing[0]?.total_sack_expense
                    ? formatCurrency(
                        data.weighing[0]?.total_sack_expense,
                        " ៛ ",
                        "after"
                      )
                    : 0
                }}
              </td>
              <td class="border-b">
                {{
                  data.wallet.preloan.original_supplier_owe[0]?.amount
                    ? formatCurrency(
                        data.wallet.preloan.original_supplier_owe[0]?.amount,
                        " ៛ ",
                        "after"
                      )
                    : 0
                }}
              </td>

              <td class="border-b">
                <el-button
                  :type="
                    data.wallet.preloan.original_supplier_owe[0]?.amount > 0
                      ? 'warning'
                      : 'success'
                  "
                  round
                  >{{
                    data.wallet.preloan.original_supplier_owe[0]?.amount > 0
                      ? "Due"
                      : "Paid"
                  }}</el-button
                >
              </td>
              <td class="border">
                <div class="flex items-center gap-3">
                  <svg-icon
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_transaction?.supplier_invoice
                          ?.view === true)
                    "
                    :path="mdiEyeOutline"
                    type="mdi"
                    class="info"
                    @click="handlePrint(data.invoice_id)"
                  />

                  <svg
                    @click="openModal(data)"
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_transaction?.supplier_invoice
                          ?.delete === true)
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-primary4 delete"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8" class="text-center">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="invoice.length > 0"
        class="w-full max-w-[1480px] flex items-center justify-between mt-8"
      >
        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          :totalRecord="invoice.length"
          @page-change="handlePageChange"
          @page-size-change="handlePageSizeChange"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-white">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
                <DialogTitle
                  class="text-lg text-start font-medium text-gray-900"
                >
                  Confirm Delete
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-nowrap text-start">
                    Are You sure you want to delete invoice ID [
                    {{ selectedDoc.invoice_id }} ]
                  </p>
                </div>
                <div class="mt-4 flex justify-end gap-2">
                  <button
                    class="bg-gray-200 px-4 py-2 rounded text-primary2"
                    @click="closeModal"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-red-600 text-white px-4 py-2 rounded"
                    @click="confirmDelete"
                  >
                    Delete
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <Toast ref="toast" />
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import { formatCurrency } from "@/composables/formatCurrency";
import Pagination from "@/components/Pagination.vue";
import Toast from "@/components/Toast.vue";
import { deleteCutPreloan } from "@/composables/usePreloan";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import BiilTransactionDetails from "../Bill/BillTransactionDetail.vue";
import { useRoute, useRouter } from "vue-router";
import toggleSort from "@/composables/sortData";
import { mdiEyeOutline } from "@mdi/js";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import NoDataComponet from "@/components/NoDataComponet.vue";
import moment from "moment-timezone";
import { useSupplierPayment } from "@/composables/usePartnerTransaction";

export default {
  components: {
    BiilTransactionDetails,
    Pagination,
    NoDataComponet,
    Toast,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "BiilTransactionDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const router = useRouter();
    const handlePrint = (id) => {
      router.push({
        name: "printbill",
        params: { id },
      });
    };
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });

    // fetch api
    const selectSupplier = ref(null);
    const currentDate = new Date();
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("invoice_id");
    const sortDirection = ref("DESC");
    const searchValue = ref("");

    const invoice = ref([]);
    const fetchInvoice = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;

      try {
        const params = {
          tableName: "supplier_invoice",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          columnAliases: JSON.stringify({
            invoice_id: { table: "supplier_invoice", column: "id" },
            supplier_name: { table: "suppliers", column: "name" },
            created_at: { table: "supplier_invoice", column: "created_at" },
            status: { table: "supplier_invoice", column: "status" },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "RIGHT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            { field: "id", operator: "IS NOT NULL", typeTable: "table" },

            ...(selectSupplier.value
              ? [
                  {
                    field: "supplier_id",
                    operator: "=",
                    value: selectSupplier.value,
                    typeTable: "table",
                  },
                ]
              : []),

            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );
        invoice.value = response.data.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const calculateWeightDrop = (weighingData) => {
      if (!weighingData || weighingData.length === 0) return 0;

      // Sum up the weight_drop values from the weighing data
      return weighingData.reduce((total, weighing) => {
        return total + (weighing.weight_drop || 0); // Add weight_drop if exists
      }, 0);
    };
    const invoice_last_record = ref([]);
    const fetchInvoiceLastRecord = async () => {
      try {
        const params = {
          tableName: "supplier_invoice",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        invoice_last_record.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "suppliers",
          sortColumn: "id",
        };

        const response = await axios.get(apiUrl, { params });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };

    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortExpense = (direction, data, field) => {
      return data.slice().sort((a, b) => {
        const fieldPath = field.split("."); // Split field path by "."

        // Function to get nested value, handling array cases
        const getValue = (obj, path) => {
          let value = obj;
          for (const part of path) {
            value = value[part];
            if (Array.isArray(value)) {
              value = value[0]; // Take the first item in the array for sorting
            }
            if (!value) break;
          }
          return value;
        };

        const aValue = getValue(a, fieldPath);
        const bValue = getValue(b, fieldPath);

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortPreloan = (direction, data, field) => {
      return data.slice().sort((a, b) => {
        const fieldPath = field.split("."); // Split field path by "."

        // Function to get nested value, handling array cases
        const getValue = (obj, path) => {
          let value = obj;
          for (const part of path) {
            value = value[part];
            if (Array.isArray(value)) {
              value = value[0]; // Take the first item in the array for sorting
            }
            if (!value) break;
          }
          return value;
        };

        const aValue = getValue(a, fieldPath);
        const bValue = getValue(b, fieldPath);

        // Ensure null or undefined values are handled gracefully
        const aAmount = aValue ? aValue : 0;
        const bAmount = bValue ? bValue : 0;

        if (aAmount < bAmount) return direction === "asc" ? -1 : 1;
        if (aAmount > bAmount) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };

    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      invoice.value = toggleSort(direction, invoice.value, field);
      invoice.value = sortExpense(direction, invoice.value, field);
      invoice.value = sortPreloan(direction, invoice.value, field);
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchInvoice(),
        fetchSupplier(),
        fetchUserByID(),
        fetchInvoiceLastRecord(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([
        fetchInvoice(),
        fetchSupplier(),
        fetchUserByID(),
        fetchInvoiceLastRecord(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchInvoice();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchInvoice();
    };
    const handleApplyFilter = () => {
      fetchInvoice();
    };
    const isModalOpen = ref(false);
    const selectedDoc = ref(null);

    const openModal = (doc) => {
      console.log("doc", doc);
      if (doc.is_sack_slip === false) {
        selectedDoc.value = doc;

        isModalOpen.value = true;
      } else {
        toast.value.showToast("Can not delete ", "error");
        return;
      }
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const confirmDelete = async () => {
      try {
        if (!selectedDoc.value) return;

        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${selectedDoc.value.invoice_id}`,
          {
            data: { tableName: "supplier_invoice" },
          }
        );

        await deleteCutPreloan(
          selectedDoc.value.invoice_id,
          selectedDoc.value.supplier_id
        );

        if (
          selectedDoc.value.weighing &&
          Array.isArray(selectedDoc.value.weighing)
        ) {
          for (const item of selectedDoc.value.weighing) {
            if (item.weighing_id) {
              const req_update_weight = {
                tableName: "weighing",
                fields: { is_supplier_invoice: false },
              };
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${item.weighing_id}`,
                req_update_weight
              );
            }
          }
          await useSupplierPayment(
            selectedDoc.value,
            selectedDoc.value.invoice_id,
            "remove"
          );
          closeModal();
        }

        showSuccessToast("delete", "error");
      } catch (error) {
        showSuccessToast("cannotdelete", "error");
        console.error("Error deleting supplier invoice:", error);
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    return {
      authUserRole,
      permissions,
      handleAdd,
      isOpen,
      currentComponent,
      handleClose,
      navClasses,
      selectClasses,
      tableClasses,
      invoice,
      formatDate2,
      formatCurrency,
      handlePageChange,
      handlePageSizeChange,
      currentPage,
      pageSize,
      totalPages,
      isLastRecordOnPage,
      handlePrint,
      sortData,
      sortDirections,
      sortColumns,
      suppliers,
      selectSupplier,
      selectDateRage,
      handleApplyFilter,
      mdiEyeOutline,
      invoice_last_record,
      toast,
      isModalOpen,
      openModal,
      closeModal,
      confirmDelete,
      selectedDoc,
      calculateWeightDrop,
    };
  },
};
</script>

<style></style>
