<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-fit h-fit bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Cut Out Form</h2>
      <div class="closeform">
        <svg
          @click="handleClose"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="space-y-5">
      <div>
        <div
          class="w-fit mx-auto bg-primary3/10 border border-primary3 rounded-lg p-4 mt-4"
        >
          <div class="flex gap-6 justify-between">
            <h2 class="text-primary3 text-heading2 text-start text-nowrap">
              Remain Weight
            </h2>
            <div>
              <h2 class="text-primary3 text-heading2 text-start text-nowrap">
                Weight:
                {{ numeralFormat(isCutOutData?.weight_in, "0,0[.]00") }} Kg
              </h2>
            </div>
          </div>
          <div
            class="text-start ml-5 text-heading1 font-semibold text-primary3"
          >
            {{ numeralFormat(isCutOutData?.weight_drop, "0,0[.]00") }} Kg
          </div>
        </div>
      </div>
      <div class="text-start w-[90%] p-3 bg-white mx-auto rounded-md space-y-2">
        <div class="flex justify-between">
          <h2>Date Time</h2>
          <p>{{ formatDate2(isCutOutData?.date_in) }}</p>
        </div>

        <div class="flex justify-between">
          <h2>Current Weigh</h2>
          <p>{{ numeralFormat(isCutOutData?.weight_in, "0,0[.]00") }} Kg</p>
        </div>
      </div>
      <div class="w-full p-5 space-y-4">
        <div class="flex items-center gap-3 justify-between text-nowrap">
          <label for="phone_number" class="flex gap-1"
            ><span class="text-red-500">*</span> Amount</label
          >
          <InputNumber
            class="w-[250px] h-2 flex items-center"
            v-model="cutout"
            suffix=" Kg"
            :maxFractionDigits="2"
            type="text"
            placeholder="Amount"
          />
        </div>
        <!-- <div class="flex items-center gap-3">
            <label for="status" class="flex gap-1 text-nowrap">Status</label>
            <el-switch v-model="status" />
          </div> -->
        <div class="flex w-full items-center gap-3">
          <button type="submit">Save</button>
          <button type="button" @click="saveAndNew" class="btncancel">
            Save & New
          </button>
          <button @click="handleClose" type="button" class="btncancel">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { onMounted, ref } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import InputNumber from "primevue/inputnumber";
export default {
  props: ["isCutOutData"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const suppliers = ref([]);
    // const plate_numbers = ref("");
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.isCutOutData.id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const cutout = ref(null);
    onMounted(async () => {
      if (props.isCutOutData) {
        cutout.value = props.isCutOutData.amount_cut_off;
      }
      await Promise.allSettled([
        fetchSupplier(),
        fetchWeighing(),
        fetchTruck(),
      ]);
    });
    const getPlatesName = (id) => {
      const name = trucks.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const handleSubmit = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          fields: {
            amount_cut_off: cutout.value,
          },
        };
        const res = await axios.patch(
          `${apirURL}/weighing/api/updateData/${props.isCutOutData.id}`,
          req
        );
        console.log("res", res);
        handleClose();
        cutout.value = null;

        emit("toast", "update");
      } catch (error) {
        console.log(error);
      }
    };
    const saveAndNew = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          fields: {
            amount_cut_off: cutout.value,
          },
        };
        const res = await axios.patch(
          `${apirURL}/weighing/api/updateData/${props.isCutOutData.id}`,
          req
        );
        console.log("res", res);
        cutout.value = null;
        emit("toast", "update");
      } catch (error) {
        console.log(error);
      }
    };
    return {
      handleClose,
      cutout,
      handleSubmit,
      formatDate2,
      weighing,
      getPlatesName,
      getSupplierName,
      saveAndNew,
    };
  },
};
</script>
