<template>
  <Toast ref="toast" />

  <div class="w-full relative text-primary6">
    <div class="w-full flex items-center justify-between">
      <div>
        <h2>Parking Card View</h2>
      </div>
      <div class="flex gap-3">
        <router-link
          to="/parking"
          v-if="
            authUserRole === 'Super Admin' ||
            (authUserRole === 'User' &&
              permissions.module_parking?.parking_list?.view === true)
          "
        >
          <button class="btncancel">Parking List</button>
        </router-link>
        <button
          v-if="
            authUserRole === 'Super Admin' ||
            (authUserRole === 'User' &&
              permissions.module_parking?.move_boat?.update === true)
          "
          @click="handleMove"
          class="btncancel"
        >
          Move
        </button>
        <button
          v-if="
            authUserRole === 'Super Admin' ||
            (authUserRole === 'User' &&
              permissions.module_parking?.boat_mapping?.create === true)
          "
          @click="handleMapping"
        >
          Mapping
        </button>
      </div>
    </div>
    <div
      v-if="uniqueBoats.length > 0 || weighing.length > 0"
      class="relative overflow-x-auto mt-4 border h-[700px] bg-white rounded p-5 flex flex-col items-start w-full justify-start text-nowrap"
    >
      <div
        v-for="card in uniqueBoats"
        :key="card.boat_id"
        @click="handleAdd(card)"
        class="w-full h-fit flex items-center justify-between bg-[#F9FCFF] m-2 rounded-md border p-2 animate-fade-up animate-duration-300 animate-delay-100"
      >
        <div
          class="px-1 h-[80px] w-full bg-[#F9FCFF] rounded flex items-center justify-around"
        >
          <div>
            <h2 class="text-[28px] text-[#0984ff]">
              {{ getParkingName(card.parking.parking_id) }}
            </h2>
          </div>
          <div>
            <h2 class="text-[#0984ff] text-[120px] mb-2 text-end">➝</h2>
          </div>
          <div>
            <h2 class="text-[#0984ff] text-[28px] space-x-2">
              {{ card.name }} [{{ getCustomername(card.customer_id) }}]
            </h2>
          </div>
        </div>
        <div class="px-4 h-[100px] w-full space-y-2">
          <div>
            <h2 class="text-[22px] text-primary5">
              Total Weight:
              {{ numeralFormat(card.total_weight_drop, "0,0[.]00") }} Kg
            </h2>
          </div>
          <div class="w-full bg-default rounded-lg">
            <div
              :class="
                card.total_weight_drop >= card.capacity
                  ? `w-[${card.max}%]`
                  : `w-[${(card.total_weight_drop / card.capacity) * 100}%]`
              "
              class="py-2 bg-[#0984ff] rounded-lg"
            ></div>
            <div class="flex justify-between bg-[#F9FCFF]">
              <div>
                <p class="text-[#0984ff]">
                  Completed:
                  {{
                    100 -
                    ((card.capacity - card.total_weight_drop) / card.capacity) *
                      100
                  }}
                  %
                </p>
              </div>
              <div>
                <p>
                  Max:
                  {{
                    numeralFormat(
                      card.capacity - card.total_weight_drop,
                      "0,0[.]00"
                    )
                  }}
                  Kg
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(weighingItem, weighingIndex) in weighing"
        :key="weighingIndex"
        class="w-full h-fit flex flex-col"
      >
        <div
          class="animate-fade-up animate-duration-300 animate-delay-100"
          style="height: 120px"
          v-for="(rental, rentalIndex) in weighingItem.rental_parking"
          :key="`${weighingIndex}-${rentalIndex}`"
          :class="
            getParkingRentalMode(rental.parking_id) === false
              ? 'px-1 h-[80px] bg-[#F9FCFF] rounded flex items-center justify-around m-2 border w-full '
              : 'px-1 h-[80px] bg-primary3/15 rounded flex items-center justify-around m-2 border w-full '
          "
        >
          <div class="w-[93%] mx-auto flex items-center justify-between">
            <div class="w-1/2 flex items-center justify-between">
              <div>
                <h2
                  :class="
                    getParkingRentalMode(rental.parking_id) === false
                      ? 'text-[28px] text-[#0B6CC6]'
                      : 'text-[28px] text-primary3'
                  "
                >
                  {{ getParkingName(rental.parking_id) }}
                </h2>
              </div>
              <div>
                <h2 class="text-[#0B6CC6] text-[120px] mb-2 text-end">
                  {{
                    getParkingRentalMode(rental.parking_id) === false ? "➝" : ""
                  }}
                </h2>
              </div>
              <!-- overlay bg -->
              <div class="bg-none w-[50px] h-2"></div>
              <div>
                <h2
                  :class="
                    getParkingRentalMode(rental.parking_id) === false
                      ? 'text-[#0B6CC6] text-[28px] space-x-2'
                      : 'text-primary3 text-[28px] space-x-2'
                  "
                >
                  {{
                    getParkingRentalMode(rental.parking_id) === false
                      ? rental.boat_name
                      : ""
                  }}
                  {{
                    getParkingRentalMode(rental.parking_id) === false
                      ? `[${getSupplierName(rental.supplier_id)}]`
                      : ""
                  }}
                </h2>
              </div>
            </div>
            <div class="w-1/2 flex items-center justify-center">
              <h2
                :class="
                  getParkingRentalMode(rental.parking_id) === false
                    ? 'text-[#0B6CC6] text-[28px]'
                    : 'text-primary3 text-[28px]'
                "
              >
                {{
                  getParkingRentalMode(rental.parking_id) === false
                    ? "Rental"
                    : "Available"
                }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="w-full h-[700px] flex items-center justify-center border mt-2 rounded"
      >
        <NoDataComponet />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    :datatoedit="datatoedit"
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isMove" as="template">
      <Dialog as="div" @close="handleCloseMove" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isMapping" as="template">
      <Dialog as="div" @close="handleCloseMapping" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseMapping"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, onMounted, onUnmounted, onBeforeUnmount, computed } from "vue";

import ParkingFormVue from "@/form/ParkingForm.vue";
import ParkingPreviewVue from "@/form/ParkingPreview.vue";
import ParkingViewMoveForm from "@/form/ParkingViewMoveForm.vue";
import ParkingMappingPriewView from "@/form/ParkingMappingPriewView.vue";
import { getResponsiveParkingCard } from "@/composables/useResponsive";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import { decodeJwt } from "@/composables/decodeJWT";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    ParkingFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ParkingPreviewVue,
    ParkingViewMoveForm,
    ParkingMappingPriewView,
    Toast,
    NoDataComponet,
  },
  setup() {
    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", updateWindowWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowWidth);
    });
    const currentComponent = ref("");
    const isOpen = ref(false);
    const datatoedit = ref(null);
    const handleAdd = (item) => {
      isOpen.value = true;
      datatoedit.value = item;
      currentComponent.value = "ParkingPreviewVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    const isMove = ref(false);
    const handleMove = () => {
      isMove.value = true;
      currentComponent.value = "ParkingViewMoveForm";
    };
    const handleCloseMove = () => {
      isMove.value = false;
      currentComponent.value = "";
    };

    const isMapping = ref(false);
    const handleMapping = () => {
      isMapping.value = true;
      currentComponent.value = "ParkingMappingPriewView";
    };
    const handleCloseMapping = () => {
      isMapping.value = false;
      currentComponent.value = "";
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initial call to set the current window width
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    // get api
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("boat_enter_parking");
    const sortColumn = ref("boat_id");
    const sortDirection = ref("ASC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const cart = ref([]);
    const uniqueBoats = ref([]);

    const fetchParkingCard = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "name",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        columnAliases: JSON.stringify({
          boat_id: { table: table.value, column: "id" },
          id: {
            table: "weighing_rice_history",
            column: "boat_enter_parking_id",
          },
          current_weight: {
            table: "weighing_rice_history",
            column: "weight_drop",
          },
          parking: {
            table: table.value,
            column: "parking",
          },
          created_at: {
            table: table.value,
            column: "created_at",
          },
        }),
        additionalJoins: JSON.stringify([
          {
            joinTableName: "weighing_rice_history",
            joinColumnChild: "id",
            joinColumnParent: "boat_enter_parking_id",
            joinType: "LEFT JOIN",
          },
        ]),
        dynamicConditions: JSON.stringify([
          {
            field: "is_complete",
            operator: "=",
            value: false,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      cart.value = response.data.data;
      console.log("data", response.data.data);

      const boatMap = new Map();
      cart.value.forEach((item) => {
        if (!boatMap.has(item.boat_id)) {
          boatMap.set(item.boat_id, { ...item, total_weight_drop: 0 });
        }
        const boatData = boatMap.get(item.boat_id);
        boatData.total_weight_drop += item.current_weight;
      });

      uniqueBoats.value = Array.from(boatMap.values());

      console.log("Unique boats with total weight_drop:", uniqueBoats.value);
      totalPages.value = response.data.pagination.totalPages;
    };

    const parking_rice = ref([]);
    const fetchParkingName = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "is_available",
            //   operator: "=",
            //   value: false,
            //   typeTable: "table",
            // },
            // {
            //   field: "is_rental",
            //   operator: "=",
            //   value: false,
            //   typeTable: "table",
            // },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking_rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "service_external_type",
              operator: "=",
              value: "rental_parking",
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: "true",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const customers = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        customers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rice = ref([]);
    const fetchRices = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const suppliers = ref([]);
    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const getParkingName = (id) => {
      const parkings = parking_rice.value.find((item) => item.id === id);
      return parkings ? parkings.name : "";
    };
    const getParkingRentalMode = (id) => {
      const parkings = parking_rice.value.find((item) => item.id === id);
      return parkings ? parkings.is_rental : false;
    };
    const getCustomername = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getRiceName = (id) => {
      const name = rice.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchParkingCard(),
        fetchParkingName(),
        fetchCustomer(),
        fetchRices(),
        fetchWeighing(),
        fetchUserByID(),
        fetchSuppliers(),
      ]);
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchParkingCard(),
        fetchParkingName(),
        fetchCustomer(),
        fetchRices(),
        fetchUserByID(),
        fetchWeighing(),
        fetchSuppliers(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });

    const getCardClass = (status) => {
      const baseClass = getResponsiveParkingCard(windowWidth.value);
      switch (status) {
        case "available":
          return `${baseClass} parking_card_available`;
        case "busy":
          return `${baseClass} parking_card_busy`;
        case "inactive":
          return `${baseClass} parking_card_inactive`;
        default:
          return baseClass;
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      handleMove,
      isMove,
      handleCloseMove,
      handleCloseMapping,
      handleMapping,
      isMapping,
      cart,
      getCardClass,
      datatoedit,
      parking_rice,
      getParkingName,
      getCustomername,
      getRiceName,
      uniqueBoats,
      weighing,
      getSupplierName,
      getParkingRentalMode,
      toast,
      showSuccessToast,
      // translate functions
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>
