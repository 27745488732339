<template>
  <div class="w-full relative text-primary6">
    <div
      :class="filterClasses"
      class="border rounded mt-3 p-3 flex items-end hidden-print"
    >
      <div class="w-fit bg-white p-4 flex gap-3">
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Warehouse</label
          >

          <Select
            class="w-full md:w-56 h-9 text-start flex items-center"
            :options="warehouse"
            v-model="selectWarehouse"
            filter
            placeholder="Select Warehouse"
            option-label="name"
            option-value="id"
            show-clear
            checkmark
            size="medium"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Rice Type</label
          >
          <Select
            class="w-full md:w-56 h-9 text-start flex items-center"
            :options="rices"
            filter
            v-model="selectRice"
            placeholder="Select Rice"
            option-label="name"
            option-value="name"
            show-clear
            checkmark
            size="medium"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Date Rage</label
          >
          <DatePicker
            v-model="selectDateRage"
            selectionMode="range"
            class="w-full md:w-56 h-1 text-start flex items-center"
            placeholder="Select Date "
            dateFormat="dd/M/yy"
            :manualInput="false"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier" class="opacity-0"
            ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
          >
          <el-button @click="handleFilterButtonClick" size="large"
            >Filter</el-button
          >
        </div>
      </div>
      <div class="w-fit bg-white p-4 flex gap-3">
        <button @click="exportToExcel" class="btncsv flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </div>
          CSV
        </button>

        <button @click="downloadPDF" class="btncancel flex items-center gap-2">
          <div>
            <font-awesome-icon :icon="['fas', 'file']" class="size-5" />
          </div>
          PDF
        </button>
        <button @click="printTable" class="flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          Print
        </button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[500px]">
      <table id="printTable" class="">
        <tbody>
          <tr class="text-body header_table">
            <td class="min-w-[180px] border-b text-start">Created At</td>
            <td
              @click="sortData('id')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumn === 'id' && sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('name')"
              class="min-w-[180px] border-b text-start toggle_sort"
            >
              Warehouse
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'name' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td class="min-w-[180px] border-b text-start">Rice Type</td>
            <td @click="sortData('weight')" class="border-b toggle_sort">
              <div class="flex items-center justify-between">
                <div class="flex items-center text-nowrap">
                  Sell Amount (Kg)
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumn === 'weight' && sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="purchase.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in purchase"
            :key="data"
          >
            <td class="border-b">{{ formatDate2(data.created_at) }}</td>
            <td class="border-b">{{ data.id }}</td>
            <td class="border-b">{{ data.name }}</td>
            <td class="border-b">
              {{ data.rice.rice_name }}
            </td>
            <td class="border-b">
              {{ numeralFormat(data.weight, "0,0[.]00") }} Kg
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full flex items-center justify-between hidden-print">
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :totalRecord="purchase.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        isLastRecordOnPage="isLastRecordOnPage"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[360px] h-[290px] bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, onMounted, onUnmounted, computed } from "vue";
import ClientFormVue from "@/form/ClientForm.vue";
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
  getResponsiveFilter,
  getResponsiveTable,
} from "@/composables/useResponsive";
import { Transition } from "vue";
import { formatDateWithTimezone } from "@/utils/formatDateWithTimeZone";
export default {
  components: {
    ClientFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    NoDataComponet,
    Transition,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "ClientFormVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive function

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const filterClasses = computed(() => {
      const classes = getResponsiveFilter(windowWidth.value);

      return classes;
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const selectRice = ref(null);
    const selectWarehouse = ref(null);

    const purchase = ref([]);
    const selectDateRage = ref(null);

    const fetchPurchaseHistory = async () => {
      try {
        let dynamicConditions = [];

        dynamicConditions.push({
          field: "transaction_type",
          operator: "=",
          value: "sell",
          typeTable: "table",
        });

        if (
          selectDateRage.value &&
          selectDateRage.value?.[0] &&
          selectDateRage.value?.[1]
        ) {
          const startOfDay = formatDateWithTimezone(
            selectDateRage.value[0],
            false
          );

          const endOfDay = formatDateWithTimezone(
            selectDateRage.value[1],
            true
          );

          dynamicConditions.push(
            {
              field: "created_at",
              operator: ">=",
              value: startOfDay, // start of day
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: endOfDay, // end of day
              typeTable: "table",
            }
          );
        }

        if (selectWarehouse.value) {
          dynamicConditions.push({
            field: "warehouse_id",
            operator: "=",
            value: selectWarehouse.value,
            typeTable: "table",
          });
        }

        if (selectRice.value) {
          dynamicConditions.push({
            field: "rice",
            operator: "->>",
            key: "rice_name",
            value: selectRice.value,
            type: "jsonb",
            typeTable: "table",
          });
        }

        const params = {
          tableName: "stock_rice_transaction_history",
          page: currentPage.value,
          pageSize: pageSize.value,
          columnAliases: JSON.stringify({
            id: { table: "stock_rice_transaction_history", column: "id" },
            warehouse_id: {
              table: "stock_rice_transaction_history",
              column: "warehouse_id",
            },
            created_by: {
              table: "stock_rice_transaction_history",
              column: "created_by",
            },
            name: { table: "warehouse", column: "name" },
          }),
          sortColumn: "id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "warehouse",
              joinColumnChild: "warehouse_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );
        purchase.value = response.data.data;

        console.log("purchase", purchase.value);
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        purchase.value = [];
        totalPages.value = 0;
      }
    };
    // Download PDF

    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(purchase.value);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase History");

      XLSX.writeFile(workbook, "purchase_history.xlsx");
    };

    const printTable = () => {
      const printContents = document.getElementById("printTable").outerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    };

    const warehouse = ref([]);
    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rices = ref([]);
    const fetchRice = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const sortDirections = ref("asc");
    const sortColumn = ref(null);
    const sortData = (field) => {
      const direction =
        sortColumn.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumn.value = field;
      purchase.value = toggleSort(direction, purchase.value, field);
    };
    const handleFilterButtonClick = () => {
      fetchPurchaseHistory();
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchPurchaseHistory()]);
    };
    onMounted(async () => {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      // Set the date range
      selectDateRage.value = [firstDay, lastDay];
      await Promise.allSettled([
        fetchPurchaseHistory(),
        fetchWarehouse(),
        fetchRice(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
      window.addEventListener("resize", handleResize);
      handleResize();
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });

    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchPurchaseHistory();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchPurchaseHistory();
    };

    const totalWeight = (stock) => {
      if (typeof stock === "string") {
        stock = JSON.parse(stock);
      }

      return stock.reduce(
        (total, item) => total + (item.current_weight || 0),
        0
      );
    };
    const isFilterable = ref(false);
    const shortcuts = [
      {
        text: "Today",
        value() {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[0].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Month",
        value() {
          const start = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          const end = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          );
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[1].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Week",
        value() {
          const today = new Date();
          const startOfWeek = new Date(
            today.setDate(today.getDate() - today.getDay())
          ); // Sunday
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date();
          endOfWeek.setDate(startOfWeek.getDate() + 6); // Saturday
          endOfWeek.setHours(23, 59, 59, 999);
          return [startOfWeek, endOfWeek];
        },
        onClick(picker) {
          const [start, end] = shortcuts[2].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 3 Months",
        value() {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 3);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[3].value();
          picker.$emit("pick", [start, end]);
        },
      },
    ];
    const downloadPDF = async () => {
      // Create a temporary div for the content
      const printDiv = document.createElement("div");
      printDiv.style.position = "fixed"; // Ensure it does not interfere with layout
      printDiv.style.top = "-10000px"; // Move it out of view
      printDiv.innerHTML = `
    <div style="padding: 20px; font-family: 'Noto Serif Khmer', serif;">
      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
        <div style="width: 65px; height: 65px; border: 1px solid #000; border-radius: 50%;"></div>
        <div style="text-align: center;">
          <h2 style="font-size: 24px; margin: 0; font-weight: bold;">Sell History</h2>
          <h2 style="font-size: 14px; margin: 0;">011 111 111 / 012 123 456</h2>
        </div>
        <div style="text-align: left;">
          <div>Str#1 ,#104</div>
          <div>Battambang</div>
          <div>Battambang city</div>
          <div>Cambodia</div>
        </div>
      </div>

      <!-- Table -->
      <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr style="background-color: #f8f9fa;">
            <th style="border: 1px solid #ddd; text-align: center;">No</th>
            <th style="border: 1px solid #ddd; text-align: center;">ID</th>
            <th style="border: 1px solid #ddd; text-align: center;">Warehouse</th>
            <th style="border: 1px solid #ddd; text-align: center;">Created At</th>
            <th style="border: 1px solid #ddd; text-align: center;">Rice Type</th>
            <th style="border: 1px solid #ddd; text-align: center;">Sell Amount</th>
          </tr>
        </thead>
        <tbody>
          ${purchase.value
            .map(
              (data, index) => `
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${
                index + 1
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${
                data.id
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${
                data.name
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${formatDate2(
                data.created_at
              )}</td>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${
                data.rice?.rice_name
              }</td>
              <td style="padding: 8px; border: 1px solid #ddd; text-align: right;">${
                data.weight
              } Kg</td>
            </tr>
          `
            )
            .join("")}
        </tbody>
      </table>
    </div>
  `;

      document.body.appendChild(printDiv);

      try {
        // Generate canvas using html2canvas
        const canvas = await html2canvas(printDiv, {
          scale: 2,
          useCORS: true,
        });

        // Initialize jsPDF
        const pdf = new jsPDF("p", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");

        // Calculate dimensions
        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = 297; // A4 height in mm
        const imgWidth = pdfWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        // Add content to PDF
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        pdf.save("Sell.pdf");
      } catch (error) {
        console.error("PDF generation error:", error);
      } finally {
        document.body.removeChild(printDiv);
      }
    };

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      tableClasses,
      filterClasses,
      purchase,
      formatDate2,
      totalWeight,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      warehouse,
      selectWarehouse,
      selectRice,
      rices,
      handleFilterButtonClick,
      selectDateRage,
      isFilterable,
      shortcuts,
      // download pdf
      downloadPDF,
      exportToExcel,
      printTable,
      sortData,
      sortDirections,
      sortColumn,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
