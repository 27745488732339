<template>
  <div class="w-[800px]">
    <div class="details_title">
      <h2 class="ml-4">Customer: {{ data.name }}</h2>
      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Detail</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="py-4 gap-4 grid grid-cols-2 border-b-2 border-primary6">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Customer Name</h2>
          <h2>{{ data.name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>No</h2>
          <h2>{{ data.id }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Phone Number</h2>
          <h2 v-if="data.phone_number">{{ data.phone_number }}</h2>
          <h2 v-else>No Data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Wallet</h2>
          <h2>
            {{
              data.wallet.customer_repayment.original_customer_owe > 0
                ? data.wallet.customer_repayment.original_customer_owe
                : "No balance"
            }}
          </h2>
        </div>
      </div>
      <h2 class="mt-6 text-start">Transactions</h2>

      <div class="mt-4">
        <div class="flex">
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierPurchaseOrder',
            }"
            @click="setActiveTab('SupplierPurchaseOrder')"
          >
            Sale Order
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierFromTruck',
            }"
            @click="setActiveTab('SupplierFromTruck')"
          >
            To Parking
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierPurchasedBill',
            }"
            @click="setActiveTab('SupplierPurchasedBill')"
          >
            Sale Invoice
          </div>
        </div>
        <div class="text-start">
          <div v-if="activeTab === 'SupplierPurchaseOrder'">
            <ClientSaleOrder :datatoedit="datatoedit" />
          </div>
          <div v-else-if="activeTab === 'SupplierFromTruck'">
            <ClientToParking :datatoedit="datatoedit" />
          </div>
          <div v-else-if="activeTab === 'SupplierPurchasedBill'">
            <ClientSaleInvoice :datatoedit="datatoedit" />
          </div>
        </div>
        <div class="w-full flex items-center justify-start mt-4">
          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import ClientSaleInvoice from "./ClientSaleInvoice.vue";
import ClientSaleOrder from "./ClientSaleOrder.vue";
import ClientToParking from "./ClientToParking.vue";
export default {
  props: ["datatoedit"],
  components: {
    ClientSaleInvoice,
    ClientToParking,
    ClientSaleOrder,
  },

  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("SupplierPurchaseOrder");
    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    onMounted(() => {
      props.datatoedit;
    });

    const data = props.datatoedit;
    return {
      handleClose,
      activeTab,
      setActiveTab,
      data,
    };
  },
};
</script>

<style></style>
