// utils.js
const toggleSort = (sortDirection, items, field) => {
    return items.sort((a, b) => {
        let valueA = a[field];
        let valueB = b[field];

        // Convert values to numbers if they are numeric strings
        if (typeof valueA === 'string' && !isNaN(valueA)) {
            valueA = parseFloat(valueA);
        }
        if (typeof valueB === 'string' && !isNaN(valueB)) {
            valueB = parseFloat(valueB);
        }

        if (sortDirection === "asc") {
            if (valueA instanceof Date && valueB instanceof Date) {
                return valueA - valueB; // For date objects
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                return valueA - valueB; // For numeric values
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB); // For string values
            }
        } else {
            if (valueA instanceof Date && valueB instanceof Date) {
                return valueB - valueA; // For date objects
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                return valueB - valueA; // For numeric values
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueB.localeCompare(valueA); // For string values
            }
        }
    });
};

export default toggleSort;
