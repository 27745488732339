<template>
  <div class="bg-primary2 w-full text-primary text-body py-2">
    <div class="w-[98%] mx-auto flex items-center justify-between gap-4">
      <div class="flex gap-3">
        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.contact") }}
          </h2>
        </div>
        <router-link to="/preLoan">
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.preLoan") }}
          </h2>
        </router-link>
        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.transactions") }}
          </h2>
        </div>

        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.parking") }}
          </h2>
        </div>
        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.externalService") }}
          </h2>
        </div>
        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.report") }}
          </h2>
        </div>
        <div>
          <h2
            class="px-[18px] py-[5px] hover:text-primary/90 active:text-primary/70 cursor-pointer duration-300"
          >
            {{ t("message.setting") }}
          </h2>
        </div>
      </div>
      <div class="flex items-center gap-3">
        <div class="relative">
          <div
            class="absolute size-2 right-0 bottom-0 bg-primary4 rounded-full"
          ></div>
          <div class="size-[28px] rounded-full overflow-hidden">
            <img
              src="https://i.pinimg.com/236x/07/33/ba/0733ba760b29378474dea0fdbcb97107.jpg"
              alt=""
            />
          </div>
        </div>

        <el-dropdown>
          <el-button plain type="primary">
            Select Langue<el-icon class="el-icon--right"
              ><arrow-down
            /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeLanguage('en')"
                >English</el-dropdown-item
              >
              <el-dropdown-item @click="changeLanguage('km')"
                >ខ្មែរ</el-dropdown-item
              >
              <el-dropdown-item @click="changeLanguage('th')"
                >แบบไทย</el-dropdown-item
              >
              <el-dropdown-item @click="changeLanguage('vi')"
                >Việt Nam</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, unref } from "vue";
import { useI18n } from "vue-i18n";


export default {
  setup() {
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";
        case "th":
          return "font-NotoSansThai";
        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    const buttonRef = ref();
    const popoverRef = ref();
    const onClickOutside = () => {
      unref(popoverRef).popperRef?.delayHide?.();
    };

    return {
      fontClass,
      t,
      changeLanguage,
      buttonRef,
      onClickOutside,
      popoverRef,
    };
  },
};
</script>

<style></style>
