<template>
  <ToastVue ref="toast" />

  <div
    class="w-full overflow-hidden h-fit relative text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div :class="navClasses">
      <div>
        <h2>{{ t("message.userlist") }}</h2>
      </div>
      <div class="flex items-center gap-2">
        <IconField class="w-[80%] placeholder:text-body2">
          <InputText v-model="searchValue" placeholder="Search | Name" />
          <InputIcon class="pi pi-search" />
        </IconField>
        <button
          v-if="
            authUserRole === 'Super Admin' ||
            (authUserRole === 'User' &&
              permissions.module_setting?.user?.create === true)
          "
          @click="handleAdd"
        >
          {{ t("message.adduser") }}
        </button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[600px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td class="min-w-[90px] text-nowrap border-b text-start">
              {{ t("message.created_at") }}
            </td>

            <td
              @click="sortData('id')"
              class="w-[120px] text-start text-nowrap toggle_sort border-b"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td class="min-w-[90px] text-nowrap border-b text-start">
              {{ t("message.photo") }}
            </td>
            <td
              @click="sortData('username')"
              class="w-[120px] text-start text-nowrap toggle_sort border-b"
            >
              Username
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'username' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('profile_name')"
              class="w-[120px] text-start text-nowrap toggle_sort border-b"
            >
              Profile Name
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'profile_name' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('email')"
              class="w-[120px] text-start text-nowrap toggle_sort border-b"
            >
              Email
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'email' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td class="min-w-[90px] text-nowrap border-b">
              {{ t("message.phone") }}
            </td>

            <td class="min-w-[90px] text-nowrap border-b">
              {{ t("message.tag") }}
            </td>

            <td class="text-nowrap border-b">
              <div class="flex items-center">Status</div>
            </td>
            <td class="min-w-[90px] border text-nowrap">
              {{ t("message.action") }}
            </td>
          </tr>
        </tbody>
        <tbody v-if="users.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="(data, index) in users"
            :key="index"
          >
            <td class="border-b text-nowrap">
              {{ formatDate2(data.createdAt) }}
            </td>
            <td class="border-b text-nowrap">{{ data.id + 1 }}</td>

            <td class="border-b text-nowrap">
              <div
                class="flex w-20 h-20 justify-center items-center overflow-hidden bg-gray-100 rounded-full border relative"
              >
                <img
                  v-if="data.image_url !== null"
                  :src="getImageUrl('users', data.image_url)"
                  alt="photo"
                  class="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                />

                <div
                  v-else
                  class="flex space-x-2 items-center justify-center w-full h-full text-gray-500 text-center font-semibold"
                >
                  <img
                    src="@/assets/images/default_profile.png"
                    alt="photo"
                    class="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                  />
                </div>
              </div>
            </td>
            <td class="border-b text-nowrap">{{ data.username }}</td>
            <td class="border-b text-nowrap">{{ data.profile_name }}</td>
            <td class="border-b text-nowrap">{{ data.email }}</td>
            <td class="border-b text-nowrap" v-if="data.phone_number">
              {{ data.phone_number }}
            </td>
            <td v-else class="border-b text-nowrap">No data</td>
            <td class="border-b text-nowrap">
              <el-button
                disabled
                class="!cursor-default"
                v-for="(tag, index) in data.tag_name"
                :key="index"
                >{{ tag }}</el-button
              >
            </td>

            <td class="border-b text-nowrap">
              <el-button
                @click="handleUpdateStatus(data)"
                v-if="data.status === true"
                type="success"
                round
                >Active</el-button
              >
              <el-button
                @click="handleUpdateStatus(data)"
                v-else
                type="warning"
                round
              >
                Incative
              </el-button>
            </td>
            <td class="border text-nowrap">
              <div class="flex items-center gap-2">
                <svg
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_setting?.user_permission?.view ===
                        true)
                  "
                  @click="handleClientDetails(data)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-link-2 info"
                >
                  <path
                    d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"
                  ></path>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_setting?.user?.update === true)
                  "
                  :path="mdiPencil"
                  type="mdi"
                  class="edit"
                  @click="handleEditData(data)"
                />

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_setting?.user?.delete === true)
                  "
                  :path="mdiTrashCanOutline"
                  @click="openDeleteConfirmation(data)"
                  type="mdi"
                  class="delete"
                ></svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9" class="text-center">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="w-full mt-8">
      <div class="">
        <PaginationVue
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          :totalRecord="users.length"
          @page-change="handlePageChange"
          @page-size-change="handlePageSizeChange"
          isLastRecordOnPage="isLastRecordOnPage"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    @toast="showSuccessToast"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetails" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[2rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isUpdateStatus" as="template">
      <Dialog as="div" @close="handleCloseStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseStatus"
                    :is="currentComponent"
                    :isStatus="isStatus"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import toggleSort from "@/composables/sortData";
import UserForm from "@/form/UserForm.vue";
import UserPermissionForm from "@/form/UserPermissionForm.vue";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import PaginationVue from "@/components/Pagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import ToastVue from "@/components/Toast.vue";
import { mdiLink, mdiPencil, mdiTrashCanOutline } from "@mdi/js";
import { formatDate2 } from "@/composables/formatDateTime";

import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/composables/formatDateTime";
import { deleteImage } from "@/composables/upload";
import { decodeJwt } from "@/composables/decodeJWT";
import { getImageUrl } from "@/composables/getImage";
import UpdateStatus from "@/components/UpdateStatus.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  components: {
    UserForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    UserPermissionForm,
    PaginationVue,
    DeleteConfrimation,
    ToastVue,
    UpdateStatus,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("users");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const searchValue = ref("");
    const users = ref([]);
    const authUserId = ref(null);

    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "UserForm";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      fetchusers();
    };
    const isDetails = ref(false);
    const handleClientDetails = (item) => {
      currentComponent.value = "UserPermissionForm";
      isDetails.value = true;
      datatoedit.value = item;
    };
    const handleCloseDetails = () => {
      currentComponent.value = "";
      isDetails.value = false;
    };

    const fetchusers = async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "username,phone_number,email",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        dynamicConditions: JSON.stringify([
          {
            field: "id",
            operator: "!=",
            value: decodeJWTInfo.userId,
            typeTable: "table",
          },
          {
            field: "role",
            operator: "!=",
            value: "Super Admin",
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      users.value = response.data.data;

      totalPages.value = response.data.pagination.totalPages;
    };
    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "UserForm";
      datatoedit.value = item;
    };

    const handleDatabaseUpdate = () => {
      fetchusers();
      fetchUserByID();
    };

    watch(searchValue, () => {
      fetchusers();
    });

    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchusers();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchusers();
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
          case "errorcreateuser":
            message = "User already signe";
            type = "error";
            break;
          case "passworderror":
            message = "Please check password and confirm password";
            type = "error";
            break;
          case "cannotupdate":
            message = "You are not allowed to update this user";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
      fetchusers();
    };
    // sort
    const sortClient = (feild, sortDirection) => {
      if (feild === "name") {
        toggleSort(sortDirection, users.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, users.value, feild);
      }
    };

    // delete
    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const imageURL = doc.image_url;
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: table.value },
        });
        await fetchusers();
        if (isLastPage && users.value.length === 0) {
          currentPage.value = 1;
          await fetchusers();
        }
        if (imageURL !== null) {
          deleteImage("users", imageURL);
        }

        closeDeleteDialog();
      } catch (error) {
        if (error.response.status === 500) {
          showSuccessToast("cannotdelete");
        }
        console.error("Error deleting item:", error);
      }
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };
    const userData = ref([]);

    const authUserRole = ref("");
    const permissions = ref(null);

    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;
        userData.value = response.data;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };
    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      if (
        authUserRole.value === "Super Admin" ||
        (authUserRole.value === "User" &&
          permissions.value?.module_setting?.user?.update === true)
      ) {
        isUpdateStatus.value = true;
        isStatus.value = doc;
        currentComponent.value = "UpdateStatus";
        console.log("isUpdate status", isStatus);
      } else {
        showSuccessToast("cannotupdate");
      }
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };

    onMounted(async () => {
      fetchusers();
      fetchUserByID();
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;
      users.value = toggleSort(direction, users.value, field);
    };
    return {
      authUserRole,
      permissions,
      getImageUrl,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      handleClientDetails,
      handleCloseDetails,
      isDetails,
      searchValue,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      users,
      toast,
      showSuccessToast,
      handleEditData,
      datatoedit,
      sortClient,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deletedata,

      // responsive
      tableClasses,
      navClasses,
      t,
      formatDate,
      userData,
      // update Status
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      mdiLink,
      mdiPencil,
      mdiTrashCanOutline,
      formatDate2,
      sortData,
      sortColumns,
      sortDirections,
    };
  },
};
</script>

<style></style>
