<template>
  <form class="h-auto w-full bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">{{ t("message.userpermssion") }}</h2>

      <div @click="handleClose" class="closeform">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="flex items-start justify-between">
      <div class="w-[400px] h-[500px] p-5 flex items-start justify-between gap-3">
        <div class="bg-white h-full p-5 rounded border w-full space-y-6">
          <div class="mx-auto w-[67px] h-[67px] rounded-full overflow-hidden border">
            <img class="w-full h-full object-cover" v-if="datatoedit.image_url"
              :src="getImageUrl('users', datatoedit.image_url)" alt="" />
            <img class="w-full h-full object-cover" v-else src="@/assets/images/default_profile.png" alt="" />
          </div>
          <div class="mt-4 space-y-6">
            <div class="flex items-center justify-between">
              <h2>{{ t("message.username") }}</h2>
              <h2>{{ datatoedit.username }}</h2>
            </div>
            <div class="flex items-center justify-between">
              <h2>{{ t("message.profilename") }}</h2>
              <h2>{{ datatoedit.profile_name }}</h2>
            </div>
            <div class="flex items-center justify-between">
              <h2>{{ t("message.email") }}</h2>
              <h2>{{ datatoedit.email }}</h2>
            </div>
            <div class="flex items-center justify-between">
              <h2>{{ t("message.phone") }}</h2>
              <h2>{{ formatMobileNumber(datatoedit.phone_number) }}</h2>
            </div>
            <div class="flex items-center justify-between">
              <h2>{{ t("message.tag") }}</h2>
              <h2>{{ formatTagName(datatoedit.tag_name) }}</h2>
            </div>
            <div class="flex items-center justify-between">
              <h2>{{ t("message.created_at") }}</h2>
              <h2>{{ formatDate(datatoedit.created_at) }}</h2>
            </div>
          </div>
        </div>
      </div>
      <form class="w-[700px] p-5 flex items-start justify-between gap-3">
        <div class="bg-white p-5 rounded border w-full space-y-6">
          <div class="space-y-6 text-start">
            <h2>{{ t("message.permission") }}</h2>
          </div>
          <div class="h-[370px] overflow-auto space-y-3 scrollbar-hide">
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Ordering Book</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllOrderingBook" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Customer Order</td>
                  <td>
                    <Checkbox v-model="customerOrderView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerOrderCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerOrderDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerOrderUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Purchase Order</td>
                  <td>
                    <Checkbox v-model="purchaseOrderView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="purchaseOrderCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="purchaseOrderDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="purchaseOrderUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Contact</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllContact" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Supplier</td>
                  <td>
                    <Checkbox v-model="supplierView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="supplierCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="supplierDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="supplierUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Customer</td>
                  <td>
                    <Checkbox v-model="customerView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Weighing</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllWeighing" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Weigh</td>
                  <td>
                    <Checkbox v-model="weighingView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="weighingCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>

                  <td>
                    <Checkbox v-model="weighingUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Truck Storage</td>
                  <td>
                    <Checkbox v-model="weighingTruckStorageView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td></td>

                  <td>
                    <Checkbox v-model="weighingTruckStorageUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>

                <tr>
                  <td class="border-b">Boat Storage</td>
                  <td>
                    <Checkbox v-model="boatStorageView" binary class="flex items-start justify-center" />
                  </td>

                  <td></td>
                  <td></td>
                  <td>
                    <Checkbox v-model="boatStorageUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Finance</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllFinance" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Supplier Invoice</td>
                  <td>
                    <Checkbox v-model="supplierInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="supplierInvoiceCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>

                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Customer Invoice</td>
                  <td>
                    <Checkbox v-model="customerInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerInvoiceCreate" binary class="flex items-start justify-center" />
                  </td>

                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Customer Payment</td>
                  <td>
                    <Checkbox v-model="customerPaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="customerPaymentCreate" binary class="flex items-start justify-center" />
                  </td>

                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Payment Record</td>
                  <td>
                    <Checkbox v-model="customerRepaymentRecordView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>

                  <td>
                    <Checkbox v-model="customerRepaymentRecordDelete" binary class="flex items-start justify-center" />
                  </td>

                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Labor Invoice</td>
                  <td>
                    <Checkbox v-model="laborInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="laborInvoiceCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Sack Invoice</td>
                  <td>
                    <Checkbox v-model="sackInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="sackInvoiceCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
              </table>
            </div>

            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Preloan</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllPreLoan" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Preloan</td>
                  <td>
                    <Checkbox v-model="preLoanView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="preLoanCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="preLoanDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="preLoanUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Transaction</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllTransaction" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Truck Go</td>
                  <td>
                    <Checkbox v-model="transactionTruckGoView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="transactionTruckGoDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="transactionTruckGoUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">On Boat</td>
                  <td>
                    <Checkbox v-model="transactionOnBoatView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="transactionOnBoatDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="transactionOnBoatUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Supplier Invoice</td>
                  <td>
                    <Checkbox v-model="transactionSupplierInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="transactionSupplierInvoiceDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Customer Invoice</td>
                  <td>
                    <Checkbox v-model="transactionCustomerInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="transactionCustomerInvoiceDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td></td>
                </tr>
                <!-- <tr>
                  <td class="border-b">Customer Payment</td>
                  <td>
                    <Checkbox v-model="transactionCustomerPaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="transactionCustomerPaymentDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr> -->
                <tr>
                  <td class="border-b">Labor Invoice</td>
                  <td>
                    <Checkbox v-model="transactionLaborInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="transactionLaborInvoiceDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Sack Invoice</td>
                  <td>
                    <Checkbox v-model="transactionSackInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="transactionSackInvoiceDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Rental Parking Invoice</td>
                  <td>
                    <Checkbox v-model="transactionRentalParkingInvoiceView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td></td>

                  <td>
                    <Checkbox v-model="transactionRentalParkingInvoiceDelete" binary
                      class="flex items-start justify-center" />
                  </td>

                  <td class="border-b"></td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Wallet</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllWallet" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Partner</td>
                  <td>
                    <Checkbox v-model="walletPartnerView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletPartnerCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletPartnerDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletPartnerUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>

                <tr>
                  <td class="border-b">Transaction List</td>
                  <td>
                    <Checkbox v-model="walletTransactionListView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Desposite</td>
                  <td>
                    <Checkbox v-model="walletTransactionDepositView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionDepositCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionDepositDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionDepositUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Drawing</td>
                  <td>
                    <Checkbox v-model="walletTransactionWithdrawView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionWithdrawCreate" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionWithdrawDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionWithdrawUpdate" binary
                      class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Transfer</td>
                  <td>
                    <Checkbox v-model="walletTransactionTransferView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionTransferCreate" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionTransferDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="walletTransactionTransferUpdate" binary
                      class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Parking</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllParking" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Parking Display</td>
                  <td>
                    <Checkbox v-model="parkingDisplayView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Move Boat</td>
                  <td>
                    <Checkbox v-model="moveBoatView" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Checkbox v-model="moveBoatUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Boat Mapping</td>
                  <td>
                    <Checkbox v-model="boatMappingView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="boatMappingCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="boatMappingUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Parking List</td>
                  <td>
                    <Checkbox v-model="parkingListView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingListCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingListDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingListUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Warehouse</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllWarehouse" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Rice Stock</td>
                  <td>
                    <Checkbox v-model="warehouseRiceStockView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Convert</td>
                  <td>
                    <Checkbox v-model="warehouseRiceConvertView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseRiceConvertCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseRiceConvertDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Adjust</td>
                  <td>
                    <Checkbox v-model="warehouseAdjustView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseAdjustCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseAdjustDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Rice History</td>
                  <td>
                    <Checkbox v-model="stockRiceHistoryView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Warehouse List</td>
                  <td>
                    <Checkbox v-model="warehouseListView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseListCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseListDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="warehouseListUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">External Weighing</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllExternalWeighing" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Weigh</td>
                  <td>
                    <Checkbox v-model="externalWeighingView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="externalWeighingCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="externalWeighingUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Truck Go</td>
                  <td>
                    <Checkbox v-model="externalWeighingTruckStorageView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="externalWeighingTruckStorageUpdate" binary
                      class="flex items-start justify-center" />
                  </td>
                </tr>

                <tr>
                  <td class="border-b">Weighing Invoice</td>
                  <td>
                    <Checkbox v-model="externalWeighingInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="externalWeighingInvoiceCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Transaction Truck</td>
                  <td>
                    <Checkbox v-model="externalWeighingTransactionTruckView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="externalWeighingTransactionTruckdelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Weighing Invoice Transaction</td>
                  <td>
                    <Checkbox v-model="externalWeighingInvoiceTransactionView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td></td>
                  <td>
                    <Checkbox v-model="externalWeighingInvoiceTransactionDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Parking Rental</td>
                  <td>
                    <Checkbox v-model="externalWeighingRentalParkingView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="externalWeighingRentalParkingCreate" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="externalWeighingRentalParkingDelete" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="externalWeighingRentalParkingUpdate" binary
                      class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Parking Rental</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllParkingRental" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Rental List</td>
                  <td>
                    <Checkbox v-model="parkingRentalView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingRentalCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingRentalDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="parkingRentalUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Report</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllReport" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Purchase Order</td>
                  <td>
                    <Checkbox v-model="reportPurchaseOrderView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Supplier Invoice</td>
                  <td>
                    <Checkbox v-model="reportSupplierInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Supplier Payment</td>
                  <td>
                    <Checkbox v-model="reportSupplierPaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Pre-Loan Repayment</td>
                  <td>
                    <Checkbox v-model="reportPreloanRepaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Customer Order</td>
                  <td>
                    <Checkbox v-model="reportCustomerOrderView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Customer Invoice</td>
                  <td>
                    <Checkbox v-model="reportCustomerInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Customer Payment</td>
                  <td>
                    <Checkbox v-model="reportCustomerPaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Labor Invoice</td>
                  <td>
                    <Checkbox v-model="reportLaborInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Sack Invoice</td>
                  <td>
                    <Checkbox v-model="reportSackInvoiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">Wallet Transaction</td>
                  <td>
                    <Checkbox v-model="reportWalletTransactionView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Wallet History</td>
                  <td>
                    <Checkbox v-model="reportWalletHistoryView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Weighing Expense</td>
                  <td>
                    <Checkbox v-model="reportWeighingExpenseView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">External Weighing Invoice</td>
                  <td>
                    <Checkbox v-model="reportExternalWeighingInvoiceView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>

                <tr>
                  <td class="border-b">External Weighing Customer Payment</td>
                  <td>
                    <Checkbox v-model="reportExternalWeighingCustomerPaymentView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">External Weighing Expense</td>
                  <td>
                    <Checkbox v-model="reportExternalWeighingExpenseView" binary
                      class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Parking Rental</td>
                  <td>
                    <Checkbox v-model="reportParkingRentalView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr>
                  <td class="border-b">Parking Rental Payment</td>
                  <td>
                    <Checkbox v-model="reportParkingRentalPaymentView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
              </table>
            </div>
            <div class="overflow-hidden border rounded-lg">
              <table>
                <tr>
                  <th class="min-w-[300px]">Setting</th>
                  <td class="border-none">View</td>
                  <td>Create</td>
                  <td>Delete</td>
                  <td>Update</td>
                </tr>
                <tr>
                  <td class="border-b">Check All</td>
                  <td colspan="4">
                    <Checkbox v-model="checkAllSetting" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">Rice</td>
                  <td>
                    <Checkbox v-model="settingRiceView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingRiceCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingRiceDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingRiceUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>

                <tr>
                  <td class="border-b">Plates</td>
                  <td>
                    <Checkbox v-model="settingPlatesView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingPlatesCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingPlatesDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingPlatesUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
                <tr>
                  <td class="border-b">User</td>
                  <td>
                    <Checkbox v-model="settingUserView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingUserCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingUserDelete" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingUserUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>

                <tr>
                  <td class="border-b">Telegram Token</td>
                  <td>
                    <Checkbox v-model="settingTelegramTokenView" binary class="flex items-start justify-center" />
                  </td>
                  <td>
                    <Checkbox v-model="settingTelegramTokenCreate" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                </tr>
                <tr v-if="authUserRole === 'Super Admin'">
                  <td class="border-b">User Permission</td>
                  <td>
                    <Checkbox v-model="settingUserPermissionView" binary class="flex items-start justify-center" />
                  </td>
                  <td class="border-b"></td>
                  <td class="border-b"></td>
                  <td>
                    <Checkbox v-model="settingUserPermissionUpdate" binary class="flex items-start justify-center" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="flex w-full items-center gap-3 p-5">
      <div class="">
        <button v-if="!isPending" @click.prevent="handleSubmit" class="btn-submit">
          Save
        </button>
        <button v-else class="relative btn-submit whitespace-nowrap flex items-center justify-center">
          <div>processing...</div>
          <div class="absolute top-3 right-2">
            <div class="mr-2 animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-btcha"></div>
          </div>
        </button>
      </div>
      <button type="button" @click="handleClose" class="btncancel">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { useI18n } from "vue-i18n";
import SaveButton from "@/components/SaveButton.vue";
import CancelBotton from "@/components/CancelBotton.vue";
import { computed, onMounted, ref, watch } from "vue";
import { formatDate } from "@/composables/formatDateTime";
import { getImageUrl } from "@/composables/getImage";
import { formatMobileNumber } from "@/composables/formatMobileNumber";
import axios from "axios";
import apirURL from "@/services/apiURL";
import ToastVue from "@/components/ToastVue.vue";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  components: {
    SaveButton,
    CancelBotton,
    ToastVue,
  },
  props: ["datatoedit"],
  setup(props, { emit }) {
    const dashboardView = ref(true);
    const customerOrderView = ref(false);
    const customerOrderCreate = ref(false);
    const customerOrderUpdate = ref(false);
    const customerOrderDelete = ref(false);
    const purchaseOrderView = ref(false);
    const purchaseOrderCreate = ref(false);
    const purchaseOrderUpdate = ref(false);
    const purchaseOrderDelete = ref(false);
    const supplierView = ref(false);
    const supplierCreate = ref(false);
    const supplierUpdate = ref(false);
    const supplierDelete = ref(false);
    const customerView = ref(false);
    const customerCreate = ref(false);
    const customerUpdate = ref(false);
    const customerDelete = ref(false);
    const weighingView = ref(false);
    const weighingCreate = ref(false);
    const checkAllOrderingBook = ref(false);

    const weighingTruckStorageView = ref(false);
    const weighingTruckStorageUpdate = ref(false);

    const boatStorageView = ref(false);
    const boatStorageUpdate = ref(false);
    const supplierInvoiceView = ref(false);
    const supplierInvoiceCreate = ref(false);

    const customerInvoiceView = ref(false);
    const customerInvoiceCreate = ref(false);

    const laborInvoiceView = ref(false);
    const laborInvoiceCreate = ref(false);

    const sackInvoiceView = ref(false);
    const sackInvoiceCreate = ref(false);

    const transactionTruckGoView = ref(false);
    const transactionOnBoatView = ref(false);
    const transactionSupplierInvoiceView = ref(false);

    const transactionCustomerInvoiceView = ref(false);
    const transactionCustomerPaymentView = ref(false);
    const transactionLaborInvoiceView = ref(false);

    const transactionSackInvoiceView = ref(false);

    const walletPartnerView = ref(false);
    const walletPartnerCreate = ref(false);
    const walletPartnerUpdate = ref(false);
    const walletPartnerDelete = ref(false);
    const walletTransactionListView = ref(false);
    const walletTransactionDepositView = ref(false);
    const walletTransactionDepositCreate = ref(false);
    const walletTransactionDepositUpdate = ref(false);
    const walletTransactionDepositDelete = ref(false);
    const walletTransactionWithdrawView = ref(false);
    const walletTransactionWithdrawCreate = ref(false);
    const walletTransactionWithdrawUpdate = ref(false);
    const walletTransactionWithdrawDelete = ref(false);
    const walletTransactionTransferView = ref(false);
    const walletTransactionTransferCreate = ref(false);

    const customerRepaymentRecordView = ref(false);
    const customerRepaymentRecordCreate = ref(false);
    const customerRepaymentRecordDelete = ref(false);

    const moveBoatView = ref(false);

    const moveBoatUpdate = ref(false);

    const boatMappingView = ref(false);
    const boatMappingCreate = ref(false);
    const parkingListView = ref(false);
    const parkingListCreate = ref(false);
    const parkingListUpdate = ref(false);
    const parkingListDelete = ref(false);
    const warehouseListView = ref(false);
    const warehouseRiceStockView = ref(false);

    const warehouseRiceConvertView = ref(false);
    const warehouseRiceConvertCreate = ref(false);

    const warehouseRiceConvertDelete = ref(false);
    const warehouseAdjustView = ref(false);
    const warehouseAdjustCreate = ref(false);

    const warehouseAdjustDelete = ref(false);
    const warehouseListCreate = ref(false);
    const warehouseListUpdate = ref(false);
    const warehouseListDelete = ref(false);
    const stockRiceHistoryView = ref(false);

    const externalWeighingView = ref(false);
    const externalWeighingCreate = ref(false);
    const externalWeighingUpdate = ref(false);

    const externalWeighingInvoiceView = ref(false);

    const parkingRentalView = ref(false);
    const parkingRentalCreate = ref(false);
    const parkingRentalUpdate = ref(false);
    const parkingRentalDelete = ref(false);

    const reportCustomerInvoiceView = ref(false);
    const reportPurchaseOrderView = ref(false);
    const reportSupplierInvoiceView = ref(false);
    const reportSupplierPaymentView = ref(false);
    const reportClientInvoiceView = ref(false);
    const reportCustomerPaymentView = ref(false);
    const reportLaborInvoiceView = ref(false);
    const reportLaborPaymentView = ref(false);
    const reportSackInvoiceView = ref(false);
    const reportWalletTransactionView = ref(false);
    const reportWalletHistoryView = ref(false);
    const reportExternalWeighingInvoiceView = ref(false);
    const reportParkingRentalView = ref(false);
    const reportParkingRentalPaymentView = ref(false);

    const weighingUpdate = ref(false);

    const settingRiceView = ref(false);
    const settingRiceCreate = ref(false);
    const settingRiceUpdate = ref(false);
    const settingRiceDelete = ref(false);
    const settingPlatesView = ref(false);
    const settingPlatesCreate = ref(false);
    const settingPlatesUpdate = ref(false);
    const settingPlatesDelete = ref(false);

    const settingUserView = ref(false);
    const settingUserCreate = ref(false);
    const settingUserUpdate = ref(false);
    const settingUserDelete = ref(false);
    const settingUserPermissionView = ref(false);
    const settingUserPermissionCreate = ref(false);
    const settingUserPermissionUpdate = ref(false);
    const settingUserPermissionDelete = ref(false);
    const settingTelegramTokenView = ref(false);
    const settingTelegramTokenCreate = ref(false);



    const transactionTruckGoDelete = ref(false);
    const transactionTruckGoUpdate = ref(false);
    const transactionOnBoatDelete = ref(false);
    const transactionOnBoatUpdate = ref(false);
    const transactionSupplierInvoiceDelete = ref(false);
    const transactionCustomerInvoiceDelete = ref(false);

    const transactionCustomerPaymentDelete = ref(false);
    const transactionLaborInvoiceDelete = ref(false);
    const transactionSackInvoiceDelete = ref(false);
    const transactionRentalParkingInvoiceDelete = ref(false);
    const walletTransactionTransferUpdate = ref(false);
    const walletTransactionTransferDelete = ref(false);

    const externalWeighingTruckStorageView = ref(false);
    const externalWeighingTruckStorageUpdate = ref(false);
    const externalWeighingTransactionTruckView = ref(false);
    const externalWeighingTransactionTruckdelete = ref(false);
    const externalWeighingInvoiceTransactionView = ref(false);
    const externalWeighingInvoiceCreate = ref(false);
    const externalWeighingRentalParkingView = ref(false);
    const externalWeighingRentalParkingCreate = ref(false);
    const externalWeighingRentalParkingUpdate = ref(false);
    const externalWeighingRentalParkingDelete = ref(false);

    const customerPaymentView = ref(false);
    const customerPaymentCreate = ref(false);
    const reportExternalWeighingExpenseView = ref(false);
    const preLoanView = ref(false);
    const preLoanCreate = ref(false);
    const preLoanUpdate = ref(false);
    const preLoanDelete = ref(false);

    const reportWeighingExpenseView = ref(false);
    const reportCustomerOrderView = ref(false);
    const reportPreloanRepaymentView = ref(false);

    const reportExternalWeighingCustomerPaymentView = ref(false);

    const externalWeighingInvoiceTransactionDelete = ref(false);

    const transactionRentalParkingInvoiceView = ref(false);

    const boatMappingUpdate = ref(false);

    const parkingDisplayView = ref(false);

    const isPending = ref(false);

    // Add new ref for Purchase Order "Select All"
    const purchaseOrderAll = ref(false);

    // Add new ref variables for each module's "Check All"
    const checkAllContact = ref(false);
    const checkAllWeighing = ref(false);
    const checkAllFinance = ref(false);
    const checkAllPreLoan = ref(false);
    const checkAllTransaction = ref(false);
    const checkAllWallet = ref(false);
    const checkAllParking = ref(false);
    const checkAllWarehouse = ref(false);
    const checkAllExternalWeighing = ref(false);
    const checkAllParkingRental = ref(false);
    const checkAllReport = ref(false);
    const checkAllSetting = ref(false);

    // Add watch functions for each module
    watch(checkAllOrderingBook, (value) => {
      customerOrderView.value = value;
      customerOrderCreate.value = value;
      customerOrderDelete.value = value;
      customerOrderUpdate.value = value;
      purchaseOrderView.value = value;
      purchaseOrderCreate.value = value;
      purchaseOrderDelete.value = value;
      purchaseOrderUpdate.value = value;
    });

    watch(checkAllContact, (value) => {
      supplierView.value = value;
      supplierCreate.value = value;
      supplierUpdate.value = value;
      supplierDelete.value = value;
      customerView.value = value;
      customerCreate.value = value;
      customerUpdate.value = value;
      customerDelete.value = value;
    });

    watch(checkAllWeighing, (value) => {
      weighingView.value = value;
      weighingCreate.value = value;
      weighingUpdate.value = value;
      weighingTruckStorageView.value = value;
      weighingTruckStorageUpdate.value = value;
      boatStorageView.value = value;
      boatStorageUpdate.value = value;
    });

    watch(checkAllFinance, (value) => {
      supplierInvoiceView.value = value;
      supplierInvoiceCreate.value = value;
      customerInvoiceView.value = value;
      customerInvoiceCreate.value = value;
      customerPaymentView.value = value;
      customerPaymentCreate.value = value;
      customerRepaymentRecordView.value = value;
      customerRepaymentRecordDelete.value = value;
      laborInvoiceView.value = value;
      laborInvoiceCreate.value = value;
      sackInvoiceView.value = value;
      sackInvoiceCreate.value = value;
    });

    watch(checkAllPreLoan, (value) => {
      preLoanView.value = value;
      preLoanCreate.value = value;
      preLoanUpdate.value = value;
      preLoanDelete.value = value;
    });

    watch(checkAllTransaction, (value) => {
      transactionTruckGoView.value = value;
      transactionTruckGoDelete.value = value;
      transactionTruckGoUpdate.value = value;
      transactionOnBoatView.value = value;
      transactionOnBoatDelete.value = value;
      transactionOnBoatUpdate.value = value;
      transactionSupplierInvoiceView.value = value;
      transactionSupplierInvoiceDelete.value = value;
      transactionCustomerInvoiceView.value = value;
      transactionCustomerInvoiceDelete.value = value;
      transactionLaborInvoiceView.value = value;
      transactionLaborInvoiceDelete.value = value;
      transactionSackInvoiceView.value = value;
      transactionSackInvoiceDelete.value = value;
      transactionRentalParkingInvoiceView.value = value;
      transactionRentalParkingInvoiceDelete.value = value;
    });

    watch(checkAllWallet, (value) => {
      walletPartnerView.value = value;
      walletPartnerCreate.value = value;
      walletPartnerUpdate.value = value;
      walletPartnerDelete.value = value;
      walletTransactionListView.value = value;
      walletTransactionDepositView.value = value;
      walletTransactionDepositCreate.value = value;
      walletTransactionDepositUpdate.value = value;
      walletTransactionDepositDelete.value = value;
      walletTransactionWithdrawView.value = value;
      walletTransactionWithdrawCreate.value = value;
      walletTransactionWithdrawUpdate.value = value;
      walletTransactionWithdrawDelete.value = value;
      walletTransactionTransferView.value = value;
      walletTransactionTransferCreate.value = value;
      walletTransactionTransferUpdate.value = value;
      walletTransactionTransferDelete.value = value;
    });

    watch(checkAllParking, (value) => {
      parkingDisplayView.value = value;
      moveBoatView.value = value;
      moveBoatUpdate.value = value;
      boatMappingView.value = value;
      boatMappingCreate.value = value;
      boatMappingUpdate.value = value;
      parkingListView.value = value;
      parkingListCreate.value = value;
      parkingListUpdate.value = value;
      parkingListDelete.value = value;
    });

    watch(checkAllWarehouse, (value) => {
      warehouseRiceStockView.value = value;
      warehouseRiceConvertView.value = value;
      warehouseRiceConvertCreate.value = value;
      warehouseRiceConvertDelete.value = value;
      warehouseAdjustView.value = value;
      warehouseAdjustCreate.value = value;
      warehouseAdjustDelete.value = value;
      stockRiceHistoryView.value = value;
      warehouseListView.value = value;
      warehouseListCreate.value = value;
      warehouseListUpdate.value = value;
      warehouseListDelete.value = value;
    });

    watch(checkAllExternalWeighing, (value) => {
      externalWeighingView.value = value;
      externalWeighingCreate.value = value;
      externalWeighingUpdate.value = value;
      externalWeighingTruckStorageView.value = value;
      externalWeighingTruckStorageUpdate.value = value;
      externalWeighingInvoiceView.value = value;
      externalWeighingInvoiceCreate.value = value;
      externalWeighingTransactionTruckView.value = value;
      externalWeighingTransactionTruckdelete.value = value;
      externalWeighingInvoiceTransactionView.value = value;
      externalWeighingInvoiceTransactionDelete.value = value;
      externalWeighingRentalParkingView.value = value;
      externalWeighingRentalParkingCreate.value = value;
      externalWeighingRentalParkingUpdate.value = value;
      externalWeighingRentalParkingDelete.value = value;
    });

    watch(checkAllParkingRental, (value) => {
      parkingRentalView.value = value;
      parkingRentalCreate.value = value;
      parkingRentalUpdate.value = value;
      parkingRentalDelete.value = value;
    });

    watch(checkAllReport, (value) => {
      reportPurchaseOrderView.value = value;
      reportSupplierInvoiceView.value = value;
      reportSupplierPaymentView.value = value;
      reportPreloanRepaymentView.value = value;
      reportCustomerOrderView.value = value;
      reportCustomerInvoiceView.value = value;
      reportCustomerPaymentView.value = value;
      reportLaborInvoiceView.value = value;
      reportSackInvoiceView.value = value;
      reportWalletTransactionView.value = value;
      reportWalletHistoryView.value = value;
      reportWeighingExpenseView.value = value;
      reportExternalWeighingInvoiceView.value = value;
      reportExternalWeighingCustomerPaymentView.value = value;
      reportParkingRentalView.value = value;
      reportParkingRentalPaymentView.value = value;
      reportExternalWeighingExpenseView.value = value;
    });

    watch(checkAllSetting, (value) => {
      settingRiceView.value = value;
      settingRiceCreate.value = value;
      settingRiceUpdate.value = value;
      settingRiceDelete.value = value;
      settingPlatesView.value = value;
      settingPlatesCreate.value = value;
      settingPlatesUpdate.value = value;
      settingPlatesDelete.value = value;
      settingUserView.value = value;
      settingUserCreate.value = value;
      settingUserUpdate.value = value;
      settingUserDelete.value = value;
      settingUserPermissionView.value = value;
      settingUserPermissionCreate.value = value;
      settingUserPermissionUpdate.value = value;
      settingUserPermissionDelete.value = value;
      settingTelegramTokenView.value = value;
      settingTelegramTokenCreate.value = value;

    });

    // Ordering Book Module
    watch(
      [
        customerOrderView,
        customerOrderCreate,
        customerOrderDelete,
        customerOrderUpdate,
        purchaseOrderView,
        purchaseOrderCreate,
        purchaseOrderDelete,
        purchaseOrderUpdate,
      ],
      () => {
        if (
          customerOrderView.value &&
          customerOrderCreate.value &&
          customerOrderDelete.value &&
          customerOrderUpdate.value &&
          purchaseOrderView.value &&
          purchaseOrderCreate.value &&
          purchaseOrderDelete.value &&
          purchaseOrderUpdate.value
        ) {
          checkAllOrderingBook.value = true;
        }
      }
    );

    // Contact Module
    watch(
      [
        supplierView,
        supplierCreate,
        supplierUpdate,
        supplierDelete,
        customerView,
        customerCreate,
        customerUpdate,
        customerDelete,
      ],
      () => {
        if (
          supplierView.value &&
          supplierCreate.value &&
          supplierUpdate.value &&
          supplierDelete.value &&
          customerView.value &&
          customerCreate.value &&
          customerUpdate.value &&
          customerDelete.value
        ) {
          checkAllContact.value = true;
        } else {
          checkAllContact.value = false;
        }
      }
    );

    // Weighing Module
    watch(
      [
        weighingView,
        weighingCreate,
        weighingUpdate,
        weighingTruckStorageView,
        weighingTruckStorageUpdate,
        boatStorageView,
        boatStorageUpdate,
      ],
      () => {
        if (
          weighingView.value &&
          weighingCreate.value &&
          weighingUpdate.value &&
          weighingTruckStorageView.value &&
          weighingTruckStorageUpdate.value &&
          boatStorageView.value &&
          boatStorageUpdate.value
        ) {
          checkAllWeighing.value = true;
        } else {
          checkAllWeighing.value = false;
        }
      }
    );

    // Finance Module
    watch(
      [
        supplierInvoiceView,
        supplierInvoiceCreate,
        customerInvoiceView,
        customerInvoiceCreate,
        customerPaymentView,
        customerPaymentCreate,
        customerRepaymentRecordView,
        customerRepaymentRecordDelete,
        laborInvoiceView,
        laborInvoiceCreate,
        sackInvoiceView,
        sackInvoiceCreate,
      ],
      () => {
        if (
          supplierInvoiceView.value &&
          supplierInvoiceCreate.value &&
          customerInvoiceView.value &&
          customerInvoiceCreate.value &&
          customerPaymentView.value &&
          customerPaymentCreate.value &&
          customerRepaymentRecordView.value &&
          customerRepaymentRecordDelete.value &&
          laborInvoiceView.value &&
          laborInvoiceCreate.value &&
          sackInvoiceView.value &&
          sackInvoiceCreate.value
        ) {
          checkAllFinance.value = true;
        } else {
          checkAllFinance.value = false;
        }
      }
    );

    // Pre Loan Module
    watch([preLoanView, preLoanCreate, preLoanUpdate, preLoanDelete], () => {
      if (
        preLoanView.value &&
        preLoanCreate.value &&
        preLoanUpdate.value &&
        preLoanDelete.value
      ) {
        checkAllPreLoan.value = true;
      } else {
        checkAllPreLoan.value = false;
      }
    });

    // Transaction Module
    watch(
      [
        transactionTruckGoView,
        transactionTruckGoDelete,
        transactionTruckGoUpdate,
        transactionOnBoatView,
        transactionOnBoatDelete,
        transactionOnBoatUpdate,
        transactionSupplierInvoiceView,
        transactionSupplierInvoiceDelete,
        transactionCustomerInvoiceView,
        transactionCustomerInvoiceDelete,
        transactionLaborInvoiceView,
        transactionLaborInvoiceDelete,
        transactionSackInvoiceView,
        transactionSackInvoiceDelete,
        transactionRentalParkingInvoiceView,
        transactionRentalParkingInvoiceDelete,
      ],
      () => {
        if (
          transactionTruckGoView.value &&
          transactionTruckGoDelete.value &&
          transactionTruckGoUpdate.value &&
          transactionOnBoatView.value &&
          transactionOnBoatDelete.value &&
          transactionOnBoatUpdate.value &&
          transactionSupplierInvoiceView.value &&
          transactionSupplierInvoiceDelete.value &&
          transactionCustomerInvoiceView.value &&
          transactionCustomerInvoiceDelete.value &&
          transactionLaborInvoiceView.value &&
          transactionLaborInvoiceDelete.value &&
          transactionSackInvoiceView.value &&
          transactionSackInvoiceDelete.value &&
          transactionRentalParkingInvoiceView.value &&
          transactionRentalParkingInvoiceDelete.value
        ) {
          checkAllTransaction.value = true;
        } else {
          checkAllTransaction.value = false;
        }
      }
    );

    // Wallet Module
    watch(
      [
        walletPartnerView,
        walletPartnerCreate,
        walletPartnerUpdate,
        walletPartnerDelete,
        walletTransactionListView,
        walletTransactionDepositView,
        walletTransactionDepositCreate,
        walletTransactionDepositUpdate,
        walletTransactionDepositDelete,
        walletTransactionWithdrawView,
        walletTransactionWithdrawCreate,
        walletTransactionWithdrawUpdate,
        walletTransactionWithdrawDelete,
        walletTransactionTransferView,
        walletTransactionTransferCreate,
        walletTransactionTransferUpdate,
        walletTransactionTransferDelete,
      ],
      () => {
        if (
          walletPartnerView.value &&
          walletPartnerCreate.value &&
          walletPartnerUpdate.value &&
          walletPartnerDelete.value &&
          walletTransactionListView.value &&
          walletTransactionDepositView.value &&
          walletTransactionDepositCreate.value &&
          walletTransactionDepositUpdate.value &&
          walletTransactionDepositDelete.value &&
          walletTransactionWithdrawView.value &&
          walletTransactionWithdrawCreate.value &&
          walletTransactionWithdrawUpdate.value &&
          walletTransactionWithdrawDelete.value &&
          walletTransactionTransferView.value &&
          walletTransactionTransferCreate.value &&
          walletTransactionTransferUpdate.value &&
          walletTransactionTransferDelete.value
        ) {
          checkAllWallet.value = true;
        } else {
          checkAllWallet.value = false;
        }
      }
    );

    // Parking Module
    watch(
      [
        parkingDisplayView,
        moveBoatView,
        moveBoatUpdate,
        boatMappingView,
        boatMappingCreate,
        boatMappingUpdate,
        parkingListView,
        parkingListCreate,
        parkingListUpdate,
        parkingListDelete,
      ],
      () => {
        if (
          parkingDisplayView.value &&
          moveBoatView.value &&
          moveBoatUpdate.value &&
          boatMappingView.value &&
          boatMappingCreate.value &&
          boatMappingUpdate.value &&
          parkingListView.value &&
          parkingListCreate.value &&
          parkingListUpdate.value &&
          parkingListDelete.value
        ) {
          checkAllParking.value = true;
        } else {
          checkAllParking.value = false;
        }
      }
    );

    // Warehouse Module
    watch(
      [
        warehouseRiceStockView,
        warehouseRiceConvertView,
        warehouseRiceConvertCreate,
        warehouseRiceConvertDelete,
        warehouseAdjustView,
        warehouseAdjustCreate,
        warehouseAdjustDelete,
        stockRiceHistoryView,
        warehouseListView,
        warehouseListCreate,
        warehouseListUpdate,
        warehouseListDelete,
      ],
      () => {
        if (
          warehouseRiceStockView.value &&
          warehouseRiceConvertView.value &&
          warehouseRiceConvertCreate.value &&
          warehouseRiceConvertDelete.value &&
          warehouseAdjustView.value &&
          warehouseAdjustCreate.value &&
          warehouseAdjustDelete.value &&
          stockRiceHistoryView.value &&
          warehouseListView.value &&
          warehouseListCreate.value &&
          warehouseListUpdate.value &&
          warehouseListDelete.value
        ) {
          checkAllWarehouse.value = true;
        } else {
          checkAllWarehouse.value = false;
        }
      }
    );

    // External Weighing Module
    watch(
      [
        externalWeighingView,
        externalWeighingCreate,
        externalWeighingUpdate,
        externalWeighingTruckStorageView,
        externalWeighingTruckStorageUpdate,
        externalWeighingInvoiceView,
        externalWeighingInvoiceCreate,
        externalWeighingTransactionTruckView,
        externalWeighingTransactionTruckdelete,
        externalWeighingInvoiceTransactionView,
        externalWeighingInvoiceTransactionDelete,
        externalWeighingRentalParkingView,
        externalWeighingRentalParkingCreate,
        externalWeighingRentalParkingUpdate,
        externalWeighingRentalParkingDelete,
      ],
      () => {
        if (
          externalWeighingView.value &&
          externalWeighingCreate.value &&
          externalWeighingUpdate.value &&
          externalWeighingTruckStorageView.value &&
          externalWeighingTruckStorageUpdate.value &&
          externalWeighingInvoiceView.value &&
          externalWeighingInvoiceCreate.value &&
          externalWeighingTransactionTruckView.value &&
          externalWeighingTransactionTruckdelete.value &&
          externalWeighingInvoiceTransactionView.value &&
          externalWeighingInvoiceTransactionDelete.value &&
          externalWeighingRentalParkingView.value &&
          externalWeighingRentalParkingCreate.value &&
          externalWeighingRentalParkingUpdate.value &&
          externalWeighingRentalParkingDelete.value
        ) {
          checkAllExternalWeighing.value = true;
        } else {
          checkAllExternalWeighing.value = false;
        }
      }
    );

    // Parking Rental Module
    watch(
      [
        parkingRentalView,
        parkingRentalCreate,
        parkingRentalUpdate,
        parkingRentalDelete,
      ],
      () => {
        if (
          parkingRentalView.value &&
          parkingRentalCreate.value &&
          parkingRentalUpdate.value &&
          parkingRentalDelete.value
        ) {
          checkAllParkingRental.value = true;
        } else {
          checkAllParkingRental.value = false;
        }
      }
    );

    // Report Module
    watch(
      [
        reportPurchaseOrderView,
        reportSupplierInvoiceView,
        reportSupplierPaymentView,
        reportPreloanRepaymentView,
        reportCustomerOrderView,
        reportCustomerInvoiceView,
        reportCustomerPaymentView,
        reportLaborInvoiceView,
        reportSackInvoiceView,
        reportWalletTransactionView,
        reportWalletHistoryView,
        reportWeighingExpenseView,
        reportExternalWeighingInvoiceView,
        reportExternalWeighingCustomerPaymentView,
        reportParkingRentalView,
        reportParkingRentalPaymentView,
      ],
      () => {
        if (
          reportPurchaseOrderView.value &&
          reportSupplierInvoiceView.value &&
          reportSupplierPaymentView.value &&
          reportPreloanRepaymentView.value &&
          reportCustomerOrderView.value &&
          reportCustomerInvoiceView.value &&
          reportCustomerPaymentView.value &&
          reportLaborInvoiceView.value &&
          reportSackInvoiceView.value &&
          reportWalletTransactionView.value &&
          reportWalletHistoryView.value &&
          reportWeighingExpenseView.value &&
          reportExternalWeighingInvoiceView.value &&
          reportExternalWeighingCustomerPaymentView.value &&
          reportParkingRentalView.value &&
          reportParkingRentalPaymentView.value &&
          reportExternalWeighingExpenseView.value
        ) {
          checkAllReport.value = true;
        } else {
          checkAllReport.value = false;
        }
      }
    );

    // Setting Module
    watch(
      [
        settingRiceView,
        settingRiceCreate,
        settingRiceUpdate,
        settingRiceDelete,
        settingPlatesView,
        settingPlatesCreate,
        settingPlatesUpdate,
        settingPlatesDelete,
        settingUserView,
        settingUserCreate,
        settingUserUpdate,
        settingUserDelete,
        settingUserPermissionView,
        settingUserPermissionCreate,
        settingUserPermissionUpdate,
        settingUserPermissionDelete,
        settingTelegramTokenView,
        settingTelegramTokenCreate,


      ],
      () => {
        if (
          settingRiceView.value &&
          settingRiceCreate.value &&
          settingRiceUpdate.value &&
          settingRiceDelete.value &&
          settingPlatesView.value &&
          settingPlatesCreate.value &&
          settingPlatesUpdate.value &&
          settingPlatesDelete.value &&
          settingUserView.value &&
          settingUserCreate.value &&
          settingUserUpdate.value &&
          settingUserDelete.value &&
          settingUserPermissionView.value &&
          settingUserPermissionCreate.value &&
          settingUserPermissionUpdate.value &&
          settingUserPermissionDelete.value &&
          settingTelegramTokenView.value &&
          settingTelegramTokenCreate.value
        ) {
          checkAllSetting.value = true;
        } else {
          checkAllSetting.value = false;
        }
      }
    );

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleClose = () => {
      emit("close");
    };

    const handleClear = () => {
      isPending.value = false;

      // Reset all permission variables
      dashboardView.value = false;
      weighingUpdate.value = false;
      customerOrderView.value = false;
      customerOrderCreate.value = false;
      customerOrderUpdate.value = false;
      customerOrderDelete.value = false;
      purchaseOrderView.value = false;
      purchaseOrderCreate.value = false;
      purchaseOrderUpdate.value = false;
      purchaseOrderDelete.value = false;
      supplierView.value = false;
      supplierCreate.value = false;
      supplierUpdate.value = false;
      supplierDelete.value = false;
      customerView.value = false;
      customerCreate.value = false;
      customerUpdate.value = false;
      customerDelete.value = false;
      weighingView.value = false;
      weighingCreate.value = false;
      weighingTruckStorageView.value = false;
      weighingTruckStorageUpdate.value = false;
      boatStorageView.value = false;
      boatStorageUpdate.value = false;
      supplierInvoiceView.value = false;
      supplierInvoiceCreate.value = false;
      customerInvoiceView.value = false;
      customerInvoiceCreate.value = false;
      laborInvoiceView.value = false;
      laborInvoiceCreate.value = false;
      sackInvoiceView.value = false;
      sackInvoiceCreate.value = false;
      transactionTruckGoView.value = false;
      transactionOnBoatView.value = false;
      transactionSupplierInvoiceView.value = false;
      transactionCustomerInvoiceView.value = false;
      transactionCustomerPaymentView.value = false;
      transactionLaborInvoiceView.value = false;
      transactionSackInvoiceView.value = false;
      walletPartnerView.value = false;
      walletPartnerCreate.value = false;
      walletPartnerUpdate.value = false;
      walletPartnerDelete.value = false;
      walletTransactionListView.value = false;
      walletTransactionDepositView.value = false;
      walletTransactionDepositCreate.value = false;
      walletTransactionDepositUpdate.value = false;
      walletTransactionDepositDelete.value = false;
      walletTransactionWithdrawView.value = false;
      walletTransactionWithdrawCreate.value = false;
      walletTransactionWithdrawUpdate.value = false;
      walletTransactionWithdrawDelete.value = false;
      walletTransactionTransferView.value = false;
      walletTransactionTransferCreate.value = false;
      moveBoatView.value = false;
      moveBoatUpdate.value = false;
      boatMappingView.value = false;
      boatMappingCreate.value = false;
      parkingListView.value = false;
      parkingListCreate.value = false;
      parkingListUpdate.value = false;
      parkingListDelete.value = false;
      warehouseListView.value = false;
      warehouseRiceStockView.value = false;
      warehouseRiceConvertView.value = false;
      warehouseRiceConvertCreate.value = false;
      warehouseRiceConvertDelete.value = false;
      warehouseAdjustView.value = false;
      warehouseAdjustCreate.value = false;
      warehouseAdjustDelete.value = false;
      warehouseListCreate.value = false;
      warehouseListUpdate.value = false;
      warehouseListDelete.value = false;
      stockRiceHistoryView.value = false;
      externalWeighingView.value = false;
      externalWeighingCreate.value = false;
      externalWeighingUpdate.value = false;
      externalWeighingInvoiceView.value = false;
      parkingRentalView.value = false;
      parkingRentalCreate.value = false;
      parkingRentalUpdate.value = false;
      parkingRentalDelete.value = false;
      reportCustomerInvoiceView.value = false;
      reportPurchaseOrderView.value = false;
      reportSupplierInvoiceView.value = false;
      reportSupplierPaymentView.value = false;
      reportClientInvoiceView.value = false;
      reportCustomerPaymentView.value = false;
      reportLaborInvoiceView.value = false;
      reportLaborPaymentView.value = false;
      reportSackInvoiceView.value = false;
      reportWalletTransactionView.value = false;
      reportWalletHistoryView.value = false;
      reportExternalWeighingInvoiceView.value = false;
      reportParkingRentalView.value = false;
      reportParkingRentalPaymentView.value = false;
      settingRiceView.value = false;
      settingRiceCreate.value = false;
      settingRiceUpdate.value = false;
      settingRiceDelete.value = false;
      settingUserView.value = false;
      settingUserCreate.value = false;
      settingUserUpdate.value = false;
      settingUserDelete.value = false;
      settingUserPermissionView.value = false;
      settingUserPermissionCreate.value = false;
      settingUserPermissionUpdate.value = false;
      settingUserPermissionDelete.value = false;
      transactionTruckGoDelete.value = false;
      transactionTruckGoUpdate.value = false;
      transactionOnBoatDelete.value = false;
      transactionOnBoatUpdate.value = false;
      transactionSupplierInvoiceDelete.value = false;
      transactionCustomerInvoiceDelete.value = false;
      transactionCustomerPaymentDelete.value = false;
      transactionLaborInvoiceDelete.value = false;
      transactionSackInvoiceDelete.value = false;
      transactionRentalParkingInvoiceDelete.value = false;
      transactionRentalParkingInvoiceView.value = false;
      walletTransactionTransferUpdate.value = false;
      walletTransactionTransferDelete.value = false;
      externalWeighingTruckStorageView.value = false;
      externalWeighingTruckStorageUpdate.value = false;
      externalWeighingTransactionTruckView.value = false;
      externalWeighingTransactionTruckdelete.value = false;
      externalWeighingInvoiceView.value = false;
      externalWeighingInvoiceCreate.value = false;
      externalWeighingInvoiceTransactionDelete.value = false;
      boatMappingUpdate.value = false;
      parkingDisplayView.value = false;
      purchaseOrderAll.value = false;
      checkAllContact.value = false;
      checkAllWeighing.value = false;
      checkAllFinance.value = false;
      checkAllPreLoan.value = false;
      checkAllTransaction.value = false;
      checkAllWallet.value = false;
      checkAllParking.value = false;
      checkAllWarehouse.value = false;
      checkAllExternalWeighing.value = false;
      checkAllParkingRental.value = false;
      checkAllReport.value = false;
      checkAllSetting.value = false;
      settingTelegramTokenView.value = false;
      settingTelegramTokenCreate.value = false;

    };
    // translate
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        const permissions = {
          // Dashboard
          module_dashboard: {
            dashboard: true,
          },

          // Ordering Book
          module_ordering_book: {
            customer_order: {
              view: customerOrderView.value,
              create: customerOrderCreate.value,
              update: customerOrderUpdate.value,
              delete: customerOrderDelete.value,
            },
            purchase_order: {
              view: purchaseOrderView.value,
              create: purchaseOrderCreate.value,
              update: purchaseOrderUpdate.value,
              delete: purchaseOrderDelete.value,
            },
          },

          // Contact
          module_contact: {
            supplier: {
              view: supplierView.value,
              create: supplierCreate.value,
              update: supplierUpdate.value,
              delete: supplierDelete.value,
            },
            customer: {
              view: customerView.value,
              create: customerCreate.value,
              update: customerUpdate.value,
              delete: customerDelete.value,
            },
          },

          // Weighing
          module_weighing: {
            weight: {
              view: weighingView.value,
              create: weighingCreate.value,
              update: weighingUpdate.value,
            },
            truck_storage: {
              view: weighingTruckStorageView.value,
              update: weighingTruckStorageUpdate.value,
            },
            boat_storage: {
              view: boatStorageView.value,
              update: boatStorageUpdate.value,
            },
          },

          // Finance
          module_finance: {
            supplier_invoice: {
              view: supplierInvoiceView.value,
              create: supplierInvoiceCreate.value,
            },
            customer_invoice: {
              view: customerInvoiceView.value,
              create: customerInvoiceCreate.value,
            },

            customer_payment: {
              view: customerPaymentView.value,
              create: customerPaymentCreate.value,
            },

            customer_repayment_record: {
              view: customerRepaymentRecordView.value,
              create: customerRepaymentRecordCreate.value,
              delete: customerRepaymentRecordDelete.value,
            },

            labor_invoice: {
              view: laborInvoiceView.value,
              create: laborInvoiceCreate.value,
            },
            sack_invoice: {
              view: sackInvoiceView.value,
              create: sackInvoiceCreate.value,
            },
          },

          //preloans
          module_pre_loan: {
            pre_loan: {
              view: preLoanView.value,
              create: preLoanCreate.value,
              update: preLoanUpdate.value,
              delete: preLoanDelete.value,
            },
          },

          // Transaction
          module_transaction: {
            truck_go: {
              view: transactionTruckGoView.value,
              delete: transactionTruckGoDelete.value,
              update: transactionTruckGoUpdate.value,
            },
            on_boat: {
              view: transactionOnBoatView.value,
              delete: transactionOnBoatDelete.value,
              update: transactionOnBoatUpdate.value,
            },
            supplier_invoice: {
              view: transactionSupplierInvoiceView.value,
              delete: transactionSupplierInvoiceDelete.value,

            },

            customer_invoice: {
              view: transactionCustomerInvoiceView.value,
              delete: transactionCustomerInvoiceDelete.value,
            },

            labor_invoice: {
              view: transactionLaborInvoiceView.value,
              delete: transactionLaborInvoiceDelete.value,
            },

            sack_invoice: {
              view: transactionSackInvoiceView.value,
              delete: transactionSackInvoiceDelete.value,
            },

            rental_parking_invoice: {
              view: transactionRentalParkingInvoiceView.value,
              delete: transactionRentalParkingInvoiceDelete.value,
            },
          },

          // Wallet
          module_wallet: {
            partner: {
              view: walletPartnerView.value,
              create: walletPartnerCreate.value,
              update: walletPartnerUpdate.value,
              delete: walletPartnerDelete.value,
            },
            transaction_list: { view: walletTransactionListView.value },
            deposit: {
              view: walletTransactionDepositView.value,
              create: walletTransactionDepositCreate.value,
              update: walletTransactionDepositUpdate.value,
              delete: walletTransactionDepositDelete.value,
            },
            withdraw: {
              view: walletTransactionWithdrawView.value,
              create: walletTransactionWithdrawCreate.value,
              update: walletTransactionWithdrawUpdate.value,
              delete: walletTransactionWithdrawDelete.value,
            },
            transfer: {
              view: walletTransactionTransferView.value,
              create: walletTransactionTransferCreate.value,
              update: walletTransactionTransferUpdate.value,
              delete: walletTransactionTransferDelete.value,
            },
          },

          // Parking
          module_parking: {
            parking_display: {
              view: parkingDisplayView.value,
            },
            move_boat: {
              view: moveBoatView.value,
              update: moveBoatUpdate.value,
            },
            boat_mapping: {
              view: boatMappingView.value,
              create: boatMappingCreate.value,
              update: boatMappingUpdate.value,
            },
            parking_list: {
              view: parkingListView.value,
              create: parkingListCreate.value,
              update: parkingListUpdate.value,
              delete: parkingListDelete.value,
            },
          },

          // Warehouse
          module_warehouse: {
            rice_stock: {
              view: warehouseRiceStockView.value,
            },
            rice_convert: {
              view: warehouseRiceConvertView.value,
              create: warehouseRiceConvertCreate.value,

              delete: warehouseRiceConvertDelete.value,
            },
            adjust: {
              view: warehouseAdjustView.value,
              create: warehouseAdjustCreate.value,

              delete: warehouseAdjustDelete.value,
            },
            stock_history: {
              view: stockRiceHistoryView.value,
            },
            warehouse_list: {
              view: warehouseListView.value,
              create: warehouseListCreate.value,
              update: warehouseListUpdate.value,
              delete: warehouseListDelete.value,
            },
          },

          // External Weighing
          module_external_weighing: {
            weighing_external: {
              view: externalWeighingView.value,
              create: externalWeighingCreate.value,
              update: externalWeighingUpdate.value,
            },
            truck_go_external: {
              view: externalWeighingTruckStorageView.value,
              update: externalWeighingTruckStorageUpdate.value,
            },

            weighing_invoice_external: {
              view: externalWeighingInvoiceView.value,
              create: externalWeighingInvoiceCreate.value,
            },
            transaction_truck_external: {
              view: externalWeighingTransactionTruckView.value,
              delete: externalWeighingTransactionTruckdelete.value,
            },

            weighing_invoice_transaction_external: {
              view: externalWeighingInvoiceTransactionView.value,
              delete: externalWeighingInvoiceTransactionDelete.value,
            },

            weighing_external_rental_parking: {
              view: externalWeighingRentalParkingView.value,
              create: externalWeighingRentalParkingCreate.value,
              update: externalWeighingRentalParkingUpdate.value,
              delete: externalWeighingRentalParkingDelete.value,
            },
          },

          // Parking Rental
          module_parking_rental: {
            rental_list: {
              view: parkingRentalView.value,
              create: parkingRentalCreate.value,
              update: parkingRentalUpdate.value,
              delete: parkingRentalDelete.value,
            },
          },

          // Report
          module_report: {
            purchase_order_report: { view: reportPurchaseOrderView.value },
            supplier_invoice: { view: reportSupplierInvoiceView.value },
            supplier_payment: { view: reportSupplierPaymentView.value },
            preloan_repayment: { view: reportPreloanRepaymentView.value },
            customer_order_report: { view: reportCustomerOrderView.value },
            customer_invoice: { view: reportCustomerInvoiceView.value },
            customer_payment: { view: reportCustomerPaymentView.value },
            labor_invoice: { view: reportLaborInvoiceView.value },
            sack_invoice: { view: reportSackInvoiceView.value },
            wallet_transaction: { view: reportWalletTransactionView.value },
            wallet_history: { view: reportWalletHistoryView.value },
            weighing_expense: { view: reportWeighingExpenseView.value },
            external_weighing_invoice: {
              view: reportExternalWeighingInvoiceView.value,
            },
            external_weighing_customer_payment: {
              view: reportExternalWeighingCustomerPaymentView.value,
            },
            external_weighing_expense: {
              view: reportExternalWeighingExpenseView.value,
            },
            parking_rental: { view: reportParkingRentalView.value },
            parking_rental_payment: {
              view: reportParkingRentalPaymentView.value,
            },
          },

          // Setting
          module_setting: {
            rice: {
              view: settingRiceView.value,
              create: settingRiceCreate.value,
              update: settingRiceUpdate.value,
              delete: settingRiceDelete.value,
            },
            plate: {
              view: settingPlatesView.value,
              create: settingPlatesCreate.value,
              update: settingPlatesUpdate.value,
              delete: settingPlatesDelete.value,
            },
            user: {
              view: settingUserView.value,
              create: settingUserCreate.value,
              update: settingUserUpdate.value,
              delete: settingUserDelete.value,
            },
            user_permission: {
              view: settingUserPermissionView.value,
              create: settingUserPermissionCreate.value,
              update: settingUserPermissionUpdate.value,
              delete: settingUserPermissionDelete.value,
            },

            telegram_token: {
              view: settingTelegramTokenView.value,
              create: settingTelegramTokenCreate.value,

            },
          },
        };

        const requestBody = {
          tableName: "users",
          fields: {
            permissions: JSON.stringify(permissions),
          },
        };

        await axios.patch(
          `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
          requestBody
        );

        handleClear();
        handleClose();
        isPending.value = false;
      } catch (error) {
        console.error("Error submitting form:", error);
        isPending.value = false;
      }
    };

    function formatTagName(tag) {
      return tag.map((item) => item).join(", ");
    }

    onMounted(() => {
      fetchUserByID();
      if (props.datatoedit) {
        const permissions = props.datatoedit.permissions;

        // Dashboard
        dashboardView.value = permissions?.module_dashboard?.dashboard;

        // Ordering Book
        customerOrderView.value =
          permissions?.module_ordering_book?.customer_order?.view || false;
        customerOrderCreate.value =
          permissions?.module_ordering_book?.customer_order?.create || false;
        customerOrderUpdate.value =
          permissions?.module_ordering_book?.customer_order?.update || false;
        customerOrderDelete.value =
          permissions?.module_ordering_book?.customer_order?.delete || false;
        purchaseOrderView.value =
          permissions?.module_ordering_book?.purchase_order?.view || false;
        purchaseOrderCreate.value =
          permissions?.module_ordering_book?.purchase_order?.create || false;
        purchaseOrderUpdate.value =
          permissions?.module_ordering_book?.purchase_order?.update || false;
        purchaseOrderDelete.value =
          permissions?.module_ordering_book?.purchase_order?.delete || false;

        // Contact
        supplierView.value =
          permissions?.module_contact?.supplier?.view || false;
        supplierCreate.value =
          permissions?.module_contact?.supplier?.create || false;
        supplierUpdate.value =
          permissions?.module_contact?.supplier?.update || false;
        supplierDelete.value =
          permissions?.module_contact?.supplier?.delete || false;
        customerView.value =
          permissions?.module_contact?.customer?.view || false;
        customerCreate.value =
          permissions?.module_contact?.customer?.create || false;
        customerUpdate.value =
          permissions?.module_contact?.customer?.update || false;
        customerDelete.value =
          permissions?.module_contact?.customer?.delete || false;

        // Weighing
        weighingView.value =
          permissions?.module_weighing?.weight?.view || false;
        weighingCreate.value =
          permissions?.module_weighing?.weight?.create || false;
        weighingUpdate.value =
          permissions?.module_weighing?.weight?.update || false;
        weighingTruckStorageView.value =
          permissions?.module_weighing?.truck_storage?.view || false;
        weighingTruckStorageUpdate.value =
          permissions?.module_weighing?.truck_storage?.update || false;
        boatStorageView.value =
          permissions?.module_weighing?.boat_storage?.view || false;
        boatStorageUpdate.value =
          permissions?.module_weighing?.boat_storage?.update || false;

        // Finance
        supplierInvoiceView.value =
          permissions?.module_finance?.supplier_invoice?.view || false;
        supplierInvoiceCreate.value =
          permissions?.module_finance?.supplier_invoice?.create || false;
        customerInvoiceView.value =
          permissions?.module_finance?.customer_invoice?.view || false;
        customerInvoiceCreate.value =
          permissions?.module_finance?.customer_invoice?.create || false;
        customerPaymentView.value =
          permissions?.module_finance?.customer_payment?.view || false;
        customerPaymentCreate.value =
          permissions?.module_finance?.customer_payment?.create || false;
        laborInvoiceView.value =
          permissions?.module_finance?.labor_invoice?.view || false;
        laborInvoiceCreate.value =
          permissions?.module_finance?.labor_invoice?.create || false;
        sackInvoiceView.value =
          permissions?.module_finance?.sack_invoice?.view || false;
        sackInvoiceCreate.value =
          permissions?.module_finance?.sack_invoice?.create || false;
        customerRepaymentRecordView.value =
          permissions?.module_finance?.customer_repayment_record?.view || false;
        customerRepaymentRecordDelete.value =
          permissions?.module_finance?.customer_repayment_record?.delete ||
          false;

        //preloan
        preLoanView.value =
          permissions?.module_pre_loan?.pre_loan?.view || false;
        preLoanCreate.value =
          permissions?.module_pre_loan?.pre_loan?.create || false;
        preLoanUpdate.value =
          permissions?.module_pre_loan?.pre_loan?.update || false;
        preLoanDelete.value =
          permissions?.module_pre_loan?.pre_loan?.delete || false;

        // Transaction
        transactionTruckGoView.value =
          permissions?.module_transaction?.truck_go?.view || false;
        transactionTruckGoDelete.value =
          permissions?.module_transaction?.truck_go?.delete || false;
        transactionTruckGoUpdate.value =
          permissions?.module_transaction?.truck_go?.update || false;
        transactionOnBoatView.value =
          permissions?.module_transaction?.on_boat?.view || false;
        transactionOnBoatDelete.value =
          permissions?.module_transaction?.on_boat?.delete || false;
        transactionOnBoatUpdate.value =
          permissions?.module_transaction?.on_boat?.update || false;
        transactionSupplierInvoiceView.value =
          permissions?.module_transaction?.supplier_invoice?.view || false;
        transactionSupplierInvoiceDelete.value =
          permissions?.module_transaction?.supplier_invoice?.delete || false;
        transactionCustomerInvoiceView.value =
          permissions?.module_transaction?.customer_invoice?.view || false;
        transactionCustomerInvoiceDelete.value =
          permissions?.module_transaction?.customer_invoice?.delete || false;
        transactionCustomerPaymentView.value =
          permissions?.module_transaction?.customer_payment?.view || false;
        transactionCustomerPaymentDelete.value =
          permissions?.module_transaction?.customer_payment?.delete || false;
        transactionLaborInvoiceView.value =
          permissions?.module_transaction?.labor_invoice?.view || false;
        transactionLaborInvoiceDelete.value =
          permissions?.module_transaction?.labor_invoice?.delete || false;
        transactionSackInvoiceView.value =
          permissions?.module_transaction?.sack_invoice?.view || false;
        transactionSackInvoiceDelete.value =
          permissions?.module_transaction?.sack_invoice?.delete || false;
        transactionRentalParkingInvoiceDelete.value =
          permissions?.module_transaction?.rental_parking_invoice?.delete ||
          false;
        transactionRentalParkingInvoiceView.value =
          permissions?.module_transaction?.rental_parking_invoice?.view ||
          false;
        // Wallet
        walletPartnerView.value =
          permissions?.module_wallet?.partner?.view || false;
        walletPartnerCreate.value =
          permissions?.module_wallet?.partner?.create || false;
        walletPartnerUpdate.value =
          permissions?.module_wallet?.partner?.update || false;
        walletPartnerDelete.value =
          permissions?.module_wallet?.partner?.delete || false;
        walletTransactionListView.value =
          permissions?.module_wallet?.transaction_list?.view || false;
        walletTransactionDepositView.value =
          permissions?.module_wallet?.deposit?.view || false;
        walletTransactionDepositCreate.value =
          permissions?.module_wallet?.deposit?.create || false;
        walletTransactionDepositUpdate.value =
          permissions?.module_wallet?.deposit?.update || false;
        walletTransactionDepositDelete.value =
          permissions?.module_wallet?.deposit?.delete || false;
        walletTransactionWithdrawView.value =
          permissions?.module_wallet?.withdraw?.view || false;
        walletTransactionWithdrawCreate.value =
          permissions?.module_wallet?.withdraw?.create || false;
        walletTransactionWithdrawUpdate.value =
          permissions?.module_wallet?.withdraw?.update || false;
        walletTransactionWithdrawDelete.value =
          permissions?.module_wallet?.withdraw?.delete || false;
        walletTransactionTransferView.value =
          permissions?.module_wallet?.transfer?.view || false;
        walletTransactionTransferCreate.value =
          permissions?.module_wallet?.transfer?.create || false;
        walletTransactionTransferUpdate.value =
          permissions?.module_wallet?.transfer?.update || false;
        walletTransactionTransferDelete.value =
          permissions?.module_wallet?.transfer?.delete || false;

        // Parking
        parkingDisplayView.value =
          permissions?.module_parking?.parking_display?.view || false;
        moveBoatView.value =
          permissions?.module_parking?.move_boat?.view || false;
        moveBoatUpdate.value =
          permissions?.module_parking?.move_boat?.update || false;
        boatMappingView.value =
          permissions?.module_parking?.boat_mapping?.view || false;
        boatMappingCreate.value =
          permissions?.module_parking?.boat_mapping?.create || false;
        boatMappingUpdate.value =
          permissions?.module_parking?.boat_mapping?.update || false;
        parkingListView.value =
          permissions?.module_parking?.parking_list?.view || false;
        parkingListCreate.value =
          permissions?.module_parking?.parking_list?.create || false;
        parkingListUpdate.value =
          permissions?.module_parking?.parking_list?.update || false;
        parkingListDelete.value =
          permissions?.module_parking?.parking_list?.delete || false;

        // Warehouse
        warehouseRiceStockView.value =
          permissions?.module_warehouse?.rice_stock?.view || false;
        warehouseRiceConvertView.value =
          permissions?.module_warehouse?.rice_convert?.view || false;
        warehouseRiceConvertCreate.value =
          permissions?.module_warehouse?.rice_convert?.create || false;
        warehouseRiceConvertDelete.value =
          permissions?.module_warehouse?.rice_convert?.delete || false;
        warehouseAdjustView.value =
          permissions?.module_warehouse?.adjust?.view || false;
        warehouseAdjustCreate.value =
          permissions?.module_warehouse?.adjust?.create || false;
        warehouseAdjustDelete.value =
          permissions?.module_warehouse?.adjust?.delete || false;
        stockRiceHistoryView.value =
          permissions?.module_warehouse?.stock_history?.view || false;
        warehouseListView.value =
          permissions?.module_warehouse?.warehouse_list?.view || false;
        warehouseListCreate.value =
          permissions?.module_warehouse?.warehouse_list?.create || false;
        warehouseListUpdate.value =
          permissions?.module_warehouse?.warehouse_list?.update || false;
        warehouseListDelete.value =
          permissions?.module_warehouse?.warehouse_list?.delete || false;

        // External Weighing
        externalWeighingView.value =
          permissions?.module_external_weighing?.weighing_external?.view ||
          false;
        externalWeighingCreate.value =
          permissions?.module_external_weighing?.weighing_external?.create ||
          false;
        externalWeighingUpdate.value =
          permissions?.module_external_weighing?.weighing_external?.update ||
          false;
        externalWeighingTruckStorageView.value =
          permissions?.module_external_weighing?.truck_go_external?.view ||
          false;
        externalWeighingTruckStorageUpdate.value =
          permissions?.module_external_weighing?.truck_go_external?.update ||
          false;
        externalWeighingInvoiceView.value =
          permissions?.module_external_weighing?.weighing_invoice_external
            ?.view || false;
        externalWeighingInvoiceCreate.value =
          permissions?.module_external_weighing?.weighing_invoice_external
            ?.create || false;
        externalWeighingTransactionTruckView.value =
          permissions?.module_external_weighing?.transaction_truck_external
            ?.view || false;
        externalWeighingTransactionTruckdelete.value =
          permissions?.module_external_weighing?.transaction_truck_external
            ?.delete || false;
        externalWeighingInvoiceTransactionView.value =
          permissions?.module_external_weighing
            ?.weighing_invoice_transaction_external?.view || false;
        externalWeighingInvoiceTransactionDelete.value =
          permissions?.module_external_weighing
            ?.weighing_invoice_transaction_external?.delete || false;
        externalWeighingRentalParkingView.value =
          permissions?.module_external_weighing
            ?.weighing_external_rental_parking?.view || false;
        externalWeighingRentalParkingCreate.value =
          permissions?.module_external_weighing
            ?.weighing_external_rental_parking?.create || false;
        externalWeighingRentalParkingUpdate.value =
          permissions?.module_external_weighing
            ?.weighing_external_rental_parking?.update || false;
        externalWeighingRentalParkingDelete.value =
          permissions?.module_external_weighing
            ?.weighing_external_rental_parking?.delete || false;

        // Parking Rental
        parkingRentalView.value =
          permissions?.module_parking_rental?.rental_list?.view || false;
        parkingRentalCreate.value =
          permissions?.module_parking_rental?.rental_list?.create || false;
        parkingRentalUpdate.value =
          permissions?.module_parking_rental?.rental_list?.update || false;
        parkingRentalDelete.value =
          permissions?.module_parking_rental?.rental_list?.delete || false;

        // Report
        reportCustomerInvoiceView.value =
          permissions?.module_report?.customer_invoice?.view || false;
        reportPurchaseOrderView.value =
          permissions?.module_report?.purchase_order?.view || false;
        reportSupplierInvoiceView.value =
          permissions?.module_report?.supplier_invoice?.view || false;
        reportSupplierPaymentView.value =
          permissions?.module_report?.supplier_payment?.view || false;
        reportClientInvoiceView.value =
          permissions?.module_report?.client_invoice?.view || false;
        reportCustomerPaymentView.value =
          permissions?.module_report?.customer_payment?.view || false;
        reportLaborInvoiceView.value =
          permissions?.module_report?.labor_invoice?.view || false;
        reportLaborPaymentView.value =
          permissions?.module_report?.labor_payment?.view || false;
        reportSackInvoiceView.value =
          permissions?.module_report?.sack_invoice?.view || false;
        reportWalletTransactionView.value =
          permissions?.module_report?.wallet_transaction?.view || false;
        reportWalletHistoryView.value =
          permissions?.module_report?.wallet_history?.view || false;
        reportExternalWeighingInvoiceView.value =
          permissions?.module_report?.external_weighing_invoice?.view || false;
        reportParkingRentalView.value =
          permissions?.module_report?.parking_rental?.view || false;
        reportParkingRentalPaymentView.value =
          permissions?.module_report?.parking_rental_payment?.view || false;
        reportExternalWeighingCustomerPaymentView.value =
          permissions?.module_report?.external_weighing_customer_payment
            ?.view || false;
        reportPreloanRepaymentView.value =
          permissions?.module_report?.preloan_repayment?.view || false;
        reportCustomerOrderView.value =
          permissions?.module_report?.customer_order?.view || false;
        reportWeighingExpenseView.value =
          permissions?.module_report?.weighing_expense?.view || false;
        reportExternalWeighingExpenseView.value =
          permissions?.module_report?.external_weighing_expense?.view || false;

        // Setting
        settingRiceView.value =
          permissions?.module_setting?.rice?.view || false;
        settingRiceCreate.value =
          permissions?.module_setting?.rice?.create || false;
        settingRiceUpdate.value =
          permissions?.module_setting?.rice?.update || false;
        settingRiceDelete.value =
          permissions?.module_setting?.rice?.delete || false;
        settingUserView.value =
          permissions?.module_setting?.user?.view || false;
        settingUserCreate.value =
          permissions?.module_setting?.user?.create || false;
        settingUserUpdate.value =
          permissions?.module_setting?.user?.update || false;
        settingUserDelete.value =
          permissions?.module_setting?.user?.delete || false;
        settingUserPermissionView.value =
          permissions?.module_setting?.user_permission?.view || false;
        settingUserPermissionCreate.value =
          permissions?.module_setting?.user_permission?.create || false;
        settingUserPermissionUpdate.value =
          permissions?.module_setting?.user_permission?.update || false;
        settingUserPermissionDelete.value =
          permissions?.module_setting?.user_permission?.delete || false;
        settingPlatesView.value =
          permissions?.module_setting?.plate?.view || false;
        settingPlatesCreate.value =
          permissions?.module_setting?.plate?.create || false;
        settingPlatesUpdate.value =
          permissions?.module_setting?.plate?.update || false;
        settingPlatesDelete.value =
          permissions?.module_setting?.plate?.delete || false;
        settingTelegramTokenView.value =
          permissions?.module_setting?.telegram_token?.view || false;
        settingTelegramTokenCreate.value =
          permissions?.module_setting?.telegram_token?.create || false;

      }
    });

    return {
      settingTelegramTokenView,
      settingTelegramTokenCreate,
      reportExternalWeighingExpenseView,
      authUserRole,
      permissions,
      checkAllOrderingBook,
      externalWeighingRentalParkingView,
      externalWeighingRentalParkingCreate,
      externalWeighingRentalParkingUpdate,
      externalWeighingRentalParkingDelete,
      reportWeighingExpenseView,
      customerRepaymentRecordView,
      customerRepaymentRecordDelete,

      reportExternalWeighingCustomerPaymentView,
      preLoanView,
      preLoanCreate,
      preLoanUpdate,
      preLoanDelete,
      reportPreloanRepaymentView,
      reportCustomerOrderView,
      settingPlatesCreate,
      settingPlatesUpdate,
      settingPlatesDelete,
      settingPlatesView,

      reportSupplierInvoiceView,
      reportSupplierPaymentView,

      weighingUpdate,

      externalWeighingInvoiceTransactionView,
      walletTransactionTransferView,
      customerPaymentView,
      customerPaymentCreate,
      formatMobileNumber,
      handleSubmit,

      getImageUrl,
      formatTagName,
      formatDate,
      handleClose,
      changeLanguage,
      fontClass,
      t,
      isPending,

      dashboardView,
      customerOrderView,
      customerOrderCreate,
      customerOrderUpdate,
      customerOrderDelete,
      purchaseOrderView,
      purchaseOrderCreate,
      purchaseOrderUpdate,
      purchaseOrderDelete,
      supplierView,
      supplierCreate,
      supplierUpdate,
      supplierDelete,
      customerView,
      customerCreate,
      customerUpdate,
      customerDelete,
      weighingView,
      weighingCreate,
      weighingTruckStorageView,
      weighingTruckStorageUpdate,
      boatStorageView,
      boatStorageUpdate,
      supplierInvoiceView,
      supplierInvoiceCreate,
      customerInvoiceView,
      customerInvoiceCreate,
      laborInvoiceView,
      laborInvoiceCreate,
      sackInvoiceView,
      sackInvoiceCreate,
      transactionTruckGoView,
      transactionOnBoatView,
      transactionSupplierInvoiceView,
      transactionCustomerInvoiceView,
      transactionCustomerPaymentView,
      transactionLaborInvoiceView,
      transactionSackInvoiceView,
      walletPartnerView,
      walletPartnerCreate,
      walletPartnerUpdate,
      walletPartnerDelete,
      walletTransactionListView,
      walletTransactionDepositView,
      walletTransactionDepositCreate,
      walletTransactionDepositUpdate,
      walletTransactionDepositDelete,
      walletTransactionWithdrawView,
      walletTransactionWithdrawCreate,
      walletTransactionWithdrawUpdate,
      walletTransactionWithdrawDelete,

      walletTransactionTransferCreate,
      moveBoatView,
      moveBoatUpdate,
      boatMappingView,
      boatMappingCreate,
      parkingListView,
      parkingListCreate,
      parkingListUpdate,
      parkingListDelete,
      warehouseListView,
      warehouseRiceStockView,
      warehouseRiceConvertView,
      warehouseRiceConvertCreate,
      warehouseRiceConvertDelete,
      warehouseAdjustView,
      warehouseAdjustCreate,
      warehouseAdjustDelete,
      warehouseListCreate,
      warehouseListUpdate,
      warehouseListDelete,
      stockRiceHistoryView,
      externalWeighingView,
      externalWeighingCreate,
      externalWeighingUpdate,
      externalWeighingInvoiceView,
      parkingRentalView,
      parkingRentalCreate,
      parkingRentalUpdate,
      parkingRentalDelete,
      reportCustomerInvoiceView,
      reportPurchaseOrderView,
      reportSupplierInvoiceView,
      reportSupplierPaymentView,
      reportClientInvoiceView,
      reportCustomerPaymentView,
      reportLaborInvoiceView,
      reportLaborPaymentView,
      reportSackInvoiceView,
      reportWalletTransactionView,
      reportWalletHistoryView,
      reportExternalWeighingInvoiceView,
      reportParkingRentalView,
      reportParkingRentalPaymentView,
      settingRiceView,
      settingRiceCreate,
      settingRiceUpdate,
      settingRiceDelete,
      settingUserView,
      settingUserCreate,
      settingUserUpdate,
      settingUserDelete,
      settingUserPermissionView,
      settingUserPermissionCreate,
      settingUserPermissionUpdate,
      settingUserPermissionDelete,
      transactionTruckGoDelete,
      transactionTruckGoUpdate,
      transactionOnBoatDelete,
      transactionOnBoatUpdate,
      transactionSupplierInvoiceDelete,
      transactionCustomerInvoiceDelete,
      transactionCustomerPaymentDelete,
      transactionLaborInvoiceDelete,
      transactionSackInvoiceDelete,
      transactionRentalParkingInvoiceDelete,
      transactionRentalParkingInvoiceView,
      walletTransactionTransferUpdate,
      walletTransactionTransferDelete,
      externalWeighingTruckStorageView,
      externalWeighingTruckStorageUpdate,
      externalWeighingTransactionTruckView,
      externalWeighingTransactionTruckdelete,
      externalWeighingInvoiceView,
      externalWeighingInvoiceCreate,
      externalWeighingInvoiceTransactionDelete,
      boatMappingUpdate,
      parkingDisplayView,
      purchaseOrderAll,
      checkAllContact,
      checkAllWeighing,
      checkAllFinance,
      checkAllPreLoan,
      checkAllTransaction,
      checkAllWallet,
      checkAllParking,
      checkAllWarehouse,
      checkAllExternalWeighing,
      checkAllParkingRental,
      checkAllReport,
      checkAllSetting,
    };
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

:deep(.p-checkbox) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

:deep(.p-checkbox .p-checkbox-box) {
  border-color: #4caf50 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* Add these properties to ensure consistent box sizing */
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  /* Update icon positioning */
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon.pi-check:before) {
  content: "\e909" !important;
  font-family: "PrimeIcons" !important;
  color: white !important;
  font-size: 12px !important;
  /* Slightly reduced size for better fit */
  line-height: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Remove any duplicate icons */
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon:not(.pi-check)) {
  display: none !important;
}
</style>
