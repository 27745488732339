<template>
  <ToastVue ref="toast" />

  <div class="w-full relative text-primary6">
    <div :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Customer</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedCustomer"
          :options="customers"
          option-label="name"
          option-value="id"
          filter
          placeholder="Select Customer"
          show-clear
          checkmark
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Status</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectStatus"
          :options="status"
          filter
          placeholder="Select Rice"
          show-clear
          checkmark
          option-label="name"
          option-value="name"
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span> Dou Time</label
        >
        <DatePicker
          v-model="filterByDate"
          placeholder="Filter By Date"
          show-clear
          checkmark
          size="medium"
          showIcon
          showButtonBar
          fluid
          iconDisplay="input"
          style="width: 250px"
          class="w-full md:w-56 h-2 text-start flex items-center"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2"></span>Receiver</label
        >
        <button class="btn_filter" @click="applyFilter">Filter</button>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2"></span>Receiver</label
        >
        <IconField class="w-[80%] placeholder:text-body2">
          <InputText
            v-model="searchValue"
            placeholder="Search | Parking | Reciver | Status | Rice Type"
          />
          <InputIcon class="pi pi-search" />
        </IconField>
      </div>
    </div>

    <div :class="navClasses">
      <div>
        <h2>Customer Order List</h2>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_ordering_book?.customer_order?.create === true)
        "
      >
        <button @click="handleAdd">New Order</button>
      </div>
    </div>

    <div
      :class="tableClasses"
      class="h-[500px] animate-fade-up animate-duration-300 animate-delay-100 relative"
    >
      <table>
        <tbody>
          <tr class="header_table">
            <td
              @click="sortData('customer_order_id')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Order ID
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      sortColumns === 'customer_order_id' &&
                      sortDirections === 'asc'
                        ? 'sort-up'
                        : sortColumns === 'customer_order_id' &&
                          sortDirections === 'desc'
                        ? 'sort-down'
                        : 'sort',
                    ]"
                  />
                </transition>
              </span>
            </td>

            <td class="min-w-[180px] border-b text-start">Created At</td>
            <td
              @click="sortData('customer_name')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Customer
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      sortColumns === 'customer_name' &&
                      sortDirections === 'asc'
                        ? 'sort-up'
                        : sortColumns === 'customer_name' &&
                          sortDirections === 'desc'
                        ? 'sort-down'
                        : 'sort',
                    ]"
                  />
                </transition>
              </span>
            </td>
            <td class="border-b text-start">Rice Type</td>
            <td class="border-b">
              <div class="flex items-center justify-between">
                <div class="flex items-center">Status</div>
              </div>
            </td>
            <td class="min-w-[126px] border">Acion</td>
          </tr>
        </tbody>
        <tbody v-if="customerOrder.length > 0">
          <!-- {{
            customerOrder
          }} -->
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in customerOrder"
            :key="data"
          >
            <td class="border-b">{{ data.customer_order_id }}</td>
            <td class="border-b text-nowrap">
              {{ formatDate2(data.current_due_date) }}
            </td>
            <td class="border-b">{{ data.customer_name }}</td>
            <td class="border-b">
              <h2>
                <span> {{ data.type_of_rice.length }}</span>
              </h2>
            </td>
            <td class="border-b">
              <div
                class="cursor-pointer"
                @click="handleUpdateSaleStatus(data)"
                :class="
                  data?.order_status === 'Order'
                    ? 'chip_order'
                    : data?.order_status === 'Complete'
                    ? 'chip_complete'
                    : 'chip_hold'
                "
                round
              >
                {{ data?.order_status }}
              </div>
            </td>
            <td class="border w-[150px]">
              <div class="flex items-center gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.customer_order?.view ===
                        true)
                  "
                  @click="handleClientDetails(data)"
                  type="mdi"
                  class="info"
                  :path="mdiEyeOutline"
                ></svg-icon>

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.customer_order
                        ?.update === true)
                  "
                  type="mdi"
                  class="edit"
                  @click="handleEditData(data)"
                  :path="mdiPencil"
                >
                </svg-icon>

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.customer_order
                        ?.delete === true)
                  "
                  type="mdi"
                  class="delete"
                  @click="openDeleteConfirmation(data)"
                  :path="mdiTrashCanOutline"
                >
                </svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <AliesPagination
        :totalItems="customerOrder.length"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :getRowOptions="getRowOptions"
        @updatePage="changePage"
        @updatePageSize="changePageSize"
      />
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-fit h-fit bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetails" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isSaleStatus" as="template">
      <Dialog as="div" @close="handleCloseSaleStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseSaleStatus"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import toggleSort from "@/composables/sortData";
import SaleOrderForm from "@/form/SaleOrderForm.vue";
import OrderDetailsPopUp from "@/form/OrderDetailsPopUp.vue";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import PaginationVue from "@/components/Pagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import ToastVue from "@/components/Toast.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { mdiTrashCanOutline, mdiPencil, mdiEyeOutline } from "@mdi/js";
import UpdateOrderStatus from "@/form/UpdateOrderStatus.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
import AliesPagination from "@/components/AliesPagination.vue";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  components: {
    SaleOrderForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    NoDataComponet,
    PaginationVue,
    DeleteConfrimation,
    ToastVue,
    UpdateStatus,
    OrderDetailsPopUp,
    AliesPagination,
    UpdateOrderStatus,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isSaleStatus = ref(false);
    const isDetails = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "SaleOrderForm";
      datatoedit.value = null;
    };
    const handleUpdateSaleStatus = (item) => {
      isSaleStatus.value = true;
      datatoedit.value = item;
      currentComponent.value = "UpdateOrderStatus";
    };
    const handleCloseSaleStatus = () => {
      isSaleStatus.value = false;
      currentComponent.value = "";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handleClientDetails = (item) => {
      currentComponent.value = "OrderDetailsPopUp";
      isDetails.value = true;
      datatoedit.value = item;
      console.log("data:", datatoedit);
    };
    const handleCloseDetails = () => {
      currentComponent.value = "";
      isDetails.value = false;
    };
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    // fetch api
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("customer_orders");
    const searchValue = ref("");

    const changePageSize = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
    };
    const changePage = (page) => {
      currentPage.value = page;
    };
    const getRowOptions = [50, 100, 150, 200];

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "SaleOrderForm";
      datatoedit.value = item;
    };

    const customers = ref([]);
    const fetchcustomers = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customers.value = res.data;
      } catch (error) {
        console.log(error);
      }
    };
    const status = ref([
      { name: "Order", value: "Order" },
      { name: "Complete", value: "Complete" },
    ]);
    const getName = (id) => {
      const customer = customers.value.find((item) => item?.id === id);
      return customer?.name;
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchcustomers(),
        fetchCustomer_Order(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchcustomers(),
        fetchCustomer_Order(),
        fetchUserByID(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });
    watch(searchValue, async () => {
      await fetchCustomer_Order();
    });

    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    // sort
    const sortClientName = (sortDirection) => {
      customerOrder.value.sort((a, b) => {
        const nameA = getName(a.customer_id)?.toUpperCase() || "";
        const nameB = getName(b.customer_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortClient = (feild, sortDirection) => {
      if (feild === "supplier_name") {
        sortClientName(sortDirection, customerOrder.value, feild);
      } else if (feild === "order_id") {
        sortClientName(sortDirection, customerOrder.value, feild);
      }
    };

    // delete

    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.customer_order_id}`,
          {
            data: { tableName: table.value },
          }
        );
        await fetchCustomer_Order();
        if (isLastPage && customerOrder.value.length === 0) {
          currentPage.value = 1;
          await fetchCustomer_Order(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      return classes;
    });

    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      isUpdateStatus.value = true;
      isStatus.value = doc;
      currentComponent.value = "UpdateStatus";
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };
    const filterByDate = ref(null);
    const selectStatus = ref(null);
    const selectedCustomer = ref(null);
    const customerOrder = ref([]);
    const fetchCustomer_Order = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        let dynamicConditions = [];

        // Build dynamic conditions based on selected values
        dynamicConditions = [
          ...(selectedCustomer.value
            ? [
                {
                  field: "customer_id",
                  operator: "=",
                  value: selectedCustomer.value,
                },
              ]
            : []),
          ...(selectStatus.value
            ? [{ field: "status", operator: "=", value: selectStatus.value }]
            : []),
          ...(filterByDate.value
            ? [
                {
                  field: "created_at",
                  operator: "=",
                  value: filterByDate.value.toISOString().substring(0, 10),
                },
              ]
            : []),
        ];

        const params = {
          tableName: table.value,
          columnSearch: "customer_name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnAliases: JSON.stringify({
            customer_order_id: { table: "customer_orders", column: "id" },
            customer_name: { table: "customers", column: "name" },
            created_by: { table: "customer_orders", column: "created_by" },
            order_status: { table: "customer_orders", column: "status" },
          }),
          sortColumn: "customer_order_id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "customers",
              joinColumnChild: "customer_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const response = await axios.get(apiUrl, { params });
        customerOrder.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;

        console.log("Filtered customer orders:", customerOrder.value);
      } catch (error) {
        console.error("Failed to fetch customer orders:", error);
        customerOrder.value = [];
        totalPages.value = 0;
      }
    };

    const applyFilter = async () => {
      await fetchCustomer_Order();
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      customerOrder.value = toggleSort(direction, customerOrder.value, field);
    };

    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      handleClientDetails,
      handleCloseDetails,
      isDetails,
      searchValue,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      customerOrder,
      toast,
      showSuccessToast,
      handleEditData,
      datatoedit,
      sortClient,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deletedata,

      // responsive
      tableClasses,
      navClasses,
      // update Status
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      formatDate2,

      getName,
      changePageSize,
      getRowOptions,
      changePage,
      totalPages,
      selectClasses,
      // TotalCusomterOrder,
      // filterCustomerOrder,
      mdiTrashCanOutline,
      mdiPencil,
      mdiEyeOutline,
      customers,
      status,
      filterByDate,
      selectStatus,
      selectedCustomer,
      applyFilter,
      sortDirections,
      sortColumns,
      sortData,
      handleUpdateSaleStatus,
      isSaleStatus,
      handleCloseSaleStatus,
    };
  },
};
</script>
