<template>
  <div class="w-full">
    <div class="w-full flex items-center justify-between">
      <!-- Supplier | Purchase order -->
    </div>
    <div>
      <div class="relative overflow-x-auto border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <!-- <td class="min-w-[126px]  border-b">Acion</td> -->

              <td class="min-w-[95px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>No</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Created At</h2>
                  <!-- <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div> -->
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Rice Amount</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Status</h2>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="purchase_order.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in purchase_order"
              :key="data"
            >
              <!-- <td class="border-b">
                <div class="flex items-center gap-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-7 text-green-600 cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-edit-3 text-primary3"
                  >
                    <path d="M12 20h9"></path>
                    <path
                      d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-trash-2 text-primary4"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </div>
              </td> -->
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">
                {{ getTotalRiceAmount(data.type_of_rice) }} Kg
              </td>
              <td class="border-b">{{ data.status }}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="4">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import { ref, onMounted } from "vue";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: ["purchase", "datatoedit"],
  components: {
    Pagination,
    NoDataComponet,
  },
  setup(props, { emit }) {
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("purchase_orders");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const purchase_order = ref([]);
    const fetchPurchase = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        // columnSearch: "name, phone_number",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "supplier_id",
            operator: "=",
            value: props.datatoedit.id,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      purchase_order.value = response.data.data;
      totalPages.value = response.data.pagination.totalPages;
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchPurchase();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchPurchase();
    };
    const sortSuppliers = (field, sortDirection) => {
      if (field === "amount") {
        purchase_order.value.sort((a, b) => {
          const amountA = a.type_of_rice?.[0]?.amount || 0;
          const amountB = b.type_of_rice?.[0]?.amount || 0;

          if (sortDirection === "asc") {
            return amountA - amountB;
          } else {
            return amountB - amountA;
          }
        });
      } else if (field === "status") {
        toggleSort(sortDirection, purchase_order.value, field);
      } else if (field === "id") {
        toggleSort(sortDirection, purchase_order.value, field);
      }
    };
    onMounted(async () => {
      await Promise.allSettled([fetchPurchase()]);
    });
    const getTotalRiceAmount = (typeOfRiceArray) => {
      return typeOfRiceArray.reduce((total, item) => {
        return total + item.amount;
      }, 0);
    };
    return {
      formatDate2,
      sortSuppliers,
      purchase_order,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      getTotalRiceAmount,
    };
  },
};
</script>

<style></style>
