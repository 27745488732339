<template>
  <form @submit.prevent="handleSubmit" class="w-full h-auto">
    <div class="form_title">
      <h2 class="ml-4">Due client | Pay</h2>
      <div class="closePopUp">
        <svg
          @click="handleClose"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="flex items-start justify-around w-full p-4 gap-3 relative">
      <div
        class="h-[500px] space-y-4 p-5 border rounded-md"
        :class="{ 'h-[570px]': repayment === 'Partner' }"
      >
        <div class="flex flex-col items-start justify-between">
          <div class="space-y-8">
            <div class="flex items-center gap-3 justify-between text-nowrap">
              <label for="cus" class="flex gap-1">
                <span class="text-red-500">*</span>Customer
              </label>
              <InputText
                disabled
                type="text"
                v-model="customerName"
                class="w-[250px]"
                placeholder="Customer Name"
              />
            </div>
            <div class="flex items-center gap-3 justify-between text-nowrap">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span>Phone Number
              </label>
              <InputText
                disabled
                type="text"
                v-model="customerPhone"
                class="w-[250px]"
                placeholder="Phone number"
              />
            </div>
            <div class="flex items-center gap-3 justify-between text-nowrap">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span>Dou Amount
              </label>
              <InputNumber
                v-model="totalAmountOwed"
                suffix=" đ"
                style="width: 250px"
                class="h-2 flex items-center"
                placeholder="Dou Amount"
                :maxFractionDigits="2"
                step="0.001"
                disabled
              />
            </div>

            <div class="flex items-center gap-3 justify-between text-nowrap">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span>Amount Repayment
              </label>
              <div class="flex flex-col space-y-2">
                <InputNumber
                  v-model="amountPaid"
                  suffix=" đ"
                  min="1"
                  style="width: 250px"
                  class="h-2 flex items-center"
                  placeholder="Amount Repayment "
                  :maxFractionDigits="2"
                  step="0.001"
                />
              </div>
            </div>

            <div class="flex items-center justify-between gap-3">
              <label for="name" class="flex gap-1 text-nowrap">
                <span class="text-red-500">*</span> Receiver
              </label>
              <label class="flex items-center space-x-2">
                <input
                  type="radio"
                  name="status"
                  value="Internal"
                  v-model="repayment"
                  @change="handleRadioChange"
                  class="form-radio text-primary3"
                />
                <span>Internal</span>
              </label>
              <label class="flex items-center space-x-2">
                <input
                  type="radio"
                  name="status"
                  value="Partner"
                  v-model="repayment"
                  @change="handleRadioChange"
                  class="form-radio text-primary3"
                />
                <span>Partner</span>
              </label>
            </div>

            <div
              v-if="repayment === 'Partner'"
              class="flex items-center gap-3 justify-between text-nowrap"
            >
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span> Partners
              </label>

              <div class="flex flex-col space-y-2">
                <Select
                  :invalid="isSelectPartner === true"
                  placeholder="Select Partner"
                  :options="partner"
                  option-value="id"
                  option-label="name"
                  v-model="selectedPartner"
                  filter
                  show-clear
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  style="width: 250px"
                />
                <div v-if="isSelectPartner === true" class="">
                  <el-alert
                    title="Please Select Partner"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-start gap-3 justify-between text-nowrap">
              <label for="Description">Note</label>
              <textarea
                type="text"
                v-model="note"
                placeholder="Any Description"
                rows="4"
                style="width: 250px"
                class="input-text-area"
              />
            </div>
          </div>

          <div class="space-x-2 flex mt-5">
            <div class="">
              <button v-if="!isPending" class="btn-submit">Save</button>
              <button
                v-else
                class="relative btn-submit whitespace-nowrap flex items-center justify-center"
              >
                <div>processing...</div>
                <div class="right-2">
                  <div
                    class="mr-2 animate-spin rounded-full h-4 w-4 border-b-2 border-r-2"
                  ></div>
                </div>
              </button>
            </div>
            <button type="button" @click="handleClose" class="btncancel">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div class="h-[450px] border p-3 relative rounded-md">
        <h2 class="text-start py-2">Invoice List</h2>
        <div class="w-full h-[250px] bg-white border overflow-auto">
          <table>
            <tbody>
              <tr class="text-body header_table">
                <td class="border-b text-nowrap text-start border-primary5/50">
                  No
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Created at
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Invoice
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Total weighing
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Total Price
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Paid
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Due
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Status
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="(invoice, index) in dataToRepayment?.repayments"
                :key="index"
              >
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ index + 1 }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ formatDate2(dataToRepayment?.invoice_date) }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ dataToRepayment?.invoice_id }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{
                    dataToRepayment?.total_weight_drop
                      ? dataToRepayment?.total_weight_drop
                      : "No data"
                  }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ formatCurrency(invoice.total_amount_owed, " đ ", "afte") }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ formatCurrency(invoice.amount_paid, " đ ", "afte") }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ formatCurrency(invoice.amount_remaining, " đ ", "afte") }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  {{ invoice.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>

  <component
    :is="currentComponent"
    :showToast="showToast"
    :informMessage="informMessage"
    @onClose="handleClearToast"
  />
</template>

<script>
import apirURL from "@/services/apiURL";
import { formatCurrency } from "@/composables/formatCurrency";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { ref, onMounted, computed, onUnmounted, watch } from "vue";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import { formatMobileNumber } from "@/composables/formatMobileNumber";
import ToastVue from "@/components/ToastVue.vue";
import { useCustomerRepayment } from "@/composables/useCustomerRepayment";
import { DMTelegram } from "@/composables/useDMTelegram";
import numeral from "numeral";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  props: ["dataToRepayment"],
  components: {
    ToastVue,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const NewRow = ref("<New>");
    const name = ref("");
    const repayment = ref("Internal");

    const customerData = ref([]);
    const customerIdSeletet = ref("");
    const note = ref("");
    const isPending = ref(false);

    const authUserId = ref(null);
    const customerName = ref("");
    const customerPhone = ref("");
    const totalAmountOwed = ref(0);
    const amountPaid = ref(null);
    const selectedPartner = ref(null);
    const isSelectPartner = ref(false);

    const currentComponent = ref(null);
    const showToast = ref(false);
    const informMessage = ref("");

    const handleClearToast = () => {
      currentComponent.value = "";
      informMessage.value = "";
      showToast.value = false;
    };

    const handleClear = () => {
      isPending.value = false;
      customerName.value = "";
      customerPhone.value = "";
      totalAmountOwed.value = 0;
      amountPaid.value = null;
      note.value = "";
      selectedPartner.value = null;
      customerData.value = [];
      isSelectPartner.value = false;
    };

    watch(selectedPartner, (newVal) => {
      if (newVal) {
        isSelectPartner.value = false;
      }
    });

    const totalEstimatedPrice = computed(() => {
      return rows.value.reduce(
        (sum, row) =>
          sum +
          (parseFloat(row.estimatePrice) || 0) * (parseFloat(row.amount) || 0),
        0
      );
    });
    const fetchcustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: props.dataToRepayment?.customer_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        customerData.value = response.data;

        if (customerData.value.length > 0) {
          customerName.value = customerData.value[0].name;
          customerPhone.value = formatMobileNumber(
            customerData.value[0].phone_number
          );
        }
      } catch (error) {
        console.error("Error fetching customer:", error);
      }
    };

    const partner = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const handleSubmit = async () => {
      isPending.value = true;

      try {
        if (repayment.value === "Partner" && !selectedPartner.value) {
          isSelectPartner.value = true;
          return;
        } else if (amountPaid.value <= 0) {
          currentComponent.value = "TostVue";
          informMessage.value = `Amount paid must be greater than 0`;
          showToast.value = true;
          isPending.value = false;
          return;
        } else if (amountPaid.value > totalAmountOwed.value) {
          currentComponent.value = "TostVue";
          informMessage.value = `Amount paid must be less or equal than total amount owed`;
          showToast.value = true;
          isPending.value = false;
          return;
        }

        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "customer_repayment",
          fields: {
            customer_id: props.dataToRepayment?.customer_id,
            customer_invoice_id: props.dataToRepayment?.invoice_id,
            partner_id: selectedPartner.value ? selectedPartner.value : null,
            amount_paid: amountPaid.value,
            created_at: timestamp,
            created_by: authUserId.value,
            payment_type: repayment.value,
            total_amount_dept: totalAmountOwed.value,
            status:
              amountPaid.value === totalAmountOwed.value
                ? "paid"
                : amountPaid.value < totalAmountOwed.value
                ? "partial"
                : "",
          },
        };
        // Add new entry
        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          requestBody
        );

        useCustomerRepayment(
          requestBody.fields,
          response.data.insertedId,
          props.dataToRepayment?.invoice_id,
          "single"
        );

        if (repayment.value === "Partner") {
          DMTelegram(
            requestBody.fields.partner_id,
            `${customerName.value} has repayment ${numeral(
              amountPaid.value
            ).format("0,0")} VNĐ for invoice ${
              props.dataToRepayment?.invoice_id
            }`
          );
        }
        emit("toast", "create");
        handleClear();
        handleClose();
        isPending.value = false;
      } catch (error) {
        isPending.value = false;
        console.error("Error during form submission:", error);
        // Handle the error appropriately
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchcustomer(), fetchPartner()]);
    };

    const dataToRepayment = ref(null);

    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);

      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.dataToRepayment) {
        dataToRepayment.value = props.dataToRepayment;
        // console.log("dataToRepayment", dataToRepayment.value)
        note.value = props.dataToRepayment.note;
        totalAmountOwed.value =
          props.dataToRepayment?.repayments.length > 0
            ? props.dataToRepayment?.repayments[
                props.dataToRepayment?.repayments.length - 1
              ].amount_remaining
            : 0;
      }
      await Promise.allSettled([fetchcustomer(), fetchPartner()]);

      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });

    return {
      dataToRepayment,
      handleClearToast,
      showToast,
      informMessage,
      currentComponent,

      isSelectPartner,
      handleClose,
      name,
      customerName,
      customerPhone,
      totalAmountOwed,
      amountPaid,
      selectedPartner,
      customerData,
      handleSubmit,
      isPending,

      customerData,
      customerIdSeletet,
      note,

      totalEstimatedPrice,
      formatCurrency,
      repayment,
      partner,
      formatDate2,
    };
  },
};
</script>
