<template>
  <div
    class="bg-default overflow-hidden relative animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Supplier</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          :options="suppliers"
          filter
          placeholder="Select Supplier"
          option-label="name"
          option-value="id"
          show-clear
          checkmark
          size="medium"
          v-model="selectCustomer"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          :manualInput="false"
          show-button-bar
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div>
      <div>
        <h2 class="py-2">External Invoice Transaction</h2>
      </div>
      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="w-[120px] text-nowrap border-b text-start">
                Created At
              </td>
              <td
                @click="sortData('id')"
                class="w-[120px] text-start text-nowrap toggle_sort border-b"
              >
                Invoice ID
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'id' && sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('name')"
                class="w-[120px] text-start text-nowrap toggle_sort border-b"
              >
                Customer
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'name' && sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="w-[120px] text-nowrap border-b text-start">Phone</td>
              <td
                @click="sortData('total_weight')"
                class="w-[120px] text-start text-nowrap toggle_sort border-b"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'total_weight' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('price')"
                class="w-[120px] text-start text-nowrap toggle_sort border-b"
              >
                Total Cost
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'price' && sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="w-[120px] border-b">Status</td>
              <td class="border w-[120px]">Acion</td>
            </tr>
          </tbody>
          <tbody v-if="invoice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in invoice"
              :key="data"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ data.name }}</td>
              <td class="border-b">{{ data.phone_number }}</td>
              <td class="border-b">
                {{ numeralFormat(data.total_weight, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_paid, "0,0[.]00") }} ៛
              </td>
              <!-- <td class="border-b">{{ data.transactions[0].note }}</td>  -->

              <td class="border-b">
                <el-button type="success" round>{{ data.status }} </el-button>
              </td>
              <td class="border">
                <div class="flex items-center gap-3">
                  <svg-icon
                    :path="mdiEyeOutline"
                    type="mdi"
                    @click="handlePrint(data.id)"
                    class="info"
                  />

                  <svg
                    @click="openModal(data)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-primary4 delete"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="invoice.length > 0"
        class="w-full flex items-center justify-between mt-8"
      >
        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          @page-change="handlePageChange"
          :totalRecord="totalRecord"
          @page-size-change="handlePageSizeChange"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
                <DialogTitle
                  class="text-lg text-start font-medium text-gray-900"
                >
                  Confirm Delete
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-nowrap text-start">
                    Are You sure you want to delete [
                    {{ selectedDoc.id }} ]
                  </p>
                </div>
                <div class="mt-4 flex justify-end gap-2">
                  <button
                    class="bg-gray-200 px-4 py-2 rounded text-primary2"
                    @click="closeModal"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-red-600 text-white px-4 py-2 rounded"
                    @click="confirmDelete"
                  >
                    Delete
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <Toast ref="toast" />
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import Toast from "@/components/Toast.vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import axios from "axios";
import apirURL from "@/services/apiURL";
import moment from "moment-timezone";
import { useRouter } from "vue-router";
import { mdiTrashCanOutline, mdiEyeOutline } from "@mdi/js";
import toggleSort from "@/composables/sortData";
import NoDataComponet from "@/components/NoDataComponet.vue";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    NoDataComponet,
    Toast,
  },
  setup() {
    const handlePrint = (id) => {
      router.push({
        name: "printrantalparking",
        params: { id },
      });
    };
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "LaborSlipTransactionDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    // Debugging output
    console.log("fromDateFormatted", fromDateFormatted.value);
    console.log("toDateFormatted", toDateFormatted.value);

    // fetch api
    const router = useRouter();
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("id");
    const sortDirection = ref("DESC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const table = ref("service_external");
    const searchValue = ref("");
    const totalRecord = ref(0);
    const invoice = ref([]);
    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "supplier_id",
            //   operator: "=",
            //   value: id,
            // },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const selectCustomer = ref(null);
    const fetchInvoice = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: table.value,
          // columnSearch: "",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,
          sortDirection: sortDirection.value,
          sortColumn: sortColumn.value,
          columnAliases: JSON.stringify({
            supplier_name: { table: "suppliers", column: "name" },
            phone_number: { table: "suppliers", column: "phone_number" },
            supplier_id: { table: "suppliers", column: "id" },
            id: { table: "service_external", column: "id" },
            status: { table: "service_external", column: "status" },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),

          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
            ...(selectCustomer.value
              ? [
                  {
                    field: "supplier_id",
                    operator: "=",
                    value: selectCustomer.value,
                  },
                ]
              : []),
            {
              field: "service_external_type",
              operator: "=",
              value: "parking_rental",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          {
            params,
          }
        );
        invoice.value = response.data.data;
        totalRecord.value = invoice.value.length;
        console.log("invoice", invoice.value);
      } catch (error) {
        console.log("error fetching invoice", error);
      }
    };
    const handleFilter = () => {
      fetchInvoice();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      fetchInvoice();
    };
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchInvoice();
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchInvoice(),
        fetchInvoiceLastRecord(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchInvoice(),
        fetchSupplier(),
        fetchInvoiceLastRecord(),
        fetchUserByID(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    const sortAmount = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA = a.rental_parking?.[0]?.[field] || 0;
        const valueB = b.rental_parking?.[0]?.[field] || 0;
        if (direction === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      });
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;
      if (field === "weight" || field === "cost") {
        invoice.value = sortAmount(direction, invoice.value, field);
      } else {
        invoice.value = toggleSort(direction, invoice.value, field);
      }
    };
    const isModalOpen = ref(false);
    const selectedDoc = ref(null);
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const openModal = (doc) => {
      console.log("doc", doc);
      console.log(" selectedDoc.value.weighing_id", selectedDoc.value);

      if (doc.id) {
        selectedDoc.value = doc;
        isModalOpen.value = true;
      } else {
        showSuccessToast("cannotdelete", "error");
        return;
      }
    };
    const invoice_last_record = ref([]);
    const fetchInvoiceLastRecord = async () => {
      try {
        const params = {
          tableName: "service_external",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        invoice_last_record.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const confirmDelete = async () => {
      if (!selectedDoc.value) return;
      try {
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${selectedDoc.value.id}`,
          {
            data: { tableName: "service_external" },
          }
        );
        if (Array.isArray(selectedDoc.value.weighing_id)) {
          for (const item of selectedDoc.value.weighing_id) {
            if (item) {
              const req_update_weight = {
                tableName: "weighing",
                fields: { is_external_invoice: false },
              };
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${item}`,
                req_update_weight
              );
              console.log("item", item);
            }
          }
          closeModal();
        }
        showSuccessToast("delete", "error");
      } catch (error) {
        showSuccessToast("delete", "error");
        console.error("Error deleting supplier invoice:", error);
      }
    };

    return {
      handleAdd,
      isOpen,
      currentComponent,
      handleClose,
      navClasses,
      tableClasses,
      selectClasses,
      invoice,
      formatDate2,
      handlePrint,
      mdiTrashCanOutline,
      mdiEyeOutline,
      handlePageChange,
      handlePageSizeChange,
      currentPage,
      pageSize,
      totalPages,
      totalRecord,
      sortData,
      sortColumns,
      sortDirections,
      selectCustomer,
      suppliers,
      selectDateRage,
      handleFilter,
      toast,
      isModalOpen,
      openModal,
      closeModal,
      confirmDelete,
      selectedDoc,
      invoice_last_record,
      authUserRole,
    };
  },
};
</script>

<style></style>
s
