import moment from 'moment-timezone';

export const formatDateWithTimezone = (date, isEndDate = false) => {
  const m = moment.tz(date, 'Asia/Phnom_Penh'); // Ensure consistent timezone
  
  if (isEndDate) {
    m.endOf('day'); // Sets time to 23:59:59.999 in Asia/Phnom_Penh
  } else {
    m.startOf('day'); // Sets time to 00:00:00.000 in Asia/Phnom_Penh
  }
  
  return m.format('YYYY-MM-DD HH:mm:ss.SSSZ'); // Explicitly include timezone offset
};
