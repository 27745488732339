<template>
  <div class="w-fit overflow-hidden text-primary6">
    <div class="w-full flex items-center justify-between py-2">
      <div>
        <h2>Truck List</h2>
      </div>
    </div>
    <div>
      <div class="flex items-start w-full gap-4">
        <div :class="mainClassesTable" class="border h-[600px]">
          <table>
            <tbody>
              <tr class="header_table">
                <td
                  @click="sortHistory('id')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Id
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'id' && sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistory('supplier_name')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Supplier
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'supplier_name' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistory('plate_number')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Truck
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'plate_number' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
              </tr>
            </tbody>
            <tr class="font-semibold">
              <td colspan="5" class="border-b">
                <div class="flex items-center justify-center gap-2">
                  <IconField class="w-[80%] placeholder:text-body2">
                    <InputText
                      v-model="searchValue"
                      placeholder="Search | Supplier | Phone Number"
                    />
                    <InputIcon class="pi pi-search" />
                  </IconField>
                </div>
              </td>
            </tr>
            <tbody v-if="filteredWeighingData.length > 0">
              <tr
                @click="handleBoatSelection(data)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="data in filteredWeighingData"
                :key="data.id"
              >
                <td class="border-b text-nowrap">{{ data.id }}</td>
                <td class="border-b text-nowrap">
                  {{ data.supplier_name }}
                </td>
                <td class="border-b text-nowrap">
                  {{ data.plate_number.plate_number }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div :class="subClassesTable" class="border h-[600px]">
          <table>
            <tbody v-if="isBoatSelected && filteredWeighingData.length > 0">
              <tr class="text-body text-primary5">
                <td colspan="9" class="border">
                  <div class="text-start flex items-center justify-between">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3">
                        <h2>Status</h2>
                        <el-button
                          :type="selectedBoatStatus ? 'success' : 'warning'"
                          round
                        >
                          {{ selectedBoatStatus ? "Completed" : "Processing" }}
                        </el-button>
                      </div>
                      <div class="flex items-center gap-3">
                        <h2>Total Weight</h2>
                        <span class="text-primary2 font-semibold"
                          >{{ numeralFormat(IsWeighing, "0[.],00") }} Kg</span
                        >
                      </div>
                    </div>
                    <div class="flex items-center gap-3">
                      <IconField class="w-[50%] placeholder:text-body2">
                        <InputText placeholder="Search | Plate" />
                        <InputIcon class="pi pi-search" />
                      </IconField>
                      <button
                        @click="open(selectedBoatId)"
                        :disabled="!selectedBoatId"
                      >
                        Mark Completed
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="sub_header">
                <td class="min-w-[100px] border-b text-start text-nowrap">
                  Created At
                </td>
                <td
                  @click="sortHistory('rice_name')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Rice Type
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'rice_name' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistory('weight_in')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Weight In
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'weight_in' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistory('weight_out')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Weight Out
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'weight_out' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>

                <td
                  @click="sortHistory('amount_cut_off')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Cut Out
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'amount_cut_off' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistory('weight_drop')"
                  class="text-start text-nowrap border-b toggle_sort"
                >
                  Remain Weight
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'weight_drop' &&
                          sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
              </tr>
            </tbody>

            <tbody v-if="isBoatSelected && filteredWeighingData.length > 0">
              <tr
                @click="handleAdd(history)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="history in weighing_rice_history"
                :key="history.id"
              >
                <td class="border-b text-nowrap">
                  {{ formatDate2(history.created_at) }}
                </td>
                <td class="border-b">
                  <p v-for="rice in history.rice" :key="rice.id">
                    {{ rice.rice_name }}
                  </p>
                </td>
                <td class="border-b">
                  {{ numeralFormat(history.weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ numeralFormat(history.weight_out, "0,0[.]00") }} Kg
                </td>

                <td class="border-b">
                  {{
                    history.amount_cut_off
                      ? numeralFormat(history.amount_cut_off, "0,0[.]00")
                      : 0
                  }}
                  Kg
                </td>
                <td class="border-b">
                  {{ numeralFormat(history.weight_drop, "0,0[.]00") }} Kg
                </td>
                <!-- <td class="border-b">
                  {{ getParking(history.boat_enter_parking_id) }}
                </td> -->
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-if="filteredWeighingData.length > 0"
        class="w-full bg-white flex items-center justify-center py-2"
      >
        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          @page-change="handlePageChange"
          :totalRecord="totalRecord"
          @page-size-change="handlePageSizeChange"
          isLastRecordOnPage="isLastRecordOnPage"
        />
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isCutOut" as="template">
      <Dialog as="div" @close="handleCloseCutOut" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseCutOut"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import Toast from "@/components/Toast.vue";
import CutOutForm from "@/form/CutOutForm.vue";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import TruckPreviewPopupDetails from "@/form/TruckPreviewPopupDetails.vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import SubPagination from "@/components/SubPagination.vue";
import socket from "@/services/socket";
import moment from "moment-timezone";

import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  components: {
    TruckPreviewPopupDetails,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    SubPagination,
    CutOutForm,
    Toast,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const isCutOut = ref(false);
    const isCutOutData = ref(null);
    const totalRecord = ref(0);
    const handleCutOut = (doc) => {
      isCutOut.value = true;
      isCutOutData.value = doc;
      currentComponent.value = "CutOutForm";
    };
    const handleCloseCutOut = () => {
      isCutOut.value = false;
      isCutOutData.value = null;
      currentComponent.value = "";
    };
    const handleAdd = (doc) => {
      isCutOutData.value = doc;
      isOpen.value = true;
      currentComponent.value = "TruckPreviewPopupDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "OrderDetailsPopUpVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const selectedBoatId = ref(null);

    const selectedBoatStatus = ref(false);
    const IsWeighing = ref(null);

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      return classes;
    });

    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("weighing");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const weighing = ref([]);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        columnSearch: "supplier_name",
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        columnAliases: JSON.stringify({
          weight_id: { table: table.value, column: "id" },
          supplier_name: { table: "suppliers", column: "name" },
          phone_number: { table: "suppliers", column: "phone_number" },
          supplier_id: { table: "suppliers", column: "id" },
        }),
        additionalJoins: JSON.stringify([
          {
            joinTableName: "suppliers",
            joinColumnChild: "supplier_id",
            joinColumnParent: "id",
            joinType: "LEFT JOIN",
          },
        ]),
        dynamicConditions: JSON.stringify([
          {
            field: "status",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          {
            field: "supplier_id",
            operator: "IS NOT NULL",
            // value: ,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },

          {
            field: "service_type",
            operator: "=",
            value: "external",
            typeTable: "table",
          },
          {
            field: "service_external_type",
            operator: "=",
            value: "weighing_rental",
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing.value = response.data.data;
      totalRecord.value = weighing.value.length;
      console.log("date ", weighing);
      totalPages.value = response.data.pagination.totalPages;
    };
    watch(searchValue, async () => {
      await fetchWeighing();
    });
    const isBoatSelected = ref(false);
    const truck = ref(null);
    const handleBoatSelection = async (data) => {
      // Set the selected boat information
      selectedBoatId.value = data.weight_id;
      selectedBoatStatus.value = data.status;
      IsWeighing.value = data.weight_in;
      truck.value = data.plate_number.plate_number;
      weighing_rice_history.value = [];

      await fetchWeighingHistory(selectedBoatId.value);

      isBoatSelected.value = true;
    };
    const open = (id) => {
      ElMessageBox({
        title: "Confirming Mark Truck as Completed",
        message: h("p", null, [
          h(
            "span",
            null,
            `Are you sure you want to mark the Truck [${truck.value}] as Completed?`
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: "custom-confirm-box",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 1000);
          } else {
            done();
          }
        },
      })
        .then(async (action) => {
          if (action === "confirm") {
            try {
              const req = {
                tableName: table.value,
                fields: {
                  status: true,
                },
              };
              const res = await axios.patch(
                `${apirURL}/weighing/api/updateData/${id}`,
                req
              );

              if (res.status === 200) {
                ElMessage({
                  type: "success",
                  message: "Truck status marked as Completed successfully.",
                });
              } else {
                ElMessage({
                  type: "error",
                  message: "Failed to update truck status.",
                });
              }
            } catch (error) {
              ElMessage({
                type: "error",
                message: `Error updating status: ${error.message}`,
              });
            }
          }
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };

    const trucks = ref([]);
    // const plate_numbers = ref("");
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parking = ref([]);
    const selectedParking = ref("");
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const suppliers = ref([]);
    const fetchSipplers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    // Weighing rice history pagination states
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPageHistory = ref(1);
    const pageSizeHistory = ref(50);
    const totalPagesHistory = ref(0);
    const weighing_rice_history = ref([]);
    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "status",
            //   operator: "=",
            //   value: true,
            // },
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing_rice_history.value = response.data;
        console.log("weighing history", weighing_rice_history.value);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const handlePageChangeHistory = (newPage) => {
      currentPageHistory.value = newPage;
      fetchWeighingHistory(selectedBoatId.value); // Ensure you have selectedWeighingId set when view details
    };

    const handlePageSizeChangeHistory = (newSize) => {
      pageSizeHistory.value = newSize;
      currentPageHistory.value = 1;
      fetchWeighingHistory(selectedBoatId.value); // Ensure you have selectedWeighingId set when view details
    };
    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = trucks.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getParking = (id) => {
      const name = parking.value.find((item) => item?.id === id);
      return name?.name;
    };
    watch(selectedBoatId, async (newVal) => {
      if (newVal) {
        await fetchWeighingHistory(newVal);
      }
    });
    const handleDataChange = async () => {
      await fetchWeighingHistory(selectedBoatId.value);
      await Promise.allSettled([
        fetchWeighing(),
        fetchParking(),
        fetchTruck(),
        fetchSipplers(),
      ]);
    };
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighing();
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchWeighing(),
        fetchParking(),
        fetchTruck(),
        fetchSipplers(),
      ]);

      socket.on("database_realTime", handleDataChange);
    });

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighing();
    };
    const sortClient = (field, sortDirection) => {
      if (field === "supplier_name") {
        sortSupplierName(sortDirection);
      } else if (field === "id") {
        toggleSort(sortDirection, weighing.value, field);
      } else if (field === "plate") {
        sortByPlates(sortDirection);
      }
    };
    const sortSupplierName = (sortDirection) => {
      weighing.value.sort((a, b) => {
        const nameA = getSupplierName(a.supplier_id)?.toUpperCase() || "";
        const nameB = getSupplierName(b.supplier_id)?.toUpperCase() || "";
        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortByPlates = (sortDirection) => {
      weighing.value.sort((a, b) => {
        const nameA = getPlatesName(a.plate_number_id)?.toUpperCase() || "";
        const nameB = getPlatesName(b.plate_number_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };

    const sortColumns = ref(null);
    const sortDirections = ref("asc");

    const toggleSort = (sortDirection, data, field) => {
      data.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    };
    const sortRice = (sortDirection) => {
      weighing_rice_history.value.sort((a, b) => {
        const nameA = a.rice.map((rice) => rice.rice_name).join(", ");
        const nameB = b.rice.map((rice) => rice.rice_name).join(", ");

        if (sortDirection === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortTruck = (sortDirection) => {
      weighing.value.sort((a, b) => {
        const nameA = a.plate_number.plate_number;
        const nameB = b.plate_number.plate_number;

        if (sortDirection === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortHistory = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;

      if (field === "weight_in") {
        toggleSort(direction, weighing_rice_history.value, field);
      } else if (field === "weight_out") {
        toggleSort(direction, weighing_rice_history.value, field);
      } else if (field === "amount_cut_off") {
        toggleSort(direction, weighing_rice_history.value, field);
      } else if (field === "weight_drop") {
        toggleSort(direction, weighing_rice_history.value, field);
      } else if (field === "rice_name") {
        sortRice(direction);
      } else if (field === "supplier_name") {
        toggleSort(direction, weighing.value, field);
      } else if (field === "id") {
        toggleSort(direction, weighing.value, field);
      } else if (field === "plate_number") {
        sortTruck(direction);
      }
    };

    const isFilterable = ref(false);
    const selectedSupplier = ref(null);
    const selectedTruck = ref(null);
    const selectedRice = ref(null);
    const filteredWeighingData = computed(() => {
      return weighing.value.filter((item) => {
        const supplierMatch = selectedSupplier.value
          ? item.supplier_id === selectedSupplier.value
          : true;
        const truckMatch = selectedTruck.value
          ? item.plate_number_id === selectedTruck.value
          : true;
        const riceMatch = selectedRice.value
          ? item.rice_id === selectedRice.value
          : true;
        const parkingMatch = selectedParking.value
          ? item.parking_id === selectedParking.value
          : true;

        return supplierMatch && truckMatch && riceMatch && parkingMatch;
      });
    });

    // Trigger filtering (This can be called on button click)
    const filterWeighingData = () => {
      console.log("Filtered Data:", filteredWeighingData.value);
    };
    const toast = ref(null);
    const showSuccessToast = async (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";

            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const getPlates = (id) => {
      const name = trucks.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getPlateId = (weighing_id) => {
      const weighings = weighing.value.find((w) => w.id === weighing_id);
      return weighings ? weighings.plate_number_id : "Unknown";
    };

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      weighing,
      weighing_rice_history,
      formatDate2,
      getParking,
      getPlatesName,
      getRice,
      getSupplierName,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      sortClient,
      sortHistory,
      selectedBoatId,
      selectedBoatStatus,
      IsWeighing,
      currentPageHistory,
      pageSizeHistory,
      handlePageChangeHistory,
      handlePageSizeChangeHistory,
      totalPagesHistory,
      handleBoatSelection,
      isBoatSelected,
      isCutOut,
      handleCloseCutOut,
      handleCutOut,
      isCutOutData,
      isFilterable,
      selectedSupplier,
      selectedTruck,
      selectedRice,
      selectedParking,
      filteredWeighingData,
      filterWeighingData,
      suppliers,
      trucks,
      parking,
      toast,
      showSuccessToast,
      getPlateId,
      getPlates,
      searchValue,
      totalRecord,
      sortColumns,
      sortDirections,
    };
  },
};
</script>

<style scoped>
.custom-confirm-box .el-button--primary {
  background-color: #28a745 !important; /* Green */
  border-color: #28a745 !important; /* Green */
  color: #fff !important;
}

.custom-confirm-box .el-button--primary:hover {
  background-color: #218838 !important; /* Darker Green on Hover */
  border-color: #218838 !important;
}
</style>
