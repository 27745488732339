<template>
  <form class="w-[400px] space-y-3" @submit.prevent="handleSubmit">
    <div class="form_title">
      <h2 class="ml-3">
        Update Status of [ {{ isStatus[tableConfig.displayField] }} ]
      </h2>
    </div>
    <div class="px-6 py-3 space-y-4">
      <div class="flex items-center gap-3">
        <label for="name" class="gap-1 flex flex-nowrap text-start"
          >Are you sure to update the status to
          <p class="text-primary4">[ {{ status }} ]</p>
          ?
        </label>
      </div>
      <div class="flex space-x-2">
        <button type="button" @click="handleClose" class="btncancel">
          Cancel
        </button>
        <button type="submit">Update</button>
      </div>
    </div>
  </form>
</template>
<script>
import { ref, computed } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import apirURL from "@/services/apiURL";

export default {
  props: ["isStatus"],
  setup(props, { emit }) {
    const route = useRoute();
    const status = ref(props.isStatus.status);

    const getTableConfig = () => {
      switch (route.path) {
        case "/users":
          return { tableName: "users", displayField: "username" };
        case "/supplier":
          return { tableName: "suppliers", displayField: "name" };
        case "/client":
          return { tableName: "customers", displayField: "name" };
        case "/parking":
          return { tableName: "parking_rice", displayField: "name" };
        case "/warehouse":
          return { tableName: "warehouse", displayField: "name" };
        case "/plates":
          return { tableName: "plates", displayField: "plate_number" };
        case "/preLoan":
          return { tableName: "preloans", displayField: "id" };
        default:
          return { tableName: "unknown", displayField: "name" };
      }
    };

    const tableConfig = computed(() => getTableConfig());

    const handleClose = () => {
      emit("close");
    };

    const statusUser = ref(false);
    const handleSubmit = async () => {
      const { tableName } = tableConfig.value;

      if (tableName === "unknown") {
        console.error("Unknown table name for this route");
        return;
      }

      try {
        // Toggle the status value (true to false, or false to true)
        const newStatus = !status.value;

        const requestBody = {
          tableName: tableName,
          fields: {
            status: newStatus,
          },
        };

        if (props.isStatus && props.isStatus.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.isStatus.id}`,
            requestBody
          );

          emit("toast", "update");
          handleClose();
        } else {
          console.error("No valid ID provided for update");
        }
      } catch (error) {
        console.error(error);
      }
    };

    return {
      handleClose,
      handleSubmit,
      status,
      tableConfig,
    };
  },
};
</script>
