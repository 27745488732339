<template>
  <ToastVue ref="toast" />
  <div
    class="w-full relative text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div :class="navClasses">
      <div>
        <h2>Rice List</h2>
      </div>

      <div class="flex items-center gap-2">
        <IconField class="w-[80%] placeholder:text-body2">
          <InputText v-model="searchValue" placeholder="Search | Name" />
          <InputIcon class="pi pi-search" />
        </IconField>
        <button @click="handleAdd">New Rice</button>
      </div>
    </div>
    <div :class="tableClasses" class="h-[500px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td class="w-[100px] text-nowrap text-start">Created At</td>
            <td
              @click="sortData('id')"
              class="w-[120px] text-start text-nowrap toggle_sort"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'id' && sortDirections === 'asc'
                        ? ['fas', 'arrow-up']
                        : ['fas', 'arrow-down']
                    "
                  />
                </transition>
              </span>
            </td>

            <td
              @click="sortData('name')"
              class="w-[120px] text-start text-nowrap toggle_sort"
            >
              Name
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'name' && sortDirections === 'asc'
                        ? ['fas', 'arrow-up']
                        : ['fas', 'arrow-down']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="w-[100px] text-nowrap border">Action</td>
          </tr>
        </tbody>
        <tbody v-if="ricetype.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in ricetype"
            :key="data"
          >
            <td class="border-b text-nowrap">
              {{ formatDate2(data.created_) }}
            </td>
            <td class="border-b text-nowrap">{{ data.id }}</td>
            <td class="border-b text-nowrap">{{ data.name }}</td>
            <td class="border text-nowrap">
              <div class="flex items-center gap-3">
                <svg-icon
                  :path="mdiPencil"
                  type="mdi"
                  @click="handleEditData(data)"
                  class="edit"
                ></svg-icon>
                <svg-icon
                  class="delete"
                  type="mdi"
                  :path="mdiTrashCanOutline"
                  @click="openDeleteConfirmation(data)"
                ></svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full mt-8">
      <div class="">
        <PaginationVue
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          :totalRecord="ricetype.length"
          @page-change="handlePageChange"
          @page-size-change="handlePageSizeChange"
          isLastRecordOnPage="isLastRecordOnPage"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js";
import RiceForm from "@/form/RiceForm.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import toggleSort from "@/composables/sortData";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import PaginationVue from "@/components/Pagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import ToastVue from "@/components/Toast.vue";
import { formatDate } from "@/composables/formatDateTime";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { formatDate2 } from "@/composables/formatDateTime";

export default {
  components: {
    RiceForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    DeleteConfrimation,
    PaginationVue,
    ToastVue,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "RiceForm";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      datatoedit.value = null;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    // fetch api
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("rices");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const ricetype = ref([]);
    const fetchRiceType = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "name",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([]),
      };
      const response = await axios.get(apiUrl, { params });
      ricetype.value = response.data.data;
      console.log("data ", ricetype);
      totalPages.value = response.data.pagination.totalPages;
    };

    onMounted(() => {
      fetchRiceType();
    });

    const handleDatabaseUpdate = () => {
      fetchRiceType();
    };
    onMounted(() => {
      socket.on("database_realTime", handleDatabaseUpdate);
      fetchRiceType();
    });
    onUnmounted(() => {
      socket.off("database_realTime");
    });
    watch(searchValue, () => {
      fetchRiceType();
    });
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    // delete
    const isDeleteOpen = ref(false);
    const deletedata = ref(null);
    const itemIdToDelete = ref(null);
    const openDeleteConfirmation = async (doc) => {
      const customerOrderData = await fetchCustomerOrder(doc.id);
      const purchaseordersData = await fetchPurchaseOrder(doc.id);
      if (customerOrderData.length > 0 || purchaseordersData.length > 0) {
        showSuccessToast("cannotdelete", "error");
        console.log("customerOrderData", customerOrderData);
      } else {
        deletedata.value = doc;
        isDeleteOpen.value = true;
        console.log("item:", deletedata);
      }
    };
    const tableName = ref(null);
    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: table.value },
        });
        await fetchRiceType();
        if (isLastPage && ricetype.value.length === 0) {
          currentPage.value = 1;
          await fetchRiceType(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    // edit

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "RiceForm";
      datatoedit.value = item;
      console.log("item:", datatoedit);
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchRiceType();
      fetchCustomerOrder();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchRiceType();
    };
    // sort
    const sortClient = (feild, sortDirection) => {
      if (feild === "name") {
        toggleSort(sortDirection, ricetype.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, ricetype.value, feild);
      }
    };
    const fetchCustomerOrder = async (id) => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "customer_orders",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "type_of_rice",
              operator: "@>",
              key: "selectedRice",
              value: id,
              type: "jsonb",
            },
          ]),
        };
        const response = await axios.get(apiUrl, { params });
        return response.data;
        // console.log("response", response.data);
      } catch (error) {
        console.error("Error fetching customer order data:", error);
        return null;
      }
    };
    const fetchPurchaseOrder = async (id) => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "purchase_orders",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "type_of_rice",
              operator: "@>",
              key: "selectedRice",
              value: id,
              type: "jsonb",
            },
          ]),
        };
        const response = await axios.get(apiUrl, { params });
        return response.data;
        // console.log("response", response.data);
      } catch (error) {
        console.error("Error fetching customer order data:", error);
        return null;
      }
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;
      ricetype.value = toggleSort(direction, ricetype.value, field);
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      navClasses,
      tableClasses,
      ricetype,
      formatDate,
      showSuccessToast,
      toast,
      searchValue,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deletedata,
      // edit
      datatoedit,
      handleEditData,
      // pagination
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      // sort
      sortClient,
      formatDate2,
      mdiPencil,
      mdiTrashCanOutline,
      sortData,
      sortColumns,
      sortDirections,
    };
  },
};
</script>

<style></style>
