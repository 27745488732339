<template>
  <div class="w-[750px] rouded-[4px] h-auto bg-white">
    <div class="details_title">
      <h2 class="ml-4">Truck: {{ truckData.plate_number[0]?.plate_number }}</h2>
      <div class="flex items-center gap-2">
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-3 mt-7">
      <div>
        <h2 class="text-start">Rice Details</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No</td>
              <td class="min-w-[70px] border-b text-start">Created at</td>

              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Weight</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
            </tr>
          </tbody>

          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="weighing in weighingData"
            >
              <td class="border-b">{{ weighing.id }}</td>
              <td class="border-b">{{ formatDate2(weighing.created_at) }}</td>
              <td class="border-b">
                {{ weighing.rice[0]?.rice_name }}
              </td>
              <td class="border-b">
                {{ numeralFormat(weighing.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(weighing.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{
                  numeralFormat(
                    weighing.weight_in -
                      weighing.weight_out -
                      weighing.amount_cut_off,
                    "0,0[.]00"
                  )
                }}
                Kg
              </td>
            </tr>
            <tr class="font-semibold">
              <td colspan="3" class="text-center border-b">Total</td>
              <td class="border-b">
                {{ numeralFormat(totalWeightDrop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(totalWeightCutOut, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(totalWeightRemaining, "0,0[.]00") }} Kg
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-4">
        <button @click="handleClose" class="btncancel">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  props: ["truckData"],
  setup(props, { emit }) {
    const weighingData = ref([]);
    const handleClose = () => {
      emit("close");
    };
    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: props.truckData.id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        console.log("Fetched weighing data", response.data);
        weighingData.value = response.data;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    const totalWeightDrop = computed(() => {
      return weighingData.value.reduce((total, record) => {
        return total + parseFloat(record.weight_drop || 0);
      }, 0);
    });
    const totalWeightCutOut = computed(() => {
      return weighingData.value.reduce((total, record) => {
        return total + parseFloat(record.amount_cut_off || 0);
      }, 0);
    });
    const totalWeightRemaining = computed(() => {
      return weighingData.value.reduce((total, record) => {
        const weightIn = parseFloat(record.weight_in || 0);
        const weightOut = parseFloat(record.weight_out || 0);
        const amountCutOff = parseFloat(record.amount_cut_off || 0);

        return total + (weightIn - weightOut - amountCutOff);
      }, 0);
    });

    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const getPlatesRice = (id) => {
      const rices = rice.value.find((t) => t.id === id);
      return rices ? rices.name : "Unknown Plate";
    };
    const rice = ref([]);
    const fetchRice = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    onMounted(() => {
      fetchWeighingData();
      fetchTruck();
      fetchRice();
    });
    return {
      weighingData,
      handleClose,
      formatDate2,
      totalWeightDrop,
      totalWeightCutOut,
      totalWeightRemaining,
      getPlatesName,
      getPlatesRice,
    };
  },
};
</script>
