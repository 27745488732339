<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="router.go(-1)" lass="btncancel hidden-print">
            Back
          </button>
        </div>
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <div class="w-full mt-4 bg-white p-8 print-header" id="printTable">
        <div class="flex justify-between items-start">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Labor Slip</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12px]">011 111 111 / 012 123 456</h2>
          </div>
          <div class="text-primary5 text-[10px] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[10px] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4"
        >
          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Bill No.</h2>
            <h2>SL- {{ invoice[0]?.id }}</h2>
          </div>
        </div>

        <div class="py-2">
          <h2>Transaction Detail</h2>
          <div
            class="relative mt-4 bg-white text-[10px]"
            v-for="data in invoice"
          >
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[70px] text-nowrap border text-start">No</td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Created At
                  </td>
                  <!-- <td class="min-w-[100px] text-nowrap border text-start">
                    Truck
                  </td> -->
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Weight Kg
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr
                  class="text-[10px] font-normal text-primary2 text-nowrap"
                  v-for="(item, index) in data.transactions"
                  :key="index"
                >
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ index + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ formatDate2(data.created_at) }}
                  </td>
                  <!-- <td class="min-w-[100px] text-nowrap border text-start">
                    <p>
                      {{ data.labors.rice[0] }}
                    </p>
                  </td> -->
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(item.weight_drop, "0,0[.]00") }} Kg
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="font-bold bg-default">
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{
                      numeralFormat(
                        data.transactions[0].total_weight,
                        "0,0[.]00"
                      )
                    }}
                    Kg
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="py-2 w-full flex items-center justify-end text-[10px]">
          <div class="w-[60%] flex items-center justify-between">
            <div class="w-1/2 flex justify-end gap-4">
              <h2>Paid</h2>
            </div>
            <div class="w-1/2 flex justify-end gap-4">
              <h2>
                {{
                  numeralFormat(
                    invoice[0]?.transactions[0].total_price_perton,
                    "0,0[.]00"
                  )
                }}
                VND
              </h2>
            </div>
          </div>
        </div>
        <div class="py-2 w-full flex items-center justify-end text-[10px]">
          <div class="w-[60%] flex items-center justify-between">
            <div class="w-1/2 flex justify-end gap-4">
              <h2>Price / 1kg</h2>
            </div>
            <div class="w-1/2 flex justify-end gap-4">
              <h2>
                {{
                  numeralFormat(invoice[0]?.transactions[0].price, "0,0[.]00")
                }}
                VND
              </h2>
            </div>
          </div>
        </div>

        <!-- <div class="w-full flex items-center justify-end">
          <div class="w-[60%] flex items-center justify-between text-[10px]">
            <div class="w-1/2 flex justify-end gap-4">
              <h2>Due</h2>
            </div>
            <div class="w-1/2 flex justify-end gap-4">
              <h2>28,000,000 VND</h2>
            </div>
          </div>
        </div> -->
        <div
          class="w-full h-[40px] bg-main border-main border-2 mt-4 flex items-center justify-between"
        >
          <div class="w-[70%]">
            <h2 class="text-right text-[10px] text-default mr-4">Total</h2>
          </div>
          <div class="bg-default w-[30%] h-full flex items-center justify-end">
            <h2 class="text-body mr-4 font-bold">
              {{
                numeralFormat(
                  invoice[0]?.transactions[0].total_price_perton,
                  "0,0[.]00"
                )
              }}
              VND
            </h2>
          </div>
        </div>

        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[40px]"
        >
          <h2 class="text-primary5 text-[10px]">Accepted</h2>
          <h2 class="text-primary5 text-[10px]">Issued</h2>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[50px]"
        >
          <h2 class="text-primary5 text-[10px]">Labor Slip</h2>
          <h2 class="text-primary5 text-[10px]">
            {{ getUserName(invoice[0]?.user_id) }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  setup() {
    const handlePrint = () => {
      window.print();
    };
    const route = useRoute();
    const invoice = ref([]);
    const router = useRouter();
    // Function to fetch invoice data
    const fetchInvoiceData = async () => {
      try {
        const req = {
          tableName: "worker_invoice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: Number(route.params.id),
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        invoice.value = response.data;
        console.log("Invoice data:", invoice.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    const plate = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        plate.value = response.data;
        console.log("plate data:", plate.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const getPlatesName = (id) => {
      const name = plate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const users = ref([]);
    const fetchUser = async () => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        users.value = response.data;
        console.log("plate data:", users.value);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };
    const getUserName = (id) => {
      const name = users.value.find((item) => item?.id === id);
      return name?.profile_name;
    };
    onMounted(() => {
      fetchInvoiceData();
      fetchPlates();
      fetchUser();
    });
    return {
      handlePrint,
      invoice,
      formatDate2,
      getPlatesName,
      getUserName,
      router,
    };
  },
};
</script>

<style lang="scss" scoped></style>
