<template>
  <div class="w-[700px] h-[650px] bg-white">
    <div class="details_title">
      <h2 class="ml-4">Supplier {{ supplierName }}</h2>
      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Detail</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="py-4 gap-3 border-b-2 grid grid-cols-2">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2 class="text-nowrap">Created At</h2>
          <h2 class="text-nowrap">
            {{ formatDate2(datatoedit?.createdat) }}
          </h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2 class="text-nowrap text-start">Loan Amount</h2>
          <h2 class="text-nowrap text-start">
            {{ numeralFormat(datatoedit?.amount, "0,0[.]00") }} ៛
          </h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2 class="text-nowrap text-start">Supplier Name</h2>
          <h2 class="text-nowrap text-start">
            {{ supplierName }}
          </h2>
        </div>

        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2 class="text-nowrap text-start">Balance Due</h2>
          <h2 class="text-nowrap text-start">
            {{ numeralFormat(datatoedit?.amount, "0,0[.]00") }} ៛
          </h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2 class="text-nowrap text-start">Status</h2>
          <h2 class="text-nowrap text-start">{{ datatoedit?.status_paid }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Repayment History</h2>
        <div class="w-full border h-[240px] overflow-auto border-primary5">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="border-b text-start border-primary5/50">No</td>
                <td class="border-b text-start border-primary5/50">
                  Created At
                </td>
                <td class="border-b text-start border-primary5/50">Amount</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in Preloan"
                :key="data"
              >
                <td class="border-b text-start border-primary5/50">
                  {{ data.id }}
                </td>
                <td class="border-b text-start border-primary5/50">
                  {{ formatDate2(data.created_at) }}
                </td>

                <td class="border-b text-start border-primary5/50">
                  {{ numeralFormat(data.amount, "0,0[.]00") }} ៛
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4">
          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate2 } from "@/composables/formatDateTime";
import { ref, onMounted, onUnmounted } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import socket from "@/services/socket";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    const suppliers = ref([]);
    const supplierName=ref("");

    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: props.datatoedit?.supplier_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = response.data;
        supplierName.value = suppliers.value[0]?.name;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    const getSupplierName = (id) => {
      const supplier = suppliers.value.find((item) => item?.id === id);
      return supplier?.name;
    };
    const Preloan = ref([]);
    const fetchPreloan = async () => {
      try {
        const req = {
          tableName: "preloans",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
        
            {
              field: "id",
              operator: "=",
              value: props.datatoedit?.preloan_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        Preloan.value = response.data;

        
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const handleDatabaseRealtime = async  () => {
      await Promise.allSettled([fetchSuppliers(), fetchPreloan()]);
    }
    onMounted(async () => {
      await Promise.allSettled([fetchSuppliers(), fetchPreloan()]);
      socket.on("database_realtime", handleDatabaseRealtime);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });
    return {
      supplierName,
      handleClose,
      formatDate2,
      getSupplierName,
    
      Preloan,
    };
  },
};
</script>

<style></style>
