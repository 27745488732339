<template>
  <div class="w-full h-auto bg-default">
    <div class="filter_type border rounded">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          dateFormat="dd/M/yy"
          showIcon
          iconDisplay="input"
          showButtonBar
          selectionMode="range"
          :manualInput="false"
          show-button-bar
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="">
          <span class="text-primary4 mr-2"></span>Type
        </label>
        <Select
          v-model="selectedType"
          :options="transactionTypes"
          optionLabel="name"
          optionValue="value"
          filter
          id="type"
          showClear
          placeholder="select type"
          checkmark
          :highlightOnSelect="true"
          class="w-full md:w-56 h-9 text-start flex items-center"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="">
          <span class="text-primary4 mr-2"></span>Partner
        </label>

        <Select
          v-model="selectedPartner"
          :options="partnerData"
          optionLabel="name"
          optionValue="id"
          filter
          id="partner"
          showClear
          placeholder="select partner"
          checkmark
          :highlightOnSelect="true"
          class="w-full md:w-56 h-9 text-start flex items-center"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="">
          <span class="text-primary4 mr-2"></span>Wallet
        </label>

        <Select
          v-model="selectedWallet"
          :options="walletData"
          optionLabel="name"
          optionValue="value"
          filter
          id="wallet"
          showClear
          placeholder="select wallet"
          checkmark
          :highlightOnSelect="true"
          class="w-full md:w-56 h-9 text-start flex items-center"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
        >
        <button
          @click="handleFilterReport"
          class="h-9 px-5 bg-primary3 text-white rounded hover:bg-primary-dark"
        >
          Filter
        </button>
      </div>
    </div>
    <div class="flex justify-end items-center w-full">
      <div class="w-fit bg-white p-4 flex gap-3">
        <button
          @click="handleExportToExcel"
          class="btncsv flex items-center gap-2"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </div>
          CSV
        </button>

        <button
          @click="handleDownloadPDF"
          class="btncancel flex items-center gap-2"
        >
          <div>
            <font-awesome-icon :icon="['fas', 'file']" class="size-5" />
          </div>
          PDF
        </button>
        <button @click="handlePrintReport" class="flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          Print
        </button>
      </div>
    </div>

    <div id="printTable">
      <div class="flex justify-between items-center w-full">
        <div class="w-[65px] h-[65px] border rounded-full"></div>
        <div class="text-center">
          <h2 class="text-heading2 font-bold">Wallet Transaction</h2>
          <h2 class="text-[16px] font-bold">Report</h2>
          <h2 class="py-2 text-[12pt] items-center">
            011 111 111 / 012 123 456
          </h2>
        </div>
        <div class="text-primary5 text-[12pt] text-left">
          <h2>Str#1 ,#104</h2>
          <h2>Battambang</h2>
          <h2>Battambang city</h2>
          <h2>Cambodia</h2>
        </div>
      </div>

      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody>
            <tr class="text-body text-primary5 bg-white">
              <td class="min-w-[95px] bg-white text-start border">
                Created At
              </td>
              <td class="min-w-[180px] bg-white text-start border">
                Transaction ID
              </td>
              <td class="min-w-[180px] bg-white text-start border">Partner</td>
              <td class="min-w-[180px] bg-white text-start border">Wallet</td>
              <td class="min-w-[180px] bg-white text-start border">Type</td>
              <td class="min-w-[180px] bg-white text-start border">
                Invoice ID
              </td>

              <td class="min-w-[180px] bg-white text-start border">Amount</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white whitespace-nowrap"
              v-for="data in reportData"
              :key="data.transaction_id"
            >
              <td class="border">{{ formatDate(data.transaction_date) }}</td>
              <td class="border">{{ data.transaction_id }}</td>
              <td class="border">{{ data.partner_name }}</td>
              <td
                class="border"
                v-if="data.transaction_type !== 'exchange balance'"
              >
                {{ data.wallet_name }}
              </td>
              <td
                class="border"
                v-if="data.transaction_type == 'exchange balance'"
              >
                {{ data.from_wallet }} → {{ data.to_wallet }}
              </td>

              <td class="border">{{ data.transaction_type }}</td>
              <td class="border">
                {{
                  data.transaction_type == "customer payment"
                    ? data.customer_invoice
                    : data.supplier_invoice
                }}
              </td>

              <td
                class="border"
                v-if="
                  data.transaction_type == 'deposit' ||
                  data.transaction_type == 'withdrawal' ||
                  data.transaction_type == 'preloan' ||
                  data.transaction_type == 'customer payment' ||
                  data.transaction_type == 'supplier payment'
                "
              >
                {{ numeralFormat(data.amount, "0,0[.]0") }}
                {{
                  data.transaction_type === "exchange balance"
                    ? data.to_currency?.symbol_currency
                    : data.transaction_type !== "customer payment" &&
                      data.transaction_type !== "supplier payment"
                    ? data.currency?.symbol_currency
                    : data.transaction_type === "customer payment"
                    ? "đ"
                    : "៛"
                }}
              </td>

              <td
                class="border"
                v-if="data.transaction_type == 'exchange balance'"
              >
                {{ numeralFormat(data.amount_before_exchange, "0,0[.]00") }}
                {{
                  data.from_wallet === "KHR"
                    ? "៛"
                    : data.from_wallet === "VND"
                    ? "đ"
                    : ""
                }}
                = {{ numeralFormat(data.amount_after_exchange, "0,0[.]00") }}
                {{
                  data.to_wallet === "KHR"
                    ? "៛"
                    : data.to_wallet === "VND"
                    ? "đ"
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { formatDateWithTimezone } from "@/utils/formatDateWithTimeZone";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate } from "@/composables/formatDateTime";
import socket from "@/services/socket";
import jsPDF from "jspdf";
import "jspdf-autotable";
import numeral from "numeral";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";

export default {
  setup() {
    const reportData = ref([]);
    const selectDateRage = ref(null);

    const selectedType = ref(null);
    const selectedPartner = ref(null);
    const selectedWallet = ref(null);
    const walletData = ref([
      {
        name: "KHR",
        value: "KHR",
      },
      {
        name: "VND",
        value: "VND",
      },
    ]);
    const transactionTypes = ref([
      {
        name: "Deposit",
        value: "deposit",
      },
      {
        name: "Withdrawal",
        value: "withdraw",
      },
      {
        name: "Exchange Balance",
        value: "exchange balance",
      },
      {
        name: "Preloan",
        value: "preloan",
      },
      {
        name: "Customer Payment",
        value: "customer payment",
      },
      {
        name: "Supplier Payment",
        value: "supplier payment",
      },
    ]);

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const fetchAllWalletTransaction = async () => {
      try {
        let dynamicConditions = [];
        if (selectedPartner.value) {
          dynamicConditions.push({
            field: "partner_id",
            operator: "=",
            value: selectedPartner.value,
            typeTable: "table",
          });
        }
        if (
          selectDateRage.value &&
          selectDateRage.value?.[0] &&
          selectDateRage.value?.[1]
        ) {
          const startOfDay = formatDateWithTimezone(
            selectDateRage.value[0],
            false
          );

          const endOfDay = formatDateWithTimezone(
            selectDateRage.value[1],
            true
          );

          dynamicConditions.push(
            {
              field: "created_at",
              operator: ">=",
              value: startOfDay, // start of day
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: endOfDay, // end of day
              typeTable: "table",
            }
          );
        }

        if (selectedType.value) {
          dynamicConditions.push({
            field: "transaction_type",
            operator: "=",
            value: selectedType.value,
            typeTable: "table",
          });
        }

        if (selectedWallet.value) {
          dynamicConditions.push({
            field: "wallet",
            operator: "=",
            value: selectedWallet.value,
            typeTable: "table",
          });
        }
        const params = {
          tableName: "partner_transaction_history",
          sortColumn: "id",
          sortDirection: "ASC",
          columnAliases: JSON.stringify({
            transaction_date: {
              table: "partner_transaction_history",
              column: "created_at",
            },
            transaction_id: {
              table: "partner_transaction_history",
              column: "id",
            },
            transaction_type: {
              table: "partner_transaction_history",
              column: "transaction_type",
            },
            from_currency: {
              table: "partner_transaction_history",
              column: "from_currency",
            },
            to_currency: {
              table: "partner_transaction_history",
              column: "to_currency",
            },
            amount: { table: "partner_transaction_history", column: "amount" },
            amount_before_exchange: {
              table: "partner_transaction_history",
              column: "amount_before_exchange",
            },
            amount_after_exchange: {
              table: "partner_transaction_history",
              column: "amount_after_exchange",
            },
            currency: {
              table: "partner_transaction_history",
              column: "currency",
            },
            to_wallet: {
              table: "partner_transaction_history",
              column: "to_wallet",
            },
            from_wallet: {
              table: "partner_transaction_history",
              column: "from_wallet",
            },
            wallet_name: {
              table: "partner_transaction_history",
              column: "wallet",
            },
            partner_name: { table: "partners", column: "name" },
            supplier_invoice: {
              table: "partner_transaction_history",
              column: "supplier_invoice",
            },
            customer_invoice: {
              table: "partner_transaction_history",
              column: "customer_invoice",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "partners",
              joinColumnChild: "partner_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const res = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params,
          }
        );

        console.log("res", res.data);

        if (res.data.length > 0) {
          reportData.value = res.data;
          console.log("reportData", reportData.value);
        } else {
          reportData.value = [];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const suppliersData = ref([]);

    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          sortDirection: "ASC",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        suppliersData.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const partnerData = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          sortDirection: "ASC",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        partnerData.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getpartnerName = (id) => {
      const findParnter = partnerData.value.find((p) => p.id === id);
      return findParnter ? findParnter.name : "";
    };

    const handleFilterReport = () => {
      fetchAllWalletTransaction();
    };

    const handlePrintReport = () => {
      const printContents = document.getElementById("printTable").outerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    };

    const handleExportToExcel = () => {
      const dataToExport = reportData.value.map((item) => ({
        "Created At": formatDate(item.transaction_date),
        "Transaction ID": item.transaction_id,
        Partner: item.partner_name,
        Wallet:
          item.transaction_type !== "exchange balance"
            ? item.wallet_name
            : `${item.from_wallet} → ${item.to_wallet}`,
        Type: item.transaction_type,
        "Invoice ID":
          item.transaction_type === "customer payment"
            ? item.customer_invoice
            : item.transaction_type === "supplier payment"
            ? item.supplier_invoice
            : "",
        Amount: `${
          item.transaction_type === "exchange balance"
            ? `${numeral(item.amount_before_exchange).format("0,0[.]00")} ${
                item.from_wallet === "KHR"
                  ? "៛"
                  : item.from_wallet === "VND"
                  ? "đ"
                  : ""
              } = ${numeral(item.amount_after_exchange).format("0,0[.]00")} ${
                item.to_wallet === "KHR"
                  ? "៛"
                  : item.to_wallet === "VND"
                  ? "đ"
                  : ""
              }`
            : item.transaction_type === "deposit" ||
              item.transaction_type === "withdrawal" ||
              item.transaction_type === "preloan" ||
              item.transaction_type === "customer payment" ||
              item.transaction_type === "supplier payment"
            ? `${numeral(item.amount).format("0,0[.]0")} ${
                item.transaction_type === "customer payment"
                  ? "đ"
                  : item.transaction_type === "supplier payment"
                  ? "៛"
                  : item.currency?.symbol_currency
              }`
            : ""
        }`,
        "Transaction Details": item.transaction_details,
      }));

      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Wallet Transaction");
      XLSX.writeFile(wb, "wallet_transaction_report.xlsx");
    };

    const handleDownloadPDF = async () => {
      // Create a temporary div for the content
      const printDiv = document.createElement("div");
      printDiv.innerHTML = `
        <div style="padding: 20px; font-family: 'Noto Serif Khmer', serif;">
          <!-- Header -->
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
            <div style="width: 65px; height: 65px; border: 1px solid #000; border-radius: 50%;"></div>
            <div style="text-align: center;">
              <h2 style="font-size: 24px; margin: 0; font-weight: bold;">Wallet Transaction </h2>
              <h2 style="font-size: 16px; margin: 5px 0; font-weight: bold;">Report</h2>
              <h2 style="font-size: 14px; margin: 0;">011 111 111 / 012 123 456</h2>
            </div>
            <div style="text-align: left;">
              <div>Str#1 ,#104</div>
              <div>Battambang</div>
              <div>Battambang city</div>
              <div>Cambodia</div>
            </div>
          </div>

          <!-- Table -->
          <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
            <thead>
              <tr style="background-color: #f8f9fa;">
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Created At</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Transaction ID</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Partner</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Wallet</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Type</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Invoice ID</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Amount</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Transaction Details</th>
              </tr>
            </thead>
            <tbody>
              ${reportData.value
                .map(
                  (item) => `
                <tr>
                  <td style="border: 1px solid #ddd; padding: 8px;">${formatDate(
                    item.transaction_date
                  )}</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.transaction_id
                  }</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.partner_name
                  }</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">
                    ${
                      item.transaction_type !== "exchange balance"
                        ? item.wallet_name
                        : `${item.from_wallet} → ${item.to_wallet}`
                    }
                  </td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.transaction_type
                  }</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">
                    ${
                      item.transaction_type === "customer payment"
                        ? item.customer_invoice
                        : item.transaction_type === "supplier payment"
                        ? item.supplier_invoice
                        : ""
                    }
                  </td>
                  <td style="border: 1px solid #ddd; padding: 8px;">
                    ${
                      item.transaction_type === "exchange balance"
                        ? `${numeral(item.amount_before_exchange).format(
                            "0,0[.]00"
                          )} ${
                            item.from_wallet === "KHR"
                              ? "៛"
                              : item.from_wallet === "VND"
                              ? "đ"
                              : ""
                          } = ${numeral(item.amount_after_exchange).format(
                            "0,0[.]00"
                          )} ${
                            item.to_wallet === "KHR"
                              ? "៛"
                              : item.to_wallet === "VND"
                              ? "đ"
                              : ""
                          }`
                        : item.transaction_type === "deposit" ||
                          item.transaction_type === "withdrawal" ||
                          item.transaction_type === "preloan" ||
                          item.transaction_type === "customer payment" ||
                          item.transaction_type === "supplier payment"
                        ? `${numeral(item.amount).format("0,0[.]0")} ${
                            item.transaction_type === "customer payment"
                              ? "đ"
                              : item.transaction_type === "supplier payment"
                              ? "៛"
                              : item.currency?.symbol_currency
                          }`
                        : ""
                    }
                  </td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.transaction_details || ""
                  }</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
      `;

      document.body.appendChild(printDiv);

      try {
        // Use html2canvas to capture the content
        const canvas = await html2canvas(printDiv, {
          scale: 2, // Increase quality
          useCORS: true,
          logging: false,
          windowWidth: 1200, // Set a fixed width for consistency
          onclone: (clonedDoc) => {
            const element = clonedDoc.querySelector("div");
            element.style.width = "1200px";
          },
        });

        // Create PDF with landscape orientation for better table fit
        const pdf = new jsPDF("l", "mm", "a4");

        const imgWidth = 297; // A4 width in landscape
        const pageHeight = 210; // A4 height in landscape
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add image to first page
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;

        // Add new pages if content exceeds first page
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }

        pdf.save("wallet_transaction_report.pdf");
      } catch (error) {
        console.error("PDF generation error:", error);
      } finally {
        document.body.removeChild(printDiv);
      }
    };

    const handleSumAmountOfRice = (riceType) => {
      return riceType.reduce((acc, curr) => acc + curr.amount, 0);
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchSupplier(),
        fetchPartner(),
        fetchAllWalletTransaction(),
      ]);
    };

    onMounted(async () => {
      // Get first and last day of current month
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      // Set the date range
      selectDateRage.value = [firstDay, lastDay];
      await Promise.allSettled([
        fetchSupplier(),
        fetchPartner(),
        fetchAllWalletTransaction(),
      ]);

      window.addEventListener("resize", handleResize);
      handleResize();

      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    return {
      walletData,
      selectedWallet,
      selectedType,
      transactionTypes,
      selectedPartner,
      partnerData,

      getpartnerName,
      handlePrintReport,
      handleExportToExcel,
      handleDownloadPDF,
      suppliersData,
      formatDate,

      handleSumAmountOfRice,

      selectDateRage,
      reportData,
      handleFilterReport,
      tableClasses,
      subClassesTable,
      navClasses,
      selectClasses,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Khmer:wght@100..900&display=swap");
</style>
