// utils.js




export function getResponsiveFilter(windowWidth) {
    if (windowWidth >= 1300) {
        return 'w-full flex items-end justify-between gap-5 duration-300';
    } else if (windowWidth <= 1080 || !windowWidth) {
        return 'w-full flex flex-col space-y-2 duration-300';
    } else {
        return 'w-full flex flex-col space-y-2 duration-300';
    }
}




export function getResponsiveMainTable(windowWidth) {
    if (windowWidth > 1590) {
        return 'w-[500px] relative overflow-auto border h-[500px] duration-300 ';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[400px] relative overflow-auto  border h-[500px] duration-300';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[400px] relative overflow-auto border h-[500px] duration-300';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[300px] relative overflow-auto border   h-[500px] duration-300';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-[250px] frelative overflow-auto mt-4 border  h-[500px] duration-300';
    } else {
        return 'w-full relative overflow-auto mt-4 border   h-[500px] duration-300';
    }
}
export function getResponsiveSubTable(windowWidth) {
    if (windowWidth > 1690) {
        return 'w-[1100px]  overflow-auto h-[500px] duration-300 ';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[750px]   overflow-auto  h-[500px] duration-300 ';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[600px]  overflow-auto h-[500px] duration-300 ';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[600px] overflow-auto h-[500px] duration-300 ';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-[500px] overflow-auto h-[500px] duration-300 ';
    } else {
        return 'w-[900px] overflow-auto h-[500px] duration-300 ';
    }
}
export function getResponsiveTable(windowWidth) {
    if (windowWidth > 1690) {
        return 'relative w-full overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'relative w-[1200px] overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'relative w-[1000px] overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'relative w-[900px] overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'relative w-[900px] overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    } else {
        return 'relative w-full overflow-x-auto mt-4 border bg-white h-[500px] duration-300';
    }
}


export function getResponsiveTableNav(windowWidth) {
    if (windowWidth > 1690) {
        return 'w-full flex items-center justify-between mt-3  relative  duration-300';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[1200px] flex items-center justify-between mt-3  relative  duration-300';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[1000px] flex items-center justify-between mt-3  relative  duration-300';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[900px] flex items-center justify-between mt-3  relative   duration-300';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-w-[900px] flex items-center justify-between mt-3  relative  duration-300';
    } else {
        return 'w-full flex items-center justify-between mt-3  relative  duration-300';
    }
}
export function getResponsiveSelect(windowWidth) {
    if (windowWidth > 1690) {
        return 'w-full bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[1200px] bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[1000px] bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[900px] bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-[900px] bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    } else {
        return 'w-full bg-white p-4 mt-4 flex gap-3 flex flex-wrap duration-300 rounded-md border';
    }
}

export function getResponsiveElementClass(windowWidth) {
    if (windowWidth > 1690) {
        return 'w-[1620px] space-x-2 py-2 px-2 mt-2 flex text-nowrap  overflow-auto border duration-300';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[1200px]  space-x-2 py-2 px-2 mt-2 flex text-nowrap overflow-auto border duration-300';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[1000px] space-x-2 py-2 px-2 mt-2 flex text-nowrap overflow-auto border duration-300';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[900px] space-x-2 py-2 px-2 mt-2 flex text-nowrap  overflow-auto border duration-300';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-[900px] space-x-2 py-2 px-2 mt-2 flex text-nowrap  overflow-auto border duration-300';
    } else {
        return 'w-full space-x-2 py-2 px-2 mt-2 flex text-nowrap  overflow-auto border duration-300';
    }
}

export function getResponsiveParkingCard(windowWidth) {
    if (windowWidth > 1590) {
        return 'w-[500px] h-[200px] duration-300';
    } else if (windowWidth > 1400 && windowWidth <= 1590) {
        return 'w-[500px] h-[200px] duration-300';
    } else if (windowWidth > 1300 && windowWidth <= 1400) {
        return 'w-[500px] h-[200px] duration-300';
    } else if (windowWidth > 1080 && windowWidth <= 1300) {
        return 'w-[300px] h-[170px] duration-300';
    } else if (windowWidth > 900 && windowWidth <= 1080) {
        return 'w-[250px] h-[150px] duration-300';
    } else {
        return 'w-full h-[150px]';
    }
}


