<template>
  <div class="relative overflow-x-auto bg-white">
    <div class="w-full flex items-center justify-between py-4">
      <h2 class="ml-4">Truck: Truck-01</h2>
      <div class="flex items-center gap-3">
        <h2 class="mr-4">Rice Detail</h2>
        <div @click="handleClose" class="closeform bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="overflow-auto rounded-md border">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[126px] border-b">No.</td>
              <td class="min-w-[95px] border-b text-start">
                Created At
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>
              <td class="min-w-[180px] border-b text-start">
                Rice Type
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>
              <td class="min-w-[186px] border-b text-start">
                Weight
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>
              <td class="min-w-[186px] border-b text-start">
                Cut out
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>
              <td class="min-w-[186px] border-b text-start">
                Cut out
                <span class="ml-2 cursor-pointer"
                  ><font-awesome-icon :icon="['fas', 'sort']"
                /></span>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in 5"
              :key="data"
            >
              <td class="border-b">Silver</td>
              <td class="border-b">Silver</td>
              <td class="border-b">Silver</td>
              <td class="border-b">Laptop</td>
              <td class="border-b">$2999</td>
              <td class="border-b">$2999</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
