<template>
  <div>
    <div :class="selectClasses" class="w-fit bg-white p-4 -mt-0.5 flex gap-3">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Warehouse</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          :options="warehouse_selection"
          filter
          placeholder="Select Warehouse"
          option-label="name"
          option-value="id"
          v-model="selectWarehouse"
          show-clear
          checkmark
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
        >
        <el-button @click="handleFilterButtonClick" size="large"
          >Filter</el-button
        >
      </div>
    </div>

    <div class="flex items-start w-full gap-4 mt-4">
      <div>
        <div :class="subClassesTable" class="h-[550px] border">
          <table>
            <tbody>
              <tr class="">
                <td colspan="9" class="border-b sticky top-0 bg-white z-10">
                  <div class="text-start flex items-center justify-center">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3">
                        <h2 class="text-primary2">Rice Total Stock:</h2>
                      </div>
                      <div class="flex items-center gap-3">
                        <span class="text-primary2 font-semibold"
                          >{{ calculateTotalStock }} Kg</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body header_table">
                <td class="min-w-[100px] border-b text-start">Rice Type</td>
                <td
                  @click="sortData('weight')"
                  class="min-w-[180px] border-b text-start toggle_sort"
                >
                  Stock Available
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'weight' && sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>

                <td
                  @click="sortData('name')"
                  class="min-w-[180px] border-b text-start toggle_sort"
                >
                  Warehouse
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="
                          sortColumns === 'name' && sortDirections === 'asc'
                            ? ['fas', 'sort']
                            : ['fas', 'sort']
                        "
                      />
                    </transition>
                  </span>
                </td>
                <td class="w-[70px] border text-start">Action</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2 bg-white"
                v-for="data in warehouse"
                :key="data.id"
              >
                <td class="border-b">
                  {{
                    warehouseRiceNames[data.id]
                      ? warehouseRiceNames[data.id]
                          .toString()
                          .replace(/[\[\]"]/g, "")
                      : "No rice "
                  }}
                </td>

                <td class="border-b">
                  {{
                    numeralFormat(warehouseTotalStock[data.id] || 0, "0,0[.]00")
                  }}
                  Kg
                </td>
                <td class="border-b">
                  {{ data.name }}
                </td>
                <td class="border flex items-center gap-3">
                  <svg-icon
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_warehouse?.rice_stock?.view === true)
                    "
                    :path="mdiEyeOutline"
                    @click="handlePopUp(data)"
                    type="mdi"
                    class="info size-8 text-white hover:text-main bg-primary7 rounded p-1.5"
                  ></svg-icon>
                  <button
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_warehouse?.rice_convert?.create ===
                          true)
                    "
                    class="btncancel bg-red-500 hover:bg-red-600 text-white border-none cursor-pointer"
                    @click="handleRiceConvert(data)"
                  >
                    Convert
                  </button>
                  <button
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_warehouse?.adjust?.create === true)
                    "
                    class="btncancel border-none bg-primary3 hover:bg-main text-white cursor-pointer"
                    @click="handleRiceAdjust(data)"
                  >
                    Adjust
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-full flex items-center justify-between py-3 border-t bg-white"
        >
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            :pageSize="pageSize"
            :totalRecord="warehouse.length"
            @page-change="handlePageChange"
            @page-size-change="handlePageSizeChange"
          />
        </div>
      </div>
      <div>
        <div :class="mainClassesTable" class="h-[600px]">
          <div class="w-[90%] mx-auto flex items-center justify-center">
            <div class="mt-4">
              <Chart
                type="doughnut"
                :data="chartData"
                :options="chartOptions"
                class="w-full md:w-[20rem]"
              />
            </div>
          </div>
          <div
            class="w-[80%] flex items-center justify-between mx-auto border-b"
          >
            <h2 class="ml-4">Total Stock</h2>
            <h2 class="mr-4">{{ calculateTotalStock }} Kg</h2>
          </div>
          <div class="w-[80%] py-4 mx-auto">
            <div
              v-for="(data, index) in globalRiceStocks"
              :key="index"
              class="space-y-4 w-full"
            >
              <h1 class="text-lg font-bold"></h1>
              <div class="flex flex-col gap-4">
                <div
                  v-for="(data, index) in globalRiceStocks"
                  :key="index"
                  class="flex justify-between w-full"
                >
                  <p class="flex items-center gap-2">
                    <span>
                      <div
                        :class="[
                          'w-6 h-6 rounded-full flex items-center justify-center',
                          getColorClass(data.rice_name),
                        ]"
                      >
                        <div class="w-3 h-3 bg-white rounded-full"></div>
                      </div>
                    </span>
                    <span>{{ data.rice_name }}</span>
                  </p>
                  <div class="flex items-center gap-2">
                    <p>{{ numeralFormat(data.total_weight, "0,0[.]00") }} Kg</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[1000px] h-[500px] bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :rice_group="rice_group"
                    :warehouse_id="warehouse_id"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isRiceAdjust" as="template">
      <Dialog as="div" @close="handleCloseRiceAdjust" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseRiceAdjust"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :rice_group="rice_group"
                    :warehouse_id="warehouse_id"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isRiceConvert" as="template">
      <Dialog as="div" @close="handleCloseRiceConver" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseRiceConver"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :rice_group="rice_group"
                    :warehouse_id="warehouse_id"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isTranfer" as="template">
      <Dialog as="div" @close="handleCloseTranfer" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseTranfer"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                    :rice_group="rice_group"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
  </div>

  <component
    v-if="currentComponent === 'ToastVue'"
    :is="currentComponent"
    :showToast="showToast"
    :informMessage="informMessage"
    @onClose="handleClearToast"
  />
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import Chart from "primevue/chart";

import RiceDetailsPopupVue from "@/form/RiceDetailsPopup.vue";
import RiceAdjust from "@/form/RiceAdjust.vue";
import RiceConvertVue from "@/form/RiceConvert.vue";
import RiceTranferVue from "@/form/RiceTranfer.vue";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { mdiEyeOutline } from "@mdi/js";

import Pagination from "@/components/Pagination.vue";
import Toast from "@/components/Toast.vue";
import { decodeJwt } from "@/composables/decodeJWT";
import numeral from "numeral";
import ToastVue from "@/components/ToastVue.vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
export default {
  components: {
    Chart,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    RiceDetailsPopupVue,
    RiceAdjust,
    RiceConvertVue,
    RiceTranferVue,
    Pagination,
    Toast,
    ToastVue,
  },
  setup() {
    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });

    const currentComponent = ref("");
    const isOpen = ref(false);
    const isTranfer = ref(false);
    const datatoedit = ref(null);
    const warehouse = ref([]);
    const showToast = ref(false);
    const informMessage = ref("");

    const handleTranfer = (doc) => {
      isTranfer.value = true;
      currentComponent.value = "RiceTranferVue";
      datatoedit.value = doc;
    };
    const handleCloseTranfer = (doc) => {
      isTranfer.value = false;
      currentComponent.value = "";
      datatoedit.value = doc;
    };
    const warehouse_id = ref(null);
    const handlePopUp = (warehouse) => {
      currentComponent.value = "RiceDetailsPopupVue";
      isOpen.value = true;
      datatoedit.value = warehouse;
    };

    const isRiceAdjust = ref(false);

    const isRiceConvert = ref(false);
    const rice_group = ref({});

    const handleRiceConvert = (warehouse) => {
      if (
        warehouseTotalStock.value[warehouse.id] &&
        warehouseTotalStock.value[warehouse.id] > 0
      ) {
        currentComponent.value = "RiceConvertVue";
        isRiceConvert.value = true;
        datatoedit.value = warehouse;
      } else {
        currentComponent.value = "ToastVue";
        showToast.value = true;
        informMessage.value = `No rice stock in ${warehouse.name} warehouse`;
      }
    };

    const handleClearToast = () => {
      showToast.value = false;
      informMessage.value = "";
      currentComponent.value = "";
    };

    const handleRiceAdjust = (warehouse) => {
      if (
        warehouseTotalStock.value[warehouse.id] &&
        warehouseTotalStock.value[warehouse.id] > 0
      ) {
        currentComponent.value = "RiceAdjust";
        isRiceAdjust.value = true;
        datatoedit.value = warehouse; // Store transactions for further processing
      } else {
        currentComponent.value = "ToastVue";
        showToast.value = true;
        informMessage.value = `No rice stock in ${warehouse.name} warehouse`;
      }
    };
    const handleCloseRiceAdjust = () => {
      isRiceAdjust.value = false;
      currentComponent.value = "";
      datatoedit.value = null;
    };
    const handleCloseRiceConver = () => {
      isRiceConvert.value = false;
      currentComponent.value = "";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      datatoedit.value = null;
    };
    const series = ref([]);

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction

    const rice_stock = ref([]);
    const total_weight = ref(null);
    const totalRecord = ref(0);
    const groupedStock = ref({});

    const rice_type = ref(null);
    const selectWarehouse = ref(null);
    const selectRice = ref("");
    const warehouse_selection = ref([]);
    const fetchWarehouseSelection = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: "warehouse",
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(apiUrl, { params });
        warehouse_selection.value = response.data.data;
      } catch (error) {
        console.log(error, "error");
      }
    };
    const fetchWarehouse = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: "warehouse",
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          dynamicConditions: JSON.stringify([
            ...(selectWarehouse.value
              ? [
                  {
                    field: "id",
                    operator: "=",
                    value: selectWarehouse.value,
                  },
                ]
              : []),
          ]),
        };
        const response = await axios.get(apiUrl, { params });
        warehouse.value = response.data.data;
      } catch (error) {
        console.log(error, "error");
      }
    };

    const globalRiceStocks = ref([]);
    const warehouseTotalStock = ref({});
    const warehouseRiceNames = ref({});
    const fetchStockRiceTransactionHistoryByIDWarehouse = async () => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );

        if (response.data.length > 0) {
          // 1. Calculate total stock by warehouse_id
          const totalStockByWarehouse = response.data.reduce(
            (acc, transaction) => {
              const warehouseId = transaction.warehouse_id;
              if (!acc[warehouseId]) {
                acc[warehouseId] = 0;
              }

              // Handle single JSONB object
              if (transaction.rice && transaction.rice.final_weight) {
                acc[warehouseId] +=
                  parseFloat(transaction.rice.final_weight) || 0;
              }
              return acc;
            },
            {}
          );

          // 2. Get unique rice names by warehouse_id
          const riceNamesByWarehouse = response.data.reduce(
            (acc, transaction) => {
              const warehouseId = transaction.warehouse_id;
              if (!acc[warehouseId]) {
                acc[warehouseId] = new Set();
              }

              // Handle single JSONB object
              if (transaction.rice && transaction.rice.rice_name) {
                acc[warehouseId].add(transaction.rice.rice_name);
              }

              return acc;
            },
            {}
          );

          // Convert Sets to Arrays
          Object.keys(riceNamesByWarehouse).forEach((warehouseId) => {
            riceNamesByWarehouse[warehouseId] = Array.from(
              riceNamesByWarehouse[warehouseId]
            );
          });

          warehouseTotalStock.value = totalStockByWarehouse;
          warehouseRiceNames.value = riceNamesByWarehouse;

          // Calculate global rice stocks from raw response data
          const globalStocks = response.data.reduce((acc, transaction) => {
            if (transaction.rice && transaction.rice.rice_name) {
              const riceName = transaction.rice.rice_name;
              const weight = parseFloat(transaction.rice.final_weight) || 0;

              if (!acc[riceName]) {
                acc[riceName] = 0;
              }
              acc[riceName] += weight;
            }
            return acc;
          }, {});

          globalRiceStocks.value = Object.entries(globalStocks).map(
            ([rice_name, total_weight]) => ({
              rice_name,
              total_weight,
            })
          );
        }

        //Object.entries() is a built-in JavaScript method that transforms an object into
        //an array of key-value pairs. Each key-value pair is represented as an array where the
        //first element is the key and the second element is the value.
      } catch (error) {
        console.error("Error fetching data:", error);
        return {
          totalStock: {},
          riceNames: {},
        };
      }
    };

    const calculateTotalStock = computed(() => {
      const total = Object.values(warehouseTotalStock.value).reduce(
        (sum, value) => {
          return sum + (parseFloat(value) || 0);
        },
        0
      );
      return numeral(total).format("0,0[.]00");
    });

    const handleFilterButtonClick = async () => {
      await fetchWarehouse();
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchWarehouse(),
        fetchUserByID(),
        fetchStockRiceTransactionHistoryByIDWarehouse(),
        fetchWarehouseSelection(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([
        fetchWarehouse(),
        fetchUserByID(),
        fetchStockRiceTransactionHistoryByIDWarehouse(),
        fetchWarehouseSelection(),
      ]);

      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const getTotalStock = (stock) => {
      if (!Array.isArray(stock)) {
        return 0; // Return 0 or handle the error as appropriate
      }
      return stock.reduce((total, item) => {
        return total + (parseFloat(item.weight) || 0);
      }, 0);
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWarehouse();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWarehouse();
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);
    // Sorting function that works for both warehouse fields and warehouseTotalStock
    const sortTotalRice = (direction, data, field) => {
      return data.sort((a, b) => {
        // If sorting by weight, use warehouseTotalStock[a.id] and warehouseTotalStock[b.id]
        let valA = field === "weight" ? warehouseTotalStock[a.id] : a[field];
        let valB = field === "weight" ? warehouseTotalStock[b.id] : b[field];

        // Compare the values depending on the direction
        if (direction === "asc") {
          return valA > valB ? 1 : valA < valB ? -1 : 0;
        } else {
          return valA < valB ? 1 : valA > valB ? -1 : 0;
        }
      });
    };
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;

      warehouse.value = sortTotalRice(direction, warehouse.value, field); // Always use sortTotalRice for sorting
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "convert":
            message = "Convert Success";
            type = "infor";
            break;
          case "rice_stock":
            message = "Adjust Success";
            type = "success";
            break;
          case "Trasfer":
            message = "Trasfer Success";
            type = "infor";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    // Prepare chart data
    const chartData = computed(() => {
      // Map the globalRiceStocks data for the chart
      const labels = globalRiceStocks.value.map((item) => item.rice_name);
      const data = globalRiceStocks.value.map((item) => item.total_weight);

      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgb(255, 99, 132)", // Red
              "rgb(54, 162, 235)", // Blue
              "rgb(255, 159, 64)", // Orange
              "rgb(75, 192, 192)", // Teal
              "rgb(153, 102, 255)", // Purple
              "rgb(255, 205, 86)", // Yellow
            ],
          },
        ],
      };
    });

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          display: true, // Enable legend display
        },
      },
    };

    const totalWeight = ref(0); // Store the total weight

    const getColorClass = (riceName) => {
      if (!riceName) return "bg-gray-500";

      const length = riceName.length;
      if (length % 2 === 0) return "bg-[rgb(255,99,132)]"; // Red (matches chart)
      if (length % 3 === 0) return "bg-[rgb(54,162,235)]"; // Blue (matches chart)
      if (length === 8) return "bg-[rgb(255,159,64)]"; // Orange (matches chart)
      if (length === 9) return "bg-[rgb(75,192,192)]"; // Teal (matches chart)
      if (length > 7) return "bg-[rgb(153,102,255)]"; // Purple (matches chart)
      return "bg-[rgb(255,205,86)]"; // Yellow (matches chart)
    };

    return {
      currentComponent,
      showToast,
      informMessage,
      handleClearToast,
      globalRiceStocks,
      calculateTotalStock,
      warehouseTotalStock,
      warehouseRiceNames,
      warehouse,
      authUserRole,
      permissions,
      chartData,
      chartOptions,
      series,
      isOpen,
      handlePopUp,
      currentComponent,
      handleClose,
      handleCloseRiceAdjust,
      handleRiceAdjust,
      isRiceAdjust,
      isRiceConvert,
      handleCloseRiceConver,
      handleRiceConvert,
      handleCloseTranfer,
      isTranfer,
      handleTranfer,
      subClassesTable,
      mainClassesTable,
      rice_stock,
      total_weight,
      getTotalStock,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      datatoedit,

      handleFilterButtonClick,

      warehouse,
      showSuccessToast,
      toast,
      groupedStock,
      rice_group,
      mdiEyeOutline,

      totalRecord,
      sortData,
      sortDirections,
      sortColumns,
      selectWarehouse,
      rice_type,
      selectRice,

      warehouse,

      totalWeight,

      warehouse_id,

      getColorClass,

      warehouse_selection,
      selectClasses,
    };
  },
};
</script>

<style scoped></style>
