<template>
  <div class="w-full text-primary6">
    <div :class="navClasses">
      <div class="py-2">
        <h2>{{ t("message.weighing_list") }}</h2>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_weighing?.weight?.create === true)
        "
      >
        <button @click="handleAdd" class="m-2">
          {{ t("message.new_weighing") }}
        </button>
      </div>
    </div>
    <div class="flex gap-3 items-start">
      <div>
        <div :class="mainClassesTable" class="h-[600px]">
          <table>
            <tbody>
              <tr class="header_table">
                <!-- <td class="min-w-[126px] border-b">Acion</td> -->
                <td
                  @click="sortData('supplier_name', 'warehouse_name')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  {{ t("message.supplier") }}
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          (sortColumns === 'supplier_name' ||
                            sortColumns === 'warehouse_name') &&
                          sortDirections === 'asc'
                            ? 'sort-up'
                            : (sortColumns === 'supplier_name' ||
                                sortColumns === 'warehouse_name') &&
                              sortDirections === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>

                <td
                  @click="sortData('plate_number', 'trucktor')"
                  class="border-b text-nowrap cursor-pointer toggle_sort"
                >
                  {{ t("message.truck") }}
                  <span class="ml-2">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          (sortColumns === 'plate_number' ||
                            sortColumns === 'trucktor') &&
                          sortDirections === 'asc'
                            ? 'sort-up'
                            : (sortColumns === 'plate_number' ||
                                sortColumns === 'trucktor') &&
                              sortDirections === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
              </tr>
            </tbody>
            <tr class="font-semibold">
              <td colspan="5" class="border-b text-nowrap">
                <div class="flex items-center justify-center gap-2">
                  <IconField class="w-[80%] placeholder:text-body2">
                    <InputText
                      v-model="searchValue"
                      placeholder="Search | Supplier | Trucktor "
                    />
                    <InputIcon class="pi pi-search" />
                  </IconField>
                </div>
              </td>
            </tr>
            <tbody v-if="joinWeighingData.length > 0">
              <tr
                class="text-body font-normal text-primary2 bg-white border-b"
                v-for="data in joinWeighingData"
                :key="data.weight_id"
                @click="handleBoatSelection(data)"
              >
                <td class="border-b text-nowrap">
                  {{
                    data.supplier_name
                      ? data.supplier_name
                      : data.warehouse_name
                  }}
                </td>

                <td class="border-b text-nowrap">
                  {{
                    data.plate_number
                      ? data.plate_number.plate_number
                      : data.trucktor
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="joinWeighingData.length > 0">
          <AliesPagination
            :totalItems="joinWeighingData.length"
            :totalPages="totalPages"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :getRowOptions="getRowOptions"
            @updatePage="changePage"
            @updatePageSize="changePageSize"
          />
        </div>
      </div>

      <div :class="subClassesTable" class="border h-[600px]">
        <table class="text-body font-normal text-primary2">
          <tbody>
            <tr class="font-semibold">
              <td class="border-b w-full" colspan="5">
                <div class="w-full flex items-center justify-between">
                  <div class="w-1/2">
                    <h2 class="text-nowrap">
                      {{ t("message.count_time") }}:
                      {{
                        weighing_rice_history.length
                          ? weighing_rice_history.length
                          : 0
                      }}
                    </h2>
                  </div>
                  <div class="w-1/2 flex justify-end">
                    <IconField class="w-[80%] placeholder:text-body2">
                      <InputText
                        v-model="searcQuery"
                        placeholder="Search | Boat "
                      />
                      <InputIcon class="pi pi-search" />
                    </IconField>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="text-primary3 bg-primary3/20 font-semibold">
              <td
                @click="sortData('weighing_rice_history_id')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.no") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weighing_rice_history_id' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weighing_rice_history_id' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td class="border-b text-nowrap">
                {{ t("message.created_at") }}
              </td>
              <td
                @click="sortData('weight_in')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.weight_in") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_in' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_in' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_out')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.weight_out") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_out' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_out' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_drop')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.remain_weight") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_drop' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_drop' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('rice')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.boat") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'rice' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'rice' && sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_out')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                Rice
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_out' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_out' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td class="border-b text-nowrap">{{ t("message.status") }}</td>
              <td class="border-b text-nowrap">{{ t("message.action") }}</td>
            </tr>
          </tbody>
          <tbody></tbody>
          <tbody v-if="weighing_rice_history.length > 0">
            <tr
              class="bg-white"
              v-for="(data, i) in weighing_rice_history"
              :key="data.id"
            >
              <td class="border-b text-nowrap">
                {{ data.weighing_rice_history_id }}
              </td>
              <td class="border-b text-nowrap">
                {{ formatDate2(data.createdAt) }}
              </td>

              <td class="border-b text-nowrap">
                {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-nowrap">
                {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-nowrap">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-nowrap">
                {{ data.name }}
              </td>

              <td class="border-b text-nowrap">
                <p v-for="rice in data.rice" class="text-nowrap" :key="rice.id">
                  {{ rice.rice_name }}
                </p>
              </td>
              <td class="border-b text-nowrap">
                <el-button
                  @click="handlePopUp(data)"
                  :disabled="i !== weighing_rice_history.length - 1"
                  v-if="data.is_hold === true"
                  type="danger"
                  round
                >
                  Pending
                </el-button>

                <el-button
                  v-if="
                    data.is_hold === false &&
                    data.boat_enter_parking_id !== null
                  "
                  :disabled="i !== weighing_rice_history.length - 1"
                  type="primary"
                  @click="handlePopUp(data)"
                  round
                >
                  Send to parking
                </el-button>

                <el-button
                  v-if="data.is_hold === false && data.warehouse_id != null"
                  :disabled="i !== weighing_rice_history.length - 1"
                  type="primary"
                  @click="handlePopUp(data)"
                  round
                >
                  Send to warehouse
                </el-button>
              </td>
              <td class="border-b text-nowrap">
                <div class="flex gap-3 items-center">
                  <!-- Disable edit icon for all items except the last one -->
                  <svg-icon
                    @click="
                      i === weighing_rice_history.length - 1 &&
                        handleEditData(data)
                    "
                    :class="{
                      'text-gray-400': i !== weighing_rice_history.length - 1,
                      'cursor-not-allowed':
                        i !== weighing_rice_history.length - 1,
                    }"
                    type="mdi"
                    :path="mdiPencil"
                    class="edit"
                  ></svg-icon>

                  <!-- Disable cut out icon for all items except the last one -->
                  <svg
                    @click.stop="
                      i === weighing_rice_history.length - 1 &&
                        handleCutOut(data)
                    "
                    :class="{
                      'text-gray-400': i !== weighing_rice_history.length - 1,
                      'cursor-not-allowed':
                        i !== weighing_rice_history.length - 1,
                    }"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-red-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[6rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :datatoedit="datatoedit"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isCutOut" as="template">
      <Dialog as="div" @close="handleCloseCutOut" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-white">
                <div>
                  <component
                    @close="handleCloseCutOut"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deleteDoc"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
  <Toast ref="toast" />
</template>

<script>
import WeighingForm from "@/form/WeighingForm.vue";
import OrderDetailsPopUpVue from "@/form/OrderDetailsPopUp.vue";
import SenderForm from "@/form/SenderForm.vue";
import CutOutForm from "@/form/CutOutForm.vue";
import EditWeighingForm from "@/form/EditWeighingForm.vue";
import { mdiTrashCanOutline, mdiPencil } from "@mdi/js";
import { useI18n } from "vue-i18n";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import apirURL from "@/services/apiURL";
import axios from "axios";
import NoDataComponet from "@/components/NoDataComponet.vue";
import socket from "@/services/socket";
import Toast from "@/components/Toast.vue";
import toggleSort from "@/composables/sortData";
import AliesPagination from "@/components/AliesPagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import moment from "moment-timezone";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  components: {
    WeighingForm,
    OrderDetailsPopUpVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SenderForm,
    NoDataComponet,
    Toast,
    AliesPagination,
    DeleteConfrimation,
    CutOutForm,
    EditWeighingForm,
  },
  setup() {
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };
    const isCutOut = ref(false);
    const isCutOutData = ref(null);
    const handleCutOut = (doc) => {
      isCutOut.value = true;
      isCutOutData.value = doc;
      currentComponent.value = "CutOutForm";
    };
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "WeighingForm";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      weighing_rice_history.value = [];
      selectedBoatId.value = null;
    };
    const datatoedit = ref(null);
    const handlePopUp = (doc) => {
      datatoedit.value = doc;
      isPopup.value = true;
      currentComponent.value = "SenderForm";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });

    const table = ref("weighing");
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");

    const searchValue = ref("");
    const joinWeighingData = ref([]);
    const fetchDataPaginationWithJoin = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let apiUrl = `${apirURL}/weighing/api/getPagination`;

        const params = {
          tableName: table.value,
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnSearch: "supplier_name,trucktor",
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          columnAliases: JSON.stringify({
            weighing_id: { table: table.value, column: "id" },
            created_at: { table: table.value, column: "created_at" },
            trucktor: { table: table.value, column: "trucktor" },
            created_by: { table: table.value, column: "created_by" },
            status_weighing: { table: table.value, column: "status" },
            weight_in: { table: table.value, column: "weight_in" },
            is_hold: { table: table.value, column: "is_hold" },
            supplier_name: { table: "suppliers", column: "name" },
            phone_number: { table: "suppliers", column: "phone_number" },
            boat_enter_parking_id: {
              table: table.value,
              column: "boat_enter_parking_id",
            },
            amount_cut_off: { table: table.value, column: "amount_cut_off" },
            created_at: { table: table.value, column: "created_at" },
            warehouse_name: {
              table: "warehouse",
              column: "name",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
            {
              joinTableName: "warehouse",
              joinColumnChild: "warehouse_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: table.value,
            },

            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: table.value,
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },

            {
              field: "created_by",
              operator: "=",
              value: decodeJWTInfo.userId,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        joinWeighingData.value = response.data.data;

        console.log("joinWeighingData", joinWeighingData.value);

        totalPages.value = response.data.pagination.totalPages;
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };
    const changePageSize = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await fetchDataPaginationWithJoin();
    };
    const changePage = async (page) => {
      currentPage.value = page;
      await fetchDataPaginationWithJoin();
    };

    const getRowOptions = [25, 50, 75, 100];
    const sortClient = (feild, sortDirection) => {
      if (feild === "supplier_name") {
        sortSupplierName(sortDirection);
      } else if (feild === "weight_id") {
        toggleSort(sortDirection, joinWeighingData.value, feild);
      } else if (feild === "weight_in") {
        toggleSort(sortDirection, joinWeighingData.value, feild);
      } else if (feild === "rice_name") {
        sortRice(sortDirection);
      } else if (feild === "plate_numbers") {
        sortByPlates(sortDirection);
      } else if (feild === "parking") {
        sortParking(sortDirection);
      }
    };
    const sortSupplierName = (sortDirection) => {
      joinWeighingData.value.sort((a, b) => {
        const nameA = getSupplierName(a.supplier_id)?.toUpperCase() || "";
        const nameB = getSupplierName(b.supplier_id)?.toUpperCase() || "";
        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortRice = (sortDirection) => {
      joinWeighingData.value.sort((a, b) => {
        const nameA = getRice(a.rice_id)?.toUpperCase() || "";
        const nameB = getRice(b.rice_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortByPlates = (sortDirection) => {
      joinWeighingData.value.sort((a, b) => {
        const nameA = getPlatesName(a.plate_number_id)?.toUpperCase() || "";
        const nameB = getPlatesName(b.plate_number_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };

    const sortParking = (sortDirection) => {
      joinWeighingData.value.sort((a, b) => {
        const nameA = getParking(a.boat_enter_parking_id)?.toUpperCase() || "";
        const nameB = getParking(b.boat_enter_parking_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchDataPaginationWithJoin(),
        fetchUserByID(),
        fetchSuppliers(),
        fetchRice(),
        fetchPlates(),
        fetBoatIntoParking(),
      ]);

      if (selectedBoatId.value) {
        await fetchWeighingHistory(selectedBoatId.value);
      } else {
        weighing_rice_history.value = [];
      }
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchDataPaginationWithJoin(),
        fetchSuppliers(),
        fetchRice(),
        fetchUserByID(),
        fetchPlates(),
        fetBoatIntoParking(),
      ]);

      if (selectedBoatId.value) {
        await fetchWeighingHistory(selectedBoatId.value);
      } else {
        weighing_rice_history.value = [];
      }

      socket.on("database_realTime", handleDatabaseUpdate);

      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });
    watch(searchValue, async () => {
      await fetchDataPaginationWithJoin();
    });
    const suppliers = ref([]);
    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const boatIntoParking = ref([]);
    const fetBoatIntoParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boatIntoParking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const rice = ref([]);
    const fetchRice = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const plates = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        plates.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getRice = (id) => {
      const name = rice.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = plates.value.find((item) => item?.id === id);
      return name?.plate_number;
    };
    const getParking = (id) => {
      const name = boatIntoParking.value.find((item) => item?.id === id);
      return name?.name;
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";

        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          case "send_to_parking":
            message = "Please send to parking first";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    // delete
    const itemIdToDelete = ref(null);
    const deleteDoc = ref(null);
    const isDeleteOpen = ref(false);
    const openDeleteConfirmation = (doc) => {
      deleteDoc.value = doc;
      itemIdToDelete.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deleteDoc.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.weighing_id}`,
          {
            data: { tableName: table.value },
          }
        );

        if (isLastPage && joinWeighingData.value.length === 0) {
          currentPage.value = 1;
        }
        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    const isFilterable = ref(false);
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const selectedBoatId = ref(null);
    const selectedBoatStatus = ref(null);
    const IsWeighing = ref(null);

    // Combined function
    const isBoatSelected = ref(false);

    const handleBoatSelection = async (data) => {
      // Call selectBoat logic
      selectedBoatId.value = data.weighing_id;
      selectedBoatStatus.value = data.is_complete;
      IsWeighing.value = data.weight_in;

      isBoatSelected.value = true;
      // Call  logic
      weighing_rice_history.value = [];

      await fetchWeighingHistory(data.weighing_id);
    };
    const weighing_rice_history = ref([]);
    const searcQuery = ref(null);

    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "weighing_rice_history_id",
          sortDirection: "ASC",
          searchQuery: searcQuery.value,
          searchColumns: JSON.stringify(["name"]),
          columnAliases: JSON.stringify({
            weighing_rice_history_id: {
              table: "weighing_rice_history",
              column: "id",
            },

            warehouse_id: {
              table: "weighing_rice_history",
              column: "warehouse_id",
            },
            boat_enter_parking_id: {
              table: "weighing_rice_history",
              column: "boat_enter_parking_id",
            },
            date_in: {
              table: "weighing_rice_history",
              column: "date_in",
            },

            weight_in: {
              table: "weighing_rice_history",
              column: "weight_in",
            },
            weight_out: {
              table: "weighing_rice_history",
              column: "weight_out",
            },
            weight_drop: {
              table: "weighing_rice_history",
              column: "weight_drop",
            },
            is_hold: {
              table: "weighing_rice_history",
              column: "is_hold",
            },
            rice: {
              table: "weighing_rice_history",
              column: "rice",
            },
            name: {
              table: "boat_enter_parking",
              column: "name",
            },
            weighing_id: {
              table: "weighing_rice_history",
              column: "weighing_id",
            },
          }),

          additionalJoins: JSON.stringify([
            {
              joinTableName: "boat_enter_parking",
              joinColumnChild: "boat_enter_parking_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          weighing_rice_history.value = response.data;
        } else {
          weighing_rice_history.value = [];
        }
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const sortWeighingHistory = (field, sortDirection) => {
      if (
        field === "weight_in" ||
        field === "weight_out" ||
        field === "weight_drop" ||
        field === "name" ||
        field === "rice" ||
        field === "weighing_rice_history_id"
      ) {
        weighing_rice_history.value = toggleSort(
          sortDirection,
          weighing_rice_history.value,
          field
        );

        console.log(`Sorting by ${field}`);
      }
    };

    watch(searcQuery, async (newVal) => {
      if (newVal) {
        await fetchWeighingHistory(selectedBoatId.value);
      } else {
        await fetchWeighingHistory(selectedBoatId.value);
      }
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const handleCloseCutOut = () => {
      isCutOut.value = false;
      isCutOutData.value = null;
      currentComponent.value = "";
    };
    const handleEditData = (doc) => {
      isOpen.value = true;
      currentComponent.value = "EditWeighingForm";
      datatoedit.value = doc;
      console.log("doc", doc);
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const sortPlateNumber = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA =
          field === "plate_number"
            ? a.plate_number?.plate_number || a.trucktor || ""
            : a[field] || "";
        const valueB =
          field === "plate_number"
            ? b.plate_number?.plate_number || b.trucktor || ""
            : b[field] || "";

        if (valueA < valueB) return direction === "asc" ? -1 : 1;
        if (valueA > valueB) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortRiceName = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA = field === "rice" ? a.rice_name : a[field];
        const valueB = field === "rice" ? b.rice_name : b[field];

        if (valueA < valueB) return direction === "asc" ? -1 : 1;
        if (valueA > valueB) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortSupplierWarehouse = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA =
          field === "supplier_name"
            ? a.supplier_name || a.warehouse_name || ""
            : a[field] || "";
        const valueB =
          field === "supplier_name"
            ? b.supplier_name || b.warehouse_name || ""
            : b[field] || "";

        if (valueA < valueB) return direction === "asc" ? -1 : 1;
        if (valueA > valueB) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };

    const sortData = (field) => {
      // Toggle the sorting direction
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";

      sortColumns.value = field;
      sortDirections.value = direction;

      // Apply sorting based on field
      if (field === "supplier_name" || field === "warehouse_name") {
        joinWeighingData.value = sortSupplierWarehouse(
          direction,
          joinWeighingData.value,
          field
        );
      } else if (field === "plate_number" || field === "trucktor") {
        joinWeighingData.value = sortPlateNumber(
          direction,
          joinWeighingData.value,
          field
        );
      } else if (
        field === "weight_in" ||
        field === "weight_out" ||
        field === "weight_drop"
      ) {
        weighing_rice_history.value = sortPlateNumber(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "rice") {
        weighing_rice_history.value = sortRiceName(
          direction,
          weighing_rice_history.value,
          field
        );
      }
    };
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };
    return {
      authUserRole,
      permissions,
      currentComponent,
      handleEditData,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      tableClasses,
      selectClasses,
      navClasses,
      joinWeighingData,
      formatDate2,
      searchValue,
      getSupplierName,
      getRice,
      getPlatesName,
      datatoedit,
      showSuccessToast,
      toast,
      sortClient,
      // pagination
      getRowOptions,
      changePageSize,
      changePage,
      pageSize,
      currentPage,
      totalPages,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deleteDoc,
      table,
      getParking,

      suppliers,
      boatIntoParking,

      rice,
      plates,

      isFilterable,
      mainClassesTable,
      handleBoatSelection,
      weighing_rice_history,
      subClassesTable,
      isCutOut,
      handleCutOut,
      isCutOutData,
      handleCloseCutOut,
      searcQuery,
      sortWeighingHistory,
      mdiTrashCanOutline,
      mdiPencil,
      sortDirections,
      sortColumns,
      sortData,
      // translate functions
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>

<style scoped>
.custom-disabled {
  opacity: 1;
  cursor: pointer;
}

.custom-disabled.el-button--primary {
  background-color: #409eff !important;
  border-color: #409eff !important;
  color: white !important;
}

.custom-disabled.el-button--danger {
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
  color: white !important;
}
</style>
