import apirURL from "@/services/apiURL";
import axios from "axios";



const fetchAllCustomerInvoiceDueOrPartial = async (customerId) => {
  try {
    const req = {
      tableName: "customer_invoices",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "customer_id",
          operator: "=",
          value: customerId,
          typeTable: "table",
        },
        {
          field: "status",
          operator: "!=",
          value: 'paid',
          typeTable: "table",
        }
      ]),
    };

    const res = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });

    return res.data;

  } catch (error) {
    console.log(error);
  }
};


const fetchCustomerByID = async (customerId) => {
  try {
    const req = {
      tableName: "customers",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: customerId,
          typeTable: "table",
        },

      ]),
    };

    const response = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });

    return response.data[0]?.wallet;

  } catch (error) {
    console.log(error);
  }
};



const fetchCustomerInvoiceById = async (customerInvoiceId) => {
  try {
    const req = {
      tableName: "customer_invoice",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: customerInvoiceId,
          typeTable: "table",
        },

      ]),
    };

    const response = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });

    return response.data;

  } catch (error) {
    console.log(error);
  }
};


const fetchAllCustomerInvoiceByCustomerRepaymentId = async (customerRepaymentId) => {
  try {
    const req = {
      tableName: "customer_invoice",
      sortColumn: "id",
      sortDirection: "ASC",
      dynamicConditions: JSON.stringify([
        {
          field: "customer_repayment_id",
          operator: "&&",
          type: 'integer',
          value: [customerRepaymentId],
          typeTable: "table",
        },

      ]),
    };

    const res = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
      params: req,
    });


    return res.data;

  } catch (error) {
    console.log(error);
  }
};



const updateCustomerWallet = async (customerId, newWalletAmount) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/updateData/${customerId}`;
    const requestBody = {
      tableName: "customers",
      fields: {
        wallet: newWalletAmount,
      },
    };
    const response = await axios.patch(apiUrl, requestBody);
    return response ? response.data.message : '';
  } catch (error) {
    console.error("Error updating supplier wallet:", error);
  }
};




export const addCustomerInvoiceOwe= async (formDoc,invoiceId) => {
  try{
 
    const customerWallet = await fetchCustomerByID(formDoc.customer_id);


    console.log("customerWallet", customerWallet);

    if(customerWallet){
      customerWallet.customer_invoice_owe['original_customer_owe'].push({
        invoice_id: invoiceId ? invoiceId : null,
        amount: formDoc.amount_paid 
      });

      await updateCustomerWallet(formDoc.customer_id, customerWallet);
    }


  }catch(err){
    console.log("failed to add customer repayment wallet", err);
  }
}


export const deleteCustomerInvoiceOwe = async (customerId, invoiceId) => {

  try{
    const customerWallet = await fetchCustomerByID(customerId);

    if(customerWallet){
      const customerInvoiceOweIndex = customerWallet.customer_invoice_owe['original_customer_owe'].findIndex((owe) => owe.invoice_id === invoiceId);
      if(customerInvoiceOweIndex !== -1){
        customerWallet.customer_invoice_owe['original_customer_owe'].splice(customerInvoiceOweIndex, 1);
      }
    }

    await updateCustomerWallet(customerId, customerWallet);

  }catch(err){
    console.log("failed to delete customer invoice owe", err);
  }

}





export const useCustomerRepayment = async (formDoc, customer_repayment_id,customerInvoiceId,type) => {
  try {
    const [customerInvoiceData, customerWallet] = await Promise.allSettled([
      fetchAllCustomerInvoiceDueOrPartial(formDoc.customer_id),
      fetchCustomerByID(formDoc.customer_id)
    ])

  



    if(type === 'single'){
     const customerInvoice = await fetchCustomerInvoiceById(customerInvoiceId);
   

      if(customerInvoice.length > 0){

        let repayments = customerInvoice[0].repayments || [];
        let customerRepaymentId = customerInvoice[0].customer_repayment_id || [];

        repayments.push({
          customer_repayment_id: customer_repayment_id,
          total_amount_owed: formDoc.total_amount_dept,
          amount_paid: formDoc.amount_paid,
          amount_remaining: formDoc.total_amount_dept - formDoc.amount_paid,
          status: formDoc.status,
          created_at: formDoc.created_at
        })

        customerRepaymentId.push(customer_repayment_id);

        const requestBody = {
          tableName: "customer_invoice",
          fields: {
            customer_repayment_id: customerRepaymentId,
            amount_paid: formDoc.amount_paid,
            status: formDoc.status,
            repayments: JSON.stringify(repayments)
          }
        }

        console.log("requestBody", requestBody);

        await axios.patch(`${apirURL}/weighing/api/updateData/${customerInvoice[0].id}`, requestBody);

        //update customer wallet
        if(customerWallet.value){
          customerWallet.value.customer_repayment['original_customer_owe'].push({
            customer_repayment_id: customer_repayment_id ? customer_repayment_id : null,
            amount: formDoc.amount_paid * -1
          });
    
          await updateCustomerWallet(formDoc.customer_id, customerWallet.value);
        }

        
      }
      
    }else if(type === 'multiple'){
      if (customerInvoiceData.value.length > 0) {
        // Initialize remainingAmount with the amount paid by the user
        let remainingAmount = formDoc.amount_paid;
  
  
        // Variable to keep track of the total amount paid in cutcustomerInvoice
        let totalAmountPaidInCustomerInvoice = 0;
  
        for (const customerInvoice of customerInvoiceData.value) {
          if (remainingAmount <= 0) break; // Stop if there's no remaining amount to apply
  
          // Initialize a new cutcustomerInvoice array for each customerInvoice record
          let repayments = customerInvoice.repayments || [];
          let customerRepaymentId = customerInvoice.customer_repayment_id || [];
  
          if (customerInvoice.amount_paid <= remainingAmount) {
            customerRepaymentId.push(customer_repayment_id);
            // Fully pay off this customerInvoice
            repayments.push({
              customer_repayment_id: customer_repayment_id,
              amount_paid: customerInvoice.amount_paid,
              amount_remaining: 0,
              total_amount_owed: 0,
              status: 'paid',
              created_at: formDoc.created_at
            });
            remainingAmount -= customerInvoice.amount_paid; // Deduct paid amount
            totalAmountPaidInCustomerInvoice += customerInvoice.amount_paid; // Track the total amount paid
  
            // Prepare the request body for a fully paid record
            let requestBody = {
              tableName: "customer_invoice",
              fields: {
                customer_repayment_id: customerRepaymentId,
                repayments: JSON.stringify(repayments),
                amount_paid: customerInvoice.total_amount,
                status: 'paid'
              }
            };
  
            console.log(`Fully paid record ${customerInvoice.id} updated`, requestBody);
            // Update the customerInvoice record in the database
            await axios.patch(`${apirURL}/weighing/api/updateData/${customerInvoice.id}`, requestBody);
  
          } else {
            customerRepaymentId.push(customer_repayment_id);
            // Partially pay this customerInvoice, with some remaining balance
            repayments.push({
              customer_repayment_id: customer_repayment_id,
              amount_paid: remainingAmount,
              amount_remaining: customerInvoice.amount_paid - remainingAmount,
              total_amount_owed: customerInvoice.amount_paid - remainingAmount,
              status: 'partial',
              created_at: formDoc.created_at
            });
  
            totalAmountPaidInCustomerInvoice += remainingAmount; // Track the partial payment
            remainingAmount = 0; // No remaining amount after partial payment
  
            // Prepare the request body for a partially paid record
            let requestBody = {
              tableName: "customer_invoice",
              fields: {
                customer_repayment_id: customerRepaymentId,
                repayments: JSON.stringify(repayments),
                amount_paid: customerInvoice.amount_paid - remainingAmount,
                status: 'partial'
              }
            };
  
            console.log(`Partially paid record ${customerInvoice.id} updated`, requestBody);
            // Update the customerInvoice record in the database
            await axios.patch(`${apirURL}/weighing/api/updateData/${customerInvoice.id}`, requestBody);
          }
        }
  
  
        let walletCustomer = customerWallet.value;
        // After the loop, update the supplier wallet with the total amount paid in cutcustomerInvoice
        walletCustomer.customer_repayment['original_customer_owe'].push({
          customer_repayment_id: customer_repayment_id ? customer_repayment_id : null,
          amount: totalAmountPaidInCustomerInvoice * -1
        });
  
  
  
        await updateCustomerWallet(formDoc.customer_id, walletCustomer);
  
      }
  
    }


   

  } catch (err) {
    console.log("failed to use customer repayment", err);
  }
}





export const deleteCustomerRepayment = async (customer_repayment_id, customerId) => {
  try {
    const [customerInvoiceData, walletCustomer] = await Promise.allSettled([
      fetchAllCustomerInvoiceByCustomerRepaymentId(customer_repayment_id),
      fetchCustomerByID(customerId),
    ]);

    console.log("customerInvoiceData", customerInvoiceData.value)
    console.log("walletCustomer", walletCustomer.value)


    if (customerInvoiceData.value.length > 0) {
      for (const customerInvoice of customerInvoiceData.value) {


        let customerRepaymentId = customerInvoice.customer_repayment_id || [];
        let customerRepaymentIdIndex = customerRepaymentId.findIndex((id) => id === customer_repayment_id);
        let repayments = customerInvoice.repayments || [];
        let repaymentsIndex = repayments.findIndex((repayment) => repayment.customer_repayment_id === customer_repayment_id);


        // delete object from repayments JSONB first
        if (customerRepaymentIdIndex !== -1) {
          customerRepaymentId.splice(customerRepaymentIdIndex, 1);
        }


        // remove customer_repayment_id from customerRepaymentId array
        if (repaymentsIndex !== -1) {
          repayments.splice(repaymentsIndex, 1);
        }


        let requestBody = {
          tableName: "customer_invoice",
          fields: {
            customer_repayment_id: customerRepaymentId,
            amount_paid: customerInvoice.repayments[customerInvoice.repayments.length - 1].amount_remaining,
            status: customerInvoice.repayments[customerInvoice.repayments.length - 1].status,
            repayments: JSON.stringify(repayments),
          }
        };

      
        // Update the supplierInvoice record in the database
        await axios.patch(`${apirURL}/weighing/api/updateData/${customerInvoice.id}`, requestBody);

      }

    }


    let customerWallet = walletCustomer.value;

    if (customerWallet) {
      const customerRepaymentIndex = customerWallet.customer_repayment['original_customer_owe'].findIndex((repayment) => repayment.customer_repayment_id === customer_repayment_id);
      if (customerRepaymentIndex !== -1) {
        customerWallet.customer_repayment['original_customer_owe'].splice(customerRepaymentIndex, 1); // remove customer_repayment object by customer_repayment_id from original_customer_owe array
      }
      const success = await updateCustomerWallet(customerId, customerWallet);
      console.log("success update customer wallet", success);
    }

  } catch (err) {
    console.log("failed to delete customer repayment", err);
  }
}
