<template>
  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div class="w-full flex items-center justify-between py-3">
      <div>
        <h2>Customers Invoice Generate</h2>
      </div>
    </div>

    <div
      class="flex items-start w-full gap-4 animate-fade-up animate-duration-300 animate-delay-100"
    >
      <div>
        <div :class="mainClassesTable" class="h-[500px]">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="min-w-[70px] border-b text-start">
                  <div class="flex items-center justify-start gap-3">
                    <h2>No</h2>
                    <div class="flex flex-col -space-y-1 items-center">
                      <font-awesome-icon
                        @click.prevent="sortClient('id', 'asc')"
                        :icon="['fas', 'caret-up']"
                        class="cursor-pointer"
                      />
                      <font-awesome-icon
                        @click.prevent="sortClient('id', 'desc')"
                        :icon="['fas', 'caret-down']"
                        class="cursor-pointer"
                      />
                    </div>
                  </div>
                </td>
                <td class="min-w-[100px] border-b text-start">Created At</td>
                <td class="min-w-[100px] border-b text-start">
                  <div class="flex items-center justify-start gap-3">
                    <h2>Customer</h2>
                    <div class="flex flex-col -space-y-1 items-center">
                      <font-awesome-icon
                        @click.prevent="sortClient('name', 'asc')"
                        :icon="['fas', 'caret-up']"
                        class="cursor-pointer"
                      />
                      <font-awesome-icon
                        @click.prevent="sortClient('name', 'desc')"
                        :icon="['fas', 'caret-down']"
                        class="cursor-pointer"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tr class="font-semibold">
              <td colspan="5" class="border">
                <div class="flex items-center justify-end gap-2">
                  <IconField class="w-[50%] placeholder:text-body2">
                    <InputText v-model="searchValue" placeholder="Search " />
                    <InputIcon class="pi pi-search" />
                  </IconField>
                </div>
              </td>
            </tr>
            <tbody v-if="matchedDataCustomer.length > 0">
              <tr
                @click="handleBoatSelection(data)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="(data, index) in matchedDataCustomer"
                :key="data.id"
              >
                <td class="border-b">{{ index + 1 }}</td>
                <td class="border-b">{{ formatDate2(data.created_at) }}</td>
                <td class="border-b">
                  {{ data.name }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="matchedDataCustomer.length > 0"
          class="w-full bg-white flex items-center justify-center py-2"
        >
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            :pageSize="pageSize"
            :totalRecord="matchedDataCustomer.length"
            @page-change="handlePageChange"
            @page-size-change="handlePageSizeChange"
          />
        </div>
      </div>
      <div>
        <div :class="subClassesTable" class="h-[500px] border">
          <table>
            <tbody v-if="weighing_rice.length > 0">
              <tr class="text-body text-primary5 b">
                <td colspan="9" class="border sticky -top-0.5 bg-white">
                  <div class="text-start flex items-center justify-between">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3">
                        <h2>Status</h2>
                        <el-button
                          :type="
                            weighing_rice[0]?.status === false
                              ? 'warning'
                              : 'success'
                          "
                          round
                        >
                          {{
                            weighing_rice[0]?.status === false
                              ? "Procecssing"
                              : "Complete"
                          }}
                        </el-button>
                      </div>
                    </div>
                    <div
                      v-if="weighing_rice[0]?.status === true"
                      class="flex items-center gap-3"
                    >
                      <button @click="handleAdd">Generate Invoice</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr v-if="weighing_rice.length > 0" class="sub_header">
                <td class="border-b text-start w-[150px]">Created At</td>
                <td
                  @click="sortHistoryData('id')"
                  class="border-b text-start toggle_sort w-[100px]"
                >
                  No
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'id' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'id' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistoryData('name')"
                  class="border-b text-start toggle_sort w-[100px]"
                >
                  Name
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'name' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'name' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>

                <td class="border-b text-start w-[150px]">Status</td>
              </tr>
              <tr v-else>
                <td>
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
            <tbody v-if="isBoatSelected && customers.length > 0">
              <tr
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="history in weighing_rice"
                :key="history.id"
              >
                <td class="border-b">{{ formatDate2(history.created_at) }}</td>
                <td class="border-b">{{ history?.id }}</td>
                <td class="border-b">
                  {{ history.name }}
                </td>

                <td class="border-b">
                  <el-button
                    :type="history.status === false ? 'warning' : 'success'"
                    round
                  >
                    {{ history.status === false ? "Processing" : "Completed" }}
                  </el-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default w-[1200px] max-w-[1200px]">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :truckProp="truckProp"
                    :customerId="Customeridprop"
                    :boat_array_id="boat_array_id"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetailsPopup" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isTruck" as="template">
      <Dialog as="div" @close="handleCloseTruck" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseTruck"
                    :is="currentComponent"
                    :truckData="truckData"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { decodeJwt } from "@/composables/decodeJWT";
import apirURL from "@/services/apiURL";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import BillPreviewVue from "@/form/BillPreview.vue";
import InvoiceGenerate from "@/form/InvoiceGenerate.vue";
import SupplierListPopUpVue from "@/form/SupplierListPopUp.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
import TruckDetails from "@/form/TruckDetails.vue";
import Pagination from "@/components/Pagination.vue";
import SubPagination from "@/components/SubPagination.vue";
import socket from "@/services/socket";
export default {
  components: {
    BillPreviewVue,
    InvoiceGenerate,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SupplierListPopUpVue,
    UpdateStatus,
    Toast,
    NoDataComponet,
    TruckDetails,
    Pagination,
    SubPagination,
  },
  setup() {
    const currentComponent = ref("");
    const isTruck = ref(false);
    const isOpen = ref(false);
    const isPopup = ref(false);
    const truckData = ref(null);
    const handleTruck = (doc) => {
      isTruck.value = true;
      truckData.value = doc;
      currentComponent.value = "TruckDetails";
    };
    const handleCloseTruck = () => {
      isTruck.value = false;
      currentComponent.value = "";
    };
    const datatoedit = ref(null);

    const truckProp = ref(null);

    const Customeridprop = ref(null);
    const boat_array_id = ref(null);
    const handleAdd = () => {
      isOpen.value = true;
      Customeridprop.value = customerProps.value;
      currentComponent.value = "InvoiceGenerate";
      boat_array_id.value = weighing_rice.value;
      datatoedit.value = selectedBoatId.value;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "BillPreviewVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const open = () => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed ",
        message: h("p", null, [
          h(
            "span",
            null,
            "Are you sure wanting to mark the Boat [Boat Name] as Completed"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      })
        .then((action) => {
          ElMessage({
            type: "info",
            message: `action: ${action}`,
          });
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };
    const isDetailsPopup = ref(false);
    const handlePopUpDeltail = () => {
      isDetailsPopup.value = true;
      currentComponent.value = "SupplierListPopUpVue";
    };
    const handleCloseDetails = () => {
      isDetailsPopup.value = false;
      currentComponent.value = "";
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";

        SubtotalPages.value = 0;
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the field";
            type = "error";
            break;
          case "loanamount":
            message = "Please enter the loan amount";
            type = "error";
          case "amount":
            message = "Please enter the  amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("customers");
    const sortDirection = ref("ASC");
    const searchValue = ref("");
    const customers = ref([]);
    const fetchCustomer = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;

      const params = {
        tableName: table.value,
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        columnSearch: "name",
        sortColumn: "id",
        sortDirection: sortDirection.value,

        dynamicConditions: JSON.stringify([]),
      };
      const response = await axios.get(apiUrl, { params });
      console.log("customer", customers.value);

      totalPages.value = response.data.pagination.totalPages;
      customers.value = response.data.data;
    };

    watch(searchValue, async () => {
      await fetchCustomer();
    });
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const boat = ref([]);
    const fetchBoat = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "is_customer_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
            // {
            //   field: "created_at",
            //   operator: ">=",
            //   value: fromDateFormatted.value,
            //   typeTable: "table",
            // },
            // {
            //   field: "created_at",
            //   operator: "<=",
            //   value: toDateFormatted.value,
            //   typeTable: "table",
            // },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boat.value = response.data;
        console.log("boat", boat.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const handleFilter = async () => {
      await fetchBoat();
    };
    const matchedDataCustomer = computed(() => {
      // Create a map of customer IDs to their corresponding `created_at` values from `boat_enter_parking`.
      const customerCreatedAtMap = new Map(
        boat.value.map((item) => [item.customer_id, item.created_at])
      );

      // Filter and map the customers to include `created_at` from `boat_enter_parking`.
      return customers.value
        .filter((customer) => customerCreatedAtMap.has(customer.id))
        .map((customer) => ({
          ...customer,
          created_at: customerCreatedAtMap.get(customer.id), // Add `created_at` from `boat_enter_parking`.
        }));
    });

    console.log("matchedDataCustomer", matchedDataCustomer.value);

    const SubCurrentPage = ref(1);
    const SubpageSize = ref(25);
    const SubtotalPages = ref(0);

    const Subtable = ref("boat_enter_parking");

    const weighing_rice = ref([]);
    const fetchWeighingHistory = async (id) => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: Subtable.value,
          sortColumn: "id",
          page: SubCurrentPage.value,
          pageSize: SubpageSize.value,
          dynamicConditions: JSON.stringify([
            {
              field: "customer_id",
              operator: "=",
              value: id,
            },
            {
              field: "is_complete",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_customer_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });

        if (response.data.data.length > 0) {
          weighing_rice.value = response.data.data;
          console.log("weighing_rice", weighing_rice.value);
          SubtotalPages.value = response.data.pagination.totalPages;
        } else {
          weighing_rice.value = [];
          SubtotalPages.value = 0;
        }
      } catch (error) {
        console.error("Error fetching rice history:", error);
        weighing_rice.value = [];
      }
    };

    const totalWeightDrop = computed(() => {
      return weighing_rice.value.reduce((total, record) => {
        return total + parseFloat(record.weight_drop || 0);
      }, 0);
    });

    const selectedBoatId = ref(null);

    const selectedBoatStatus = ref(false);

    const isBoatSelected = ref(false);
    const customerProps = ref(null);
    const handleBoatSelection = async (data) => {
      selectedBoatId.value = data;
      customerProps.value = data.id;

      selectedBoatStatus.value = data.status;

      await fetchWeighingHistory(data.id);

      isBoatSelected.value = true;
    };

    const weighingData = ref([]);
    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighingData.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchCustomer(),
        fetchWeighingData(),
        fetBoatIntoParking(),
        fetchBoat(),
        fetchUserByID(),
      ]);
    };
    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      await Promise.allSettled([
        fetchCustomer(),
        fetchWeighingData(),
        fetBoatIntoParking(),
        fetchBoat(),
        fetchUserByID(),
      ]);

      socket.on("database_realTime", handleDatabaseUpdate);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const sortClient = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing.value, field);
      } else if (field === "name") {
        toggleSort(sortDirection, weighing.value, field);
      }
    };

    const sortHistory = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "weight_in") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "name") {
        toggleSort(sortDirection, weighing_rice.value, field);
      }
    };
    const boatIntoParking = ref([]);
    const fetBoatIntoParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "is_customer_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          {
            params: req,
          }
        );

        boatIntoParking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchCustomer();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchCustomer();
    };
    const handleSubPageChange = async (page) => {
      SubCurrentPage.value = page;
      await fetchWeighingHistory(selectedBoatId.value);
    };

    const handleSubPageSizeChange = async (size) => {
      SubpageSize.value = size;
      SubCurrentPage.value = 1;
      await fetchWeighingHistory(selectedBoatId.value);
    };

    const sortHistoryDirection = ref("asc");
    const sortHistoryColumns = ref(null);
    const sortHistoryData = (field) => {
      const direction =
        sortHistoryColumns.value === field &&
        sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";
      sortHistoryDirection.value = direction;
      sortHistoryColumns.value = field;
      if (field === "id") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "weight_in") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "name") {
        toggleSort(direction, weighing_rice.value, field);
      }
    };
    return {
      customers,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      handlePopUpDeltail,
      isDetailsPopup,
      handleCloseDetails,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,

      formatDate2,
      handleBoatSelection,
      weighing_rice,
      isBoatSelected,

      datatoedit,
      truckProp,
      toast,
      showSuccessToast,
      totalWeightDrop,
      handleTruck,
      handleCloseTruck,
      truckData,
      isTruck,
      handleTruck,
      Customeridprop,

      sortClient,
      sortHistory,

      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,

      Subtable,
      SubCurrentPage,
      SubpageSize,
      SubtotalPages,
      handleSubPageSizeChange,
      handleSubPageChange,
      searchValue,
      matchedDataCustomer,
      boat_array_id,
      sortHistoryDirection,
      sortHistoryData,
      sortHistoryColumns,
      selectDateRage,
      handleFilter,
      authUserRole,
    };
  },
};
</script>

<style></style>
