<template>
  <!--  from trucl transition -->
  <div class="w-full">
    <div>
      <div class="relative overflow-x-auto border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <!-- <td class="min-w-[95px]  border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>No</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td> -->
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Invoice No</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Created At</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Weight</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Price</h2>
                </div>
              </td>
              <!-- <td class="min-w-[120px]  border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Status</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortClient('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td> -->
            </tr>
          </tbody>
          <!-- {{
            customers
          }} -->
          <tbody v-if="customers.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in customers"
              :key="data"
            >
              <td class="border-b">{{ data.invoice_id }}</td>
              <td class="border-b">{{ formatDate2(data.invoice_created) }}</td>
              <td class="border-b">
                {{ numeralFormat(data.total_amount, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_paid, "0,0[.]00") }} VND
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import moment from "moment-timezone";
import Pagination from "@/components/Pagination.vue";
import { onMounted, ref } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: ["datatoedit"],
  components: {
    Pagination,
    NoDataComponet,
  },
  setup(props, { emit }) {
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("customer_invoice");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const customers = ref([]);

    const fetch_customers = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        // columnSearch: "name, phone_number",
        // search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        additionalJoins: JSON.stringify([
          {
            joinTableName: "customers",
            joinColumnChild: "customer_id",
            joinColumnParent: "id",
            joinType: "LEFT JOIN",
          },
        ]),
        columnAliases: JSON.stringify({
          customer_id: { table: "customers", column: "id" },
          invoice_id: { table: table.value, column: "id" },
          invoice_created: { table: table.value, column: "created_at" },
        }),

        dynamicConditions: JSON.stringify([
          {
            field: "customer_id",
            operator: "=",
            value: props.datatoedit.id,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      customers.value = response.data.data;
      totalPages.value = response.data.pagination.totalPages;
      console.log("customers", response.data.data);
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetch_customers();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetch_customers();
    };

    const sortClient = (field, sortDirection) => {
      if (field === "amount_paid") {
        toggleSort(sortDirection, customers.value, field);
      } else if (field === "invoice_id") {
        toggleSort(sortDirection, customers.value, field);
      } else if (field === "total_amount") {
        toggleSort(sortDirection, customers.value, field);
      } else if (field === "weight_out") {
        toggleSort(sortDirection, customers.value, field);
      }
    };

    onMounted(async () => {
      props.datatoedit;
      await Promise.allSettled([fetch_customers()]);
    });
    return {
      customers,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      formatDate2,
      sortClient,
    };
  },
};
</script>

<style></style>
