<template>
  <div class="w-[792px] h-[650px]">
    <div class="details_title">
      <h2 class="ml-4">Customer: Customer Name</h2>

      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Payment Slip Preview</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>
    <div v-if="activeTab === 'details'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Phone Number</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>Cell data</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction History</h2>
        <div class="w-full h-[240px] overflow-auto border">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>
                <td class="border-b text-start text-nowrap">Weigh In</td>
                <td class="border-b text-start text-nowrap">Weigh out</td>
                <td class="border-b text-start text-nowrap">Weight</td>
                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in 3"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">total</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handleClose">Print</button>

          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>Cell data</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction</h2>
        <div class="w-full border h-[240px] overflow-auto">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>
                <td class="border-b text-start text-nowrap">Weight</td>
                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in 3"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">Silver</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">total</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handleClose">Print</button>
          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    return {
      handleClose,
      setActiveTab,
      activeTab,
    };
  },
};
</script>

<style></style>
