export const calculateBalance = (wallet, type) => {
  if (type === "KH") {
    const sumDeposit = wallet.KH.deposit.original_partner_owe.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    const sumPreloan = wallet.KH.preloan.original_partner_owe.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    const sumWithdrawal = wallet.KH.withdrawal.original_partner_owe.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    const sumCustomerPayment =
      wallet.KH.customer_payment.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumExchangeBalanceOriginalPartner =
      wallet.KH.exchange_balance.original_owe_partner.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumExchangeBalanceOriginalPartnerOwe =
      wallet.KH.exchange_balance.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumSupplierPayment =
      wallet.KH.supplier_payment.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumPreloanRepayment =
      wallet.KH.preloan_repayment.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    return (
      sumDeposit +
      sumPreloan +
      sumWithdrawal +
      sumCustomerPayment +
      sumExchangeBalanceOriginalPartner +
      sumExchangeBalanceOriginalPartnerOwe +
      sumSupplierPayment +
      sumPreloanRepayment
    );
  } else if (type === "VN") {
    const sumDeposit = wallet.VN.deposit.original_partner_owe.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    const sumWithdrawal = wallet.VN.withdrawal.original_partner_owe.reduce(
      (sum, transaction) => sum + transaction.amount,
      0
    );
    const sumCustomerPayment =
      wallet.VN.customer_payment.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumExchangeBalanceOriginalPartner =
      wallet.VN.exchange_balance.original_owe_partner.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    const sumExchangeBalanceOriginalPartnerOwe =
      wallet.VN.exchange_balance.original_partner_owe.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    return (
      sumDeposit +
      sumWithdrawal +
      sumCustomerPayment +
      sumExchangeBalanceOriginalPartner +
      sumExchangeBalanceOriginalPartnerOwe
    );
  }
};



export const calculateBalancePreloanSupplier = (wallet) => {
  return [
    ...wallet.preloan.original_supplier_owe, 
    ...wallet.supplier_invoice_cut_preloan.original_supplier_owe
  ].reduce((sum, transaction) => sum + transaction.amount, 0);
};
