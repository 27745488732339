
import axios from 'axios';
import apirURL from "@/services/apiURL";

export const uploadImage = async (file,foldernmae,type) => {
    const formData = new FormData();
    formData.append('folderName', foldernmae);
    formData.append('image_url', file);
    formData.append('type', type);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    try {
      const response = await axios.post(`${apirURL}/weighing/api/upload`, formData, {
        headers: headers
      });

      let filename='';
      if(type ==='AWS'){
        filename = response.data.imageUrl
      }else{
          filename = response.data.filename;
      }
      

      return filename; // Return the filename from the function
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error
      return null; // Return null in case of an error
    }
  };


export const deleteImage=async(folderName,filename,type)=>{

  try{
  const response=await axios.delete(`${apirURL}/weighing/api/deleteImage`, {
      data: { folderName:folderName, imageName: filename ,type:type}
    });
    return response;
  }catch(err){
    console.error('Error delete image:', err);
    // Handle error
    return null; 
  }

  }
  
