<template>
  <div class="w-fit h-auto">
    <div class="details_title">
      <h2 class="ml-4">Parking Mapping</h2>
      <div class="flex items-center gap-2">
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <form
        @submit.prevent="handleSubmit"
        class="p-5 bg-white rounded-[4px] flex justify-between"
      >
        <div class="space-y-2 flex flex-col" ref="container" @click.stop>
          <div class="w-full">
            <div class="flex items-start justify-between gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-semibold">
                <span class="text-red-500 text-start">*</span> Client</label
              >
              <div class="flex flex-col">
                <Select
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  style="width: 250px"
                  v-model="selectClient"
                  :options="customers"
                  placeholder="Select Client"
                  option-label="name"
                  option-value="id"
                  show-clear
                  checkmark
                  filter
                  ref="selectRef"
                  size="medium"
                  :dropdownVisible="dropdownVisible"
                  @focus="dropdownVisible = true"
                  @blur="dropdownVisible = false"
                />
                <div v-if="isValidateCustomer" class="w-[250px] mt-2 float-end">
                  <el-alert
                    title="Please Select Customer"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-start justify-between gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-semibold">
                <span class="text-red-500 text-start">*</span> Boat name</label
              >
              <div class="flex flex-col">
                <InputText
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  v-model="name"
                  placeholder="Boat Name"
                  style="width: 250px"
                />
                <div v-if="isValidateBoatName" class="w-[250px] mt-2 float-end">
                  <el-alert
                    title="Please Select Name"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="flex items-start justify-between gap-3">
              <label
                for="Capacity"
                class="flex gap-1 text-nowrap font-semibold"
              >
                <span class="text-red-500 text-start">*</span> Max</label
              >
              <div>
                <div class="flex flex-col space-y-4">
                  <InputNumber
                    v-model="max"
                    class="w-full md:w-56 h-2 text-start flex items-center mt-3"
                    placeholder="Max"
                    label="Max"
                    suffix=" Kg"
                    :maxFractionDigits="2"
                    style="width: 250px"
                    step="0.01"
                    :fluid="true"
                  />
                </div>
                <div v-if="isValidateMax" class="w-[250px] mt-6 float-end">
                  <el-alert
                    title="Enter The Capacity"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="flex w-full justify-start gap-3 mt-12">
            <button type="submit" :disabled="isPending">
              <span v-if="isPending" :class="{ is_pending: isPending }"></span
              >Save
            </button>
            <button type="button" class="btncancel" @click="handleClose">
              Cancel
            </button>
          </div>
        </div>
        <div class="space-y-3">
          <div>
            <div class="flex items-start justify-between gap-3">
              <label for="parking" class="flex gap-1 text-nowrap font-semibold">
                <span class="text-red-500 text-start">*</span>To Parking</label
              >
              <div class="flex flex-col">
                <Select
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  style="width: 250px"
                  v-model="selectParking"
                  :options="parkings"
                  filter
                  placeholder="Select Parking"
                  option-label="name"
                  option-value="id"
                  show-clear
                  checkmark
                  size="medium"
                  id="parking"
                />
                <div v-if="isValidateParking" class="w-[250px] mt-2 float-end">
                  <el-alert
                    title="Enter The Capacity"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-start gap-3 justify-between">
            <label
              for="Description"
              class="flex gap-1 text-nowrap font-semibold"
              >Note</label
            >
            <textarea
              type="text"
              id="Description"
              v-model="note"
              placeholder="Any Description"
              style="width: 250px"
              rows="4"
              cols="25"
              class="input-text-area"
            />
          </div>
        </div>
      </form>
      <div class="p-2 bg-white rounded-[4px]">
        <div class="mt-4">
          <div class="w-full border h-[240px] overflow-auto">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    No
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    Parking
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    Customer
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    Boat
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    Capacity
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    Note
                  </td>
                </tr>
              </tbody>
              <tbody v-if="boat_enter_parking.lenght > 0">
                <tr
                  class="text-body font-normal text-primary2"
                  v-for="data in boat_enter_parking"
                  :key="data"
                  @click="handleEditData(data)"
                >
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ data.id }}
                    </div>
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ getParking(data.parking.parking_id) }}
                    </div>
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ getCustomerName(data.customer_id) }}
                    </div>
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ data.name }}
                    </div>
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ numeralFormat(data.capacity, "0,0[.]00") }} Kg
                    </div>
                  </td>
                  <td class="border-b text-start text-nowrap min-w-[150px]">
                    <div class="flex items-start justify-between gap-3">
                      {{ data.note ? data.note : "No data" }}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">
                    <NoDataComponet />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import { handleValidate } from "@/composables/checkValidatefeild";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: [""],
  components: {
    NoDataComponet,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const selectRef = ref(null);

    const dropdownVisible = ref(false);
    const container = ref(null);

    // Handle outside clicks to close dropdown
    const handleOutsideClick = (event) => {
      if (container.value && !container.value.contains(event.target)) {
        dropdownVisible.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("mousedown", handleOutsideClick);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("mousedown", handleOutsideClick);
    });
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);
    const currentDate = new Date();
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectClient = ref(null);
    const name = ref("");
    const selectParking = ref(null);
    const note = ref("");
    const max = ref(null);
    const errorMessages = ref({
      client: "",
      name: "",
      parking: "",
      max: "",
    });
    const validateForm = () => {
      errorMessages.value.client = !selectClient.value
        ? "Client is required."
        : "";
      errorMessages.value.name = !name.value ? "Boat name is required." : "";
      errorMessages.value.parking = !selectParking.value
        ? "Parking is required."
        : "";
      errorMessages.value.max =
        max.value === null ? "Max capacity is required." : "";
    };
    watch(selectClient, (newValue) => {
      errorMessages.value.client = !newValue ? "Client is required." : "";
    });
    watch(name, (newValue) => {
      errorMessages.value.name = !newValue ? "Boat name is required." : "";
    });
    watch(selectParking, (newValue) => {
      errorMessages.value.parking = !newValue ? "Parking is required." : "";
    });
    watch(max, (newValue) => {
      errorMessages.value.max =
        newValue === null ? "Max capacity is required." : "";
    });
    const isValidateParking = handleValidate(
      selectParking.value,
      selectParking
    );
    const isValidateCustomer = handleValidate(selectClient.value, selectClient);
    const isValidateBoatName = handleValidate(name.value, name);
    const isValidateMax = handleValidate(max.value, max);
    const isPending = ref(false);
    const handleClear = () => {
      selectClient.value = "";
      name.value = "";
      note.value = "";
      selectParking.value = "";
      isPending.value = false; // Correctly reset isPending using .value
    };
    const handleSubmit = async () => {
      if (!selectClient.value) {
        isValidateCustomer.value = true;
        return;
      }
      if (!name.value) {
        isValidateBoatName.value = true;
        return;
      }
      if (!max.value) {
        isValidateMax.value = true;
        return;
      }
      if (!selectParking.value) {
        isValidateParking.value = true;
        return;
      }
      isPending.value = true; // Set isPending to true before processing
      try {
        const timestamp = await fetchTimestamp();
        const reqBody = {
          tableName: "boat_enter_parking",
          fields: {
            name: name.value,
            parking: JSON.stringify({
              parking_id: selectParking.value,
              name: name.value,
            }),
            customer_id: selectClient.value,
            note: note.value,
            capacity: max.value,
            name_lowercase: name.value,
            created_at: timestamp,
            status: true,
            is_complete: false,
            is_customer_invoice: false,
          },
        };
        if (datatoedit.value) {
          // update

          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${datatoedit.value.id}`,
            reqBody
          );
          if (response.data.message) {
            const res = await axios.patch(
              `${apirURL}/weighing/api/updateData/${datatoedit.value.parking.parking_id}`,
              {
                tableName: "parking_rice",
                fields: {
                  is_rental: false,
                  is_available: false,
                },
              }
            );
            console.log("res", res.data);
          }
          handleClear();

          emit("toast", "update");
          fetchBoatEnterParking();
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            reqBody
          );
          if (response.data.message) {
            const res = await axios.patch(
              `${apirURL}/weighing/api/updateData/${selectParking.value}`,
              {
                tableName: "parking_rice",
                fields: {
                  is_rental: false,
                  is_available: false,
                },
              }
            );
            console.log("res new mapping ", res.data);

            console.log("response", response);
          }
          handleClear();
          emit("close");
          emit("toast", "create");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const customers = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        customers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const boat_enter_parking = ref([]);
    const fetchBoatEnterParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_complete",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boat_enter_parking.value = response.data;
        console.log("boat_enter_parking", boat_enter_parking.value);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    watch(selectParking, (newValue) => {
      console.log("selectParking id", selectParking.value.id);
    });

    const parkings = ref([]);
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_available",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_rental",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parkings.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const getParking = (id) => {
      const name = parkings.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getCustomerName = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.name;
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchCustomer(),
        fetchParking(),
        fetchBoatEnterParking(),
      ]);
    });
    const isFilterable = ref(false);
    const datatoedit = ref(null);

    const handleEditData = (data) => {
      datatoedit.value = data;
      if (datatoedit.value) {
        selectClient.value = datatoedit.value.customer_id;
        name.value = datatoedit.value.name;
        max.value = datatoedit.value.capacity;
        note.value = datatoedit.value.note;
        selectParking.value = datatoedit.value.parking.parking_id;
      }
    };
    return {
      handleClose,
      selectParking,
      selectClient,
      parkings,
      customers,
      name,
      max,
      note,
      handleSubmit,
      isFilterable,
      boat_enter_parking,
      errorMessages,
      getParking,
      getCustomerName,
      isValidateCustomer,
      isValidateMax,
      isValidateBoatName,
      isValidateParking,
      selectRef,
      dropdownVisible,
      container,
      handleEditData,
      isPending,
    };
  },
};
</script>

<style></style>
