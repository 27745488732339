<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[400px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ isUpdate ? "Update Parking" : "New Parking" }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Name</label
        >
        <input
          required
          type="text"
          v-model="name"
          class="w-[250px]"
          placeholder="Enter Name"
          autofocus
        />
      </div>

      <!-- <div class="flex items-center justify-start gap-3">
        <label for="name" class="flex gap-1 text-nowrap">
          <span class="text-red-500">*</span> Is Available</label
        >
        <label class="flex items-center space-x-2">
          <input
            type="radio"
            name="status"
            value="true"
            v-model="is_available"
            @change="handleRadioChange"
            class="form-radio text-primary3"
          />
          <span>Available</span>
        </label>
      </div> -->

      <!-- <div class="flex items-center gap-3">
        <label for="status" class="flex gap-1 text-nowrap">Status</label>
        <el-switch v-model="status" />
      </div> -->
      <div class="flex w-full items-center gap-3">
        <button type="submit" v-if="isAdding === true" :disabled="isAdding">
          Saving
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path></svg
          ></span>
        </button>
        <button v-else>{{ isUpdate ? "Update" : "Save" }}</button>
        <button
          type="button"
          class="btncancel"
          @click="SaveAndNew"
          :disabled="isUpdatig"
          v-if="isUpdatig === true"
        >
          Saving
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="#168AF4"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </span>
        </button>
        <button v-else @click="SaveAndNew" type="button" class="btncancel">
          {{ isUpdate ? "Update & New" : "Save & New" }}
        </button>
        <button type="button" class="btncancel" @click="handleClose">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { ref, onMounted } from "vue";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const name = ref("");
    const status = ref(true);
    const is_available = ref(true);
    const handleRadioChange = () => {
      console.log("Radio changed:", is_available.value);
    };
    const capacity = ref(null);
    const isAdding = ref(false);
    const isUpdatig = ref(false);
    const isUpdate = props.datatoedit;
    const handleClear = () => {
      name.value = "";
    };

    const handleSubmit = async () => {
      isAdding.value = true;

      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "parking_rice",
        fields: {
          name: name.value,
          name_lowercase: name.value.toLowerCase(),
          is_available: is_available.value,
          status: status.value,
          created_at: timestamp,
          is_rental: true,
        },
      };
      try {
        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");

          handleClear();
          handleClose();
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");

          handleClear();
          handleClose();
        }
      } catch (error) {
        console.log("error:", error);
      }
    };
    const SaveAndNew = async () => {
      setTimeout(() => {
        isUpdatig.value = true;
        setTimeout(() => {
          isUpdatig.value = false;
        }, 1000);
      }, 0);
      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "parking_rice",
        fields: {
          name: name.value,
          name_lowercase: name.value.toLowerCase(),
          is_available: is_available.value,
          status: status.value,
          created_at: timestamp,
          is_rental: true,
        },
      };
      try {
        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
          handleClear();
        }
      } catch (error) {
        console.log("error:", error);
      }
    };
    onMounted(() => {
      if (props.datatoedit) {
        name.value = props.datatoedit.name;
        is_available.value = props.datatoedit?.is_available;
        console.log(
          "props.datatoedit?.is_available",
          props.datatoedit?.is_available
        );

        status.value = props.datatoedit.status;
        capacity.value = props.datatoedit.capacity;
      }
    });
    return {
      handleClose,
      name,
      status,
      handleSubmit,
      handleRadioChange,
      is_available,
      isAdding,
      isUpdate,
      SaveAndNew,
      isUpdatig,
    };
  },
};
</script>

<style></style>
