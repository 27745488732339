<template>
  <button class="btncancel">
    {{ t("message.cancel") }}
  </button>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };
    return { t, changeLanguage };
  },
};
</script>

<style lang="scss" scoped></style>
