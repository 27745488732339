<template>
  <div
    class="w-full relative text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div class="w-auto">
      <div class="flex items-start gap-3 -mt-6">
        <div :class="selectClasses">
          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Partner
            </label>

            <Select
              v-model="selectedPartner"
              :options="partner"
              optionLabel="name"
              optionValue="id"
              filter
              id="partner"
              showClear
              placeholder="Select partner"
              checkmark
              :highlightOnSelect="true"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="mt-2">
              <span class="text-primary4 mr-2"></span>Start Date
            </label>
            <DatePicker
              v-model="selectDateRage"
              class="w-full md:w-56 h-4 text-start flex items-center"
              dateFormat="dd/M/yy"
              placeholder="Select start"
              showIcon
              iconDisplay="input"
              showButtonBar
              selectionMode="range"
              :manualInput="false"
              inputId="dd"
            />
          </div>

          <div class="filter_type_wrap">
            <label for="Supplier" class="opacity-0 mt-2">
              <span class="text-primary4 mr-2">*</span>Receiver
            </label>
            <button class="btn_filter" @click="handleFilter">Filter</button>
          </div>
          <div class="filter_type_wrap">
            <label for="Supplier" class="opacity-0 mt-2">
              <span class="text-primary4 mr-2">*</span>Receiver
            </label>
            <IconField class="w-[80%] placeholder:text-body2">
              <InputText v-model="searchValue" placeholder="Search " />
              <InputIcon class="pi pi-search" />
            </IconField>
          </div>
        </div>
      </div>
    </div>
    <div :class="navClasses">
      <div>
        <h2>Pre-Loan List</h2>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_pre_loan?.pre_loan?.create === true)
        "
      >
        <button @click="handleAdd">New Laon</button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[500px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td class="min-w-[126px] border-b">Created At</td>
            <td
              @click="sortData('preloan_id')"
              class="min-w-[95px] border-b text-start toggle_sort whitespace-nowrap"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'preloan_id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'preloan_id' &&
                          sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('partner_name')"
              class="min-w-[95px] border-b text-start toggle_sort whitespace-nowrap"
            >
              Partner
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'partner_name' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'partner_name' &&
                          sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td
              @click="sortData('supplier_name')"
              class="min-w-[95px] border-b text-start toggle_sort whitespace-nowrap"
            >
              Supplier
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'supplier_name' &&
                      sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'supplier_name' &&
                          sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('amount')"
              class="min-w-[95px] border-b text-start toggle_sort whitespace-nowrap"
            >
              Amount
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'amount' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumns === 'amount' && sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td class="min-w-[100px] border-b text-start text-nowrap">
              <div class="flex items-center justify-start gap-3">
                <h2>Get By</h2>
              </div>
            </td>

            <td class="min-w-[100px] border-b text-start text-nowrap">
              <div class="flex items-center justify-start gap-3">
                <h2>Amount Paid</h2>
              </div>
            </td>

            <td class="min-w-[300px] border-b">
              <div class="flex items-center justify-between">
                <div class="flex items-center">Status</div>
              </div>
            </td>
            <td class="min-w-[126px] border">Acion</td>
          </tr>
        </tbody>

        <tbody v-if="filterPreloan.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in filterPreloan"
            :key="data"
          >
            <td class="border-b">
              {{ formatDate2(data.createdat) }}
            </td>
            <td class="border-b">{{ data.preloan_id }}</td>
            <td class="border-b">{{ data.partner_name }}</td>

            <td class="border-b">{{ data.supplier_name }}</td>

            <td class="border-b">
              {{
                data.cut_preloans
                  ? formatCurrency(
                      data.cut_preloans[0]?.total_amount_owed,
                      " ៛ ",
                      "after"
                    )
                  : 0
              }}
            </td>

            <td class="border-b text-nowrap">
              {{ data.get_preloan_by.toUpperCase() }}
            </td>
            <td class="border-b text-nowrap">
              {{
                data.cut_preloans
                  ? numeralFormat(
                      data.cut_preloans.length > 0
                        ? data.cut_preloans[data.cut_preloans.length - 1]
                            .amount_paid
                        : 0,
                      "0,0[.]0"
                    )
                  : 0
              }}
              {{ data.currency?.symbol_currency }}
            </td>

            <td class="border-b">
              <span class="px-4 py-1 rounded-full bg-yellow-600 text-white">{{
                data.status_paid.toUpperCase()
              }}</span>
            </td>
            <td class="border">
              <div class="flex items-center gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_pre_loan?.pre_loan?.update === true)
                  "
                  :path="mdiPencil"
                  type="mdi"
                  class="edit"
                  @click="handleEditData(data)"
                >
                </svg-icon>

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_pre_loan?.pre_loan?.delete === true)
                  "
                  :path="mdiTrashCanOutline"
                  type="mdi"
                  class="delete"
                  @click="openDeleteConfirmation(data)"
                >
                </svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <PaginationVue
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        :tableName="table"
        :totalRecord="totalItems"
      />
    </div>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deleteDoc"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
    />

    <ToastVue ref="toast" />
    <TransitionRoot appear :show="isUpdateStatus" as="template">
      <Dialog as="div" @close="handleCloseStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseStatus"
                    :is="currentComponent"
                    :isStatus="isStatus"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetails" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <div>
      <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="handleClose" class="relative z-10">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div class="fixed top-[7rem] inset-0 overflow-y-auto">
            <div class="flex min-h-full items-start justify-center text-center">
              <TransitionChild
                as="template"
                enter="duration-200 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel class="bg-default">
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import PreLoanForm from "@/form/PreLoanForm.vue";
import PreLoanDetailPopUp from "@/form/PreLoanDetailPopUp.vue";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import ToastVue from "@/components/Toast.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { formatDate, formatDate2 } from "@/composables/formatDateTime";
import PaginationVue from "@/components/Pagination.vue";
import moment from "moment-timezone";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { deletePreloan } from "@/composables/usePreloan";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { formatCurrency } from "@/composables/formatCurrency";
import ToastVue2 from "@/components/ToastVue.vue";
import { DMTelegram } from "@/composables/useDMTelegram";
import numeral from "numeral";
import { fetchTimestamp } from "@/services/timestamp";
import { formatMobileNumber } from "@/composables/formatMobileNumber";
import { mdiTrashCanOutline, mdiPencil, mdiEyeOutline } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  components: {
    PreLoanForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    PreLoanDetailPopUp,
    PaginationVue,
    DeleteConfrimation,
    ToastVue2,
    UpdateStatus,
    NoDataComponet,
    ToastVue,
  },
  setup() {
    const currentDate = new Date();
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);

    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "PreLoanForm";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const selectedPartner = ref(null);
    const isDetails = ref(false);
    const handleClientDetails = (item) => {
      currentComponent.value = "PreLoanDetailPopUp";
      isDetails.value = true;
      datatoedit.value = item;
    };
    const handleCloseDetails = () => {
      currentComponent.value = "";
      isDetails.value = false;
    };

    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    // fetch api
    const partner = ref([]);
    const fetchWalletPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const searchValue = ref("");
    const table = ref("preloans");
    // serarching join
    const joinPreloanData = ref([]);
    const totalItems = ref(0);
    const fetchDataPaginationWithJoin = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: table.value,
          columnSearch: "supplier_name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortDirection: "DESC",
          columnAliases: JSON.stringify({
            preloan_id: { table: "preloans", column: "id" },
            supplier_name: { table: "suppliers", column: "name" },
            amount: { table: "preloans", column: "amount" },
            status: { table: "preloans", column: "status" },
            status_paid: { table: "preloans", column: "status_paid" },
            cut_preloans: { table: "preloans", column: "cut_preloans" },
            currency: { table: "preloans", column: "currency" },
            createdat: { table: "preloans", column: "created_at" },
            supplier_invoice_id: {
              table: "preloans",
              column: "supplier_invoice_id",
            },
            partner_name: { table: "partners", column: "name" },
          }),
          sortColumn: "preloan_id",
          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
            ...(selectedPartner.value
              ? [
                  {
                    field: "partner_id",
                    operator: "=",
                    value: selectedPartner.value,
                    typeTable: "table",
                  },
                ]
              : []),
          ]),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
            {
              joinTableName: "partners",
              joinColumnChild: "partner_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );
        joinPreloanData.value = [];
        joinPreloanData.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
        totalItems.value = response.data.pagination.totalData;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleFilter = async () => {
      await fetchDataPaginationWithJoin();
    };
    const filterPreloan = computed(() => {
      return joinPreloanData.value.filter((data) => data.preloan_id !== null);
    });

    const changePageSize = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await fetchDataPaginationWithJoin();
    };
    const changePage = async (page) => {
      currentPage.value = page;
      await fetchDataPaginationWithJoin();
    };

    const getRowOptions = [50, 75, 100, 200, 500, 1000, 2000];

    const suppliers = ref([]);

    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    const getSupplierName = (id) => {
      const supplier = suppliers.value.find((item) => item?.id === id);
      return supplier?.name;
    };

    const partners = ref([]);

    const fetchPartners = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partners.value = response.data;
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    const totalRecord = ref(0);
    const fetchTotalRecord = async () => {
      try {
        const req = {
          tableName: table.value,
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        totalRecord.value = response.data.length;
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    const getPartnerName = (id) => {
      const partner = partners.value.find((item) => item?.id === id);
      return partner?.name;
    };

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      if (item.supplier_invoice_id === null) {
        isOpen.value = true;
        currentComponent.value = "PreLoanForm";
        datatoedit.value = item;
        console.log("item", item);
      } else {
        showSuccessToast("cannotedit", "error");
      }
    };

    watch(searchValue, async (newV) => {
      if (newV) {
        currentPage.value = 1;
        await fetchDataPaginationWithJoin();
      } else {
        await fetchDataPaginationWithJoin();
      }
    });

    // paginatetion
    const handlePageChange = async (page) => {
      if (page > 0 && page <= totalPages.value) {
        currentPage.value = page;
        await fetchDataPaginationWithJoin();
      }
    };

    const handlePageSizeChange = async (size) => {
      pageSize.value = size;
      currentPage.value = 1; // Reset to first page when page size changes
      await fetchDataPaginationWithJoin();
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the field";
            type = "error";
            break;
          case "loanamount":
            message = "Please enter the loan amount";
            type = "error";
            break;
          case "cannotedit":
            message = "Can not edit";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    // sort

    const sortDirections = ref("asc");
    const sortColumns = ref(null);
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      joinPreloanData.value = toggleSort(
        direction,
        joinPreloanData.value,
        field
      );
    };

    const toggleSort = (direction, data, field) => {
      return data.sort((a, b) => {
        let comparison = 0;

        if (field === "preloan_id") {
          comparison = a.id - b.id;
        } else if (field === "supplier_name") {
          comparison = a.supplier_name.localeCompare(b.supplier_name);
        } else if (field === "partner_name") {
          comparison = a.partner_name.localeCompare(b.partner_name);
        } else if (field === "amount") {
          comparison = a.amount - b.amount;
        }

        return direction === "asc" ? comparison : -comparison;
      });
    };
    const handleClearToast = () => {
      informMessage.value = "";
      showToast.value = false;
      isSuccess.value = false;
      currentComponent.value = "";
    };

    // delete
    const deleteDoc = ref(null);
    const openDeleteConfirmation = (doc) => {
      if (doc.supplier_invoice_id === null) {
        deleteDoc.value = doc;
        itemIdToDelete.value = doc;
        isDeleteOpen.value = true;
      } else {
        showSuccessToast("cannotdelete", "error");
      }
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deleteDoc.value = null;
    };

    const informMessage = ref("");
    const showToast = ref(false);

    const partnerTransactionHistory = ref([]);
    const fetchPartnerTransactionHistory = async (id) => {
      try {
        const req = {
          tableName: "partner_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "preloan_id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };

        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        if (res.data.length > 0) {
          partnerTransactionHistory.value = res.data;
        } else {
          partnerTransactionHistory.value = [];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const isSuccess = ref(false);
    const handleConfirmDelete = async (doc) => {
      try {
        console.log("doc", doc);

        await deletePreloan(doc);

        const timestamp = await fetchTimestamp();
        const findParter = partners.value.find((p) => p.id === doc.partner_id);
        const findSupplier = suppliers.value.find(
          (s) => s.id === doc.supplier_id
        );

        const Message = `Hello, Dear ${findParter ? findParter.name : ""}

A preloan has been deleted for ${
          findSupplier
            ? findSupplier.name +
              "|" +
              formatMobileNumber(findSupplier.phone_number)
            : ""
        } in the amount of: ${numeral(doc.amount).format("0,0")} ${
          doc.currency?.symbol_currency
        }.
Please process it as soon as possible.
Date: ${formatDate(timestamp)}`;

        if (doc.get_preloan_by === "partner") {
          DMTelegram(doc.partner_id, Message);
        }

        const response = await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.preloan_id}`,
          {
            data: { tableName: "preloans" },
          }
        );

        if (response.status === 200) {
          if (joinPreloanData.value.length <= 1 && currentPage.value > 1) {
            handlePageChange(currentPage.value - 1);
          } else if (currentPage.value === 1) {
            await fetchDataPaginationWithJoin();
          }

          isSuccess.value = true;
          showToast.value = true;
          informMessage.value = "Delete Success";
          currentComponent.value = "ToastVue2";

          closeDeleteDialog();
          deleteDoc.value = null;
        }
      } catch (error) {
        if (error) {
          await fetchPartnerTransactionHistory(doc.preloan_id);
          informMessage.value = `Can't Delete this transaction because it has associated in partner transaction history [ ${partnerTransactionHistory.value[0]?.id} ] !`;
          showToast.value = true;
          currentComponent.value = "ToastVue2";
          console.error("Error deleting item:", error);
        }
      }
    };

    // responsive
    const windowWidth = ref(window.innerWidth);
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchSuppliers(),
        fetchDataPaginationWithJoin(),
        fetchPartners(),
        fetchTotalRecord(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      await Promise.allSettled([
        fetchPartners(),
        fetchSuppliers(),
        fetchDataPaginationWithJoin(),
        fetchTotalRecord(),
        fetchUserByID(),
        fetchWalletPartner(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      isUpdateStatus.value = true;
      isStatus.value = doc;
      currentComponent.value = "UpdateStatus";
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };

    return {
      authUserRole,
      permissions,
      totalItems,
      totalRecord,
      handleClearToast,
      isSuccess,
      informMessage,
      showToast,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      handleClientDetails,
      handleCloseDetails,
      isDetails,
      searchValue,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,

      toast,
      showSuccessToast,
      handleEditData,
      datatoedit,
      sortData,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deleteDoc,

      // responsive
      tableClasses,
      navClasses,
      selectClasses,
      //
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      joinPreloanData,
      getSupplierName,
      suppliers,
      filterPreloan,
      // pagination
      getRowOptions,
      changePageSize,
      changePage,
      formatDate2,
      formatCurrency,
      getPartnerName,
      mdiTrashCanOutline,
      mdiPencil,
      mdiEyeOutline,
      sortData,
      sortDirections,
      sortColumns,
      // date range
      selectDateRage,
      handleFilter,
      partner,
      selectedPartner,
    };
  },
};
</script>

<style scoped>
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-numbers button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  color: black;
}

.page-numbers button.active {
  background-color: #f0f0f0;
  color: black !important;
}

.page-numbers button:not(.active):hover {
  background-color: #f0f0f0;
  color: black;
}

.hidden-pages {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: 10;
}

.page-numbers button:hover .hidden-pages {
  display: block;
}
</style>
