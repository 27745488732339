<template>
  <div
    class="w-full relative text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div :class="navClasses">
      <div>
        <h2>Truck List</h2>
      </div>
      <div class="flex items-center justify-between gap-3">
        <IconField class="w-[80%] placeholder:text-body2">
          <InputText v-model="searchValue" placeholder="Search | Name" />
          <InputIcon class="pi pi-search" />
        </IconField>
        <button @click="handleAdd">New Truck</button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[600px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td class="text-nowrap text-start">Created At</td>
            <td
              @click="sortData('id')"
              class="text-start text-nowrap toggle_sort"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition
                  name="fade"
                  mode="out-in"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated animate__fadeOut"
                >
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'id' && sortDirections === 'asc'
                        ? ['fas', 'arrow-up']
                        : ['fas', 'arrow-down']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('plate_number')"
              class="text-start text-nowrap toggle_sort"
            >
              Plate Number
              <span class="ml-2 cursor-pointer">
                <transition
                  name="fade"
                  mode="out-in"
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="animate__animated animate__fadeOut"
                >
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'plate_number' && sortDirections === 'asc'
                        ? ['fas', 'arrow-up']
                        : ['fas', 'arrow-down']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              class="text-nowrap text-start flex items-center justify-between gap-4"
            >
              <div class="flex items-start justify-between">Status</div>
            </td>
            <td class="border text-nowrap">Action</td>
          </tr>
        </tbody>
        <tbody v-if="plates.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in plates"
            :key="data"
          >
            <td class="border-b text-nowrap">
              {{ formatDate2(data.created_at) }}
            </td>
            <td class="border-b text-nowrap">{{ data.id }}</td>
            <td class="border-b text-nowrap">{{ data.plate_number }}</td>
            <td
              v-if="data.status === true"
              @click="handleUpdateStatus(data)"
              class="border-b text-nowrap"
            >
              <el-button type="success" round>Active</el-button>
            </td>
            <td
              v-else
              @click="handleUpdateStatus(data)"
              class="border-b text-nowrap"
            >
              <el-button type="warning" round>Incative</el-button>
            </td>
            <td class="border text-nowrap">
              <div class="flex items-center gap-3">
                <svg-icon
                  :path="mdiPencil"
                  type="mdi"
                  class="edit"
                  @click="handleEditData(data)"
                ></svg-icon>
                <svg-icon
                  :path="mdiTrashCanOutline"
                  type="mdi"
                  class="delete"
                  @click="data.status === false && openDeleteConfirmation(data)"
                ></svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full flex items-center justify-between mt-8">
      <PaginationVue
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :isLastRecordOnPage="isLastRecordOnPage"
        :totalRecord="plates.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        :tableName="tableName"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isUpdateStatus" as="template">
      <Dialog as="div" @close="handleCloseStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseStatus"
                    :is="currentComponent"
                    :isStatus="isStatus"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
  <Toast ref="toast" />
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import NewTruckForm from "@/form/NewTruckForm.vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import socket from "@/services/socket";
import { formatDate } from "@/composables/formatDateTime";
import Toast from "@/components/Toast.vue";
import PaginationVue from "@/components/Pagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import toggleSort from "@/composables/sortData";
import UpdateStatus from "@/components/UpdateStatus.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  components: {
    NewTruckForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Toast,
    PaginationVue,
    DeleteConfrimation,
    UpdateStatus,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "NewTruckForm";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    // fetch api
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("plates");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const plates = ref([]);
    const fetchPlates = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "plate_number",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([]),
      };
      const response = await axios.get(apiUrl, { params });
      plates.value = response.data.data;
      console.log("date ", plates.value);
      totalPages.value = response.data.pagination.totalPages;
    };
    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "NewTruckForm";
      datatoedit.value = item;
      console.log("item:", datatoedit);
    };
    onMounted(() => {
      fetchPlates();
    });

    const handleDatabaseUpdate = () => {
      fetchPlates();
    };
    onMounted(() => {
      socket.on("database_realTime", handleDatabaseUpdate);
      fetchPlates();
    });
    onUnmounted(() => {
      socket.off("database_realTime");
    });
    watch(searchValue, () => {
      fetchPlates();
    });
    const tableName = ref("");
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetch_suppliers();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetch_suppliers();
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    // sort
    const sortClient = (feild, sortDirection) => {
      if (feild === "plate_number") {
        toggleSort(sortDirection, plates.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, plates.value, feild);
      }
    };
    // delete
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
      console.log("item:", deletedata);
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: table.value },
        });
        await fetchplates();
        if (isLastPage && plates.value.length === 0) {
          currentPage.value = 1;
          await fetchplates(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      isUpdateStatus.value = true;
      isStatus.value = doc;
      currentComponent.value = "UpdateStatus";
      console.log("isUpdate status", isStatus);
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (feild) => {
      const direction =
        sortColumns.value === feild && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = feild;
      sortDirections.value = direction;
      plates.value = toggleSort(direction, plates.value, feild);
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      navClasses,
      tableClasses,
      searchValue,
      plates,
      formatDate,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      table,
      tableName,
      showSuccessToast,
      toast,
      handleEditData,
      datatoedit,
      deletedata,
      openDeleteConfirmation,
      handleConfirmDelete,
      itemIdToDelete,
      isDeleteOpen,
      closeDeleteDialog,
      sortClient,
      // update Status
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      formatDate2,
      mdiPencil,
      mdiTrashCanOutline,
      sortData,
      sortColumns,
      sortDirections,
    };
  },
};
</script>

<style scoped>
.toggle_sort {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
