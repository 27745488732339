<template>
  <div class="w-full overflow-hidden relative">
    <div class="flex items-center justify-between mt-5 border-b border-t py-2">
      <h2 class="uppercase">Balance Summary</h2>
    </div>
    <div>
      <div class="p-3 flex w-full items-start gap-3">
        <div class="w-1/2 h-[140px] bg-primary3 rounded-[8px] p-5">
          <h2
            :class="{
              'text-white text-heading1': windowWidth >= 1480,
              'text-white text-body': windowWidth < 1480,
            }"
          >
            Balance in KHR
          </h2>
          <h2
            :class="{
              'text-heading1 text-end  mt-10 ': windowWidth >= 1480,
              'text-heading2 text-end mt-10 ': windowWidth < 1480,
            }"
          >
            <span class="text-white font-semibold mr-4">
              {{ numeralFormat(totalKhWallets, "0,0[.]00") }} KHR</span
            >
          </h2>
        </div>
        <div
          class="w-1/2 h-[140px] bg-primary3/25 border-primary3 border-2 rounded-[8px] p-5"
        >
          <h2
            :class="{
              'text-primary3 text-heading1': windowWidth >= 1480,
              'text-primary3 text-body': windowWidth < 1480,
            }"
          >
            Balance in VND
          </h2>
          <h2
            :class="{
              'text-heading1 text-end mt-10 ': windowWidth >= 1480,
              'text-heading2 text-end mt-10 ': windowWidth < 1480,
            }"
          >
            <span class="text-primary3 mr-4">
              {{ numeralFormat(totalVnWallets, "0,0[.]00") }} VND</span
            >
          </h2>
        </div>
      </div>
    </div>
    <div class="w-full h-[500px] overflow-auto relative">
      <div class="w-full overflow-x-auto mt-4 border sticky top-0">
        <div
          class="w-full py-2 bg-white flex items-center justify-between border-b-2"
        >
          <h2 class="ml-4">
            Wallet <span class="font-semibold"> Partner Name </span>
          </h2>
          <div class="mr-4">
            <button
              @click="handleAddWallet"
              v-if="
                authUserRole === 'Super Admin' ||
                (authUserRole === 'User' &&
                  permissions.module_wallet?.partner?.create === true)
              "
            >
              New Wallet
            </button>
          </div>
        </div>
      </div>

      <div class="w-full flex flex-wrap items-center justify-center p-3 gap-2">
        <!-- {{ partners }} -->
        <div
          class="bg-white rounded-[8px] py-3 cursor-pointer w-[750px] shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
          @click="handleDetailsWallet(data)"
          v-for="data in partners"
          :key="data.id"
        >
          <div class="p-3 space-y-4">
            <div class="flex items-center gap-3 w-full justify-between">
              <div class="flex gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_wallet?.partner?.update === true)
                  "
                  :path="mdiPencil"
                  @click.stop="handleEditData(data)"
                  type="mdi"
                  class="edit"
                ></svg-icon>
                <h2>{{ data.wallet_number }}</h2>
              </div>
              <div class="flex gap-3">
                <h2 class="text-heading2 font-bold text-red-500">
                  {{
                    numeralFormat(
                      calculateBalance(data.wallet[0], "KH"),
                      "0,0[.]00"
                    )
                  }}
                </h2>
                <h2 class="text-primary2 text-heading2 font-bold">KHR</h2>
              </div>
            </div>
            <div>
              <div class="flex items-center gap-3 w-full justify-between">
                <div class="flex gap-3">
                  <!-- <svg
                   
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-trash-2 delete"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg> -->

                  <svg-icon
                    v-if="
                      authUserRole === 'Super Admin' ||
                      (authUserRole === 'User' &&
                        permissions.module_wallet?.partner?.delete === true)
                    "
                    @click.stop="openDeleteConfirmation(data)"
                    :path="mdiTrashCanOutline"
                    type="mdi"
                    class="delete"
                  ></svg-icon>

                  <h2 class="font-bold text-primary2 text-heading2">
                    {{ data.name }}
                    <br />
                    <span class="text-primary6 font-normal text-body">{{
                      data.phone_number
                    }}</span>
                  </h2>
                </div>
                <div class="flex gap-3">
                  <h2 class="font-bold text-red-500 text-heading2">
                    {{
                      numeralFormat(
                        calculateBalance(data.wallet[0], "VN"),
                        "0,0[.]00"
                      )
                    }}
                  </h2>
                  <h2 class="font-bold text-primary2 text-heading2">VND</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    @toast="showSuccessToast"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="handleCloseWallet" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseWallet"
                    @toast="showSuccessToast"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetails" as="template">
      <Dialog as="div" @close="handleCloseDetals" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetals"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :calculateBalance="calculateBalance"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, onBeforeUnmount, onMounted, onUnmounted, computed } from "vue";
import ParnterFormVue from "@/form/ParnterForm.vue";
import WalletFormVue from "@/form/WalletForm.vue";
import WalletDetailPopupVue from "@/form/WalletDetailPopup.vue";
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import { mdiTrashCanOutline, mdiPencil } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ParnterFormVue,
    WalletFormVue,
    WalletDetailPopupVue,
    Toast,
    DeleteConfrimation,
  },
  setup() {
    // responsive
    const windowWidth = ref(window.innerWidth);
    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowWidth);
    });

    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "ParnterFormVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handleAddWallet = () => {
      isPopup.value = true;
      datatoedit.value = null;

      currentComponent.value = "WalletFormVue";
    };
    const handleCloseWallet = () => {
      currentComponent.value = "";
      isPopup.value = false;
    };
    const isDetails = ref(false);

    const handleDetailsWallet = (item) => {
      if (
        authUserRole.value === "Super Admin" ||
        (authUserRole.value === "User" &&
          permissions.value?.module_wallet?.partner?.view === true)
      ) {
        isDetails.value = true;
        datatoedit.value = item;
        currentComponent.value = "WalletDetailPopupVue";
      }
    };
    const handleCloseDetals = () => {
      isDetails.value = false;
      currentComponent.value = "";
    };

    const totalVnWallet = ref(null);
    const totalKhWallet = ref(null);
    const partners = ref([]);
    const fetchpartners = async () => {
      const params = {
        tableName: "partners",
        sortColumn: "id",
        dynamicConditions: JSON.stringify([
          {
            field: "status",
            operator: "=",
            value: true,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
        params,
      });
      partners.value = response.data;

      totalVnWallet.value = null;
      totalKhWallet.value = null;

      if (partners.value.length > 0) {
        partners.value.forEach((p) => {
          console.log("total partner wallet", calculateBalance(p.wallet[0]));

          console.log("p", p);
        });
      } else {
        totalVnWallet.value = null;
        totalKhWallet.value = null;
      }
    };
    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isPopup.value = true;
      currentComponent.value = "WalletFormVue";
      datatoedit.value = item;
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchpartners(), fetchUserByID()]);
    };
    onMounted(async () => {
      window.addEventListener("resize", updateWindowWidth);

      await Promise.allSettled([fetchpartners(), fetchUserByID()]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });
    onUnmounted(() => {
      socket.off("database_realTime");
    });

    // toast
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    //
    // delete
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      const walletToDelete = partners.value.find((cust) => cust.id === doc.id);
      const khWallet = calculateBalance(walletToDelete.wallet[0], "KH");
      const vnWallet = calculateBalance(walletToDelete.wallet[0], "VN");
      if (Math.abs(khWallet) > 0 || Math.abs(vnWallet) > 0) {
        showSuccessToast("cannotdelete", "error");
      } else {
        deletedata.value = doc;
        isDeleteOpen.value = true;
      }
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: "partners" },
        });
        await fetchpartners();

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    const calculateBalance = (wallet, type) => {
      if (type === "KH") {
        const sumDeposit = wallet.KH.deposit.original_partner_owe.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        const sumPreloan = wallet.KH.preloan.original_partner_owe.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        const sumWithdrawal = wallet.KH.withdrawal.original_partner_owe.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        const sumCustomerPayment =
          wallet.KH.customer_payment.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumExchangeBalanceOriginalPartner =
          wallet.KH.exchange_balance.original_owe_partner.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumExchangeBalanceOriginalPartnerOwe =
          wallet.KH.exchange_balance.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumSupplierPayment =
          wallet.KH.supplier_payment.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumPreloanRepayment =
          wallet.KH.preloan_repayment.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        return (
          sumDeposit +
          sumPreloan +
          sumWithdrawal +
          sumCustomerPayment +
          sumExchangeBalanceOriginalPartner +
          sumExchangeBalanceOriginalPartnerOwe +
          sumSupplierPayment +
          sumPreloanRepayment
        );
      } else if (type === "VN") {
        const sumDeposit = wallet.VN.deposit.original_partner_owe.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        const sumWithdrawal = wallet.VN.withdrawal.original_partner_owe.reduce(
          (sum, transaction) => sum + transaction.amount,
          0
        );
        const sumCustomerPayment =
          wallet.VN.customer_payment.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumExchangeBalanceOriginalPartner =
          wallet.VN.exchange_balance.original_owe_partner.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        const sumExchangeBalanceOriginalPartnerOwe =
          wallet.VN.exchange_balance.original_partner_owe.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
          );
        return (
          sumDeposit +
          sumWithdrawal +
          sumCustomerPayment +
          sumExchangeBalanceOriginalPartner +
          sumExchangeBalanceOriginalPartnerOwe
        );
      }
    };

    // Compute totals for KH and VN wallets
    const totalKhWallets = computed(() => {
      return partners.value.reduce((total, partner) => {
        if (partner.wallet && partner.wallet.length > 0) {
          return total + calculateBalance(partner.wallet[0], "KH");
        }
        return total;
      }, 0);
    });

    const totalVnWallets = computed(() => {
      return partners.value.reduce((total, partner) => {
        if (partner.wallet && partner.wallet.length > 0) {
          return total + calculateBalance(partner.wallet[0], "VN");
        }
        return total;
      }, 0);
    });

    return {
      authUserRole,
      permissions,
      currentComponent,
      isPopup,
      handleAdd,
      isOpen,
      handleClose,
      handleAddWallet,
      handleCloseWallet,
      handleDetailsWallet,
      handleCloseDetals,
      isDetails,
      windowWidth,

      partners,
      toast,
      showSuccessToast,
      handleEditData,
      datatoedit,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,

      isDeleteOpen,
      deletedata,
      totalKhWallet,
      totalVnWallet,
      calculateBalance,
      // total wallet
      totalKhWallets,
      totalVnWallets,
      mdiTrashCanOutline,
      mdiPencil,
    };
  },
};
</script>

<style></style>
