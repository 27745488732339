<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="router.go(-1)" class="btncancel hidden-print">
            Back
          </button>
        </div>
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <!-- {{ invoice }} -->
      <div class="w-full mt-4 bg-white p-8 print-header" id="printTable">
        <div class="flex justify-between items-start">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Sack Slip</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12px]">011 111 111 / 012 123 456</h2>
          </div>
          <div class="text-primary5 text-[10px] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[10px] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4"
        >
          <div class="flex w-full items-center justify-between">
            <h2>Bill No.</h2>
            <h2>{{ invoice[0]?.id }}</h2>
          </div>

          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
        </div>
        <div class="py-2">
          <h2>Transaction Detail</h2>
          <div class="relative mt-4 bg-white text-[10px]">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[70px] text-nowrap border text-start">No</td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Time
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Bill Ref
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total Amount
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr
                  class="text-[10px] font-normal text-primary2 text-nowrap"
                  v-for="(data, index) in invoice[0]?.weighing"
                  :key="index"
                >
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ index + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ formatDate2(data.created_at) }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ data.supplier_invoice }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.sack_expense, "0,0[.]00") }}
                    ៛
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="font-bold bg-default">
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(invoice[0]?.amount_paid, "0,0[.]00") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="w-full h-[40px] bg-main border-main border-2 mt-4 flex items-center justify-between"
        >
          <div class="w-[70%]">
            <h2 class="text-right text-[10px] text-default mr-4">
              Total to Pay
            </h2>
          </div>
          <div class="bg-default w-[30%] h-full flex items-center justify-end">
            <h2 class="text-body mr-4 font-bold">
              {{ numeralFormat(invoice[0]?.amount_paid, "0,0[.]00") }} ៛
            </h2>
          </div>
        </div>

        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[40px]"
        >
          <h2 class="text-primary5 text-[10px]">Accepted</h2>
          <h2 class="text-primary5 text-[10px]">Issued</h2>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[50px]"
        >
          <h2 class="text-primary5 text-[10px]">Sack Slip</h2>
          <h2 class="text-primary5 text-[10px]">
            {{ getUserName(invoice[0]?.user_id) }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
export default {
  setup() {
    const route = useRoute();
    const invoice = ref([]);

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const fetchInvoiceData = async () => {
      try {
        const req = {
          tableName: "fee_bag_invoice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: Number(route.params.id),
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        invoice.value = response.data;

        console.log("Invoice data:", invoice.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const supplier = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        supplier.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const weighingData = ref([]); // Store full weighing data

    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        weighingData.value = response.data; // Store all data
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    const plate = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        plate.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const partner = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching partner data:", error);
      }
    };
    const users = ref([]);
    const fetchUser = async () => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        users.value = response.data;
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };
    const plateNumberMap = computed(() => {
      return weighingData.value.reduce((map, weighing) => {
        map[weighing.id] = weighing.plate_number_id;
        return map;
      }, {});
    });
    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getSupplierName = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = plate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getPhoneNumer = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.phone_number;
    };
    const getUserName = (id) => {
      const name = users.value.find((item) => item?.id === id);
      return name?.profile_name;
    };
    // Computed property to group rices
    const groupedData = computed(() => {
      if (
        !invoice.value ||
        !invoice.value.length ||
        !invoice.value[0].rices ||
        !invoice.value[0].additionalexpense ||
        !invoice.value[0].weighing
      ) {
        console.warn("No invoice data available or missing required arrays");
        return {};
      }

      const rices = invoice.value[0].rices;
      const weighings = invoice.value[0].weighing;
      const additionalExpenses = invoice.value[0].additionalexpense;

      const riceGroups = {};

      weighings.forEach((weighing) => {
        const weighingId = weighing.weighing_id;
        if (!riceGroups[weighingId]) {
          riceGroups[weighingId] = {
            rices: [],
            additionalExpenses: [],
            weighing,
            plate_number_id: plateNumberMap.value[weighingId] || null, // Access plate_number_id
          };
        }
      });

      rices.forEach((rice) => {
        if (rice.weighing_id in riceGroups) {
          riceGroups[rice.weighing_id].rices.push(rice);
        }
      });

      additionalExpenses.forEach((expenseEntry) => {
        if (expenseEntry.expenses) {
          expenseEntry.expenses.forEach((expense) => {
            if (expense.weighing_id in riceGroups) {
              riceGroups[expense.weighing_id].additionalExpenses.push(expense);
            }
          });
        }
      });

      return riceGroups;
    });

    // Ensure to fetch data on component mount
    onMounted(async () => {
      await Promise.allSettled([
        fetchInvoiceData(),
        fetchSupplier(),
        fetchRiceType(),
        fetchPlates(),
        fetchPartner(),
        fetchWeighingData(),
        fetchUser(),
      ]);
    });

    const handlePrint = () => {
      window.print();
    };
    const totalWeightIn = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.weight_in || 0), 0);

    const totalWeightOut = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.weight_out || 0), 0);

    const totalWeight = (rices = []) =>
      rices.reduce(
        (total, rice) =>
          total + (Number(rice.weight_in || 0) - Number(rice.weight_out || 0)),
        0
      );

    const totalRemain = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.remain || 0), 0);

    const totalCost = (rices = []) =>
      rices.reduce(
        (total, rice) =>
          total + Number(rice.unit_cost || 0) * Number(rice.remain || 0),
        0
      );

    const totalCutOut = (rices = []) =>
      rices.reduce(
        (total, rice) => total + Number(rice.amount_cut_off || 0),
        0
      );
    const totalAdditionalExpense = computed(() => {
      return invoice.value[0]?.additionalexpense.reduce((total, item) => {
        const expenseTotal = item.expenses.reduce(
          (sum, expense) => sum + (expense.amount || 0),
          0
        );
        return total + expenseTotal;
      }, 0);
    });
    const router = useRouter();
    return {
      handlePrint,
      invoice,
      groupedData,
      totalRemain,
      totalWeight,
      totalCost,
      totalWeightIn,
      totalWeightOut,
      totalCost,
      totalCutOut,
      supplier,
      partner,
      formatDate2,
      getPlatesName,
      getRice,
      getSupplierName,
      getPhoneNumer,
      totalAdditionalExpense,
      getUserName,
      users,
      router,
      // groupBySupplierInvoice,
    };
  },
};
</script>

<style lang="scss" scoped></style>
