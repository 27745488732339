<template>
  <div
    class="w-full h-screen flex items-center justify-center bg-gradient-to-br from-green-200 via-green-100 to-green-300"
  >
    <form
      @submit.prevent="handleLogin"
      class="w-fit p-8 rounded-lg shadow-lg backdrop-blur-md bg-white/60 border border-white/50"
    >
      <div class="text-center mb-4">
        <h2 class="text-2xl font-bold text-gray-800">Login Form</h2>
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label"
          >Username / Email</label
        >
        <input
          type="text"
          v-model="usernameOrEmail"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Email"
          required
        />
      </div>
      <div class="mb-3">
        <label for="exampleInputPassword1" class="form-label">Password</label>
        <input
          type="password"
          v-model="password"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="Password"
          required
        />
      </div>
      <div v-if="isError" class="text-red-500">
        <p>Invalid username / email or password</p>
      </div>

      <div v-if="isErrorPermissionLogin" class="text-red-500">
        <p>You are not allowed to login , Please contact your administrator</p>
      </div>

      <div class="py-3">
        <button v-if="!isPending" type="submit" class="btn-submit">
          Login
        </button>
        <button
          v-else
          type="button"
          class="btn-submit whitespace-nowrap flex items-center justify-center"
        >
          <div>Loading...</div>
          <div class="">
            <div
              class="animate-spin rounded-full size-4 border-b-2 border-r-2 border-btcha"
            ></div>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    const usernameOrEmail = ref("");
    const password = ref("");
    const isPending = ref(false);
    const isError = ref(false);
    const isErrorPermissionLogin = ref(false);
    const handleLogin = async () => {
      try {
        isPending.value = true;
        isError.value = false;
        let params;
        const isEmail = usernameOrEmail.value.includes("@");

        if (usernameOrEmail.value) {
          if (isEmail) {
            params = {
              tableName: "users",
              sortColumn: "id",
              dynamicConditions: JSON.stringify([
                {
                  field: "email",
                  operator: "=",
                  value: usernameOrEmail.value,
                  typeTable: "table",
                },
              ]),
            };
          } else {
            params = {
              tableName: "users",
              sortColumn: "id",
              dynamicConditions: JSON.stringify([
                {
                  field: "username",
                  operator: "=",
                  value: usernameOrEmail.value,
                  typeTable: "table",
                },
              ]),
            };
          }

          const response = await axios.get(
            `${apirURL}/weighing/api/getAllData`,
            { params }
          );

          if (response.data.length > 0) {
            if (response.data[0].role === "Super Admin") {
              if (password.value === response.data[0]?.temporary_password) {
                const timestamp = await fetchTimestamp();
                const requestBody = {
                  tableName: "users",
                  fields: {
                    password: password.value,
                    created_at: timestamp,
                    temporary_password: null,
                  },
                };
                const res = await axios.patch(
                  `${apirURL}/weighing/api/updateData/${response.data[0]?.id}`,
                  requestBody
                );

                if (res.data.message) {
                  const resLogin = await axios.post(
                    `${apirURL}/weighing/api/login`,
                    {
                      usernameOrEmail: usernameOrEmail.value,
                      password: password.value,
                    }
                  );

                  // Check if response contains token
                  const token = resLogin.data.token;
                  if (token) {
                    // Store the token in local storage
                    localStorage.setItem("token", token);

                    // Redirect the user to the home page
                    router.push("/");
                    isPending.value = false;
                  } else {
                    isPending.value = false;
                    // Show an alert if token is missing
                    console.error("Token not found in response:", resLogin);
                  }
                }
              } else {
                console.log("part min = knear temporary password");

                const response = await axios.post(
                  `${apirURL}/weighing/api/login`,
                  {
                    usernameOrEmail: usernameOrEmail.value,
                    password: password.value,
                  }
                );

                // Check if response contains token
                const token = response.data.token;
                if (token) {
                  // Store the token in local storage
                  localStorage.setItem("token", token);

                  // Redirect the user to the home page
                  router.push("/");
                  isPending.value = false;
                } else {
                  // Show an alert if token is missing
                  console.error("Token not found in response:", response);
                  isPending.value = false;
                }
              }
            } else {
              if (
                response.data[0].role === "User" &&
                response.data[0].status === false
              ) {
                console.log("canot login");
                isPending.value = false;
                isErrorPermissionLogin.value = true;
              } else {
                const response = await axios.post(
                  `${apirURL}/weighing/api/login`,
                  {
                    usernameOrEmail: usernameOrEmail.value,
                    password: password.value,
                  }
                );

                // Check if response contains token
                const token = response.data.token;
                if (token) {
                  // Store the token in local storage
                  localStorage.setItem("token", token);

                  // Redirect the user to the home page
                  router.push("/");
                  isPending.value = false;
                } else {
                  isPending.value = false;
                  // Show an alert if token is missing
                  console.error("Token not found in response:", response);
                }
              }
            }
          } else {
            isError.value = true;
            isPending.value = false;
          }
        }
      } catch (err) {
        isError.value = true;
        isPending.value = false;
        console.log("failed to login", err);
      }
    };

    const handleStorageChange = (event) => {
      if (event.key === "token" && event.newValue) {
        // Token has been removed, indicating a logout
        router.push("/");
      }
    };

    onMounted(() => {
      window.addEventListener("storage", handleStorageChange);
    });

    return {
      isErrorPermissionLogin,
      isPending,
      usernameOrEmail,
      password,
      handleLogin,
      isError,
    };
  },
};
</script>

<style>
.backdrop-blur-md {
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
</style>
