<template>
  <form @submit.prevent="handleSubmit" class="w-[500px] bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">Rice Adjustment</h2>
      <div @click="handleClose" class="closePopUp">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="w-full items-center gap-3 bg-white p-4 rounded border space-y-3">
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-nowrap font-bold">
            <span class="text-red-500">*</span> Warehouse</label>

          <InputText disabled type="text" v-model="selectWarehouse" placeholder="Warehouse"
            class="w-full md:w-[250px]  h-9 text-start flex items-center" />
        </div>
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-nowrap font-semibold">
            <span class="text-red-500">*</span> Select Rice</label>
          <div class="flex flex-col gap-1">
            <Select :options="rice_stock" :placeholder="'Select rice'" style="width: 250px"
              class="h-9 flex items-center justify-start" option-value="rice.rice_name" filter show-clear
              option-label="rice.rice_name" v-model="selectRice" />
            <div v-if="is_ValidateRice" class="mt-4">
              <el-alert title="Please Select the rice type" type="error" :closable="false" />
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3 justify-between ">
          <label for="name" class="flex gap-1 text-start text-nowrap font-semibold">
            <span class="text-red-500">*</span> Current Stock</label>
          <InputNumber v-model="displayStock" :maxFractionDigits="2" disabled class="h-2 w-[250px]" step="0.01"
            suffix=" Kg" required placeholder="Current Stock" />
        </div>
      </div>
      <div class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3">
        <div class="p">
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap font-semibold">
              <span class="text-red-500">*</span> Amount to Adjust</label>
            <div class="flex flex-col gap-1">

              <div class="mt-10">
                      <InputNumber :maxFractionDigits="2" v-model="amount" class="h-2 w-[250px] flex items-center" step="0.01"
                suffix=" Kg" placeholder="Amount Rice Adjustment" @keydown="validateInputNUmber" />
              </div>


              <div class="flex items-center gap-3 ">
                <div class="flex items-center gap-1">
                  <input type="radio" id="increase" value="increase" v-model="adjustType" />
                  <label for="increase">Increase</label>
                </div>
                <div class="flex items-center gap-1">
                  <input type="radio" id="decrease" value="decrease" v-model="adjustType" />
                  <label for="decrease">Decrease</label>
                </div>
              </div>

        

              
              <div v-if="is_validateAmount" class="mt-4">
                <el-alert title="Please Enter the amount" type="error" :closable="false" />
              </div>
            </div>
            
          </div>
        </div>

        <div class="flex items-start gap-3 font-semibold">
          <label for="Description">Description</label>
          <textarea type="text" placeholder="Any Description" rows="4"
            class="w-full px-[12px] bg-none border rounded placeholder:text-primary5 outline-none" />
        </div>
      </div>
      <div class="flex w-full items-center gap-3">
        <button type="submit">Save</button>
        <button class="btncancel">Save & New</button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
  <component :is="currentComponent" :informMessage="informMessage" :showToast="showToast" @onClose="handleClearToast" />
</template>

<script>
import { validateInputNUmber } from "@/composables/ValidateInput";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { onMounted, ref, watch, computed } from "vue";
import { decodeJwt } from "@/composables/decodeJWT";
import { fetchTimestamp } from "@/services/timestamp";
import { handleValidate } from "@/composables/checkValidatefeild";
import { useWareHouseStockRiceTransaction } from "@/composables/useWareHouseStockRiceTransaction";
import ToastVue from "@/components/ToastVue.vue";
export default {
  components: {
    ToastVue
  },
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const handleClear = () => {
      selectRice.value = null;
      amount.value = null;
    };
    const selectWarehouse = ref(null);

    const showToast = ref(false); 
    const informMessage = ref(null);
    const currentComponent = ref(null);
    const handleClearToast = () => {
      showToast.value = false;
      informMessage.value = null;
      currentComponent.value = null;
    };


    const amount = ref(null);
    const authUserId = ref(null);
    const currentStock = ref(null);
    const selectRice = ref(null);
    const warehouse = ref([]);


    const fetchWarebyId = async (id) => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        warehouse.value = response.data;
        console.log("warehouse", warehouse.value);

        return response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };


    function calculateTotalRiceStock(rice_stock, rice_name) {
      // Initialize total
      let total = 0;

      // Sum up all final_weight values for the specified rice_name
      rice_stock.forEach(record => {
        try {
          const riceData = record.rice;
          if (riceData.rice_name === rice_name) {
            total += Number(riceData.final_weight) || 0;
          }
        } catch (error) {
          console.error('Error parsing rice data:', error);
        }
      });

      return total;
    }

    const rice_stock = ref([]);

    const fetchRiceStock = async () => {
      try {
        const req = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
              typeTable: "table",
            },

          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
          params: req,
        });
        if (response.data.length > 0) {
          rice_stock.value = response.data;
          console.log("rice_stock", rice_stock.value);
        } else {
          rice_stock.value = [];
        }

      } catch (error) {
        console.error("Error fetching rice stock:", error);
        rice_stock.value = [];
      }
    };


    const is_ValidateRice = handleValidate(selectRice.value, selectRice);
    const is_validateAmount = handleValidate(amount.value, amount);
    const handleSubmit = async () => {
   
      try {

        if (!selectRice.value) {
        is_ValidateRice.value = true;
        return;
      }
      if (!amount.value) {
        is_validateAmount.value = true;
        return;
      }

      if(isErrorAmounDecrease.value){
        return;
      }
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "stock_rice_transaction_history",
          fields: {
            warehouse_id: props.datatoedit.id,
            created_at: timestamp,
            transaction_type: "adjust",
            created_by: authUserId.value,
            weight: adjustType.value === 'increase' ? amount.value : amount.value * -1,
            rice: JSON.stringify(
              {
                weight: null,
                original_weight: currentStock.value,
                weight_adjust:adjustType.value === 'increase' ? amount.value : amount.value * -1,
                after_adjust_weight: adjustType.value === 'increase' ? currentStock.value + amount.value : currentStock.value - amount.value,
                rice_name: selectRice.value,
                weight_sell: null,
                type: "adjust",
                from_weight: null,
                to_weight: null,
                final_weight: adjustType.value === 'increase' ? currentStock.value + amount.value : currentStock.value - amount.value,
                transaction_id: null,
              },
            ),
          },
        };


        console.log(req, "req");

         useWareHouseStockRiceTransaction(req, "adjust");

        emit("toast", "adjust");
        emit("close");
        handleClear();
      } catch (error) {
        console.log("Error during stock adjustment:", error);
      }
    };

    const handleSaveAndNew = async () => { };
    const isAmount = ref("");
    const validateAmount = () => {
      if (amount.value === null) {
        isAmount.value = "Please Enter the amount";
        return false;
      } else {
        isAmount.value = "";
        return true;
      }
    };
    watch(selectRice, (newRiceName) => {
      if (!newRiceName) {
        currentStock.value = null;
        return;
      }


      let totalWeight = null;
      if (rice_stock.value.length > 0) {
        totalWeight = calculateTotalRiceStock(rice_stock.value, newRiceName);

      }
      ;

      currentStock.value = totalWeight;
    });
    const adjustType = ref('increase');

    const isErrorAmounDecrease = ref(false);
    watch([adjustType, amount], () => {
      if (!currentStock.value || !amount.value) return;
      
      if (adjustType.value === 'decrease' && amount.value > currentStock.value) {

        
        isErrorAmounDecrease.value = true;
        currentComponent.value = "ToastVue";
        showToast.value = true;
        informMessage.value = "Amount adjust decrease cannot be bigger than current stock";

        setTimeout(() => {
          amount.value = null;
        
        }, 2000);
      } else {
        isErrorAmounDecrease.value = false;
      }
    });

    watch(amount, (newValue) => {
      if (newValue) {
        validateAmount();

      }
    });

    onMounted(async () => {

      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      await Promise.allSettled([
        fetchWarebyId(props.datatoedit.id),
        fetchRiceStock(),
      ]);
      if (props.datatoedit.id) {

        selectWarehouse.value = props.datatoedit.name;
      }
    });

    const isFilterable = ref(false);
    const displayStock = computed(() => {
      if (!currentStock.value || !amount.value) return currentStock.value;


      if(isErrorAmounDecrease.value){
        return currentStock.value;
      }
      
      return adjustType.value === 'increase' 
        ? currentStock.value + amount.value 
        : currentStock.value - amount.value;
    });
    return {
      showToast,
      informMessage,
      currentComponent,
      handleClearToast,
      rice_stock,
      handleClose,
      validateInputNUmber,
      selectWarehouse,

      currentStock,
      amount,
      handleSubmit,
      selectRice,
      handleSaveAndNew,
      isAmount,

      isFilterable,
      is_ValidateRice,
      is_validateAmount,
      warehouse,
      adjustType,
      displayStock,
    };
  },
};
</script>

<style></style>
