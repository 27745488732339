<template>
  <form @submit.prevent="handleSubmit" class="bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">{{ isUpdate ? "Update Supplier" : "New Supplier" }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Name</label
        >
        <input
          required
          class="w-[250px]"
          type="text"
          placeholder="Enter Name"
          v-model="name"
        />
      </div>

      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1 text-nowrap">
          <span class="text-red-500"></span> Phone number</label
        >
        <input
          type="text"
          class="w-[250px]"
          v-model="phone_number"
          @keydown="formatPhoneNumber"
          placeholder="Phone number"
        />
      </div>
      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1 text-nowrap">
          <span class="text-red-500">*</span> Supplier Type</label
        >
        <el-select
          v-if="isSupplierType === true"
          v-model="supplier_type"
          placeholder="Select"
          size="large"
          style="width: 250px"
          disabled
        >
          <el-option
            v-for="item in suppliersType"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <el-select
          v-if="isSupplierType === false"
          v-model="supplier_type"
          placeholder="Select"
          size="large"
          style="width: 250px"
          disabled
          :disabled="
            walletSupplier?.amount_owe_supplier > 0 ||
            walletSupplier?.amount_supplier_owe > 0 ||
            walletSupplier?.original_owe_supplier?.length > 0
          "
        >
          <el-option
            v-for="item in suppliersType"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <!-- <div class="flex items-center gap-3">
        <label for="name" class="flex gap-1 text-nowrap"> Status</label>

        <el-switch v-model="status" />
      </div> -->
      <div class="flex w-full items-center gap-3">
        <button type="submit" v-if="isAdding === true" :disabled="isAdding">
          Saving
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path></svg
          ></span>
        </button>
        <button v-else>{{ isUpdate ? "Update" : "Save" }}</button>
        <button
          type="button"
          class="btncancel"
          @click="handleSubmitAndNew"
          :disabled="isUpdatig"
          v-if="isUpdatig === true"
        >
          Saving
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="#168AF4"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </span>
        </button>
        <button
          v-else
          @click="handleSubmitAndNew"
          type="button"
          class="btncancel"
        >
          {{ isUpdate ? "Update & New" : "Save & New" }}
        </button>
        <button type="button" class="btncancel" @click="handleClose">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { formatPhoneNumber } from "@/composables/ValidateInput";
import { decodeJwt } from "@/composables/decodeJWT";

export default {
  props: ["datatoedit", "isSupplierType"],
  setup(props, { emit }) {
    // const showtoastToast = () => {
    //   emit("toast");
    // };
    const handleClose = () => {
      emit("close");
    };
    const handleClear = () => {
      name.value = "";
      phone_number.value = "";
    };

    const name = ref("");
    const phone_number = ref("");
    const supplier_type = ref("KH");
    const suppliersType = ref(["KH", "TH"]);
    const status = ref(true);
    const isAdding = ref(false);
    const isUpdatig = ref(false);
    const handleSubmitAndNew = async () => {
      setTimeout(() => {
        isUpdatig.value = true;
        setTimeout(() => {
          isUpdatig.value = false;
        }, 1000);
      }, 0);
      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "suppliers",
        fields: {
          name: name.value,
          name_lowercase: name.value.toLowerCase(),
          phone_number: phone_number.value,
          supplier_type: supplier_type.value,
          status: status.value,
          created_at: timestamp,
        },
      };

      try {
        if (props.datatoedit && props.datatoedit.id) {
          requestBody.fields.updated_at = timestamp;
          requestBody.fields.updated_by = authUserId.value;
          if (
            walletSupplier.value.preloan.original_supplier_owe.length <= 0 &&
            walletSupplier.value.supplier_invoice_cut_preloan
              .original_supplier_owe.length <= 0 &&
            walletSupplier.value.preloan_repayment.original_supplier_owe
              .length <= 0
          ) {
            requestBody.fields.wallet = {
              preloan: {
                original_supplier_owe: [], //sign +  // note [{"preloan_id":"","amount":}]
              },

              supplier_invoice_cut_preloan: {
                original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
              },

              supplier_invoice_unpaid: {
                original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
              },
              preloan_repayment: {
                original_supplier_owe: [], //sign -   // note [{"preloan_repayment_id":"","amount":}]
              },
            };
          }

          await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          handleClear();

          emit("toast", "update");
        } else {
          requestBody.fields.created_at = timestamp;
          requestBody.fields.created_by = authUserId.value;
          requestBody.fields.wallet = {
            preloan: {
              original_supplier_owe: [], //sign +  // note [{"preloan_id":"","amount":}]
            },

            supplier_invoice_cut_preloan: {
              original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
            },
            supplier_invoice_unpaid: {
              original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
            },

            preloan_repayment: {
              original_supplier_owe: [], //sign -   // note [{"preloan_repayment_id":"","amount":}]
            },
          };

          await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);
          handleClear();
          emit("toast", "create");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = async () => {
      isAdding.value = true;
      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "suppliers",
        fields: {
          name: name.value,
          name_lowercase: name.value.toLowerCase(),
          phone_number: phone_number.value,
          supplier_type: supplier_type.value,
          status: status.value,
          created_at: timestamp,
        },
      };

      try {
        if (props.datatoedit && props.datatoedit.id) {
          requestBody.fields.updated_at = timestamp;
          requestBody.fields.updated_by = authUserId.value;
          if (
            walletSupplier.value.preloan.original_supplier_owe.length <= 0 &&
            walletSupplier.value.supplier_invoice_cut_preloan
              .original_supplier_owe.length <= 0 &&
            walletSupplier.value.preloan_repayment.original_supplier_owe
              .length <= 0
          ) {
            requestBody.fields.wallet = {
              preloan: {
                original_supplier_owe: [], //sign +  // note [{"preloan_id":"","amount":}]
              },

              supplier_invoice_cut_preloan: {
                original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
              },

              supplier_invoice_unpaid: {
                original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
              },
              preloan_repayment: {
                original_supplier_owe: [], //sign -   // note [{"preloan_repayment_id":"","amount":}]
              },
            };
          }

          await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );

          emit("close");
          handleClear();
          emit("toast", "update");
        } else {
          requestBody.fields.created_at = timestamp;
          requestBody.fields.created_by = authUserId.value;
          requestBody.fields.wallet = {
            preloan: {
              original_supplier_owe: [], //sign +  // note [{"preloan_id":"","amount":}]
            },

            supplier_invoice_cut_preloan: {
              original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
            },
            supplier_invoice_unpaid: {
              original_supplier_owe: [], //sign -   // note [{"invoice_id":"","amount":}]
            },

            preloan_repayment: {
              original_supplier_owe: [], //sign -   // note [{"preloan_repayment_id":"","amount":}]
            },
          };

          await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);
          handleClear();
          emit("close");
          emit("toast", "create");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const walletSupplier = ref({});
    const isSupplierType = ref(null);
    const authUserId = ref(null);
    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.datatoedit) {
        name.value = props.datatoedit.name || "";
        phone_number.value = props.datatoedit.phone_number || "";
        supplier_type.value = props.datatoedit.supplier_type || "";
        status.value = props.datatoedit.status;
        walletSupplier.value = props.datatoedit.wallet;

        console.log("status", status);
      }
      if (props.isSupplierType === true || props.isSupplierType === false) {
        isSupplierType.value = props.isSupplierType;
        console.log("props", props.isSupplierType);
      }
    });

    const isUpdate = props.datatoedit;
    return {
      name,
      phone_number,
      supplier_type,
      handleSubmit,
      suppliersType,
      status,
      isUpdate,
      handleSubmitAndNew,
      handleClose,
      formatPhoneNumber,
      isAdding,
      isUpdatig,
      isUpdate,
      walletSupplier,
      isSupplierType,
    };
  },
};
</script>

<style></style>
