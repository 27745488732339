<template>
  <form
    @submit.prevent="handleSubmit"
    class="h-fit bg-default text-primary6 relative"
  >
    <div class="form_title">
      <h2 class="ml-4">New Weighing</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-5">
      <div class="bg-white p-2 rounded-md border">
        <div class="flex justify-between">
          <div class="flex items-center justify-start gap-5">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span>
              {{ getFrom === "supplier" ? "From Supplier" : "From Warehouse" }}
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="status"
                value="supplier"
                v-model="getFrom"
                class="form-radio text-primary3 cursor-pointer"
              />
              <span>Suppliler</span>
            </label>
          </div>
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span> Truck</label
              >
              <Select
                v-model="plate_numbers"
                :options="trucks"
                placeholder="Select Truck"
                option-value="plate_number"
                option-label="plate_number"
                size="large"
                filterable="true"
                class="w-full md:w-56 h-9 text-start flex items-center"
                show-clear
              />
              <button
                type="button"
                @click="handleAdd"
                class="px-2 py-2 bg-primary3/20"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 text-primary3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
            <div
              class="mt-4 w-[250px] float-end"
              v-if="isValidateTruck === true"
            >
              <el-alert
                title="Please Select the Truck"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>
        <div
          v-if="getFrom === 'supplier'"
          class="flex w-full justify-between py-4 gap-3"
        >
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span> Supplier</label
              >

              <Select
                v-model="selectSupplier"
                :options="suppliers"
                placeholder="Select Supplier"
                option-value="id"
                option-label="name"
                size="medium"
                :disabled="weighing?.length"
                class="w-full md:w-56 h-9 text-start flex items-center"
                filter
                show-clear
              />
            </div>
            <div v-if="isValidateSupplier" class="mt-4 w-[250px] float-end">
              <el-alert
                title="Please Select Supplier"
                type="error"
                :closable="false"
              />
            </div>
          </div>
          <div class="flex items-center gap-3">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span> Times of Weight
            </label>
            <InputText
              disabled
              type="text"
              v-model="numberOfWeighings"
              placeholder="Times of Weight"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>
        </div>
        <div
          v-else-if="getFrom === 'warehouse'"
          class="flex w-full justify-between py-4 gap-3"
        >
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span> Warehouse</label
              >

              <Select
                placeholder="Select Warehouse"
                v-model="selectWarehouse"
                :options="warehouse"
                option-value="id"
                option-label="name"
                filter
                show-clear
                class="w-full md:w-56 h-9 text-start flex items-center"
              />
            </div>
            <div v-if="isValidateWarehouse" class="mt-4 w-[250px] float-end">
              <el-alert
                title="Please Select Warehouse"
                type="error"
                :closable="false"
              />
            </div>
          </div>

          <div class="flex items-center gap-3">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span> Times of Weight
            </label>
            <InputText
              disabled
              type="text"
              v-model="weighing_time"
              placeholder="Times of Weigh"
              class="w-full md:w-56 h-9 text-start flex items-center"
            />
          </div>
        </div>
      </div>
      <div class="bg-white rounded-md border">
        <div class="w-full flex">
          <div
            :class="{
              'tab2 cursor-pointer w-full  ': true,
              ' pop_up_tab ': activeTab === 'weight',
            }"
            @click="setActiveTab('weight')"
          >
            Weight
          </div>
          <div
            :class="{
              'tab2 cursor-pointer w-full  ': true,
              'pop_up_tab ': activeTab === 'history',
            }"
            @click="setActiveTab('history')"
          >
            History
          </div>
        </div>
        <div v-if="activeTab === 'weight'" class="p-2">
          <div class="gap-8 w-[55%] mt-4 flex justify-between">
            <div class="space-y-3 flex flex-col">
              <div>
                <div class="flex items-center gap-3 justify-between">
                  <label for="name" class="flex gap-1 text-nowrap font-bold">
                    <span class="text-red-500">*</span> Current Weight
                  </label>
                  <InputNumber
                    suffix=" Kg"
                    style="width: 250px"
                    placeholder="Current Weigh"
                    :invalid="iscurrentWeightt === true"
                    :maxFractionDigits="2"
                    class="w-full md:w-56 h-2 text-start flex items-center"
                    v-model="currentWeight"
                    step="0.01"
                  />
                </div>
                <!-- <div
                  v-if="isValidatePreviousWeight"
                  class="mt-4 w-[250px] float-end"
                >
                  <el-alert
                    title="The Current Weight must be greater than the Previous Weights"
                    type="error"
                    :closable="false"
                  />
                </div> -->
                <div v-if="iscurrentWeightt" class="mt-4 w-[250px] float-end">
                  <el-alert
                    title="Please Enter the amount"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> Previous Weigh
                </label>
                <InputNumber
                  suffix=" Kg"
                  style="width: 250px"
                  placeholder="Previous Weigh"
                  :maxFractionDigits="2"
                  v-model="previousWeight"
                  disabled
                  step="0.01"
                />
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> Weight
                </label>
                <InputNumber
                  suffix=" Kg"
                  style="width: 250px"
                  placeholder=" Weigh"
                  :maxFractionDigits="2"
                  v-model="currentWeight"
                  disabled
                  step="0.01"
                />
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> Previous Parking</label
                >

                <Select
                  v-model="selectedParking"
                  :options="parking"
                  placeholder="Parking"
                  option-value="id"
                  option-label="name"
                  size="large"
                  style="width: 250px"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  filterable="true"
                  show-clear
                  checkmark
                  disabled
                />
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> Previous Rice</label
                >

                <Select
                  v-model="previousRight"
                  :options="rices"
                  placeholder="Rice type"
                  option-value="name"
                  option-label="name"
                  filter
                  disabled
                  show-clear
                  checkmark
                  style="width: 250px"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                />
              </div>
            </div>
            <div class="space-y-3">
              <div class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500"></span> Rice type</label
                >

                <Select
                  v-model="selectRice"
                  :options="rices"
                  placeholder="Rice type"
                  option-value="name"
                  option-label="name"
                  filter
                  show-clear
                  checkmark
                  style="width: 250px"
                  size="medium"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                />
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500"></span> Cut out
                </label>
                <InputNumber
                  suffix=" Kg"
                  :maxFractionDigits="2"
                  style="width: 250px"
                  placeholder=" Cut out"
                  v-model="isCutOut"
                  step="0.01"
                />
              </div>
              <div
                v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between"
              >
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> Weight Drop
                </label>
                <InputNumber
                  placeholder="Weight"
                  suffix=" Kg"
                  style="width: 250px"
                  :maxFractionDigits="2"
                  step="0.01"
                  v-model="remainingWeight"
                  disabled
                />
              </div>
              <div
                class="w-[500px] bg-primary3/10 border border-primary3 rounded-lg p-4 mt-12"
                v-if="weighing?.length > 0 && previousWeight !== null"
              >
                <div class="flex justify-between">
                  <h2 class="text-primary3 text-heading2 text-start">
                    Weight Drop
                  </h2>
                  <div>
                    <h2 class="text-primary3 text-heading2 text-start">
                      Weight:
                      {{
                        remainingWeight
                          ? numeralFormat(remainingWeight, "0,0[.]00")
                          : "---"
                      }}
                      Kg
                    </h2>
                  </div>
                </div>
                <div
                  class="text-start ml-12 text-heading1 font-semibold text-primary3"
                >
                  {{
                    remainingWeight
                      ? numeralFormat(remainingWeight, "0,0[.]00")
                      : "---"
                  }}
                  Kg
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="activeTab === 'history'" class="p-2">
          <div class="relative overflow-x-auto mt-4 border h-[280px]">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[100px] border-b text-start">No.</td>
                  <td class="min-w-[100px] border-b text-start">Rice Type</td>
                  <td class="min-w-[100px] border-b text-start">Weight</td>
                  <td class="min-w-[100px] border-b text-start">Cut out</td>
                  <td class="min-w-[100px] border-b text-start">Remain</td>
                  <td class="min-w-[100px] border-b text-start">Status</td>
                </tr>
              </tbody>
              <tbody v-if="weighing?.length > 0">
                <tr
                  class="text-body font-normal text-primary2 bg-white"
                  v-for="data in weighing"
                  :key="data.id"
                >
                  <td class="border-b">{{ data.id }}</td>
                  <td class="border-b">{{ data.rice[0]?.rice_name }}</td>
                  <td class="border-b">
                    {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
                  </td>
                  <td class="border-b">
                    {{ data.cuteOut ? data.cuteOut : "No Weigh Cut out" }}
                  </td>
                  <td class="border-b">
                    {{ data.weight_in - previousWeight }}
                  </td>
                  <td class="border-b">
                    {{
                      data.is_hold === false ? " Send to parking" : "Pending"
                    }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="history.length > 0">
                <tr
                  class="text-body font-normal text-primary2 bg-white"
                  v-for="data in history"
                  :key="data.id"
                >
                  <td class="border-b">{{ data.id }}</td>
                  <td
                    class="border-b"
                    v-for="rices in data.rice"
                    :key="rices.id"
                  >
                    {{ rices.rice_name }}
                  </td>
                  <td class="border-b">
                    {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
                  </td>
                  <td class="border-b">
                    {{
                      data.cuteOut
                        ? numeralFormat(data.cuteOut, "0,0[.]00")
                        : "No Weigh Cut out"
                    }}
                    Kg
                  </td>
                  <td class="border-b" colspan="2">
                    {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
                  </td>
                  <!-- <td class="border-b">
                    {{ data.is_hold === true ? "Pending" : "Send to parking" }}
                  </td> -->
                </tr>
              </tbody>
              <tbody v-if="history.length === 0 && weighing.length === 0">
                <tr>
                  <td colspan="6">
                    <NoDataComponet />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="flex w-full items-center gap-3">
        <button type="submit">Save</button>
        <button type="button" @click="SaveAndNew" class="btncancel">
          Save & New
        </button>

        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
    <div
      v-if="isOpen"
      @click="isClose"
      class="fixed bg-black/25 w-full h-full top-0 right-0 flex items-start justify-center z-40"
    >
      <div @click.stop class="mt-32">
        <component @closeTruck="isClose" :is="currentComponent"></component>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import NewTruckFormVue from "./NewTruckForm.vue";
import { validateInputNUmber } from "@/composables/ValidateInput";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: ["datatoedit"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    NewTruckFormVue,
    NoDataComponet,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
      handleClear();
    };
    const service_type = ref("");
    const isFrom = ref("supplier");
    const getFrom = ref("supplier");
    const currentComponent = ref("");
    const isOpen = ref(false);
    const cuteOut = ref(null);
    const isCutOut = ref(null);
    const selectSupplier = ref(null);
    const suppliers = ref([]);
    const rices = ref([]);
    const weighing = ref([]);
    const previousWeight = ref(null);
    const selectRice = ref(null);
    const validationError = ref("");
    const isPlate = ref("");
    const currentWeight = ref(null);
    const numberOfWeighings = ref(0);
    const handleClear = () => {
      isSupplier.value = "";
      iscurrentWeightt.value = "";
      isPlate.value = "";
      validationError.value = "";
      isCutOut.value = null;
      currentWeight.value = null;
      selectRice.value = null;
      selectSupplier.value = null;
      selectWarehouse.value = null;
      plate_numbers.value = null;
      previousWeight.value = null;
      cuteOut.value = null;
      selectWarehouse.value = null;
    };

    const remainingWeight = computed(() => {
      if (previousWeight.value !== null && currentWeight.value !== null) {
        return previousWeight.value - currentWeight.value;
      }
      return null;
    });

    const iscurrentWeightt = handleValidate(currentWeight.value);
    const plate_numbers = ref(null);
    const isValidateTruck = handleValidate(plate_numbers.value);
    const isSupplier = ref("");
    const isValidateSupplier = handleValidate(selectSupplier.value);

    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "NewTruckFormVue";
    };
    const isClose = () => {
      isOpen.value = false;
    };
    const handleCloseTruck = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const activeTab = ref("weight");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const fetchSipplers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const selectWarehouse = ref(null);
    const warehouse = ref([]);

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const trucks = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parking = ref([]);
    const selectedParking = ref("");
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    // Holds the previous weight (from old data)

    // Function to fetch both current and previous weighing data
    const weighingId = ref(null);
    const previousRight = ref(null);
    const fetchWeighing = async (plate) => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "plate_number",
              operator: "->>",
              key: "plate_number",
              value: plate,
              type: "jsonb",
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        console.log("response Data", response.data);

        // src/form/WeighingForm.vue
        if (response.data.length > 0) {
          weighing.value = response.data;
          previousWeight.value = weighing.value[0]?.weight_in;
          selectedParking.value = weighing.value[0]?.boat_enter_parking_id;
          selectRice.value = weighing.value[0]?.rice[0]?.rice_name;
          selectSupplier.value = weighing.value[0]?.supplier_id;
          selectWarehouse.value = weighing.value[0]?.warehouse_id;
          service_type.value = weighing.value[0]?.service_type;
          weighingId.value = weighing.value[0]?.id;
          previousRight.value = weighing.value[0]?.rice[0]?.rice_name;

          // Check if the weight_in is zero and handle accordingly
          if (weighing.value[0]?.weight_in === 0) {
            previousWeight.value = null;
            selectedParking.value = null;
            selectRice.value = null;
            // selectSupplier.value = null; // Uncomment if needed
            isCutOut.value = null;
          }
        } else {
          // Handle case where there is no data
          weighing.value = [];
          previousWeight.value = null;
          selectedParking.value = null;
          selectRice.value = null;
          // selectSupplier.value = null; // Uncomment if needed
          isCutOut.value = null;
        }
      } catch (error) {
        console.error("Error fetching weighing data:", error);
        weighing.value = []; // Clear data in case of an error
      }
    };

    const fetchLastWeighingRiceHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
              typeTable: "table",
            },
            // {
            //   field: "is_hold",
            //   operator: "=",
            //   value: false,
            //   typeTable: "table",
            // },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };

    const history = ref([]);
    const weighing_time = ref("");

    const fetchWeighingHistory = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId.value,
              typeTable: "table",
            },
            // {
            //   field: "status",
            //   operator: "=",
            //   value: true,
            //   typeTable: "table",
            // },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        history.value = response.data;
        weighing_time.value = history.value.length;
        console.log("history", history.value);
      } catch (error) {
        console.error("Error fetching weighing history data:", error);
      }
    };
    const last_weighing_history = ref([]);

    const fetchNumberOfWeighings = async (plate_number) => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "plate_number",
              operator: "->>",
              key: "plate_number",
              value: plate_number,
              type: "jsonb",
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };

    watch(plate_numbers, async (newVal) => {
      if (newVal) {
        const data = await fetchNumberOfWeighings(newVal);
        let numberOfWeight = 0;
        if (data.length > 0) {
          console.log("data", data);
          numberOfWeight = data[0]?.number_of_weighings + 1;
        } else {
          numberOfWeight = 1;
        }

        console.log("numberOfWeighings", numberOfWeight);

        numberOfWeighings.value = numberOfWeight;
      }
    });

    const authUserId = ref(null);
    onMounted(async () => {
      await Promise.allSettled([
        fetchRiceType(),
        fetchSipplers(),
        fetchWarehouse(),
        fetchPlates(),
        fetchParking(),
      ]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      weighing_time.value = history.value.length;
    });

    const isFilterable = ref(false);

    const handleSubmit = async () => {
      if (!plate_numbers.value || plate_numbers.value === null) {
        isValidateTruck.value = true;
        return;
      }
      if (!selectSupplier.value) {
        isValidateSupplier.value = true;
        return;
      }
      if (currentWeight.value === null) {
        iscurrentWeightt.value = true;
        return;
      }

      try {
        const timestamp = await fetchTimestamp();

        const req = {
          tableName: "weighing",
          fields: {
            supplier_id: selectSupplier.value,
            rice: JSON.stringify([{ rice_name: selectRice.value }]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            date_in: timestamp,
            service_type: "external",
            service_external_type: "weighing_rental",
            number_of_weighings: numberOfWeighings.value,
            plate_number: JSON.stringify({ plate_number: plate_numbers.value }),
            boat_enter_parking_id: selectedParking.value || null,
            amount_cut_off: Number(isCutOut.value),
          },
        };

        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          const res = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (res.data.message) {
            //update last weighing rice history record first
            const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
              weighing.value[0]?.id
            );

            const requestBody = {
              tableName: "weighing_rice_history",
              fields: {
                weight_out: Number(currentWeight.value),
                weight_drop: Number(remainingWeight.value),
              },
            };

            const resUpdate = await axios.patch(
              `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
              requestBody
            );

            if (resUpdate.data.message) {
              if (currentWeight.value > 0) {
                await insertWeighingHistory(timestamp, weighing.value[0]?.id);
              }
            }
          }
        } else {
          req.fields.created_by = authUserId.value;
          req.fields.created_at = timestamp;
          // Create logic: no existing weighing entry
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );

          if (response.data) {
            await insertWeighingHistory(timestamp, response.data.insertedId);
          }
        }

        // Emit success notifications and reset the form
        emit("toast", "create");
        emit("close");
        handleClear();
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };

    const fetchWeighingHistoryWarehouse = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
              typeTable: "table",
            },
            {
              field: "is_hold",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );
        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };
    const insertWeighingHistory = async (timestamp, weighingId) => {
      try {
        // Insert history request
        const historyReq = {
          tableName: "weighing_rice_history",

          fields: {
            created_at: timestamp,
            date_in: timestamp,
            weighing_id: weighingId,
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value,
              },
            ]),
            boat_enter_parking_id: selectedParking.value
              ? selectedParking.value
              : null,
            weight_in: currentWeight.value,
            weight_out: 0,
            weight_drop: 0,
            status: false,
            service_type: "external",

            amount_cut_off: Number(isCutOut.value),
            created_by: authUserId.value,
          },
        };

        await axios.post(`${apirURL}/weighing/api/insertData`, historyReq);
      } catch (error) {
        console.error(
          "Error inserting history or updating weighing table:",
          error
        );
      }
    };
    const isValidateWarehouse = handleValidate(selectWarehouse.value);
    const insertWeighingHistoryWarehouse = async (timestamp, weighingId) => {
      try {
        // Insert history request
        const historyReq = {
          tableName: "weighing_rice_history",

          fields: {
            created_at: timestamp,
            date_in: timestamp,
            weighing_id: weighingId,
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value,
              },
            ]),
            warehouse_id: selectWarehouse.value,
            weight_in: currentWeight.value,
            weight_out: 0,
            weight_drop: 0,
            status: false,
            service_type: "external",
            is_hold: true,
            amount_cut_off: Number(isCutOut.value),
            created_by: authUserId.value,
          },
        };
        const res = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          historyReq
        );
        if (res.data.message) {
          const warehouseData = warehouse.value.find(
            (w) => w.id === selectWarehouse.value
          );

          // Check if warehouseData.stock is a string and parse it if necessary
          let existingStock;
          if (typeof warehouseData.stock === "string") {
            existingStock = JSON.parse(warehouseData.stock);
          } else {
            existingStock = warehouseData.stock || [];
          }

          // Proceed with the rest of your function...
          const newStockEntry = {
            rice_name: selectRice.value,
            weight: currentWeight.value,
          };
          const updatedStock = [...existingStock, newStockEntry];
          const req_warehouse = {
            tableName: "warehouse",
            fields: {
              // replace stock when weight is not 0
              stock: JSON.stringify(updatedStock),
              // weighing_id is array
              weighing_id: [weighingId],
            },
          };
          const updateWarehouse = await axios.patch(
            `${apirURL}/weighing/api/updateData/${selectWarehouse.value}`,
            req_warehouse
          );
          if (updateWarehouse.data.message) {
            const req_rice_stock_history = {
              tableName: "stock_rice_transaction_history",
              fields: {
                rice: JSON.stringify([
                  {
                    rice_name: selectRice.value,
                    weight: remainingWeight.value,
                  },
                ]),
                weight: remainingWeight.value,
                warehouse_id: selectWarehouse.value,
                transaction_type: "purchase",
                created_by: authUserId.value,
                created_at: timestamp,
              },
            };
            await axios.post(
              `${apirURL}/weighing/api/insertData`,
              req_rice_stock_history
            );
          }
        }
      } catch (error) {
        console.error(
          "Error inserting history or updating weighing table:",
          error
        );
      }
    };

    const handleSendtoWarehouse = async () => {
      if (!plate_numbers.value || plate_numbers.value === null) {
        isValidateTruck.value = true;
        return;
      }
      if (!selectWarehouse.value) {
        isValidateWarehouse.value = true;
        return;
      }
      if (!currentWeight.value) {
        iscurrentWeightt.value = true;
        return;
      }

      try {
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "weighing",
          fields: {
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value,
              },
            ]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            created_at: timestamp,
            date_in: timestamp,
            service_type: "external",
            number_of_weighings: numberOfWeighings.value,
            plate_number: JSON.stringify({
              plate_number: plate_numbers.value,
            }),
            warehouse_id: selectWarehouse.value,
            created_by: authUserId.value,
            amount_cut_off: Number(isCutOut.value),
          },
        };
        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          const update_weighing = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (update_weighing.data.message) {
            const lastrecord = await fetchWeighingHistoryWarehouse(
              weighing.value[0]?.id
            );
            console.log("lastrecord", lastrecord);

            const requestBody = {
              tableName: "weighing_rice_history",
              fields: {
                weight_out: Number(currentWeight.value),
                weight_drop: Number(remainingWeight.value),
              },
            };
            const resUpdate = await axios.patch(
              `${apirURL}/weighing/api/updateData/${lastrecord[0]?.id}`,
              requestBody
            );
            if (resUpdate.data.message) {
              if (currentWeight.value > 0) {
                await insertWeighingHistoryWarehouse(
                  timestamp,
                  weighing.value[0]?.id
                );
              }
            }
          }
        } else {
          const res = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );
          console.log("res", res.data);

          if (res.data.message) {
            await insertWeighingHistoryWarehouse(
              timestamp,
              res.data.insertedId
            );
          }
        }
      } catch (error) {
        console.log("error ", error);
      }
    };
    const SaveAndNew = async () => {
      if (!plate_numbers.value || plate_numbers.value === null) {
        isValidateTruck.value = true;
        return;
      }
      if (!selectSupplier.value) {
        isValidateSupplier.value = true;
        return;
      }
      if (currentWeight.value === null) {
        iscurrentWeightt.value = true;
        return;
      }

      try {
        const timestamp = await fetchTimestamp();

        const req = {
          tableName: "weighing",
          fields: {
            supplier_id: selectSupplier.value,
            rice: JSON.stringify([{ rice_name: selectRice.value }]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            date_in: timestamp,
            service_type: "external",
            service_external_type: "weighing_rental",
            number_of_weighings: numberOfWeighings.value,
            plate_number: JSON.stringify({ plate_number: plate_numbers.value }),
            boat_enter_parking_id: selectedParking.value || null,
            amount_cut_off: Number(isCutOut.value),
          },
        };

        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          const res = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (res.data.message) {
            //update last weighing rice history record first
            const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
              weighing.value[0]?.id
            );

            const requestBody = {
              tableName: "weighing_rice_history",
              fields: {
                weight_out: Number(currentWeight.value),
                weight_drop: Number(remainingWeight.value),
              },
            };

            const resUpdate = await axios.patch(
              `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
              requestBody
            );

            if (resUpdate.data.message) {
              if (currentWeight.value > 0) {
                await insertWeighingHistory(timestamp, weighing.value[0]?.id);
              }
            }
          }
        } else {
          req.fields.created_by = authUserId.value;
          req.fields.created_at = timestamp;
          // Create logic: no existing weighing entry
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );

          if (response.data) {
            await insertWeighingHistory(timestamp, response.data.insertedId);
          }
        }

        emit("toast", "create");
        handleClear();
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };

    watch(plate_numbers, async (newVal) => {
      if (newVal) {
        console.log("newVal", newVal);

        await fetchWeighing(newVal);
        await fetchWeighingHistory();
        if (weighing.value.length === 0) {
          selectSupplier.value = null;
        }
      } else {
        weighing.value = [];
        history.value = [];
      }
    });

    const clearWeighingData = () => {
      weighing.value = [];
      plate_numbers.value = "";
    };

    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    // const warehouse_by_id = ref([]);
    const fetchWarebyId = async (id) => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const SendAndNew = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "weighing",
          fields: {
            supplier_id: selectSupplier.value,
            rice_id: selectRice.value,
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            created_at: timestamp,
            date_in: timestamp,
            service_type: "external",
            plate_number_id: plate_numbers.value,
            warehouse_id: selectWarehouse.value,
            created_by: authUserId.value,
            amount_cut_off: Number(isCutOut.value),
          },
        };

        let firstWeighingId;
        if (weighing.value.length > 0) {
          // Update case
          req.fields.updated_by = authUserId.value;
          const res = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (res.data) {
            firstWeighingId = weighing.value[0]?.id; // Store the first weighing ID

            if (res.data) {
              try {
                const reqhistory = {
                  tableName: "weighing_rice_history",
                  fields: {
                    created_at: timestamp,
                    date_in: timestamp,
                    weighing_id: firstWeighingId, // Use the stored firstWeighingId here
                    rice_id: selectRice.value,
                    boat_enter_parking_id: selectedParking.value,
                    weight_in: currentWeight.value,
                    status: true,
                    service_type: "external",
                    weight_drop: remainingWeight.value,
                    warehouse_id: selectWarehouse.value,
                    created_by: authUserId.value,
                    is_hold: true,
                    status: true,
                  },
                };

                const weighing_rice_history = await axios.post(
                  `${apirURL}/weighing/api/insertData`,
                  reqhistory
                );
                console.log("response", reqhistory);
                emit("toast", "update");
                if (weighing_rice_history.data.message) {
                  const reqtohistory = {
                    tableName: "stock_rice_transaction_history",
                    fields: {
                      rice_id: selectRice.value,
                      warehouse_id: selectWarehouse.value,
                      created_at: timestamp,
                      transaction_type: "purchase",
                      updated_by: authUserId.value,
                      created_by: authUserId.value,
                      weight:
                        weighing.value.length > 0
                          ? previousWeight.value - currentWeight.value
                          : 0,
                    },
                  };
                  const stock_rice_history = await axios.post(
                    `${apirURL}/weighing/api/insertData`,
                    reqtohistory
                  );
                  console.log(
                    "reqtohistory",
                    reqtohistory,
                    stock_rice_history.data
                  );
                }
                handleClear();
              } catch (error) {
                console.log(error);
              }
            }
          }
        } else {
          // Insert case
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );

          if (response.data) {
            firstWeighingId = response.data.insertedId;

            const rice_id_array = ref([]);
            rice_id_array.value.push(selectRice.value);
            let weighing_id_array = [];
            weighing_id_array.push(firstWeighingId);
            const datawarehouse_by_id = await fetchWarebyId(
              selectWarehouse.value
            );
            console.log("datawarehouse_by_id", datawarehouse_by_id);

            let currentStock = datawarehouse_by_id[0]?.stock || [];
            const lastTransaction =
              currentStock.length > 0
                ? Math.max(...currentStock.map((item) => item.transaction || 0))
                : 0;

            const newTransactionId = lastTransaction + 1;
            console.log("newTransactionId", newTransactionId);

            const currentRiceIds = datawarehouse_by_id[0]?.rice_id || [];
            const currentWeightingIds =
              datawarehouse_by_id[0]?.weighing_id || [];
            // Check if rice_id already exists
            const riceExists = currentRiceIds.includes(selectRice.value);

            // Check if weighing_id already exists
            const weighingExists =
              currentWeightingIds.includes(firstWeighingId);
            if (!riceExists) {
              rice_id_array.value.push(selectRice.value);
            }

            // Push weighing_id only if it doesn't already exist
            if (!weighingExists) {
              weighing_id_array.push(firstWeighingId);
            }

            const reqBody = {
              tableName: "warehouse",
              fields: {
                rice_id:
                  rice_id_array.value.length > 0
                    ? [...currentRiceIds, ...rice_id_array.value]
                    : currentRiceIds,
                weighing_id:
                  weighing_id_array.length > 0
                    ? [...currentWeightingIds, ...weighing_id_array]
                    : currentWeightingIds,
                created_at: timestamp,
                stock: JSON.stringify([
                  {
                    rice_id: selectRice.value,
                    current_weight:
                      weighing.value.length > 0
                        ? previousWeight.value - currentWeight.value
                        : 0,
                    weighing_id: firstWeighingId,
                    weight_type: "kilo",
                    transaction: newTransactionId,
                  },
                ]),
              },
            };

            const res = await axios.patch(
              `${apirURL}/weighing/api/updateData/${selectWarehouse.value}`,
              reqBody
            );

            if (res.data) {
              try {
                const reqhistory = {
                  tableName: "weighing_rice_history",
                  fields: {
                    created_at: timestamp,
                    date_in: timestamp,
                    weighing_id: firstWeighingId, // Use the stored firstWeighingId here
                    rice_id: selectRice.value,
                    boat_enter_parking_id: selectedParking.value,
                    weight_in: currentWeight.value,
                    status: true,
                    service_type: "external",
                    weight_drop: remainingWeight.value,
                    warehouse_id: selectWarehouse.value,
                    created_by: authUserId.value,
                    is_hold: true,
                    status: true,
                  },
                };

                const weighing_rice_history = await axios.post(
                  `${apirURL}/weighing/api/insertData`,
                  reqhistory
                );
                console.log("response", reqhistory);
                emit("toast", "update");
                if (weighing_rice_history.data.message) {
                  const reqtohistory = {
                    tableName: "stock_rice_transaction_history",
                    fields: {
                      rice_id: selectRice.value,
                      warehouse_id: selectWarehouse.value,
                      created_at: timestamp,
                      transaction_type: "purchase",
                      updated_by: authUserId.value,
                      created_by: authUserId.value,
                      weight:
                        weighing.value.length > 0
                          ? previousWeight.value - currentWeight.value
                          : 0,
                    },
                  };

                  const stock_rice_history = await axios.post(
                    `${apirURL}/weighing/api/insertData`,
                    reqtohistory
                  );
                  console.log(
                    "reqtohistory",
                    reqtohistory,
                    stock_rice_history.data
                  );
                }
                handleClear();
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    return {
      numberOfWeighings,
      handleClose,

      handleAdd,
      handleCloseTruck,
      currentComponent,
      isOpen,
      isClose,
      validateInputNUmber,
      activeTab,
      setActiveTab,
      getFrom,
      isFrom,
      rices,
      isFilterable,
      suppliers,
      warehouse,
      selectRice,
      selectSupplier,
      selectWarehouse,
      handleSubmit,
      plate_numbers,
      trucks,
      currentWeight,
      weighing,
      clearWeighingData,
      previousWeight,
      validationError,

      remainingWeight,
      parking,
      selectedParking,
      cuteOut,
      SaveAndNew,
      iscurrentWeightt,
      isSupplier,
      getRice,
      history,
      isCutOut,
      handleSendtoWarehouse,
      SendAndNew,
      weighing_time,

      isValidateTruck,
      isValidateSupplier,
      isValidateWarehouse,
      last_weighing_history,
      previousRight,
      // isValidatePreviousWeight,
    };
  },
};
</script>

<style></style>
