<template>
    <div>
        <h2>
            {{ title }}
        </h2>
        <form @submit.prevent="handleWeighing">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputSupplier">Supplier</label>
                    <input v-model="supplier" type="number" class="form-control" id="inputEmail"
                        placeholder="Supplier" />
                </div>
                <div class="form-group col-md-6">
                    <label for="inputParking">Parking</label>
                    <input v-model="parking" type="number" class="form-control" id="inputPassword"
                        placeholder="Parking" />
                </div>
                <div class="form-group col-md-6">
                    <label for="inputParking">Boat Enter Parking</label>
                    <input v-model="boat" type="number" class="form-control" id="inputPassword"
                        placeholder="boat" />
                </div>
                <div class="form-group col-md-6">
                    <label for="inputUsername">Plate Number</label>
                    <input v-model="plate_number" type="text" class="form-control" id="inputUsername"
                        placeholder="plate number" />
                </div>
                <div class="form-group col-md-6">
                    <label for="phone_number">Weigh In</label>
                    <input v-model="weigh_in" type="number" class="form-control" id="inputUsername"
                        placeholder="Weigh IN" />
                </div>
                <div class="form-group col-md-6">
                    <label for="inputRole">Ware house</label>
                    <input v-model="warehouse" type="number" class="form-control" id="inputRole"
                        placeholder="ware house" />
                </div>

                <div class="form-group col-md-6 mt-2">
                    <label for="inputUsername">Service Type</label>
                    <select class="form-select" v-model="serviceType">
                        <option value="Internal">Internal</option>
                        <option value="External">External</option>
                    </select>
                </div>
            </div>
            <button type="submit" class="btn btn-primary mt-2 ml-4">{{ weighing_id == null ? 'Add' : 'Update'
                }}</button>
        </form>
        <table class="table table-hover mt-4">
            <thead>
                <tr>
                    <th scope="col">Plate Number</th>
                    <th scope="col">Weight In</th>
                    <th scope="col">Weight Out</th>
                    <th scope="col">Weight Drop</th>
                    <th scope="col">Date In</th>
                    <th scope="col">Time In</th>
                    <th scope="col">Date Out</th>
                    <th scope="col">Time Out</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-success" v-for="(weighing, index) in weighingData" :key="index">
                    <td rowspan="{{ weighing.history && weighing.history.length ? weighing.history.length : 1 }}">
                        {{ weighing.plate_number }}
                    </td>
                    <td>
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ historyItem.weight_in || weighing.weights_in }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                        <!-- Show weights_in if there is no history -->
                        <span v-if="!weighing.history || !weighing.history.length">
                            {{ weighing.weights_in }}
                        </span>
                    </td>

                    <td v-if="weighing.history && weighing.history.length">
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ historyItem.weight_out }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                    </td>
                    <td v-else></td>

                    <td v-if="weighing.history && weighing.history.length">
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ historyItem.weight_drop }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                    </td>
                    <td v-else></td>

                    <td>
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ formatDate(historyItem.date_in) || weighing.dates_in }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                        <!-- Show weights_in if there is no history -->
                        <span v-if="!weighing.history || !weighing.history.length">
                            {{ formatDate(weighing.dates_in) }}
                        </span>
                    </td>

                    <td>
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ formatTime(historyItem.date_in) || weighing.dates_in }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                        <!-- Show weights_in if there is no history -->
                        <span v-if="!weighing.history || !weighing.history.length">
                            {{ formatTime(weighing.dates_in) }}
                        </span>
                    </td>
                    <td v-if="weighing.history && weighing.history.length">
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ formatDate(historyItem.date_out) }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                    </td>
                    <td v-else></td>
                    <td v-if="weighing.history && weighing.history.length">
                        <span v-for="(historyItem, historyIndex) in weighing.history" :key="historyIndex">
                            {{ formatTime(historyItem.date_out) }}
                            <br v-if="historyIndex !== weighing.history.length - 1" />
                        </span>
                    </td>
                    <td v-else></td>

                    <td>
                        <button class="btn btn-info" style="padding: 10px" @click="handleEdit(weighing)">
                            Edit
                        </button>
                        <button class="btn btn-primary" style="margin-left: 15px; padding: 8px"
                            @click="handleDelete(weighing.weighings_id)">
                            Delete
                        </button>
                    </td>
                    <!-- Add a default cell if there's no history -->
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" @click="goToPage(currentPage - 1)">Previous</a>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click="goToPage(currentPage + 1)">Next</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "axios";
import { fetchTimestamp } from "@/services/timestamp";
import socket from "@/services/socket";
import { formatDate, formatTime } from "@/composables/formatDateTime";
import apirURL from '@/services/apiURL';


export default {
    setup() {
        const supplier = ref(null);
        const parking = ref(null);
        const plate_number = ref("");
        const weigh_in = ref(null);
        const warehouse = ref(null);
        const serviceType = ref("");
        const weighingData = ref([]);
        const currentPage = ref(1);
        const pageSize = ref(1);
        const totalPages = ref(0);
        const weighingHistory = ref([]);
        const boat =ref(null);
        const title = ref("Create New Weighing");



        const handlefetchWeighing = async () => {
            try {
                let apiUrl = `http://localhost:3000/weighing/api/getPaginationwithjoin`;

                const columnAliases = {
                    id: "weighings_id",
                    weight_in: "weights_in",
                    parking_id: "parking_id",
                    plate_number: "plate_number",
                    status: "status",
                    service_type: "service_type",
                    created_at: "created_at",
                    fee: "fee",
                    supplier_id: "supplier_id",
                    warehouse_id: "warehouse_id",
                    trucktor: "trucktor",
                    date_in: "dates_in",
                };

                const params = {
                    tableName: "weighing",
                    columnAliases: JSON.stringify(columnAliases),
                    columnSearch: 'plate_number,service_type',
                    search: "",
                    page: currentPage.value,
                    pageSize: pageSize.value,
                    sortColumn: "weighings_id",
                    sortDirection: "ASC",
                    filterColumn: "",
                    filterValue: "",
                    joinTableName: "",
                    joinColumnChild: "",
                    joinColumnParent: "",
                    joinColumnSearch: '',
                    joinType: "",
                    dynamicConditions: JSON.stringify([
                        //{ field:'plate_number' , operator:'!=' , value:'AC-345' ,typeTable:'table'},
                    ]),
                };

                const response = await axios.get(apiUrl, { params });
                weighingData.value = response.data.data;
                totalPages.value = response.data.pagination.totalPages;

                if (weighingData.value.length > 0) {
                    const allWeighingHistory = [];

                    for (const weighing of weighingData.value) {
                        const weighingId = weighing.weighings_id;

                        const url = `http://localhost:3000/weighing/api/getAllData`;

                        const columnAliasesHistory = {
                            id: "weighing_rice_history_id",
                            rice_id: "rice_id",
                            weighing_id: "weighing_id",
                            weight_out: "weight_out",
                            weight_in: "weight_in",
                            weight_drop: 'weight_drop',
                            date_in: 'date_in',
                            date_out: 'date_out'
                        };

                        const paramsHistory = {
                            tableName: "weighing_rice_history",
                            columnAliases: JSON.stringify(columnAliasesHistory),
                            sortColumn: "weighing_rice_history_id",
                            sortDirection: "ASC",
                            joinTableName: "",
                            joinColumnChild: "",
                            joinColumnParent: "",
                            joinColumnSearch: "",
                            joinType: "",
                            dynamicConditions: JSON.stringify([
                                {
                                    field: "weighing_id",
                                    operator: "=",
                                    value: weighingId,
                                    typeTable: "table",
                                },
                            ]),
                        };

                        const response = await axios.get(url, { params: paramsHistory });

                        if (response.data && response.data.length > 0) {
                            allWeighingHistory.push(response.data);
                        }
                    }

                    let combinedHistory = [].concat(...allWeighingHistory);
                    console.log("combine history", combinedHistory);

                    // Merge allWeighingHistory into weighingData.value
                    weighingData.value = weighingData.value.map((weighing) => ({
                        ...weighing,
                        history: combinedHistory.filter(
                            (w) => w.weighing_id === weighing.weighings_id
                        ),
                    }));

                    console.log("weighing Data", weighingData);
                }
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        };



  




        const fetchAllData = async () => {
            try {
                let url = `http://localhost:3000/weighing/api/getAllData`;

                const columnAliases = {
                    id: "book_id",
                    title: "title",
                };

                const params = {
                    tableName: "book",
                    joinColumnChild: "author_id", 
                    columnAliases: JSON.stringify(columnAliases),
                    sortColumn: "id",
                    sortDirection: "",
                    joinTableName: "author",
                    joinColumnParent: "id", 
                    joinType: "JOIN",
                    dynamicConditions: JSON.stringify([
                  
                        // {
                        //     field: "id",
                        //     operator: "=",
                        //     value: "13",
                        //     typeTable: "table",
                        // },

                        // {
                        //     field: "name",
                        //     operator: "=",
                        //     value: "Dara",
                        //     typeTable: "joinTable",
                        // },

                    ]),
                };

              

           

                const response = await axios.get(url, { params });

                const data = response.data;

                console.log("All data without paginatin", data);



            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchPaginationWithoutJoin = async () => {
            try {
                
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: "users",
          columnSearch: 'username,role,email',
          search: 'value1',
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: 'id',
          sortDirection: 'ASC',
          filterColumn:'',
          filterValue:'',
          dynamicConditions: JSON.stringify([]),
        };

            const response = await axios.get(apiUrl, { params });
      

             console.log("data pagination with search", response.data.data);

            //totalPages.value = response.data.pagination.totalPages;
            } catch (err) {
                console.error("Failed to fetch data", err);
            }
        };



        const searchQuery=ref(null);
        const handleSearchWithoutJoin = async () => {
            try {
              
                    const response = await axios.get(
                        `http://localhost:3000/weighing/api/searchSpecific`,
                        {
                            params: {
                                tableName: "users",
                                columnSearch: "username",
                                valueSearch: searchQuery.value,
                            },
                        }
                    );
                    // users.value = response.data;
                    // console.log("user data by search", users)


                

            } catch (err) {
                console.error("Failed to fetch data", err);
            }
        };

        const fetchDataWithJoinSearch = async () => {
            try {
                // Make a GET request to the endpoint where your function is hosted
                const response = await axios.get(
                    "http://localhost:3000/weighing/api/searchjoin",
                    {
                        params: {
                            tableName: "book2",
                            searchValue: "Stephen",
                            joinTableName: "author",
                            joinSearchColumn: "name",
                            joinColumnChild: "author_id",
                            joinType: "LEFT JOIN",
                            joinColumnParent: "author_id",
                        },
                    }
                );


                //console.log('Data fetched successfully search join:', response.data);
            } catch (error) {
                // Handle errors
                console.log("Failed to fetch data:", error);
            }
        };



        const fetchDataByID = async () => {
            try {
                const tableName = ref("weighing");
                const id = ref(15);
                let url = `http://localhost:3000/weighing/api/getDataByID/${tableName.value}/${id.value}`;
                const response = await axios.get(url);

                const data = response.data;

                console.log("data by id", data);

            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const weighing_id = ref(null);
        const oldWeight_in = ref(null);
        const oldDate_in = ref(null);
        const handleEdit = (doc) => {
            weigh_in.value = doc.weights_in;
            weighing_id.value = doc.weighings_id;
            boat.value=doc.boat_enter_parking_id;


            oldDate_in.value = doc.dates_in
            // console.log("old date in", oldDate_in);
            oldWeight_in.value = doc.weights_in;
            // console.log("old weight in", oldWeight_in);
            title.value = "Edit Weighing ";
        };


        

        const handleWeighing = async () => {
            try {
                if (title.value === "Create New Weighing") {
                    const timestamp = await fetchTimestamp();
                    const requestBody = {
                        tableName: "weighing",
                        fields: {
                            supplier_id: supplier.value,
                            parking_id: parking.value,
                            plate_number: plate_number.value,
                            weight_in: weigh_in.value,
                            rice_id: 2,
                            date_in: timestamp,
                            boat_enter_parking_id:boat.value,
                            status: false,
                            fee: null,
                            service_type: serviceType.value,
                            created_at: timestamp,
                        },
                    };

                    const response = await axios.post(
                        "http://localhost:3000/weighing/api/insertData",
                        requestBody
                    );

                    console.log(response.data.message, response.data.insertedId);
                } else {
                    const timestamp = await fetchTimestamp();
                    const requestBody = {
                        tableName: "weighing",
                        fields: {
                            weight_in: weigh_in.value,
                            rice_id: 2,
                            boat_enter_parking_id:boat.value,
                            date_in: timestamp,
                            updated_at:timestamp
                        },
                    };

                    const response = await axios.patch(
                        `http://localhost:3000/weighing/api/updateData/${weighing_id.value}`,
                        requestBody,

                    );

                    if (response) {
                        // const timestamp = await fetchTimestamp();
                        // // insert to weighing_rice_history
                        // const requestBody = {
                        //     tableName: "weighing_rice_history",
                        //     fields: {
                        //         weighing_id: weighing_id.value,
                        //         weight_in: Number(oldWeight_in.value),
                        //         weight_out: weigh_in.value,
                        //         weight_drop: Number(oldWeight_in.value) - weigh_in.value,
                        //         date_in: oldDate_in.value,
                        //         date_out: timestamp,
                        //         created_at: timestamp,
                        //     },
                        // };

                        // const response = await axios.post(
                        //     "http://localhost:3000/weighing/api/insertData",
                        //     requestBody
                        // );

                        console.log("insert to weighing _rice _history ", response);
                    }
                }
            } catch (err) {
                console.log("failed to add weighing", err);
            }
        };

        const updateJSONBSpecific = async () => {
      try {
        const requestBody = {
          tableName: "users",
          id: 6,
          columnNameJSONB: "assign_task",
          keyToUpdateForCondition: "currency",
          valueKeyToUpdateCondition: 3,
          keyToUpdate: "name",
          valueToUpdate: JSON.stringify("unya"),
        };

        // Make a POST request to your backend endpoint
        const response = await axios.patch(
          "http://localhost:3000/weighing/api/updateDataJSONB",
          requestBody
        );

        // Handle the response if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error updating record:", error);
      }
    };





        const handleDelete = async (id) => {
            try {
                const response = await axios.delete(
                    `http://localhost:3000/weighing/api/deleteData/${id}`,
                    {
                        data: { tableName: "weighing" },
                    }
                );

                console.log("delete success", response);
            } catch (err) {
                console.log("failed to delete");
            }
        };

        const goToPage = async (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                await handlefetchWeighing();
            }
        };

        // Socket event handler for database updates
        const handleDatabaseUpdate = () => {
            handlefetchWeighing(); // Call fetchUsers when database updates occur
        };





        onMounted(() => {
            socket.on("database_realTime", handleDatabaseUpdate);
            handlefetchWeighing();
            fetchAllData();
           //fetchDataWithJoinSearch();
            fetchPaginationWithoutJoin();
            fetchDataByID();
     
            handleSearchWithoutJoin();
        });


        //         const formatDate = (timestamp) => {
        //     return moment(timestamp).format("DD-MMM-YYYY");
        // };


        // const formatTime = (timestamp) => {
        //     return moment(timestamp).format("HH:mm:ss A");
        // };

        return {
         
            boat,
            weighing_id,
            formatTime,
            formatDate,
            handleDelete,
            handleEdit,
            title,
            goToPage,
            currentPage,
            pageSize,
            totalPages,
            weighingHistory,
            weighingData,
            supplier,
            parking,
            plate_number,
            weigh_in,
            warehouse,
            handleWeighing,
            serviceType,
        };
    },
};
</script>

<style></style>