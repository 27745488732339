<template>
  <div class="w-full">
    <div class="flex">
      <div
        :class="{
          ' tab2 cursor-pointer': true,
          'text-primary': activeTab === 'saleOrder',
        }"
        @click="setActiveTab('saleOrder')"
      >
        From Truck
      </div>
      <div
        :class="{
          ' tab2 cursor-pointer': true,
          'text-primary': activeTab === 'purchase',
        }"
        @click="setActiveTab('purchase')"
      >
        To Parking
      </div>
    </div>
    <div v-if="activeTab === 'saleOrder'">
      <!-- Content for Sale Order tab -->
      <FromeTruckTransitionVue />
    </div>
    <div v-if="activeTab === 'purchase'">
      <ToParkingTransitionVue />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import FromeTruckTransitionVue from "./FromeTruckTransition.vue";
import ToParkingTransitionVue from "./ToParkingTransition.vue";
export default {
  components: {
    FromeTruckTransitionVue,
    ToParkingTransitionVue,
  },
  setup() {
    const activeTab = ref("saleOrder");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
/* .tab2 {
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  */
.text-primary {
  color: #168af4; /* Use your primary text color */
}
</style>
