<template>
  <div class="w-full relative text-primary6">
    <div class="filter_type -mt-0.5">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Client</label
        >
        <el-select
          v-model="value"
          placeholder="Client"
          size="large"
          style="width: 240px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Truck</label
        >
        <el-select
          v-model="value"
          placeholder="Truck"
          size="large"
          style="width: 240px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Rice</label
        >
        <el-select
          v-model="value"
          placeholder="Rice"
          size="large"
          style="width: 240px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button size="large">Filter</el-button>
      </div>
    </div>
    <div class="w-full flex items-center justify-between py-3">
      <div>
        <h2>List Truck</h2>
      </div>
    </div>

    <div class="flex items-start w-full gap-4">
      <div>
        <div :class="mainClassesTable" class="h-[500px]">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="min-w-[70px] bg-white border-b text-start">
                  No
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Client
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Truck
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2 bg-white"
                v-for="data in 10"
                :key="data"
              >
                <td class="border-b">Silver</td>
                <td class="border-b">$2999</td>
                <td class="border-b">$2999</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full bg-white flex items-center justify-center py-2">
          <el-pagination layout="prev, pager, next" :total="50" />
        </div>
      </div>
      <div>
        <div :class="subClassesTable" class="h-[500px]">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td colspan="9" class="bg-white border-b sticky top-0">
                  <div class="text-start flex items-center justify-between">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3">
                        <h2>Status</h2>
                        <el-button type="warning" round>Processing</el-button>
                      </div>
                      <div class="flex items-center gap-3">
                        <h2>Total Weight</h2>
                        <span class="text-primary2 font-semibold">--- Kg</span>
                      </div>
                    </div>
                    <div class="flex items-center gap-3">
                      <button class="btncancel border" @click="handleAdd">
                        Preview
                      </button>
                      <button @click="open">Mark Complete</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="min-w-[70px] bg-white border-b text-start">
                  Action
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Created At
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Rice Type
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Weigh in
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[70px] bg-white border-b text-start">
                  Weigh out
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[70px] bg-white border-b text-start">
                  Weigh
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Cut out
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
                <td class="min-w-[100px] bg-white border-b text-start">
                  Remain
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2 bg-white"
                v-for="data in 30"
                @click="handlePopUpDeltail"
                :key="data"
              >
                <td class="border-b">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-primary4 duration-300 active:text-primary4/50 hover:text-primary/80"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </td>
                <td class="border-b">$2999</td>
                <td class="border-b">$2999</td>
                <td class="border-b">Silver</td>
                <td class="border-b">Silver</td>
                <td class="border-b">$2999</td>
                <td class="border-b">$2999</td>
                <td class="border-b">Silver</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-full flex items-center justify-between py-3 border-t bg-white"
        >
          <div class="">
            <div class="demo-pagination-block flex items-center gap-2">
              <div class="demonstration">Total of 400 items</div>
              <el-select
                v-model="value"
                placeholder="Select"
                style="width: 120px"
              >
                <el-option v-for="item in 5" :key="item" />
              </el-select>
            </div>
          </div>
          <div class="demo-pagination-block">
            <el-pagination
              v-model:current-page="currentPage3"
              v-model:page-size="pageSize3"
              :size="size"
              :disabled="disabled"
              :background="background"
              layout="prev, pager, next, jumper"
              :total="1000"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetailsPopup" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import FinanceBillGenerate from "@/form/FinanceBillGenerate.vue";
import BillPreviewVue from "@/form/BillPreview.vue";
import TruckDetails from "./TruckDetails.vue";

export default {
  components: {
    BillPreviewVue,
    FinanceBillGenerate,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    TruckDetails,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);

    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "TruckDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "TruckDetails";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const open = () => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed ",
        message: h("p", null, [
          h(
            "span",
            null,
            "Are you sure wanting to mark the Boat [Boat Name] as Completed"
          ),
          //   h("i", { style: "color: teal" }, "VNode"),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      })
        .then((action) => {
          ElMessage({
            type: "info",
            message: `action: ${action}`,
          });
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };
    const isDetailsPopup = ref(false);
    const handlePopUpDeltail = () => {
      isDetailsPopup.value = true;
      currentComponent.value = "TruckDetails";
    };
    const handleCloseDetails = () => {
      isDetailsPopup.value = false;
      currentComponent.value = "";
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      handlePopUpDeltail,
      isDetailsPopup,
      handleCloseDetails,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
    };
  },
};
</script>

<style></style>
