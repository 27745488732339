<template>
  <form class="w-[800px] h-auto bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">Generate Label Slip</h2>
    </div>
    <div
      class="flex items-center h-[85px] justify-between bg-primary2 text-primary text-heading2"
    >
      <div
        class="flex flex-col items-start justify-center px-2 text-start w-[55%] bg-primary6 p-1 h-full text-[12px]"
      >
        <div class="flex justify-between w-full">
          <p>Total Rice Weight</p>
          <p>--- T</p>
        </div>
        <div class="flex justify-between w-full">
          <p>Cost/t</p>
          <p>--- KHR</p>
        </div>
      </div>
      <div class="w-full text-start">
        <h2 class="text-default text-body font-bold ml-4">To Be Paid:</h2>
        <h2 class="text-default text-heading1 font-bold ml-4">100,000 KHR</h2>
      </div>
    </div>
    <div class="p-4 grid grid-cols-2">
      <div class="">
        <div class="py-2 border-primary6">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>Cell data</h2>
          </div>
        </div>
      </div>
      <div class="">
        <div class="py-2 border-primary6">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Client</h2>
            <h2>Cell data</h2>
          </div>
        </div>
      </div>
      <div class="">
        <div class="py-2 border-primary6">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Phone Number</h2>
            <h2>Cell data</h2>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="text-start ml-4">
        <h2>Rice Detail</h2>
      </div>
      <div class="p-4">
        <div class="relative overflow-x-auto mt-4 border">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="min-w-[70px] text-nowrap border-b text-start">No</td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Created At
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Truck
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Total Rice Weigh(Ton)
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2 text-nowrap"
                v-for="data in 3"
                :key="data"
              >
                <td class="border-b">Silver</td>
                <td class="border-b">$2999</td>
                <td class="border-b">$2999</td>
                <td class="border-b">$2999</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body font-normal text-primary2">
                <td colspan="4">
                  <div class="flex items-center justify-center gap-3 font-bold">
                    <h2>Total</h2>
                    <h2>{{ numeralFormat(10000) }}</h2>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="p-5 w-[70%] mx-auto space-y-2 flex flex-col items-end">
      <div class="flex items-center gap-3">
        <label for="name" class="flex gap-1 text-nowrap">
          <span class="text-red-500">*</span> Expense per ton</label
        >
        <input
          required
          type="number"
          min="1"
          step="0.01"
          placeholder="Enter Amount"
          @keydown="validateInputNUmber"
        />
        <el-select
          v-model="value"
          placeholder="KHR"
          size="large"
          style="width: 200px"
        >
          <el-option key="KHR" label="KHR" value="KHR" />
        </el-select>
      </div>
      <div class="flex items-start gap-3">
        <label for="Description">Note</label>
        <textarea
          type="text"
          placeholder="Any Description"
          rows="4"
          class="w-[340px] px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
        />
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex w-full items-center gap-3">
        <button @click="handleToPrint">Print</button>
        <button class="btncancel">Save & Print</button>
        <button class="btncancel" @click="handleClose">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { validateInputNUmber } from "@/composables/ValidateInput";
export default {
  props: [""],
  setup(props, { emit }) {
    const router = useRouter();
    const handleToPrint = () => {
      router.push({ name: "printweighingservice" });
    };
    const handleClose = () => {
      emit("close");
    };
    return {
      handleClose,
      handleToPrint,
      validateInputNUmber,
    };
  },
};
</script>

<style></style>
