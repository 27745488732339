
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/css/style.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import PrimeVue from 'primevue/config';
import Material from '@primevue/themes/material';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import DatePicker from 'primevue/datepicker';
import Checkbox from 'primevue/checkbox';
import 'vue-material-design-icons/styles.css';
import SvgIcon from '@jamescoyle/vue-icon';



import Select from 'primevue/select';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAlignLeft, faArrowRotateLeft, faBook, faCaretDown, faCaretUp, faFile, faFileExcel, faLongArrowAltRight, faPaperPlane, faRoad, faSort, faSortUp, faSortDown, faWeightScale, faBox, faMoneyBill, faWallet, faFileInvoice, faAddressBook, faWarehouse, faChartGantt, faGear, faPlay, faBars, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import i18n from './i18n'; // Import the i18n configuration
// import VueApexCharts from "vue3-apexcharts";
import 'primeicons/primeicons.css'

import VueNumerals from 'vue-numerals';




// import the necessary css file



const app = createApp(App)
library.add(faAlignLeft, faSort, faFileExcel, faFile, faCaretUp, faCaretDown, faPaperPlane, faLongArrowAltRight, faWeightScale, faRoad, faBox, faMoneyBill, faWallet, faFileInvoice, faAddressBook, faWarehouse, faChartGantt, faGear, faPlay, faBars, faSortUp, faSortDown, faArrowDown, faArrowUp)
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(ElementPlus);
app.use(PrimeVue, {
    theme: {
        preset: Material,
        options: {
            darkModeSelector: '.app-dark',
        }
    }
});
app.component('Message', Message);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('Select', Select);
app.component('InputText', InputText);
app.component('DatePicker', DatePicker);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Checkbox', Checkbox);
app.component('SvgIcon', SvgIcon);
app.use(i18n);
app.use(VueNumerals)
app.use(VueNumerals, {
    locale: 'en'
});
app.use(router).mount('#app')
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
// }