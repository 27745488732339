<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-auto h-auto bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ isEdit ? "Update Wallet " : "New Wallet" }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full max-w-2xl mx-auto p-5 space-y-4">
      <div class="grid grid-cols-[200px_1fr] gap-y-2 gap-x-4">
        <label for="walletNo" class="flex gap-1 items-start pt-1.5">
          <span class="text-red-500">*</span> Wallet No
        </label>
        <div class="min-h-[42px]">
          <input
            id="walletNo"
            v-model="walletNo"
            type="text"
            placeholder="Wallet No"
            class="w-full"
          />
          <Message severity="error" class="text-body2 mt-1" v-if="isWalletNo"
            >Wallet No is required</Message
          >
        </div>

        <label for="name" class="flex gap-1 items-start pt-1.5">
          <span class="text-red-500">*</span> Partner Name
        </label>
        <div class="min-h-[42px]">
          <input
            id="name"
            type="text"
            placeholder="Partner Name"
            v-model="name"
            class="w-full"
          />
          <Message severity="error" class="text-body2 mt-1" v-if="isPartnerName"
            >Partner Name is required</Message
          >
        </div>

        <label for="phone" class="flex gap-1 items-start pt-1.5">
          <span class="text-red-500">*</span> Phone Number
        </label>
        <div class="min-h-[42px]">
          <input
            id="phone"
            v-model="phone_number"
            maxlength="11"
            @input="formatPhoneNumber"
            type="text"
            placeholder="Phone Number"
            class="w-full"
            :disabled="isPartnerAssociated"
          />
          <Message severity="error" class="text-body2 mt-1" v-if="isPhoneNumber"
            >Phone Number is required</Message
          >
        </div>
      </div>

      <div class="flex w-full items-center gap-3">
        <button type="submit" v-if="isAdding === true" :disabled="isAdding">
          Saving
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="white"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path></svg
          ></span>
        </button>
        <button v-else>{{ isUpdate ? "Update" : "Save" }}</button>
        <button
          type="button"
          class="btncancel"
          @click="SaveAndAddNew"
          :disabled="isUpdatig"
          v-if="isUpdatig === true"
        >
          Saving
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="#168AF4"
                stroke-dasharray="15"
                stroke-dashoffset="15"
                stroke-linecap="round"
                stroke-width="2.25"
                d="M12 3C16.9706 3 21 7.02944 21 12"
              >
                <animate
                  fill="freeze"
                  attributeName="stroke-dashoffset"
                  dur="0.3s"
                  values="15;0"
                />
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                />
              </path>
            </svg>
          </span>
        </button>
        <button
          v-else
          @click="SaveAndAddNew"
          :disabled="isUpdatig"
          type="button"
          class="btncancel"
        >
          {{ isUpdate ? "Update & New" : "Save & New" }}
        </button>
        <button type="button" class="btncancel" @click="handleClose">
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import apirURL from "@/services/apiURL";
import axios from "axios";

import Toast from "@/components/Toast.vue";
import { formatPhoneNumber } from "@/composables/ValidateInput";
export default {
  components: {
    Toast,
  },
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const status = ref(true);
    const name = ref("");
    const phone_number = ref("");
    const walletNo = ref("");
    const currencies = ref([]);
    const isAdding = ref(false);
    const isUpdatig = ref(false);
    const isWalletNo = ref(false);
    const isPhoneNumber = ref(false);
    const isPartnerName = ref(false);

    const handleClear = () => {
      name.value = "";
      walletNo.value = "";
      phone_number.value = "";
      isWalletNo.value = false;
      isPartnerName.value = false;
      isPhoneNumber.value = false;
    };

    const fetchCurence = async () => {
      try {
        const req = {
          tableName: "currencies",
          sortColumn: "id",
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        currencies.value = res.data;
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = async () => {
      isAdding.value = true;
      try {
        let wallet = [
          {
            KH: {
              preloan: {
                original_partner_owe: [], //sign - // note [{"preloan_id":"","amount":}]
              },

              customer_payment: {
                original_partner_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
              },

              supplier_payment: {
                original_partner_owe: [], //sign - // note [{"invoice_id":"","amount":}]
              },

              preloan_repayment: {
                original_partner_owe: [], // sign -  // note [{"preloan_repayment_id":"","amount":}]
              },

              exchange_balance: {
                original_owe_partner: [], //sign -
                original_partner_owe: [], // sign +     // note [{"exchange_id":"","amount":}]
              },
              deposit: {
                original_partner_owe: [], //sign +   // note [{"deposit_id":"","amount":}]
              },
              withdrawal: {
                original_partner_owe: [], // sign -  // note [{"withdrawal_id":"","amount":}]
              },
            },

            VN: {
              customer_payment: {
                original_partner_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
              },
              exchange_balance: {
                original_owe_partner: [], // sign - // note stand for from wallet the we exchange
                original_partner_owe: [], // sign +   // note [{"exchange_id":"","amount":}]
              },
              deposit: {
                original_partner_owe: [], // sign +   // note [{"deposit_id":"","amount":}]
              },
              withdrawal: {
                original_partner_owe: [], // sign -  // note [{"withdrawal_id":"","amount":}]
              },
            },
          },
        ];
        const timestamp = await fetchTimestamp();

        if (!walletNo.value) {
          isWalletNo.value = true;
          isAdding.value = false;
          return;
        } else if (!name.value) {
          isPartnerName.value = true;
          isAdding.value = false;
          return;
        } else if (!phone_number.value) {
          isPhoneNumber.value = true;
          isAdding.value = false;
          return;
        } else {
          if (props.datatoedit && props.datatoedit.id) {
            const requestBody = {
              tableName: "partners",
              fields: {
                name: name.value,
                name_lowercase: name.value.toLowerCase(),
                phone_number: phone_number.value,
                status: status.value,
                wallet_number: walletNo.value,
                created_at: timestamp,
              },
            };

            await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              requestBody
            );

            handleClose();
            emit("toast", "update");
          } else {
            const requestBody = {
              tableName: "partners",
              fields: {
                name: name.value,
                name_lowercase: name.value.toLowerCase(),
                phone_number: phone_number.value,
                status: status.value,
                wallet: JSON.stringify(wallet),
                wallet_number: walletNo.value,
                created_at: timestamp,
              },
            };

            await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);
            handleClose();
            emit("toast", "create");

            console.log(response.data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    watch(walletNo, (newV) => {
      if (newV) {
        isWalletNo.value = false;
      }
    });

    watch(name, (newV) => {
      if (newV) {
        isPartnerName.value = false;
      }
    });

    watch(phone_number, (newV) => {
      if (newV) {
        isPhoneNumber.value = false;
      }
    });

    const isEdit = props.datatoedit;

    // add new
    const SaveAndAddNew = async () => {
      setTimeout(() => {
        isUpdatig.value = true;
        setTimeout(() => {
          isUpdatig.value = false;
        }, 1000);
      }, 0);
      try {
        if (!walletNo.value) {
          isWalletNo.value = true;
          return;
        } else if (!name.value) {
          isPartnerName.value = true;
          return;
        } else if (!phone_number.value) {
          isPhoneNumber.value = true;
          return;
        } else {
          let wallet = [
            {
              KH: {
                preloan: {
                  original_partner_owe: [], //sign - // note [{"preloan_id":"","amount":}]
                },

                customer_payment: {
                  original_partner_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
                },

                supplier_payment: {
                  original_partner_owe: [], //sign - // note [{"invoice_id":"","amount":}]
                },

                preloan_repayment: {
                  original_partner_owe: [], // sign -  // note [{"preloan_repayment_id":"","amount":}]
                },

                exchange_balance: {
                  original_owe_partner: [], //sign -
                  original_partner_owe: [], // sign +     // note [{"exchange_id":"","amount":}]
                },
                deposit: {
                  original_partner_owe: [], //sign +   // note [{"deposit_id":"","amount":}]
                },
                withdrawal: {
                  original_partner_owe: [], // sign -  // note [{"withdrawal_id":"","amount":}]
                },
              },

              VN: {
                customer_payment: {
                  original_partner_owe: [], // sign +  // note [{"invoice_id":"","amount":}]
                },
                exchange_balance: {
                  original_owe_partner: [], // sign - // note stand for from wallet the we exchange
                  original_partner_owe: [], // sign +   // note [{"exchange_id":"","amount":}]
                },
                deposit: {
                  original_partner_owe: [], // sign +   // note [{"deposit_id":"","amount":}]
                },
                withdrawal: {
                  original_partner_owe: [], // sign -  // note [{"withdrawal_id":"","amount":}]
                },
              },
            },
          ];
          const timestamp = await fetchTimestamp();
          const requestBody = {
            tableName: "partners",
            fields: {
              name: name.value,
              name_lowercase: name.value.toLowerCase(),
              phone_number: phone_number.value,
              status: status.value,
              wallet: JSON.stringify(wallet),
              wallet_number: walletNo.value,
              created_at: timestamp,
            },
          };
          if (props.datatoedit && props.datatoedit.id) {
            await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              requestBody
            );

            emit("toast", "update");

            handleClear();
          } else {
            await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);
            emit("toast", "create");
            handleClear();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Improved isPartnerAssociated function with computed property
    const isPartnerAssociated = computed(() => {
      if (!props.datatoedit?.wallet) return false;

      const walletData = props.datatoedit.wallet[0];
      const khData = walletData.KH;
      const vnData = walletData.VN;

      // Check KH transactions
      const hasKHTransactions = [
        khData.preloan.original_partner_owe,
        khData.customer_payment.original_partner_owe,
        khData.supplier_payment.original_partner_owe,
        khData.preloan_repayment.original_partner_owe,
        khData.exchange_balance.original_partner_owe,
        khData.exchange_balance.original_owe_partner,
        khData.deposit.original_partner_owe,
        khData.withdrawal.original_partner_owe,
      ].some((arr) => arr.length > 0); //use some to check if any array in the array is not empty

      // Check VN transactions
      const hasVNTransactions = [
        vnData.customer_payment.original_partner_owe,
        vnData.exchange_balance.original_partner_owe,
        vnData.exchange_balance.original_owe_partner,
        vnData.deposit.original_partner_owe,
        vnData.withdrawal.original_partner_owe,
      ].some((arr) => arr.length > 0);

      return hasKHTransactions || hasVNTransactions;
    });

    const wallet = ref([]);
    onMounted(async () => {
      await fetchCurence();
      if (props.datatoedit) {
        name.value = props.datatoedit.name;
        phone_number.value = props.datatoedit.phone_number;
        status.value = props.datatoedit.status;
        walletNo.value = props.datatoedit.wallet_number;
      }
    });

    return {
      isPartnerAssociated,
      wallet,
      formatPhoneNumber,
      isWalletNo,
      isPartnerName,
      isPhoneNumber,
      handleClose,
      currencies,
      handleSubmit,
      name,
      phone_number,
      walletNo,
      status,
      isEdit,
      SaveAndAddNew,
      isAdding,
      isUpdatig,
    };
  },
};
</script>

<style></style>
