<template>
  <div class="w-auto overflow-auto">
    <!-- Header and Close Button -->
    <div class="details_title">
      <h2 class="ml-4 text-nowrap">Wallet Detail</h2>
      <div class="flex items-center gap-2">
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- Wallet Details -->
    <div class="w-full">
      <div class="p-4 flex items-start gap-3">
        <div class="bg-white p-4 mt-4 gap-3 w-full space-y-3">
          <div class="flex items-center justify-between">
            <h2 class="ml-4 text-nowrap">
              Wallet’s No:
              <span class="font-semibold">
                {{ datatoedit?.wallet_number }}
              </span>
            </h2>
            <div class="mr-4">
              <h2 class="ml-4 text-nowrap">
                Partner:
                <span class="font-semibold"> {{ datatoedit?.name }} </span>
              </h2>
            </div>
          </div>
          <!-- Balance Display -->
          <div
            class="border border-primary flex py-2 rounded p-2 justify-between"
          >
            <div>
              <h2>Balance (KHR)</h2>
              <h2>Balance (VND)</h2>
            </div>
            <div class="font-semibold text-end">
              <h2>
                <span class="text-red-500">{{
                  numeralFormat(
                    calculateBalance(datatoedit.wallet[0], "KH"),
                    "0,0[.]00"
                  )
                }}</span>

                KHR
              </h2>
              <h2>
                <span class="text-red-500">
                  {{
                    numeralFormat(
                      calculateBalance(datatoedit.wallet[0], "VN"),
                      "0,0[.]00"
                    )
                  }}
                </span>
                VND
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transactions Table -->
    <div class="p-5">
      <h2 class="py-2 text-start">Wallet Transactions</h2>
      <div class="relative w-full overflow-x-auto mt-4 border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">Created At</td>
              <td class="min-w-[126px] border-b">
                <div class="flex items-center justify-start gap-3">
                  <h2>Transaction Id</h2>
                </div>
              </td>
              <td class="min-w-[100px] border-b text-start">Partner</td>
              <td class="min-w-[70px] border-b text-start">Wallet</td>
              <td class="min-w-[100px] border-b text-start">
                Transaction Type
              </td>
              <td class="min-w-[70px] border-b text-start">Amount</td>
              <td class="border text-start">Action</td>
            </tr>
          </tbody>
          <tbody v-if="filteredWalletTransactions.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in filteredWalletTransactions"
              :key="data.id"
            >
              <!-- Date Column -->
              <td class="border-b">
                {{ formatDate2(data.created_at) }}
              </td>

              <!-- ID Column -->
              <td class="border-b">{{ data.id }}</td>

              <!-- Name Column -->
              <td class="border-b">{{ datatoedit.name }}</td>

              <!-- Wallet Column -->
              <td class="border-b">
                <template v-if="data.transaction_type === 'exchange_balance'">
                  {{ data.from_wallet }} → {{ data.to_wallet }}
                </template>
                <template v-else>
                  {{ data.wallet }}
                </template>
              </td>

              <!-- Transaction Type Buttons -->
              <td class="border-b">
                <template v-if="data.transaction_type === 'deposit'">
                  <div
                    class="bg-primary3 text-white p-1 text-center rounded-2xl"
                  >
                    Deposit
                  </div>
                </template>
                <template v-else-if="data.transaction_type === 'withdrawal'">
                  <div class="bg-main text-white p-1 text-center rounded-2xl">
                    Drawing
                  </div>
                </template>
                <template
                  v-else-if="data.transaction_type === 'exchange_balance'"
                >
                  <div
                    class="bg-blue-700 text-white p-1 text-center rounded-2xl"
                  >
                    Exchange
                  </div>
                </template>
                <template
                  v-else-if="data.transaction_type === 'customer payment'"
                >
                  <div
                    class="bg-primary8 text-white p-1 text-center rounded-2xl"
                  >
                    Customer Repayment
                  </div>
                </template>
                <template
                  v-else-if="data.transaction_type === 'supplier payment'"
                >
                  <div
                    class="bg-primary4 text-white p-1 text-center rounded-2xl"
                  >
                    Supplier Repayment
                  </div>
                </template>
                <template v-else-if="data.transaction_type === 'preloan'">
                  <div
                    class="bg-blue-700 text-white p-1 text-center rounded-2xl"
                  >
                    Preloan
                  </div>
                </template>
              </td>

              <!-- Amount Column -->
              <td
                class="border-b font-semibold text-red-500"
                :class="{
                  'text-red-500':
                    data.transaction_type === 'deposit' ||
                    data.transaction_type === 'withdrawal',
                  'text-red-500': data.transaction_type === 'exchange_balance',
                }"
              >
                <template
                  v-if="
                    data.transaction_type === 'deposit' ||
                    data.transaction_type === 'withdrawal'
                  "
                >
                  {{
                    data.transaction_type === "deposit"
                      ? numeralFormat(data.amount, "0,0[.]00")
                      : numeralFormat(-data.amount, "0,0[.]00")
                  }}
                  {{
                    data.wallet === "KHR"
                      ? "៛"
                      : data.wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
                <template v-if="data.transaction_type === 'exchange_balance'">
                  {{ numeralFormat(data.amount_after_exchange, "0,0[.]00") }}
                  {{
                    data.from_wallet === "KHR"
                      ? "៛"
                      : data.from_wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
                <template v-if="data.transaction_type === 'customer payment'">
                  {{ numeralFormat(data.amount, "0,0[.]00") }}
                  {{
                    data.wallet === "KHR"
                      ? "៛"
                      : data.wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
                <template v-if="data.transaction_type === 'supplier payment'">
                  {{ numeralFormat(data.amount, "0,0[.]00") }}
                  {{
                    data.wallet === "KHR"
                      ? "៛"
                      : data.wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
                <template v-if="data.transaction_type === 'preloan'">
                  {{ numeralFormat(data.amount, "0,0[.]00") }}
                  {{
                    data.wallet === "KHR"
                      ? "៛"
                      : data.wallet === "VND"
                      ? "đ"
                      : ""
                  }}
                </template>
              </td>
              <td class="border">
                <svg-icon
                  @click="openDeleteConfirmation(data)"
                  :class="
                    data.id !== lastTransaction[0]?.id
                      ? 'text-slate-400'
                      : 'delete'
                  "
                  type="mdi"
                  :path="mdiTrashCanOutline"
                />
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="6">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
  <Toast ref="toast" />
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import { mdiTrashCanOutline } from "@mdi/js";
import { remove } from "@/composables/usePartnerTransaction";
import DeleteConfrimation from "./DeleteConfrimation.vue";
import Toast from "@/components/Toast.vue";
export default {
  components: {
    Pagination,
    NoDataComponet,
    DeleteConfrimation,
    Toast,
  },
  props: ["datatoedit", "calculateBalance"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    // State variables
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const walletTransactionData = ref([]);
    const selectedTransactionType = ref(null);
    const selectedDateRange = ref(null);
    const appliedTransactionType = ref(null);
    const appliedDateRange = ref(null);
    const transactionTypes = ref([
      // { value: "deposit", label: "Deposit" },
      // { value: "withdrawal", label: "Withdrawal" },
      // { value: "customer_payment", label: "Customer Payment" },
      // { value: "supplier_payment", label: "Supplier Payment" },
      // { value: "preloan", label: "Preloan" },
      // { value: "preloan_repayment", label: "Preloan Repayment" },
      // { value: "exchange", label: "Exchange Balance" },
    ]);
    // delete
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);

    const openDeleteConfirmation = (doc) => {
      if (lastTransaction.value[0].id !== doc.id) {
        showSuccessToast("cannotdelete");
      } else {
        deletedata.value = doc;
        isDeleteOpen.value = true;
      }
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const response = await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.id}`,
          {
            data: { tableName: table.value },
          }
        );

        if (response.status === 200) {
          //update partner wallet transaction
          remove(deletedata.value);

          if (
            walletTransactionData.value.length <= 1 &&
            currentPage.value > 1
          ) {
            handlePageChange(currentPage.value - 1);
          } else if (currentPage.value === 1) {
            await fetchWalletTransaction();
          }

          closeDeleteDialog();
          deletedata.value = null;
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    const tableName = ref("");

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          case "amount":
            message = "Please Enter the amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const table = ref("partner_transaction_history");
    const fetchWalletTransaction = async () => {
      const params = {
        tableName: table.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: "id",
        sortDirection: "DESC",
        dynamicConditions: JSON.stringify([
          {
            field: "partner_id",
            operator: "=",
            value: props.datatoedit.id,
          },
        ]),
      };
      const response = await axios.get(
        `${apirURL}/weighing/api/getPagination`,
        { params }
      );
      walletTransactionData.value = response.data.data;
      totalPages.value = response.data.pagination.totalPages;
    };
    const lastTransaction = ref([]);
    const fetchWalletTransactionLimit = async () => {
      const params = {
        tableName: table.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: "id",
        sortDirection: "DESC",
        limit: 1,
        dynamicConditions: JSON.stringify([
          {
            field: "partner_id",
            operator: "=",
            value: props.datatoedit.id,
          },
        ]),
      };
      const response = await axios.get(
        `${apirURL}/weighing/api/getPagination`,
        { params }
      );
      lastTransaction.value = response.data.data;
    };
    // Computed property to filter transactions
    const filteredWalletTransactions = computed(() => {
      let filteredData = walletTransactionData.value;

      // Apply filter by transaction type
      if (appliedTransactionType.value) {
        filteredData = filteredData.filter(
          (transaction) =>
            transaction.transaction_type === appliedTransactionType.value
        );
      }

      // Apply filter by date range
      if (appliedDateRange.value) {
        const [start, end] = appliedDateRange.value;
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Adjust endDate to include the entire end day
        endDate.setHours(23, 59, 59, 999);

        filteredData = filteredData.filter((transaction) => {
          const createdAt = new Date(transaction.created_at);
          return createdAt >= startDate && createdAt <= endDate;
        });
      }

      return filteredData;
    });

    const resetFilters = () => {
      selectedTransactionType.value = null;
      selectedDateRange.value = null;
      appliedTransactionType.value = null;
      appliedDateRange.value = null;
      fetchWalletTransaction();
    };

    const handlePageChange = (newPage) => {
      currentPage.value = newPage;
      fetchWalletTransaction();
    };

    const handlePageSizeChange = (newPageSize) => {
      pageSize.value = newPageSize;
      fetchWalletTransaction();
    };

    const applyFilters = () => {
      // Apply the selected filters
      appliedTransactionType.value = selectedTransactionType.value;
      appliedDateRange.value = selectedDateRange.value;
    };

    onMounted(async () => {
      await fetchWalletTransaction();
      await fetchWalletTransactionLimit();
      tableName.value = "partner_transaction_history";
    });
    const sortIdOfTranSaction = (feild, sortDirection) => {
      if (feild === "name") {
        toggleSort(sortDirection, walletTransactionData.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, walletTransactionData.value, feild);
      }
    };
    const shortcuts = [
      {
        text: "Today",
        value() {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[0].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Month",
        value() {
          const start = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          const end = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          );
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[1].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "This Week",
        value() {
          const today = new Date();
          const startOfWeek = new Date(
            today.setDate(today.getDate() - today.getDay())
          ); // Sunday
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date();
          endOfWeek.setDate(startOfWeek.getDate() + 6); // Saturday
          endOfWeek.setHours(23, 59, 59, 999);
          return [startOfWeek, endOfWeek];
        },
        onClick(picker) {
          const [start, end] = shortcuts[2].value();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 3 Months",
        value() {
          const end = new Date();
          const start = new Date();
          start.setMonth(start.getMonth() - 3);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          return [start, end];
        },
        onClick(picker) {
          const [start, end] = shortcuts[3].value();
          picker.$emit("pick", [start, end]);
        },
      },
    ];

    return {
      handleClose,
      walletTransactionData,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      formatDate2,
      filteredWalletTransactions,
      selectedTransactionType,
      selectedDateRange,
      transactionTypes,
      applyFilters,
      appliedDateRange,
      sortIdOfTranSaction,
      fetchWalletTransaction,
      resetFilters,
      shortcuts,
      mdiTrashCanOutline,
      openDeleteConfirmation,
      closeDeleteDialog,
      handleConfirmDelete,
      showSuccessToast,
      isDeleteOpen,
      itemIdToDelete,
      deletedata,
      toast,
      tableName,
      table,
      lastTransaction,
    };
  },
};
</script>
