<template>
  <div class="relative w-64">
    <input
      type="text"
      v-model="searchQuery"
      @focus="isOpen = true"
      @blur="handleBlur"
      placeholder="Select an option"
      class="w-full px-4 py-2 border rounded-lg focus:outline-none"
    />
    <div
      v-if="isOpen"
      class="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg"
    >
      <ul>
        <li
          v-for="option in filteredOptions"
          :key="option.value"
          @click="selectOption(option)"
          class="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
        >
          {{ option.label }}
        </li>
        <li
          v-if="filteredOptions.length === 0"
          class="px-4 py-2 text-gray-500 cursor-default"
        >
          No options found
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue", "select"],
  setup(props, { emit }) {
    const searchQuery = ref(props.modelValue.label || "");
    const isOpen = ref(false);

    const filteredOptions = computed(() => {
      return props.options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const selectOption = (option) => {
      searchQuery.value = option.label;
      isOpen.value = false;
      emit("update:modelValue", option);
      emit("select", option);
    };

    const handleBlur = () => {
      // Delay hiding to allow click event to register
      setTimeout(() => {
        isOpen.value = false;
      }, 200);
    };

    // Watch for changes to modelValue prop to update local searchQuery
    watch(
      () => props.modelValue,
      (newVal) => {
        searchQuery.value = newVal.label || "";
      }
    );

    return {
      searchQuery,
      isOpen,
      filteredOptions,
      selectOption,
      handleBlur,
    };
  },
};
</script>

<style scoped>
/* Tailwind CSS styles */
</style>
