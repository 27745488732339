<template>
  <div class="w-full h-auto bg-default">
    <div :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Supplier</label
        >
        <el-select
          v-model="value"
          placeholder="Select"
          size="large"
          style="width: 150px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Status</label
        >
        <el-select
          v-model="value"
          placeholder="Select"
          size="large"
          style="width: 150px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          class="!w-[250px]"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>filter</label
        >
        <el-button size="large">Filter</el-button>
      </div>
    </div>
    <div :class="navClasses">
      <div>
        <h2>Sale Invoice Report</h2>
      </div>
      <div class="flex items-center gap-3">
        <button @click="handleAdd" class="btncsv flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </div>
          CSV
        </button>

        <button @click="handleAdd" class="btncancel flex items-center gap-2">
          <div>
            <font-awesome-icon :icon="['fas', 'file']" class="size-5" />
          </div>
          PDF
        </button>
        <button @click="handleAdd" class="flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          Print
        </button>
      </div>
    </div>

    <div class="h-[600px]" :class="tableClasses">
      <table>
        <tbody>
          <tr class="text-body text-primary5 bg-white sticky top-0">
            <td class="min-w-[95px] bg-white border-b text-start text-nowrap">
              Created At
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] bg-white border-b text-start text-nowrap">
              Invoice No
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] bg-white border-b text-start text-nowrap">
              Client
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] bg-white border-b text-start text-nowrap">
              Total Weight
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] bg-white border-b text-start text-nowrap">
              Total Price
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>

            <td class="min-w-[380px] bg-white border-b text-start text-nowrap">
              Paid
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[380px] bg-white border-b text-start text-nowrap">
              Status
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in 20"
            :key="data"
          >
            <td class="border-b">Silver</td>
            <td class="border-b">Silver</td>
            <td class="border-b">$2999</td>
            <td class="border-b">Silver</td>
            <td class="border-b">$2999</td>
            <td class="border-b">$2999</td>
            <td class="border-b">
              <el-button type="warning" round>Dou</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
export default {
  setup() {
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    return {
      tableClasses,
      subClassesTable,
      navClasses,
      selectClasses,
    };
  },
};
</script>
