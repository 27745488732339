<template>
  <div class="w-[792px] h-auto relative">
    <div class="details_title">
      <h2 class="ml-4">Labor Slip Detail</h2>
    </div>
    <div class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Labor Slip No</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Total Weight</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Labor Cost per Ton</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Payment Status</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Total Amount</h2>
          <h2>Cell data</h2>
        </div>
      </div>
      <div class="w-full flex mt-4 justify-between">
        <div class="flex">
          <div
            :class="{
              'tab2 cursor-pointer': true,
              'text-primary3': activeTab === 'details',
            }"
            @click="setActiveTab('details')"
          >
            Transaction
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              'text-primary3': activeTab === 'Summary',
            }"
            @click="setActiveTab('Summary')"
          >
            Payment
          </div>
        </div>
        <div class="" v-if="activeTab === 'Summary'">
          <button @click="handleAdd">Add New</button>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'details'" class="p-5">
      <div>
        <div class="w-full h-[240px] overflow-auto border">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Weight</td>
                <td class="border-b text-start text-nowrap">Cut Out</td>
                <td class="border-b text-start text-nowrap">Remain Weight</td>
                <td class="border-b text-start text-nowrap">Unit Price</td>

                <td class="border-b text-start text-nowrap">Total</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in 3"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">total</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handleClose">Print</button>

          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div>
        <div class="w-full border h-[240px] overflow-auto">
          <table>
            <tbody>
              <tr class="text-body text-primary5">
                <td class="border-b text-start text-nowrap">Action</td>
                <td class="border-b text-start text-nowrap">No</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Amount</td>
                <td class="border-b text-start text-nowrap">Note</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in 3"
                :key="data"
              >
                <td class="border-b">
                  <div class="flex items-center gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather cursor-pointer feather-edit-3 edit"
                    >
                      <path d="M12 20h9"></path>
                      <path
                        d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather cursor-pointer feather-trash-2 delete"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path
                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                      ></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">$2999</td>
                <td class="border-b text-start text-nowrap">Silver</td>
                <td class="text-start text-nowrap border-b">Cell data</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
                <td class="text-start text-nowrap">Cell data</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handleClose">Print</button>

          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
    <div
      v-if="outerVisible === true"
      @click="isClose"
      class="w-full h-screen bg-black/25 fixed flex items-center justify-center top-0 right-0"
    >
      <div @click.stop>
        <component @close="handleClose" :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import AddPaymentLoborSlip from "@/form/AddPaymentLoborSlip.vue";
import { ref } from "vue";
export default {
  components: { AddPaymentLoborSlip },
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const outerVisible = ref(false);
    const currentComponent = ref("");
    const handleAdd = () => {
      outerVisible.value = true;
      currentComponent.value = "AddPaymentLoborSlip";
    };
    const isClose = () => {
      outerVisible.value = false;
      currentComponent.value = "";
    };
    return {
      handleClose,
      setActiveTab,
      activeTab,
      outerVisible,
      currentComponent,
      handleAdd,
      isClose,
    };
  },
};
</script>

<style></style>
