<template>
  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div class="w-full flex items-center justify-between py-3">
      <div>
        <h2>Suppliers Invoice Generate</h2>
      </div>
    </div>

    <div
      class="flex items-start w-full gap-4 animate-fade-up animate-duration-300 animate-delay-100"
    >
      <div>
        <div :class="mainClassesTable" class="h-[500px]">
          <table>
            <tbody>
              <tr class="header_table">
                <td
                  @click="sortData('id')"
                  class="border-b text-start toggle_sort w-[100px]"
                >
                  No
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortColumns === 'id' && sortDirections === 'asc'
                            ? 'sort-up'
                            : sortColumns === 'id' && sortDirections === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortData('name')"
                  class="border-b text-start toggle_sort w-[120px]"
                >
                  Supplier
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortColumns === 'name' && sortDirections === 'asc'
                            ? 'sort-up'
                            : sortColumns === 'name' &&
                              sortDirections === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="font-semibold">
                <td colspan="5" class="border">
                  <div class="flex items-center justify-end gap-2">
                    <IconField class="w-[50%] placeholder:text-body2">
                      <InputText
                        v-model="searchValue"
                        placeholder="Search | Supplier | Phone Number"
                      />
                      <InputIcon class="pi pi-search" />
                    </IconField>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="matchedDataSupplier.length > 0">
              <tr
                @click="handleBoatSelection(data)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="data in matchedDataSupplier"
                :key="data.id"
              >
                <td class="border-b">{{ data.id }}</td>

                <td class="border-b">
                  {{ data.name }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="2">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full bg-white flex items-center justify-center py-2">
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            :totalRecord="matchedDataSupplier.length"
            :pageSize="pageSize"
            @page-change="handlePageChange"
            @page-size-change="handlePageSizeChange"
          />
        </div>
      </div>
      <div>
        <div :class="subClassesTable" class="h-[500px] border">
          <table>
            <tbody v-if="weighing_rice.length > 0">
              <tr class="text-body">
                <td colspan="9" class="border-b sticky top-0">
                  <div class="text-start flex items-center justify-between">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3 text-primary6">
                        <h2>Status</h2>
                        <el-button
                          :type="allWeighed === true ? 'warning' : 'success'"
                          round
                        >
                          {{ allWeighed === true ? "Procecssing" : "Complete" }}
                        </el-button>
                      </div>
                      <div class="flex items-center gap-3">
                        <h2>Total Weight</h2>
                        <span class="text-primary2 font-semibold">
                          {{
                            numeralFormat(totalWeightDrop, "0,0[.]0")
                          }}
                          Kg</span
                        >
                      </div>
                    </div>
                    <div class="flex items-center gap-3">
                      <button v-if="allWeighed === false" @click="handleAdd">
                        Generate Invoice
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-if="weighing_rice.length > 0" class="sub_header">
                <td
                  @click="sortHistoryData('id')"
                  class="min-w-[100px] border-b text-start toggle_sort"
                >
                  ID
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'id' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'id' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>

                <td class="min-w-[95px] border-b text-start">Created At</td>
                <td
                  @click="sortHistoryData('plate_number')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  Truck
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'plate_number' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'plate_number' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistoryData('weight_in')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  Total Weight
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'weight_in' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'weight_in' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td class="min-w-[100px] border-b text-start">Status</td>
              </tr>
            </tbody>
            <tbody v-if="isBoatSelected && weighing.length > 0">
              <tr
                @click="handleTruck(history)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="history in weighing_rice"
                :key="history.id"
              >
                <td class="border-b">{{ history.id }}</td>
                <td class="border-b">{{ formatDate2(history.created_at) }}</td>
                <td class="border-b">
                  {{ history.plate_number.plate_number }}
                </td>
                <td class="border-b">
                  {{
                    numeralFormat(
                      totalWeightByWeighingId[history.id],
                      "0,0[.]0"
                    )
                  }}
                  Kg
                </td>
                <td class="border-b">
                  <el-button
                    :type="history.is_weight ? 'warning' : 'success'"
                    round
                  >
                    {{ history.is_weight ? "Processing" : "Completed" }}
                  </el-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :truckProp="truckProp"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetailsPopup" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isTruck" as="template">
      <Dialog as="div" @close="handleCloseTruck" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseTruck"
                    :is="currentComponent"
                    :truckData="truckData"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import SubPagination from "@/components/SubPagination.vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import FinanceBillGenerate from "@/form/FinanceBillGenerate.vue";
import BillPreviewVue from "@/form/BillPreview.vue";
import SupplierListPopUpVue from "@/form/SupplierListPopUp.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
import TruckDetails from "@/form/TruckDetails.vue";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    BillPreviewVue,
    FinanceBillGenerate,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SupplierListPopUpVue,
    UpdateStatus,
    Toast,
    NoDataComponet,
    TruckDetails,
    Pagination,
    SubPagination,
  },
  setup() {
    const currentComponent = ref("");
    const isTruck = ref(false);
    const isOpen = ref(false);
    const isPopup = ref(false);
    const truckData = ref(null);
    const handleTruck = (doc) => {
      isTruck.value = true;
      truckData.value = doc;
      currentComponent.value = "TruckDetails";
    };
    const handleCloseTruck = () => {
      isTruck.value = false;
      currentComponent.value = "";
    };
    const datatoedit = ref(null);
    const truckProp = ref(null);
    const handleAdd = () => {
      isOpen.value = true;
      datatoedit.value = weighing_rice.value;
      truckProp.value = trucks.value;
      currentComponent.value = "FinanceBillGenerate";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "BillPreviewVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const open = () => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed ",
        message: h("p", null, [
          h(
            "span",
            null,
            "Are you sure wanting to mark the Boat [Boat Name] as Completed"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      })
        .then((action) => {
          ElMessage({
            type: "info",
            message: `action: ${action}`,
          });
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };
    const isDetailsPopup = ref(false);
    const handlePopUpDeltail = () => {
      isDetailsPopup.value = true;
      currentComponent.value = "SupplierListPopUpVue";
    };
    const handleCloseDetails = () => {
      isDetailsPopup.value = false;
      currentComponent.value = "";
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the field";
            type = "error";
            break;
          case "loanamount":
            message = "Please enter the loan amount";
            type = "error";
          case "amount":
            message = "Please enter the  amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const suppliers = ref([]);
    const weighing = ref([]);
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("suppliers");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");

    const searchValue = ref("");
    const fetctSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: table.value,
          columnSearch: "name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(apiUrl, { params });
        suppliers.value = response.data.data;

        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };
    watch(searchValue, async () => {
      await fetctSupplier();
    });
    const SubCurrentPage = ref(1);
    const SubpageSize = ref(25);
    const SubtotalPages = ref(0);
    const SubsortColumn = ref("id");
    const SubsortDirection = ref("ASC");

    const Subtable = ref("weighing");

    const weighing_rice = ref([]);
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const totalWeightDrop = ref(0);
    const fetctSupplierHistory = async (id) => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: Subtable.value,
          page: SubCurrentPage.value,
          pageSize: SubpageSize.value,
          sortColumn: SubsortColumn.value,
          sortDirection: SubsortDirection.value,
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: id,
            },
            // {
            //   field: "created_at",
            //   operator: ">=",
            //   value: fromDateFormatted.value,
            //   typeTable: "table",
            // },
            // {
            //   field: "created_at",
            //   operator: "<=",
            //   value: toDateFormatted.value,
            //   typeTable: "table",
            // },
            {
              field: "is_supplier_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });

        weighing_rice.value = response.data.data; // Update weighing_rice with the paginated data
        SubtotalPages.value = response.data.pagination.totalPages; // Update totalPages with the pagination info
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          sortDirection: "ASC",

          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          totalWeightDrop.value += parseFloat(
            response.data[0].weight_drop || 0
          );
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching rice history:", error);
        return [];
      }
    };

    const allWeighed = computed(() =>
      weighing_rice.value.every((item) => item.is_weight === true)
    );

    const selectedBoatId = ref(null);

    const selectedBoatStatus = ref(false);
    const IsWeighing = ref(null);
    const isBoatSelected = ref(false);
    const totalWeightByWeighingId = ref({});

    const calculateTotalWeightByWeighingId = (weighingRiceHistoryData) => {
      // Group the data by weighing_id and sum the weight_in
      const weightByWeighingId = weighingRiceHistoryData.reduce((acc, item) => {
        const weighingId = item.weighing_id;

        // If the weighing_id doesn't exist in the accumulator, initialize it
        if (!acc[weighingId]) {
          acc[weighingId] = 0;
        }

        // Add the weight_in to the total for this weighing_id
        acc[weighingId] += parseFloat(item.weight_drop || 0);

        return acc;
      }, {});

      return weightByWeighingId;
    };

    const handleBoatSelection = async (data) => {
      selectedBoatId.value = data.id;
      selectedBoatStatus.value = data.status;
      IsWeighing.value = data.weight_in;

      weighing_rice.value = [];

      await fetctSupplierHistory(data.id);

      if (weighing_rice.value.length > 0) {
        const dataWeighingRiceHistory = await Promise.all(
          weighing_rice.value.map(async (item) => {
            return await fetchWeighingHistory(item.id);
          })
        ).then((results) => results.flat()); // use flat to make it one array

        totalWeightByWeighingId.value = calculateTotalWeightByWeighingId(
          dataWeighingRiceHistory
        );
      }

      isBoatSelected.value = true;
    };

    const fetchWeighing = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: "weighing",
          sortColumn: "id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "is_supplier_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            // {
            //   field: "created_at",
            //   operator: ">=",
            //   value: fromDateFormatted.value,
            //   typeTable: "table",
            // },
            // {
            //   field: "created_at",
            //   operator: "<=",
            //   value: toDateFormatted.value,
            //   typeTable: "table",
            // },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params,
        });

        if (response.data.length > 0) {
          weighing.value = response.data;
        } else {
          weighing.value = [];
        }
        console.log("success", weighing.value);
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };
    const handleFilter = async () => {
      await fetchWeighing();
    };

    watch(
      () => weighing.value,
      (newV) => {
        if (newV && newV.length > 0) {
          const supplierIdsInWeighing = new Set(
            newV.map((item) => item.supplier_id)
          );
          suppliers.value = suppliers.value.filter((supplier) =>
            supplierIdsInWeighing.has(supplier.id)
          );
        }
      },
      { deep: true }
    ); //use deep to watch the deep of the object

    const matchedDataSupplier = computed(() => {
      const supplierIdsInWeighing = new Set(
        weighing.value.map((item) => item.supplier_id)
      );
      return suppliers.value.filter((supplier) =>
        supplierIdsInWeighing.has(supplier.id)
      );
    });

    const totalWeightIn = computed(() => {
      return weighing_rice.value.reduce((total, record) => {
        return total + parseFloat(record.weight_drop || 0);
      }, 0);
    });
    console.log("totalWeightIn", weighing_rice.value);
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetctSupplier(),
        fetchTruck(),
        fetchUserByID(),
        fetchWeighing(),
      ]);

      if (selectedBoatId.value) {
        await fetctSupplierHistory(selectedBoatId.value);
      }
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      await Promise.allSettled([
        fetctSupplier(),
        fetchTruck(),
        fetchWeighing(),
        fetchUserByID(),
      ]);

      if (selectedBoatId.value) {
        await fetctSupplierHistory(selectedBoatId.value);
      }

      socket.on("database_realTime", handleDatabaseUpdate);
    });
    const sortSupplier = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing.value, field);
      } else if (field === "name") {
        toggleSort(sortDirection, weighing.value, field);
      }
    };
    const sortHistory = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "plate_number_id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "weight_in") {
        toggleSort(sortDirection, weighing_rice.value, field);
      }
    };
    const handlePageChange = async (page) => {
      currentPage.value = page;
      await fetchWeighing();
    };

    const handlePageSizeChange = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await fetchWeighing();
    };
    const handleSubPageChange = async (page) => {
      SubCurrentPage.value = page;
      await fetctSupplierHistory();
    };

    const handleSubPageSizeChange = async (size) => {
      SubpageSize.value = size;
      SubCurrentPage.value = 1;
      await fetctSupplierHistory();
    };

    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      if (field === "id") {
        suppliers.value = toggleSort(direction, suppliers.value, field);
      } else if (field === "name") {
        suppliers.value = toggleSort(direction, suppliers.value, field);
      }
    };
    const sortPlate = (direction, array, field) => {
      array.sort((a, b) => {
        const plateA = a.plate_number.plate_number.toUpperCase(); // assuming plate_number is nested
        const plateB = b.plate_number.plate_number.toUpperCase();
        if (direction === "asc") {
          return plateA.localeCompare(plateB);
        } else {
          return plateB.localeCompare(plateA);
        }
      });
    };
    const sortHistoryDirection = ref("asc");
    const sortHistoryColumns = ref(null);
    const sortHistoryData = (field) => {
      const direction =
        sortHistoryColumns.value === field &&
        sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";
      sortHistoryDirection.value = direction;
      sortHistoryColumns.value = field;
      if (field === "id") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "weight_in") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "plate_number") {
        sortPlate(direction, weighing_rice.value, field);
      }
    };
    return {
      totalWeightByWeighingId,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      handlePopUpDeltail,
      isDetailsPopup,
      handleCloseDetails,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      weighing,
      getPlatesName,
      formatDate2,
      handleBoatSelection,
      weighing_rice,
      isBoatSelected,
      datatoedit,
      truckProp,
      toast,
      showSuccessToast,
      totalWeightIn,
      handleTruck,
      handleCloseTruck,
      truckData,
      isTruck,
      handleTruck,
      // matchingWeighings,
      sortSupplier,
      sortHistory,
      // handleUpdateStatus,
      // handleCloseStatus,

      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      // subpagination

      Subtable,
      SubCurrentPage,
      SubpageSize,
      SubtotalPages,
      handleSubPageSizeChange,
      handleSubPageChange,
      searchValue,
      sortData,
      sortDirections,
      sortColumns,
      suppliers,
      matchedDataSupplier,
      sortHistoryData,
      sortHistoryDirection,
      sortHistoryColumns,
      allWeighed,
      handleFilter,
      selectDateRage,
      authUserRole,
      totalWeightDrop,
    };
  },
};
</script>

<style></style>
