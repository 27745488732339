import {  ref } from "vue";
import apirURL from "@/services/apiURL"
import { decodeJwt } from "@/composables/decodeJWT";
import axios from "axios";

const usersDataByID = ref([]);
const authUserRole = ref("");
const permissions = ref(null);
export const fetchUserByID = async () => {
  try {

    const token = localStorage.getItem('token');
    const decodeJWTInfo = await decodeJwt(token);

    let table = 'users';
    const response = await axios.get(`${apirURL}/weighing/api/getDataByID/${table}/${decodeJWTInfo ? decodeJWTInfo.userId : null}`);
    usersDataByID.value = response.data;

    authUserRole.value = usersDataByID.value[0]?.role;

    permissions.value = usersDataByID.value[0]?.permissions;



    return {
      authUserRole:authUserRole.value,
      permissions:permissions.value
    }


  } catch (err) {
    console.log("failed to fetch data", err)
  }
}






