<template>
  <div class="w-full h-auto p-3 flex overflow-auto items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="handleBack" class="btncancel hidden-print">
            Back
          </button>
        </div>
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <div class="w-full h-full mt-4 bg-white p-2 print-header" id="printTable">
        <div class="flex justify-between items-start w-full">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Supplier Invoice</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12pt] items-center">
              011 111 111 / 012 123 456
            </h2>
          </div>
          <div class="text-primary5 text-[12pt] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[8pt] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4 mt-4"
        >
          <div class="flex w-full items-center justify-between text-[10pt]">
            <h2>Supplier</h2>
            <h2>{{ getSupplierName(invoice[0]?.supplier_id) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between text-[10pt]">
            <h2>Bill No.</h2>
            <h2>SL- {{ invoice[0]?.id }}</h2>
          </div>
          <div class="flex w-full items-center justify-between text-[10pt]">
            <h2>Phone Number</h2>
            <h2>{{ getPhoneNumer(invoice[0]?.supplier_id) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between text-[10pt]">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
        </div>
        <div class="mt-4">
          <p>Rice Details</p>
          <div>
            <table>
              <thead>
                <tr class="text-[8pt] bg-primary3 text-white">
                  <td
                    class="min-w-[70px] text-nowrap border text-start leading-[6px]"
                  >
                    No
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Rice Type
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Weight In
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Weight Out
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Cut
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Remain Kg
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Unit Cost
                  </td>
                  <td class="text-nowrap border text-start leading-[6px]">
                    Total (KHR)
                  </td>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(data, weighingId) in groupedData"
                  :key="weighingId"
                >
                  <tr class="border text-start">
                    <td colspan="8" class="font-bold text-[10pt] leading-3">
                      Truck: {{ data.plate_number.plate_number }}
                      <!-- Display plate_number -->
                    </td>
                  </tr>
                  <tr
                    v-for="(riceData, i) in data.rices"
                    :key="riceData.id"
                    class="text-[8pt] font-normal text-primary2 text-nowrap"
                  >
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ i + 1 }}
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ getRice(riceData.rice) }}
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(riceData.weight_in, "0,0[.]00") }} Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(riceData.weight_out, "0,0[.]00") }} Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(riceData.amount_cut_off, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(riceData.weight_drop, "0,0[.]00") }} Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(riceData.unit_cost, "0,0[.]00") }} ៛
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{
                        numeralFormat(
                          riceData.unit_cost * riceData.weight_drop,
                          "0,0[.]00"
                        )
                      }}
                      ៛
                    </td>
                  </tr>

                  <tr class="font-bold border text-[8pt]">
                    <td
                      colspan="2"
                      class="text-nowrap border text-start leading-[6px]"
                    >
                      Total
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(totalWeightIn(data.rices), "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{
                        numeralFormat(totalWeightOut(data.rices), "0,0[.]00")
                      }}
                      Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(totalCutOut(data.rices), "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(totalRemain(data.rices), "0,0[.]00") }}
                      Kg
                    </td>
                    <td
                      class="text-nowrap border text-start leading-[6px]"
                    ></td>
                    <td class="text-nowrap border text-start leading-[6px]">
                      {{ numeralFormat(totalCost(data.rices), "0,0[.]00") }} ៛
                    </td>
                  </tr>

                  <tr
                    v-if="data.weighing"
                    class="font-normal border text-[8pt]"
                  >
                    <td
                      colspan="8"
                      class="text-nowrap border text-end leading-[6px]"
                    >
                      Sack Expense:
                      {{
                        numeralFormat(data.weighing.sack_expense, "0,0[.]00")
                      }}
                      ៛
                    </td>
                  </tr>
                  <tr
                    v-if="data.weighing"
                    class="font-normal border text-[8pt]"
                  >
                    <td
                      colspan="8"
                      class="text-nowrap border text-end leading-[6px]"
                    >
                      Weighing Expense:
                      {{
                        numeralFormat(
                          data.weighing.weighing_expense,
                          "0,0[.]00"
                        )
                      }}
                      ៛
                    </td>
                  </tr>

                  <tr
                    v-for="(expense, index) in data.additional_expense"
                    :key="index"
                    class="font-normal border"
                  >
                    <td
                      colspan="8"
                      class="text-nowrap border text-end leading-[6px] text-[8pt]"
                    >
                      {{ expense.title }}:
                      {{ numeralFormat(expense.amount, "0,0[.]00") }} ៛
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="flex items-start justify-between w-full mt-4">
          <div
            class="py-2 flex flex-col items-start bg-primary3/20 border-primary3 border rounded-lg w-fit text-nowrap px-4"
          >
            <h2 class="text-primary5 text-[8pt]">
              *Get payment from our Partner:
            </h2>
            <h2 class="text-primary2 font-bold text-[8pt] text-center">
              {{ partner[0]?.name }} | {{ partner[0]?.phone_number }}
            </h2>
          </div>
          <div>
            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px]"
            >
              <h2 class="text-primary5 text-[8pt]">Total Rice Cost:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{ numeralFormat(invoice[0]?.amount_paid, "0,0[.]00") }} ៛
              </h2>
            </div>
            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px]"
            >
              <h2 class="text-primary5 text-[8pt]">Pre-loan:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{
                  numeralFormat(
                    invoice[0]?.weighing[0].preloan_amount,
                    "0,0[.]00"
                  )
                }}
                ៛​
              </h2>
            </div>
            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px]"
            >
              <h2 class="text-primary5 text-[8pt]">Weighing Expense:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{
                  numeralFormat(
                    invoice[0]?.weighing[0].total_weighing_expense,
                    "0,0[.]00"
                  )
                }}
                ៛​
              </h2>
            </div>
            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px]"
            >
              <h2 class="text-primary5 text-[8pt]">Sack Expense:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{
                  numeralFormat(
                    invoice[0]?.weighing[0].total_sack_expense,
                    "0,0[.]00"
                  )
                }}
                ៛​
              </h2>
            </div>
            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px]"
            >
              <h2 class="text-primary5 text-[8pt]">Additional Expense:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{ numeralFormat(totalAdditionalExpense, "0,0[.]00") }}
                ៛​
              </h2>
            </div>

            <div
              class="py-2 w-full flex justify-between items-center text-[8pt] gap-3 leading-[6px] border-t-2 border-primary2"
            >
              <h2 class="text-primary5 text-[8pt]">Grand Total:</h2>
              <h2 class="text-primary2 font-bold text-[8pt]">
                {{ numeralFormat(invoice[0]?.total_amount, "0,0[.]00") }}
                ៛​
              </h2>
            </div>
          </div>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[10px] mt-8"
        >
          <h2 class="text-primary5 text-[8pt]">Accepted</h2>
          <h2 class="text-primary5 text-[8pt]">Issued</h2>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[10px]"
        >
          <h2 class="text-primary5 text-[8pt]">
            {{ getSupplierName(invoice[0]?.supplier_id) }}
          </h2>
          <h2 class="text-primary5 text-[8pt]">
            {{ getUserName(invoice[0]?.user_id) }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";

export default {
  props: ["id"],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const invoice = ref([]);
    const supplier = ref([]);
    const rices = ref([]);
    const weighingData = ref([]);
    const plate = ref([]);
    const partner = ref([]);
    const users = ref([]);

    // Function to fetch invoice data
    const fetchInvoiceData = async () => {
      try {
        const req = {
          tableName: "supplier_invoice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: Number(props.id) || Number(route.params.id),
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        invoice.value = response.data;
        console.log("Invoice data:", invoice.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    // Fetch other necessary data
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        supplier.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      }
    };

    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice data:", error);
      }
    };

    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        weighingData.value = response.data;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        plate.value = response.data;
      } catch (error) {
        console.error("Error fetching plate data:", error);
      }
    };

    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching partner data:", error);
      }
    };

    const fetchUser = async () => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        users.value = response.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };

    const getSupplierName = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.name;
    };

    const getPhoneNumer = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.phone_number;
    };

    const getUserName = (id) => {
      const name = users.value.find((item) => item?.id === id);
      return name?.profile_name;
    };

    const groupedData = computed(() => {
      if (
        !invoice.value ||
        !invoice.value.length ||
        !invoice.value[0].rices ||
        !invoice.value[0].additional_expense ||
        !invoice.value[0].weighing
      ) {
        return {};
      }

      const rices = invoice.value[0].rices;
      const weighings = invoice.value[0].weighing;
      const additionalExpenses = invoice.value[0].additional_expense;
      const riceGroups = {};

      // Group by weighing_id
      weighings.forEach((weighing) => {
        const weighingId = weighing.weighing_id;
        if (!riceGroups[weighingId]) {
          riceGroups[weighingId] = {
            rices: [],
            additional_expense: [],
            weighing,
            plate_number: weighing.plate_number || "N/A", // Save plate_number here
          };
        }
      });

      // Group rices by weighing_id
      rices.forEach((rice) => {
        if (rice.weighing_id in riceGroups) {
          riceGroups[rice.weighing_id].rices.push(rice);
        }
      });

      // Group additional expenses by weighing_id
      additionalExpenses.forEach((expenseEntry) => {
        if (expenseEntry.expenses) {
          expenseEntry.expenses.forEach((expense) => {
            if (expense.weighing_id in riceGroups) {
              riceGroups[expense.weighing_id].additional_expense.push(expense);
            }
          });
        }
      });

      return riceGroups;
    });

    watch(groupedData, (newVal) => {
      console.log("Grouped Rices:", newVal);
    });

    onMounted(async () => {
      await Promise.allSettled([
        fetchInvoiceData(),
        fetchSupplier(),
        fetchRiceType(),
        fetchPlates(),
        fetchPartner(),
        fetchWeighingData(),
        fetchUser(),
      ]);
    });

    const handlePrint = () => {
      window.print();
    };

    const totalWeightIn = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.weight_in || 0), 0);

    const totalWeightOut = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.weight_out || 0), 0);

    const totalRemain = (rices = []) =>
      rices.reduce((total, rice) => total + Number(rice.weight_drop || 0), 0);

    const totalCost = (rices = []) =>
      rices.reduce(
        (total, weighing) => total + Number(weighing.total_cost_price || 0),
        0
      );

    const totalCutOut = (rices = []) =>
      rices.reduce(
        (total, weighing) => total + Number(weighing.amount_cut_off || 0),
        0
      );

    const totalAdditionalExpense = computed(() => {
      return invoice.value[0]?.additional_expense.reduce((total, item) => {
        const expenseTotal = item.expenses.reduce(
          (sum, expense) => sum + (expense.amount || 0),
          0
        );
        return total + expenseTotal;
      }, 0);
    });
    const handleBack = () => {
      router.go(-1);
    };

    return {
      handlePrint,
      invoice,
      groupedData,
      totalRemain,
      totalCost,
      totalWeightIn,

      totalWeightOut,
      totalCutOut,
      supplier,
      partner,
      formatDate2,
      getRice,
      getSupplierName,
      getPhoneNumer,
      totalAdditionalExpense,
      getUserName,
      handleBack,
    };
  },
};
</script>

<style lang="scss" scoped></style>
