<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[1200px] max-w-[1200px] h-[800px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Generate Customer Invoice</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex w-full items-center h-[85px] justify-center bg-primary3/20 text-primary3 text-heading2"
    >
      <div class="w-full flex flex-col items-center justify-center">
        <h2 class="text-body font-bold ml-4">To Be Paid:</h2>
        <h2 class="text-heading1 font-bold ml-4">
          {{ numeralFormat(totalUnitCost, "0,0[.]00") }} VND
        </h2>
      </div>
    </div>
    <div class="p-5 h-[600px] overflow-auto">
      <div class="border p-2 rounded-md">
        <div class="py-2 border-primary6 grid grid-cols-2 space-y-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Client</h2>
            <h2>{{ getCustomerName(customerId) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(datatoedit[0]?.created_at) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Phone Number</h2>
            <h2>{{ getCustomerPhone(customerId) }}</h2>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="text-start">
          <h2>Rice Detail</h2>
        </div>
        <div class="relative overflow-x-auto mt-4 border h-[250px]">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="min-w-[70px] text-nowrap border-b text-start">No</td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Rice Type
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Weigh In
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Weigh Out
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Cut Out
                </td>

                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Remain Weight
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Unit Cost
                </td>
                <td class="min-w-[100px] text-nowrap border-b text-start">
                  Total
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr
                class="text-body font-normal text-primary2 text-nowrap"
                v-for="data in weighingInHistory"
                :key="data"
              >
                <td class="border-b">{{ data.id }}</td>
                <td class="border-b">
                  {{ data.rice[0]?.rice_name }}
                </td>
                <td class="border-b">
                  {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ numeralFormat(data.weight_drop, "0,0[.]00") }}
                  Kg
                </td>

                <td class="border-b">
                  <InputNumber
                    v-model="data.unitCost"
                    placeholder="Unit Cost"
                    style="width: 150px"
                    suffix=" ₫"
                    :invalid="isValidateUnitCost"
                    :maxFractionDigits="2"
                    step="0.01"
                    class="h-2 flex items-center"
                  />
                </td>
                <td class="border-b">
                  {{ numeralFormat(calculateTotal(data), "0,0[.]00") }} ₫
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="border p-2 w-full rounded-md mt-8">
          <div class="flex justify-between py-1">
            <h2>Add Payment</h2>
            <h2>data number VND</h2>
          </div>
          <div>
            <div class="border p-3 rounded space-y-4">
              <div class="flex flex-col space-y-4">
                <label class="flex gap-1 text-nowrap">
                  <span class="text-red-500">*</span> Amount
                </label>
                <InputNumber
                  placeholder="Amount"
                  style="width: 250px"
                  v-model="amountPaid"
                  :invalid="isValidateAmountPaid"
                  suffix=" ₫"
                  :maxFractionDigits="2"
                  step="0.01"
                  class="h-2 flex items-center"
                />
              </div>
              <div>
                <div class="flex flex-col space-y-2">
                  <label class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span> Recive by
                  </label>

                  <Select
                    v-model="isReciver"
                    :options="reciverBy"
                    placeholder="Select Recive"
                    style="width: 250px"
                    option-value="id"
                    option-label="name"
                    class="w-full md:w-56 h-9 text-start flex items-center"
                  />
                </div>
              </div>
              <div v-if="isReciver === 'partner'">
                <div class="flex flex-col space-y-2">
                  <label class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span> By Partner
                  </label>

                  <Select
                    v-model="recive"
                    :options="partner"
                    placeholder="Select Recive"
                    style="width: 250px"
                    option-value="id"
                    option-label="name"
                    class="w-full md:w-56 h-9 text-start flex items-center"
                  />
                </div>
              </div>
              <!-- <div v-if="isReciver === 'internal'">
                <div class="flex flex-col space-y-2">
                  <label class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span>By Internal
                  </label>

                  <Select
                    v-model="recive"
                    placeholder="Select Recive"
                    style="width: 250px"
                    :options="internal"
                    option-value="name"
                    option-label="name"
                    class="w-full md:w-56 h-9 text-start flex items-center"
                  />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full p-3">
      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending" type="submit">
          <span v-if="isPending" :class="{ is_pending: isPending }"></span>
          Save
        </button>
        <button
          @click="handleToPrint"
          :disabled="isPendingSave"
          type="button"
          class="btncancel"
        >
          Save & Print
          <span
            v-if="isPendingSave"
            :class="{ is_pending: isPendingSave }"
          ></span>
        </button>
        <button class="btncancel" @click="handleClose">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { validateInputNUmber } from "@/composables/ValidateInput";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch, watchEffect } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { fetchTimestamp } from "@/services/timestamp";
import { DMTelegram } from "@/composables/useDMTelegram";
import { decodeJwt } from "@/composables/decodeJWT";
import moment from "moment-timezone";

import { addCustomerInvoiceOwe } from "@/composables/useCustomerRepayment";
import { useCustomerPayment } from "@/composables/usePartnerTransaction";
import { handleValidate } from "@/composables/checkValidatefeild";
import { invalid } from "moment/moment";
export default {
  props: ["datatoedit", "truckProp", "customerId", "boat_array_id"],
  setup(props, { emit }) {
    const isReciver = ref("");
    const internal = ref([
      { id: "internal", name: "internal", type: "internal" },
    ]);
    const reciverBy = ref([
      { id: "internal", name: "internal", type: "internal" },
      { id: "partner", name: "partner", type: "partner" },
    ]);
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const router = useRouter();
    const recive = ref(null);
    const isPending = ref(false);
    const isPendingSave = ref(false);
    const amountPaid = ref(null);
    const isValidateAmountPaid = handleValidate(amountPaid.value, amountPaid);
    const weighing = ref([]);

    const partner = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const calculateTotal = (record) => {
      const weight = parseFloat(record.weight_drop || 0);
      const unitCost = parseFloat(record.unitCost || 0);
      return weight * unitCost;
    };
    const totalUnitCost = computed(() => {
      return weighingInHistory.value.reduce((total, record) => {
        const weight = parseFloat(record.weight_drop || 0);
        const unitCost = parseFloat(record.unitCost || 0);
        return total + weight * unitCost;
      }, 0);
    });

    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };

    const weighingProp = ref([]);

    const authUserId = ref(null);

    const unitCost = ref(null);
    const weighingInHistory = ref([]);
    const isValidateUnitCost = ref(false);
    watch(
      () => weighingInHistory.value.map((item) => item.unitCost),
      (newUnitCosts) => {
        isValidateUnitCost.value = newUnitCosts.some(
          (unitCost) => unitCost === 0 || unitCost === null
        );
      },
      { deep: true }
    );

    const totalWeightDrop = ref(null);
    const fetchWeighingHistory = async (id) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "boat_enter_parking_id",
              operator: "=",
              value: id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        return response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const getCustomerName = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getCustomerPhone = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.phone_number;
    };
    const customers = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const isPartner = ref(null);

    const handleSubmit = async () => {
      const isUnitCostValid = weighingInHistory.value.every(
        (record) => record.unitCost !== null && record.unitCost !== undefined
      );
      if (
        !amountPaid.value ||
        amountPaid.value <= 0 ||
        amountPaid.value === null
      ) {
        isValidateAmountPaid.value = true;
        console.log("isValidateAmountPaid");
        return;
      }
      const isTotalAmountValid =
        amountPaid.value !== null && amountPaid.value !== undefined;

      if (!isUnitCostValid || !isTotalAmountValid) {
        console.error(
          "Validation failed: Ensure all required fields are filled."
        );

        emit("toast", "amount");
        return;
      }

      if (amountPaid.value > totalUnitCost.value) {
        emit("toast", "amount");
        return;
      }
      try {
        isPending.value = true;

        const timestamp = await fetchTimestamp();

        const requestBody = {
          tableName: "customer_invoice",
          fields: {
            customer_id: props.customerId,
            created_at: timestamp,
            partner_id: recive.value ? recive.value : null,
            weighing_id: weighingInHistory.value
              .map((record) => record.weighing_id)
              .filter((id, index, array) => array.indexOf(id) === index),

            weigh_type: "kilo",
            total_weight_drop: totalWeightDrop.value,
            weighings: JSON.stringify(weighingInHistory.value),
            total_amount: totalUnitCost.value,
            amount_paid: amountPaid.value,
            payment_type: recive.value ? "partner" : "internal",
            created_by: authUserId.value,
            status:
              amountPaid.value === totalUnitCost.value
                ? "paid"
                : amountPaid.value <= 0
                ? "due"
                : "partial",
            rices: JSON.stringify(
              weighingInHistory.value.map((record) => ({
                weighing_id: record.weighing_id,
                rice_name: record.rice_name,
                unitCost: record.unitCost,
                total_cost: record.weight_drop * record.unitCost,
                weight_drop: record.weight_drop,
              }))
            ),

            repayments: JSON.stringify([
              {
                boat_id: weighingInHistory.value.map((record) => ({
                  boat_enter_parking_id: record.boat_enter_parking_id,
                })),
                total_amount_owed: Number(totalUnitCost.value),
                customer_repayment_id: null,
                amount_paid: amountPaid.value,
                amount_remaining: Number(
                  totalUnitCost.value - amountPaid.value
                ),
                status:
                  amountPaid.value === totalUnitCost.value
                    ? "paid"
                    : amountPaid.value <= 0
                    ? "due"
                    : "partial",
              },
            ]),
          },
        };
        console.log("requestBody", requestBody);

        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          requestBody
        );

        if (recive.value) {
          await useCustomerPayment(
            requestBody.fields,
            response.data.insertedId,
            "add"
          );
        }

        if (response.data) {
          const requestBodyWeighing = {
            tableName: "weighing",
            fields: {
              is_customer_invoice: true,
            },
          };

          const weighing_ids = weighingInHistory.value
            .map((record) => record.weighing_id)
            .filter((id, index, array) => array.indexOf(id) === index);
          weighing_ids.forEach(async (d) => {
            await axios.patch(
              `${apirURL}/weighing/api/updateData/${d}`,
              requestBodyWeighing
            );
          });
          const requestBodyBoatEnterParking = {
            tableName: "boat_enter_parking",
            fields: {
              is_customer_invoice: true,
            },
          };
          const boat_ids = weighingInHistory.value
            .map((record) => record.boat_enter_parking_id)
            .filter((id, index, array) => array.indexOf(id) === index);
          boat_ids.forEach(async (d) => {
            await axios.patch(
              `${apirURL}/weighing/api/updateData/${d}`,
              requestBodyBoatEnterParking
            );
          });

          if (requestBody.fields.status !== "paid") {
            addCustomerInvoiceOwe(requestBody.fields, response.data.insertedId);
          }
          const Message = `Hello , Dear ${recive.value}
                             Please Kindly Check In your bank account
                             This amount ${amountPaid.value}dong is paid by customer ${props.customerId} `;
          DMTelegram(recive.value, Message);
          if (response.data) {
            const insertTo_partner_history = {
              tableName: "partner_transaction_history",
              fields: {
                customer_invoice: response.data.insertedId,
                partner_id: recive.value,
                created_at: timestamp,
                created_by: authUserId.value,
                transaction_type: "customer payment",
                wallet: "VND",
                amount: amountPaid.value,
              },
            };
            if (recive.value) {
              const insert_history = await axios.post(
                `${apirURL}/weighing/api/insertData`,
                insertTo_partner_history
              );
              console.log("insert_history", insert_history.data);
            }
          }
          handleClose();
          emit("toast", "create");
        }
      } catch (error) {
        console.error("Error while posting data:", error);
      }
    };

    const handleToPrint = async () => {
      const isUnitCostValid = weighingInHistory.value.every(
        (record) => record.unitCost !== null && record.unitCost !== undefined
      );
      if (
        !amountPaid.value ||
        amountPaid.value <= 0 ||
        amountPaid.value === null
      ) {
        isValidateAmountPaid.value = true;
        console.log("isValidateAmountPaid");
        return;
      }
      const isTotalAmountValid =
        amountPaid.value !== null && amountPaid.value !== undefined;

      if (!isUnitCostValid || !isTotalAmountValid) {
        console.error(
          "Validation failed: Ensure all required fields are filled."
        );

        emit("toast", "amount");
        return;
      }

      if (amountPaid.value > totalUnitCost.value) {
        emit("toast", "amount");
        return;
      }
      try {
        isPendingSave.value = true;
        const timestamp = await fetchTimestamp();

        const requestBody = {
          tableName: "customer_invoice",
          fields: {
            customer_id: props.customerId,
            created_at: timestamp,
            partner_id: recive.value ? recive.value : null,
            weighing_id: weighingInHistory.value
              .map((record) => record.weighing_id)
              .filter((id, index, array) => array.indexOf(id) === index),

            weigh_type: "kilo",
            total_weight_drop: totalWeightDrop.value,
            weighings: JSON.stringify(weighingInHistory.value),
            total_amount: totalUnitCost.value,
            amount_paid: amountPaid.value,
            payment_type: recive.value ? "partner" : "internal",
            created_by: authUserId.value,
            status:
              amountPaid.value === totalUnitCost.value
                ? "paid"
                : amountPaid.value <= 0
                ? "due"
                : "partial",
            rices: JSON.stringify(
              weighingInHistory.value.map((record) => ({
                weighing_id: record.weighing_id,
                rice_name: record.rice_name,
                unitCost: record.unitCost,
                total_cost: record.weight_drop * record.unitCost,
                weight_drop: record.weight_drop,
              }))
            ),

            repayments: JSON.stringify([
              {
                boat_id: weighingInHistory.value.map((record) => ({
                  boat_enter_parking_id: record.boat_enter_parking_id,
                })),
                total_amount_owed: Number(totalUnitCost.value),
                customer_repayment_id: null,
                amount_paid: amountPaid.value,
                amount_remaining: Number(
                  totalUnitCost.value - amountPaid.value
                ),
                status:
                  amountPaid.value === totalUnitCost.value
                    ? "paid"
                    : amountPaid.value <= 0
                    ? "due"
                    : "partial",
              },
            ]),
          },
        };

        // Make a single post request with the consolidated data
        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          requestBody
        );

        if (recive.value) {
          await useCustomerPayment(
            requestBody.fields,
            response.data.insertedId,
            "add"
          );
        }

        if (response.data) {
          //update is_customer_invoice =true in weighing table
          const requestBodyWeighing = {
            tableName: "weighing",
            fields: {
              is_customer_invoice: true,
            },
          };

          const weighing_ids = weighingInHistory.value
            .map((record) => record.weighing_id)
            .filter((id, index, array) => array.indexOf(id) === index);
          weighing_ids.forEach(async (d) => {
            await axios.patch(
              `${apirURL}/weighing/api/updateData/${d}`,
              requestBodyWeighing
            );
          });
          const requestBodyBoatEnterParking = {
            tableName: "boat_enter_parking",
            fields: {
              is_customer_invoice: true,
            },
          };
          const boat_ids = weighingInHistory.value
            .map((record) => record.boat_enter_parking_id)
            .filter((id, index, array) => array.indexOf(id) === index);
          boat_ids.forEach(async (d) => {
            await axios.patch(
              `${apirURL}/weighing/api/updateData/${d}`,
              requestBodyBoatEnterParking
            );
          });
          router.push({
            name: "printclientinvoice",
            params: { id: response.data.insertedId },
          });
          if (requestBody.fields.status !== "paid") {
            addCustomerInvoiceOwe(requestBody.fields, response.data.insertedId);
          }
          const Message = `Hello , Dear ${recive.value}
                             Please Kindly Check In your bank account
                             This amount ${amountPaid.value}dong is paid by customer ${props.customerId} `;
          DMTelegram(recive.value, Message);
          if (response.data) {
            const insertTo_partner_history = {
              tableName: "partner_transaction_history",
              fields: {
                customer_invoice: response.data.insertedId,
                partner_id: recive.value,
                created_at: timestamp,
                created_by: authUserId.value,
                transaction_type: "customer payment",
                wallet: "VND",
                amount: amountPaid.value,
              },
            };
            if (recive.value) {
              const insert_history = await axios.post(
                `${apirURL}/weighing/api/insertData`,
                insertTo_partner_history
              );
              console.log("insert_history", insert_history.data);
            }
          }
          handleClose();
          emit("toast", "create");
        }
      } catch (error) {
        console.error("Error while posting data:", error); // Log error if any
      }
    };
    const handleClose = () => {
      emit("close");
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchRiceType(),
        fetchPartner(),
        fetchCustomer(),
        // fetchWeighingHistory(),
      ]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }

      console.log("props.datatoedit", props.datatoedit);
      console.log("customer id", props.customerId);
      if (props.boat_array_id) {
        const results = await Promise.all(
          props.boat_array_id.map((d) => fetchWeighingHistory(d.id))
        );

        weighingInHistory.value = results.flat();
        totalWeightDrop.value = weighingInHistory.value.reduce(
          (total, record) => {
            return total + parseFloat(record.weight_drop || 0);
          },
          0
        );
      }
    });

    return {
      handleClose,

      validateInputNUmber,
      weighing,
      unitCost,

      getRice,

      weighingProp,

      partner,

      isPartner,
      weighingInHistory,
      recive,
      calculateTotal,
      getCustomerName,
      getCustomerPhone,
      formatDate2,
      handleToPrint,
      amountPaid,
      handleSubmit,
      totalUnitCost,
      isPending,
      isReciver,
      reciverBy,
      internal,
      isValidateUnitCost,
      isValidateAmountPaid,
    };
  },
};
</script>

<style></style>
