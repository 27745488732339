<template>
  <div>
    <h2>Forgot password</h2>
    <form @submit.prevent="handleSendResetPassword">
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Email</label>
        <input
          type="email"
          v-model="userEmail"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          
          required
        />
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>
    
  
  <script>
import axios from "axios";
import {ref} from 'vue';
export default {
  setup() {


    const userEmail =ref(null)
    const handleSendResetPassword = async () => {
      try {
        const response = await axios.post(
          "http://localhost:3000/weighing/api/sendResetPassword",
          { userEmail: userEmail.value }
        );

        console.log("Password reset request successful:", response);
      } catch (error) {
        if (error.response.status === 401) {
          console.log("user email don't exists");
        } else if (error.response.status === 403) {
          console.log("you don't have permission to reset password yet.");
        } else if (error.response.status === 400) {
          console.log("Unsupported email provider");
        } else {
          console.log("failed to send reset password", error);
        }
      }
    };

    return {
      userEmail,
      handleSendResetPassword,
    };
  },
};
</script>
  
  <style>
</style>