<template>
  <div class="w-[750px] h-[700px]">
    <div class="details_title">
      <h2 class="ml-4">
        Boat: {{ getBoat(datatoedit.boat_enter_parking_id) }}
      </h2>
      <h2 class="mr-4">Boat Preview</h2>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>
    <div v-if="activeTab === 'details'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Custome Name</h2>
            <h2>{{ getSupplierName(customer[0]?.id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Boat</h2>
            <h2>{{ getBoat(datatoedit.boat_enter_parking_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>{{ getBoatStatus(datatoedit.boat_enter_parking_id) }}</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction History</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px] bg-white">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No</td>
              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Weigh In</td>
              <td class="min-w-[100px] border-b text-start">Weigh out</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
              <td class="min-w-[100px] border-b text-start">Parking</td>
            </tr>
          </tbody>
          <tr>
            <td
              colspan="8"
              class="text-body font-normal text-primary2 border-b"
            >
              <div class="flex w-full gap-2">
                <h2 class="text-heading2 font-semibold">Truck No</h2>
                <h2 class="text-heading2 font-semibold">
                  {{ datatoedit.weighing_history_rice[0]?.plate_number }}
                </h2>
              </div>
            </td>
          </tr>

          <tr v-if="datatoedit">
            <td class="text-body border-b">
              {{ datatoedit.weighing_history_id }}
            </td>
            <td class="text-body text-nowrap border-b">
              {{ formatDate2(datatoedit.created_at) }}
            </td>
            <td class="text-body border-b">
              {{ datatoedit.rice[0]?.rice_name }}
            </td>
            <td class="text-body border-b">
              {{
                numeralFormat(datatoedit.weighing_history_weight_in, "0,0[.]00")
              }}
              Kg
            </td>
            <td class="text-body border-b">
              {{
                numeralFormat(
                  datatoedit.weighing_rice_history_weight_out,
                  "0,0[.]00"
                )
              }}
              Kg
            </td>
            <td class="text-body border-b">
              {{
                numeralFormat(
                  datatoedit.weighing_rice_history_amount_cut_off,
                  "0,0[.]00"
                )
              }}
              Kg
            </td>
            <td class="text-body border-b">
              {{
                numeralFormat(
                  datatoedit.weighing_history_weight_drop,
                  "0,0[.]00"
                )
              }}
              Kg
            </td>
            <td class="text-body border-b">
              {{ getBoat(datatoedit.boat_enter_parking_id) }}
            </td>
          </tr>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5">
        <button @click="handleClose" class="btncancel">Close</button>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Customer Name</h2>
            <h2>{{ getSupplierName(customer[0]?.id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Boat</h2>
            <h2>{{ getBoat(datatoedit.boat_enter_parking_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>{{ getBoatStatus(datatoedit.boat_enter_parking_id) }}</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[100px] border-b text-start">No.</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
            </tr>
          </tbody>

          <tbody
            v-for="(group, weighingId) in groupedWeighing"
            :key="weighingId"
          >
            <tr>
              <td
                colspan="4"
                class="text-body font-normal text-primary2 border-b"
              >
                <div class="flex w-full gap-2">
                  <h2 class="text-heading2 font-semibold">Truck No</h2>
                  <h2
                    v-for="data in group[0].rice"
                    class="text-heading2 font-semibold"
                  >
                    {{ data.plate_number }}
                  </h2>
                </div>
              </td>
            </tr>
            <tr
              v-for="data in group"
              :key="data.id"
              class="text-body font-normal text-primary2 bg-white"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">
                <p v-for="rice in data.rice" :key="rice.id">
                  {{ rice.rice_name }}
                </p>
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="text-body font-normal text-primary2 bg-white">
              <td colspan="2" class="font-semibold">Total</td>
              <td class="font-semibold">
                {{ numeralFormat(totalAmountCutOff, "0,0[.]00") }}
              </td>
              <td class="font-semibold">
                {{ numeralFormat(totalWeightIn, "0,0[.]00") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5">
        <button class="btncancel" @click="handleClose">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const rices = ref([]);
    const fetchRicce = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const plate = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.datatoedit.plate_number_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        plate.value = response.data;
        console.log("plate", plate.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const isPlate = computed(() => {
      return plate.value?.[0].plate_numbers;
    });
    const weighing = ref([]);
    const groupedWeighing = ref({});

    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicCondition: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;

        console.log("Grouped Weighing Data:", groupedWeighing.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const allboatHistory = ref([]);
    const fetchallboatHistory = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        allboatHistory.value = response.data;
        groupDataByWeighingId();
        console.log("weighing", allboatHistory.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const groupDataByWeighingId = () => {
      groupedWeighing.value = allboatHistory.value.reduce((acc, item) => {
        const key = item.weighing_id;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
    };

    const baot = ref([]);
    const fetchBoat = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.datatoedit.boat_enter_parking_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        baot.value = response.data;
        console.log("weighing", weighing.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const customer = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: baot.value[0]?.customer_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        customer.value = response.data;
        console.log("weighing", weighing.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const weighingData = ref([]);
    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "id",
            //   operator: "=",
            //   value: props.datatoedit.supplier_id,
            //   typeTable: "table",
            // },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighingData.value = response.data;
        console.log("weighing", weighing.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const getPlateId = (weighing_id) => {
      const weighing = weighingData.value.find((w) => w.id === weighing_id);

      return weighing ? weighing.plate_number_id : "Unknown";
    };
    const Allplate = ref([]);
    const fetchAllPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          // dynamicConditions: JSON.stringify([
          //   {
          //     field: "id",
          //     operator: "=",
          //     value: props.datatoedit.plate_number_id,
          //     typeTable: "table",
          //   },
          // ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        Allplate.value = response.data;
        console.log("plate", Allplate.value);
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    onMounted(async () => {
      if (props.datatoedit) {
        console.log("prop:", props.datatoedit);
      }
      await Promise.allSettled([
        fetchPlates(),
        fetchRicce(),
        fetchWeighing(),
        fetchBoat(),
        fetchSupplier(),
        fetchallboatHistory(),
        fetchWeighingData(),
        fetchAllPlates(),
      ]);
    });
    const getRiceName = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlates = (id) => {
      const name = plate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getAllplate = (id) => {
      const name = Allplate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getBoat = (id) => {
      const name = baot.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getBoatStatus = (id) => {
      const name = baot.value.find((item) => item?.id === id);
      if (name?.is_complete === false) {
        return "Processing";
      } else {
        return "Complete";
      }
    };
    const getSupplierName = (id) => {
      const name = customer.value.find((item) => item?.id === id);
      return name?.name;
    };
    // Computed property to sum up amount_cut_off
    const totalAmountCutOff = computed(() => {
      return weighing.value.reduce(
        (sum, data) => sum + parseFloat(data.amount_cut_off || 0),
        0
      );
    });

    // Computed property to sum up weight_in
    const totalWeightIn = computed(() => {
      return weighing.value.reduce(
        (sum, data) => sum + parseFloat(data.weight_in || 0),
        0
      );
    });

    // const groupedData = computed(() => {
    //   const grouped = {};
    //   allboatHistory.value.forEach((data) => {
    //     const plateId = getPlateId(data.id);
    //     const plateName = getAllplate(plateId);

    //     if (!grouped[plateName]) {
    //       grouped[plateName] = [];
    //     }
    //     grouped[plateName].push(data);
    //   });
    //   return grouped;
    // });
    // const groupedDataWithTotals = computed(() => {
    //   const grouped = {};
    //   allboatHistory.value.forEach((data) => {
    //     const plateId = getPlateId(data.id);
    //     const plateName = getAllplate(plateId);

    //     // Initialize group if it doesn't exist
    //     if (!grouped[plateName]) {
    //       grouped[plateName] = {
    //         items: [],
    //         totalAmountCutOff: 0,
    //         totalWeightIn: 0,
    //         totalWeightOut: 0,
    //         totalRemain: 0,
    //       };
    //     }

    //     // Push the data into the group
    //     grouped[plateName].items.push(data);

    //     // Calculate totals for each group
    //     grouped[plateName].totalAmountCutOff += parseFloat(
    //       data.amount_cut_off || 0
    //     );
    //     grouped[plateName].totalWeightIn += parseFloat(data.weight_in || 0);
    //     grouped[plateName].totalWeightOut += parseFloat(data.weight_out || 0);
    //     grouped[plateName].totalRemain +=
    //       parseFloat(data.weight_in || 0) - parseFloat(data.weight_out || 0);
    //   });

    //   return grouped;
    // });

    return {
      handleClose,
      setActiveTab,
      activeTab,
      weighing,
      getRiceName,
      getPlates,
      getBoat,
      formatDate2,
      getBoatStatus,
      plate,
      isPlate,
      getSupplierName,
      totalWeightIn,
      totalAmountCutOff,
      allboatHistory,
      getPlateId,
      getAllplate,
      weighingData,
      // groupedData,
      // groupedDataWithTotals,
      customer,
      groupedWeighing,
    };
  },
};
</script>

<style></style>
