<template>
    <div class="font-noto text-btc text-bts px-5 sm:px-10 max-w-[840px] mx-auto mt-[32px] xl:mt-[64px] h-screen">
        <div
            class="flex flex-col-reverse sm:flex-row items-center justify-center shadow border p-5 xl:w-1/2 lg:w-1/2 mx-auto">
            <div class="xl:w-[382px] lg:w-[382px] w-full h-auto">
                <form @submit.prevent="handleSubmit" class="space-y-3">
                    <div v-if="logoURL" class="w-[100px] max-h-[100px] mx-auto">
                        <img :src="logoURL ? getImageUrl('company', logoURL) : logoPreview" alt="profile"
                            class="w-24 h-24 object-cover object-center border border-primary-200" />
                    </div>
                    <h1 class="font-bold text-hd3 sm:text-hd2 text-center">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម</h1>
                    <h2 class="text-xl font-bold text-center mb-8">Signup Form</h2>

                    <div class="">
                        <div class="flex space-x-2">
                            <label class="rq-label" for="username">Username </label>
                            <span class="text-red-500 ">*</span>
                        </div>

                        <input class="input" v-model="username" type="text" id="username" required
                            placeholder="Please enter your username" />
                            <span v-if="isUsernameValid" class="text-red-500">Username must be at least 3 characters long</span>
                    </div>

                    <div>
                        <div class="flex space-x-2">
                            <label class="rq-label" for="email">Email</label>
                            <span class="text-red-500">*</span>
                        </div>

                        <input class="input" v-model="email" type="text" id="email" required
                            placeholder="Please enter your email" />
                    </div>

                    <div class="relative">
                        <div>
                            <div class="flex space-x-2">
                                <label class="rq-label" for="password">Password</label>
                                <span class="text-red-500">*</span>
                            </div>

                            <input class="input" v-model="password" :type="passwordFieldType" id="password" required
                                placeholder="Please enter your password" />
                                <span v-if="isPasswordValid" class="text-red-500">Password must be at least 6 characters long</span>
                        </div>
                        <svg @click="togglePassword" v-if="viewPassword === false" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor"
                            class="w-5 text-btc hover:text-btc/80 absolute right-2 top-9 cursor-pointer">
                            <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                            <path fill-rule="evenodd"
                                d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg @click="togglePassword" v-if="viewPassword === true" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor"
                            class="w-5 text-btc hover:text-btc/80 absolute right-2 top-9 cursor-pointer">
                            >
                            <path fill-rule="evenodd"
                                d="M3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06l-1.745-1.745a10.029 10.029 0 003.3-4.38 1.651 1.651 0 000-1.185A10.004 10.004 0 009.999 3a9.956 9.956 0 00-4.744 1.194L3.28 2.22zM7.752 6.69l1.092 1.092a2.5 2.5 0 013.374 3.373l1.091 1.092a4 4 0 00-5.557-5.557z"
                                clip-rule="evenodd" />
                            <path
                                d="M10.748 13.93l2.523 2.523a9.987 9.987 0 01-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 010-1.186A10.007 10.007 0 012.839 6.02L6.07 9.252a4 4 0 004.678 4.678z" />
                        </svg>
                    </div>

                    <div class="flex py-3 items-center justify-center">
                        <button v-if="!isPending" class="btn-submit" >Signup</button>
                        <button v-else class="relative btn-submit whitespace-nowrap flex items-center justify-center">
                            <div>Processing...</div>
                            <div class="absolute top-3 right-2">
                                <div class="mr-2 animate-spin rounded-full h-4 w-4 border-b-2 border-r-2 border-btcha">
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>

import apirURL from '@/services/apiURL';
import { fetchTimestamp } from '@/services/timestamp';

import axios from 'axios';
import {  ref, watch } from 'vue';
import { useRouter } from 'vue-router';
export default {
    name: 'SignUpForm',
    components: {},
    setup() {
        const username = ref('');
        const email = ref('');
        const password = ref('');
        const passwordFieldType = ref('password');
        const viewPassword = ref(false);



        const isPending = ref(false);

        const router = useRouter();

        const isUsernameValid = ref(false);
        watch(username,(newV)=>{
            if(newV.length < 3){
                isUsernameValid.value = true;
            }else{
                isUsernameValid.value = false;
            }
        })

        const isPasswordValid = ref(false);
        watch(password,(newV)=>{
            if(newV.length < 6){
                isPasswordValid.value = true;
            }else{
                isPasswordValid.value = false;
            }
        })


        const togglePassword = () => {
            viewPassword.value = !viewPassword.value;

            if (viewPassword.value) {
                return (passwordFieldType.value = 'text');
            }

            return (passwordFieldType.value = 'password');
        };

        const handleSubmit = async () => {
            try {
                isPending.value = true;
                const timestamp = await fetchTimestamp();
                const requestBody = {
                    tableName: 'users',
                    fields: {
                        username: username.value,
                        name_lowercase: username.value.toLowerCase(),
                        password: password.value,
                        email: email.value,
                        profile_name: username.value,
                        role: "Super Admin",
                        status: true,
                        created_at: timestamp
                    }
                };


                if(isUsernameValid.value){
                    setTimeout(()=>{
                        isPending.value = false;
                    },1000)
                    return;
                }
                if(isPasswordValid.value){

                    setTimeout(()=>{
                        isPending.value = false;
                    },1000)
                    return;
                }
                const response = await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);
                if (response.data.message) {
                    router.push('/login');

                    isPending.value = false;
                }
            } catch (err) {
                isPending.value = false;
                console.log('failed to submit data', err);
            }
        };

        return {
            isPasswordValid,
            isUsernameValid,
            togglePassword,
            handleSubmit,

            username,
            email,
            password,
            viewPassword,
            passwordFieldType,

            isPending
        };
    }
};
</script>
