<template>
  <form @submit.prevent="handleSubmit" class="w-[500px] bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">Rice Convert</h2>
      <div @click="handleClose" class="closePopUp">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <!-- {{ datatoedit }} -->
    <div class="w-full p-5 space-y-4">
      <div class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3">
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-nowrap font-bold">
            <span class="text-red-500">*</span> Warehouse</label>

            <InputText disabled type="text" v-model="selectWarehouse" placeholder="Warehouse"
            class="w-full md:w-[250px]  h-9 text-start flex items-center" />
        </div>
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-nowrap font-bold">
            <span class="text-red-500">*</span> Select Rice</label>
          <div class="flex flex-col">
            <Select :options="rice_stock" option-value="rice.rice_name" filter show-clear option-label="rice.rice_name" :placeholder="'Select rice'" style="width: 250px"
              class="h-9 flex items-center justify-start" v-model="selectRice" />
            <div v-if="isValidateRice" class="mt-2">
              <el-alert title="Please Select the rice" type="error" :closable="false" />
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3 justify-between">
          <label for="name" class="flex gap-1 text-start text-nowrap font-bold">
            <span class="text-red-500">*</span> Current Stock</label>
          <div class="flex flex-col">
            <InputNumber v-model="remainingStock" :maxFractionDigits="2" disabled class="h-[40px] w-[250px]"
              placeholder="Enter Amount" step="0.01" suffix=" Kg" required />
          </div>
        </div>
      </div>
      <div class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3">
        <div>
          <h2 class="text-start">Convert</h2>
        </div>
        <div class="flex flex-col space-y-5">
          <div>
            <div class="flex items-center gap-3 justify-between">
              <label for="name" class="flex gap-1 text-start text-nowrap font-bold">
                <span class="text-red-500">*</span> From</label>
              <div class="flex flex-col">
                <InputNumber v-model="amountFrom" :maxFractionDigits="2" class="h-3 flex items-center w-[250px]"
                  placeholder="Enter Amount" step="0.01" suffix=" Kg" />
                <div v-if="isValidateAmountFrom" class="text-end text-red-500 mt-4">
                  <el-alert title="Please Enter the amount" type="error" :closable="false" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex items-center gap-3 justify-between">
              <label for="name" class="flex gap-1 text-start text-nowrap font-bold">
                <span class="text-red-500">*</span> To</label>
              <div class="flex flex-col">
                <InputNumber v-model="amountTo" :maxFractionDigits="2" class="h-3 flex items-center w-[250px]"
                  placeholder="Enter Amount" step="0.01" suffix=" Kg" />
                <div v-if="isValidateAmountFrom" class="text-end text-red-500 mt-4">
                  <el-alert title="Please Enter the amount" type="error" :closable="false" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-start text-nowrap font-bold">
              <span class="text-red-500">*</span> Less</label>
            <InputNumber v-model="amountLess" :maxFractionDigits="2" class="h-3 flex items-center w-[250px]"
              placeholder="Less Amount" disabled step="0.01" suffix=" Kg" required />
          </div>
        </div>
      </div>
      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending">
          <span v-if="isPending === true" :class="{
            is_pending: isPending,
          }">
          </span>
          Save
        </button>
        <button :disabled="isPendingSave" class="btncancel">
          <span v-if="isPendingSave === true" :class="{
            is_pending_save: isPendingSave,
          }">
          </span>
          Save & New
        </button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
  <component :is="currentComponent" :informMessage="informMessage" :showToast="showToast" @onClose="handleClearToast" />
</template>

<script>
import { validateInputNUmber } from "@/composables/ValidateInput";
import { computed, ref, onMounted, watch } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import { handleValidate } from "@/composables/checkValidatefeild";
import ToastVue from "@/components/ToastVue.vue";
import { useWareHouseStockRiceTransaction } from "@/composables/useWareHouseStockRiceTransaction";
export default {
  components: {
    ToastVue
  },
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const handleClear = () => {
      selectWarehouse.value = null;
      amountTo.value = null;
    };
    const amount = ref();
    const selectWarehouse = ref(null);
    const showToast = ref(false);
    const informMessage = ref("");
    const currentComponent = ref("");

    const selectRice = ref(null);
    const currentStock = ref(null);
    const amountFrom = ref(null);
    const amountTo = ref(null);
    const amountLess = computed(() => {
      if(amountFrom.value && amountTo.value && (amountFrom.value > amountTo.value)){
        const amount_less = amountFrom.value - amountTo.value;
        return amount_less;
      }else{
        return null;
      }
    });

    const remainingStock = computed(() => {
      if(amountFrom.value && amountTo.value && (amountFrom.value > amountTo.value)){
        return currentStock.value - amountLess.value;
      }else{
        return currentStock.value;
      }
    });


    watch(amountFrom, (newVal)=>{
      if(newVal < amountTo.value){
        currentComponent.value = 'ToastVue'
        informMessage.value = `From Weight ${newVal} ${selectRice.value} Can't be smaller than To Weight ${amountTo.value} ${selectRice.value}`
        showToast.value = true;
        setTimeout(() => {
          amountFrom.value = null;
          amountTo.value = null;
          handleClearToast();
        }, 2000);
        return;
      }else if(newVal > currentStock.value){
        currentComponent.value = 'ToastVue'
        informMessage.value = `From Weight ${newVal} ${selectRice.value} Can't be greater than Current Stock ${currentStock.value} ${selectRice.value}`
        showToast.value = true;
        setTimeout(() => {
          amountFrom.value = null;
          amountTo.value = null;
          handleClearToast();
        }, 2000);
        return;
      }
    })


    watch(amountTo, (newVal)=>{
      if(newVal >= amountFrom.value){
        currentComponent.value = 'ToastVue'
        informMessage.value = `To Weight ${newVal} ${selectRice.value} Can't be greater than From Weight ${amountFrom.value} ${selectRice.value}`
        showToast.value = true;
        setTimeout(() => {
          amountTo.value = null;
          handleClearToast();
        }, 2000);
        return;
      }
    })

    const authUserId = ref(null);

    onMounted(async () => {
      await Promise.allSettled([
        fetchWarebyId(props.datatoedit.id),
        fetchRiceStock(),
      ]);

      if (props.datatoedit) {
        selectWarehouse.value = props.datatoedit.name;

      }
   
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
    });
    const warehouse = ref([]);
    const fetchWarebyId = async (id) => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        warehouse.value = response.data;
        console.log("warehouse", warehouse.value);

        return response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };


    function calculateTotalRiceStock(rice_stock, rice_name) {
      // Initialize total
      let total = 0;

      // Sum up all final_weight values for the specified rice_name
      rice_stock.forEach(record => {
        try {
          const riceData = record.rice;
          if (riceData.rice_name === rice_name) {
            total += Number(riceData.final_weight) || 0;
          }
        } catch (error) {
          console.error('Error parsing rice data:', error);
        }
      });

      return total;
    }

    const rice_stock = ref([]);

    const fetchRiceStock = async () => {
      try {
        const req = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
              typeTable: "table",
            },

          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllDataWithJoinMultiple`, {
          params: req,
        });
        if(response.data.length > 0){
          rice_stock.value = response.data;
          console.log("rice_stock", rice_stock.value);
        }else{
          rice_stock.value = [];
        }

      } catch (error) {
        console.error("Error fetching rice stock:", error);
        rice_stock.value = [];
      }
    };
    const isAmountFrom = ref("");
    const validateAmountFrom = () => {
      if (amountFrom.value === null) {
        isAmountFrom.value = "Please Enter the amount";
        return false;
      } else {
        isAmountFrom.value = "";
        return true;
      }
    };
    watch(amountFrom, () => {
      validateAmountFrom();
    });
    const isValidateRice = handleValidate(selectRice.value, selectRice);
    const isValidateAmountFrom = handleValidate(amountFrom.value, amountFrom);
    const isAmountTo = ref("");
    const validateAmountTo = () => {
      if (amountTo.value === null) {
        isAmountTo.value = "Please Enter the amount";
        return false;
      } else {
        isAmountTo.value = "";
        return true;
      }
    };
    watch(amountTo, (newVal) => {
      if (newVal !== null) {
        validateAmountTo();
      } else {
        isAmountTo.value = "";
      }
    });
    const isPending = ref(false);
    const handleSubmit = async () => {
 

      try {

        if (!selectRice.value) {
        isValidateRice.value = true;
        return;
      }
      if (!amountFrom.value || !amountTo.value) {
        isValidateAmountFrom.value = true;
          return;
        }


        if(amountFrom.value > currentStock.value){
          currentComponent.value = 'ToastVue'
            informMessage.value = `From Weight ${amountFrom.value} ${selectRice.value} Can't be greater than Current Stock ${currentStock.value} ${selectRice.value}`
            showToast.value = true;
            return;
        }


        if(amountTo.value > amountFrom.value){
          currentComponent.value = 'ToastVue'
            informMessage.value = `To Weight ${amountTo.value} ${selectRice.value} Can't be greater than From Weight ${amountFrom.value} ${selectRice.value}`
            showToast.value = true;
            return;
        }
        setTimeout(() => {
          isPending.value = true;
        }, 1000);

        const timestamp = await fetchTimestamp();

        const requestBody = {
          tableName: "stock_rice_transaction_history",
          fields: {
            warehouse_id: props.datatoedit.id,
            created_at: timestamp,
            transaction_type: "convert",
            created_by: authUserId.value,
            weight: amountLess.value,
            rice: JSON.stringify(
              {
                weight: currentStock.value,
                original_weight: currentStock.value,
                weight_adjust: null,
                after_adjust_weight: null,
                rice_name: selectRice.value,
                weight_sell: null,
                type: "convert",
                from_weight: amountFrom.value,
                to_weight: amountTo.value,
                final_weight: remainingStock.value,
                transaction_id: null,
              },
            ),
          },
        };

           //update rice transaction history old
           useWareHouseStockRiceTransaction(requestBody, "convert");

    

       

          emit("toast", "convert");
          emit("close");
          handleClear();
        
      } catch (error) {
        console.log(error);
      }
    };

    watch(selectRice, (newRiceName) => {
      if (newRiceName) {
        if(rice_stock.value.length > 0){
          currentStock.value = calculateTotalRiceStock(rice_stock.value, newRiceName);
        }
      }else{
        currentStock.value = null;
      }
   
    });

    const handleClearToast = () => {
      amountFrom.value=null;
      amountTo.value=null;
      amountLess.value=null;
      currentComponent.value = ""
      informMessage.value = ""
      showToast.value = false;
    }

    const isFilterable = ref(false);
    return {
      handleClearToast,
      currentComponent,
      informMessage,
      showToast,
      rice_stock,
      handleClose,
      validateInputNUmber,
      amount,
      selectWarehouse,
      selectRice,
      currentStock,
      amountTo,
      amountFrom,
      amountLess,
      handleSubmit,
      remainingStock,
      isAmountFrom,
      isAmountTo,
      isFilterable,
      warehouse,
      isValidateRice,
      isValidateAmountFrom,
      validateAmountTo,
      isPending,
    };
  },
};
</script>

<style></style>
