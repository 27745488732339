<template>
  <form
    @submit.prevent="handleSubmit"
    class="h-auto w-[800px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ t("message.send_to_parking") }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="bg-default p-3 space-y-3">
      <div class="w-full p-2 bg-white rounded-[4px] border">
        <div class="py-4 gap-3 grid grid-cols-2">
          <div
            class="flex items-center justify-between text-body text-primary6"
          >
            <h2>{{ t("message.created_at") }}</h2>
            <h2>{{ formatDate(datatoedit?.date_in) }}</h2>
          </div>
          <div
            class="flex items-center justify-between text-body text-primary6"
          >
            <h2>{{ t("message.truck") }}</h2>
            <h2>{{ datatoedit?.rice[0]?.plate_number }}</h2>
          </div>
          <div
            class="flex items-center justify-between text-body text-primary6"
          >
            <h2>{{ t("message.boat") }}</h2>
            <h2>
              {{ selectParking ? selectParking?.name : "No Boat" }}
            </h2>
          </div>

          <div
            class="flex items-center justify-between text-body text-primary6"
          >
            <h2>{{ t("message.weight") }}</h2>
            <h2>{{ numeralFormat(datatoedit?.weight_in, "0,0[.]00") }} Kg</h2>
          </div>
          <div
            class="flex items-center justify-between text-body text-primary6"
          >
            <h2>{{ t("message.status") }}</h2>
            <h2>
              {{
                datatoedit.is_hold === true
                  ? t("message.pending")
                  : t("message.send_to_parking")
              }}
            </h2>
          </div>
        </div>
      </div>
      <div class="w-full p-2 bg-white rounded-[4px] border">
        <div class="flex justify-between">
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-semibold">
                <span class="text-red-500">*</span
                >{{ t("message.mark_status") }}
              </label>
              <label
                for="pending"
                class="flex items-center space-x-2 cursor-pointer"
              >
                <input
                  type="radio"
                  name="holdStatus"
                  :value="true"
                  id="pending"
                  v-model="isHold"
                  class="form-radio text-primary3 cursor-pointer"
                />
                <span>{{ t("message.pending") }}</span>
              </label>
              <label
                for="sent"
                class="flex items-center space-x-2 cursor-pointer"
              >
                <input
                  type="radio"
                  name="holdStatus"
                  id="sent"
                  v-model="isHold"
                  :value="false"
                  class="form-radio text-primary3 cursor-pointer"
                />
                <span>{{ t("message.sent") }}</span>
              </label>
            </div>
            <div v-if="isHold === false">
              <!-- select rice -->
              <div class="space-y-3 flex flex-col">
                <div>
                  <div class="flex items-center gap-3 justify-between">
                    <label
                      for="name"
                      class="flex gap-1 text-nowrap font-semibold text-end"
                    >
                      <span class="text-red-500">*</span>
                      {{ t("message.rice_type") }}</label
                    >
                    <Select
                      v-model="type_of_rice"
                      :options="rices"
                      option-value="name"
                      option-label="name"
                      filter
                      show-clear
                      checkmark
                      style="width: 250px"
                      placeholder="Rice type"
                      class="w-full md:w-56 h-9 text-start flex items-center"
                    />
                  </div>
                  <div
                    v-if="isValidateRiceTye"
                    class="w-[250px] mt-2 float-end"
                  >
                    <el-alert
                      title="Please Select the rice type"
                      type="error"
                      :closable="false"
                    />
                  </div>
                </div>

                <!-- note -->
                <div class="flex items-start gap-3 justify-between">
                  <label
                    for="Description"
                    class="flex gap-1 text-nowrap font-semibold text-end"
                    >{{ t("message.notes") }}</label
                  >
                  <textarea
                    type="text"
                    v-model="datatoedit.note"
                    placeholder="Any Description"
                    rows="4"
                    class="w-[250px] px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- sending to -->
          <div v-if="!isHold">
            <div class="flex items-center justify-start gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-semibold">
                <span class="text-red-500">*</span> Send to
              </label>
              <label class="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="destinationType"
                  value="parking"
                  v-model="isGoing"
                  class="form-radio text-primary3 cursor-pointer"
                />
                <span>Parking</span>
              </label>
              <label
                class="flex items-center space-x-2 cursor-pointer"
                v-if="
                  dataWeighingRiceHistory.warehouse_id == null ||
                  dataWeighingRiceHistory.warehouse_id == undefined
                "
              >
                <input
                  type="radio"
                  name="destinationType"
                  value="warehouse"
                  v-model="isGoing"
                  class="form-radio text-primary3 cursor-pointer"
                />
                <span>Warehouse</span>
              </label>
            </div>
            <div v-if="isGoing === 'parking'">
              <div class="space-y-3 flex flex-col">
                <div>
                  <div class="flex items-center gap-3 justify-between">
                    <label
                      for="name"
                      class="flex gap-1 text-nowrap font-semibold text-end"
                    >
                      <span class="text-red-500">*</span> Parking</label
                    >

                    <Select
                      v-model="selectParking"
                      :options="boats"
                      option-label="name"
                      filter
                      show-clear
                      checkmark
                      placeholder="Parking"
                      style="width: 250px"
                      class="w-full md:w-56 h-9 text-start flex items-center"
                    />
                  </div>
                  <div
                    v-if="isValidateParking"
                    class="w-[250px] mt-2 float-end"
                  >
                    <el-alert
                      title="Please Select the parking"
                      type="error"
                      :closable="false"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="space-y-3">
                <div class="flex items-center gap-3 justify-between">
                  <label
                    for="name"
                    class="flex gap-1 text-nowrap font-semibold text-end"
                  >
                    <span class="text-red-500">*</span> Warehouse</label
                  >

                  <Select
                    v-model="selectWarehouse"
                    :options="warehouse"
                    option-value="id"
                    option-label="name"
                    placeholder="Warehouse"
                    filter
                    show-clear
                    checkmark
                    class="w-full md:w-56 h-9 text-start flex items-center"
                  />
                </div>
                <div
                  v-if="isValidateWarehouse"
                  class="w-[250px] mt-2 float-end"
                >
                  <el-alert
                    title="Please Select the warehouse"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ datatoedit }} -->
    <div v-if="isGoing === 'parking'" class="w-full p-5 space-y-4">
      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending" type="submit">
          <span
            v-if="isPending === true"
            :class="{
              is_pending: isPending,
            }"
          >
          </span>
          Save
        </button>

        <button @click="handleClose" type="button" class="btncancel">
          Cencel
        </button>
      </div>
    </div>
    <div v-else class="w-full p-5 space-y-4">
      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending" type="submit">
          <span
            v-if="isPending === true"
            :class="{
              is_pending: isPending,
            }"
          >
          </span>
          Save
        </button>

        <button @click="handleClose" type="button" class="btncancel">
          Cencel
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { formatDate2, formatDate } from "@/composables/formatDateTime";
import { decodeJwt } from "@/composables/decodeJWT";
import { handleValidate } from "@/composables/checkValidatefeild";
import { useI18n } from "vue-i18n";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const pending = ref("pending");
    const isHold = ref(true);
    const isGoing = ref("parking");
    const isOpion = ref("parking");
    const rices = ref([]);
    const type_of_rice = ref(null);
    const weight_in = ref(null);
    const isFilterable = ref(false);
    const isSelectParking = ref(false);
    const isSelectWarehouse = ref(false);
    const isSelectRice = ref(false);
    const isPending = ref(false);
    const isPendingSave = ref(false);
    const handleClear = () => {
      selectParking.value = null;
      selectRice.value = null;
      selectWarehouse.value = null;
    };

    watch(type_of_rice, (newVal) => {
      if (newVal) {
        isSelectRice.value = false;
        isValidateRiceTye.value = false;
      }
    });

    const handleClose = () => {
      emit("close");
    };
    const selectRice = ref(null);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const suppliers = ref([]);
    const selectSuppier = ref(null);
    const fetchSipplers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: props.datatoedit[0]?.weighing_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const selectWarehouse = ref(null);
    const warehouse = ref([]);
    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const selectParking = ref(null);
    const boats = ref([]);
    const plate_numbers = ref(null);
    const fetchBoat = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_complete",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "is_customer_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boats.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const plates = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        plates.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    watch(selectParking, (newVal) => {
      if (newVal) {
        isSelectParking.value = false;
        isValidateParking.value = false;
      }
    });

    watch(selectWarehouse, (newVal) => {
      if (newVal) {
        isSelectWarehouse.value = false;
        isValidateWarehouse.value = false;
      }
    });

    watch(selectRice, (newVal) => {
      if (newVal) {
        isSelectRice.value = false;
        isValidateRiceTye.value = false;
      }
    });

    watch(isGoing, (newVal) => {
      if (newVal === "parking") {
        selectWarehouse.value = null;
        isValidateWarehouse.value = false;
      } else {
        selectParking.value = null;
        isValidateParking.value = false;
      }
    });

    const isValidateRiceTye = handleValidate(type_of_rice.value);
    const isValidateParking = handleValidate(selectParking.value);
    const isValidateWarehouse = handleValidate(selectWarehouse.value);

    const handleSubmit = async () => {
      if (isHold.value) {
        return;
      }
      if (!type_of_rice.value) {
        isValidateRiceTye.value = true;
        return;
      }
      if (
        !selectParking.value &&
        isHold.value === false &&
        isGoing.value === "parking"
      ) {
        isValidateParking.value = true;
        return;
      }
      if (
        isHold.value === false &&
        isGoing.value === "warehouse" &&
        !selectWarehouse.value
      ) {
        isValidateWarehouse.value = true;
        return;
      }
      setTimeout(() => {
        isPending.value = true;
        setTimeout(() => {
          isPending.value = false;
        }, 1000);
      }, 1000);
      try {
        {
          const timestamp = await fetchTimestamp();
          const reqBody = {
            tableName: "weighing_rice_history",
            fields: {
              created_at: timestamp,
              date_in: timestamp,

              rice: JSON.stringify([
                {
                  rice_name: type_of_rice.value,
                  plate_number: plate_numbers.value,
                },
              ]),
              boat_enter_parking_id: selectParking.value
                ? selectParking.value?.id
                : null,
              status: false,
              service_type: "internal",
              is_hold: isHold.value ? true : false,
              warehouse_id: selectWarehouse.value
                ? selectWarehouse.value
                : null,
              created_by: authUserId.value,
              weight_in: props.datatoedit.weight_in,
              weight_out: props.datatoedit.weight_out,
              amount_cut_off: props.amount_cut_off,
              updated_at: timestamp,
            },
          };
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.weighing_rice_history_id}`,
            reqBody
          );
          if (response.data.message) {
            const req = {
              tableName: "weighing",
              fields: {
                weight_in: props.datatoedit.weight_in,
                boat_enter_parking_id: selectParking.value
                  ? selectParking.value?.id
                  : null,
                is_hold: true,
                warehouse_id:
                  dataWeighingRiceHistory.value.warehouse_id == null
                    ? selectWarehouse.value
                    : dataWeighingRiceHistory.value.warehouse_id,
                rice: JSON.stringify([
                  {
                    rice_name: type_of_rice.value,
                    plate_number: plate_numbers.value
                      ? plate_numbers.value
                      : null,
                  },
                ]),
              },
            };

            await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.weighing_id}`,
              req
            );
          }

          // insert to stock_rice_transaction_history
          //  if (selectWarehouse.value) {
          //     const requestBody = {
          //       tableName: "stock_rice_transaction_history",
          //       fields: {
          //         warehouse_id: selectWarehouse.value,
          //         created_at: timestamp,
          //         weighing_id: props.datatoedit.weighing_id,
          //         transaction_type: "purchase",
          //         created_by: authUserId.value,
          //         weight: props.datatoedit.weight_in,
          //         rice: JSON.stringify(
          //           {
          //             weight: props.datatoedit.weight_in,
          //             original_weight: null,
          //             weight_adjust: null,
          //             after_adjust_weight: null,
          //             rice_name: type_of_rice.value,
          //             weight_sell:null,
          //             type: "purchase",
          //             from_weight: null,
          //             to_weight: null,
          //             final_weight: props.datatoedit.weight_in,
          //             transaction_id: null,
          //           },
          //         ),
          //       },
          //     };

          //     await axios.post(
          //       `${apirURL}/weighing/api/insertData`,
          //       requestBody
          //     );
          //   }

          // insert to stock_rice_transaction_history

          emit("toast", "create");
          handleClear();
          emit("close");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const authUserId = ref(null);
    const dataWeighingRiceHistory = ref(null);
    onMounted(async () => {
      await Promise.allSettled([
        fetchRiceType(),
        fetchSipplers(),
        fetchWarehouse(),
        fetchBoat(),
        fetchPlates(),
        fetchWeighing(),
      ]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.datatoedit) {
        dataWeighingRiceHistory.value = props.datatoedit;
        console.log(
          "dataWeighingRiceHistory.value",
          dataWeighingRiceHistory.value
        );
        type_of_rice.value = props.datatoedit.rice[0]?.rice_name;
        plate_numbers.value = props.datatoedit.rice[0]?.plate_number;
        weight_in.value = props.datatoedit.weight_in;
        isHold.value = props.datatoedit.is_hold;
        console.log("props.datatoedit", props.datatoedit);
      }
    });
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      dataWeighingRiceHistory,
      formatDate,
      isPending,
      isSelectParking,
      isSelectWarehouse,
      isSelectRice,

      pending,
      isHold,
      isGoing,
      isOpion,
      rices,
      isFilterable,
      suppliers,
      warehouse,
      selectRice,
      selectSuppier,
      selectWarehouse,
      type_of_rice,
      selectParking,
      boats,
      handleSubmit,
      weight_in,

      handleClose,
      formatDate2,
      isValidateWarehouse,

      isValidateRiceTye,
      isValidateParking,
      isPendingSave,
      isPending,
      // translate functions
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>
