<template>
  <div class="input-group ">
    <input type="text" class="form-control" v-model="message" />
    <select class="form-select" v-model="selectedp">
      <option v-for="p in partners" :key="p.id" :value="p.chat_id">{{ p.phone_number }}</option>
    </select>
    <button class="btn btn-primary" @click="sendMessage">Send to Telegram</button>
  </div>
</template>

<script>
import axios from 'axios'
import { onMounted, ref } from 'vue';
export default {
  setup() {

    const botToken = ref("nnnnnIIcuuu")
    const partners=ref([]);
    const updateBotToken = async () => {
      await axios
        .post("http://localhost:3000/weighing/api/updateBotToken", {
          botToken: botToken.value,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const sendMessage = async () => {
      await axios.post(`http://localhost:3000/weighing/api/messageTelegram/${1014342560}`, {
        message: 'Love You boss',
      })
        .then((response) => {
          console.log("Message sent successfully:", response.data);
        })
        .catch((error) => {
          console.error("There was a problem sending the message:", error);
        });
    };


    //for line 
    const updateLineToken = async () => {
      await axios
        .post("http://localhost:3000/weighing/api/updateLineTokenSecret", {
          lineToken: lineToken.value,
          lineSecret: lineSecret.value
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const sendMessageLine = async () => {
      try {
        const url = 'http://localhost:3000/weighing/api/messageLine'; //
        const data = {
          pId: "234234",
          message: "hellow",
        }

        const response = await axios.post(url, data);

        if (response.status === 200) {
          console.log(response.data.message);

        } else {
          console.error('Error sending message:', response.data);
          // Handle error (e.g., show error message)
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle general error
      }
    };

    const fetchps=async()=>{
      try{
        const params = {
          tableName: "partners",
          columnAliases:[],
          sortColumn: "id",
          sortDirection: "ASC",
          joinTableName: "",
          joinColumnChild: "", //original column
          joinColumnParent: "", //original column,
          joinType: "",
          dynamicConditions: JSON.stringify([
          
          ]),
        };

        const response=await axios.get('http://localhost:3000/weighing/api/getAllData',{params});
        console.log("response",response.data);

        partners.value=response.data;

      }catch(err){
        console.log("failed to fetch data",err);
      }
    }

    onMounted(()=>{
      fetchps();
    })

    return {
      partners,
      sendMessage
    }
  }

}
</script>

<style></style>