<template>
  <div class="bg-white">
    <div class="details_title">
      <h2 class="ml-4">Rice Type</h2>
      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Detail</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="py-4 gap-4 grid grid-cols-2 border-b-2 border-primary6">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Rice Type</h2>
          <div
            v-for="(transactions, riceName) in groupByRiceName"
            :key="riceName"
          >
            <h3>{{ riceName }}</h3>
          </div>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Warehouse ID</h2>
          <h2>{{ datatoedit.id }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Warehouse</h2>
          <h2>{{ datatoedit?.name }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <div class="w-full bg-white h-[300px] border overflow-auto">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="border-b text-start">Total Purchase</td>
                <td class="border-b text-start">Total Sell</td>
                <td class="border-b text-start">Total Adjust</td>
                <td class="border-b text-start">Total Convert</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="(stock, index) in history"
                :key="index"
              >
                <td class="border text-start">
                  {{
                    stock.rice?.type === "purchase"
                      ? numeralFormat(stock.rice.final_weight, "0,0[.]00")
                      : "-"
                  }}
                  {{ stock.rice?.type === "purchase" ? "Kg" : "" }}
                </td>

                <td class="border text-start">
                  <p>
                    {{
                      stock.rice?.type === "sell"
                        ? numeralFormat(stock.rice.final_weight, "0,0[.]00")
                        : "-"
                    }}
                    {{ stock.rice?.type === "sell" ? "Kg" : "" }}
                  </p>
                </td>
                <td class="border text-start">
                  {{
                    stock.rice?.type === "convert"
                      ? numeralFormat(stock.rice.final_weight, "0,0[.]00")
                      : "-"
                  }}
                  {{ stock.rice?.type === "convert" ? " Kg" : "" }}
                </td>
                <td class="border text-start">
                  {{
                    stock.rice?.type === "convert"
                      ? numeralFormat(stock.rice.final_weight, "0,0[.]00")
                      : "-"
                  }}
                  {{ stock.rice?.type === "convert" ? " Kg" : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4">
          <button @click="handleClose" class="btncancel">Cencel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
export default {
  props: ["datatoedit", "groupedRice", "totalWeight", "warehouse_id"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const getTotalCurrentWeight = (stock) => {
      return stock.reduce((total, item) => {
        return total + (parseFloat(item.current_weight) || 0);
      }, 0);
    };
    const history = ref([]);
    const fetchPurchaseHistory = async () => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: props.datatoedit?.id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );
        history.value = response.data.data;
        console.log("data", history.value);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    const warehouse = ref([]);
    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.warehouse_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        warehouse.value = response.data;
        console.log("wearehouse", warehouse.value);
      } catch (error) {
        console.error("Error fetching warehouse:", error);
      }
    };
    const groupByRiceName = computed(() => {
      return history.value.reduce((acc, transaction) => {
        const riceName = transaction.rice?.rice_name;

        if (!riceName) return acc;

        if (!acc[riceName]) {
          acc[riceName] = [];
        }

        acc[riceName].push(transaction);
        return acc;
      }, {});
    });

    onMounted(async () => {
      await Promise.allSettled([fetchWarehouse(), fetchPurchaseHistory()]);
      if (props.groupedRice) {
        console.log("groupedRice", props.groupedRice);
      }
    });

    return {
      handleClose,
      warehouse,
      history,
      groupByRiceName,
    };
  },
};
</script>

<style></style>
