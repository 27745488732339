<template>
  <TransitionRoot :show="visible" as="template">
    <TransitionChild
      as="template"
      enter="transition ease-out duration-300"
      enter-from="transform opacity-0 translate-y-2"
      enter-to="transform opacity-100 translate-y-0"
      leave="transition ease-in duration-300"
      leave-from="transform opacity-100 translate-y-0"
      leave-to="transform opacity-0 translate-y-2"
    >
      <div
        class="w-full h-screen fixed top-0 right-0 bg-none z-50 flex items-center justify-center"
      >
        <div
          class="fixed justify-center top-20 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded shadow-lg text-white cursor-pointer text-nowrap"
          :class="['toast', type]"
          @click="closeToast"
        >
          {{ message }}
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import { ref, defineExpose } from "vue";
import { TransitionRoot, TransitionChild } from "@headlessui/vue";

const visible = ref(false);
const message = ref("");
const type = ref("");

const showToast = (msg, toastType = "success", duration = 2000) => {
  message.value = msg;
  type.value = toastType;
  visible.value = true;
  setTimeout(() => {
    visible.value = false;
  }, duration);
};

const closeToast = () => {
  visible.value = false;
};

defineExpose({
  showToast,
});
</script>

<style scoped>
.toast {
  transition: all 0.3s ease-in-out;
}

.toast.success {
  @apply bg-green-500;
}

.toast.error {
  @apply bg-red-500;
}

.toast.infor {
  @apply bg-primary3;
}
</style>
