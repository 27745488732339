<template>
  <div class="w-[850px] h-[700px]">
    <div class="details_title">
      <h2 class="ml-4" v-for="truck in weighing">
        Truck: {{ truck.plate_number.plate_number }}
      </h2>
      <h2 class="mr-4">Truck Preview</h2>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'pop_up_tab w-1/2': activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'pop_up_tab w-1/2': activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
    </div>

    <div v-if="activeTab === 'Summary'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            v-for="supplier in weighing"
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Supplier Name</h2>
            <h2>{{ getSupplierName(supplier?.supplier_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(isCutOutData?.created_at) }}</h2>
          </div>
          <div
            v-for="truck in weighing"
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Truck</h2>
            <h2>{{ truck.plate_number.plate_number }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>
              {{
                isCutOutData.is_hold === false ? "Send to parking" : "Pending"
              }}
            </h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No.</td>
              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <!-- <td class="min-w-[100px]  border-b text-start">
                Weigh In
              </td>
              <td class="min-w-[100px]  border-b text-start">
                Weigh out
              </td>
              <td class="min-w-[100px]  border-b text-start">Weight</td> -->
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-if="isCutOutData"
            >
              <td class="border-b">{{ isCutOutData?.id }}</td>
              <td class="text-nowrap border-b">
                {{ formatDate2(isCutOutData.created_at) }}
              </td>
              <td class="border-b">
                <p v-for="rice in isCutOutData.rice">
                  <span>{{ rice.rice_name }}</span>
                </p>
              </td>
              <!-- <td>
                {{ numeralFormat(isCutOutData.weight_out, "0,0[.]00") }} Kg
              </td>
              <td>
                {{ numeralFormat(isCutOutData.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td>
                {{ numeralFormat(isCutOutData.weight_in, "0,0[.]00") }} Kg
              </td> -->
              <td class="border-b">
                {{ numeralFormat(isCutOutData.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(isCutOutData.weight_drop, "0,0[.]00") }}
                Kg
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="activeTab === 'details'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            v-for="supplier in weighing"
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Supplier Name</h2>
            <h2>{{ getSupplierName(supplier?.supplier_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(isCutOutData?.created_at) }}</h2>
          </div>
          <div
            v-for="truck in weighing"
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Truck</h2>
            <h2>{{ truck.plate_number.plate_number }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>
              {{
                isCutOutData.is_hold === false ? "Send to parking" : "Pending"
              }}
            </h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction History</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No.</td>
              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Weigh In</td>
              <td class="min-w-[100px] border-b text-start">Weigh out</td>
              <td class="min-w-[100px] border-b text-start">Weight</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
            </tr>
          </tbody>
          <tbody v-if="isCutOutData">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing_rice_history"
            >
              <td class="border-b text-start">{{ data?.id }}</td>
              <td class="text-nowrap border-b">
                {{ formatDate2(data.created_at) }}
              </td>
              <td class="border-b text-start">
                <p v-for="rice in data.rice">
                  <span>{{ rice.rice_name }}</span>
                </p>
              </td>
              <td class="border-b text-start">
                {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-start">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-start">
                {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-start">
                {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }}
                Kg
              </td>
              <td class="border-b text-start">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }}
                Kg
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex gap-3 mt-6 ml-5">
      <button @click="handleClose" class="btncancel">Close</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  props: ["isCutOutData"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("Summary");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.isCutOutData.weighing_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const weighing_rice_history = ref([]);
    const fetchWeighingHistory = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing_rice_history.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const rices = ref([]);
    const fetchRicce = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const plate = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        plate.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const getRiceName = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlates = (id) => {
      const name = plate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    onMounted(() => {
      if (props.isCutOutData) {
        props.isCutOutData;
      }
      fetchWeighing();
      fetchRicce();
      fetchPlates();
      fetchSupplier();
      fetchWeighingHistory();
    });
    return {
      handleClose,
      setActiveTab,
      activeTab,
      weighing,
      formatDate2,
      getRiceName,
      getPlates,
      getSupplierName,
      weighing_rice_history,
    };
  },
};
</script>

<style></style>
