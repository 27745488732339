<template>
  <div class="flex items-center justify-between w-full px-2 bg-white py-2">
    <div class="flex items-center gap-3">
      <h2>Total of {{ totalRecord }} items</h2>
      <!-- <el-select
        v-model="pageSize"
        @change="emitPageSizeChange"
        placeholder="Select"
        style="width: 70px"
      >
        <el-option
          v-for="size in [50, 75, 100, 200]"
          :key="size"
          :label="size"
          :value="size"
        /> -->
      <el-select
        v-model="pageSize"
        @change="emitPageSizeChange"
        placeholder="Select"
        style="width: 70px"
      >
        <el-option
          v-for="size in [50, 75, 100, 200, 500]"
          :key="size"
          :label="size"
          :value="size"
        />
      </el-select>
    </div>
    <div class="flex items-center gap-3">
      <svg
        :disabled="currentPage === 1"
        @click="emitPageChange(currentPage - 1)"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 cursor-pointer hover:text-primary3 duration-300"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5 8.25 12l7.5-7.5"
        />
      </svg>
      <div class="page-numbers">
        <button
          v-for="page in truncatedPages"
          :key="page"
          @click="emitPageChange(page)"
          :class="{
            'text-primary3 bg-default border !border-none cursor-pointer':
              page === currentPage,
            'text-primary2 !bg-default border !border-none cursor-pointer':
              page !== currentPage,
          }"
        >
          {{ page }}
        </button>

        <svg
          :disabled="currentPage === totalPages"
          @click="emitPageChange(currentPage + 1)"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6 cursor-pointer hover:text-primary3 duration-300"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { useRoute } from "vue-router";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
export default {
  props: [
    "currentPage",
    "totalPages",
    "pageSize",
    "totalRecord",
    "tableName",
    "isFilter",
  ],

  emits: ["page-change", "page-size-change"],
  setup(props, { emit }) {
    const pageSize = ref(props.pageSize);

    const getTruncatedPages = () => {
      const total = props.totalPages;
      const current = props.currentPage;
      const delta = 2;
      const range = [];

      for (
        let i = Math.max(2, current - delta);
        i <= Math.min(total - 1, current + delta);
        i++
      ) {
        range.push(i);
      }

      if (current - delta > 2) {
        range.unshift("...");
      }
      if (current + delta < total - 1) {
        range.push("...");
      }

      range.unshift(1);
      if (total > 1) {
        range.push(total);
      }

      return range;
    };

    // const isEllipsis = (page) => page === "...";

    // const getHiddenPages = (ellipsis) => {
    //   if (ellipsis === "...") {
    //     const total = props.totalPages;
    //     const current = props.currentPage;
    //     const delta = 2;
    //     const range = [];

    //     if (current - delta > 2) {
    //       for (let i = 2; i < current - delta; i++) {
    //         range.push(i);
    //       }
    //     }

    //     if (current + delta < total - 1) {
    //       for (let i = current + delta + 1; i < total; i++) {
    //         range.push(i);
    //       }
    //     }

    //     return range;
    //   }
    //   return [];
    // };

    watch(
      () => props.isFilter,
      (newVal) => {
        if (newVal === true) {
          emit("page-change", props.currentPage);
        }
      }
    );

    watch(
      () => props.isFilter,
      (newVal) => {
        if (newVal === true) {
          emit("page-change", props.currentPage);
        }
      }
    );

    const truncatedPages = computed(() => getTruncatedPages());

    const emitPageChange = (page) => {
      if (page > 0 && page <= props.totalPages && page !== "...") {
        emit("page-change", page);
      }
    };

    const emitPageSizeChange = (size) => {
      emit("page-size-change", parseInt(size, 10));
    };

    watch(pageSize, (newSize) => {
      emitPageSizeChange(newSize);
    });

    const fetchTotalData = async (name) => {
      try {
        const req = {
          tableName: name,
          sortColumn: "id",
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const route = useRoute();
    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount

      switch (route.path) {
        case "/supplier":
          await fetchTotalData("suppliers");
          break;
        case "/client":
          await fetchTotalData("customers");
          break;
        case "/parking":
          await fetchTotalData("parking_rice");
          break;
        case "/trucks":
          await fetchTotalData("plates");
          break;
        case "/warehouse":
          await fetchTotalData("warehouse");
          break;
        case "/ricetyesetting":
          await fetchTotalData("rices");
          break;
        case "/user":
          await fetchTotalData("users");
          break;

        case "/preLoan":
          await fetchTotalData("preloans");
        case "/wallet":
          await fetchTotalData("partner_transaction_history");
          break;
        case "/formtruck":
          await fetchTotalData("weighing");
          break;

        case "/toparking":
          await fetchTotalData("weighing");
          break;
        case "/walletTransaction":
          await fetchTotalData("partner_transaction_history");
          break;
        case "/rentalparking":
          await fetchTotalData("weighing");
          break;
        case "/supplilerbilltransaction":
          await fetchTotalData("supplier_invoice");
          break;
        default:
          console.log("No matching route found.");
          break;
      }
      if (props.totalRecord) {
        console.log("tolal record", props.totalRecord);
      }
    });

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
      socket.off("lastRecordDeleted");
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);
      console.log("Table classes:", classes);
      return classes;
    });

    return {
      truncatedPages,
      pageSize,
      emitPageChange,
      navClasses,
      // onHoverEllipsis,
      // hideHiddenPages,
      // getHiddenPages,
      // isEllipsis,
    };
  },
};
</script>

<style scoped>
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-numbers button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  color: black;
}

.page-numbers button.active {
  background-color: #007bff;
  color: black !important;
}

.page-numbers button:not(.active):hover {
  background-color: #f0f0f0;
  color: black;
}

.hidden-pages {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: 10;
}

.page-numbers button:hover .hidden-pages {
  display: block;
}
</style>
