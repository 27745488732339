<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="handleBack" class="btncancel hidden-print">
            Back
          </button>
        </div>
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <div class="w-full mt-4 bg-white p-8 print-header" id="printTable">
        <div class="flex justify-between items-start">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Parking Rental Slip</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12px]">011 111 111 / 012 123 456</h2>
          </div>
          <div class="text-primary5 text-[10px] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[10px] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4"
        >
          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>28-May-2024</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Slip No.</h2>
            <h2>SL-{{ invoice[0]?.id }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Supplier</h2>
            <h2>{{ getSupplier(invoice[0]?.supplier_id) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Status</h2>
            <!-- <h2>{{ invoice[0]?.status }}</h2> -->
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Phone Number</h2>
            <h2>011 111 111</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
        </div>
        <div class="py-2">
          <h2>Parking Occupied</h2>
          <div class="relative mt-4 bg-white text-[10px]">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[70px] text-nowrap border text-start">No</td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Description
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Weight
                  </td>
                </tr>
              </tbody>
              <tbody
                v-for="(data, dataIndex) in invoice[0]?.parkings"
                :key="dataIndex"
              >
                <tr class="text-[10px] font-normal text-primary2 text-nowrap">
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ dataIndex + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ getParking(data.parking_id) }}
                    {{ data.boat_name || "" }}
                    ({{ formatDate2(data.data_in) }})
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.weight, "0,0[.]00") }} Kg
                  </td>
                </tr>
                <tr
                  v-for="(truck, truckIndex) in data.TruckList"
                  :key="truckIndex"
                >
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ truckIndex + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ truck.truck }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(truck.weight, "0,0[.]00") }} Kg
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-[50%] float-end space-y-2">
          <div class="w-full flex items-center justify-end text-[10px]">
            <div class="w-[60%] flex items-center justify-between">
              <div class="w-1/2 flex justify-end gap-4">
                <h2>Total Weight</h2>
              </div>
              <div class="w-1/2 flex justify-end items-end gap-2">
                <h2>
                  {{ numeralFormat(invoice[0]?.total_weight, "0,0[.]00") }}
                </h2>
                <h2 class="text-primary2 text-body">Kg</h2>
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-end text-[10px]">
            <div class="w-[60%] flex items-center justify-between border-b">
              <div class="w-1/2 flex justify-end gap-4">
                <h2>Cost / Kg</h2>
              </div>
              <div class="w-1/2 flex justify-end items-end gap-2">
                <h2>{{ numeralFormat(invoice[0]?.price, "0,0[.]00") }}</h2>
                <h2 class="text-primary2 text-body">KHR</h2>
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-end">
            <div class="w-[60%] flex items-center justify-between text-[10px]">
              <div class="w-1/2 flex justify-end gap-4">
                <h2>Total</h2>
              </div>
              <div class="w-1/2 flex justify-end gap-4">
                <h2>
                  {{ numeralFormat(invoice[0]?.amount_paid, "0,0[.]00") }} KHR
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[90px] mt-12"
      >
        <h2 class="text-primary5 text-[10px]">Accepted</h2>
        <h2 class="text-primary5 text-[10px]">Issued</h2>
      </div>
      <div
        class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[90px]"
      >
        <h2 class="text-primary5 text-[10px]">Mr. A</h2>
        <h2 class="text-primary5 text-[10px]">Mr. Industry</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  setup() {
    const router = useRouter();
    const handlePrint = () => {
      window.print();
    };
    const handleBack = () => {
      router.go(-1);
    };
    const route = useRoute();
    console.log("route", route.params.id);

    const invoice = ref([]);
    const fetchInvoice = async () => {
      try {
        const req = {
          tableName: "service_external",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: Number(route.params.id),
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        invoice.value = res.data;
        console.log("invoice", invoice.value);
      } catch (error) {
        console.log("error", error);
      }
    };
    const parking = ref([]);
    const fetchParking = async () => {
      const req = {
        tableName: "parking_rice",
        sortColumn: "id",
      };
      const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
        params: req,
      });
      parking.value = res.data;
    };
    const supplier = ref([]);
    const fetchSupplier = async () => {
      const req = {
        tableName: "suppliers",
        sortColumn: "id",
      };
      const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
        params: req,
      });
      supplier.value = res.data;
    };
    const getParking = (id) => {
      const name = parking.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getSupplier = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.name;
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchInvoice(),
        fetchParking(),
        fetchSupplier(),
      ]);
    });
    return {
      handlePrint,
      invoice,
      formatDate2,
      getParking,
      getSupplier,
      handleBack,
    };
  },
};
</script>

<style lang="scss" scoped></style>
