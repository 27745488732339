<template>
  <Toast ref="toast" />

  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Customer</label
        >
        <Select
          v-model="selectCustomer"
          class="w-full md:w-56 h-9 text-start flex items-center"
          placeholder="Select Customer "
          :options="suppliers"
          filter
          option-label="name"
          option-value="id"
          show-clear
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div :class="navClasses">
      <div>
        <h2>Rental List</h2>
      </div>
      <div>
        <button @click="handleAdd">New Rental</button>
      </div>
    </div>
    <div :class="tableClasses" class="h-[500px]">
      <table>
        <thead>
          <tr class="header_table">
            <td
              @click="sortData('id')"
              class="w-[120px] text-start text-nowrap border-b toggle_sort"
            >
              Id
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[100px] border-b text-start text-nowrap">
              Created At
            </td>
            <td
              @click="sortData('supplier_name')"
              class="w-[120px] text-start text-nowrap border-b toggle_sort"
            >
              Customer
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'supplier_name' &&
                      sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[100px] border-b text-start text-nowrap">
              Status
            </td>
            <td
              @click="sortData('cost')"
              class="w-[120px] text-start text-nowrap border-b toggle_sort"
            >
              Cost
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'cost' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('weight')"
              class="w-[120px] text-start text-nowrap border-b toggle_sort"
            >
              Total Weight Rice
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumns === 'weight' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[100px] border-b text-start text-nowrap">
              Date out
            </td>

            <td class="border-b">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  Note
                  <span class="ml-2 cursor-pointer"
                    ><font-awesome-icon :icon="['fas', 'sort']"
                  /></span>
                </div>
              </div>
            </td>
            <td class="border">Acion</td>
          </tr>
        </thead>
        <tbody v-if="rental.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in rental"
            :key="data"
          >
            <td class="border-b">{{ data.id }}</td>
            <td class="border-b">{{ formatDate2(data.created_at) }}</td>
            <td class="border-b">{{ data.supplier_name }}</td>
            <td class="border-b">
              <el-button
                @click="handlePopupDetails(data, data.id)"
                :type="
                  getOverallStatus(data) === 'Pending'
                    ? 'danger'
                    : getOverallStatus(data) === 'In Progress'
                    ? 'primary'
                    : 'success'
                "
                round
              >
                {{ getOverallStatus(data) }}
              </el-button>
            </td>

            <td class="border-b">
              {{ numeralFormat(getTotalCost(data), "0,0[.]00") }} ៛
            </td>
            <td class="border-b text-nowrap">
              {{ numeralFormat(getTotalWeightRice(data), "0,0[.]00") }} Kg
            </td>
            <td class="border-b text-nowrap">
              {{ formatDate2(data.data_out) }}
            </td>

            <td class="border-b text-nowrap">
              {{ data.note ? data.note : "No Data" }}
            </td>
            <td class="border">
              <div class="flex space-x-3">
                <svg-icon
                  @click="handleEdit(data)"
                  :path="mdiPencil"
                  type="mdi"
                  class="edit"
                ></svg-icon>
                <svg-icon
                  :path="mdiTrashCanOutline"
                  @click="openDeleteConfirmation(data)"
                  type="mdi"
                  class="delete"
                ></svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="rental.length > 0"
      class="w-full flex items-center justify-between mt-8"
    >
      <Pagination
        :currentPage="currentPage"
        :pageSize="pageSize"
        :totalPages="totalPages"
        @handlePageChange="handlePageChange"
        @handlePageSizeChange="handlePageSizeChange"
        :totalRecord="totalRecord"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetails" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                    :doc_id="doc_id"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :itemId="itemIdToDelete"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import RentalForm from "@/form/RentalForm.vue";
import ParkingRentalDetail from "@/form/ParkingRentalDetail.vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import ParkingRentalConfirm from "@/form/ParkingRentalConfirm.vue";
import socket from "@/services/socket";
import Toast from "@/components/Toast.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import Pagination from "@/components/Pagination.vue";
import { mdiTrashCanOutline, mdiPencil, mdiEyeOutline } from "@mdi/js";
import moment from "moment-timezone";
import toggleSort from "@/composables/sortData";
import { decodeJwt } from "@/composables/decodeJWT";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
export default {
  components: {
    RentalForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ParkingRentalDetail,
    ParkingRentalConfirm,
    Toast,
    DeleteConfrimation,
    Pagination,
    NoDataComponet,
  },

  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "RentalForm";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      datatoedit.value = null;
    };
    const isDetails = ref(false);
    const datatoedit = ref(null);
    const doc_id = ref(null);
    const handlePopupDetails = (doc) => {
      currentComponent.value = "ParkingRentalConfirm";
      isDetails.value = true;
      doc_id.value = doc.id;
      datatoedit.value = doc;
    };
    const handleCloseDetails = () => {
      currentComponent.value = "";
      isDetails.value = false;
    };
    const handleEdit = (doc) => {
      currentComponent.value = "RentalForm";
      isOpen.value = true;
      datatoedit.value = doc;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const selectCustomer = ref(null);
    const integerSearchValue = ref(null);
    const isLastRecordOnPage = ref(null);
    const totalRecord = ref(0);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("weighing");
    const rental = ref([]);
    const searchValue = ref("");
    const fetctRentalParkingByWeighing = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: table.value,
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnAliases: JSON.stringify({
            id: { table: "weighing", column: "id" },
            supplier_name: { table: "suppliers", column: "name" },
            created_by: { table: "weighing", column: "created_by" },
            supplier_id: { table: "suppliers", column: "id" },
          }),
          sortColumn: "id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),
            ...(selectCustomer.value
              ? [
                  {
                    field: "supplier_id",
                    operator: "=",
                    value: selectCustomer.value,
                  },
                ]
              : []),
            ...(integerSearchValue.value
              ? [
                  {
                    field: "id", // Or any integer field you want to search
                    operator: "=",
                    value: parseInt(integerSearchValue.value, 10),
                  },
                ]
              : []),
            {
              field: "service_external_type",
              operator: "=",
              value: "rental_parking",
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
            {
              field: "is_external_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios(`${apirURL}/weighing/api/getPagination`, {
          params,
        });
        rental.value = [];
        rental.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
        totalRecord.value = rental.value.length;
        console.log("rental", rental.value);
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleFilter = () => {
      fetctRentalParkingByWeighing();
    };
    watch(integerSearchValue, async (value) => {
      await fetctRentalParkingByWeighing();
    });
    const getTotalCost = (data) => {
      return data.rental_parking?.reduce((acc, curr) => acc + curr.cost, 0);
    };
    const getTotalWeightRice = (data) => {
      return data.rental_parking?.reduce((acc, curr) => acc + curr.weight, 0);
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetctRentalParkingByWeighing(),
        fetchUserByID(),
      ]);
    };
    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      socket.on("database_realTime", handleDatabaseUpdate);
      await Promise.allSettled([
        fetctRentalParkingByWeighing(),
        fetchSupplier(),
        fetchUserByID(),
      ]);
    });
    onUnmounted(() => {
      socket.off("database_realTime");
    });

    const getOverallStatus = (data) => {
      const allPending = data.rental_parking?.every(
        (item) => item.status === "pending"
      );
      const anyPending = data.rental_parking?.some(
        (item) => item.status === "pending"
      );

      if (allPending) {
        return "Pending";
      } else if (anyPending) {
        return "In Progress";
      } else {
        return "Completed";
      }
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "supplier_id",
            //   operator: "=",
            //   value: id,
            // },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);
    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };

    const openDeleteConfirmation = async (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: "weighing" },
        });
        await fetctRentalParkingByWeighing();
        if (isLastPage && rental.value.length === 0) {
          currentPage.value = 1;
          await fetchCustomer(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetctRentalParkingByWeighing();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetctRentalParkingByWeighing();
    };
    const sortAmount = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA = a.rental_parking?.[0]?.[field] || 0;
        const valueB = b.rental_parking?.[0]?.[field] || 0;
        if (direction === "asc") {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      });
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;
      if (field === "weight" || field === "cost") {
        rental.value = sortAmount(direction, rental.value, field);
      } else {
        rental.value = toggleSort(direction, rental.value, field);
      }
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      handleCloseDetails,
      isDetails,
      navClasses,
      tableClasses,
      rental,
      formatDate2,
      handlePopupDetails,
      datatoedit,
      toast,
      showSuccessToast,
      handleConfirmDelete,
      openDeleteConfirmation,
      isDeleteOpen,
      closeDeleteDialog,
      table,
      deletedata,
      getOverallStatus,
      handlePageChange,
      handlePageSizeChange,
      currentPage,
      pageSize,
      totalPages,
      handleEdit,
      doc_id,
      mdiPencil,
      mdiEyeOutline,
      mdiTrashCanOutline,
      getTotalCost,
      getTotalWeightRice,
      totalRecord,
      selectClasses,
      suppliers,
      selectDateRage,
      handleFilter,
      selectCustomer,
      integerSearchValue,
      sortData,
      sortDirections,
      sortColumns,
      authUserRole,
    };
  },
};
</script>

<style></style>
