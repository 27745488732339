<template>
  <div class="w-full bg-default h-auto">
    <div class="flex hidden-print">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Purchased',
        }"
        @click="setActiveTab('Purchased')"
      >
        Purchase
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Converted',
        }"
        @click="setActiveTab('Converted')"
      >
        Convert
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Adjusted',
        }"
        @click="setActiveTab('Adjusted')"
      >
        Adjust
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Soldout',
        }"
        @click="setActiveTab('Soldout')"
      >
        Sell
      </div>
    </div>
    <div v-if="activeTab === 'Purchased'">
      <Purchased />
    </div>
    <div v-else-if="activeTab === 'Converted'">
      <Converted />
    </div>
    <div v-else-if="activeTab === 'Adjusted'">
      <Adjusted />
    </div>
    <div v-else-if="activeTab === 'Soldout'">
      <Soldout />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Purchased from "../warehouse/Purchased.vue";
import Converted from "./Converted.vue";
import Adjusted from "./Adjusted.vue";
import Soldout from "./Soldout.vue";
export default {
  components: {
    Purchased,
    Converted,
    Adjusted,
    Soldout,
  },
  setup() {
    const activeTab = ref("Purchased");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    return { activeTab, setActiveTab };
  },
};
</script>

<style></style>
