
//use partner transaction

import apirURL from "@/services/apiURL";
import axios from "axios";


const fetchPartnerByID = async (id) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/getAllData`;
    const params = {
      tableName: "partners",
      sortColumn: "id",
      dynamicConditions: JSON.stringify([
        {
          field: "id",
          operator: "=",
          value: id,
          typeTable: "table",
        },
      ]),
    };

    const response = await axios.get(apiUrl, { params });
    return response.data[0]?.wallet[0];
  } catch (error) {
    console.error("Error fetching supplier data:", error);
    return null;
  }
};


const updatePartnerWallet = async (partnerId, newWalletAmount) => {
  try {
    let apiUrl = `${apirURL}/weighing/api/updateData/${partnerId}`;
    const requestBody = {
      tableName: "partners",
      fields: {
        wallet: JSON.stringify(newWalletAmount),
      },
    };
    const response = await axios.patch(apiUrl, requestBody);
    return response ? response.data.message : '';
  } catch (error) {
    console.error("Error updating supplier wallet:", error);
  }
};




export const add = async (formDoc, transactionType, insertID) => {
  try {

    // Use Promise.all to run all fetch operations in parallel
    const [walletPartner] = await Promise.all([
      fetchPartnerByID(formDoc.partner_id),
    ]);

    let partnerWallet = [];
    if (transactionType === 'deposit') {
      if (formDoc.wallet === 'KHR') {
        walletPartner.KH.deposit['original_partner_owe'].push({
          deposit_id: insertID,
          amount: formDoc.amount
        });

        partnerWallet.push(walletPartner);
        await updatePartnerWallet(formDoc.partner_id, partnerWallet);

      } else if (formDoc.wallet === 'VND') {
        walletPartner.VN.deposit['original_partner_owe'].push({
          deposit_id: insertID,
          amount: formDoc.amount
        });
        partnerWallet.push(walletPartner);
        await updatePartnerWallet(formDoc.partner_id, partnerWallet)
      }


    } else if (transactionType === 'withdrawal') {
      if (formDoc.wallet === 'KHR') {
        walletPartner.KH.withdrawal['original_partner_owe'].push({
          withdrawal_id: insertID,
          amount: formDoc.amount * -1 // here what happen when 1 * -1
        });
        partnerWallet.push(walletPartner);
        await updatePartnerWallet(formDoc.partner_id, partnerWallet)
      } else if (formDoc.wallet === 'VND') {
        walletPartner.VN.withdrawal['original_partner_owe'].push({
          withdrawal_id: insertID,
          amount: formDoc.amount * -1
        });
        partnerWallet.push(walletPartner);
        await updatePartnerWallet(formDoc.partner_id, partnerWallet)
      }
    } else if (transactionType === 'exchange balance') {

      if (formDoc.from_wallet == formDoc.to_wallet) {
        console.log("from wallet and to wallet is same");
        return;
      } else {
        if (formDoc.from_wallet === 'KHR') {
          //before exchange
          walletPartner.KH.exchange_balance['original_owe_partner'].push({
            exchange_id: insertID,
            amount: formDoc.amount_before_exchange * - 1
          });

          //update from wallet
          partnerWallet.push(walletPartner);
          const success = await updatePartnerWallet(formDoc.partner_id, partnerWallet);

          if (success) {
            if (formDoc.to_wallet === 'VND') {
              //update to wallet
              const [TowalletPartner] = await Promise.all([
                fetchPartnerByID(formDoc.partner_id),
              ]);

              //after exchange
              TowalletPartner.VN.exchange_balance['original_partner_owe'].push({
                exchange_id: insertID,
                amount: formDoc.amount_after_exchange
              });

              let to_walletPartner = [];
              to_walletPartner.push(TowalletPartner)
              await updatePartnerWallet(formDoc.partner_id, to_walletPartner);
            }
          }

        } else if (formDoc.from_wallet === 'VND') {
          console.log("from wallet VND exchange balance", formDoc.from_wallet)
          //before exchange
          walletPartner.VN.exchange_balance['original_owe_partner'].push({
            exchange_id: insertID,
            amount: formDoc.amount_before_exchange * - 1
          });


          //update from wallet
          partnerWallet.push(walletPartner);
          const success = await updatePartnerWallet(formDoc.partner_id, partnerWallet);

          if (success) {

            if (formDoc.to_wallet === 'KHR') {
              //update to wallet
              const [TowalletPartner] = await Promise.all([
                fetchPartnerByID(formDoc.partner_id),
              ]);

              //after exchange
              TowalletPartner.KH.exchange_balance['original_partner_owe'].push({
                exchange_id: insertID,
                amount: formDoc.amount_after_exchange
              });

              let to_walletPartner = [];
              to_walletPartner.push(TowalletPartner)
              await updatePartnerWallet(formDoc.partner_id, to_walletPartner);
            }

          }

        }
      }

    }

    return {
      partnerWallet: partnerWallet
    }

  } catch (err) {
    console.log("failed to add deposit", err);
  }
}


export const update = async (oldDoc, NewDoc) => {
  try {
    const [OldwalletPartner] = await Promise.all([
      fetchPartnerByID(oldDoc.partner_id),
    ]);

    let OldpartnerWallet = [];
    let NewpartnerWallet = [];

    if (oldDoc.transaction_type === 'deposit') {
      if (oldDoc.wallet === 'KHR') {
        const depositIndex = OldwalletPartner.KH.deposit['original_partner_owe'].findIndex((p) => p.deposit_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.deposit['original_partner_owe'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

        if (successOld) {
          const [NewwalletPartner] = await Promise.all([
            fetchPartnerByID(NewDoc.partner_id),
          ]);

          if (NewDoc.wallet === 'KHR') {
            NewwalletPartner.KH.deposit['original_partner_owe'].push({
              deposit_id: oldDoc.id,
              amount: NewDoc.amount
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          } else if (NewDoc.wallet === 'VND') {
            NewwalletPartner.VN.deposit['original_partner_owe'].push({
              deposit_id: oldDoc.id,
              amount: NewDoc.amount
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          }


        }
      } else if (oldDoc.wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.deposit['original_partner_owe'].findIndex((p) => p.deposit_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.deposit['original_partner_owe'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

        if (successOld) {
          const [NewwalletPartner] = await Promise.all([
            fetchPartnerByID(NewDoc.partner_id),
          ]);


          if (NewDoc.wallet === 'KHR') {
            NewwalletPartner.KH.deposit['original_partner_owe'].push({
              deposit_id: oldDoc.id,
              amount: NewDoc.amount
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          } else if (NewDoc.wallet === 'VND') {
            NewwalletPartner.VN.deposit['original_partner_owe'].push({
              deposit_id: oldDoc.id,
              amount: NewDoc.amount
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          }
        }
      }
    } else if (oldDoc.transaction_type === 'withdrawal') {

      if (oldDoc.wallet === 'KHR') {
        const depositIndex = OldwalletPartner.KH.withdrawal['original_partner_owe'].findIndex((p) => p.withdrawal_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.withdrawal['original_partner_owe'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

        if (successOld) {
          const [NewwalletPartner] = await Promise.all([
            fetchPartnerByID(NewDoc.partner_id),
          ]);

          if (NewDoc.wallet === 'KHR') {
            NewwalletPartner.KH.withdrawal['original_partner_owe'].push({
              withdrawal_id: oldDoc.id,
              amount: NewDoc.amount * -1
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          } else if (NewDoc.wallet === 'VND') {
            NewwalletPartner.VN.withdrawal['original_partner_owe'].push({
              withdrawal_id: oldDoc.id,
              amount: NewDoc.amount * -1
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          }


        }
      } else if (oldDoc.wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.withdrawal['original_partner_owe'].findIndex((p) => p.withdrawal_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.withdrawal['original_partner_owe'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

        if (successOld) {
          const [NewwalletPartner] = await Promise.all([
            fetchPartnerByID(NewDoc.partner_id),
          ]);

          if (NewDoc.wallet === 'KHR') {
            NewwalletPartner.KH.withdrawal['original_partner_owe'].push({
              withdrawal_id: oldDoc.id,
              amount: NewDoc.amount * -1
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          } else if (NewDoc.wallet === 'VND') {
            NewwalletPartner.VN.withdrawal['original_partner_owe'].push({
              withdrawal_id: oldDoc.id,
              amount: NewDoc.amount * -1
            });

            NewpartnerWallet.push(NewwalletPartner);
            await updatePartnerWallet(NewDoc.partner_id, NewpartnerWallet);
          }
        }
      }
    } else if (oldDoc.transaction_type === 'exchange balance') {
      // update oldDoc from wallet first
      if (oldDoc.from_wallet === 'KHR') {
        const depositIndex = OldwalletPartner.KH.exchange_balance['original_owe_partner'].findIndex((p) => p.exchange_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.exchange_balance['original_owe_partner'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
        if (successOld) {
          // old doc to_wallet
          const [OldpartnerWalletTo] = await Promise.all([
            fetchPartnerByID(oldDoc.partner_id),
          ]);
          if (oldDoc.to_wallet === 'VND') {
            const depositIndex = OldpartnerWalletTo.VN.exchange_balance['original_partner_owe'].findIndex((p) => p.exchange_id === oldDoc.id)
            if (depositIndex !== -1) {
              // If the object is found, remove it from the array
              OldpartnerWalletTo.VN.exchange_balance['original_partner_owe'].splice(depositIndex, 1);
            }

            let oldToWallet = [];
            oldToWallet.push(OldpartnerWalletTo)
            const success = await updatePartnerWallet(oldDoc.partner_id, oldToWallet);
            if (success) {
              const [walletPartner] = await Promise.all([
                fetchPartnerByID(formDoc.partner_id),
              ]);
              if (NewDoc.from_wallet == NewDoc.to_wallet) {
                return;
              } else {
                if (NewDoc.from_wallet === 'KHR') {
                  //before exchange
                  walletPartner.KH.exchange_balance['original_owe_partner'].push({
                    exchange_id: oldDoc.id,
                    amount: NewDoc.amount_before_exchange * - 1
                  });


                  let partnerWallet = [];
                  //update from wallet
                  partnerWallet.push(walletPartner);
                  const success = await updatePartnerWallet(NewDoc.partner_id, partnerWallet);

                  if (success) {
                    if (NewDoc.to_wallet === 'VND') {
                      //update to wallet
                      const [TowalletPartner] = await Promise.all([
                        fetchPartnerByID(NewDoc.partner_id),
                      ]);

                      //after exchange
                      TowalletPartner.VN.exchange_balance['original_partner_owe'].push({
                        exchange_id: oldDoc.id,
                        amount: NewDoc.amount_after_exchange
                      });

                      let to_walletPartner = [];
                      to_walletPartner.push(TowalletPartner)
                      await updatePartnerWallet(NewDoc.partner_id, to_walletPartner);
                    }
                  }

                } else if (NewDoc.from_wallet === 'VND') {
                  //before exchange
                  walletPartner.VN.exchange_balance['original_owe_partner'].push({
                    exchange_id: oldDoc.id,
                    amount: NewDoc.amount_before_exchange * - 1
                  });


                  let partnerWallet = [];
                  //update from wallet
                  partnerWallet.push(walletPartner);
                  const success = await updatePartnerWallet(NewDoc.partner_id, partnerWallet);

                  if (success) {

                    if (NewDoc.to_wallet === 'KHR') {
                      //update to wallet
                      const [TowalletPartner] = await Promise.all([
                        fetchPartnerByID(NewDoc.partner_id),
                      ]);

                      //after exchange
                      TowalletPartner.KH.exchange_balance['original_partner_owe'].push({
                        exchange_id: oldDoc.id,
                        amount: NewDoc.amount_after_exchange
                      });

                      let to_walletPartner = [];
                      to_walletPartner.push(TowalletPartner)
                      await updatePartnerWallet(NewDoc.partner_id, to_walletPartner);
                    }
                  }
                }
              }
            }
          }
        }
      } else if (oldDoc.from_wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.exchange_balance['original_owe_partner'].findIndex((p) => p.exchange_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.exchange_balance['original_owe_partner'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
        if (successOld) {
          // old doc to_wallet
          const [OldpartnerWalletTo] = await Promise.all([
            fetchPartnerByID(oldDoc.partner_id),
          ]);
          if (oldDoc.to_wallet === 'KHR') {
            const depositIndex = OldpartnerWalletTo.KH.exchange_balance['original_partner_owe'].findIndex((p) => p.exchange_id === oldDoc.id)
            if (depositIndex !== -1) {
              // If the object is found, remove it from the array
              OldpartnerWalletTo.KH.exchange_balance['original_partner_owe'].splice(depositIndex, 1);
            }

            let oldToWallet = [];
            oldToWallet.push(OldpartnerWalletTo)
            const success = await updatePartnerWallet(oldDoc.partner_id, oldToWallet);
            if (success) {
              const [walletPartner] = await Promise.all([
                fetchPartnerByID(formDoc.partner_id),
              ]);
              if (NewDoc.from_wallet == NewDoc.to_wallet) {
                return;
              } else {
                if (NewDoc.from_wallet === 'KHR') {
                  //before exchange
                  walletPartner.KH.exchange_balance['original_owe_partner'].push({
                    exchange_id: oldDoc.id,
                    amount: NewDoc.amount_before_exchange * - 1
                  });


                  let partnerWallet = [];
                  //update from wallet
                  partnerWallet.push(walletPartner);
                  const success = await updatePartnerWallet(NewDoc.partner_id, partnerWallet);

                  if (success) {
                    if (NewDoc.to_wallet === 'VND') {
                      //update to wallet
                      const [TowalletPartner] = await Promise.all([
                        fetchPartnerByID(NewDoc.partner_id),
                      ]);

                      //after exchange
                      TowalletPartner.VN.exchange_balance['original_partner_owe'].push({
                        exchange_id: oldDoc.id,
                        amount: NewDoc.amount_after_exchange
                      });

                      let to_walletPartner = [];
                      to_walletPartner.push(TowalletPartner)
                      await updatePartnerWallet(NewDoc.partner_id, to_walletPartner);
                    }
                  }

                } else if (NewDoc.from_wallet === 'VND') {
                  //before exchange
                  walletPartner.VN.exchange_balance['original_owe_partner'].push({
                    exchange_id: oldDoc.id,
                    amount: NewDoc.amount_before_exchange * - 1
                  });


                  let partnerWallet = [];
                  //update from wallet
                  partnerWallet.push(walletPartner);
                  const success = await updatePartnerWallet(NewDoc.partner_id, partnerWallet);

                  if (success) {

                    if (NewDoc.to_wallet === 'KHR') {
                      //update to wallet
                      const [TowalletPartner] = await Promise.all([
                        fetchPartnerByID(NewDoc.partner_id),
                      ]);

                      //after exchange
                      TowalletPartner.KH.exchange_balance['original_partner_owe'].push({
                        exchange_id: oldDoc.id,
                        amount: NewDoc.amount_after_exchange
                      });

                      let to_walletPartner = [];
                      to_walletPartner.push(TowalletPartner)
                      await updatePartnerWallet(NewDoc.partner_id, to_walletPartner);
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

  } catch (err) {
    console.log("faile to update data", err);
  }
}



export const remove = async (oldDoc) => {
  try {
    const [OldwalletPartner] = await Promise.all([
      fetchPartnerByID(oldDoc.partner_id),
    ]);

    let OldpartnerWallet = [];
    if (oldDoc.transaction_type === 'deposit') {
      if (oldDoc.wallet === 'KHR') {

        const depositIndex = OldwalletPartner.KH.deposit['original_partner_owe'].findIndex((p) => p.deposit_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.deposit['original_partner_owe'].splice(depositIndex, 1);
        }
        OldpartnerWallet.push(OldwalletPartner);

        await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

      } else if (oldDoc.wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.deposit['original_partner_owe'].findIndex((p) => p.deposit_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.deposit['original_partner_owe'].splice(depositIndex, 1);
        }
        OldpartnerWallet.push(OldwalletPartner);

        await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
      }
    } else if (oldDoc.transaction_type === 'withdrawal') {

      if (oldDoc.wallet === 'KHR') {

        const depositIndex = OldwalletPartner.KH.withdrawal['original_partner_owe'].findIndex((p) => p.withdrawal_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.withdrawal['original_partner_owe'].splice(depositIndex, 1);
        }
        OldpartnerWallet.push(OldwalletPartner);

        await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);

      } else if (oldDoc.wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.withdrawal['original_partner_owe'].findIndex((p) => p.withdrawal_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.withdrawal['original_partner_owe'].splice(depositIndex, 1);
        }
        OldpartnerWallet.push(OldwalletPartner);

        await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
      }
    } else if (oldDoc.transaction_type === 'exchange balance') {

      // update oldDoc from wallet first
      if (oldDoc.from_wallet === 'KHR') {
        const depositIndex = OldwalletPartner.KH.exchange_balance['original_owe_partner'].findIndex((p) => p.exchange_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.KH.exchange_balance['original_owe_partner'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
        if (successOld) {
          // old doc to_wallet
          const [OldpartnerWalletTo] = await Promise.all([
            fetchPartnerByID(oldDoc.partner_id),
          ]);
          if (oldDoc.to_wallet === 'VND') {
            const depositIndex = OldpartnerWalletTo.VN.exchange_balance['original_partner_owe'].findIndex((p) => p.exchange_id === oldDoc.id)
            if (depositIndex !== -1) {
              // If the object is found, remove it from the array
              OldpartnerWalletTo.VN.exchange_balance['original_partner_owe'].splice(depositIndex, 1);
            }

            let oldToWallet = [];
            oldToWallet.push(OldpartnerWalletTo)
            await updatePartnerWallet(oldDoc.partner_id, oldToWallet);

          }
        }
      } else if (oldDoc.from_wallet === 'VND') {
        const depositIndex = OldwalletPartner.VN.exchange_balance['original_owe_partner'].findIndex((p) => p.exchange_id === oldDoc.id)
        if (depositIndex !== -1) {
          // If the object is found, remove it from the array
          OldwalletPartner.VN.exchange_balance['original_owe_partner'].splice(depositIndex, 1);
        }

        OldpartnerWallet.push(OldwalletPartner);
        const successOld = await updatePartnerWallet(oldDoc.partner_id, OldpartnerWallet);
        if (successOld) {
          // old doc to_wallet
          const [OldpartnerWalletTo] = await Promise.all([
            fetchPartnerByID(oldDoc.partner_id),
          ]);
          if (oldDoc.to_wallet === 'KHR') {
            const depositIndex = OldpartnerWalletTo.KH.exchange_balance['original_partner_owe'].findIndex((p) => p.exchange_id === oldDoc.id)
            if (depositIndex !== -1) {
              // If the object is found, remove it from the array
              OldpartnerWalletTo.KH.exchange_balance['original_partner_owe'].splice(depositIndex, 1);
            }

            let oldToWallet = [];
            oldToWallet.push(OldpartnerWalletTo)
            await updatePartnerWallet(oldDoc.partner_id, oldToWallet);



          }
        }
      }
    }

  } catch (err) {
    console.log("failed to delete  data", err)
  }
}


export const useSupplierPayment = async (formDoc, insertID, type) => {

  try {
    // Use Promise.all to run all fetch operations in parallel
    const [walletPartner] = await Promise.all([
      fetchPartnerByID(formDoc.partner_id),
    ]);

    let partnerWallet = [];

    if (type === 'add') {



      walletPartner.KH.supplier_payment['original_partner_owe'].push({
        invoice_id: insertID,
        amount: formDoc.amount_paid * -1
      });

      partnerWallet.push(walletPartner);
      await updatePartnerWallet(formDoc.partner_id, partnerWallet);


    } else if (type === 'remove') {
      const supplierPaymentIndex = walletPartner.KH.supplier_payment['original_partner_owe'].findIndex((p) => p.invoice_id === insertID)
      if (supplierPaymentIndex !== -1) {
        // If the object is found, remove it from the array
        walletPartner.KH.supplier_payment['original_partner_owe'].splice(supplierPaymentIndex, 1);
      }
      partnerWallet.push(walletPartner);

      await updatePartnerWallet(formDoc.partner_id, partnerWallet);

    }


  } catch (err) {
    console.log("failed to add or delete supplier payment", err)
  }

}





export const useCustomerPayment = async (formDoc, insertID, type) => {

  try {
    // Use Promise.all to run all fetch operations in parallel
    const [walletPartner] = await Promise.all([
      fetchPartnerByID(formDoc.partner_id),
    ]);

    let partnerWallet = [];

    if (type === 'add') {



      walletPartner.VN.customer_payment['original_partner_owe'].push({
        invoice_id: insertID,
        amount: formDoc.amount_paid
      });

      partnerWallet.push(walletPartner);
      await updatePartnerWallet(formDoc.partner_id, partnerWallet);


    } else if (type === 'remove') {
      const customerPaymentIndex = walletPartner.VN.customer_payment['original_partner_owe'].findIndex((p) => p.invoice_id === insertID)
      if (customerPaymentIndex !== -1) {
        // If the object is found, remove it from the array
        walletPartner.VN.customer_payment['original_partner_owe'].splice(customerPaymentIndex, 1);
      }
      partnerWallet.push(walletPartner);

      await updatePartnerWallet(formDoc.partner_id, partnerWallet);

    }


  } catch (err) {
    console.log("failed to add or delete supplier payment", err)
  }

}

