<template>
  <form class="w-[360px] h-[290px] bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">New Partner</h2>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Name</label
        >
        <input required type="text" placeholder="Enter Name" />
      </div>
      <div class="flex items-center gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Phone</label
        >
        <input required type="text" placeholder="Enter Name" />
      </div>
      <div class="flex w-full items-center gap-3">
        <button>Save</button>
        <button class="btncancel">Save & New</button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    return {
      handleClose,
    };
  },
};
</script>

<style></style>
