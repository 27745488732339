<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[400px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ datatoedit ? "Update Rice" : "New Rice Type" }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center gap-3 justify-between">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Name</label
        >
        <input
          class="w-[250px]"
          required
          type="text"
          placeholder="Enter Name"
          v-model="name"
        />
      </div>
      <div class="flex items-center gap-3">
        <label for="status" class="flex gap-1 text-nowrap">Status</label>
        <el-switch style="--el-switch-on-color: #4caf50" v-model="status" />
      </div>
      <div class="flex w-full items-center gap-3">
        <button>Save</button>
        <button type="button" @click="SaveAndNew" class="btncancel">
          Save & New
        </button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import apirURL from "@/services/apiURL";
import axios from "axios";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const name = ref("");
    const status = ref(true);

    const handleClose = () => {
      emit("close");
    };
    const handleClear = () => {
      name.value = "";
    };
    const handleSubmit = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "rices",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            status: status.value,
            created_at: timestamp,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
          handleClose();
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
          handleClear();
          handleClose();
        }
        emit("close");
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      if (props.datatoedit) {
        name.value = props.datatoedit.name || "";
      }
    });

    const isEdit = props.datatoedit;

    // save and new
    const SaveAndNew = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "rices",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            status: status.value,
            created_at: timestamp,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
          handleClear();
        }
      } catch (error) {
        console.error(error);
      }
    };
    return {
      handleClose,
      name,
      status,
      handleSubmit,
      isEdit,
      SaveAndNew,
    };
  },
};
</script>
