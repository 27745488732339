<template>
  <div class="">
    <div class="details_title">
      <h2 class="ml-4">Supplier: {{ data.name }}</h2>
      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Detail</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="p-5">
      <div class="py-4 gap-4 grid grid-cols-2 border-b-2 border-primary6">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>{{ data.name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>No</h2>
          <h2>{{ data.id }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Phone Number</h2>
          <h2 v-if="data.phone_number">{{ data.phone_number }}</h2>
          <h2 v-else>No data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Due Balance</h2>
          <h2 v-if="data.wallet">
            {{
              numeralFormat(
                data.wallet.preloan.original_supplier_owe[0]?.amount,
                "0,0[.]00"
              )
            }}
            ៛
          </h2>
          <h2 v-else>No data</h2>
        </div>
      </div>
      <h2 class="mt-6 text-start">Transactions</h2>

      <div class="mt-4">
        <div class="flex">
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierPurchaseOrder',
            }"
            @click="setActiveTab('SupplierPurchaseOrder')"
          >
            Purchase Order
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierFromTruck',
            }"
            @click="setActiveTab('SupplierFromTruck')"
          >
            From Truck
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierPurchasedBill',
            }"
            @click="setActiveTab('SupplierPurchasedBill')"
          >
            Purchased Bill
          </div>
          <div
            :class="{
              'tab2 cursor-pointer': true,
              pop_up_tab: activeTab === 'SupplierPreLaonHisotry',
            }"
            @click="setActiveTab('SupplierPreLaonHisotry')"
          >
            Pre-Laon Hisotry
          </div>
        </div>
        <div class="text-start">
          <div v-if="activeTab === 'SupplierPurchaseOrder'">
            <SupplierPurchaseOrder
              :purchase="purchase"
              :datatoedit="datatoedit"
            />
          </div>
          <div v-else-if="activeTab === 'SupplierFromTruck'">
            <SupplierFromTruck
              :formTruck="formTruck"
              :datatoedit="datatoedit"
            />
          </div>
          <div v-else-if="activeTab === 'SupplierPurchasedBill'">
            <SupplierPurchasedBill
              :supplier_invoice="supplier_invoice"
              :datatoedit="datatoedit"
            />
          </div>
          <div v-else-if="activeTab === 'SupplierPreLaonHisotry'">
            <SupplierPreLaonHisotry
              :preloanhistory="preloanhistory"
              :datatoedit="datatoedit"
            />
          </div>
        </div>
        <div class="w-full flex items-center justify-start mt-4">
          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import SupplierFromTruck from "./SupplierFromTruck.vue";
import SupplierPurchaseOrder from "./SupplierPurchaseOrder.vue";
import SupplierPurchasedBill from "./SupplierPurchasedBill.vue";
import SupplierPreLaonHisotry from "./SupplierPreLaonHisotry.vue";
import axios from "axios";
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
export default {
  props: ["datatoedit"],
  components: {
    SupplierFromTruck,
    SupplierPurchaseOrder,
    SupplierPurchasedBill,
    SupplierPreLaonHisotry,
    NoDataComponet,
  },

  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("SupplierPurchaseOrder");
    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const purchase = ref([]);
    const fetchPuchaseOrder = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "purchase_orders",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        purchase.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const formTruck = ref([]);
    const fetchFromTruck = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        formTruck.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const supplier_invoice = ref([]);
    const fetchSupplierInvoice = async () => {
      try {
        const req = {
          tableName: "supplier_invoice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        supplier_invoice.value = res.data;
        console.log(" data invoice", supplier_invoice.value);
      } catch (error) {
        console.log(error);
      }
    };
    const preloanhistory = ref([]);
    const fetchPreloan = async () => {
      try {
        const req = {
          tableName: "preloans",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        preloanhistory.value = res.data;
        console.log(" data invoice", preloanhistory.value);
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(async () => {
      props.datatoedit;
      await Promise.allSettled([
        fetchPuchaseOrder(),
        fetchFromTruck(),
        fetchSupplierInvoice(),
        fetchPreloan(),
      ]);
    });
    const data = props.datatoedit;
    return {
      handleClose,
      data,
      activeTab,
      setActiveTab,
      purchase,
      formTruck,
      supplier_invoice,
      preloanhistory,
    };
  },
};
</script>

<style></style>
