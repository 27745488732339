<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[800px] h-auto bg-white text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Generate Label Slip</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full bg-primary7/50 p-5">
      <h2 class="text-main text-heading2 font-bold">
        Amount Paid:
        {{ numeralFormat(totalWeight * amountPerKilo, "0,0[.]00") }} ₫
      </h2>
    </div>
    <div class="h-[500px] overflow-auto">
      <div class="p-5">
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <p>
              {{ formatDate2(dataPrev[0].created_at) }}
            </p>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            {{ dataPrev[0].status === true ? "Complete" : "Procesccing" }}
          </div>
        </div>
        <div class="text-start py-2">
          <h2>Transaction Detail</h2>
        </div>
        <div class="relative overflow-x-auto border">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="min-w-[100px] border-b text-start">No.</td>

                <td class="min-w-[100px] border-b text-start">Created At</td>
                <td class="min-w-[100px] border-b text-start">Truck</td>
                <td class="min-w-[100px] border-b text-start">
                  Total Rice Weight
                </td>
                <td class="min-w-[100px] border-b text-start">Status</td>
              </tr>
            </tbody>

            <tbody v-for="data in dataPrev" :key="data">
              <tr
                class="text-body font-normal text-primary2 bg-white"
                v-for="item in data.rices"
              >
                <td class="border-b">{{ item.weighing_id }}</td>
                <td class="border-b">{{ formatDate2(data.created_at) }}</td>
                <td class="border-b">
                  {{ item.plate_number }}
                </td>
                <td class="border-b">
                  {{ numeralFormat(item.weight_drop, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ data.status === true ? "Complete" : "Procesccing" }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body font-normal text-primary2 bg-white">
                <td colspan="3">
                  <h2 class="flex items-center justify-center gap-3 font-bold">
                    Total
                  </h2>
                </td>
                <td colspan="3">
                  <div class="flex items-center justify-center gap-3 font-bold">
                    <h2>{{ numeralFormat(totalWeight, "0,0[.]00") }} Kg</h2>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="p-5 w-[70%] flex flex-col items-end">
        <div
          :class="{
            'flex items-start gap-3': is_ValidateAmount,
            'flex items-center gap-3': !is_ValidateAmount,
          }"
        >
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500">*</span> Labor Cost/Kg</label
          >
          <div class="flex flex-col">
            <InputNumber
              :maxFractionDigits="2"
              class="w-full md:w-56 h-2 text-start flex items-center"
              placeholder="Enter Amount"
              step="0.01"
              style="width: 250px"
              suffix=" ₫"
              required
              v-model="amountPerKilo"
            />
            <div v-if="is_ValidateAmount" class="w-[250px] mt-6 float-end">
              <el-alert
                title="Enter The Amount"
                type="error"
                :closable="false"
              />
            </div>
            <div></div>
          </div>
          <el-select
            disabled
            v-model="value"
            placeholder="VND"
            size="large"
            style="width: 100px"
          >
            <el-option key="VND" label="VND" value="VND" />
          </el-select>
        </div>
      </div>
      <div class="flex p-5 gap-3">
        <div class="border rounded w-full p-2">
          <div class="py-2 px-2">
            <h2 class="text-start">Additional Expense</h2>
          </div>
          <table class="border">
            <thead>
              <tr class="header_table">
                <td>Type</td>
                <td>Amount</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(expense, index) in expensesByGroup" :key="index">
                <td class="border-b border-t">
                  <input
                    placeholder="Type"
                    style="width: 200px"
                    v-model="expense.type"
                  />
                </td>
                <td class="border-b border-t">
                  <InputNumber
                    placeholder="Amount"
                    suffix=" ₫"
                    class="w-full md:w-56 h-2 text-start flex items-center"
                    :maxFractionDigits="2"
                    step="0.01"
                    v-model="expense.amount"
                  />
                </td>
                <td class="border-b border-t">
                  <div class="flex items-center justify-center">
                    <svg
                      v-if="expensesByGroup.length > 1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather cursor-pointer feather-trash-2 delete"
                      @click="removeExpense(index)"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path
                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                      ></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Add More Row Button -->
          <div class="py-2 px-2">
            <button
              @click="addMoreRow"
              type="button"
              class="!bg-white text-primary2 px-4 py-2 rounded text-body"
            >
              < New >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex w-full items-center gap-3">
        <button type="submit">Save</button>
        <button @click="handleToPrint" type="button" class="btncancel">
          Save & Print
        </button>
        <button class="btncancel" @click="handleClose">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { validateInputNUmber } from "@/composables/ValidateInput";
import { ref, onMounted, computed } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: ["dataPrev"],
  setup(props, { emit }) {
    const router = useRouter();

    const handleClose = () => {
      emit("close");
    };
    const expensesByGroup = ref([{ type: "", amount: null }]);

    // Method to add a new row to the expenses
    const addMoreRow = () => {
      expensesByGroup.value.push({ type: "", amount: null });
    };

    // Method to remove a row from the expenses
    const removeExpense = (index) => {
      expensesByGroup.value.splice(index, 1);
    };
    onMounted(async () => {
      if (props.dataPrev) {
        props.dataPrev;
        console.log("  props.dataPrev", props.dataPrev);
      }
      // fetchWeighing();
      fetchTruck();
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
    });
    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const totalWeight = computed(() => {
      return props.dataPrev.reduce((total, data) => {
        const riceWeight = data.rices.reduce((subTotal, item) => {
          const weightDrop = parseFloat(item.weight_drop || 0);
          return subTotal + weightDrop;
        }, 0);
        return total + riceWeight;
      }, 0);
    });

    console.log("totalWeight", totalWeight.value);

    const totalCost = computed(() => {
      return totalWeight.value * (parseFloat(amountPerKilo.value) || 0);
    });
    const authUserId = ref(null);

    const amountPerKilo = ref(null);
    const is_ValidateAmount = handleValidate(
      amountPerKilo.value,
      amountPerKilo
    );
    const handleToPrint = async () => {
      if (!amountPerKilo.value) {
        is_ValidateAmount.value = true;
        return;
      }
      try {
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "worker_invoice",
          fields: {
            transactions: JSON.stringify(
              props.dataPrev.flatMap((record) => {
                return record.rices.map((rice) => ({
                  weighing_id: rice.weighing_id,
                  plate_number_id: rice.plate_number_id,
                  total_weight: totalWeight.value,
                  price: amountPerKilo.value,
                  weight_drop: rice.weight_drop, // Access weight_drop for each rice
                  total_price_perton: amountPerKilo.value * rice.weight_drop, // Calculate per ton price
                }));
              })
            ),

            status: "paid",
            weigh_type: "kilo",
            option_fee: JSON.stringify(
              expensesByGroup.value.map((record) => {
                return {
                  fee: record.amount,
                  name: record.type,
                  weigh_type: "kilo",
                };
              })
            ),
            created_at: timestamp,
            created_by: authUserId.value,
            labors: JSON.stringify(props.dataPrev),
            supplier_invoice_id: [props.dataPrev[0].id],
          },
        };
        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          req
        );
        const update_labor = {
          tableName: "supplier_invoice",
          fields: {
            is_labor_invoice: true,
          },
        };

        props.dataPrev.forEach(async (data) => {
          try {
            if (data.id) {
              // Send the update request for each record
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${data.id}`,
                update_labor
              );
              console.log(`Updated labor invoice for ID: ${data.id}`);
            }
          } catch (error) {
            console.error(
              `Failed to update labor invoice for ID: ${data.id}`,
              error
            );
          }
        });
        console.log("req", req, response.data);
        if (response.data) {
          router.push({
            name: "printlabor",
            params: { id: response.data.insertedId },
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const handleSubmit = async () => {
      if (!amountPerKilo.value) {
        is_ValidateAmount.value = true;
        return;
      }
      try {
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "worker_invoice",
          fields: {
            transactions: JSON.stringify(
              props.dataPrev.flatMap((record) => {
                return record.rices.map((rice) => ({
                  weighing_id: rice.weighing_id,
                  plate_number_id: rice.plate_number_id,
                  total_weight: totalWeight.value,
                  price: amountPerKilo.value,
                  weight_drop: rice.weight_drop,
                  total_price_perton: amountPerKilo.value * rice.weight_drop,
                }));
              })
            ),

            status: "paid",
            weigh_type: "kilo",
            option_fee: JSON.stringify(
              expensesByGroup.value.map((record) => {
                return {
                  fee: record.amount,
                  name: record.type,
                  weigh_type: "kilo",
                };
              })
            ),
            created_at: timestamp,
            created_by: authUserId.value,
            labors: JSON.stringify(props.dataPrev),
            supplier_invoice_id: [props.dataPrev[0].id],
          },
        };
        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          req
        );
        if (response.data) {
          const update_labor = {
            tableName: "supplier_invoice",
            fields: {
              is_labor_invoice: true,
            },
          };

          props.dataPrev.forEach(async (data) => {
            try {
              if (data.id) {
                // Send the update request for each record
                const response_update = await axios.patch(
                  `${apirURL}/weighing/api/updateData/${data.id}`,
                  update_labor
                );
                console.log(`Updated labor invoice for ID: ${data.id}`);
              }
              emit("close");
              emit("toast", "create");
            } catch (error) {
              console.error(
                `Failed to update labor invoice for ID: ${data.id}`,
                error
              );
            }
          });
        }
        console.log("req", req, response.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    return {
      handleClose,
      validateInputNUmber,
      expensesByGroup,
      removeExpense,
      addMoreRow,
      // weighing,
      formatDate2,
      getPlatesName,
      totalWeight,
      amountPerKilo,
      totalCost,
      handleToPrint,
      handleSubmit,
      is_ValidateAmount,
    };
  },
};
</script>

<style></style>
