<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[560px] h-auto bg-default text-primary6"
  >
    <div class="details_title">
      <h2 class="ml-4 text-default">Move Boat</h2>
      <div class="flex items-center gap-2">
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="bg-white p-5 rounded border">
        <div class="text-start">
          <h2>From</h2>
        </div>
        <div class="flex items-center gap-3 bg-white p-5 justify-between">
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500">*</span> Parking</label
          >
          <div class="flex flex-col gap-1">
            <Select
              class="md:w-56 h-9 text-start flex items-center"
              v-model="selectParking"
              :options="parking"
              filter
              placeholder="Select Parking"
              style="width: 250px"
              option-label="name"
              show-clear
              checkmark
              size="medium"
            />
            <div v-if="is_ValidateParkingFrom" class="w-[250px] mt-2 float-end">
              <el-alert
                title="Please Select Parking"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center gap-3 bg-white p-5 justify-between">
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500">*</span> Boat
          </label>
          <div class="flex flex-col gap-1">
            <Select
              class="w-full md:w-56 h-9 text-start flex items-center"
              v-model="selectedBoat"
              :options="boat_enter_parking"
              filter
              placeholder="Select Boat"
              option-label="name"
              option-value="id"
              disabled
              size="medium"
              style="width: 250px"
            />
            <div v-if="is_ValidateBoat" class="w-[250px] mt-2 float-end">
              <el-alert
                title="Please Select Boat"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-5 rounded border">
        <div class="text-start">
          <h2>To</h2>
        </div>
        <div class="flex items-center gap-3 bg-white p-5 justify-between">
          <label for="name" class="flex gap-1 text-nowrap">
            <span class="text-red-500">*</span> Parking</label
          >
          <div class="flex flex-col gap-1">
            <Select
              class="w-full md:w-56 h-9 text-start flex items-center"
              v-model="parkingTo"
              :options="parkings"
              filter
              placeholder="Select Parking"
              option-label="name"
              show-clear
              checkmark
              size="medium"
            />
            <div v-if="is_ValidateParkingTo" class="w-[250px] mt-2 float-end">
              <el-alert
                title="Please Select Parking"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending">
          <span :class="{ is_pending: isPending }">Save</span>
        </button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { ref, onMounted, watch } from "vue";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const selectClient = ref(null);
    const selectParking = ref(null);
    const selectedBoat = ref(null);

    const errorMessages = ref({
      parking: "",
    });
    const validateForm = () => {
      errorMessages.value.parking = !selectParking.value
        ? "Parking is required."
        : "";
    };

    watch(selectParking, (newValue) => {
      errorMessages.value.parking = !newValue ? "Parking is required." : "";
    });
    const parking = ref([]);
    const fetchParkingFrom = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_available",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
        console.log("parking", parking.value);
      } catch (error) {
        console.error("Error fetching parking_rice data:", error);
      }
    };
    const parkings = ref([]);

    const boat_enter_parking = ref([]);
    const fetchBoatEnterParking = async (id) => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "parking",
              operator: "->>",
              key: "parking_id",
              value: id,
              typeTable: "table",
              type: "jsonb",
            },
            {
              field: "is_available",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_rental",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boat_enter_parking.value = response.data;

        // Auto-select the first boat in the list
        if (boat_enter_parking.value.length > 0) {
          selectedBoat.value = boat_enter_parking.value[0].id;
          console.log("selectedBoat", selectedBoat.value);
        } else {
          selectedBoat.value = null;
        }

        console.log("boat_enter_parking", boat_enter_parking.value[0].name);
      } catch (error) {
        console.error("Error fetching boat_enter_parking data:", error);
      }
    };
    const fetchFilteredParkingTo = () => {
      parkings.value = parking.value.filter(
        (p) => p.id !== selectParking.value.id
      );
    };
    const isBaot = ref(null);
    const parkingTo = ref(null);
    watch(selectParking, async (newParkingId) => {
      if (newParkingId) {
        await Promise.allSettled([
          fetchBoatEnterParking(newParkingId.id),
          fetchFilteredParkingTo(),
        ]);
      } else {
        console.log("else", (boat_enter_parking.value = []));
      }
    });
    const is_ValidateParkingFrom = handleValidate(
      selectParking.value,
      selectParking
    );
    const is_ValidateParkingTo = handleValidate(parkingTo.value, parkingTo);
    const is_ValidateBoat = handleValidate(selectedBoat.value, selectedBoat);
    const isPending = ref(false);
    const handleSubmit = async () => {
      if (!selectParking.value) {
        is_ValidateParkingFrom.value = true;
        return;
      }

      if (!selectedBoat.value) {
        is_ValidateBoat.value = true;
        return;
      }
      if (!parkingTo.value) {
        is_ValidateParkingTo.value = true;
        return;
      }
      try {
        isPending.value = true;
        const timestamp = await fetchTimestamp();
        const reqBody = {
          tableName: "boat_enter_parking",
          fields: {
            parking: JSON.stringify([
              {
                parking_id: parkingTo.value.id,
                name: selectedBoat.value.name,
              },
            ]),
            created_at: timestamp,
            status: true,
            is_complete: false,
          },
        };
        const response = await axios.patch(
          `${apirURL}/weighing/api/updateData/${selectedBoat.value?.id}`,
          reqBody
        );
        console.log("res", response.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    onMounted(async () => {
      await Promise.allSettled([fetchParkingFrom()]);
    });
    const isFilterable = ref(false);

    return {
      handleClose,
      selectParking,
      selectClient,
      parkings,
      handleSubmit,
      isFilterable,
      boat_enter_parking,
      errorMessages,
      isBaot,
      parkingTo,
      selectedBoat,
      parking,
      is_ValidateParkingFrom,
      is_ValidateParkingTo,
      is_ValidateBoat,
      isPending,
    };
  },
};
</script>

<style></style>
