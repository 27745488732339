vuelang
<template>
  <form class="bg-default text-primary6 w-[460px]">
    <div class="form_title">
      <h2 class="ml-4">Add Payment</h2>
    </div>
    <div class="gap-3">
      <div class="w-full p-5 space-y-4 h-full">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Labor Slip Ref</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Total Payment</h2>
          <h2>Cell data</h2>
        </div>
      </div>
      <div class="w-full p-5 space-y-4">
        <div class="flex items-center gap-3">
          <label for="name" class="flex gap-1">
            <span class="text-red-500">*</span> Amount</label
          >
          <input required type="text" placeholder="Enter Name" />
        </div>

        <div class="flex items-start gap-3">
          <label for="Description">Note</label>
          <textarea
            type="text"
            placeholder="Any Description"
            rows="4"
            class="w-full px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
          />
        </div>
        <div class="flex w-full items-center gap-3">
          <button>Save</button>
          <button class="btncancel" @click="handleClose">Cencel</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    return {
      handleClose,
    };
  },
};
</script>

<style></style>
