import { watch, ref } from 'vue';

export function handleValidate(currentWeight, fieldRef) {
    const isValidate = ref(false);

    watch([currentWeight, fieldRef], (newVal) => {
        if (newVal) {
            isValidate.value = false;
        } else {
            isValidate.value = true;
        }
    });

    return isValidate;
}


export function handleValidateRice(valueOrArray) {
    const isValidate = ref(false);

    const validate = () => {
        if (Array.isArray(valueOrArray)) {
            // If it's an array, set validation to true if any item is invalid
            isValidate.value = valueOrArray.some((item) => !item);
        } else {
            // Single value check
            isValidate.value = !valueOrArray;
        }
    };

    return { isValidate, validate };
}
