<template>
  <Toast ref="toast" />

  <div class="w-full relative text-primary6">
    <div
      :class="filterClasses"
      class="border rounded mt-3 p-3 flex items-end hidden-print"
    >
      <div class="w-fit bg-white p-4 flex gap-3">
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Warehouse</label
          >

          <Select
            class="w-full md:w-56 h-9 text-start flex items-center"
            :options="warehouse"
            v-model="selectWarehouse"
            filter
            placeholder="Select Warehouse"
            option-label="name"
            option-value="id"
            show-clear
            checkmark
            size="medium"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Rice Type</label
          >
          <Select
            class="w-full md:w-56 h-9 text-start flex items-center"
            :options="rices"
            filter
            placeholder="Select Rice"
            option-label="name"
            v-model="selectRice"
            option-value="name"
            show-clear
            checkmark
            size="medium"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier"
            ><span class="text-primary4 mr-2"></span>Date Rage</label
          >
          <DatePicker
            v-model="selectDateRage"
            selectionMode="range"
            class="w-full md:w-56 h-1 text-start flex items-center"
            placeholder="Select Date "
            :manualInput="false"
          />
        </div>
        <div class="filter_type_wrap">
          <label for="Supplier" class="opacity-0"
            ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
          >
          <el-button @click="handleFilterButtonClick" size="large"
            >Filter</el-button
          >
        </div>
      </div>
      <div class="w-fit bg-white p-4 flex gap-3">
        <button @click="exportToExcel" class="btncsv flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </div>
          CSV
        </button>

        <button @click="downloadPDF" class="btncancel flex items-center gap-2">
          <div>
            <font-awesome-icon :icon="['fas', 'file']" class="size-5" />
          </div>
          PDF
        </button>
        <button @click="printTable" class="flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          Print
        </button>
      </div>
    </div>
    <div :class="tableClasses">
      <table id="printTable" class="overflow-auto border">
        <tbody>
          <tr class="header_table">
            <td
              @click="sortData('id')"
              class="min-w-[95px] border-b text-start toggle_sort text-nowrap"
            >
              No
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'id' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : sortColumn === 'id' && sortDirections === 'desc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('name')"
              class="min-w-[180px] border-b text-start toggle_sort text-nowrap"
            >
              Warehouse
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'name' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[180px] border-b text-start">Created At</td>

            <td class="min-w-[180px] border-b text-start">
              Rice Type
              <!-- <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span> -->
            </td>
            <td
              @click="sortData('weight')"
              class="min-w-[180px] border-b text-start toggle_sort text-nowrap"
            >
              Original Weight (Kg)
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'weight' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td
              @click="sortData('weight')"
              class="min-w-[180px] border-b text-start toggle_sort text-nowrap"
            >
              Amount Adjust (Kg)
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'weight' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>

            <td
              @click="sortData('weight')"
              class="min-w-[180px] border-b text-start toggle_sort text-nowrap"
            >
              Amount After Adjust (Kg)
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'weight' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td
              @click="sortData('note')"
              class="border-b text-start toggle_sort text-nowrap"
            >
              Note
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="
                      sortColumn === 'note' && sortDirections === 'asc'
                        ? ['fas', 'sort']
                        : ['fas', 'sort']
                    "
                  />
                </transition>
              </span>
            </td>
            <td class="border-b">Acion</td>
          </tr>
        </tbody>

        <tbody v-if="adjust.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="(data, index) in adjust"
            :key="data"
          >
            <td class="border-b">{{ data.id }}</td>
            <td class="border-b">{{ data.name }}</td>
            <td class="border-b">{{ formatDate2(data.created_at) }}</td>
            <td class="border-b">
              {{ data.rice.rice_name }}
            </td>
            <td class="border-b">
              {{ numeralFormat(data.rice.original_weight, "0,0[.]00") }} Kg
            </td>
            <td class="border-b">
              {{ numeralFormat(data.rice.weight_adjust, "0,0[.]00") }} Kg
            </td>
            <td class="border-b">
              {{ numeralFormat(data.rice.final_weight, "0,0[.]00") }} Kg
            </td>
            <td class="border-b text-nowrap">
              {{ data.note ? data.note : "No Note" }}
            </td>
            <td class="border-b">
              <div class="flex items-center gap-3">
                <svg
                  @click="index === 0 ? openModal(data) : null"
                  :class="{
                    'cursor-pointer': index === 0,
                    'cursor-not-allowed opacity-50': index !== 0,
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 delete"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full flex items-center justify-between">
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        :totalRecord="totalRecord"
      />
    </div>
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
                <DialogTitle
                  class="text-lg text-start font-medium text-gray-900"
                >
                  Confirm Delete
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-nowrap text-start">
                    Are You sure you want to delete [
                    {{ selectedDoc.id }} ]
                  </p>
                </div>
                <div class="mt-4 flex justify-end gap-2">
                  <button
                    class="bg-gray-200 px-4 py-2 rounded text-primary2"
                    @click="closeModal"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-red-600 text-white px-4 py-2 rounded"
                    @click="confirmDelete"
                  >
                    Delete
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, onMounted, onUnmounted, computed } from "vue";
import ClientFormVue from "@/form/ClientForm.vue";
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
  getResponsiveFilter,
  getResponsiveTable,
} from "@/composables/useResponsive";
import { mdiEyeOutline, mdiTrashCan, mdiPencil } from "@mdi/js";
import Toast from "@/components/Toast.vue";
import { deleteStockRiceTransaction } from "@/composables/useWareHouseStockRiceTransaction";

export default {
  components: {
    ClientFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    NoDataComponet,
    Toast,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "ClientFormVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive function

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const filterClasses = computed(() => {
      const classes = getResponsiveFilter(windowWidth.value);

      return classes;
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const adjust = ref([]);
    const currentDate = new Date();
    const selectWarehouse = ref(null);
    const selectRice = ref(null);

    const selectDateRage = ref([
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
    ]);
    const totalRecord = ref(0);
    const fetchAdjustHistory = async () => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          page: currentPage.value,
          pageSize: pageSize.value,
          sortDirection: "DESC",
          columnAliases: JSON.stringify({
            id: { table: "stock_rice_transaction_history", column: "id" },
            warehouse_id: {
              table: "stock_rice_transaction_history",
              column: "warehouse_id",
            },
            created_by: {
              table: "stock_rice_transaction_history",
              column: "created_by",
            },
            name: {
              table: "warehouse",
              column: "name",
            },
          }),
          sortColumn: "id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "warehouse",
              joinColumnChild: "warehouse_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "transaction_type",
              operator: "=",
              value: "adjust",
              typeTable: "table",
            },

            // Filter by date range if selected
            ...(selectDateRage.value
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: selectDateRage.value[0].toISOString().split("T")[0],
                    typeTable: "table",
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: selectDateRage.value[1].toISOString().split("T")[0],
                    typeTable: "table",
                  },
                ]
              : []),
            // Filter by selected warehouse if available
            ...(selectWarehouse.value
              ? [
                  {
                    field: "warehouse_id",
                    operator: "=",
                    value: selectWarehouse.value,
                    typeTable: "table",
                  },
                ]
              : []),
            // Filter by selected rice type if available
            // ...(selectRice.value
            //   ? [
            //       {
            //         field: "rices",
            //         operator: "=",
            //         value: selectRice.value,
            //         typeTable: "table",
            //       },
            //     ]
            //   : []),
          ]),
        };

        const response = await axios(`${apirURL}/weighing/api/getPagination`, {
          params,
        });

        adjust.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
        totalRecord.value = adjust.value.length;
        console.log("adjust", adjust.value);
        if (selectRice.value) {
          adjust.value = adjust.value
            .map((warehouse) => {
              return {
                ...warehouse,
                rice: warehouse.rice.filter(
                  (item) => item.rice_name === selectRice.value
                ),
              };
            })
            .filter((warehouse) => warehouse.rice.length > 0);
        }
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const downloadPDF = async () => {
      // Create the printDiv with dynamic content
      const printDiv = document.createElement("div");
      printDiv.innerHTML = `
    <div style="padding: 20px; font-family: 'Noto Serif Khmer', serif;">
      <div style="text-align: center;">
        <h2 style="font-size: 24px; margin: 0; font-weight: bold;">Adjust History</h2>
      </div>

      <!-- Table -->
      <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr style="background-color: #f8f9fa;">
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">No</th>
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">ID</th>
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Warehouse</th>
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Created By</th>
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Rice Type</th>
            <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Adjusted Amount (Kg)</th>
          </tr>
        </thead>
        <tbody>
          ${adjust.value
            .map(
              (data, index) => `
              <tr>
                <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                  index + 1
                }</td>
                <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                  data.id
                }</td>
                <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                  data.name
                }</td>
                <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                  data.created_by
                }</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${
                  data.rice.rice_name
                }</td>

                <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px; text-align: right;">${
                  data.weight
                } Kg</td>
              </tr>`
            )
            .join("")}
        </tbody>
      </table>
    </div>
  `;

      // Append the printDiv to the document body
      document.body.appendChild(printDiv);

      try {
        // Use html2canvas to capture the table
        const canvas = await html2canvas(printDiv, {
          scale: 2, // Increase quality
          useCORS: true, // For handling cross-origin issues
          logging: false,
          windowWidth: 1200, // Set a fixed width for consistency
          onclone: (clonedDoc) => {
            const element = clonedDoc.querySelector("table");
            element.style.width = "1200px"; // Set a fixed width for the table in the clone
          },
        });

        // Create PDF with portrait orientation and A4 size
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add image to first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add new pages if content exceeds first page
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // Save the PDF
        pdf.save("adjust_history_report.pdf");
      } catch (error) {
        console.error("PDF generation error:", error);
      } finally {
        // Remove the printDiv from the document after use
        document.body.removeChild(printDiv);
      }
    };

    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(adjust.value);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Adjust History");

      XLSX.writeFile(workbook, "adjust_history_report.xlsx");
    };
    const printTable = () => {
      const printContents = document.getElementById("printTable").outerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    };
    const warehouse = ref([]);
    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rices = ref([]);
    const fetchRice = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const handleFilterButtonClick = () => {
      fetchAdjustHistory();
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchAdjustHistory()]);
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchAdjustHistory(),
        fetchRice(),
        fetchWarehouse(),
        fetctLastRecord(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });

    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchAdjustHistory();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchAdjustHistory();
    };

    const totalWeight = (stock) => {
      if (typeof stock === "string") {
        stock = JSON.parse(stock);
      }

      return stock.reduce(
        (total, item) => total + (item.current_weight || 0),
        0
      );
    };
    const isFilterable = ref(false);
    const sortDirections = ref("asc");
    const sortColumn = ref(null);
    const sortData = (field) => {
      const direction =
        sortColumn.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumn.value = field;

      // Apply sorting based on the selected field (e.g., 'from_weight', 'to_weight', 'less_weight')
      adjust.value = toggleSort(direction, adjust.value, field);
    };

    const toggleSort = (direction, data, field) => {
      return data.sort((a, b) => {
        let comparison = 0;

        if (field === "id") {
          comparison = a.id - b.id;
        } else if (field === "name") {
          comparison = a.name.localeCompare(b.name);
        }
        if (field === "weight") {
          comparison = a.weight - b.weight;
        }

        return direction === "asc" ? comparison : -comparison;
      });
    };
    const invoice_last_record = ref([]);
    const fetctLastRecord = async () => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "transaction_type",
              operator: "=",
              value: "adjust",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        invoice_last_record.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const selectedDoc = ref(null);
    const isModalOpen = ref(false);

    const fetchLastRecordStockRiceTransaction = async (
      id,
      warehouse_id,
      rice_name
    ) => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: warehouse_id,
              typeTable: "table",
            },

            {
              field: "id",
              operator: "!=",
              value: id,
              typeTable: "table",
            },

            {
              field: "transaction_type",
              operator: "!=",
              value: "purchase",
              typeTable: "table",
            },
            {
              field: "transaction_type",
              operator: "!=",
              value: "sell",
              typeTable: "table",
            },

            {
              field: "rice",
              operator: "->>",
              key: "rice_name",
              value: rice_name,
              type: "jsonb",
              typeTable: "table",
            },

            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params,
          }
        );

        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching last record:", error);
      }
    };

    const openModal = async (doc) => {
      const lastRecord = await fetchLastRecordStockRiceTransaction(
        doc.id,
        doc.warehouse_id,
        doc.rice.rice_name
      );
      if (lastRecord.length > 0) {
        currentComponent.value = "ToastVue";
        showToast.value = true;
        informMessage.value = `
Cannot delete this record: ${doc.rice.rice_name + " " + doc.transaction_type}
Reason: Must delete the last associated record first
Details:
- Transaction Type: ${lastRecord[0].transaction_type}
- Transaction ID: ${lastRecord[0].id}
`;
        return;
      } else {
        selectedDoc.value = doc;
        isModalOpen.value = true;
      }
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const confirmDelete = async () => {
      if (!selectedDoc.value) return;

      try {
        await deleteStockRiceTransaction(selectedDoc.value);
        showSuccessToast("delete");
      } catch (error) {
        console.error("Error during deletion and update process:", error);
      } finally {
        closeModal();
      }
    };

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      tableClasses,
      filterClasses,
      adjust,
      formatDate2,
      totalWeight,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      handleFilterButtonClick,
      selectDateRage,
      rices,
      warehouse,
      selectWarehouse,
      isFilterable,
      totalRecord,
      printTable,
      downloadPDF,
      exportToExcel,
      sortData,
      selectRice,
      isModalOpen,
      openModal,
      closeModal,
      confirmDelete,
      selectedDoc,
      invoice_last_record,
      mdiEyeOutline,
      mdiPencil,
      showSuccessToast,
      toast,
    };
  },
};
</script>

<style></style>
