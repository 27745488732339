<template>
  <div class="w-[737px] h-auto">
    <div class="details_title">
      <h2 class="ml-4">Boat: {{ data.name }}</h2>
      <div class="flex items-center gap-2">
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          pop_up_tab: activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>

    <div v-if="activeTab === 'details'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2 space-y-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Customer Name</h2>
            <h2>{{ getCusomterName(datatoedit.customer_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Parking</h2>
            <h2>{{ getParkingName(datatoedit.parking.parking_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Boat</h2>
            <h2>{{ datatoedit.name }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction History</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No</td>
              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Weight</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
              <td class="min-w-[100px] border-b text-start">Boat</td>
            </tr>
          </tbody>

          <tbody>
            <tr
              v-if="weighing_rice_history.length > 0"
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing_rice_history"
              :key="data"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ getRiceName(data.rice_id) }}</td>
              <td class="border-b">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{
                  numeralFormat(
                    data.weight_in - data.weight_out - data.amount_cut_off,
                    "0,0[.]00"
                  )
                }}
                Kg
              </td>
              <td class="border-b">
                {{ getBoatName(data.boat_enter_parking_id) }}
              </td>
            </tr>
            <tr v-else>
              <td colspan="7">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5 py-4">
        <button class="btncancel">Close</button>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2 space-y-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Customer Name</h2>
            <h2>{{ getCusomterName(datatoedit.customer_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Parking</h2>
            <h2>{{ getParkingName(datatoedit.parking.parking_id) }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Boat</h2>
            <h2>{{ datatoedit.name }}</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>{{ formatDate2(data.created_at) }}</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">No</td>
              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">Rice Type</td>
              <td class="min-w-[100px] border-b text-start">Weight</td>
              <td class="min-w-[100px] border-b text-start">Cut out</td>
              <td class="min-w-[100px] border-b text-start">Remain</td>
              <td class="min-w-[100px] border-b text-start">Boat</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing_rice_historys"
              :key="data"
              v-if="weighing_rice_historys.length > 0"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ getRiceName(data.rice_id) }}</td>
              <td class="border-b">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{
                  numeralFormat(
                    data.weight_in - data.weight_out - data.amount_cut_off,
                    "0,0[.]00"
                  )
                }}
                Kg
              </td>
              <td class="border-b">
                {{ getBoatName(data.boat_enter_parking_id) }}
              </td>
            </tr>
            <tr v-else>
              <td colspan="7">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5 py-4">
        <button class="btncancel">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import axios from "axios";
import apirURL from "@/services/apiURL";
import NoDataComponet from "@/components/NoDataComponet.vue";
import moment from "moment-timezone";

export default {
  props: ["datatoedit"],
  components: {
    NoDataComponet,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const baot = ref([]);
    const fetchBoatEnterParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        baot.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parking = ref([]);
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const weighing_rice_history = ref([]);
    const fetchWeighingHistory = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "boat_enter_parking_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing_rice_history.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const weighing_rice_historys = ref([]);
    const fetchWeighingHistorys = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        weighing_rice_historys.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rice = ref([]);
    const fetchRices = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const customer = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customer.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    onMounted(async () => {
      if (props.datatoedit) {
        console.log("props.datatoedit", props.datatoedit);
      }
      await Promise.allSettled([
        fetchBoatEnterParking(),
        fetchWeighingHistory(),
        fetchRices(),
        fetchParking(),
        fetchWeighingHistorys(),
        fetchCustomer(),
      ]);
    });
    const getRiceName = (id) => {
      const name = rice.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getBoatName = (id) => {
      const name = baot.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getParkingName = (id) => {
      const name = parking.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getCusomterName = (id) => {
      const name = customer.value.find((item) => item?.id === id);
      return name?.name;
    };
    const data = props.datatoedit;
    return {
      handleClose,
      setActiveTab,
      activeTab,
      data,
      baot,
      formatDate2,
      weighing_rice_history,
      getRiceName,
      getBoatName,
      getParkingName,
      weighing_rice_historys,
      getCusomterName,
    };
  },
};
</script>

<style></style>
