<template>
  <div class="w-[500px] h-auto bg-white text-primary6">
    <form @submit.prevent="handleSubmit" class="">
      <div class="form_title">
        <h2 class="ml-4">
          {{ isUpdate ? "Update Pre Loan" : "New Pre-Loan" }}
        </h2>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div class="w-full h-fit p-5 space-y-4 flex flex-col justify-between">
        <div class="space-y-4">
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1">
              <span class="text-red-500" :class="{ 'mb-12': isSelectSupplier }"
                >*</span
              >
              Supplier
            </label>
            <div class="flex flex-col space-y-2">
              <Select
                :invalid="isSelectSupplier === true"
                v-model="selectedSupplier"
                class="w-full md:w-56 h-9 text-start flex items-center"
                :options="suppliers"
                filter
                placeholder="Select Supplier"
                optionLabel="name"
                optionValue="id"
                show-clear
                checkmark
                style="width: 250px"
                size="medium"
              />
              <div v-if="isSelectSupplier === true" class="mt-1">
                <el-alert
                  title="Please Select Supplier"
                  type="error"
                  :closable="false"
                />
              </div>
            </div>
          </div>

          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1">
              <span class="text-red-500" :class="{ 'mb-12': isGetBy }">*</span
              >Get By</label
            >
            <div>
              <div class="flex flex-col space-y-2">
                <Select
                  :invalid="isGetBy === true"
                  v-model="selectedGetBy"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  :options="getPreloanBy"
                  filter
                  placeholder="Select Get By"
                  optionLabel="name"
                  optionValue="name"
                  show-clear
                  checkmark
                  style="width: 250px"
                  size="medium"
                />

                <div v-if="isGetBy === true" class="mt-1">
                  <el-alert
                    title="Please Select Get By"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center gap-3 justify-between"
            v-if="selectedGetBy === 'partner'"
          >
            <label for="name" class="flex gap-1">
              <span class="text-red-500" :class="{ 'mb-12': isSelectPartner }"
                >*</span
              >
              Partner</label
            >
            <div>
              <div class="flex flex-col space-y-2">
                <Select
                  :invalid="isSelectPartner === true"
                  v-model="partnerSelected"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                  :options="partners"
                  filter
                  placeholder="Select Partner"
                  optionLabel="name"
                  optionValue="id"
                  show-clear
                  checkmark
                  style="width: 250px"
                  size="medium"
                />

                <div v-if="isSelectPartner === true" class="mt-1">
                  <el-alert
                    title="Please Select Partner"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Loan Amount</label
            >
            <div>
              <div class="flex items-center justify-between text-start gap-1">
                <div class="flex">
                  <div>
                    <InputNumber
                      :invalid="isAmount === true"
                      v-model="amount"
                      :maxFractionDigits="2"
                      class="w-[125px] h-2 text-start items-center"
                      placeholder="Amount"
                      :min="1"
                      step="0.01"
                    />
                  </div>
                </div>

                <div class="flex">
                  <Select
                    v-model="selectedCurrency"
                    :invalid="isSelectCurrency === true"
                    disabled
                    class="w-[125px] h-9 text-start flex items-center"
                    :options="currencies"
                    optionLabel="currency_name"
                    placeholder="Select Currency"
                    size="medium"
                  >
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSelectCurrency === true" class="">
            <el-alert
              title="Please Select Currency"
              type="error"
              :closable="false"
            />
          </div>
          <div v-if="isAmount === true" class="">
            <el-alert
              title="Please Enter Amount"
              type="error"
              :closable="false"
            />
          </div>
        </div>

        <div class="flex space-x-5">
          <label for="name" class="">Note</label>
          <textarea
            v-model="note"
            class="px-3 border rounded border-primary5 placeholder:text-primary5 outline-none focus:ring-[1.5px] focus:ring-primary3"
            rows="5"
            cols="50"
            placeholder="Note"
          />
        </div>

        <div class="flex w-full items-center gap-3">
          <button type="submit">{{ isUpdate ? "Update" : "Save" }}</button>
          <button class="btncancel" type="button" @click="SaveAndNew">
            {{ isUpdate ? "Update & New" : "Save & New" }}
          </button>
          <button class="btncancel" type="button" @click="handleClose">
            Cencel
          </button>
        </div>
      </div>
    </form>
  </div>
  <div></div>
  <component
    :is="currentComponent"
    :showToast="showToast"
    :informMessage="informMessage"
    @onClose="handleClearToast"
  />
</template>

<script>
import { validateInputNUmber } from "@/composables/ValidateInput";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import { addPreloan, updatePreloan } from "@/composables/usePreloan";
import { decodeJwt } from "@/composables/decodeJWT";
import { DMTelegram } from "@/composables/useDMTelegram";
// import { calculateBalance } from "@/utils/calculateWallet";
import ToastVue from "@/components/ToastVue.vue";
import numeral from "numeral";
import { formatDate } from "@/composables/formatDateTime";
import { formatMobileNumber } from "@/composables/formatMobileNumber";

export default {
  props: ["datatoedit"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ToastVue,
  },

  setup(props, { emit }) {
    const amount = ref(null);
    const handleClose = () => {
      emit("close");
    };
    const partnerSelected = ref(null);
    const isOpen = ref(true);
    const partners = ref([]);
    const status = ref(true);
    const status_paid = ref("due");
    const isSelectPartner = ref(false);
    const isSelectSupplier = ref(false);
    const isAmount = ref(false);
    const isUpdatig = ref(false);
    const isAdding = ref(false);
    const isUpdate = props.datatoedit;
    const selectedCurrency = ref(null);
    const selectedSupplier = ref(null);
    const isSelectCurrency = ref(false);
    const authUserId = ref(null);
    const isGetBy = ref(false);
    const selectedGetBy = ref(null);
    const note = ref(null);
    const getPreloanBy = ref([
      {
        name: "internal",
        id: 1,
      },
      {
        name: "partner",
        id: 2,
      },
    ]);

    const handleClear = () => {
      amount.value = null;
      partnerSelected.value = null;
      status_paid.value = "due";
      selectedSupplier.value = null;
      isSelectCurrency.value = false;
      isSelectPartner.value = false;
      isSelectSupplier.value = false;
      selectedGetBy.value = null;
      isGetBy.value = false;
      note.value = null;
      isAmount.value = false;
    };

    const handleClearToast = () => {
      currentComponent.value = "";
      informMessage.value = "";
      showToast.value = false;
    };

    watch(partnerSelected, async (newVal) => {
      if (newVal) {
        await fetchPartnerByID(newVal);
        isSelectPartner.value = false;
      }
    });

    watch(selectedSupplier, (newVal) => {
      if (newVal) {
        isSelectSupplier.value = false;
      }
    });

    watch(selectedGetBy, (newVal) => {
      if (newVal) {
        isGetBy.value = false;

        if (newVal === "internal") {
          partnerSelected.value = null;
          isSelectPartner.value = false;
        }
      }
    });

    watch(amount, (newVal) => {
      if (newVal) {
        isAmount.value = false;
      }
    });

    watch(selectedCurrency, (newVal) => {
      if (newVal) {
        isSelectCurrency.value = false;
      }
    });

    const suppliers = ref([]);
    const fetchsuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partners.value = response.data;
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };
    const currencies = ref([]);
    const fetchCurrency = async () => {
      try {
        const req = {
          tableName: "currencies",
          sortColumn: "id",
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        if (res.data.length > 0) {
          currencies.value = res.data
            .filter(
              (c) => c.symbol_currency !== "đ" && c.symbol_currency !== "฿"
            )
            .map((c) => ({
              ...c,
              currency_name: c.currency_name.toUpperCase(),
            }))
            .sort((a, b) => a.currency_name.localeCompare(b.currency_name));

          const findKHRCurrency = currencies.value.find(
            (c) => c.symbol_currency === "៛"
          );
          selectedCurrency.value = findKHRCurrency ? findKHRCurrency : null;
        }
      } catch (error) {
        console.log(`failed to fetch currency ${error}`);
      }
    };

    const partnerByIDData = ref([]);
    const fetchPartnerByID = async (id) => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partnerByIDData.value = response.data;

        console.log("partnerByIDData", partnerByIDData.value);
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    const currentComponent = ref("");
    const informMessage = ref("");
    const showToast = ref(false);

    const handleSubmit = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "preloans",
          fields: {
            partner_id: partnerSelected.value,
            supplier_id: selectedSupplier.value,
            amount: amount.value,
            status: status.value,
            status_paid: status_paid.value,
            get_preloan_by: selectedGetBy.value,
            note: note.value,
            cut_preloans: JSON.stringify([
              {
                total_amount_owed: amount.value || 0,
                supplier_invoice_id: null,
                amount_paid: 0,
                amount_remaining: amount.value || 0,
                status: "due",
              },
            ]),
            currency: JSON.stringify(selectedCurrency.value),
          },
        };

        if (!selectedSupplier.value) {
          isSelectSupplier.value = true;
          return;
        } else if (!selectedGetBy.value) {
          isGetBy.value = true;
          return;
        } else if (
          selectedGetBy.value === "partner" &&
          !partnerSelected.value
        ) {
          isSelectPartner.value = true;
          return;
        } else if (!amount.value) {
          isAmount.value = true;
          return;
        } else if (!selectedCurrency.value) {
          isSelectCurrency.value = true;
          return;
        } else {
          if (props.datatoedit) {
            requestBody.fields.updated_at = timestamp;
            requestBody.fields.updated_by = authUserId.value;
            const response = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.preloan_id}`,
              requestBody
            );
            if (response.data.message) {
              const findSupplier = suppliers.value.find(
                (s) => s.id === selectedSupplier.value
              );

              const findPartner = partnerByIDData.value[0];
              const Message1 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              },
Transaction Date: ${formatDate(props.datatoedit.createdat)}

A preloan has been updated for ${
                findSupplier
                  ? findSupplier.name +
                    " | " +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              }, changing the amount from ${numeral(
                props.datatoedit.amount
              ).format("0,0")} ${
                props.datatoedit.currency?.symbol_currency
              } to ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }. Please process it as soon as possible.

Date: ${formatDate(timestamp)}`;

              const Message2 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              }
Transaction Date: ${formatDate(timestamp)}

A preloan has been requested for ${
                findSupplier
                  ? findSupplier.name +
                    "|" +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } in the amount of: ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }.
Please process it as soon as possible.
Date: ${formatDate(timestamp)}`;

              const Message3 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              },
Transaction Date: ${formatDate(props.datatoedit.createdat)}

The preloan amount of ${numeral(props.datatoedit.amount).format("0,0")} ${
                props.datatoedit.currency?.symbol_currency
              } for the transaction with ${
                findSupplier
                  ? findSupplier.name +
                    " | " +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } has been canceled. Please take note of this change.

Date: ${formatDate(timestamp)}`;

              if (selectedGetBy.value === "partner") {
                DMTelegram(
                  partnerSelected.value,
                  props.datatoedit.get_preloan_by === "internal" &&
                    selectedGetBy.value === "partner"
                    ? Message2
                    : Message1
                );
              } else if (
                props.datatoedit.get_preloan_by === "partner" &&
                selectedGetBy.value === "internal"
              ) {
                DMTelegram(props.datatoedit.partner_id, Message3);
              }
              updatePreloan(props.datatoedit, requestBody.fields);
            }

            emit("toast", "update");
            handleClose();
            handleClear();
          } else {
            requestBody.fields.created_at = timestamp;
            requestBody.fields.created_by = authUserId.value;

            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              requestBody
            );

            if (response.data.message) {
              const findSupplier = suppliers.value.find(
                (s) => s.id === selectedSupplier.value
              );
              const findParter = partnerByIDData.value[0];

              console.log("insertedId", response.data.insertedId);
              addPreloan(requestBody.fields, response.data.insertedId);
              const Message = `Hello, Dear ${findParter ? findParter.name : ""}
Transaction Date: ${formatDate(timestamp)}

A preloan has been requested for ${
                findSupplier
                  ? findSupplier.name +
                    "|" +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } in the amount of: ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }.
Please process it as soon as possible.
Date: ${formatDate(timestamp)}`;

              if (selectedGetBy.value === "partner") {
                DMTelegram(partnerSelected.value, Message);
              }
              emit("toast", "create");
              handleClose();
              handleClear();
            }
          }
        }
      } catch (error) {
        console.log("Error in pre-loan submission:", error);
      } finally {
        isAdding.value = false;
      }
    };

    const SaveAndNew = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "preloans",
          fields: {
            partner_id: partnerSelected.value,
            supplier_id: selectedSupplier.value,
            amount: Number(amount.value),
            status: status.value, // status for default berk or min torn berk default it mean berk true
            status_paid: status_paid.value,
            get_preloan_by: selectedGetBy.value,
            note: note.value,
            cut_preloans: JSON.stringify([
              {
                total_amount_owed: Number(amount.value) || 0,
                supplier_invoice_id: null,
                amount_paid: 0,
                amount_remaining: Number(amount.value) || 0,
                status: "due",
              },
            ]),
            currency: JSON.stringify(selectedCurrency.value),
          },
        };

        if (!selectedSupplier.value) {
          isSelectSupplier.value = true;
          return;
        } else if (!selectedGetBy.value) {
          isGetBy.value = true;
          return;
        } else if (
          selectedGetBy.value === "partner" &&
          !partnerSelected.value
        ) {
          isSelectPartner.value = true;
          return;
        } else if (!amount.value) {
          isAmount.value = true;
          return;
        } else if (!selectedCurrency.value) {
          isSelectCurrency.value = true;
          return;
        } else {
          if (props.datatoedit) {
            requestBody.fields.updated_at = timestamp;
            requestBody.fields.updated_by = authUserId.value;

            console.log("requestBody", requestBody);

            const response = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.preloan_id}`,
              requestBody
            );
            if (response.data.message) {
              const findSupplier = suppliers.value.find(
                (s) => s.id === selectedSupplier.value
              );

              const findPartner = partnerByIDData.value[0];
              const Message1 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              },
Transaction Date: ${formatDate(props.datatoedit.createdat)}

A preloan has been updated for ${
                findSupplier
                  ? findSupplier.name +
                    " | " +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              }, changing the amount from ${numeral(
                props.datatoedit.amount
              ).format("0,0")} ${
                props.datatoedit.currency?.symbol_currency
              } to ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }. Please process it as soon as possible.

Date: ${formatDate(timestamp)}`;

              const Message2 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              }
Transaction Date: ${formatDate(timestamp)}

A preloan has been requested for ${
                findSupplier
                  ? findSupplier.name +
                    "|" +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } in the amount of: ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }.
Please process it as soon as possible.
Date: ${formatDate(timestamp)}`;

              const Message3 = `Hello, Dear ${
                findPartner ? findPartner.name : ""
              },
Transaction Date: ${formatDate(props.datatoedit.createdat)}

The preloan amount of ${numeral(props.datatoedit.amount).format("0,0")} ${
                props.datatoedit.currency?.symbol_currency
              } for the transaction with ${
                findSupplier
                  ? findSupplier.name +
                    " | " +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } has been canceled. Please take note of this change.

Date: ${formatDate(timestamp)}`;

              if (selectedGetBy.value === "partner") {
                DMTelegram(
                  partnerSelected.value,
                  props.datatoedit.get_preloan_by === "internal" &&
                    selectedGetBy.value === "partner"
                    ? Message2
                    : Message1
                );
              } else if (
                props.datatoedit.get_preloan_by === "internal" &&
                selectedGetBy.value === "partner"
              ) {
                DMTelegram(props.datatoedit.partner_id, Message3);
              }

              updatePreloan(props.datatoedit, requestBody.fields);
            }

            emit("toast", "update");

            handleClear();
          } else {
            requestBody.fields.created_at = timestamp;
            requestBody.fields.created_by = authUserId.value;

            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              requestBody
            );

            console.log("requestBody", requestBody);
            if (response.data.message) {
              const findSupplier = suppliers.value.find(
                (s) => s.id === selectedSupplier.value
              );
              const findParter = partnerByIDData.value[0];
              addPreloan(requestBody.fields, response.data.insertedId);
              const Message = `Hello, Dear ${findParter ? findParter.name : ""}

A preloan has been requested for ${
                findSupplier
                  ? findSupplier.name +
                    "|" +
                    formatMobileNumber(findSupplier.phone_number)
                  : ""
              } in the amount of: ${numeral(amount.value).format("0,0")} ${
                selectedCurrency.value?.symbol_currency
              }.
Please process it as soon as possible.
Date: ${formatDate(timestamp)}`;

              if (selectedGetBy.value === "partner") {
                DMTelegram(partnerSelected.value, Message);
              }
              emit("toast", "create");

              handleClear();
            }
          }
        }
      } catch (error) {
        console.log("Error in pre-loan submission:", error);
      } finally {
        isAdding.value = false;
      }
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchsuppliers(),
        fetchPartner(),
        fetchCurrency(),
      ]);
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchsuppliers(),
        fetchPartner(),
        fetchCurrency(),
      ]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.datatoedit) {
        isUpdate.value = true;
        selectedSupplier.value = props.datatoedit.supplier_id;
        partnerSelected.value = props.datatoedit.partner_id;
        status_paid.value = props.datatoedit.status_paid;
        amount.value = Number(props.datatoedit.amount);
        selectedGetBy.value = props.datatoedit.get_preloan_by;
        const findCurrency = currencies.value.find(
          (c) => c.id === props.datatoedit.currency?.id
        );
        selectedCurrency.value = findCurrency ? findCurrency : null;
      }

      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime", handleDatabaseUpdate);
    });

    return {
      note,
      getPreloanBy,
      selectedGetBy,
      isGetBy,
      currentComponent,
      informMessage,
      showToast,
      handleClearToast,

      isSelectCurrency,
      isSelectPartner,
      isSelectSupplier,
      isAmount,

      selectedCurrency,
      currencies,
      handleClose,
      validateInputNUmber,
      selectedSupplier,
      suppliers,
      partners,
      partnerSelected,
      status,
      amount,
      handleSubmit,
      SaveAndNew,
      isAdding,
      isUpdatig,
      isUpdate,

      isOpen,
    };
  },
};
</script>

<style></style>
