<template>
  <Toast ref="toast" />
  <div class="w-full text-primary6 relative">
    <!-- <div :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Supplier</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedSupplier"
          :options="suppliers"
          optionLabel="name"
          option-value="id"
          :highlightOnSelect="true"
          filter
          placeholder="Select Supplier"
          show-clear
          checkmark
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Truck</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedTruck"
          :options="plates"
          filter
          placeholder="Select Truck"
          show-clear
          checkmark
          option-label="plate_number"
          option-value="plate_number"
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Rice Type</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedRice"
          :options="rice"
          filter
          placeholder="Select Rice"
          show-clear
          checkmark
          option-label="name"
          option-value="name"
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Parking</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedParking"
          :options="boatIntoParking"
          filter
          placeholder="Select Parking"
          option-value="id"
          option-label="name"
          show-clear
          checkmark
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2"></span>Receiver</label
        >
        <button class="btn_filter" @click="handleFilterButtonClick">
          Filter
        </button>
      </div>
    </div> -->

    <div :class="navClasses">
      <div>
        <h2>Weighing List</h2>
      </div>
      <div>
        <button @click="handleAdd" class="m-2">New Weighing</button>
      </div>
    </div>
    <div class="flex gap-3 items-start">
      <div :class="mainClassesTable" class="h-[600px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[126px] border-b">Acion</td>
              <td
                @click="sortData('supplier_name')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Supplier
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'supplier_name' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>

              <td
                @click="sortData('plate_number')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Truck
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'plate_number' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>
          <tr class="font-semibold">
            <td colspan="5" class="border-b">
              <div class="flex items-center justify-center gap-2">
                <IconField class="w-[80%] placeholder:text-body2">
                  <InputText
                    v-model="searchValue"
                    placeholder="Search | Supplier | Phone Number"
                  />
                  <InputIcon class="pi pi-search" />
                </IconField>
              </div>
            </td>
          </tr>
          <tbody v-if="joinWeighingData.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white border-b"
              v-for="(data, i) in joinWeighingData"
              :key="i"
              @click="handleBoatSelection(data)"
            >
              <td>
                <div class="flex items-center gap-3">
                  <svg-icon
                    :path="mdiTrashCanOutline"
                    type="mdi"
                    @click="openDeleteConfirmation(data)"
                    class="delete"
                  ></svg-icon>
                </div>
              </td>

              <td class="border-b">
                {{
                  data.supplier_name ? data.supplier_name : data.warehouse_name
                }}
              </td>

              <td class="border-b">
                {{ data.plate_number?.plate_number }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :class="subClassesTable" class="border h-[600px]">
        <table class="text-body font-normal text-primary2">
          <tbody>
            <tr class="font-semibold">
              <td class="border-b w-full" colspan="5">
                <div class="w-full flex items-center justify-between">
                  <div class="w-1/2">
                    <h2 class="text-nowrap">
                      Count Time:
                      {{
                        weighing_rice_history.length
                          ? weighing_rice_history.length
                          : 0
                      }}
                    </h2>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="text-primary3 bg-primary3/20 font-semibold">
              <td
                @click="sortHistoryData('id')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Id
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortHistory === 'id' && sortHistoryDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="border-b">Created At</td>
              <td
                @click="sortHistoryData('weight_in')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortHistory === 'weight_in' &&
                        sortHistoryDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortHistoryData('rice_name')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Rice
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortHistory === 'rice_name' &&
                        sortHistoryDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="border-b">Action</td>
            </tr>
          </tbody>
          <tbody></tbody>
          <tbody v-if="weighing_rice_history.length > 0">
            <tr
              class="bg-white"
              v-for="(data, i) in weighing_rice_history"
              :key="i"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.createdAt) }}</td>

              <td class="border-b">
                {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                <p v-for="rice in data.rice" :key="rice.id">
                  {{ rice.rice_name }}
                </p>
              </td>

              <td class="border-b">
                <div class="flex gap-3 items-center">
                  <svg-icon
                    :path="mdiPencil"
                    class="edit"
                    @click="
                      i === weighing_rice_history.length - 1 &&
                        handleEditData(data)
                    "
                    :class="{
                      'text-gray-400': i !== weighing_rice_history.length - 1,
                      'cursor-not-allowed':
                        i !== weighing_rice_history.length - 1,
                    }"
                    type="mdi"
                  ></svg-icon>

                  <svg
                    @click="
                      i === weighing_rice_history.length - 1 &&
                        handleEditData(data)
                    "
                    :class="{
                      'text-gray-400': i !== weighing_rice_history.length - 1,
                      'cursor-not-allowed':
                        i !== weighing_rice_history.length - 1,
                    }"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    :disabled="i !== weighing_rice_history.length - 1"
                    stroke="currentColor"
                    class="size-6 text-red-500"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="joinWeighingData.length > 0">
      <AliesPagination
        :totalItems="joinWeighingData.length"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :getRowOptions="getRowOptions"
        @updatePage="changePage"
        @updatePageSize="changePageSize"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[6rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :datatoedit="datatoedit"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isCutOut" as="template">
      <Dialog as="div" @close="handleCloseCutOut" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-white">
                <div>
                  <component
                    @close="handleCloseCutOut"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isEdit" as="template">
      <Dialog as="div" @close="handleCloseEdit" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-white">
                <div>
                  <component
                    @close="handleCloseCutOut"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deleteDoc"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
</template>

<script>
import WeighingServiceForm from "@/form/WeighingServiceForm.vue";
import OrderDetailsPopUpVue from "@/form/OrderDetailsPopUp.vue";
import SenderForm from "@/form/SenderForm.vue";
import EditWeighingForm from "@/form/EditWeighingForm.vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import apirURL from "@/services/apiURL";
import axios from "axios";
import NoDataComponet from "@/components/NoDataComponet.vue";
import socket from "@/services/socket";
import Toast from "@/components/Toast.vue";
import toggleSort from "@/composables/sortData";
import AliesPagination from "@/components/AliesPagination.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import moment from "moment-timezone";
import CutOutForm from "@/form/CutOutForm.vue";
import { mdiTrashCanOutline, mdiPencil } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    WeighingServiceForm,
    OrderDetailsPopUpVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SenderForm,
    NoDataComponet,
    Toast,
    AliesPagination,
    DeleteConfrimation,
    CutOutForm,
    EditWeighingForm,
  },
  setup() {
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "WeighingServiceForm";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const datatoedit = ref(null);
    const handlePopUp = (doc) => {
      datatoedit.value = doc;
      isPopup.value = true;
      currentComponent.value = "SenderForm";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("weighing");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const joinWeighingData = ref([]);
    const fetchDataPaginationWithJoin = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        console.log("decodeJWTInfo", decodeJWTInfo);

        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: "weighing",
          columnSearch: "supplier_name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,

          sortDirection: sortDirection.value,

          supplier_id: selectedSupplier.value,
          plate_number: selectedTruck.value,
          rice_id: selectedRice.value,
          boat_enter_parking_id: selectedParking.value,
          columnAliases: JSON.stringify({
            weighing_id: { table: table.value, column: "id" },
            created_by: { table: table.value, column: "created_by" },
            status_weighing: { table: table.value, column: "status" },

            weight_in: { table: table.value, column: "weight_in" },

            is_hold: { table: table.value, column: "is_hold" },

            supplier_name: { table: "suppliers", column: "name" },
            phone_number: { table: "suppliers", column: "phone_number" },
            boat_enter_parking_id: {
              table: table.value,
              column: "boat_enter_parking_id",
            },
            amount_cut_off: { table: table.value, column: "amount_cut_off" },
            created_at: { table: table.value, column: "created_at" },
            warehouse_name: {
              table: "warehouse",
              column: "name",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
            {
              joinTableName: "warehouse",
              joinColumnChild: "warehouse_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: table.value,
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: table.value,
            },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
            {
              field: "is_external_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "service_external_type",
              operator: "=",
              value: "weighing_rental",
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "created_by",
              operator: "=",
              value: decodeJWTInfo?.userId,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        joinWeighingData.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };
    const changePageSize = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchDataPaginationWithJoin();
    };
    const changePage = (page) => {
      currentPage.value = page;
      fetchDataPaginationWithJoin();
    };

    const getRowOptions = [25, 50, 75, 100];

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchDataPaginationWithJoin()]);
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchDataPaginationWithJoin(),
        fetchSuppliers(),
        fetchRice(),
        fetchPlates(),
        fetBoatIntoParking(),
      ]);

      socket.on("database_realTime", handleDatabaseUpdate);

      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });
    watch(searchValue, () => {
      fetchDataPaginationWithJoin();
    });
    const suppliers = ref([]);
    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const boatIntoParking = ref([]);
    const fetBoatIntoParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        boatIntoParking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const rice = ref([]);
    const fetchRice = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const plates = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        plates.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getRice = (id) => {
      const name = rice.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = plates.value.find((item) => item?.id === id);
      return name?.plate_number;
    };
    const getParking = (id) => {
      const name = boatIntoParking.value.find((item) => item?.id === id);
      return name?.name;
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    // delete
    const itemIdToDelete = ref(null);
    const deleteDoc = ref(null);
    const isDeleteOpen = ref(false);
    const openDeleteConfirmation = (doc) => {
      deleteDoc.value = doc;
      itemIdToDelete.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deleteDoc.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.weighing_id}`,
          {
            data: { tableName: table.value },
          }
        );

        if (isLastPage && joinPreloanData.value.length === 0) {
          currentPage.value = 1;
        }
        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    const selectedSupplier = ref(null);
    const selectedTruck = ref("");
    const selectedRice = ref("");
    const selectedParking = ref("");

    const isFilterable = ref(false);
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const selectedBoatId = ref(null);
    const selectedBoatStatus = ref(null);
    const IsWeighing = ref(null);

    // Combined function
    const isBoatSelected = ref(false);

    const handleBoatSelection = (data) => {
      // Call selectBoat logic
      selectedBoatId.value = data.weighing_id;
      selectedBoatStatus.value = data.is_complete;
      IsWeighing.value = data.weight_in;

      isBoatSelected.value = true;
      // Call  logic
      weighing_rice_history.value = [];

      fetchWeighingHistory(data.weighing_id);
    };
    const weighing_rice_history = ref([]);
    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing_rice_history.value = response.data;
        console.log(weighing_rice_history);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const isCutOut = ref(false);
    const isCutOutData = ref(null);
    const handleCutOut = (doc) => {
      isCutOut.value = true;
      isCutOutData.value = doc;
      currentComponent.value = "CutOutForm";
    };
    const handleCloseCutOut = () => {
      isCutOut.value = false;
      isCutOutData.value = null;
      currentComponent.value = "";
    };
    const isEdit = ref(false);
    const handleCloseEdit = () => {
      isEdit.value = false;
      datatoedit.value = null;
      currentComponent.value = "";
    };
    const handleEditData = (doc) => {
      isEdit.value = true;
      currentComponent.value = "EditWeighingForm";
      datatoedit.value = doc;
      console.log("doc", doc);
    };
    const sortByPlates = (sortDirection) => {
      joinWeighingData.value.sort((a, b) => {
        const nameA = a.plate_number.plate_number.toUpperCase() || "";
        const nameB = b.plate_number.plate_number.toUpperCase() || "";

        if (sortDirection === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;

      // Check if the field is related to plates (truck column)
      if (field === "plate_number") {
        sortByPlates(direction); // Call the sortByPlates function for plates column
      } else {
        joinWeighingData.value = toggleSort(
          direction,
          joinWeighingData.value,
          field
        );
      }
    };

    const sortRice = (sortDirection) => {
      weighing_rice_history.value.sort((a, b) => {
        const nameA = a.rice.map((rice) => rice.rice_name).join(", ");
        const nameB = b.rice.map((rice) => rice.rice_name).join(", ");

        if (sortDirection === "asc") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortHistory = ref(null);
    const sortHistoryDirection = ref("asc");
    const sortHistoryData = (field) => {
      const direction =
        sortHistory.value === field && sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";

      sortHistory.value = field;
      sortHistoryDirection.value = direction;
      if (field === "rice_name") {
        sortRice(direction);
      } else {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      }
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      tableClasses,
      selectClasses,
      navClasses,
      joinWeighingData,
      formatDate2,
      searchValue,
      getSupplierName,
      getRice,
      getPlatesName,
      datatoedit,
      showSuccessToast,
      toast,
      // pagination
      getRowOptions,
      changePageSize,
      changePage,
      pageSize,
      currentPage,
      totalPages,
      // delete
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      table,
      isDeleteOpen,
      deleteDoc,
      table,
      getParking,
      selectedSupplier,
      selectedParking,
      selectedSupplier,
      selectedTruck,

      suppliers,
      boatIntoParking,
      selectedRice,
      rice,
      plates,

      isFilterable,
      mainClassesTable,
      handleBoatSelection,
      weighing_rice_history,
      subClassesTable,
      handleCutOut,
      handleCloseCutOut,
      isCutOut,
      isCutOutData,
      handleEditData,
      handleCloseEdit,
      isEdit,
      sortData,
      sortHistory,
      sortHistoryDirection,
      sortHistoryData,
      mdiTrashCanOutline,
      mdiPencil,
    };
  },
};
</script>

<style scoped>
.custom-disabled {
  opacity: 1;
  cursor: pointer;
}

.custom-disabled.el-button--primary {
  background-color: #409eff !important;
  border-color: #409eff !important;
  color: white !important;
}

.custom-disabled.el-button--danger {
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
  color: white !important;
}
</style>
