<template>
  <form @submit.prevent="handleSubmit" class="h-fit bg-default text-primary6 relative">
    <div class="form_title">
      <h2 class="ml-4">{{ t("message.new_weighing") }}</h2>
      <div @click="handleClose" class="closePopUp">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-5">
      <div class="bg-white p-2 rounded-md border">
        <div class="flex justify-between">
          <div class="flex items-center justify-start gap-5">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span>
              {{
                getFrom === "supplier"
                  ? t("message.from_warehouse")
                  : t("message.from_warehouse")
              }}
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" name="status" value="supplier" v-model="getFrom"
                class="form-radio text-primary3 cursor-pointer" />
              <span>{{ t("message.supplier") }}</span>
            </label>
            <label class="flex items-center space-x-2 cursor-pointer">
              <input type="radio" name="status" value="warehouse" v-model="getFrom"
                class="form-radio text-primary3 cursor-pointer" />
              <span>{{ t("message.warehouse") }}</span>
            </label>
          </div>
          <div v-if="getFrom === 'supplier'">
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span>
                {{ t("message.truck") }}</label>
              <Select v-model="plate_numbers" filter :options="trucks" placeholder="Select Truck"
                option-value="plate_number" option-label="plate_number" size="large"
                class="w-full md:w-56 h-9 text-start flex items-center" show-clear />
              <button type="button" @click="handleAdd" class="px-2 py-2 bg-primary3/20">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-6 text-primary3">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              </button>
            </div>
            <div class="mt-4 w-[250px] float-end" v-if="isValidateTruck === true">
              <el-alert title="Please Select the Truck" type="error" :closable="false" />
            </div>
          </div>

          <div v-if="getFrom === 'warehouse'">
            <div class="flex items-center gap-3">
              <label for="trucktor" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span> TruckTor
              </label>
              <div class="relative w-full">
                <!-- Main Input -->
                <div @click="toggleDropdownTructor"
                  class="flex items-center justify-between w-full h-9 px-3 border rounded-md cursor-pointer hover:border-primary3">
                  <input type="text" v-model="selectedValueTructor" placeholder="Enter or select TruckTor"
                    class="w-full h-full outline-none border-none text-sm bg-transparent cursor-pointer" @click.stop />
                  <div class="flex items-center gap-2">
                    <!-- Clear Icon (shows only when there's a value) -->
                    <svg v-if="selectedValueTructor" @click.stop="clearSelection" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      class="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <!-- Dropdown Arrow -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 transform transition-transform duration-200"
                      :class="{ 'rotate-180': isOpenTructor }">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </div>

                <!-- Dropdown Menu -->
                <div v-if="isOpenTructor" class="absolute z-50 w-full mt-1 bg-white border rounded-md shadow-lg">
                  <!-- Search Input -->
                  <div class="p-2 border-b">
                    <div class="relative">
                      <input type="text" v-model="searchQueryTructor" placeholder="Search..."
                        class="w-full px-8 py-1.5 text-sm border rounded-md outline-none focus:border-primary3"
                        @click.stop />
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4 absolute left-2 top-1/2 -translate-y-1/2 text-gray-400">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                      </svg>
                    </div>
                  </div>

                  <!-- Options List -->
                  <div class="max-h-48 overflow-y-auto">
                    <div v-for="option in dataWeighingByTrucktorName" :key="option"
                      @click="selectOption(option.trucktor)"
                      class="flex items-center justify-between px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm">
                      <span>{{ option.trucktor }}</span>
                      <svg v-if="option.trucktor === selectedValueTructor" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        class="w-4 h-4 text-primary3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    </div>
                    <!-- No Results Message -->
                    <div v-if="dataWeighingByTrucktorName.length === 0"
                      class="px-3 py-2 text-sm text-gray-500 text-center">
                      No results found
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getFrom === 'supplier'" class="flex w-full justify-between py-4 gap-3">
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span>
                {{ t("message.supplier") }}</label>

              <Select v-model="selectSupplier" :options="suppliers" placeholder="Select Supplier" option-value="id"
                option-label="name" size="medium" :disabled="plate_numbers === null || weighing.length > 0"
                class="w-full md:w-56 h-9 text-start flex items-center" filter show-clear />
            </div>
            <div v-if="isValidateSupplier" class="mt-4 w-[250px] float-end">
              <el-alert title="Please Select Supplier" type="error" :closable="false" />
            </div>
          </div>
          <div class="flex items-center gap-3">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span>
              {{ t("message.time_of_weight") }}
            </label>
            <InputText disabled type="text" v-model="numberOfWeighings" placeholder="Times of Weight"
              class="w-full md:w-56 h-9 text-start flex items-center" />
          </div>
        </div>
        <div v-else-if="getFrom === 'warehouse'" class="flex w-full justify-between py-4 gap-3">
          <div>
            <div class="flex items-center gap-3">
              <label for="name" class="flex gap-1 text-nowrap font-bold">
                <span class="text-red-500">*</span>
                {{ t("message.warehouse") }}</label>

              <Select placeholder="Select Warehouse" v-model="selectWarehouse" :options="warehouse" option-value="id"
                option-label="name" filter show-clear class="w-full md:w-56 h-9 text-start flex items-center"
                :disabled="selectedValueTructor === null || weighing.length > 0" />
            </div>
            <div v-if="isValidateWarehouse" class="mt-4 w-[250px] float-end">
              <el-alert title="Please Select Warehouse" type="error" :closable="false" />
            </div>
          </div>

          <div class="flex items-center gap-3">
            <label for="name" class="flex gap-1 text-nowrap font-bold">
              <span class="text-red-500">*</span>
              {{ t("message.time_of_weight") }}
            </label>
            <InputText disabled type="text" v-model="numberOfWeighings" placeholder="Times of Weigh"
              class="w-full md:w-56 h-9 text-start flex items-center" />
          </div>
        </div>
      </div>
      <div class="bg-white rounded-md border">
        <div class="w-full flex">
          <div :class="{
            'tab2 cursor-pointer w-full  ': true,
            ' pop_up_tab ': activeTab === 'weight',
          }" @click="setActiveTab('weight')">
            {{ t("message.weight") }}
          </div>
          <div :class="{
            'tab2 cursor-pointer w-full  ': true,
            'pop_up_tab ': activeTab === 'history',
          }" @click="setActiveTab('history')">
            {{ t("message.history") }}
          </div>
        </div>
        <div v-if="activeTab === 'weight'" class="p-2">
          <div class="gap-8 w-[55%] mt-4 flex justify-between">
            <div class="space-y-3 flex flex-col">
              <div>
                <div class="flex items-center gap-3 justify-between">
                  <label for="name" class="flex gap-1 text-nowrap font-bold">
                    <span class="text-red-500">*</span>
                    {{ t("message.current_weight") }}
                  </label>
                  <InputNumber suffix=" Kg" style="width: 250px" placeholder="Current Weigh"
                    :invalid="iscurrentWeightt === true" :maxFractionDigits="2"
                    class="w-full md:w-56 h-2 text-start flex items-center" v-model="currentWeight" step="0.01" />
                </div>

                <div v-if="iscurrentWeightt" class="mt-4 w-[250px] float-end">
                  <el-alert title="Please Enter the amount" type="error" :closable="false" />
                </div>
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span>
                  {{ t("message.previous_weight") }}
                </label>
                <InputNumber suffix=" Kg" style="width: 250px" placeholder="Previous Weight" :maxFractionDigits="2"
                  v-model="previousWeight" disabled step="0.01" />
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span> {{ t("message.weight") }}
                </label>
                <InputNumber suffix=" Kg" style="width: 250px" placeholder=" Weigh" :maxFractionDigits="2"
                  v-model="currentWeight" disabled step="0.01" />
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span>
                  {{ t("message.previous_parking") }}</label>

                <Select v-model="selectedParking" :options="parking" placeholder="Parking" option-value="id"
                  option-label="name" size="large" style="width: 250px"
                  class="w-full md:w-56 h-9 text-start flex items-center" filterable="true" show-clear checkmark
                  disabled />
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span>
                  {{ t("message.rice_type") }}</label>

                <Select v-model="previousRight" :options="rices" placeholder="Rice type" option-value="name"
                  option-label="name" filter disabled show-clear checkmark style="width: 250px"
                  class="w-full md:w-56 h-9 text-start flex items-center" />
              </div>
            </div>
            <div class="space-y-3">
              <div class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">{{
                    getFrom === "warehouse" ? "*" : ""
                  }}</span>
                  {{ t("message.previous_rice") }}</label>

                <Select v-model="selectRice" :options="rices" placeholder="Rice type" option-value="name"
                  option-label="name" filter show-clear checkmark style="width: 250px" size="medium"
                  class="w-full md:w-56 h-9 text-start flex items-center" />
              </div>
              <div v-if="getFrom === 'warehouse' && isValidateRice" class="mt-4 w-[250px] float-end">
                <el-alert title="Please select rice type" type="error" :closable="false" />
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500"></span> Cut out
                </label>
                <InputNumber suffix=" Kg" :maxFractionDigits="2" style="width: 250px" placeholder=" Cut out"
                  v-model="isCutOut" step="0.01" />
              </div>
              <div v-if="weighing?.length > 0 && previousWeight !== null"
                class="flex items-center gap-3 justify-between">
                <label for="name" class="flex gap-1 text-nowrap font-bold">
                  <span class="text-red-500">*</span>
                  {{ t("message.weight_drop") }}
                </label>
                <InputNumber placeholder="Weight" suffix=" Kg" style="width: 250px" :maxFractionDigits="2" step="0.01"
                  v-model="remainingWeight" disabled />
              </div>
              <div class="w-[500px] bg-primary3/10 border border-primary3 rounded-lg p-4 mt-12"
                v-if="weighing?.length > 0 && previousWeight !== null">
                <div class="flex justify-between">
                  <h2 class="text-primary3 text-heading2 text-start">
                    {{ t("message.weight_drop") }}
                  </h2>
                  <div>
                    <h2 class="text-primary3 text-heading2 text-start">
                      {{ t("message.weight") }}:
                      {{
                        remainingWeight
                          ? numeralFormat(remainingWeight, "0,0[.]00")
                          : "---"
                      }}
                      Kg
                    </h2>
                  </div>
                </div>
                <div class="text-start ml-12 text-heading1 font-semibold text-primary3">
                  {{
                    remainingWeight
                      ? numeralFormat(remainingWeight, "0,0[.]00")
                      : "---"
                  }}
                  Kg
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="activeTab === 'history'" class="p-2">
          <div class="relative overflow-x-auto mt-4 border h-[280px]">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.no") }}
                  </td>
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.rice_type") }}
                  </td>
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.weight") }}
                  </td>
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.amount_cut_off") }}
                  </td>
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.weight_drop") }}
                  </td>
                  <td class="min-w-[100px] border-b text-start">
                    {{ t("message.status") }}
                  </td>
                </tr>
              </tbody>

              <tbody v-if="history.length > 0">
                <tr class="text-body font-normal text-primary2 bg-white" v-for="(data, index) in history"
                  :key="data.id">
                  <td class="border-b">{{ index + 1 }}</td>
                  <td class="border-b" v-for="rices in data.rice" :key="rices.id">
                    {{ rices.rice_name }}
                  </td>
                  <td class="border-b">
                    {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
                  </td>
                  <td class="border-b">
                    {{
                      numeralFormat(data.cuteOut, "0,0[.]00")
                        ? numeralFormat(data.cuteOut, "0,0[.]00")
                        : "0"
                    }}
                    Kg
                  </td>
                  <td class="border-b" colspan="2">
                    {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
                  </td>
                </tr>
              </tbody>
              <tbody v-if="history.length === 0 && weighing.length === 0">
                <tr>
                  <td colspan="6">
                    <NoDataComponet />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="getFrom === 'supplier'" class="flex w-full items-center gap-3">
        <button :disabled="isPending" type="submit">
          <span v-if="isPending === true" :class="{
            is_pending: isPending,
          }">
          </span>
          Save
        </button>
        <button :disabled="isPendingSave" type="button" @click="SaveAndNew" class="btncancel">
          <span v-if="isPendingSave === true" :class="{
            is_pending_save: isPendingSave,
          }">
          </span>
          Save & New
        </button>

        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
      <div v-else class="flex w-full items-center gap-3">
        <button :disabled="isPending" type="button" @click="handleSubmitWeightRiceFromWarehouse">
          <span v-if="isPending === true" :class="{
            is_pending: isPending,
          }">
          </span>
          Save
        </button>
        <button :disabled="isPendingSave" type="button" @click="SendAndNew" class="btncancel">
          <span v-if="isPendingSave === true" :class="{
            is_pending_save: isPendingSave,
          }">
          </span>
          Save & New
        </button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
    <div v-if="isOpen" @click="isClose"
      class="fixed bg-black/25 w-full h-full top-0 right-0 flex items-start justify-center z-40">
      <div @click.stop class="mt-32">
        <component @closeTruck="handleCloseTruck" :is="currentComponent" />
      </div>
    </div>
  </form>

  <component v-if="currentComponent === 'ToastVue'" :is="currentComponent" :showToast="showToast"
    :informMessage="informMessage" @onClose="handleClearToast" />
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useI18n } from "vue-i18n";
import NewTruckFormVue from "./NewTruckForm.vue";
import { validateInputNUmber } from "@/composables/ValidateInput";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { handleValidate } from "@/composables/checkValidatefeild";
import ToastVue from "@/components/ToastVue.vue";
import numeral from "numeral";
import { useWareHouseStockRiceTransaction } from "@/composables/useWareHouseStockRiceTransaction";
export default {
  props: ["datatoedit"],
  components: {
    ToastVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    NewTruckFormVue,
    NoDataComponet,
  },
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
      handleClear();
    };
    const service_type = ref("");
    const isFrom = ref("supplier");
    const getFrom = ref("supplier");
    const currentComponent = ref("");
    const selectWarehouse = ref(null);
    const warehouse = ref([]);
    const isOpen = ref(false);
    const cuteOut = ref(null);
    const isCutOut = ref(null);
    const selectSupplier = ref(null);
    const suppliers = ref([]);
    const rices = ref([]);
    const weighing = ref([]);
    const previousWeight = ref(null);
    const selectRice = ref(null);
    const validationError = ref("");
    const isPlate = ref("");
    const currentWeight = ref(null);
    const isPending = ref(false);
    const isPendingSave = ref(false);
    const weighing_time = ref(null);
    const numberOfWeighings = ref(null);
    const optionsTructor = ref([]); // Your trucktor options
    const trucktor = ref("");
    const selectedValueTructor = ref("");
    const handleClear = () => {
      isSupplier.value = "";
      trucktor.value = "";
      iscurrentWeightt.value = "";
      isPlate.value = "";
      validationError.value = "";
      isCutOut.value = null;
      currentWeight.value = null;
      selectRice.value = null;
      selectSupplier.value = null;
      selectWarehouse.value = null;
      plate_numbers.value = null;
      isValidateRice.value = false;
      previousWeight.value = null;
      cuteOut.value = null;
      selectWarehouse.value = null;
      isValidateWarehouse.value = false;
      isValidateSupplier.value = false;
      isCutOut.value = null;
      remainingWeight.value = null;
      currentWeight.value = null;
      previousRight.value = null;
      selectRice.value = null;
      isValidateTruck.value = false;
      isPending.value = false;
      isPendingSave.value = false;
      isValidateWarehouse.value = false;
      isValidateSupplier.value = false;
      iscurrentWeightt.value = false;
      showToast.value = false;
      informMessage.value = "";
      isOpen.value = false;
      currentComponent.value = "";
      selectSupplier.value = null;
      selectWarehouse.value = null;
      selectedParking.value = null;
    };

    watch(selectRice, (newVal) => {
      if (newVal) {
        isValidateRice.value = false;
      }
    });

    watch(selectWarehouse, (newVal) => {
      if (newVal) {
        isValidateWarehouse.value = false;
      } else {
        currentWeight.value = null;
      }
    });

    watch(selectSupplier, (newVal) => {
      if (newVal) {
        isValidateSupplier.value = false;
      } else {
        currentWeight.value = null;
      }
    });

    watch(currentWeight, (newVal) => {
      if (newVal) {
        iscurrentWeightt.value = false;
      }
    });

    const remainingWeight = computed(() => {
      if (previousWeight.value !== null && currentWeight.value !== null) {
        return previousWeight.value - currentWeight.value;
      }
      return null;
    });

    const iscurrentWeightt = ref(false);
    const plate_numbers = ref(null);
    const isValidateTruck = handleValidate(plate_numbers.value);
    const isSupplier = ref("");
    const isValidateSupplier = handleValidate(selectSupplier.value);

    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "NewTruckFormVue";
    };
    const isClose = () => {
      isOpen.value = false;
    };
    const handleCloseTruck = () => {
      currentComponent.value = "";
      isOpen.value = false;
      fetchPlates();
    };
    const activeTab = ref("weight");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const fetchSipplers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    function calculateTotalRiceStock(rice_stock, rice_name) {
      // Initialize total
      let total = 0;

      // Sum up all final_weight values for the specified rice_name
      rice_stock.forEach((record) => {
        try {
          const riceData = record.rice;
          if (riceData.rice_name === rice_name) {
            total += Number(riceData.final_weight) || 0;
          }
        } catch (error) {
          console.error("Error parsing rice data:", error);
        }
      });

      return total;
    }

    const totalRiceStock = ref(null);
    const fetchStockRiceFromWarehouse = async (warehouse_id) => {
      try {
        const req = {
          tableName: "stock_rice_transaction_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: warehouse_id,
              typeTable: "table",
            },
            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
            },
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        return response.data;
      } catch (error) {
        console.error("Error fetching rice stock:", error);
        return [];
      }
    };

    watch([selectWarehouse, selectRice], async () => {
      if (selectWarehouse.value && selectRice.value) {
        if (weighing.value.length > 0) {
          return;
        } else {
          console.log("selectWarehouse <0", selectWarehouse.value);
          const data = await fetchStockRiceFromWarehouse(selectWarehouse.value);

          if (data.length > 0) {
            totalRiceStock.value = calculateTotalRiceStock(
              data,
              selectRice.value
            );
            console.log("totalRiceStock", totalRiceStock.value);
          }
        }

        const data = await fetchStockRiceFromWarehouse(selectWarehouse.value);

        if (data.length > 0) {
          totalRiceStock.value = calculateTotalRiceStock(
            data,
            selectRice.value
          );
          console.log("totalRiceStock", totalRiceStock.value);
        }
      } else {
        totalRiceStock.value = null;
      }
    });

    const trucks = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parking = ref([]);
    const selectedParking = ref("");
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rice_stock = ref([]);
    const fetchRiceHistory = async (id) => {
      try {
        const params = {
          tableName: "stock_rice_transaction_history",
          dynamicConditions: JSON.stringify([
            {
              field: "warehouse_id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
            {
              field: "rice",
              operator: "->>",
              key: "transaction_id",
              value: null,
              type: "jsonb",
              isNull: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );

        if (response.data.length > 0) {
          rice_stock.value = response.data;
        } else {
          rice_stock.value = [];
        }
      } catch (error) {
        rice_stock.value = [];
        console.error("Error fetching data", error);
      }
    };
    watch(selectWarehouse, async (newVal) => {
      if (newVal) {
        await fetchRiceHistory(newVal);
      }
    });

    watch(getFrom, async (newVal) => {
      if (newVal) {
        selectWarehouse.value = null;
        selectSupplier.value = null;
        plate_numbers.value = null;
        selectedValueTructor.value = null;
        weighing.value = [];
        currentWeight.value = null;
        previousRight.value = null;
        selectRice.value = null;
        handleClear();
      }
    });

    const weighingId = ref(null);
    const previousRight = ref(null);
    const fetchWeighing = async (plate, type) => {
      try {
        let dynamicConditions = [];
        if (type === "supplier") {
          dynamicConditions.push({
            field: "plate_number",
            operator: "->>",
            key: "plate_number",
            value: plate,
            type: "jsonb",
            typeTable: "table",
          });
        } else if (type === "warehouse") {
          dynamicConditions.push({
            field: "trucktor",
            operator: "=",
            value: plate,
            typeTable: "table",
          });
        }
        dynamicConditions.push(
          {
            field: "service_type",
            operator: "=",
            value: "internal",
            typeTable: "table",
          },

          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "is_weight",
            operator: "=",
            value: true,
            typeTable: "table",
          }
        );
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          sortDirection: "ASC",
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        console.log("response", response.data);

        if (response.data.length > 0) {
          weighing.value = response.data;

          previousWeight.value = weighing.value[0]?.weight_in;
          selectedParking.value = weighing.value[0]?.boat_enter_parking_id;
          selectWarehouse.value = weighing.value[0]?.warehouse_id;

          selectRice.value = weighing.value[0]?.rice[0]?.rice_name;
          selectSupplier.value = weighing.value[0]?.supplier_id;
          selectWarehouse.value = weighing.value[0]?.warehouse_id;
          service_type.value = weighing.value[0]?.service_type;
          weighingId.value = weighing.value[0]?.id;

          await fetchWeighingHistory(weighingId.value);

          await fetchWeighingHistory(weighingId.value);

          previousRight.value = weighing.value[0]?.rice[0]?.rice_name;
        } else {
          history.value = [];

          weighingId.value = null;
          weighing.value = [];
          previousRight.value = null;

          previousWeight.value = null;
          selectedParking.value = null;
          selectWarehouse.value = null;
          selectRice.value = null;
          isCutOut.value = null;
        }
      } catch (error) {
        console.error("Error fetching weighing data:", error);
        weighing.value = []; // Clear data in case of an error
      }
    };

    const isErrorCurrentWeight = ref(false);
    watch([previousWeight, currentWeight], () => {
      if (previousWeight.value && currentWeight.value) {
        if (currentWeight.value >= previousWeight.value) {
          console.log("currentWeight.value >= previousWeight.value");
          isErrorCurrentWeight.value = true;
        } else {
          isErrorCurrentWeight.value = false;
        }
      }
    });

    const searchQueryTructor = ref("");
    const dataWeighingByTrucktorName = ref([]);
    const fetchWeighingByTrucktorName = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          searchQuery: searchQueryTructor.value,
          searchColumns: JSON.stringify(["trucktor"]),
          dynamicConditions: JSON.stringify([
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },

            {
              field: "trucktor",
              operator: "IS NOT NULL",
              typeTable: "table",
            },

            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          dataWeighingByTrucktorName.value = response.data;
        } else {
          dataWeighingByTrucktorName.value = [];
        }
      } catch (error) {
        console.error("Error fetching  data:", error);
        dataWeighingByTrucktorName.value = [];
      }
    };

    watch(searchQueryTructor, async () => {
      fetchWeighingByTrucktorName();
    });

    const clearSelection = () => {
      selectedValueTructor.value = null;
      weighing.value = [];
      history.value = [];
    };

    const fetchLastWeighingRiceHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
              typeTable: "table",
            },
            {
              field: "is_hold",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };

    const fetchNumberOfWeighings = async (plate_number) => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "plate_number",
              operator: "->>",
              key: "plate_number",
              value: plate_number,
              type: "jsonb",
              typeTable: "table",
            },
            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };

    const fetchNumberOfWeighingsByTrucktor = async (trucktor) => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "trucktor",
              operator: "=",
              value: trucktor,
              typeTable: "table",
            },

            {
              field: "is_weight",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },

            {
              field: "status",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          previousWeight.value = response.data[0]?.weight_in;
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error("failed to fetch data", error);
        return [];
      }
    };

    watch(selectedValueTructor, async (newVal) => {
      if (newVal) {
        const data = await fetchNumberOfWeighingsByTrucktor(newVal);

        await fetchWeighing(newVal, "warehouse");

        let numberOfWeight = 0;
        if (data.length > 0) {
          numberOfWeight = data[0]?.number_of_weighings + 1;
        } else {
          numberOfWeight = 1;
        }
        numberOfWeighings.value = numberOfWeight;
      } else {
        selectWarehouse.value = null;
        history.value = [];
        weighing.value = [];
        numberOfWeighings.value = null;
      }
    });

    watch(plate_numbers, async (newVal) => {
      if (newVal) {
        const data = await fetchNumberOfWeighings(newVal);
        let numberOfWeight = 0;
        if (data.length > 0) {
          numberOfWeight = data[0]?.number_of_weighings + 1;
        } else {
          numberOfWeight = 1;
        }
        numberOfWeighings.value = numberOfWeight;
      } else {
        numberOfWeighings.value = null;
      }
    });

    const history = ref([]);
    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        if (response.data.length > 0) {
          history.value = response.data;
        } else {
          history.value = [];
        }
      } catch (error) {
        console.error("Error fetching weighing history data:", error);
        history.value = [];
      }
    };
    const last_weighing_history = ref([]);
    const fetchLastWeighingHistoryRecord = async (id) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
            {
              field: "is_hold",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching last weighing history record:", error);
      }
    };

    const authUserId = ref(null);
    onMounted(async () => {
      document.addEventListener("click", (e) => {
        if (!e.target.closest(".relative")) {
          isOpenTructor.value = false;
        }
      });
      await Promise.allSettled([
        fetchRiceType(),
        fetchWeighingByTrucktorName(),
        fetchSipplers(),
        fetchWarehouse(),
        fetchPlates(),
        fetchParking(),
      ]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
    });

    const isFilterable = ref(false);

    const handleSubmit = async () => {
      try {
        let is_last_weighing = [];
        if (weighing.value.length > 0) {
          is_last_weighing = await fetchLastWeighingHistoryRecord(
            weighing.value[0]?.id
          );
        }
        if (!plate_numbers.value || plate_numbers.value === null) {
          isValidateTruck.value = true;
          return;
        }
        if (!selectSupplier.value) {
          isValidateSupplier.value = true;
          return;
        }
        if (currentWeight.value === null) {
          iscurrentWeightt.value = true;
          return;
        }

        if (isErrorCurrentWeight.value) {
          currentComponent.value = "ToastVue";
          showToast.value = true;
          informMessage.value =
            "Current weight must be smaller than previous weight";
          return;
        }

        if (is_last_weighing.length > 0) {
          emit("toast", "send_to_parking");
          return;
        }
        setTimeout(() => {
          isPending.value = true;
          setTimeout(() => {
            isPending.value = false;
          }, 1000);
        }, 1000);
        const timestamp = await fetchTimestamp();

        const req = {
          tableName: "weighing",
          fields: {
            supplier_id: selectSupplier.value,
            rice: JSON.stringify([{ rice_name: selectRice.value }]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            date_in: timestamp,
            service_type: "internal",
            plate_number: JSON.stringify({ plate_number: plate_numbers.value }),
            number_of_weighings: numberOfWeighings.value,
            boat_enter_parking_id: selectedParking.value || null,
            amount_cut_off: Number(isCutOut.value),
          },
        };

        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          const res = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (res.data.message) {
            //update last weighing rice history record first
            const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
              weighing.value[0]?.id
            );

            const requestBody = {
              tableName: "weighing_rice_history",
              fields: {
                weight_out: Number(currentWeight.value),
                weight_drop: Number(remainingWeight.value),
              },
            };

            const resUpdate = await axios.patch(
              `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
              requestBody
            );


            if (selectWarehouse.value) {
              // insert to warehouse stock rice transaction history
              const requestBodyWarehouse = {
                tableName: "stock_rice_transaction_history",
                fields: {
                  warehouse_id: selectWarehouse.value,
                  created_at: timestamp,
                  weighing_id: weighing.value[0]?.id,
                  transaction_type: "purchase",
                  created_by: authUserId.value,
                  weight: remainingWeight.value,
                  rice: JSON.stringify({
                    weight: remainingWeight.value,
                    original_weight: null,
                    weight_adjust: null,
                    after_adjust_weight: null,
                    rice_name: previousRight.value,
                    weight_sell: null,
                    type: "purchase",
                    from_weight: null,
                    to_weight: null,
                    final_weight: remainingWeight.value,
                    transaction_id: null,
                  }),
                },
              };

              await axios.post(
                `${apirURL}/weighing/api/insertData`,
                requestBodyWarehouse
              );


            }


            if (resUpdate.data.message) {
              if (currentWeight.value > 0) {
                await insertWeighingHistory(timestamp, weighing.value[0]?.id);
              }
            }
          }
        } else {
          req.fields.created_by = authUserId.value;
          req.fields.created_at = timestamp;
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );
          if (response.data) {
            await insertWeighingHistory(timestamp, response.data.insertedId);
          }
        }

        // Emit success notifications and reset the form
        emit("toast", "create");
        emit("close");
        handleClear();
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };

    const SaveAndNew = async () => {
      try {
        let is_last_weighing = [];
        if (weighing.value.length > 0) {
          is_last_weighing = await fetchLastWeighingHistoryRecord(
            weighing.value[0]?.id
          );
        }
        if (!plate_numbers.value || plate_numbers.value === null) {
          isValidateTruck.value = true;
          return;
        }
        if (!selectSupplier.value) {
          isValidateSupplier.value = true;
          return;
        }
        if (!currentWeight.value) {
          iscurrentWeightt.value = true;
          return;
        }

        if (isErrorCurrentWeight.value) {
          currentComponent.value = "ToastVue";
          showToast.value = true;
          informMessage.value =
            "Current weight must be smaller than previous weight";
          return;
        }

        if (is_last_weighing.length > 0) {
          emit("toast", "send_to_parking");
          return;
        }
        setTimeout(() => {
          isPending.value = true;
          setTimeout(() => {
            isPending.value = false;
          }, 1000);
        }, 1000);
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "weighing",
          fields: {
            supplier_id: selectSupplier.value,
            rice: JSON.stringify([{ rice_name: selectRice.value }]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            date_in: timestamp,
            service_type: "internal",
            plate_number: JSON.stringify({ plate_number: plate_numbers.value }),
            number_of_weighings: numberOfWeighings.value,
            boat_enter_parking_id: selectedParking.value || null,
            amount_cut_off: Number(isCutOut.value),
          },
        };

        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          const res = await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          if (res.data.message) {
            //update last weighing rice history record first
            const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
              weighing.value[0]?.id
            );

            const requestBody = {
              tableName: "weighing_rice_history",
              fields: {
                weight_out: Number(currentWeight.value),
                weight_drop: Number(remainingWeight.value),
              },
            };

            const resUpdate = await axios.patch(
              `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
              requestBody
            );


            if (selectWarehouse.value) {
              // insert to warehouse stock rice transaction history
              const requestBodyWarehouse = {
                tableName: "stock_rice_transaction_history",
                fields: {
                  warehouse_id: selectWarehouse.value,
                  created_at: timestamp,
                  weighing_id: weighing.value[0]?.id,
                  transaction_type: "purchase",
                  created_by: authUserId.value,
                  weight: remainingWeight.value,
                  rice: JSON.stringify({
                    weight: remainingWeight.value,
                    original_weight: null,
                    weight_adjust: null,
                    after_adjust_weight: null,
                    rice_name: previousRight.value,
                    weight_sell: null,
                    type: "purchase",
                    from_weight: null,
                    to_weight: null,
                    final_weight: remainingWeight.value,
                    transaction_id: null,
                  }),
                },
              };

              await axios.post(
                `${apirURL}/weighing/api/insertData`,
                requestBodyWarehouse
              );

            }

            if (resUpdate.data.message) {
              if (currentWeight.value > 0) {
                await insertWeighingHistory(timestamp, weighing.value[0]?.id);
              }
            }
          }
        } else {
          req.fields.created_by = authUserId.value;
          req.fields.created_at = timestamp;
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );
          if (response.data) {
            await insertWeighingHistory(timestamp, response.data.insertedId);
          }
        }

        // Emit success notifications and reset the form
        emit("toast", "create");

        handleClear();
      } catch (error) {
        console.error("Error during submission:", error);
      }
    };

    const informMessage = ref("");
    const showToast = ref(false);

    const handleClearToast = () => {
      currentComponent.value = "";
      informMessage.value = "";
      showToast.value = false;
    };

    const isValidateRice = ref(false);
    const handleSubmitWeightRiceFromWarehouse = async () => {
      try {
        let is_last_weighing = [];
        if (weighing.value.length > 0) {
          is_last_weighing = await fetchLastWeighingHistoryRecord(
            weighing.value[0]?.id
          );
        }

        if (isErrorCurrentWeight.value) {
          currentComponent.value = "ToastVue";
          showToast.value = true;
          informMessage.value =
            "Current weight must be smaller than previous weight";
          return;
        }

        if (is_last_weighing.length > 0) {
          emit("toast", "send_to_parking");
          return;
        }

        if (!selectWarehouse.value) {
          isValidateWarehouse.value = true;
          return;
        }

        if (currentWeight.value === null) {
          iscurrentWeightt.value = true;
          return;
        }

        if (!selectRice.value) {
          isValidateRice.value = true;
          return;
        }

        if (totalRiceStock.value < currentWeight.value) {
          currentComponent.value = "ToastVue";
          informMessage.value = `Not enough stock to sell ${selectRice.value
            } . Total stock ${numeral(totalRiceStock.value).format("0,0")} ${selectRice.value
            } available`;
          showToast.value = true;
          return;
        }

        setTimeout(() => {
          isPendingSave.value = true;

          setTimeout(() => {
            isPendingSave.value = false;
          }, 1000);
        }, 1000);
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "weighing",
          fields: {
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value || null,
                trucktor: selectedValueTructor.value || null,
              },
            ]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            created_at: timestamp,
            date_in: timestamp,
            service_type: "internal",
            number_of_weighings: numberOfWeighings.value,
            trucktor: selectedValueTructor.value || null,
            warehouse_id: selectWarehouse.value || null,
            created_by: authUserId.value,
            amount_cut_off: Number(isCutOut.value),
          },
        };
        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          //update last weighing rice history record first
          const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
            weighing.value[0]?.id
          );

          const requestBody = {
            tableName: "weighing_rice_history",
            fields: {
              weight_out: Number(currentWeight.value),
              weight_drop: Number(remainingWeight.value),
            },
          };

          const resUpdate = await axios.patch(
            `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
            requestBody
          );

          if (resUpdate.data.message) {
            if (currentWeight.value > 0) {
              await insertWeighingHistory(timestamp, weighing.value[0]?.id);
            }
          }
          emit("toast", "update");
          emit("close");
          handleClear();
        } else {
          if (totalRiceStock.value < currentWeight.value) {
            currentComponent.value = "ToastVue";
            informMessage.value = `Not enough stock to sell ${selectRice.value
              } . Total stock ${numeral(totalRiceStock.value).format("0,0")} ${selectRice.value
              } available`;
            showToast.value = true;
            return;
          }
          const res = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );

          if (res.data.message) {
            await insertWeighingHistoryWarehouse(
              timestamp,
              res.data.insertedId
            );
          }

          emit("toast", "create");
          emit("close");
          handleClear();
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    const SendAndNew = async () => {
      try {
        let is_last_weighing = [];
        if (weighing.value.length > 0) {
          is_last_weighing = await fetchLastWeighingHistoryRecord(
            weighing.value[0]?.id
          );
        }

        if (isErrorCurrentWeight.value) {
          currentComponent.value = "ToastVue";
          showToast.value = true;
          informMessage.value =
            "Current weight must be smaller than previous weight";
          return;
        }

        if (is_last_weighing.length > 0) {
          emit("toast", "send_to_parking");
          return;
        }

        if (!selectWarehouse.value) {
          isValidateWarehouse.value = true;
          return;
        }

        if (!currentWeight.value) {
          iscurrentWeightt.value = true;
          return;
        }

        if (!selectRice.value) {
          isValidateRice.value = true;
          return;
        }

        if (totalRiceStock.value < currentWeight.value) {
          currentComponent.value = "ToastVue";
          informMessage.value = `Not enough stock to sell ${selectRice.value
            } . Total stock ${numeral(totalRiceStock.value).format("0,0")} ${selectRice.value
            } available`;
          showToast.value = true;
          return;
        }

        setTimeout(() => {
          isPendingSave.value = true;

          setTimeout(() => {
            isPendingSave.value = false;
          }, 1000);
        }, 1000);
        const timestamp = await fetchTimestamp();
        const req = {
          tableName: "weighing",
          fields: {
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value || null,
                trucktor: selectedValueTructor.value || null,
              },
            ]),
            weight_in: currentWeight.value,
            is_hold: true,
            status: false,
            created_at: timestamp,
            date_in: timestamp,
            service_type: "internal",
            number_of_weighings: numberOfWeighings.value,
            trucktor: selectedValueTructor.value || null,
            warehouse_id: selectWarehouse.value || null,
            created_by: authUserId.value,
            amount_cut_off: Number(isCutOut.value),
          },
        };
        if (weighing.value.length > 0) {
          if (currentWeight.value <= 0) {
            req.fields.is_weight = false;
          } else {
            req.fields.is_weight = true;
          }
          req.fields.updated_by = authUserId.value;
          req.fields.updated_at = timestamp;
          await axios.patch(
            `${apirURL}/weighing/api/updateData/${weighing.value[0]?.id}`,
            req
          );

          //update last weighing rice history record first
          const lastWeighingRiceHistory = await fetchLastWeighingRiceHistory(
            weighing.value[0]?.id
          );

          const requestBody = {
            tableName: "weighing_rice_history",
            fields: {
              weight_out: Number(currentWeight.value),
              weight_drop: Number(remainingWeight.value),
            },
          };

          const resUpdate = await axios.patch(
            `${apirURL}/weighing/api/updateData/${lastWeighingRiceHistory[0]?.id}`,
            requestBody
          );

          if (resUpdate.data.message) {
            if (currentWeight.value > 0) {
              await insertWeighingHistory(timestamp, weighing.value[0]?.id);
            }
          }

          emit("toast", "update");

          handleClear();
        } else {
          if (totalRiceStock.value < currentWeight.value) {
            currentComponent.value = "ToastVue";
            informMessage.value = `Not enough stock to sell ${selectRice.value
              } . Total stock ${numeral(totalRiceStock.value).format("0,0")} ${selectRice.value
              } available`;
            showToast.value = true;
            return;
          }
          const res = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            req
          );

          if (res.data.message) {
            await insertWeighingHistoryWarehouse(
              timestamp,
              res.data.insertedId
            );
          }

          emit("toast", "create");

          handleClear();
        }
      } catch (error) {
        console.log("error ", error);
      }
    };

    const insertWeighingHistory = async (timestamp, weighingId) => {
      try {
        // Insert history request
        const historyReq = {
          tableName: "weighing_rice_history",

          fields: {
            created_at: timestamp,
            date_in: timestamp,
            weighing_id: weighingId,
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value,
              },
            ]),
            boat_enter_parking_id: selectedParking.value
              ? selectedParking.value
              : null,
            weight_in: currentWeight.value,
            weight_out: 0,
            weight_drop: 0,
            status: false,
            service_type: "internal",
            is_hold: true,
            amount_cut_off: Number(isCutOut.value),
            created_by: authUserId.value,
          },
        };

        await axios.post(`${apirURL}/weighing/api/insertData`, historyReq);
      } catch (error) {
        console.error(
          "Error inserting history or updating weighing table:",
          error
        );
      }
    };
    const isValidateWarehouse = handleValidate(selectWarehouse.value);
    const insertWeighingHistoryWarehouse = async (timestamp, weighingId) => {
      try {
        // Insert history request
        const historyReq = {
          tableName: "weighing_rice_history",
          fields: {
            created_at: timestamp,
            date_in: timestamp,
            weighing_id: weighingId,
            rice: JSON.stringify([
              {
                rice_name: selectRice.value,
                plate_number: plate_numbers.value ? plate_numbers.value : null,
                tructor_name: selectedValueTructor.value
                  ? selectedValueTructor.value
                  : null,
              },
            ]),
            warehouse_id: selectWarehouse.value,
            weight_in: currentWeight.value,
            weight_out: 0,
            weight_drop: 0,
            status: false,
            service_type: "internal",
            is_hold: true,
            amount_cut_off: Number(isCutOut.value),
            created_by: authUserId.value,
          },
        };
        const res = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          historyReq
        );
        if (res.data.message) {
          // insert to stock rice transaction history transaction_type sell
          historyReq.fields.totalRiceStock = totalRiceStock.value;
          historyReq.fields.currentWeight = currentWeight.value;

          useWareHouseStockRiceTransaction(historyReq.fields, "sell");
        }
      } catch (error) {
        console.error(
          "Error inserting history or updating weighing table:",
          error
        );
      }
    };

    watch(plate_numbers, async (newVal) => {
      if (newVal) {
        await fetchWeighing(newVal, "supplier");
        // const data = await fetchWeighingHistory(weighingId.value);

        if (weighing.value.length === 0) {
          selectSupplier.value = null;
        }
      } else {
        selectSupplier.value = null;
        weighing.value = [];
        history.value = [];
      }
    });

    const clearWeighingData = () => {
      weighing.value = [];
      plate_numbers.value = "";
    };

    const isOpenTructor = ref(false);

    const toggleDropdownTructor = () => {
      isOpenTructor.value = !isOpenTructor.value;
    };

    const selectOption = (value) => {
      selectedValueTructor.value = value;
      isOpenTructor.value = false;
    };

    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      informMessage,
      showToast,
      handleClearToast,
      handleSubmitWeightRiceFromWarehouse,
      searchQueryTructor,
      clearSelection,
      selectedValueTructor,
      selectOption,
      dataWeighingByTrucktorName,
      trucktor,
      isOpenTructor,
      numberOfWeighings,
      handleClose,
      handleAdd,
      handleCloseTruck,
      currentComponent,
      isOpen,
      isClose,
      validateInputNUmber,
      activeTab,
      setActiveTab,
      getFrom,
      isFrom,
      rices,
      isFilterable,
      suppliers,
      warehouse,
      selectRice,
      selectSupplier,
      selectWarehouse,
      handleSubmit,
      plate_numbers,
      trucks,
      currentWeight,
      weighing,
      clearWeighingData,
      previousWeight,
      validationError,
      remainingWeight,
      parking,
      selectedParking,
      cuteOut,
      SaveAndNew,
      iscurrentWeightt,
      isSupplier,
      history,
      isCutOut,
      isValidateRice,
      SendAndNew,
      weighing_time,
      isValidateTruck,
      isValidateSupplier,
      isValidateWarehouse,
      last_weighing_history,
      previousRight,
      isPending,
      isPendingSave,
      isOpen,
      selectedValueTructor,
      optionsTructor,
      toggleDropdownTructor,
      // translate functions
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>

<style scoped>
.relative input::placeholder {
  color: #9ca3af;
}

/* Scrollbar styles */
.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Animation for dropdown */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.absolute {
  animation: slideDown 0.2s ease-out;
}
</style>
