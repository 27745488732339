<template>
  <form
    @submit.prevent="handleSubmit"
    class="h-auto w-fit bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ datatoedit ? "Edit" : "New" }} Rental</h2>

      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="w-full p-5 flex items-start justify-between gap-3">
        <div class="p-1 w-full space-y-3">
          <div>
            <div class="flex items-center justify-between gap-3">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span> Select Customer</label
              >
              <div class="flex flex-col gap-1">
                <Select
                  v-model="selectCustomer"
                  :options="customer"
                  option-value="id"
                  option-label="name"
                  placeholder="Select Customer"
                  filter
                  show-clear
                  checkmark
                  class="w-full md:w-56 h-9 text-start flex items-center"
                />
                <div v-if="isValidateCustomer" class="">
                  <el-alert
                    title="Please Select Customer"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex items-center justify-between gap-3">
            <label for="name" class="flex gap-1">
              <span class="text-red-500"></span> Phone Number</label
            >
            <InputText
              v-model="phone_number"
              type="text"
              disabled
              class="w-full md:w-56 h-9 text-start flex items-center"
              placeholder="Phone Number"
            />
          </div>
        </div>
      </div>
      <div class="w-fit p-5 flex items-start justify-between gap-3">
        <div class="rounded border w-full overflow-auto text-start">
          <div
            class="w-full py-2 flex border-b p-2 justify-between header_table"
          >
            <h2 class="w-1/2 text-start">Parking</h2>
            <div class="w-1/2 text-start">
              <h2>Boat</h2>
            </div>
          </div>
          <div v-for="(row, i) in rows" :key="i" class="flex flex-col">
            <div class="w-full items-start flex justify-start bg-default">
              <div class="p-0.5 flex items-start gap-2 justify-between">
                <div class="flex items-start justify-start gap-3">
                  <div>
                    <Select
                      v-model="row.selectParking"
                      placeholder="Select parking"
                      :options="parkings"
                      option-value="id"
                      option-label="name"
                      filter
                      show-clear
                      checkmark
                      class="w-full md:w-56 h-9 text-start flex items-center"
                    />
                    <div class="py-3 bg-default">
                      <div
                        v-if="isValidateParking"
                        class="text-red-500 text-end text-nowrap text-sm"
                      >
                        <el-alert
                          title="Please Select Parking"
                          type="error"
                          :closable="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white">
                  <InputText
                    v-model="row.name"
                    type="text"
                    class="w-full md:w-56 text-start flex items-center"
                    placeholder="Boat Name"
                  />
                  <div class="py-3 bg-default">
                    <div
                      v-if="isValidateBoatName"
                      class="text-red-500 text-end bg-default text-sm"
                    >
                      <el-alert
                        title="Please Enter Boat Name"
                        type="error"
                        :closable="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                @click="deleteRow(i)"
                v-if="rows.length > 1"
                class="size-10 border-l flex items-center justify-center bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather cursor-pointer feather-trash-2 delete"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            </div>
          </div>
          <div
            @click="addRow"
            class="w-full py-2 flex border-t p-2 justify-between cursor-pointer"
          >
            <h2 class="w-1/2 text-start">New</h2>
            <div class="w-1/2 text-start">
              <h2>New</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center gap-3 p-5">
      <button type="submit">{{ datatoedit ? "Update" : "Save" }}</button>
      <button @click="handleSaveAndPrint" class="btncancel">
        {{ datatoedit ? "Update & New" : "Save & New" }}
      </button>

      <button class="btncancel" @click="handleClose">Cencel</button>
    </div>
  </form>
</template>

<script>
import apirURL from "@/services/apiURL";
import axios from "axios";
import { fetchTimestamp } from "@/services/timestamp";
import { ref, computed, onMounted, watch } from "vue";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClear = () => {
      rows.value = [{ selectParking: null, name: "" }];
      selectCustomer.value = null;
    };
    const rows = ref([{ selectParking: null, name: "" }]);
    const addRow = () => {
      rows.value.push({
        selectParking: null,
        name: "",
      });
    };

    const deleteRow = (index) => {
      rows.value.splice(index, 1);
    };

    const customer = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customer.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parkings = ref([]);
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "status",
            //   operator: "=",
            //   value: true,
            //   typeTable: "table",
            // },

            {
              field: "is_available",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parkings.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const isValidateBoatName = ref("");
    const validateBoatName = () => {
      const hasEmptyName = rows.value.some((p) => p.name === "");
      if (hasEmptyName) {
        isValidateBoatName.value = "Please enter the boat name";
        return false;
      } else {
        isValidateBoatName.value = "";
        return true;
      }
    };

    watch(rows, () => {
      validateBoatName();
    });

    const selectCustomer = ref(null);
    const phone_number = ref(null);
    const isValidateCustomer = ref("");
    const validateCustomer = () => {
      if (!selectCustomer.value) {
        isValidateCustomer.value = "Please select a customer";
        return false;
      } else {
        isValidateCustomer.value = "";
        return true;
      }
    };
    const isValidateParking = ref("");

    const validateParking = () => {
      if (!rows.value.some((p) => p.selectParking)) {
        isValidateParking.value = "Please select at least one parking";
        return false;
      } else {
        isValidateParking.value = "";
        return true;
      }
    };

    watch(rows, () => {
      validateParking();
    });

    watch(rows, () => {
      validateParking();
    });
    const handleSubmit = async () => {
      if (!validateCustomer() || !validateParking() || !validateBoatName()) {
        return;
      }
      try {
        const timestamp = await fetchTimestamp();

        const selectedParkings = rows.value
          .filter((p) => p.selectParking)
          .map((p) => ({
            parking_id: p.selectParking,
            boat_name: p.name,
            status: "pending",
            created_by: authUserId.value,
            created_at: timestamp,
            weight: 0,
            data_in: timestamp,
            cost: 0,
            total_cost: 0,
            supplier_id: selectCustomer.value,
          }));

        // Update each parking individually
        const updateRequests = selectedParkings.map(async (parking) => {
          const requestBody = {
            tableName: "parking_rice",
            fields: {
              is_rental: false,
              is_available: false,
            },
          };
          return axios.patch(
            `${apirURL}/weighing/api/updateData/${parking.parking_id}`,
            requestBody
          );
        });
        await Promise.all(updateRequests);

        if (selectedParkings.length > 0) {
          const req = {
            tableName: "weighing",
            fields: {
              supplier_id: selectCustomer.value,
              created_at: timestamp,
              created_by: authUserId.value,
              is_hold: false,
              service_type: "external",
              service_external_type: "rental_parking",
              rental_parking: JSON.stringify(selectedParkings),
            },
          };
          if (props.datatoedit) {
            const res = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              req
            );
            console.log(res.data);
            if (res.data) {
              emit("close");
              emit("toast", "update");
              handleClear();
            }
          } else {
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              req
            );
            if (response.data) {
              emit("close");
              emit("toast", "create");
              handleClear();
            }
          }
        }

        console.log("All parking rental updates successful");
      } catch (error) {
        console.error("Error updating parking rentals:", error);
      }
    };

    const handleClose = () => {
      emit("close");
    };
    const authUserId = ref(null);
    onMounted(async () => {
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      await Promise.allSettled([fetchCustomer(), fetchParking()]);
      if (props.datatoedit) {
        rows.value = props.datatoedit.rental_parking.map((item) => ({
          selectParking: item.parking_id,
          name: item.boat_name,
        }));
        selectCustomer.value = props.datatoedit.supplier_id;
      }
    });

    const handleSaveAndPrint = async () => {
      if (!validateCustomer() || !validateParking() || !validateBoatName()) {
        return;
      }
      try {
        const timestamp = await fetchTimestamp();

        // Collect all selected parkings
        const selectedParkings = rows.value
          .filter((p) => p.selectParking)
          .map((p) => ({
            parking_id: p.selectParking,
            boat_name: p.name,
            status: "pending",
            created_by: authUserId.value,
            created_at: timestamp,
            weight: 0,
            data_in: timestamp,
            cost: 0,
            total_cost: 0,
          }));

        const updateRequests = selectedParkings.map(async (parking) => {
          const requestBody = {
            tableName: "parking_rice",
            fields: {
              is_rental: true,
            },
          };
          return axios.patch(
            `${apirURL}/weighing/api/updateData/${parking.parking_id}`,
            requestBody
          );
        });

        await Promise.all(updateRequests);

        if (selectedParkings.length > 0) {
          const req = {
            tableName: "weighing",
            fields: {
              supplier_id: selectCustomer.value,
              created_at: timestamp,
              created_by: authUserId.value,
              is_hold: false,
              service_type: "external",
              service_external_type: "rental_parking",
              rental_parking: JSON.stringify(selectedParkings),
            },
          };
          if (props.datatoedit) {
            const res = await axios.patch(
              `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
              req
            );
            console.log(res.data);
            if (res.data) {
              emit("toast", "update");
              handleClear();
            }
          } else {
            const response = await axios.post(
              `${apirURL}/weighing/api/insertData`,
              req
            );
            if (response.data) {
              emit("toast", "create");
              handleClear();
            }
          }
        }
        console.log("All parking rental updates successful");
      } catch (error) {
        console.error("Error updating parking rentals:", error);
      }
    };

    const isFilterable = ref(false);

    return {
      handleClose,
      customer,
      parkings,
      isFilterable,
      selectCustomer,
      phone_number,
      addRow,
      rows,
      deleteRow,
      handleSubmit,
      isValidateCustomer,
      isValidateParking,
      handleSaveAndPrint,
      isValidateBoatName,
    };
  },
};
</script>
