<template>
  <form @submit.prevent="handleSubmit" class="w-[415px] bg-default">
    <div class="form_title">
      <h2 class="ml-4">{{ isUpdate ? "Update Truck" : "New Truck" }}</h2>
      <div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full p-5">
      <div class="space-y-3 mx-auto text-nowrap">
        <div
          :class="{
            'flex items-start gap-3': isValidatePlate,
            'flex items-center gap-3': !isValidatePlate,
          }"
        >
          <label for="name" class="flex gap-1">
            <span class="text-red-500">*</span> Plate number</label
          >
          <div class="flex flex-col gap-2">
            <input
              required
              type="text"
              v-model="plate"
              placeholder="Plate number"
              class="w-[250px]"
            />
            <div v-if="isValidatePlate" class="w-[250px]">
              <el-alert
                title="Plate number must be unique"
                type="error"
                :closable="false"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center gap-3">
          <label for="status" class="flex gap-1 text-nowrap">Status</label>
          <el-switch style="--el-switch-on-color: #4caf50" v-model="status" />
        </div>
      </div>
    </div>
    <div
      class="flex w-full items-center gap-3 p-5"
      v-if="route.path === '/weighinglist'"
    >
      <button type="button" @click="handleSubmit">Save</button>
      <button class="btncancel" type="button" @click="handleClose">
        Cencel
      </button>
    </div>
    <div v-else class="flex w-full items-center gap-3 p-5">
      <button>{{ isUpdate ? "Update" : "Save" }}</button>
      <button @click="handleSaveAndNew" type="button" class="btncancel">
        {{ isUpdate ? "Update & New" : "Save & New" }}
      </button>
      <button class="btncancel" type="button" @click="handleClose">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchTimestamp } from "@/services/timestamp";
import { handleValidate } from "@/composables/checkValidatefeild";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { useRoute } from "vue-router";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const route = useRoute();
    const handleClose = () => {
      emit("closeTruck") ? route.path === "/weighinglist" : emit("close");
    };
    const handleClear = () => {
      plate.value = null;
    };
    const isValidatePlate = handleValidate();
    const plate = ref("");
    const status = ref(true);
    const handleSubmit = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "plates",
          fields: {
            plate_number: plate.value,
            status: status.value,
            created_at: timestamp,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
        }
        emit("close");
      } catch (error) {
        if (error) {
          isValidatePlate.value = true;
          console.log("plate error");
          return;
        }
        console.error(error);
      }
    };
    const handleSaveAndNew = async () => {
      try {
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "plates",
          fields: {
            plate_number: plate.value,
            status: status.value,
            created_at: timestamp,
          },
        };

        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          handleClear();
          emit("toast", "update");
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          handleClear();
          emit("toast", "create");
        }
      } catch (error) {
        if (error) {
          isValidatePlate.value = true;
          console.log("plate error");
          return;
        }
        console.error(error);
      }
    };
    onMounted(() => {
      if (props.datatoedit) {
        plate.value = props.datatoedit.plate_number;
        status.value = props.datatoedit.status;
      }
    });
    const isUpdate = props.datatoedit;
    return {
      handleClose,
      handleSubmit,
      plate,
      status,
      isUpdate,
      route,
      isValidatePlate,
      handleSaveAndNew,
    };
  },
};
</script>

<style></style>
