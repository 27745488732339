<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-auto h-auto bg-white text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Generate Payment Invoice</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex items-center h-[85px] justify-between bg-primary3/20 text-primary3 text-heading2"
    >
      <div
        class="flex flex-col items-start justify-center px-2 text-start w-[55%] bg-primary3/10 p-1 h-full text-[12px]"
      >
        <div class="flex justify-between w-full text-body">
          <p>Total Rice Weight</p>
          <p>{{ numeralFormat(total_remain_weight, "0,0[.]00") }} Kg</p>
        </div>
        <div class="flex justify-between w-full text-body">
          <p>Cost/t</p>
          <p>
            {{ amountPerKilo ? numeralFormat(amountPerKilo, "0,0[.]00") : 0 }}
            KHR
          </p>
        </div>
      </div>
      <div class="w-full text-start">
        <h2 class="text-primary3 text-body font-bold ml-4">To Be Paid:</h2>
        <h2 class="text-primary3 text-heading1 font-bold ml-4">
          {{ numeralFormat(total_remain_weight * amountPerKilo, "0,0[.]00") }}
          KHR
        </h2>
      </div>
    </div>
    <div class="w-full overflow-auto">
      <div class="w-full overflow-auto">
        <div class="p-3">
          <div
            class="py-2 grid grid-cols-2 space-y-2 bg-white border p-2 rounded-md"
          >
            <div
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Supplier</h2>
              <h2>{{ datatoedit[0].name }}</h2>
            </div>
            <div
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Created At</h2>
              <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
            </div>
            <div
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Phone Number</h2>
              <h2>
                {{
                  datatoedit.phone_number ? datatoedit.phone_number : "No Data"
                }}
              </h2>
            </div>
          </div>
        </div>
        <div class="p-3 space-y-3">
          <div class="bg-white p-2 rounded-lg">
            <div>
              <h2 class="text-body text-primary6 text-start">Rice Details</h2>
            </div>
            <div class="relative w-full overflow-auto mt-4 bg-white">
              <table class="border">
                <thead>
                  <tr class="header_table">
                    <td class="min-w-[70px] text-nowrap border-b text-start">
                      Date Time
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Rice Type
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Weight In
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Weight Out
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Cut Out
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Remain Weight
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="weighings in weighing"
                    class="text-body font-normal text-primary2 text-nowrap"
                  >
                    <td class="border-b">
                      {{ formatDate2(weighings.created_at) }}
                    </td>
                    <td class="border-b">{{ weighing.rice }}</td>
                    <td class="border-b">
                      {{ numeralFormat(weighings.weight_in, "0,0[.]00") }} Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(weighings.weight_out, "0,0[.]00") }} Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(weighings.amount_cut_off, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(weighings.weight_drop, "0,0[.]00") }}
                      Kg
                    </td>
                  </tr>
                  <!-- {{totalsByGroup}} -->
                  <tr>
                    <td colspan="2" class="font-semibold border-b">Totals</td>
                    <td class="border-b">
                      {{ numeralFormat(total_weight_in, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(total_weight_out, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(total_cut_off, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(total_remain_weight, "0,0[.]00") }}
                      Kg
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex flex-col items-start">
            <div class="flex items-start gap-3">
              <label for="name" class="flex gap-1 text-nowrap">
                <span class="text-red-500">*</span> Labor Cost/Kg</label
              >
              <div class="w-full flex flex-col">
                <div class="flex items-center gap-3">
                  <InputNumber
                    :maxFractionDigits="2"
                    class="w-full md:w-56 h-2 text-start flex items-center"
                    placeholder="Enter Amount"
                    step="0.01"
                    suffix=" ៛"
                    required
                    v-model="amountPerKilo"
                  />
                  <el-select
                    disabled
                    v-model="value"
                    placeholder="KHR"
                    size="large"
                    style="width: 100px"
                  >
                    <el-option key="KHR" label="KHR" value="KHR" />
                  </el-select>
                </div>
                <div v-if="isValidateAmountPerKilo" class="mt-2">
                  <el-alert title="Please enter amounts" type="error" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full p-5 space-y-4 flex items-end justify-between">
        <div class="flex w-full items-center gap-3">
          <button type="submit">Save</button>
          <button class="btncancel" type="button" @click="handleToPrint">
            Save & Print
          </button>
          <button class="btncancel" type="button" @click="handleClose">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { validateInputNUmber } from "@/composables/ValidateInput";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch, reactive, onUnmounted } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { fetchTimestamp } from "@/services/timestamp";
import { decodeJwt } from "@/composables/decodeJWT";
import socket from "@/services/socket";
import { calculateBalancePreloanSupplier } from "@/utils/calculateWallet";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const router = useRouter();

    const handleClose = () => {
      emit("close");
    };
    const total_weight_in = ref(null);
    const total_weight_out = ref(null);
    const total_cut_off = ref(null);
    const total_remain_weight = ref(null);
    const weighing = ref([]);

    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: props.datatoedit[0].id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        weighing.value = response.data;
        total_weight_in.value = weighing.value.reduce(
          (acc, curr) => acc + curr.weight_in,
          0
        );
        total_weight_out.value = weighing.value.reduce(
          (acc, curr) => acc + curr.weight_out,
          0
        );
        total_cut_off.value = weighing.value.reduce(
          (acc, curr) => acc + curr.amount_cut_off,
          0
        );
        total_remain_weight.value = weighing.value.reduce(
          (acc, curr) => acc + curr.weight_drop,
          0
        );
        console.log("weighing.value", weighing.value);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const amountPerKilo = ref(null);
    const isValidateAmountPerKilo = handleValidate(
      amountPerKilo.value,
      amountPerKilo
    );
    const note = ref(null);
    const handleSubmit = async () => {
      if (!amountPerKilo.value) {
        isValidateAmountPerKilo.value = true;
        return;
      }
      try {
        const timestamp = await fetchTimestamp();
        const resquest_to_service_external = {
          tableName: "service_external",
          fields: {
            supplier_id: props.datatoedit[0].supplier_id,
            weighing_id: [props.datatoedit[0].id],
            weighings: JSON.stringify(weighing.value),
            amount_paid: total_weight_in.value * amountPerKilo.value,
            service_external_type: "weighing",
            note: note.value,
            weight_type: "kg",
            phone_number: props.datatoedit[0].phone_number,
            price: amountPerKilo.value,
            total_weight: total_weight_in.value,
            created_by: authUserId.value,
            created_at: timestamp,
            status: "Completed",
          },
        };
        const res = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          resquest_to_service_external
        );
        if (res.data) {
          const req_invoice_update = {
            tableName: "weighing",
            fields: {
              is_external_invoice: true,
            },
          };

          const weighing_ids = props.datatoedit
            .map((record) => record.id)
            .filter((id, index, array) => array.indexOf(id) === index);

          weighing_ids.forEach(async (d) => {
            try {
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${d}`,
                req_invoice_update
              );
              console.log(`Weighing entry with ID ${d} updated successfully`);
            } catch (error) {
              console.error(`Failed to update weighing ID: ${d}`, error);
            }
          });
        }
        emit("close");
        emit("toast", "update");
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const authUserId = ref(null);
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchWeighing()]);
    };
    onMounted(async () => {
      await Promise.allSettled([fetchWeighing()]);

      socket.on("database_realTime", handleDatabaseUpdate);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
    });

    onUnmounted(() => {
      socket.off("database_realTime");
    });
    const handleToPrint = async () => {
      if (!amountPerKilo.value) {
        isValidateAmountPerKilo.value = true;
        return;
      }
      try {
        const timestamp = await fetchTimestamp();
        const resquest_to_service_external = {
          tableName: "service_external",
          fields: {
            supplier_id: props.datatoedit[0].supplier_id,
            weighing_id: [props.datatoedit[0].id],
            weighings: JSON.stringify(weighing.value),
            amount_paid: total_weight_in.value * amountPerKilo.value,
            service_external_type: "weighing",
            note: note.value,
            weight_type: "kg",
            phone_number: props.datatoedit[0].phone_number,
            price: amountPerKilo.value,
            total_weight: total_weight_in.value,
            created_by: authUserId.value,
            created_at: timestamp,
            status: "Completed",
          },
        };
        const res = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          resquest_to_service_external
        );
        if (res.data) {
          const req_invoice_update = {
            tableName: "weighing",
            fields: {
              is_external_invoice: true,
            },
          };

          const weighing_ids = props.datatoedit
            .map((record) => record.id)
            .filter((id, index, array) => array.indexOf(id) === index);

          weighing_ids.forEach(async (d) => {
            try {
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${d}`,
                req_invoice_update
              );
              console.log(`Weighing entry with ID ${d} updated successfully`);
            } catch (error) {
              console.error(`Failed to update weighing ID: ${d}`, error);
            }
          });
          router.push({
            name: "printweighingservice",
            params: { id: res.data.insertedId },
          });
          handleClose();
          emit("toast", "update");
        }
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    return {
      handleClose,
      handleToPrint,
      handleSubmit,
      formatDate2,
      weighing,
      total_weight_in,
      total_weight_out,
      total_cut_off,
      total_remain_weight,
      amountPerKilo,
      isValidateAmountPerKilo,
    };
  },
};
</script>

<style></style>
