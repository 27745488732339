<template>
  <div class="w-full flex items-center justify-between mt-8">
    <div class="flex gap-2 items-center">
      <h2>Total of {{ totalItems }} items</h2>
      <el-select
        v-model="internalPageSize"
        @change="handlePageSizeChange"
        placeholder="Select"
        style="width: 70px"
      >
        <el-option
          v-for="option in getRowOptions"
          :key="option"
          :value="option"
        />
      </el-select>
    </div>
    <div class="flex items-center gap-3 pagination-controls">
      <svg
        @click="prevPage"
        :disabled="currentPage === 1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 cursor-pointer hover:text-primary3 duration-300"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>

      <button
        v-for="page in totalPages"
        :key="page"
        @click="changePage(page)"
        :class="{
          'text-primary3 bg-default border !border-none hover:bg-none cursor-pointer px-2 py-1':
            page === currentPage,
          'text-primary2 !bg-default border !border-none hover:bg-none cursor-pointer px-2 py-1':
            page !== currentPage,
        }"
      >
        {{ page }}
      </button>

      <svg
        @click="nextPage"
        :class="{ disabled: currentPage === totalPages }"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6 cursor-pointer hover:text-primary3 duration-300"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, watch} from "vue";

export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 1
    },
    getRowOptions: {
      type: Array,
      default: () => [50, 75, 100, 200, 500, 1000, 2000],
    },
  },
  setup(props, { emit }) {
    const internalPageSize = ref(props.pageSize);

    const handlePageSizeChange = (size) => {
      emit("updatePageSize", size);
    };

    const changePage = (page) => {
      emit("updatePage", page);
    };

    const prevPage = () => {
      if (props.currentPage > 1) {
        emit("updatePage", props.currentPage - 1);
      }
    };

    const nextPage = () => {
      if (props.currentPage < props.totalPages) {
        emit("updatePage", props.currentPage + 1);
      }
    };

    watch(
      () => props.pageSize,
      (newValue) => {
        internalPageSize.value = newValue;
      }
    );

    return {
      internalPageSize,
      handlePageSizeChange,
      changePage,
      prevPage,
      nextPage,
    };
  },
};
</script>

<style scoped>
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-numbers button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  color: black;
}

.page-numbers button.active {
  background-color: #f0f0f0;
  color: black !important;
}

.page-numbers button:not(.active):hover {
  background-color: #f0f0f0;
  color: black;
}

.hidden-pages {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: 10;
}

.page-numbers button:hover .hidden-pages {
  display: block;
}
</style>
