<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeDialog" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed top-[7rem] inset-0 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
              <DialogTitle class="text-lg text-start font-medium text-gray-900">
                Confirm Delete
              </DialogTitle>
              <div class="mt-2">
                <p class="text-nowrap text-start">
                  {{ t("message.deleteconfirm") }}
                  {{
                    $route.path === "/walletTransaction"
                      ? "transaction"
                      : $route.path === "/preLoan"
                      ? "transaction"
                      : ""
                  }}
                  [

                  {{
                    $route.path === "/users"
                      ? deleteDoc?.username
                      : $route.path === "/supplier" ||
                        $route.path === "/client" ||
                        $route.path === "/parking" ||
                        $route.path === "/warehouse" ||
                        $route.path === "/ricetyesetting"
                      ? deleteDoc?.name
                      : $route.path === "/plates"
                      ? deleteDoc?.plate_number
                      : $route.path === "/preLoan"
                      ? deleteDoc?.preloan_id
                      : $route.path === "/sale-order"
                      ? getCustomersName(deleteDoc?.id)
                      : $route.path === "/wallet"
                      ? deleteDoc?.id
                      : $route.path === "/weighinglist"
                      ? getSupplierName(deleteDoc?.id)
                      : $route.path === "/purchase-order"
                      ? getSupplierName(deleteDoc?.id)
                      : $route.path === "/rentalparking"
                      ? getSupplierName(deleteDoc?.supplier_id)
                      : $route.path === "/formtrucktransction"
                      ? deleteDoc?.supplier_name
                      : ""
                  }}

                  ]
                </p>
              </div>
              <div class="mt-4 flex justify-end gap-2">
                <button
                  class="bg-gray-200 px-4 py-2 rounded text-primary2"
                  @click="closeDialog"
                >
                  Cancel
                </button>
                <button
                  class="bg-red-600 text-white px-4 py-2 rounded"
                  @click="confirmDelete"
                >
                  Delete
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useRoute } from "vue-router";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import apirURL from "@/services/apiURL";
import axios from "axios";
export default defineComponent({
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    deleteDoc: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const closeDialog = () => {
      emit("close");
    };

    const route = useRoute();
    const confirmDelete = () => {
      emit("confirm", props.deleteDoc);
      emit("toast", "delete");
      closeDialog();
    };

    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    // Function to fetch suppliers
    const suppliers = ref([]);

    const fetchSuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const customers = ref([]);

    const fetchCustomers = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customers.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const partnerHistory = ref([]);
    const fetchWalletTransactionHistory = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partnerHistory.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const getParnerName = (id) => {
      const partner = partnerHistory.value.find((item) => item?.id === id);
      return partner?.name || "Unknown Supplier";
    };

    const getSupplierName = (id) => {
      const supplier = suppliers.value.find((item) => item?.id === id);
      return supplier?.name || "Unknown Supplier";
    };
    const getCustomersName = (id) => {
      const supplier = customers.value.find((item) => item?.id === id);
      return supplier?.name || "Unknown Supplier";
    };

    watch([() => props.deleteDoc, () => props.isOpen], async () => {
      if (props.isOpen) {
        await Promise.allSettled([fetchSuppliers(), fetchCustomers()]);
      }
    });
    onMounted(async () => {
      await Promise.allSettled([
        fetchSuppliers(),
        fetchCustomers(),
        fetchWalletTransactionHistory(),
      ]);
    });
    return {
      closeDialog,
      confirmDelete,
      route,
      t,
      getSupplierName,
      getCustomersName,
      getParnerName,
    };
  },
});
</script>
