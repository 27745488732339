<template>
  <div>
    <ParkingListVue />
  </div>
</template>

<script>
import ParkingListVue from "./ParkingList.vue";
export default {
  components: {
    ParkingListVue,
  },
};
</script>

<style></style>
