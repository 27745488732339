<template>
  <div class="w-full relative text-primary6">
    <Toast ref="toast" />

    <div :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Customer</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedSupplier"
          :options="suppliers"
          optionLabel="name"
          option-value="id"
          :highlightOnSelect="true"
          filter
          placeholder="Select Customer"
          show-clear
          checkmark
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Status</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectStatus"
          :options="status"
          filter
          placeholder="Select Rice"
          show-clear
          checkmark
          option-label="name"
          option-value="name"
          size="medium"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span> Dou Time</label
        >
        <DatePicker
          v-model="filterByDate"
          placeholder="Filter By Date"
          show-clear
          checkmark
          size="medium"
          showIcon
          showButtonBar
          fluid
          iconDisplay="input"
          style="width: 250px"
          class="w-full md:w-56 h-2 text-start flex items-center"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2"></span>Receiver</label
        >
        <button class="btn_filter" @click="applyFilter">Filter</button>
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2"></span>Receiver</label
        >
        <IconField class="w-[80%] placeholder:text-body2">
          <InputText
            v-model="searchValue"
            placeholder="Search | Parking | Reciver | Status | Rice Type"
          />
          <InputIcon class="pi pi-search" />
        </IconField>
      </div>
    </div>
    <div :class="navClasses">
      <div>
        <h2>Purchase Order List</h2>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_ordering_book?.purchase_order?.create === true)
        "
      >
        <button @click="handleAdd">New Punchase</button>
      </div>
    </div>
    <div
      :class="tableClasses"
      class="h-[500px] animate-fade-up animate-duration-300 animate-delay-100"
    >
      <table>
        <tbody>
          <tr class="header_table">
            <td
              @click="sortData('purchase_order_id')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Order ID
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      sortColumns === 'purchase_order_id' &&
                      sortDirections === 'asc'
                        ? 'sort-up'
                        : sortColumns === 'purchase_order_id' &&
                          sortDirections === 'desc'
                        ? 'sort-down'
                        : 'sort',
                    ]"
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[180px] border-b text-start">Created At</td>
            <td
              @click="sortData('supplier_name')"
              class="min-w-[95px] border-b text-start toggle_sort"
            >
              Supplier
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      sortColumns === 'supplier_name' &&
                      sortDirections === 'asc'
                        ? 'sort-up'
                        : sortColumns === 'supplier_name' &&
                          sortDirections === 'desc'
                        ? 'sort-down'
                        : 'sort',
                    ]"
                  />
                </transition>
              </span>
            </td>
            <td class="min-w-[180px] border-b text-start">Rice Type</td>
            <td class="min-w-[200px] border-b">
              <div class="flex items-center justify-between">
                <div class="flex items-center">Status</div>
                <!-- <input
                  type="text"
                  placeholder="Search by Supplier name"
                  v-model="searchValue"
                  class="w-[70%]"
                /> -->
              </div>
            </td>
            <td class="min-w-[126px] border">Acion</td>
          </tr>
        </tbody>
        <tbody v-if="purchaseOrder.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in purchaseOrder"
            :key="data"
          >
            <td class="border-b">{{ data.purchase_order_id }}</td>
            <td class="border-b">{{ formatDate2(data.current_due_date) }}</td>
            <td class="border-b">{{ data.name }}</td>
            <td class="border-b">
              {{ data.type_of_rice.length }}
            </td>
            <td class="border-b">
              <div
                class="cursor-pointer"
                @click="handleUpdateSaleStatus(data)"
                :class="
                  data?.order_status === 'Order'
                    ? 'chip_order'
                    : data?.order_status === 'Complete'
                    ? 'chip_complete'
                    : 'chip_hold'
                "
                round
              >
                {{ data?.order_status }}
              </div>
            </td>
            <td class="border w-[150px]">
              <div class="flex items-center gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.purchase_order?.view ===
                        true)
                  "
                  @click="handlePopUp(data)"
                  type="mdi"
                  class="info"
                  :path="mdiEyeOutline"
                ></svg-icon>

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.purchase_order
                        ?.update === true)
                  "
                  type="mdi"
                  class="edit"
                  @click="handleEditData(data)"
                  :path="mdiPencil"
                >
                </svg-icon>

                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_ordering_book?.purchase_order
                        ?.delete === true)
                  "
                  type="mdi"
                  class="delete"
                  @click="openDeleteConfirmation(data)"
                  :path="mdiTrashCanOutline"
                >
                </svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <AliesPagination
        :totalItems="purchaseOrder.length"
        :totalPages="totalPages"
        :currentPage="currentPage"
        :pageSize="pageSize"
        :getRowOptions="getRowOptions"
        @updatePage="changePage"
        @updatePageSize="changePageSize"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-fit h-fit bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <component
                  @close="ClosePopup"
                  :is="currentComponent"
                  :datatoedit="datatoedit"
                ></component>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deletedata"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
    <TransitionRoot appear :show="isSaleStatus" as="template">
      <Dialog as="div" @close="handleCloseSaleStatus" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseSaleStatus"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import toggleSort from "@/composables/sortData";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import PunchaseFormVue from "@/form/PunchaseForm.vue";
import PunchaseDetailsPopupVue from "@/form/PunchaseDetailsPopup.vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import socket from "@/services/socket";
import {
  getResponsiveTable,
  getResponsiveSelect,
  getResponsiveTableNav,
} from "@/composables/useResponsive";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import moment from "moment-timezone";
import AliesPagination from "@/components/AliesPagination.vue";
import { mdiEyeOutline, mdiPencil, mdiTrashCanOutline } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";
import UpdatePurchaseOrderStatus from "@/form/UpdatePurchaseOrderStatus.vue";
export default {
  components: {
    PunchaseFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    PunchaseDetailsPopupVue,
    DeleteConfrimation,
    Toast,
    NoDataComponet,
    AliesPagination,
    UpdatePurchaseOrderStatus,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isSaleStatus = ref(false);
    const handleUpdateSaleStatus = (item) => {
      isSaleStatus.value = true;
      datatoedit.value = item;
      currentComponent.value = "UpdatePurchaseOrderStatus";
    };
    const handleCloseSaleStatus = () => {
      isSaleStatus.value = false;
      currentComponent.value = "";
      datatoedit.value = null;
    };
    const handleAdd = () => {
      isOpen.value = true;
      datatoedit.value = null;
      currentComponent.value = "PunchaseFormVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const isPopup = ref(false);
    const handlePopUp = (doc) => {
      datatoedit.value = doc;
      isPopup.value = true;
      currentComponent.value = "PunchaseDetailsPopupVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);
    const currentDate = new Date();
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const filterByDate = ref(null);
    const selectStatus = ref(null);
    const selectedSupplier = ref(null);
    const purchases = ref([]);
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("purchase_orders");
    const searchValue = ref("");
    const purchaseOrder = ref([]);

    const fetchPurchaseOrder = async () => {
      try {
        // Build dynamic conditions based on selected values
        let dynamicConditions = [
          ...(selectStatus.value
            ? [{ field: "status", operator: "=", value: selectStatus.value }]
            : []),
          ...(selectedSupplier.value
            ? [
                {
                  field: "supplier_id",
                  operator: "=",
                  value: selectedSupplier.value,
                },
              ]
            : []),
          ...(filterByDate.value
            ? [
                {
                  field: "due_time",
                  operator: "=",
                  value: filterByDate.value.toISOString().substring(0, 10),
                },
              ]
            : []),
        ];

        const params = {
          tableName: table.value,
          columnSearch: "supplier_name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnAliases: JSON.stringify({
            purchase_order_id: { table: "purchase_orders", column: "id" },
            supplier_name: { table: "suppliers", column: "name" },
            created_by: { table: "purchase_orders", column: "created_by" },
            order_status: { table: "purchase_orders", column: "status" },
          }),
          sortColumn: "purchase_order_id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          { params }
        );

        purchaseOrder.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        purchaseOrder.value = [];
        totalPages.value = 0;
      }
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    watch(searchValue, () => {
      fetchPurchaseOrder();
    });

    const datatoedit = ref(null);
    const handleEditData = (item) => {
      isOpen.value = true;
      currentComponent.value = "PunchaseFormVue";
      datatoedit.value = item;
    };

    const sortSupplierName = (sortDirection) => {
      purchases.value.sort((a, b) => {
        const nameA = getName(a.supplier_id)?.toUpperCase() || "";
        const nameB = getName(b.supplier_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortClient = (field, sortDirection) => {
      if (field === "supplier_name") {
        sortSupplierName(sortDirection);
      } else if (field === "id") {
        toggleSort(sortDirection, purchases.value, field);
      }
    };

    const suppliers = ref([]);

    const fetchsuppliers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        suppliers.value = res.data;
      } catch (error) {
        console.log(error);
      }
    };
    const getName = (id) => {
      const suppliersName = suppliers.value.find((item) => item?.id === id);
      return suppliersName?.name;
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };
    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.purchase_order_id}`,
          {
            data: { tableName: table.value },
          }
        );
        await fetchDataPaginationWithJoin();
        if (isLastPage && purchaseOrder.value.length === 0) {
          currentPage.value = 1;
          await fetchDataPaginationWithJoin(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    const changePageSize = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchDataPaginationWithJoin();
    };
    const changePage = (page) => {
      currentPage.value = page;
      fetchDataPaginationWithJoin();
    };
    const getRowOptions = [50, 100, 150, 200];
    const status = ref([
      { name: "Order", value: "Order" },
      { name: "Complete", value: "Complete" },
    ]);

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchPurchaseOrder(),
        fetchsuppliers(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([
        fetchPurchaseOrder(),
        fetchsuppliers(),
        fetchUserByID(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });
    const applyFilter = async () => {
      await fetchPurchaseOrder();
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      purchaseOrder.value = toggleSort(direction, purchaseOrder.value, field);
    };

    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      navClasses,
      selectClasses,
      tableClasses,
      purchases,
      formatDate2,
      getName,
      showSuccessToast,
      toast,
      datatoedit,
      searchValue,
      handleEditData,
      sortClient,
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      table,
      isDeleteOpen,
      deletedata,
      changePageSize,
      changePage,
      getRowOptions,
      totalPages,
      pageSize,
      purchaseOrder,
      mdiEyeOutline,
      mdiPencil,
      mdiTrashCanOutline,
      suppliers,
      status,
      filterByDate,
      selectStatus,
      selectedSupplier,
      applyFilter,
      sortData,
      sortDirections,
      sortColumns,
      handleCloseSaleStatus,
      handleUpdateSaleStatus,
      isSaleStatus,
    };
  },
};
</script>

<style></style>
