<template>
  <div class="w-[792px] h-fit">
    <div class="details_title">
      <h2 class="ml-4">Boat: {{ boat[0]?.name }}</h2>
      <h2 class="mr-4">Boat Preview</h2>
    </div>

    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>
    <div v-if="activeTab === 'details'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Customer</h2>
          <h2>{{ boat[0]?.customer_id }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(boat[0]?.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Boat</h2>
          <h2>{{ boat[0]?.name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit.status ? "Completed" : "Pending" }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction History</h2>
        <div class="w-full h-fit overflow-auto border">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="border-b text-start text-nowrap">No</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>
                <td class="border-b text-start text-nowrap">Weigh In</td>
                <td class="border-b text-start text-nowrap">Weigh out</td>

                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tbody
              v-for="(entries, plateNumber) in groupedWeighingRiceHistory"
              :key="plateNumber"
            >
              <tr class="border-b">
                <td colspan="7" class="font-bold text-start">
                  Plate Number: {{ plateNumber }}
                </td>
              </tr>
              <tr
                v-for="entry in entries"
                :key="entry.id"
                class="text-body font-normal text-primary2"
              >
                <td class="border-b text-start text-nowrap">{{ entry.id }}</td>
                <td class="border-b text-start text-nowrap">
                  {{ formatDate2(entry.created_at) }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ entry.rice[0].rice_name }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(entry.weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(entry.weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(entry.amount_cut_off, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(entry.weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">Total</td>
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_amount_cut_off, "0,0[.]00") }}
                  Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Customer</h2>
          <h2>{{ boat[0]?.customer_id }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(boat[0]?.created_a) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Boat</h2>
          <h2>{{ boat[0]?.name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit.status ? "Completed" : "Pending" }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction</h2>
        <div class="w-full border h-fit overflow-auto">
          <table
            v-for="(entries, plateNumber) in groupedWeighingRiceHistory"
            :key="plateNumber"
          >
            <tbody>
              <tr class="header_table">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>

                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tr class="">
              <td colspan="7" class="font-bold text-start">
                Plate Number: {{ plateNumber }}
              </td>
            </tr>
            <tr
              v-for="entry in entries"
              :key="entry.id"
              class="text-body font-normal text-primary2"
            >
              <td class="border-b text-start text-nowrap">{{ entry.id }}</td>
              <td class="border-b text-start text-nowrap">
                {{ formatDate2(entry.created_at) }}
              </td>
              <td class="border-b text-start text-nowrap">
                {{ entry.rice[0].rice_name }}
              </td>

              <td class="border-b text-start text-nowrap">
                {{ numeralFormat(entry.amount_cut_off, "0,0[.]00") }} Kg
              </td>
              <td class="border-b text-start text-nowrap">
                {{ numeralFormat(entry.weight_drop, "0,0[.]00") }} Kg
              </td>
            </tr>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">Total</td>
                <td class="text-start text-nowrap"></td>

                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_amount_cut_off, "0,0[.]00") }}
                  Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-start mt-4 gap-3 p-5">
      <button
        type="button"
        @click="handlePrint(datatoedit.boat_enter_parking_id)"
      >
        Print
      </button>

      <button @click="handleClose" class="btncancel">Close</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate2 } from "@/composables/formatDateTime";
import { useRouter } from "vue-router";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const router = useRouter();
    const handlePrint = (id) => {
      const params = {
        id: id,
      };
      router.push({
        name: "printboat",
        params: params,
      });
    };
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("boat_enter_parking");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const boat = ref([]);
    const total_weight_drop = ref(0);
    const total_weight_amount_cut_off = ref(0);
    const total_weight_out = ref(0);
    const total_weight_in = ref(0);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "id",
            operator: "=",
            value: props.datatoedit.boat_enter_parking_id,
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      boat.value = response.data.data;

      console.log("date ", boat.value);

      totalPages.value = response.data.pagination.totalPages;
    };
    const weighing_rice_history = ref([]);
    const fetchWeighingRiceHistory = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: "weighing_rice_history",
          columnSearch: "",

          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: props.datatoedit.weighing_id,
            },
          ]),
        };
        const response = await axios.get(apiUrl, { params });
        weighing_rice_history.value = response.data.data;
        total_weight_drop.value = weighing_rice_history.value.reduce(
          (acc, curr) => acc + curr.weight_drop,
          0
        );
        total_weight_amount_cut_off.value = weighing_rice_history.value.reduce(
          (acc, curr) => acc + curr.amount_cut_off,
          0
        );
        total_weight_out.value = weighing_rice_history.value.reduce(
          (acc, curr) => acc + curr.weight_out,
          0
        );
        total_weight_in.value = weighing_rice_history.value.reduce(
          (acc, curr) => acc + curr.weight_in,
          0
        );
        console.log("weighing_rice_history ", weighing_rice_history.value);
      } catch (error) {
        console.log(error);
      }
    };
    const groupedWeighingRiceHistory = computed(() => {
      return weighing_rice_history.value.reduce((grouped, entry) => {
        const plateNumber = entry.rice[0].plate_number; // Extract plate_number
        if (!grouped[plateNumber]) {
          grouped[plateNumber] = [];
        }
        grouped[plateNumber].push(entry);
        return grouped;
      }, {});
    });

    onMounted(() => {
      if (props.datatoedit) {
        console.log("datatoedit", props.datatoedit);
      }
      fetchWeighing();
      fetchWeighingRiceHistory();
    });

    return {
      handleClose,
      setActiveTab,
      activeTab,
      boat,
      weighing_rice_history,
      total_weight_amount_cut_off,
      total_weight_drop,
      total_weight_out,
      total_weight_in,
      formatDate2,
      groupedWeighingRiceHistory,
      handlePrint,
    };
  },
};
</script>

<style></style>
