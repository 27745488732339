<template>
  <form @submit.prevent="handleSubmit" class="w-[1200px] max-w-[1200px]">
    <div class="form_title">
      <h2 class="ml-4">{{ datatoedit ? "Update Order" : "New Order" }}</h2>
      <div class="closePopUp">
        <svg
          @click="handleClose"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex items-start justify-around w-full h-full p-4 gap-3 relative"
    >
      <div class="h-full space-y-4 border p-3 rounded-md">
        <div class="flex flex-col items-start justify-between h-[350px]">
          <div class="space-y-8">
            <div class="flex items-start gap-3 justify-between text-nowrap">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span>Customer Name</label
              >
              <div class="flex flex-col space-y-2">
                <Select
                  v-model="customerIdSeletet"
                  placeholder="Select Customer"
                  :options="customer"
                  show-clear
                  checkmark
                  optionLabel="name"
                  optionValue="id"
                  style="width: 250px"
                  filter
                  size="medium"
                  class="w-full md:w-56 h-9 text-start flex items-center"
                />
                <div v-if="isValidateCustomer" class="w-[250px]">
                  <el-alert
                    title="Please Select Customer"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center gap-3 justify-between text-nowrap">
              <label for="name" class="flex gap-1">
                <span class="text-red-500">*</span>
                Dou Time
              </label>
              <div class="flex flex-col gap-2">
                <DatePicker
                  v-model="douDate"
                  placeholder="Select Due Date"
                  show-clear
                  checkmark
                  size="medium"
                  showIcon
                  showButtonBar
                  fluid
                  iconDisplay="input"
                  style="width: 250px"
                  class="w-full md:w-56 h-2 text-start flex items-center"
                  :minDate="new Date()"
                />
                <div v-if="isValidateDudat" class="w-[250px] mt-4">
                  <el-alert
                    title="Please Select Due Date"
                    type="error"
                    :closable="false"
                  />
                </div>
              </div>
              <!-- <DatePicker v-model="icondisplay" showIcon fluid iconDisplay="input" /> -->
            </div>

            <div
              v-if="datatoedit"
              class="flex items-center justify-between gap-3"
            >
              <label for="name" class="flex gap-1 text-nowrap">
                <span class="text-red-500">*</span> Order Status</label
              >
              <label class="flex items-center space-x-2">
                <input
                  type="radio"
                  name="status"
                  :value="isOrder"
                  v-model="status"
                  @change="handleRadioChange"
                  class="form-radio text-primary3"
                />
                <span>Order</span>
              </label>

              <label class="flex items-center space-x-2">
                <input
                  type="radio"
                  name="status"
                  :value="isComplete"
                  v-model="status"
                  @change="handleRadioChange"
                  class="form-radio text-primary3"
                />
                <span>Complete</span>
              </label>

              <label class="flex items-center space-x-2">
                <input
                  type="radio"
                  name="status"
                  :value="isCancel"
                  v-model="status"
                  @change="handleRadioChange"
                  class="form-radio text-primary3"
                />
                <span>Cancel</span>
              </label>
            </div>
            <div class="flex items-start gap-3">
              <label for="Description">Note</label>
              <textarea
                type="text"
                v-model="note"
                placeholder="Any Description"
                rows="4"
                class="w-full px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
              />
            </div>
          </div>
          <div class="space-x-2 flex">
            <button type="submit" :disabled="isPending">
              {{ datatoedit ? "Update " : "Save" }}
              <span v-if="isPending" :class="{ is_pending: isPending }"></span>
            </button>
            <button
              type="button"
              @click="SaveAndNew"
              :disabled="isPendingSave"
              class="btncancel"
            >
              {{ datatoedit ? "Update & Add New " : "Save & New" }}
              <span
                v-if="isPendingSave"
                :class="{ is_pending_save: isPendingSave }"
              ></span>
            </button>
            <button type="button" @click="handleClose" class="btncancel">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div class="p-3 rounded-md relative border">
        <h2 class="text-start py-2">Rice Detail</h2>
        <div class="w-full h-[500px] bg-white overflow-auto border">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Rice Type
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Amount
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Estimate Price
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  Estimate Total
                </td>
                <td
                  class="border-b text-nowrap text-start border-primary5/50"
                ></td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="(row, index) in rows"
                :key="index"
              >
                <td class="border-b text-nowrap text-start border-primary5/50">
                  <div class="flex flex-col gap-2 items-start">
                    <Select
                      :options="rices"
                      checkmark
                      placeholder="Select Rice"
                      v-model="row.selectedRice"
                      optionLabel="name"
                      optionValue="name"
                      filter
                      size="medium"
                      :invalid="isValidateRice"
                      style="width: 150px"
                      class="w-full md:w-56 h-9 text-start flex items-center"
                    />
                  </div>
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  <div class="flex flex-col gap-2 items-start">
                    <InputNumber
                      v-model="row.amount"
                      style="width: 150px"
                      placeholder="Amount"
                      suffix=" Kg"
                      class="w-full md:w-56 h-3 text-start flex items-center"
                      :invalid="isValidateAmount"
                      :maxFractionDigits="2"
                      step="0.01"
                    />
                  </div>
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  <InputNumber
                    v-model="row.estimatePrice"
                    style="width: 150px"
                    suffix=" ₫"
                    class="w-full md:w-56 h-3 text-start flex items-center"
                    placeholder="Amount"
                    :maxFractionDigits="2"
                    step="0.01"
                  />
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  VND
                  {{
                    numeralFormat(
                      row.amount * row.estimatePrice || "0",
                      "0,0[.]0"
                    )
                  }}
                </td>
                <td class="border-b text-nowrap text-start border-primary5/50">
                  <svg
                    @click="deleteRow(index)"
                    v-if="index > 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-trash-2 delete"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  <svg
                    @click="deleteRow(index)"
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-trash-2 delete"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <div @click="addRow" class="w-full cursor-pointer">
                    {{ NewRow }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="w-full right-0 py-4 bg-white rounded flex items-center justify-between text-red-500 text-heading2 bottom-0"
        >
          <h2 class="ml-4">
            Total Amount: {{ numeralFormat(totalAmount, "0,0[.]0") }} Kg
          </h2>
          <h2 class="mr-4">
            Total Estimated Price:
            {{
              numeralFormat(
                formatCurrency(totalEstimatedPrice, " VND ", "after"),
                "0,0[.]0"
              )
            }}
            VND
          </h2>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import apirURL from "@/services/apiURL";
import { formatCurrency } from "@/composables/formatCurrency";
import { fetchTimestamp } from "@/services/timestamp";
import axios from "axios";
import { ref, onMounted, onUnmounted, computed } from "vue";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import { nextTick } from "vue";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const NewRow = ref("<New>");
    const name = ref("");
    const status = ref("Order");
    const isCancel = ref("Cancel");
    const isOrder = ref("Order");
    const isComplete = ref("Complete");
    const handleRadioChange = () => {
      console.log("handleRadioChange statuse", status.value);
    };
    const customer = ref([]);
    const customerIdSeletet = ref(null);
    const note = ref("");
    const isPending = ref(false);
    const isPendingSave = ref(false);
    const isAdding = ref(false);
    const isUpdatig = ref(false);
    const isUpdate = props.datatoedit;
    const authUserId = ref(null);
    const douDate = ref(null);
    const handleClear = () => {
      customerIdSeletet.value = null;
      note.value = "";
      status.value = "";
      riceSelect.value = "";
      douDate.value = null;
      rows.value.map((row) => {
        row.selectedRice = null;
        row.amount = null;
        row.estimatePrice = null;
      });
    };

    const rows = ref([
      { selectedRice: null, amount: null, estimatePrice: null },
    ]);

    // Method to add a new row
    const addRow = () => {
      rows.value.push({
        selectedRice: null,
        amount: null,
        estimatePrice: null,
      });
    };

    const deleteRow = (index) => {
      rows.value.splice(index, 1);
    };
    const totalAmount = computed(() => {
      return rows.value.reduce(
        (sum, row) => sum + parseFloat(row.amount) || 0,
        0
      );
    });

    const totalEstimatedPrice = computed(() => {
      return rows.value.reduce(
        (sum, row) =>
          sum +
          (parseFloat(row.estimatePrice) || 0) * (parseFloat(row.amount) || 0),
        0
      );
    });
    const fetchcustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        customer.value = response.data;
        console.log("customer", customer.value);
      } catch (error) {
        console.error("Error fetching customer:", error);
      }
    };
    const riceSelect = ref([]);
    const rices = ref([]);

    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rices.value = response.data.map((rice) => ({
          id: rice.id,
          name: rice.name,
        }));
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const isValidateCustomer = handleValidate(
      customerIdSeletet.value,
      customerIdSeletet
    );
    const isValidateDudat = handleValidate(douDate.value, douDate);
    const isValidateRice = ref(false);
    const isValidateAmount = ref(false);
    const validateRows = () => {
      isValidateRice.value = rows.value.some((row) => !row.selectedRice);
      isValidateAmount.value = rows.value.some((row) => !row.amount);
    };
    const handleSubmit = async () => {
      if (!customerIdSeletet.value) {
        isValidateCustomer.value = true;
        return;
      }
      if (!douDate.value) {
        isValidateDudat.value = true;
        return;
      }

      validateRows();
      if (isValidateRice.value) {
        console.log("Please select rice for all rows.");
        return;
      }

      if (isValidateAmount.value) {
        console.log("Please enter an amount for all rows.");
        return;
      }
      isPending.value = true;
      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "customer_orders",
        fields: {
          customer_id: customerIdSeletet.value,
          type_of_rice: JSON.stringify(
            rows.value.map((row) => ({
              selectedRice: row.selectedRice,
              amount: row.amount,
              estimatePrice: row.estimatePrice,
            }))
          ),
          note: note.value,
          created_by: authUserId.value,
          status: status.value,
          created_at: timestamp,
          current_due_date: douDate.value,
        },
      };

      try {
        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.customer_order_id}`,
            requestBody
          );
          emit("toast", "update");
        } else {
          // Add new entry
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
        }

        handleClear();
        handleClose();
      } catch (error) {
        console.error("Error during form submission:", error);
        // Handle the error appropriately
      }
    };

    const SaveAndNew = async () => {
      if (!customerIdSeletet.value) {
        isValidateCustomer.value = true;
        return;
      }
      if (!douDate.value) {
        isValidateDudat.value = true;
        return;
      }

      validateRows();
      if (isValidateRice.value) {
        console.log("Please select rice for all rows.");
        return;
      }

      if (isValidateAmount.value) {
        console.log("Please enter an amount for all rows.");
        return;
      }
      const timestamp = await fetchTimestamp();
      const requestBody = {
        tableName: "customer_orders",
        fields: {
          customer_id: customerIdSeletet.value,
          type_of_rice: JSON.stringify(
            rows.value.map((row) => ({
              selectedRice: row.selectedRice,
              amount: row.amount,
              estimatePrice: row.estimatePrice,
            }))
          ),
          note: note.value,
          created_by: authUserId.value,
          status: status.value,
          created_at: timestamp,
          current_due_date: douDate.value,
        },
      };
      setTimeout(() => {
        isPendingSave.value = true;
        setTimeout(() => {
          isPendingSave.value = false;
        }, 1000);
      }, 0);
      try {
        if (props.datatoedit && props.datatoedit.id) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.customer_order_id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
        } else {
          // Add new entry
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          emit("toast", "create");
          handleClear();
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        // Handle the error appropriately
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchcustomer(), fetchRiceType()]);
    };

    onMounted(async () => {
      if (props.datatoedit) {
        setTimeout(() => {
          customerIdSeletet.value = props.datatoedit
            ? props.datatoedit.customer_id
            : null;
        }, 100);
        rows.value = props.datatoedit.type_of_rice.map((rice) => ({
          selectedRice: rice.selectedRice || rice, // Adjust this if your structure differs
          amount: rice.amount || 0, // Provide a default value if not present
          estimatePrice: rice.estimatePrice || 0, // Provide a default value if not present
        }));

        note.value = props.datatoedit.note;

        status.value = props.datatoedit.order_status;
        douDate.value = props.datatoedit.current_due_date;
      }
      await Promise.allSettled([fetchcustomer(), fetchRiceType()]);

      socket.on("database_realTime", handleDatabaseUpdate);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);

      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
    });

    const handleFocus = (event) => {
      if (!event.isTrusted) {
        event.preventDefault();
      }
    };
    const isFilterable = ref(false);
    fetchcustomer().then(() => {
      nextTick(() => {
        const selectEl = document.querySelector(".el-select");
        if (selectEl) {
          selectEl.blur();
        }
      });
    });
    return {
      handleClose,
      name,
      status,
      handleSubmit,
      isAdding,
      isUpdate,
      SaveAndNew,
      isUpdatig,
      customer,
      customerIdSeletet,
      note,
      rices,
      riceSelect,
      rows,
      deleteRow,
      addRow,
      totalAmount,
      totalEstimatedPrice,
      isFilterable,
      handleFocus,
      isCancel,
      isOrder,
      handleRadioChange,
      NewRow,
      formatCurrency,
      douDate,
      isValidateCustomer,
      isValidateDudat,
      isValidateRice,
      isValidateAmount,
      isPending,
      isPendingSave,
      isComplete,
    };
  },
};
</script>

<style></style>
