<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="router.go(-1)" class="btncancel hidden-print">
            Back
          </button>
        </div>
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <!-- {{ invoice }} -->
      <div class="w-full mt-4 bg-white p-8 print-header" id="printTable">
        <div class="flex justify-between items-start">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Truck Invoice</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12px]">011 111 111 / 012 123 456</h2>
          </div>
          <div class="text-primary5 text-[10px] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[10px] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4"
        >
          <div class="flex w-full items-center justify-between">
            <h2>Invoice No.</h2>
            <h2>{{ router.currentRoute.value.params.id }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Truck No.</h2>
            <h2>{{ weighingData[0]?.plate_number.plate_number }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Truck In</h2>
            <h2>
              {{ formatDate2(weighingData[0]?.created_at) }}
            </h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Truck Out</h2>
            <h2>
              {{ formatDate2(weighingData[0]?.updated_at) }}
            </h2>
          </div>
          <!-- <div class="flex w-full items-center justify-between">
            <h2>Partner</h2>
            <h2>{{ getPartnerName(invoice[0]?.partner_id) }}</h2>
          </div> -->

          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
        </div>
        <div class="py-2">
          <h2>Transaction Detail</h2>
          <div class="relative mt-4 bg-white text-[10px]">
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[70px] text-nowrap border text-start">No</td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Time
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Rice Type
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Weight In
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Weight Out
                  </td>

                  <td class="min-w-[100px] text-nowrap border text-start">
                    Cut
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Remain Kg
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr
                  class="text-[10px] font-normal text-primary2 text-nowrap"
                  v-for="(data, index) in weighing"
                  :key="index"
                >
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ index + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ formatDate2(data.created_at) }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ data.rice[0].rice_name }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.weight_in, "0,0[.]00") }}
                    Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.weight_out, "0,0[.]00") }}
                    Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }}
                    Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.weight_drop, "0,0[.]00") }}
                    Kg
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="font-bold bg-default">
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(total_weight_in, "0,0[.]00") }} Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(total_weight_out, "0,0[.]00") }} Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(total_weight_amount_cut_off, "0,0[.]00") }}
                    Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(total_weight_drop, "0,0[.]00") }} Kg
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div
          class="w-full h-[40px] bg-main border-main border-2 mt-4 flex items-center justify-between"
        >
          <div class="w-[70%]">
            <h2 class="text-right text-[10px] text-default mr-4">
              Total to Pay
            </h2>
          </div>
          <div class="bg-default w-[30%] h-full flex items-center justify-end">
            <h2 class="text-body mr-4 font-bold">
              {{ numeralFormat(invoice[0]?.amount_paid, "0,0[.]00") }} ៛
            </h2>
          </div>
        </div> -->

        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[40px]"
        >
          <h2 class="text-primary5 text-[10px]">Accepted</h2>
          <h2 class="text-primary5 text-[10px]">Issued</h2>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[50px]"
        >
          <h2 class="text-primary5 text-[10px]"></h2>
          <h2 class="text-primary5 text-[10px]">
            {{ getUserName(invoice[0]?.user_id) }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { ref, onMounted, computed, watch } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
export default {
  setup() {
    const route = useRoute();
    const invoice = ref([]);

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );

    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("weighing_rice_history");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const weighing = ref([]);
    const total_weight_drop = ref(0);
    const total_weight_amount_cut_off = ref(0);
    const total_weight_out = ref(0);
    const total_weight_in = ref(0);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "weighing_id",
            operator: "=",
            value: route.params.id,
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing.value = response.data.data;
      total_weight_drop.value = weighing.value.reduce(
        (acc, curr) => acc + curr.weight_drop,
        0
      );
      total_weight_amount_cut_off.value = weighing.value.reduce(
        (acc, curr) => acc + curr.amount_cut_off,
        0
      );
      total_weight_out.value = weighing.value.reduce(
        (acc, curr) => acc + curr.weight_out,
        0
      );
      total_weight_in.value = weighing.value.reduce(
        (acc, curr) => acc + curr.weight_in,
        0
      );
      console.log("Invoice ", weighing.value);
      totalPages.value = response.data.pagination.totalPages;
    };
    onMounted(() => {
      fetchWeighing();
    });
    const supplier = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        supplier.value = response.data;
        console.log("suppiler data:", supplier.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rices.value = response.data;
        console.log("rices data:", rices.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const weighingData = ref([]); // Store full weighing data

    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: route.params.id,
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        weighingData.value = response.data; // Store all data
        console.log("Fetched weighing data:", weighingData.value); // Check if data is fetched correctly
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    const partner = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        partner.value = response.data;
        console.log("plate data:", partner.value);
      } catch (error) {
        console.error("Error fetching partner data:", error);
      }
    };
    const users = ref([]);
    const fetchUser = async () => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: invoice.value[0]?.partner_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        users.value = response.data;
        console.log("plate data:", users.value);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };

    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getSupplierName = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = plate.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getPhoneNumer = (id) => {
      const name = supplier.value.find((item) => item?.id === id);
      return name?.phone_number;
    };
    const getUserName = (id) => {
      const name = users.value.find((item) => item?.id === id);
      return name?.profile_name;
    };

    // Ensure to fetch data on component mount
    onMounted(async () => {
      await Promise.allSettled([
        fetchSupplier(),
        fetchRiceType(),
        fetchPartner(),
        fetchWeighingData(),
        fetchUser(),
      ]);
    });

    const handlePrint = () => {
      window.print();
    };

    const router = useRouter();
    return {
      handlePrint,
      invoice,

      supplier,
      partner,
      formatDate2,
      getPlatesName,
      getRice,
      getSupplierName,
      getPhoneNumer,
      getUserName,
      users,
      router,
      weighing,
      total_weight_drop,
      total_weight_amount_cut_off,
      total_weight_out,
      total_weight_in,
      weighingData,
    };
  },
};
</script>

<style lang="scss" scoped></style>
