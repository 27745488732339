import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TestView from "../views/test/testView.vue";
import LoginView from "../views/test/loginView.vue";
import weighingView from "../views/test/weighingView.vue";
import telegramView from "../views/test/telegramView.vue";
import { isAuthenticated } from "../composables/checkAuthentication";
import resetPasswordView from "../views/test/resetPassword.vue";
import forgotPasswordView from "../views/test/forgotPassword.vue";

import Dashboard from "@/views/admin/Dashboard.vue";
import Client from "@/views/admin/Client.vue";
import Parking from "@/views/admin/Parking.vue";
import Weighing from "@/views/admin/Weighing.vue";
import Supplier from "@/views/admin/SupplierPage.vue";
import FinancePage from "@/views/admin/Finance.vue";
import Warehouse from "@/views/admin/Warehouse.vue";
import OrderingBook from "@/views/admin/OrderingBook.vue";

import WalletList from "@/views/admin/WalletList.vue";
import PreLoan from "@/views/admin/PreLoan.vue";
import TruckList from "@/views/admin/TruckList.vue";
import PrintBill from "../prints/PrintBill.vue";
import PrintClientInvoice from "../prints/PrintClientInvoice.vue";
import PrintLabor from "../prints/PrintLaborSlip.vue";
import PrintSackSlip from "../prints/PrintSackSlip.vue";
import PrintRentalParking from "@/prints/PrintRentalParking.vue";
import ExternalWeighingPrintInvoice from "@/externalservice/ExternalWeighingPrintInvoice.vue";
import ViewParkingCard from "@/views/admin/ViewParkingCard.vue";
import WeighingTransaction from "@/views/admin/WeighingTransaction.vue";
import RiceStock from "@/warehouse/RiceStock.vue";
import StockHistory from "@/warehouse/StockHistory.vue";

import SaleOrderReport from "@/reports/SaleOrderReport.vue";
import ReportPurchaseOrder from "@/reports/ReportPurchaseOrder.vue";
import SupplierBillReport from "@/reports/SupplierBillReport.vue";
import BillPaymentReport from "@/reports/BillPaymentReport.vue";
import SaleInvoiceReport from "@/reports/SaleInvoiceReport.vue";
import InvoicePaymentReport from "@/reports/InvoicePaymentReport.vue";
import LaborSlipReport from "@/reports/LaborSlipReport.vue";
import LaborSlipPayment from "@/reports/LaborSlipPayment.vue";
import SackSlipReport from "@/reports/SackSlipReport.vue";
import SackSlipPayment from "@/reports/SackSlipPayment.vue";
import WalletTransactionReport from "@/reports/WalletTransactionReport.vue";
import WalletHistory from "@/reports/WalletHistoryReport.vue";
import PreLoanReport from "@/reports/PreLoanReport.vue";
import LoanRepaymentReport from "@/reports/LoanRepaymentReport.vue";
import ExternalWeighingSlipReport from "@/reports/ExternalWeighingSlipReport.vue";
import RentalParkingReport from "@/reports/RentalParkingReport.vue";
import ExternalWeighingSlipPaymentReport from "@/reports/ExternalWeighingSlipPaymentReport.vue";

import ParkingRentalPaymentReport from "@/reports/ParkingRentalPaymentReport.vue";
import User from "@/settings/User.vue";
import SettingRiceType from "@/settings/SettingRiceType.vue";

import RentalParkingTransaction from "@/externalservice/RentalParkingList.vue";
import FromeTruckTransition from "@/views/admin/FromeTruckTransition.vue";
import ToParkingTransition from "@/views/admin/ToParkingTransition.vue";
import BillTransaction from "@/Bill/BillTransaction.vue";
import InvoiceTransactoin from "@/Bill/InvoiceTransactoin.vue";
import LaborSlipTransaction from "@/Bill/LaborSlipTransaction.vue";
import SackSlipTransaction from "@/Bill/SackSlipTransaction.vue";
import WalletTransaction from "@/views/admin/WalletTransaction.vue";
import ExternalServiceWeighing from "@/externalservice/ExternalServiceWeighing.vue";
import ClientRepaymentList from "@/views/admin/ClientRepaymentList.vue";
import WeighingList from "@/views/admin/WeighingList.vue";
import ToParkingList from "@/views/admin/ToParkingList.vue";
import FromTruckList from "@/views/admin/FromTruckList.vue";

import PurchaseOrder from "@/views/admin/PurchaseOrder.vue";
import SaleOrderList from "@/views/admin/SaleOrderList.vue";
import ParkingRentalInvoice from "@/views/admin/ParkingRentalInvoice.vue";
import SupplierList from "@/views/admin/SupplierList.vue";
import ClientInvoiceList from "@/views/admin/ClientInvoiceList.vue";
import Laborslip from "@/views/admin/Laborslip.vue";
import SackSlip from "@/views/admin/SackSlip.vue";
import TruckGo from "@/externalservice/TruckGo.vue";
import SupplierRepaymentReport from "@/reports/SupplierRepaymentReport.vue";
import LaborCustomerRepaymentReport from "@/reports/LaborCustomerRepaymentReport.vue";
import SackCustomerRepaymentReport from "@/reports/SackCustomerRepaymentReport.vue";
import ExternalWeighingInvoice from "@/reports/ExternalWeighingInvoice.vue";
import TransactionTruck from "@/views/admin/TransactionTruck.vue";
import ServiceExternalInvoice from "@/views/admin/ServiceExternalInvoice.vue";
import PaymentRecord from "@/views/admin/PaymentRecord.vue";
import ExternalWeighingInvoiceReport from "@/reports/ExternalWeighingInvoiceReport.vue";
import TransactionInvoiceParkingRental from "@/views/admin/TransactionInvoiceParkingRental.vue";
import WeighingExspenceReport from "@/reports/WeighingExspenceReport.vue";

import { fetchUserByID } from "@/composables/checkPermissionRoute";
import PrintTruckTransaction from "@/prints/PrintTruckTransaction.vue";
import Signup from "@/views/superAdmin/Signup.vue";
import PrintBoatInPaking from "@/prints/PrintBoatInPaking.vue";
import SetUpTelegramToken from "@/views/admin/SetUpTelegramToken.vue";
// Add a store for user data
let userData = null

// Function to fetch user data once
async function initializeUserData() {
  if (!userData) {
    userData = await fetchUserByID();
  }
  return userData;
}

// Update the checkPermission function to handle nested permissions
function checkPermission(module_name, permission_key, sub_permission = 'view') {
  return async (to, from, next) => {
    const user = await initializeUserData();

    if (user.authUserRole === "Super Admin") {
      next();
      return;
    }

    const modulePermissions = user.permissions[module_name];

    if (!modulePermissions) {
      next('/');
      return;
    }

    // Handle nested permissions
    if (permission_key && modulePermissions[permission_key]) {
      if (typeof modulePermissions[permission_key] === 'object') {

        if (modulePermissions[permission_key][sub_permission]) {
          next();
          return;
        }
      } else {
        // For direct permissions
        if (modulePermissions[permission_key]) {
          next();
          return;
        }
      }
    }

    next('/');
  };
}




const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/dashboard",
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next();
      } else {
        next("/login");
      }
    },
    children: [
      {
        path: "/:pathMatch(.*)*",
        redirect: "/",
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
       
      },
      {
        path: "/client",
        name: "client",
        component: Client,
        beforeEnter: checkPermission("module_contact", "customer", "view")
      },
      {
        path: "/parking",
        name: "parking",
        component: Parking,
        beforeEnter: checkPermission("module_parking", "parking_list", "view")
      },
      {
        path: "/weighing",
        name: "weighing",
        component: Weighing,
        redirect: "/weighinglist",
        beforeEnter: checkPermission("module_weighing", "weight", "view")
      },
      {
        path: "/history", name: "history", component: StockHistory,
        beforeEnter: checkPermission("module_warehouse", "stock_history", "view")
      },
      { path: "/toparking", name: "toparking", component: ToParkingList, beforeEnter: checkPermission("module_weighing", "boat_storage", "view") },
      { path: "/formtruck", name: "formtruck", component: FromTruckList, beforeEnter: checkPermission("module_weighing", "truck_storage", "view") },
      {
        path: "/supplier", name: "supplier", component: Supplier,
        beforeEnter: checkPermission("module_contact", "supplier", "view")
      },
      { path: "/finance", name: "finance", component: FinancePage },
      {
        path: "/warehouse", name: "warehouse", component: Warehouse,
        beforeEnter: checkPermission("module_warehouse", "warehouse_list", "view")
      },
      {
        path: "/weighinglist",
        name: "weighinglist",
        component: WeighingList,
      },
      {
        path: "/customerorder",
        name: "ordering-book",
        component: OrderingBook,
      },
      {
        path: "/purchase-order",
        name: "purchase-order",
        component: PurchaseOrder,
        beforeEnter: checkPermission("module_ordering_book", "purchase_order", "view")
      },
      { path: "sale-order", name: "sale-order", component: SaleOrderList, beforeEnter: checkPermission("module_ordering_book", "customer_order", "view") },
      {
        path: "/wallet",
        name: "wallet",
        component: WalletList,
        beforeEnter: checkPermission("module_wallet", "partner", "view")
      },
      {
        path: "/walletTransaction",
        name: "wallet-transaction",
        component: WalletTransaction,
        beforeEnter: checkPermission("module_wallet", "transaction_list", "view")
      },
      {
        path: "/preLoan", name: "preLoan", component: PreLoan,
        beforeEnter: checkPermission("module_pre_loan", "pre_loan", "view")
      },
      {
        path: "/weighingservice",
        name: "weighingservice",
        component: ExternalServiceWeighing,
        beforeEnter: checkPermission("module_external_weighing", "weighing_external", "view")
      },
      {
        path: "/truckgo", name: "truckgo", component: TruckGo,
        beforeEnter: checkPermission("module_external_weighing", "truck_go_external", "view")
      },
      {
        path: "/ricestock",
        name: "ricestock",
        component: RiceStock,
        beforeEnter: checkPermission("module_warehouse", "rice_stock", "view")



      },
      {
        path: "/supplilerbilltransaction",
        name: "supplilerbilltransaction",
        component: BillTransaction,
        beforeEnter: checkPermission("module_transaction", "supplier_invoice", "view")
      },
      {
        path: "/invoicetransaction",
        name: "invoicetransaction",
        component: InvoiceTransactoin,
        beforeEnter: checkPermission("module_transaction", "customer_invoice", "view")
      },
      {
        path: "/laborsliptransaction",
        name: "laborsliptransaction",
        component: LaborSlipTransaction,
        beforeEnter: checkPermission("module_transaction", "labor_invoice", "view")
      },
      {
        path: "/sacksliptransaction",
        name: "sacksliptransaction",
        component: SackSlipTransaction,
        beforeEnter: checkPermission("module_transaction", "sack_invoice", "view")
      },
      { path: "/viewparking", name: "viewparking", component: ViewParkingCard, beforeEnter: checkPermission("module_parking", "parking_display", "view") },
      {
        path: "/plates", name: "plates", component: TruckList,
        beforeEnter: checkPermission("module_setting", "plate", "view")
      },
      // report
      {
        path: "/saleorderreport",
        name: "saleorderreport",
        component: SaleOrderReport,
        beforeEnter: checkPermission("module_report", "customer_order_report", "view")
      },
      {
        path: "/reportpurchaseorder",
        name: "reportpurchaseorder",
        component: ReportPurchaseOrder,
        beforeEnter: checkPermission("module_report", "purchase_order_report", "view")
      },
      {
        path: "/supplierinvoice",
        name: "supplierinvoice",
        component: SupplierBillReport,
        beforeEnter: checkPermission("module_report", "supplier_invoice", "view")

      },
      {
        path: "/customerinvoice",
        name: "customerinvoice",
        component: BillPaymentReport,
        beforeEnter: checkPermission("module_report", "customer_invoice", "view")
      },
      {
        path: "/saleinvoicereport",
        name: "saleinvoicereport",
        component: SaleInvoiceReport,
      },
      {
        path: "/invoicepaymentreport",
        name: "invoicepaymentreport",
        component: InvoicePaymentReport,
        beforeEnter: checkPermission("module_report", "customer_payment", "view")
      },
      {
        path: "/laborslipreport",
        name: "laborslipreport",
        component: LaborSlipReport,
        beforeEnter: checkPermission("module_report", "labor_invoice", "view")
      },
      {
        path: "/laborslippaymentreport",
        name: "laborslippaymentreport",
        component: LaborSlipPayment,
      },
      {
        path: "/sackllipreport",
        name: "sackllipreport",
        component: SackSlipReport,
        beforeEnter: checkPermission("module_report", "sack_invoice", "view")
      },
      {
        path: "/sackslippaymentreport",
        name: "sackslippaymentreport",
        component: SackSlipPayment,
      },
      {
        path: "/wallethistoryreport",
        name: "wallethistoryreport",
        component: WalletHistory,
        beforeEnter: checkPermission("module_report", "wallet_history", "view")
      },
      {
        path: "/wallettransactionreport",
        name: "wallettransactionreport",
        component: WalletTransactionReport,
        beforeEnter: checkPermission("module_report", "wallet_transaction", "view")
      },
      {
        path: "/preloanreport",
        name: "preloanreport",
        component: PreLoanReport,
        beforeEnter: checkPermission("module_report", "preloan_repayment", "view")
      },
      {
        path: '/weighingexspencereport',
        name: 'weighingexspencereport',
        component: WeighingExspenceReport,
        beforeEnter: checkPermission("module_report", "weighing_expense", "view")
      },
      {
        path: "/loanrepaymentreport",
        name: "loanrepaymentreport",
        component: LoanRepaymentReport,
      },
      {
        path: "/externalweighingslipreport",
        name: "externalweighingslipreport",
        component: ExternalWeighingSlipReport,
        beforeEnter: checkPermission("module_report", "external_weighing_expense", "view")
      },
      {
        path: "/externalweighingsinvoicereport",
        name: "externalweighingsinvoicereport",
        component: ExternalWeighingInvoiceReport,
        beforeEnter: checkPermission("module_report", "external_weighing_invoice", "view")
      },
      {
        path: "/externalweighingslippaymentreport",
        name: "externalweighingslippaymentreport",
        component: ExternalWeighingSlipPaymentReport,
      },
      {
        path: "/parkingrantal",
        name: "parkingrantal",
        component: RentalParkingReport,
        beforeEnter: checkPermission("module_report", "parking_rental", "view")
      },
      {
        path: "/parkingrentalpayment",
        name: "parkingrentalpayment",
        component: ParkingRentalPaymentReport,
        beforeEnter: checkPermission("module_report", "parking_rental_payment", "view")
      },
      {
        path: "/supplierrepaymentreport",
        name: "supplierrepaymentreport",
        component: SupplierRepaymentReport,
        beforeEnter: checkPermission("module_report", "supplier_payment", "view")
      },
      {
        path: "/laborcustomerrepaymentreport",
        name: "laborcustomerrepaymentreport",
        component: LaborCustomerRepaymentReport,
      },
      {
        path: "/sackcustomerrepaymentreport",
        name: "sackcustomerrepaymentreport",
        component: SackCustomerRepaymentReport,
      },
      {
        path: "/externalweighinginvoicereport",
        name: "externalweighinginvoicereport",
        component: ExternalWeighingInvoice,
        beforeEnter: checkPermission("module_report", "external_weighing_invoice", "view")
      },
      {
        path: "/serviceexternalinvoice",
        name: "serviceexternalinvoice",
        component: ServiceExternalInvoice,
        beforeEnter: checkPermission("module_external_weighing", "weighing_invoice_external", "view")
      },
      // settings
      {
        path: "/users", name: "users", component: User,
        beforeEnter: checkPermission("module_setting", "user", "view")
      },
      {
        path: "/setup-telegram-token",
        name: "setup-telegram-token",
        component:SetUpTelegramToken,
        beforeEnter: checkPermission("module_setting", "telegram_token", "view")
      },
      {
        path: "/ricetyesetting",
        name: "ricetyesetting",
        component: SettingRiceType,
        beforeEnter: checkPermission("module_setting", "rice", "view")
      },
      {
        path: "/parkingrentalinvoice",
        name: "parkingrentalinvoice",
        component: ParkingRentalInvoice,
        beforeEnter: checkPermission("module_transaction", "rental_parking_invoice", "view")
      },
      // External Service

      {
        path: "/paymentrecord",
        name: "paymentrecord",
        component: PaymentRecord,
        beforeEnter: checkPermission("module_finance", "customer_repayment_record", "view")
      },
      // transaction
      {
        path: "/rentalparking",
        name: "rentalparking",
        component: RentalParkingTransaction,
        beforeEnter: checkPermission("module_external_weighing", "weighing_external_rental_parking", "view")
      },
      {
        path: "/weighingtransaction",
        name: "weighingtransaction",
        component: WeighingTransaction,
      },
      {
        path: "/formtrucktransction",
        name: "formtrucktransction",
        component: FromeTruckTransition,
        beforeEnter: checkPermission("module_transaction", "truck_go", "view")
      },
      {
        path: "/toparkingtransaction",
        name: "toparkingtransaction",
        component: ToParkingTransition,
        beforeEnter: checkPermission("module_transaction", "on_boat", "view")
      },
      {
        path: "/transactioninvoiceparkingrental",
        name: "transactioninvoiceparkingrental",
        component: TransactionInvoiceParkingRental,
        beforeEnter: checkPermission("module_transaction", "rental_parking_invoice", "view")
      },
      // client
      {
        path: "/clientreypayment",
        name: "clientreypayment",
        component: ClientRepaymentList,
        beforeEnter: checkPermission("module_finance", "customer_payment", "view")
      },
      {
        path: "/supplierlist",
        name: "supplierlist",
        component: SupplierList,
        beforeEnter: checkPermission("module_finance", "supplier_invoice", "view")
      },
      {
        path: "/clientinvoicelist",
        name: "clientinvoicelist",
        component: ClientInvoiceList,
        beforeEnter: checkPermission("module_finance", "customer_invoice", "view")

      },
      {
        path: '/transactiontruck',
        name: 'transactiontruck',
        component: TransactionTruck,
        beforeEnter: checkPermission("module_external_weighing", "transaction_truck_external", "view")
      },
      {
        path: "/laborslip",
        name: "laborslip",
        component: Laborslip,
        beforeEnter: checkPermission("module_finance", "labor_invoice", "view")
      },
      {
        path: "/sackslip",
        name: "sackslip",
        component: SackSlip,
        beforeEnter: checkPermission("module_finance", "sack_invoice", "view")
      },
    ],
  },
  {
    path: "/printbill/:id",
    name: "printbill",
    component: PrintBill,
    props: true,
  },
  {
    path: "/printclientinvoice/:id",
    name: "printclientinvoice",
    component: PrintClientInvoice,
    props: true,
  },
  {
    path: "/printlabor/:id",
    name: "printlabor",
    component: PrintLabor,
    props: true,
  },
  {
    path: "/printsackslip/:id",
    name: "printsackslip",
    component: PrintSackSlip,
  },
  {
    path: "/printrantalparking/:id",
    name: "printrantalparking",
    component: PrintRentalParking,
    props: true,
  },

  {
    path: "/printweighingservice/:id",
    name: "printweighingservice",
    component: ExternalWeighingPrintInvoice,
  },
  {
    path: "/printtrucktransaction/:id",
    name: "printtrucktransaction",
    component: PrintTruckTransaction,
    props: true,
  },
  {
    path: "/printboat/:id",
    name: "printboat",
    component: PrintBoatInPaking,
    props: true,
  },
  {
    path: "/testView",
    name: "TestView",
    component: TestView,
  },
  {
    path: "/signup-superadmin",
    name: "signup-superadmin",
    component: Signup,
  },
  {
    path: "/resetPasswordView/:reset_token",
    name: "resetPassword",
    component: resetPasswordView,
  },
  {
    path: "/forgotPasswordView",
    name: "forgotPasswordView",
    component: forgotPasswordView,

  },

  {
    path: "/testWeighing",
    name: "weighingView",
    component: weighingView,
  },
  {
    path: "/telegram",
    name: "telegramView",
    component: telegramView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next("/");
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Reset userData when logging out
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    userData = null;
  }
  next();
});

export default router;
