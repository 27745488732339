<template>
  <form
    @submit.prevent="handleSubmit"
    class="w-[1200px] max-w-[1200px] h-auto bg-white text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">Generate Invoice</h2>
      <div @click="handleClose" class="closePopUp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>

    <div class="h-[600px] w-full overflow-auto">
      <div class="w-full overflow-auto">
        <div class="p-3">
          <div
            class="py-2 grid grid-cols-2 space-y-2 bg-white border p-2 rounded-md"
          >
            <div
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Supplier</h2>
              <h2>{{ suppliers.name }}</h2>
            </div>
            <div
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Created At</h2>
              <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
            </div>
            <div
              v-if="suppliers"
              class="w-[80%] flex items-center justify-between text-body text-primary6"
            >
              <h2>Phone Number</h2>
              <h2>
                {{
                  suppliers.phone_number ? suppliers.phone_number : "No Data"
                }}
              </h2>
            </div>
          </div>
        </div>
        <div class="p-3 space-y-3">
          <div
            v-for="(records, weighingId) in groupedByWeighingId"
            :key="weighingId"
            class="bg-white p-2 rounded-lg"
          >
            <div class="text-start" v-if="records.length > 0">
              <span>Plate Number: {{ records[0].rice[0].plate_number }}</span>
            </div>
            <div class="relative w-full overflow-auto mt-4 bg-white">
              <table class="border">
                <thead>
                  <tr class="header_table">
                    <td class="min-w-[70px] text-nowrap border-b text-start">
                      Date Time
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Rice Type
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Weight In
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Weight Out
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Cut Out
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Remain Weight
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Unit Cost
                    </td>
                    <td class="min-w-[100px] text-nowrap border-b text-start">
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="record in records"
                    :key="record.id"
                    class="text-body font-normal text-primary2 text-nowrap"
                  >
                    <td class="border-b">
                      {{ formatDate2(record.created_at) }}
                    </td>
                    <td class="border-b">{{ record.rice[0]?.rice_name }}</td>
                    <td class="border-b">
                      {{ numeralFormat(record.weight_in, "0,0[.]00") }} Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(record.weight_out, "0,0[.]00") }} Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(record.amount_cut_off, "0,0[.]00") }} Kg
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(record.weight_drop, "0,0[.]00") }}
                      Kg
                    </td>
                    <td class="border-b">
                      <InputNumber
                        v-model="record.unitCost"
                        placeholder="Unit Cost"
                        style="width: 250px"
                        suffix=" ៛"
                        :maxFractionDigits="2"
                        :invalid="isValidateUnitCost"
                        step="0.01"
                        class="h-2 flex items-center"
                      />
                    </td>
                    <td class="border-b">
                      {{ numeralFormat(calculateTotal(record), "0,0[.]00") }} ៛
                    </td>
                  </tr>
                  <!-- {{totalsByGroup}} -->
                  <tr>
                    <td colspan="2" class="font-semibold border-b">Totals</td>
                    <td class="border-b">
                      {{
                        numeralFormat(
                          totalsByGroup[weighingId].totalWeightIn,
                          "0,0[.]00"
                        )
                      }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{
                        numeralFormat(
                          totalsByGroup[weighingId].totalWeightOut,
                          "0,0[.]00"
                        )
                      }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{
                        numeralFormat(
                          totalsByGroup[weighingId].totalCutOut,
                          "0,0[.]00"
                        )
                      }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{
                        numeralFormat(
                          totalsByGroup[weighingId].totalRemainWeight,
                          "0,0[.]00"
                        )
                      }}
                      Kg
                    </td>
                    <td class="border-b">
                      {{
                        numeralFormat(
                          totalsByGroup[weighingId].totalOverall,
                          "0,0[.]00"
                        )
                      }}
                      ៛
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex gap-3 mt-4">
              <div class="border p-3 rounded space-y-4">
                <div class="flex flex-col space-y-2">
                  <label class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span> Weighing Expense
                  </label>
                  <InputNumber
                    placeholder="Amount"
                    style="width: 250px"
                    suffix=" ៛"
                    :invalid="isValidate_weighing_expense"
                    :maxFractionDigits="2"
                    step="0.01"
                    v-model="expenseByWeighingId[weighingId].weighing_expense"
                  />
                </div>
                <div class="flex flex-col space-y-2">
                  <label for="sack-expense" class="flex gap-1 text-nowrap">
                    <span class="text-red-500">*</span> Sack Expense
                  </label>
                  <InputNumber
                    placeholder="Amount"
                    style="width: 250px"
                    :invalid="isValidate_sack_expense"
                    suffix=" ៛"
                    :maxFractionDigits="2"
                    step="0.01"
                    v-model="expenseByWeighingId[weighingId].sack_expense"
                  />
                </div>
              </div>
              <div class="border rounded w-full p-2">
                <div class="py-2 px-2">
                  <h2 class="text-start">Additional Expense</h2>
                </div>
                <table class="border">
                  <thead>
                    <tr class="header_table">
                      <td>Type</td>
                      <td>Amount</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(expense, index) in expensesByGroup[weighingId]"
                      :key="index"
                    >
                      <td class="border-b border-t">
                        <input
                          placeholder="Type"
                          style="width: 250px"
                          v-model="expense.type"
                        />
                      </td>
                      <td class="border-b border-t">
                        <InputNumber
                          placeholder="Amount"
                          suffix=" ៛"
                          style="width: 250px"
                          :maxFractionDigits="2"
                          step="0.01"
                          v-model="expense.amount"
                        />
                      </td>
                      <td class="border-b border-t">
                        <div class="flex items-center justify-center">
                          <svg
                            v-if="expensesByGroup[weighingId].length > 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather cursor-pointer feather-trash-2 delete"
                            @click="removeExpense(weighingId, index)"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- Add More Row Button -->
                <div class="py-2 px-2">
                  <button
                    @click="addMoreRow(weighingId)"
                    type="button"
                    class="!bg-white text-primary2 px-4 py-2 rounded text-body"
                  >
                    <span>New</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-5 w-full space-y-2">
        <div class="p-5 w-full space-y-2 border rounded-md">
          <div class="flex items-center gap-14 justify-start">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Is Calculate to Pre-Loan
            </label>

            <label for="sent" class="flex items-center space-x-2">
              <input
                type="radio"
                name="false"
                v-model="cut_preloan"
                :value="true"
                class="form-radio text-primary3 cursor-pointer"
              />
              <span>Yes</span>
            </label>
            <label for="sent" class="flex items-center space-x-2">
              <input
                type="radio"
                name="false"
                v-model="cut_preloan"
                :value="false"
                class="form-radio text-primary3 cursor-pointer"
              />
              <span class="text-nowrap">Not Now</span>
            </label>
            <button
              v-if="showPreloanDue"
              type="button"
              class="px-2 py-1 bg-primary4 rounded-[30px] text-nowrap"
            >
              Pre-Loan Due:
              {{
                numeralFormat(
                  cut_preloan == true ? total_supplier_preloan : 0,
                  "0,0[.]00"
                )
              }}
              KHR
            </button>
          </div>
          <div class="flex items-center gap-14 justify-start">
            <label class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Send to Partner
            </label>
            <div
              v-for="partners in partner"
              :key="partners.id"
              class="flex flex-nowrap space-x-12 items-center"
            >
              <div class="flex items-center gap-3">
                <input
                  type="radio"
                  :id="`partner-${partners.id}`"
                  v-model="isPartner"
                  :value="partners.id"
                  class="form-radio text-primary3 cursor-pointer"
                />
                <label
                  :for="`partner-${partners.id}`"
                  class="text-nowrap cursor-pointer"
                >
                  {{ partners.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full p-5 space-y-4 flex items-end justify-between">
        <div class="flex w-full items-center gap-3">
          <button type="submit" :disabled="isPending">
            <span v-if="isPending" :class="{ is_pending: isPending }"></span>
            Save
          </button>
          <button
            class="btncancel"
            type="button"
            :disabled="isPendingSave"
            @click="handleToPrint"
          >
            <span
              v-if="isPendingSave"
              :class="{ is_pending_save: isPendingSave }"
            ></span>
            Save & Print
          </button>
          <button class="btncancel" type="button" @click="handleClose">
            Cancel
          </button>
        </div>
        <div
          class="flex flex-col w-full items-start gap-3 border p-3 rounded-md"
        >
          <div class="flex justify-between w-full">
            <h2 class="text-body font-semibold">Total Rice Cost</h2>
            <h2 class="text-body font-semibold">
              {{ numeralFormat(grandTotalOverall, "0,0[.]00") }} KHR
            </h2>
          </div>
          <div class="flex justify-between w-full">
            <h2 class="text-body font-semibold">Less</h2>
            <h2 class="text-body font-semibold">
              {{
                cut_preloan && grandTotalOverall > 0
                  ? numeralFormat(
                      grandTotalOverall - total_supplier_preloan,
                      "0,0[.]00"
                    )
                  : 0
              }}
              KHR
            </h2>
          </div>
          <div class="flex justify-between w-full text-sm">
            <h2>Preloan :</h2>
            <h2>
              {{
                numeralFormat(
                  cut_preloan == true ? total_supplier_preloan : 0,
                  "0,0[.]00"
                )
              }}
              KHR
            </h2>
            <!-- {{ suppliers }} -->
          </div>
          <div class="flex justify-between w-full text-sm">
            <h2>Weighing Expense :</h2>
            <h2>
              {{ numeralFormat(totalWeighingExpense, "0,0[.]00") }}
              KHR
            </h2>
          </div>
          <div class="flex justify-between w-full text-sm">
            <h2>Sack Expense :</h2>
            <h2>{{ numeralFormat(totalSack, "0,0[.]00") }} KHR</h2>
          </div>
          <div class="flex justify-between w-full text-sm">
            <h2>Additional Expense :</h2>
            <h2>
              {{ numeralFormat(grandTotalExpenses, "0,0[.]00") }}
              KHR
            </h2>
          </div>
          <div
            class="flex justify-between w-full text-heading2 font-semibold py-2 bg-primary3/10"
          >
            <h2 class="text-lg text-primary3 ml-4">Grand Total</h2>
            <h2 class="text-lg text-primary3 mr-4">
              {{
                numeralFormat(
                  grandTotalOverall -
                    (totalWeighingExpense + totalSack + grandTotalExpenses) -
                    (cut_preloan == true ? total_supplier_preloan : 0),
                  "0,0[.]00"
                )
              }}
              KHR
            </h2>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import { validateInputNUmber } from "@/composables/ValidateInput";
import axios from "axios";
import apirURL from "@/services/apiURL";
import {
  ref,
  onMounted,
  computed,
  watch,
  reactive,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import { fetchTimestamp } from "@/services/timestamp";
import { DMTelegram } from "@/composables/useDMTelegram";
import { decodeJwt } from "@/composables/decodeJWT";
import socket from "@/services/socket";
import { cutPreloan } from "@/composables/usePreloan";
import { calculateBalancePreloanSupplier } from "@/utils/calculateWallet";
import { useSupplierPayment } from "@/composables/usePartnerTransaction";

export default {
  props: ["datatoedit", "truckProp"],
  setup(props, { emit }) {
    const router = useRouter();

    const handleClose = () => {
      emit("close");
    };
    const isPartner = ref(null);

    watch(isPartner, (newVal) => {
      console.log("isPartner", newVal);
    });
    const expenseByWeighingId = ref({});

    const weighing_rice = ref([]);
    const weighingProp = ref([]);
    const weighingData = ref([]);
    const groupedByWeighingId = computed(() => {
      const grouped = {};
      weighingProp.value.forEach((record) => {
        if (!grouped[record.weighing_id]) {
          grouped[record.weighing_id] = [];
        }
        grouped[record.weighing_id].push(record);
      });
      return grouped;
    });
    const unitCost = ref(null);
    const isValidate_weighing_expense = ref(false);
    const isValidate_sack_expense = ref(false);
    // watch(
    //   () => Object.values(expenseByWeighingId.value),
    //   (newVal) => {
    //     isValidate_weighing_expense.value = newVal.some(
    //       (expense) =>
    //         expense.weighing_expense === 0 || expense.weighing_expense === null
    //     );

    //     isValidate_sack_expense.value = newVal.some(
    //       (expense) =>
    //         expense.sack_expense === 0 || expense.sack_expense === null
    //     );
    //   }
    // );

    const isPending = ref(false);
    const isPendingSave = ref(false);
    const weighing = ref([]);
    const fetchWeighing = async (id) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        return response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const suppliers = ref([]);
    const total_supplier_preloan = ref(null);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.datatoedit[0]?.supplier_id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data[0];
        total_supplier_preloan.value = null;

        total_supplier_preloan.value = calculateBalancePreloanSupplier(
          suppliers.value.wallet
        );
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const truck = ref([]);
    const fetchTruck = async () => {
      try {
        // Make sure to use the correct field names and endpoint
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.datatoedit.plate_number_id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Make sure the response contains the correct data structure
        truck.value = response.data || [];
      } catch (error) {
        console.error("Error fetching truck data:", error);
      }
    };

    const preloan = ref([]);
    const fetchPreloan = async () => {
      try {
        const req = {
          tableName: "preloans",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: props.datatoedit[0]?.supplier_id,
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        preloan.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const partner = ref([]);
    const fetchPartner = async () => {
      try {
        const req = {
          tableName: "partners",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        partner.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const expense = ref({
      weighing_expense: null,
      sack_expense: null,
    });

    const expensesByGroup = reactive({});

    // Method to add a new row to the expenses for a specific weighingId
    const addMoreRow = (weighingId) => {
      if (!expensesByGroup[weighingId]) {
        expensesByGroup[weighingId] = [];
      }
      expensesByGroup[weighingId].push({ type: "", amount: null });
    };

    // Method to remove a row from the expenses for a specific weighingId
    const removeExpense = (weighingId, index) => {
      if (
        expensesByGroup[weighingId] &&
        expensesByGroup[weighingId].length > 1
      ) {
        expensesByGroup[weighingId].splice(index, 1);
      }
    };

    const grandTotalExpenses = computed(() => {
      return Object.values(expensesByGroup).reduce((grandTotal, expenses) => {
        return (
          grandTotal +
          expenses.reduce(
            (sum, expense) => sum + parseFloat(expense.amount || 0),
            0
          )
        );
      }, 0);
    });

    const totalWeighingExpense = computed(() => {
      return Object.values(expenseByWeighingId.value).reduce(
        (sum, expense) => sum + parseFloat(expense.weighing_expense || 0),
        0
      );
    });
    const totalSack = computed(() => {
      return Object.values(expenseByWeighingId.value).reduce(
        (sum, expense) => sum + parseFloat(expense.sack_expense || 0),
        0
      );
    });
    const SackExpense = ref(null);
    const AmountPaid = ref(null);
    const cut_preloan = ref(false);
    const showPreloanDue = computed(() => {
      return cut_preloan.value && preloan.value.length > 0;
    });
    const calculateTotal = (record) => {
      // Parse the values and provide default values if they are not numbers
      const weight = parseFloat(record.weight_drop) || 0;
      const unitCost = parseFloat(record.unitCost) || 0;

      // Calculate the total
      const total = weight * unitCost;

      return total;
    };

    // Validate fields to ensure required data is present
    const validateFields = () => {
      const missingFields = [];

      // Validate records in groupedByWeighingId
      if (groupedByWeighingId.value) {
        Object.values(groupedByWeighingId.value)
          .flat()
          .forEach((record) => {
            if (record.unitCost === null || record.unitCost === undefined) {
              missingFields.push(`Unit Cost for record ID ${record.id}`);
            }
          });
      } else {
        console.error("groupedByWeighingId.value is undefined or null.");
      }

      // Validate expense fields
      if (expenseByWeighingId.value) {
        Object.keys(expenseByWeighingId.value).forEach((weighingId) => {
          const { weighing_expense, sack_expense } =
            expenseByWeighingId.value[weighingId];
          if (weighing_expense === null || weighing_expense === undefined) {
            missingFields.push(
              `Weighing Expense for plate number ${weighingId}`
            );
          }
          if (sack_expense === null || sack_expense === undefined) {
            missingFields.push(`Sack Expense for plate number ${weighingId}`);
          }
        });
      } else {
        console.error("expenseByWeighingId.value is undefined or null.");
      }

      // Validate additional expenses in expensesByGroup
      if (expensesByGroup) {
        Object.keys(expensesByGroup).forEach((weighingId) => {
          expensesByGroup[weighingId].forEach((expense, index) => {
            if (expense.amount === null || expense.amount === undefined) {
              missingFields.push(
                `Additional Expense Amount for plate number ${weighingId}, index ${index}`
              );
            }
            if (expense.type === null || expense.type === undefined) {
              missingFields.push(
                `Additional Expense Type for plate number ${weighingId}, index ${index}`
              );
            }
          });
        });
      } else {
        console.error("expensesByGroup is undefined or null.");
      }

      return {
        valid: missingFields.length === 0,
        missingFields,
      };
    };
    const authUserId = ref(null);
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchWeighing(),
        fetchSupplier(),
        fetchTruck(),
        fetchPreloan(),
        fetchRiceType(),
        fetchWeighingData(),
        fetchPartner(),
      ]);
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchWeighing(),
        fetchSupplier(),
        fetchTruck(),
        fetchPreloan(),
        fetchRiceType(),
        fetchWeighingData(),
        fetchPartner(),
      ]);

      socket.on("database_realTime", handleDatabaseUpdate);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      if (props.datatoedit) {
        weighing_rice.value = props.datatoedit;
        weighing_rice.value.forEach(async (data) => {
          const tempdata = await fetchWeighing(data.id);
          weighingProp.value.push(...tempdata);
        });
      }
    });
    const isValidateUnitCost = ref(false);
    watch(
      () => {
        return Object.values(groupedByWeighingId.value).flatMap((group) =>
          group.map((record) => record.unitCost)
        );
      },
      (newVal) => {
        isValidateUnitCost.value = newVal.includes(0) || newVal.includes(null);
      }
    );

    const handleSubmit = async () => {
      if (isValidateUnitCost.value) {
        console.log("isValidateUnitCost", isValidateUnitCost.value);
        return;
      }
      const expenseValues = Object.values(expenseByWeighingId.value);

      // Validate weighing_expense
      isValidate_weighing_expense.value = expenseValues.some(
        (expense) =>
          expense.weighing_expense === 0 || expense.weighing_expense === null
      );

      // Validate sack_expense
      isValidate_sack_expense.value = expenseValues.some(
        (expense) => expense.sack_expense === 0 || expense.sack_expense === null
      );

      // If there are validation errors, stop form submission or take action
      if (isValidate_weighing_expense.value || isValidate_sack_expense.value) {
        console.log("Validation failed");
        return;
      }
      isPending.value = false;
      try {
        const totalAmount =
          grandTotalOverall.value -
          (totalWeighingExpense.value +
            totalSack.value +
            grandTotalExpenses.value);

        isPending.value = true;
        let total_cut_preloan = 0;
        if (cut_preloan.value === true) {
          if (totalAmount >= total_supplier_preloan.value) {
            total_cut_preloan = total_supplier_preloan.value;
          } else if (totalAmount < total_supplier_preloan.value) {
            total_cut_preloan = totalAmount;
          }
        }

        const timestamp = await fetchTimestamp();

        // Collect all data into a single object before submission
        const totalWeights = Object.keys(groupedByWeighingId.value).reduce(
          (totals, weighingId) => {
            const records = groupedByWeighingId.value[weighingId];
            records.forEach((record) => {
              totals.weight_in += parseFloat(record.weight_in || 0);
              totals.weight_out += parseFloat(record.weight_out || 0);
              totals.amount_cut_off += parseFloat(record.amount_cut_off || 0);
              totals.remain +=
                parseFloat(record.weight_in || 0) -
                parseFloat(record.weight_out || 0) -
                parseFloat(record.amount_cut_off || 0);
            });
            return totals;
          },
          {
            weight_in: 0,
            weight_out: 0,
            amount_cut_off: 0,
            remain: 0,
          }
        );

        const req = {
          tableName: "supplier_invoice",
          fields: {
            supplier_id: props.datatoedit[0].supplier_id,
            rices: JSON.stringify(
              Object.keys(groupedByWeighingId.value)
                .map((weighingId) => {
                  return groupedByWeighingId.value[weighingId].map(
                    (record) => ({
                      weighing_id: weighingId,
                      rice: record.rice_id,
                      total_weight_in: totalWeights.weight_in,
                      total_weight_out: totalWeights.weight_out,
                      total_cut_out: totalWeights.amount_cut_off,

                      unit_cost: record.unitCost || 0,
                      weight_in: record.weight_in,
                      weight_out: record.weight_out,
                      amount_cut_off: record.amount_cut_off,
                      weight_drop: record.weight_drop,
                      total_cost_price: calculateTotal(record),
                      plate_number: record.rice[0]?.plate_number,
                    })
                  );
                })
                .flat()
            ),

            weighing: JSON.stringify(
              props.datatoedit.map((currentData, index) => ({
                weighing_id: currentData.id,
                sack_expense:
                  expenseByWeighingId.value[currentData.id]?.sack_expense || 0,
                weighing_expense:
                  expenseByWeighingId.value[currentData.id]?.weighing_expense ||
                  0,
                preloan_amount: total_cut_preloan > 0 ? total_cut_preloan : 0,
                total_weighing_expense: totalWeighingExpense.value,
                total_sack_expense: totalSack.value,
                plate_number: props.datatoedit[0].plate_number,
              }))
            ),
            additional_expense: JSON.stringify(
              props.datatoedit.map((currentData) => ({
                expenses:
                  expensesByGroup[currentData.id]?.map((row) => ({
                    weighing_id: currentData.id,
                    amount: row.amount,
                    title: row.type,
                  })) || [],
              }))
            ),

            weigh_type: "kilo",
            created_at: timestamp,
            created_by: authUserId.value,
            amount_paid:
              cut_preloan.value === true
                ? Number(amountPaid.value - total_supplier_preloan.value)
                : Number(amountPaid.value),
            cut_preloan: cut_preloan.value,
            partner_id: isPartner.value,
            total_amount:
              totalAmount - cut_preloan.value
                ? total_supplier_preloan.value
                : 0,
            is_sack_slip: false,
            is_labor_invoice: false,
            status: "paid",
            weighing_id: [props.datatoedit[0].id],
          },
        };

        console.log("req", req);

        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          req
        );

        if (response.data) {
          const Message = `បើកលុយ : ${
            grandTotalOverall.value +
            totalWeighingExpense.value +
            totalSack.value +
            grandTotalExpenses.value
          } ៛`;

          if (cut_preloan.value) {
            await cutPreloan(
              req.fields,
              response.data.insertedId,
              amountPaid.value
            );
          }

          if (isPartner.value) {
            await DMTelegram(isPartner.value, Message);
            useSupplierPayment(req.fields, response.data.insertedId, "add");
          }

          try {
            const req_invoice_update = {
              tableName: "weighing",
              fields: {
                is_supplier_invoice: true,
              },
            };

            const weighing_ids = props.datatoedit
              .map((record) => record.id)
              .filter((id, index, array) => array.indexOf(id) === index);

            weighing_ids.forEach(async (d) => {
              try {
                await axios.patch(
                  `${apirURL}/weighing/api/updateData/${d}`,
                  req_invoice_update
                );
                console.log(`Weighing entry with ID ${d} updated successfully`);
              } catch (error) {
                console.error(`Failed to update weighing ID: ${d}`, error);
              }
            });

            const insertTo_partner_history = {
              tableName: "partner_transaction_history",
              fields: {
                supplier_invoice: response.data.insertedId,
                partner_id: isPartner.value,
                created_at: timestamp,
                created_by: authUserId.value,
                transaction_type: "supplier payment",
                wallet: "KHR",
                amount: amountPaid.value,
              },
            };
            await axios.post(
              `${apirURL}/weighing/api/insertData`,
              insertTo_partner_history
            );
          } catch (error) {
            console.error("Error while updating weighing table:", error);
          }
        }
        handleClose();
        emit("toast", "create");
      } catch (error) {
        console.error("Error while posting data:", error);
      }
    };

    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };

    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighingData.value = response.data;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    onBeforeUnmount(() => {
      socket.off("database_realTime");
    });

    const getWeighing = (id) => {
      const findWeighing = weighingProp.value.find(
        (data) => data.weighing_id === id
      );
      return findWeighing ? findWeighing : null;
    };

    watch(weighingProp, () => {
      props.datatoedit.forEach((data1) => {
        weighingProp.value.forEach((data2) => {
          const findWeighing = weighingProp.value.find(
            (data) => data.weighing_id === id
          );
        });
      });
    });

    const totalWeightIn = computed(() =>
      Object.values(groupedByWeighingId.value)
        .flat()
        .reduce((sum, item) => sum + parseFloat(item.weight_in || 0), 0)
    );

    const totalCutOut = computed(() =>
      Object.values(groupedByWeighingId.value)
        .flat()
        .reduce((sum, item) => sum + parseFloat(item.amount_cut_off || 0), 0)
    );

    const totalWeightOut = computed(() =>
      Object.values(groupedByWeighingId.value)
        .flat()
        .reduce((sum, item) => sum + parseFloat(item.weight_out || 0), 0)
    );

    const totalRemainWeight = computed(() =>
      Object.values(groupedByWeighingId.value)
        .flat()
        .reduce(
          (sum, item) =>
            sum +
            (parseFloat(item.weight_in || 0) -
              parseFloat(item.amount_cut_off || 0)),
          0
        )
    );

    const totalOverall = computed(() => {
      return Object.values(groupedByWeighingId.value)
        .flat()
        .reduce((sum, item) => sum + calculateTotal(item), 0);
    });
    const grandTotalOverall = computed(() => {
      const totalOverallSum = Object.values(totalsByGroup.value).reduce(
        (sum, groupTotals) => sum + groupTotals.totalOverall,
        0
      );

      return totalOverallSum;
    });

    const preloanAmount = total_supplier_preloan.value;

    const amountPaid = computed(() => {
      return (
        grandTotalOverall.value -
        (totalWeighingExpense.value +
          totalSack.value +
          grandTotalExpenses.value)
      );
    });

    const totalsByGroup = computed(() => {
      const result = {};

      Object.keys(groupedByWeighingId.value).forEach((weighingId) => {
        const recordsInGroup = groupedByWeighingId.value[weighingId];

        // Calculate totals for the group
        const totalWeightIn = recordsInGroup.reduce(
          (sum, item) => sum + parseFloat(item.weight_in || 0),
          0
        );
        const totalWeightOut = recordsInGroup.reduce(
          (sum, item) => sum + parseFloat(item.weight_out || 0),
          0
        );
        const totalCutOut = recordsInGroup.reduce(
          (sum, item) => sum + parseFloat(item.amount_cut_off || 0),
          0
        );
        const totalRemainWeight = recordsInGroup.reduce(
          (sum, item) => sum + parseFloat(item.weight_drop || 0),
          0
        );
        const totalOverall = recordsInGroup.reduce((sum, item) => {
          const weight = parseFloat(item.weight_drop || 0);
          const unitCost = parseFloat(item.unitCost || 0);
          return sum + weight * unitCost;
        }, 0);

        // Store totals for the group
        result[weighingId] = {
          totalWeightIn,
          totalWeightOut,
          totalCutOut,
          totalRemainWeight,
          totalOverall,
        };
      });

      return result;
    });

    watch(
      groupedByWeighingId,
      (newValue) => {
        Object.keys(newValue).forEach((weighingId) => {
          if (!expenseByWeighingId.value[weighingId]) {
            expenseByWeighingId.value[weighingId] = {
              weighing_expense: null,
              sack_expense: null,
            };
          }
        });
      },
      { deep: true }
    );

    const result = grandTotalOverall.value - total_supplier_preloan.value;
    const handleToPrint = async () => {
      if (isValidateUnitCost.value) {
        console.log("isValidateUnitCost", isValidateUnitCost.value);
        return;
      }
      const expenseValues = Object.values(expenseByWeighingId.value);

      // Validate weighing_expense
      isValidate_weighing_expense.value = expenseValues.some(
        (expense) =>
          expense.weighing_expense === 0 || expense.weighing_expense === null
      );

      // Validate sack_expense
      isValidate_sack_expense.value = expenseValues.some(
        (expense) => expense.sack_expense === 0 || expense.sack_expense === null
      );

      // If there are validation errors, stop form submission or take action
      if (isValidate_weighing_expense.value || isValidate_sack_expense.value) {
        console.log("Validation failed");
        return;
      }
      isPendingSave.value = true;
      try {
        // Collect all data into a single object before submission
        const timestamp = await fetchTimestamp();
        const totalWeights = Object.keys(groupedByWeighingId.value).reduce(
          (totals, weighingId) => {
            const records = groupedByWeighingId.value[weighingId];
            records.forEach((record) => {
              totals.weight_in += parseFloat(record.weight_in || 0);
              totals.weight_out += parseFloat(record.weight_out || 0);
              totals.amount_cut_off += parseFloat(record.amount_cut_off || 0);
              totals.remain +=
                parseFloat(record.weight_in || 0) -
                parseFloat(record.weight_out || 0) -
                parseFloat(record.amount_cut_off || 0);
            });
            return totals;
          },
          {
            weight_in: 0,
            weight_out: 0,
            amount_cut_off: 0,
            remain: 0,
          }
        );

        const requestBody = {
          tableName: "supplier_invoice",
          fields: {
            // Consolidate all supplier IDs if needed (e.g., pick from the first entry)
            supplier_id: props.datatoedit[0].supplier_id,
            rices: JSON.stringify(
              Object.keys(groupedByWeighingId.value)
                .map((weighingId) => {
                  return groupedByWeighingId.value[weighingId].map(
                    (record) => ({
                      weighing_id: weighingId,
                      rice: record.rice_id,
                      total_weight_in: totalWeights.weight_in,
                      total_weight_out: totalWeights.weight_out,
                      total_cut_out: totalWeights.amount_cut_off,

                      unit_cost: record.unitCost || 0,
                      weight_in: record.weight_in,
                      weight_out: record.weight_out,
                      amount_cut_off: record.amount_cut_off,
                      weight_drop: record.weight_drop,
                      total_cost_price: calculateTotal(record),
                      plate_number: record.rice[0]?.plate_number,
                    })
                  );
                })
                .flat()
            ),

            weighing: JSON.stringify(
              props.datatoedit.map((currentData, index) => ({
                weighing_id: currentData.id, // Use currentData.id instead of props.datatoedit.id
                sack_expense:
                  expenseByWeighingId.value[currentData.id]?.sack_expense || 0,
                weighing_expense:
                  expenseByWeighingId.value[currentData.id]?.weighing_expense ||
                  0,
                preloan_amount: preloan.value[0]?.amount
                  ? preloan.value[0]?.amount
                  : 0,
                total_weighing_expense: totalWeighingExpense.value,
                total_sack_expense: totalSack.value,
                plate_number: props.datatoedit[0].plate_number,
              }))
            ),
            additional_expense: JSON.stringify(
              props.datatoedit.map((currentData) => ({
                expenses:
                  expensesByGroup[currentData.id]?.map((row) => ({
                    weighing_id: currentData.id,
                    amount: row.amount,
                    title: row.type,
                  })) || [],
              }))
            ),

            weigh_type: "kilo",
            created_at: timestamp,
            amount_paid:
              cut_preloan.value === true
                ? Number(amountPaid.value - total_supplier_preloan.value)
                : Number(amountPaid.value),
            cut_preloan: cut_preloan.value,
            partner_id: isPartner.value,
            created_by: authUserId.value,
            total_amount:
              grandTotalOverall.value +
              totalWeighingExpense.value +
              totalSack.value +
              grandTotalExpenses.value -
              cut_preloan
                ? amountPaid.value
                : amountPaid.value - total_supplier_preloan.value,
            is_sack_slip: false,
            is_labor_invoice: false,
            status: "paid",
            weighing_id: [props.datatoedit[0].id],
          },
        };

        // Make a single post request with the consolidated data
        const response = await axios.post(
          `${apirURL}/weighing/api/insertData`,
          requestBody
        );

        if (response.data) {
          const Message = `បើកលុយ : ${
            amountPaid.value - total_supplier_preloan.value
          } ៛`;

          if (cut_preloan.value === true) {
            await cutPreloan(
              requestBody.fields,
              response.data.insertedId,
              amountPaid.value
            );
          }

          if (isPartner.value) {
            await DMTelegram(isPartner.value, Message);
            useSupplierPayment(
              requestBody.fields,
              response.data.insertedId,
              "add"
            );
          }
          const req_invoice_update = {
            tableName: "weighing",
            fields: {
              is_supplier_invoice: true,
            },
          };

          const weighing_ids = props.datatoedit
            .map((record) => record.id)
            .filter((id, index, array) => array.indexOf(id) === index);

          weighing_ids.forEach(async (d) => {
            try {
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${d}`,
                req_invoice_update
              );
              console.log(`Weighing entry with ID ${d} updated successfully`);
            } catch (error) {
              console.error(`Failed to update weighing ID: ${d}`, error);
            }
          });
          router.push({
            name: "printbill",
            params: { id: response.data.insertedId },
          });

          const insertTo_partner_history = {
            tableName: "partner_transaction_history",
            fields: {
              supplier_invoice: response.data.insertedId,
              partner_id: isPartner.value,
              created_at: timestamp,
              created_by: authUserId.value,
              transaction_type: "supplier payment",
              wallet: "KHR",
              amount: amountPaid.value,
            },
          };
          const insert_history = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            insertTo_partner_history
          );
          console.log("insert_history", insert_history.data);
        }
      } catch (error) {
        console.error("Error while posting data:", error); // Log error if any
      }
    };

    return {
      handleClose,
      handleToPrint,
      validateInputNUmber,
      weighing,
      unitCost,
      calculateTotal,
      formatDate2,
      totalWeightIn,
      totalOverall,
      totalRemainWeight,
      suppliers,
      totalCutOut,
      expensesByGroup,
      addMoreRow,
      removeExpense,
      preloan,
      getRice,
      totalWeightOut,
      AmountPaid,
      SackExpense,
      handleSubmit,
      weighingProp,
      getWeighing,
      groupedByWeighingId,
      totalsByGroup,
      expense,
      expenseByWeighingId,
      grandTotalOverall,
      totalWeighingExpense,
      totalSack,
      partner,
      cut_preloan,
      showPreloanDue,
      grandTotalExpenses,
      isPartner,
      result,
      total_supplier_preloan,
      isPending,
      isPendingSave,
      isValidateUnitCost,
      isValidate_weighing_expense,
      isValidate_sack_expense,
    };
  },
};
</script>

<style></style>
