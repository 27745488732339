<template>
  <!--  from trucl transition -->
  <div class="w-full">
    <div>
      <div class="relative overflow-x-auto border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[95px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>No</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Parking</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Boat</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Weight</h2>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-if="boat.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in boat"
              :key="data"
            >
              <td class="border-b">{{ data.weighing_rice_id }}</td>
              <td class="border-b">{{ data.parking_name }}</td>
              <td class="border-b">{{ data.baot_name }}</td>
              <td class="border-b">
                {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="4">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import apirURL from "@/services/apiURL";
import socket from "@/services/socket";
import axios from "axios";
import moment from "moment-timezone";
import { onMounted, ref } from "vue";
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: ["datatoedit"],
  components: { Pagination, NoDataComponet },
  setup(props, { emit }) {
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("boat_enter_parking");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");

    const boat = ref([]);
    const fetchBoat = async () => {
      try {
        const req = {
          tableName: table.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnAliases: JSON.stringify({
            weighing_rice_id: { table: "weighing_rice_history", column: "id" },
            parking_name: { table: "parking_rice", column: "name" },
            baot_name: {
              table: table.value,
              column: "name",
            },
          }),
          sortColumn: "weighing_rice_id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "weighing_rice_history",
              joinColumnChild: "id",
              joinColumnParent: "boat_enter_parking_id",
              joinType: "LEFT JOIN",
            },
            {
              joinTableName: "parking_rice",
              joinColumnChild: "parking_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "customer_id",
              operator: "=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: "weighing_rice_history",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "joinTable",
              joinTableName: "weighing_rice_history",
            },
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          {
            params: req,
          }
        );
        boat.value = response.data.data;
        console.log("boat", boat.value);
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Error fetching boat:", error);
      }
    };

    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
    };

    onMounted(async () => {
      await Promise.allSettled([fetchBoat()]);
      if (props.datatoedit) {
        console.log("customer_id", props.datatoedit.id, boat.value[0]?.id);
      }
    });
    const sortClient = (field, sortDirection) => {
      // if (field === "amount") {
      //   boat.value.sort((a, b) => {
      //     const amountA = a.type_of_rice?.[0]?.amount || 0;
      //     const amountB = b.type_of_rice?.[0]?.amount || 0;

      //     if (sortDirection === "asc") {
      //       return amountA - amountB;
      //     } else {
      //       return amountB - amountA;
      //     }
      //   });
      // } else
      if (field === "baot_name") {
        toggleSort(sortDirection, boat.value, field);
      } else if (field === "id") {
        toggleSort(sortDirection, boat.value, field);
      } else if (field === "name") {
        toggleSort(sortDirection, boat.value, field);
      } else if (field === "weight_out") {
        toggleSort(sortDirection, boat.value, field);
      }
    };
    return {
      boat,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      sortClient,
    };
  },
};
</script>

<style scoped></style>
