import apirURL from "@/services/apiURL";
import axios from "axios";

const fetchPartnerByID = async (id) => {
    try {
      let apiUrl = `${apirURL}/weighing/api/getAllData`;
      const params = {
        tableName: "partners",
        sortColumn: "id",
        dynamicConditions: JSON.stringify([
          {
            field: "id",
            operator: "=",
            value: id,
            typeTable: "table",
          },
        ]),
      };
  
      const response = await axios.get(apiUrl, { params });
      return response.data[0]?.telegram_id
    } catch (error) {
      console.error("Error fetching supplier data:", error);
      return null;
    }
  };

export const DMTelegram=async(partner_id,Message)=>{
    try{
            
    const [telegram_id] = await Promise.all([
        fetchPartnerByID(partner_id),
      ]);

   

    await axios.post(`${apirURL}/weighing/api/messageTelegram/${telegram_id}`, {
        message: `${Message}`,
      }) .then((response) => {
        console.log("Message sent successfully:", response.data);
      })
      .catch((error) => {
        console.error("There was a problem sending the message:", error);
      });

    

    }catch(err){
        console.log("failed to send message",err)
    }
}