<template>
  <div class="">
    <!-- <div class="flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'supplierbil',
        }"
        @click="setActiveTab('supplierbil')"
      >
        External Weighing
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'clinentinvoice',
        }"
        @click="setActiveTab('clinentinvoice')"
      >
        Truck
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'laborslip',
        }"
        @click="setActiveTab('laborslip')"
      >
        Slip
      </div>
    </div> -->
    <!-- Content based on activeTab -->
    <div v-if="activeTab === 'supplierbil'">
      <WeighingService />
    </div>
    <div v-else-if="activeTab === 'clinentinvoice'">
      <Truck />
    </div>
    <div v-else-if="activeTab === 'laborslip'">
      <Slip />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import WeighingService from "./WeighingService.vue";
import Slip from "./Slip.vue";
import Truck from "./Truck.vue";
export default {
  components: {
    WeighingService,
    Slip,
    Truck,
  },
  setup() {
    const activeTab = ref("supplierbil");
    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style></style>
