<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="w-1/2">
      <div class="flex w-full justify-between">
        <div>
          <button @click="$router.go(-1)" class="btncancel hidden-print">
            Back
          </button>
        </div>
        <!-- {{ invoice }} -->
        <div>
          <button @click="handlePrint" class="hidden-print">Print</button>
        </div>
      </div>
      <div class="w-full mt-4 bg-white p-8 print-header" id="printTable">
        <div class="flex justify-between items-start">
          <div class="w-[65px] h-[65px] border rounded-full"></div>
          <div class="text-center">
            <h2 class="text-heading2 font-bold">Client Invoice</h2>
            <h2 class="text-[16px] font-bold">Weighing</h2>
            <h2 class="py-2 text-[12px]">011 111 111 / 012 123 456</h2>
          </div>
          <div class="text-primary5 text-[10px] text-end">
            <h2>Str#1 ,#104</h2>
            <h2>Battambang</h2>
            <h2>Battambang city</h2>
            <h2>Cambodia</h2>
          </div>
        </div>
        <div
          class="text-[10px] text-primary5 grid grid-cols-2 w-full items-center text-start gap-x-4"
        >
          <div class="flex w-full items-center justify-between">
            <h2>Client</h2>
            <h2>{{ getCustomerName(invoice[0]?.customer_id) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Bill No.</h2>
            <h2>SL-2024 {{ invoice[0]?.id }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Phone Number</h2>
            <h2>{{ getCustomerPhone(invoice[0]?.customer_id) }}</h2>
          </div>
          <div class="flex w-full items-center justify-between">
            <h2>Date Time</h2>
            <h2>{{ formatDate2(invoice[0]?.created_at) }}</h2>
          </div>
        </div>
        <div class="py-2">
          <h2>Rice Detail</h2>
          <div
            class="relative mt-4 bg-white text-[10px]"
            v-for="invoics in invoice"
          >
            <table>
              <tbody>
                <tr class="header_table">
                  <td class="min-w-[70px] text-nowrap border text-start">No</td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Rice Type
                  </td>

                  <td class="min-w-[100px] text-nowrap border text-start">
                    Remain kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Unit Cost (VND)
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total (VND)
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr
                  class="text-[10px] font-normal text-primary2 text-nowrap"
                  v-for="(data, index) in invoics.weighings"
                  :key="index"
                >
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ index + 1 }}
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    <p v-for="rice in data.rice">
                      {{ rice.rice_name }}
                    </p>
                  </td>

                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(data.unitCost, "0,0[.]00") }} ₫
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(totalUnitCost, "0,0[.]00") }}
                    ₫
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="font-bold bg-default">
                  <td class="min-w-[100px] text-nowrap border text-start"></td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    Total
                  </td>

                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(totalWeightDrop, "0,0[.]00") }} Kg
                  </td>
                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(UnitCost, "0,0[.]00") }} ₫
                  </td>

                  <td class="min-w-[100px] text-nowrap border text-start">
                    {{ numeralFormat(totalUnitCost, "0,0[.]00") }} ₫
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="py-2 w-full flex items-center justify-end text-[10px]">
          <div class="w-[60%] flex items-center justify-between">
            <div class="w-1/2 flex justify-end gap-4">
              <h2>Paid</h2>
            </div>
            <div class="w-1/2 flex justify-end gap-4">
              <h2>{{ numeralFormat(totalUnitCost, "0,0[.]00") }} ₫</h2>
            </div>
          </div>
        </div>
        <div class="w-full flex items-center justify-end">
          <div class="w-[60%] flex items-center justify-between text-[10px]">
            <div class="w-1/2 flex justify-end gap-4">
              <h2>Due</h2>
            </div>
            <div class="w-1/2 flex justify-end gap-4">
              <h2>---</h2>
            </div>
          </div>
        </div>

        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[90px]"
        >
          <h2 class="text-primary5 text-[10px]">Accepted</h2>
          <h2 class="text-primary5 text-[10px]">Issued</h2>
        </div>
        <div
          class="py-2 w-1/2 mx-auto flex items-center justify-between gap-3 h-[90px]"
        >
          <h2 class="text-primary5 text-[10px]">
            {{ getUserName(invoice[0]?.user_id) }}
          </h2>
          <h2 class="text-primary5 text-[10px]">
            {{ getCustomerName(invoice[0]?.customer_id) }}
          </h2>
        </div>
      </div>
    </div>
    <!-- {{ invoice }} -->
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, computed } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  setup() {
    const route = useRoute();
    const handlePrint = () => {
      window.print();
    };
    console.log("route", route.params.id);
    const invoice = ref([]);

    // Function to fetch invoice data
    const fetchInvoiceData = async () => {
      try {
        const req = {
          tableName: "customer_invoice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: Number(route.params.id),
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        invoice.value = response.data;
        console.log("Invoice data:", invoice.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const totalUnitCost = computed(() => {
      // Assuming each invoice has a 'weighings' array directly
      const weighings = invoice.value?.[0]?.weighings || [];
      return weighings.reduce((total, weighing) => {
        const weightDrop = parseFloat(weighing.weight_drop || 0);
        const unitCost = parseFloat(weighing.unitCost || 0);
        const totalCost = weightDrop * unitCost;
        return total + totalCost;
      }, 0);
    });

    const totalWeightDrop = computed(() => {
      // Assuming each invoice has a 'rices' array directly
      const weighings = invoice.value?.[0]?.weighings || [];
      return weighings.reduce((total, weighing) => {
        const weightDrop = parseFloat(weighing.weight_drop || 0);
        return total + weightDrop;
      }, 0);
    });

    const UnitCost = computed(() => {
      // Assuming each invoice has a 'rices' array directly
      const weighings = invoice.value?.[0]?.weighings || [];
      return weighings.reduce((total, weighing) => {
        const unitCost = parseFloat(weighing.unitCost || 0);
        return total + unitCost;
      }, 0);
    });

    const getCustomerName = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getCustomerPhone = (id) => {
      const name = customers.value.find((item) => item?.id === id);
      return name?.phone_number;
    };
    const customers = ref([]);
    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "weighing_id",
            //   operator: "=",
            //   value: props.datatoedit[0].id,
            // },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        customers.value = response.data;
        console.log("customers", customers.value);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };

    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rices.value = response.data;
        console.log("rices data:", rices.value);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const users = ref([]);
    const fetchUser = async () => {
      try {
        const req = {
          tableName: "users",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        users.value = response.data;
        console.log("plate data:", users.value);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };

    const getUserName = (id) => {
      const name = users.value.find((item) => item?.id === id);
      return name?.profile_name;
    };

    onMounted(() => {
      fetchInvoiceData();
      fetchCustomer();
      fetchRiceType();
      fetchUser();
    });

    return {
      handlePrint,
      invoice,
      totalUnitCost,
      totalWeightDrop,
      UnitCost,
      formatDate2,
      getCustomerName,
      getCustomerPhone,
      getRice,
      getUserName,
    };
  },
};
</script>

<style lang="scss" scoped></style>
