import apirURL from "@/services/apiURL";
import axios from "axios";



const fetchStockRiceTransaction = async (stockId, warehouse_id, rice_name) => {
    try {
        const params = {
            tableName: "stock_rice_transaction_history",
            sortColumn: "id",
            dynamicConditions: JSON.stringify([
                {
                    field: "id",
                    operator: "!=",
                    value: stockId,
                    typeTable: "table",
                },

                {
                    field: "warehouse_id",
                    operator: "=",
                    value: warehouse_id,
                    typeTable: "table",
                },


                {
                    field: "rice",
                    operator: "->>",
                    key: "rice_name",
                    value: rice_name,
                    type: "jsonb",
                    typeTable: "table",
                },

                {
                    field: "rice",
                    operator: "->>",
                    key: "transaction_id",
                    value: null,
                    type: "jsonb",
                    typeTable: "table",
                },


            ]),
        };

        const response = await axios.get(
            `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
            {
                params,
            }
        );

        if (response.data.length > 0) {
            return response.data;
        } else {
            return [];
        }


    } catch (error) {
        console.error("Error fetching  data:", error);
        return [];
    }
};


export const useWareHouseStockRiceTransaction = async (formData, type) => {
    try {

        if (type === 'sell') {
            const rice = JSON.parse(formData.rice);
            const requestBody = {
                tableName: "stock_rice_transaction_history",
                fields: {
                    warehouse_id: formData.warehouse_id,
                    created_at: formData.created_at,
                    weighing_id: formData.weighing_id,
                    transaction_type: "sell",
                    created_by: formData.created_by,
                    weight: formData.weight_in,
                    rice: JSON.stringify(
                        {
                            weight: null,
                            original_weight: formData.totalRiceStock,
                            weight_adjust: null,
                            after_adjust_weight: null,
                            rice_name: rice[0]?.rice_name,
                            type: "sell",
                            weight_sell: formData.currentWeight,
                            from_weight: null,
                            to_weight: null,
                            final_weight: formData.totalRiceStock - formData.currentWeight,
                            transaction_id: null,
                        },
                    ),
                },
            };

            const response = await axios.post(`${apirURL}/weighing/api/insertData`, requestBody);

            if (response.data.message) {
                const data = await fetchStockRiceTransaction(response.data.insertedId, formData.warehouse_id, rice[0]?.rice_name);

                console.log(data, "data");
                data.forEach(async (item) => {
                    const updateRequestBody = {
                        tableName: "stock_rice_transaction_history",
                        fields: {
                            rice: JSON.stringify({
                                ...item.rice,
                                transaction_id: response.data.insertedId
                            })
                        }
                    }
                    await axios.patch(`${apirURL}/weighing/api/updateData/${item.id}`, updateRequestBody);
                })
            }
        } else if (type === 'convert') {

            const rice = JSON.parse(formData.fields.rice);

            // insert new rice convert transaction first
            const response = await axios.post(`${apirURL}/weighing/api/insertData`, formData);

            if (response.data.message) {
                // update rice transaction history old
                const data = await fetchStockRiceTransaction(response.data.insertedId, formData.fields.warehouse_id, rice.rice_name);
                console.log(data, "data");
                data.forEach(async (item) => {
                    const updateRequestBody = {
                        tableName: "stock_rice_transaction_history",
                        fields: {
                            rice: JSON.stringify({
                                ...item.rice,
                                transaction_id: response.data.insertedId
                            })
                        }
                    }
                    await axios.patch(`${apirURL}/weighing/api/updateData/${item.id}`, updateRequestBody);
                })

            }


        } else if (type === 'adjust') {
            const rice = JSON.parse(formData.fields.rice);

            // insert new rice convert transaction first
            const response = await axios.post(`${apirURL}/weighing/api/insertData`, formData);

            if (response.data.message) {
                // update rice transaction history old
                const data = await fetchStockRiceTransaction(response.data.insertedId, formData.fields.warehouse_id, rice.rice_name);
                console.log(data, "data");
                data.forEach(async (item) => {
                    const updateRequestBody = {
                        tableName: "stock_rice_transaction_history",
                        fields: {
                            rice: JSON.stringify({
                                ...item.rice,
                                transaction_id: response.data.insertedId
                            })
                        }
                    }
                    await axios.patch(`${apirURL}/weighing/api/updateData/${item.id}`, updateRequestBody);
                })

            }

        }

    } catch (error) {
        console.error("error submit data:", error);
        return [];
    }
}



const fetchAssociateStockRiceTransaction = async (id, warehouse_id) => {
    try {
        const params = {
            tableName: "stock_rice_transaction_history",
            sortColumn: "id",
            dynamicConditions: JSON.stringify([

                {
                    field: "warehouse_id",
                    operator: "=",
                    value: warehouse_id,
                    typeTable: "table",
                },



                {
                    field: "rice",
                    operator: "->>",
                    key: "transaction_id",
                    value: id,
                    type: "jsonb",
                    typeTable: "table",
                },



            ]),
        };

        const response = await axios.get(
            `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
            {
                params,
            }
        );

        if (response.data.length > 0) {
            return response.data;
        } else {
            return [];
        }


    } catch (error) {
        console.error("Error fetching  data:", error);
        return [];
    }
};

const fetchLastRecordStockRiceTransaction = async (id, warehouse_id, rice_name) => {
    try {

        const params = {
            tableName: "stock_rice_transaction_history",
            sortColumn: "id",
            sortDirection: "DESC",
            limit: 1,
            dynamicConditions: JSON.stringify([

                {
                    field: "warehouse_id",
                    operator: "=",
                    value: warehouse_id,
                    typeTable: "table",
                },

                {
                    field: "id",
                    operator: "!=",
                    value: id,
                    typeTable: "table",
                },

                {
                    field: "transaction_type",
                    operator: "!=",
                    value: 'purchase',
                    typeTable: "table",
                },

                {
                    field: "transaction_type",
                    operator: "!=",
                    value: 'sell',
                    typeTable: "table",
                },


                {
                    field: "rice",
                    operator: "->>",
                    key: "rice_name",
                    value: rice_name,
                    type: "jsonb",
                    typeTable: "table",
                },

                {
                    field: "rice",
                    operator: "->>",
                    key: "transaction_id",
                    value: null,
                    type: "jsonb",
                    typeTable: "table",
                },




            ]),
        };

        const response = await axios.get(
            `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
            {
                params,
            }
        );

        if (response.data.length > 0) {
            return response.data;
        } else {
            return [];
        }


    } catch (error) {
        console.error("Error fetching last record:", error)
    }
}



export const deleteStockRiceTransaction = async (formData) => {
    try {
        const lastRecord = await fetchLastRecordStockRiceTransaction(formData.id, formData.warehouse_id, formData.rice.rice_name);
        if (lastRecord.length > 0) {
            console.log("lastRecord", lastRecord)
            console.log("can't delete must delete last record first")
        } else {


            console.log("can delete")
            const data = await fetchAssociateStockRiceTransaction(formData.id, formData.warehouse_id);

            //update transaction id to null for all associate data
            data.forEach(async (item) => {
                const updateRequestBody = {
                    tableName: "stock_rice_transaction_history",
                    fields: {
                        rice: JSON.stringify({ ...item.rice, transaction_id: null })
                    }
                }
                await axios.patch(`${apirURL}/weighing/api/updateData/${item.id}`, updateRequestBody);
            })

            // delete main data
            await axios.delete(`${apirURL}/weighing/api/deleteData/${formData.id}`, {
                data: { tableName: "stock_rice_transaction_history" },
            });

        

        }
    } catch (error) {
        console.log("failed to delete data:", error);

    }
}

