<template>
  <div class="w-[750px] h-auto">
    <div class="details_title">
      <h2 class="ml-4">Supplier: Supplier Name</h2>
      <h2 class="mr-4">Bill Preview</h2>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>

    <div v-if="activeTab === 'details'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2 space-y-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Supplier Name</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Phone Number</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>Cell data</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction History</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="text-body text-primary5">
              <td class="min-w-[70px] bg-white border-b text-start">No</td>
              <td class="min-w-[100px] bg-white border-b text-start">
                Rice Type
              </td>
              <td class="min-w-[100px] bg-white border-b text-start">Truck</td>
              <td class="min-w-[100px] bg-white border-b text-start">Weight</td>
              <td class="min-w-[100px] bg-white border-b text-start">
                Cut out
              </td>
              <td class="min-w-[100px] bg-white border-b text-start">Remain</td>
            </tr>
          </tbody>

          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in 10"
              :key="data"
            >
              <td>Silver</td>
              <td>$2999</td>
              <td>$2999</td>
              <td>Silver</td>
              <td>$2999</td>
              <td>$2999</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5 py-4">
        <button>Print</button>
        <button class="btncancel">Close</button>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div>
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Supplier Name</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Boat</h2>
            <h2>Cell data</h2>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            <h2>Cell data</h2>
          </div>
        </div>
      </div>
      <div class="text-start py-3">
        <h2>Transaction</h2>
      </div>
      <div class="relative overflow-x-auto mt-4 border h-[350px]">
        <table>
          <tbody>
            <tr class="text-body text-primary5">
              <td class="min-w-[100px] bg-white border-b text-start">No.</td>
              <td class="min-w-[100px] bg-white border-b text-start">
                Rice Type
              </td>
              <td class="min-w-[100px] bg-white border-b text-start">Weigh</td>
              <td class="min-w-[100px] bg-white border-b text-start">
                Cut out
              </td>
              <td class="min-w-[100px] bg-white border-b text-start">Remain</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in 10"
              :key="data"
            >
              <td>Silver</td>
              <td>$2999</td>
              <td>$2999</td>
              <td>Silver</td>
              <td>$2999</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex gap-3 mt-6 ml-5 py-4">
        <button>Print</button>
        <button class="btncancel">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    return {
      handleClose,
      setActiveTab,
      activeTab,
    };
  },
};
</script>

<style></style>
