<template>
  <div class="">
    <div class="flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'supplierbil',
        }"
        @click="setActiveTab('supplierbil')"
      >
        Supplier Bill
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'clinentinvoice',
        }"
        @click="setActiveTab('clinentinvoice')"
      >
        Client Invoice
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'laborslip',
        }"
        @click="setActiveTab('laborslip')"
      >
        Labor Slip
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3': activeTab === 'sackslip',
        }"
        @click="setActiveTab('sackslip')"
      >
        Sack Slip
      </div>
    </div>
    <!-- Content based on activeTab -->
    <div v-if="activeTab === 'supplierbil'">
      <SupplierListVue />
    </div>
    <div v-else-if="activeTab === 'clinentinvoice'">
      <ClientInvoiceListVue />
    </div>
    <div v-else-if="activeTab === 'laborslip'">
      <LaborslipVue />
    </div>
    <div v-else-if="activeTab === 'sackslip'">
      <SackSlip />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import SupplierListVue from "../admin/SupplierList.vue";
import ClientInvoiceListVue from "../admin/ClientInvoiceList.vue";
import LaborslipVue from "./Laborslip.vue";
import SackSlip from "./SackSlip.vue";
export default {
  components: { SupplierListVue, ClientInvoiceListVue, LaborslipVue, SackSlip },
  setup() {
    const activeTab = ref("supplierbil");
    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    return {
      activeTab,
      setActiveTab,
      SackSlip,
    };
  },
};
</script>

<style></style>
