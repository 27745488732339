<template>
  <div class="w-[750px] h-auto">
    <div class="details_title">
      <h2 class="ml-4">Labor Slip</h2>
      <div class="flex items-center">
        <h2 class="mr-4">Preview</h2>
        <div>
          <div @click="handleClose" class="closePopUp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="h-fit overflow-auto">
      <div class="p-5">
        <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Created At</h2>
            <p>
              {{ formatDate2(dataPrev[0].created_at) }}
            </p>
          </div>
          <div
            class="w-[80%] flex items-center justify-between text-body text-primary6"
          >
            <h2>Status</h2>
            {{ dataPrev[0].status === true ? "Complete" : "Procesccing" }}
          </div>
        </div>
        <div class="text-start py-2">
          <h2>Transaction Detail</h2>
        </div>
        <div class="relative overflow-x-auto border">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="min-w-[100px] border-b text-start">No.</td>

                <td class="min-w-[100px] border-b text-start">Created At</td>
                <td class="min-w-[100px] border-b text-start">Truck</td>
                <td class="min-w-[100px] border-b text-start">
                  Total Rice Weight
                </td>
                <td class="min-w-[100px] border-b text-start">Status</td>
              </tr>
            </tbody>

            <tbody v-for="data in dataPrev" :key="data">
              <tr
                class="text-body font-normal text-primary2 bg-white"
                v-for="item in data.rices"
              >
                <td class="border-b">{{ item.weighing_id }}</td>
                <td class="border-b">{{ formatDate2(data.created_at) }}</td>
                <td class="border-b">
                  {{ item.plate_number }}
                </td>
                <td class="border-b">
                  {{ numeralFormat(item.weight_drop, "0,0[.]00") }} Kg
                </td>
                <td class="border-b">
                  {{ data.status === true ? "Complete" : "Procesccing" }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body font-normal text-primary2 bg-white">
                <td colspan="3">
                  <h2 class="flex items-center justify-center gap-3 font-bold">
                    Total
                  </h2>
                </td>
                <td colspan="3">
                  <div class="flex items-center justify-center gap-3 font-bold">
                    <h2>{{ numeralFormat(totalWeight, "0,0[.]00") }} Kg</h2>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="flex gap-3 mt-6 ml-5 py-4">
      <!-- <button>Print</button> -->
      <button class="btncancel" @click="handleClose">Close</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { useRouter } from "vue-router";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  props: ["dataPrev"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const weighing = ref([]);

    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "id",
              operator: "=",
              value: props.dataPrev?.weighing_id,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };
    onMounted(() => {
      if (props.dataPrev) {
        props.dataPrev;
        console.log("  props.dataPrev", props.dataPrev);
      }
      fetchWeighing();
      fetchTruck();
    });
    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const totalWeight = computed(() => {
      return props.dataPrev.reduce((total, data) => {
        const riceWeight = data.rices.reduce((subTotal, item) => {
          const weightDrop = parseFloat(item.weight_drop || 0);
          return subTotal + weightDrop;
        }, 0);
        return total + riceWeight;
      }, 0);
    });
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    return {
      handleClose,
      setActiveTab,
      activeTab,
      weighing,
      formatDate2,
      getPlatesName,
      totalWeight,
    };
  },
};
</script>

<style></style>
