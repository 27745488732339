<template>
  <div class="w-full h-auto bg-default">
    <div class="filter_type border rounded">
      <div class="filter_type_wrap">
        <label for="Customer"
          ><span class="text-primary4 mr-2"></span>Customer</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectedCustomer"
          :options="customersData"
          filter
          placeholder="Select Customer"
          option-label="name"
          option-value="id"
          show-clear
          checkmark
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Customer"
          ><span class="text-primary4 mr-2"></span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          dateFormat="dd/M/yy"
          showIcon
          iconDisplay="input"
          showButtonBar
          selectionMode="range"
          :manualInput="false"
          show-button-bar
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Customer" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Customer</label
        >
        <button
          @click="handleFilterReport"
          class="h-9 px-5 bg-primary3 text-white rounded hover:bg-primary-dark"
        >
          Filter
        </button>
      </div>
    </div>
    <div class="flex justify-end items-center w-full">
      <div class="w-fit bg-white p-4 flex gap-3">
        <button
          @click="handleExportToExcel"
          class="btncsv flex items-center gap-2"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          </div>
          CSV
        </button>

        <button
          @click="handleDownloadPDF"
          class="btncancel flex items-center gap-2"
        >
          <div>
            <font-awesome-icon :icon="['fas', 'file']" class="size-5" />
          </div>
          PDF
        </button>
        <button @click="handlePrintReport" class="flex items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
          </div>
          Print
        </button>
      </div>
    </div>

    <div id="printTable">
      <div class="flex justify-between items-center w-full mt-2">
        <div class="w-[65px] h-[65px] border rounded-full"></div>
        <div class="text-center">
          <h2 class="text-heading2 font-bold">Sale Order</h2>
          <h2 class="text-[16px] font-bold">Report</h2>
          <h2 class="py-2 text-[12pt] items-center">
            011 111 111 / 012 123 456
          </h2>
        </div>
        <div class="text-primary5 text-[12pt] text-left">
          <h2>Str#1 ,#104</h2>
          <h2>Battambang</h2>
          <h2>Battambang city</h2>
          <h2>Cambodia</h2>
        </div>
      </div>

      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody>
            <tr class="text-body text-primary5 bg-white">
              <td class="min-w-[95px] bg-white text-start border">No</td>
              <td class="min-w-[95px] bg-white text-start border">
                Created At
              </td>
              <td class="min-w-[180px] bg-white text-start border">Customer</td>
              <td class="min-w-[180px] bg-white text-start border">
                Rice Amount
              </td>
              <td class="min-w-[180px] bg-white text-start border">Status</td>
              <td class="min-w-[380px] bg-white text-start border">Note</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white whitespace-nowrap"
              v-for="(data, index) in reportData"
              :key="data.sale_order_id"
            >
              <td class="border">{{ index + 1 }}</td>
              <td class="border">{{ formatDate(data.order_date) }}</td>
              <td class="border">{{ data.customer_name }}</td>
              <td class="border">
                {{
                  numeralFormat(
                    handleSumAmountOfRice(data.type_of_rice),
                    "0,0[.]0"
                  )
                }}
                Kg
              </td>
              <td class="border">{{ data.order_status }}</td>
              <td class="border">{{ data.order_note }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { formatDateWithTimezone } from "@/utils/formatDateWithTimeZone";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { formatDate } from "@/composables/formatDateTime";
import socket from "@/services/socket";
import jsPDF from "jspdf";
import "jspdf-autotable";
import numeral from "numeral";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";

export default {
  setup() {
    const reportData = ref([]);
    const selectedCustomer = ref(null);
    const selectDateRage = ref(null);
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const fetchAllPurchaseOrder = async () => {
      try {
        let dynamicConditions = [];
        if (selectedCustomer.value) {
          dynamicConditions.push({
            field: "customer_id",
            operator: "=",
            value: selectedCustomer.value,
            typeTable: "table",
          });
        }
        if (
          selectDateRage.value &&
          selectDateRage.value?.[0] &&
          selectDateRage.value?.[1]
        ) {
          const startOfDay = formatDateWithTimezone(
            selectDateRage.value[0],
            false
          );

          const endOfDay = formatDateWithTimezone(
            selectDateRage.value[1],
            true
          );

          dynamicConditions.push(
            {
              field: "created_at",
              operator: ">=",
              value: startOfDay, // start of day
            },
            {
              field: "created_at",
              operator: "<=",
              value: endOfDay, // end of day
            }
          );
        }
        const params = {
          tableName: "customer_orders",
          sortColumn: "customer_order_id",
          sortDirection: "ASC",
          columnAliases: JSON.stringify({
            customer_order_id: { table: "customer_orders", column: "id" },
            order_date: { table: "customer_orders", column: "created_at" },
            type_of_rice: { table: "customer_orders", column: "type_of_rice" },
            order_status: { table: "customer_orders", column: "status" },
            order_note: { table: "customer_orders", column: "note" },
            customer_name: { table: "customers", column: "name" },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "customers",
              joinColumnChild: "customer_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify(dynamicConditions),
        };

        const res = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params,
          }
        );

        if (res.data.length > 0) {
          reportData.value = res.data;
          console.log("reportData", reportData.value);
        } else {
          reportData.value = [];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const customersData = ref([]);

    const fetchCustomer = async () => {
      try {
        const req = {
          tableName: "customers",
          sortColumn: "id",
          sortDirection: "ASC",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        customersData.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const handleFilterReport = () => {
      fetchAllPurchaseOrder();
    };

    const handlePrintReport = () => {
      const printContents = document.getElementById("printTable").outerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    };

    const handleExportToExcel = () => {
      const dataToExport = reportData.value.map((item, index) => ({
        No: index + 1,
        "Order Date": formatDate(item.order_date),
        Customer: item.customer_name,
        "Rice Amount": `${numeral(
          handleSumAmountOfRice(item.type_of_rice),
          "0,0[.]0"
        ).format("0,0[.]0")} Kg`,
        Status: item.order_status,
        Note: item.order_note,
      }));

      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sale Order");
      XLSX.writeFile(wb, "sale_order_report.xlsx");
    };

    const handleDownloadPDF = async () => {
      // Create a temporary div for the content
      const printDiv = document.createElement("div");
      printDiv.innerHTML = `
        <div style="padding: 20px; font-family: 'Noto Serif Khmer', serif;">
           <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
            <div style="width: 65px; height: 65px; border: 1px solid #000; border-radius: 50%;"></div>
            <div style="text-align: center;">
              <h2 style="font-size: 24px; margin: 0; font-weight: bold;">Purchase Order</h2>
              <h2 style="font-size: 16px; margin: 5px 0; font-weight: bold;">Report</h2>
              <h2 style="font-size: 14px; margin: 0;">011 111 111 / 012 123 456</h2>
            </div>
            <div style="text-align: left;">
              <div>Str#1 ,#104</div>
              <div>Battambang</div>
              <div>Battambang city</div>
              <div>Cambodia</div>
            </div>
          </div>

          <!-- Table -->
          <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
            <thead>
              <tr style="background-color: #f8f9fa;">
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">No</th>
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Created At</th>
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Customer</th>
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Rice Amount</th>
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Status</th>
                <th style="border: 1px solid #ddd; text-align: center; font-size: 18px; line-height: 1; vertical-align: middle;">Note</th>
              </tr>
            </thead>
            <tbody>
              ${reportData.value
                .map(
                  (item, index) => `
                <tr>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                    index + 1
                  }</td>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${formatDate(
                    item.order_date
                  )}</td>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                    item.customer_name
                  }</td>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px; text-align: right;">${numeral(
                    handleSumAmountOfRice(item.type_of_rice)
                  ).format("0,0[.]0")} Kg</td>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                    item.order_status
                  }</td>
                  <td style="padding: 9px; border: 1px solid #ddd; font-size: 15px;">${
                    item.order_note || ""
                  }</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
      `;

      document.body.appendChild(printDiv);

      try {
        // Use html2canvas to capture the content
        const canvas = await html2canvas(printDiv, {
          scale: 2, // Increase quality
          useCORS: true,
          logging: false,
          windowWidth: 1200, // Set a fixed width for consistency
          onclone: (clonedDoc) => {
            const element = clonedDoc.querySelector("div");
            element.style.width = "1200px";
          },
        });

        // Create PDF with portrait orientation and A4 size
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        // Add image to first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add new pages if content exceeds first page
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("sale_order_report.pdf");
      } catch (error) {
        console.error("PDF generation error:", error);
      } finally {
        document.body.removeChild(printDiv);
      }
    };

    const handleSumAmountOfRice = (riceType) => {
      return riceType.reduce((acc, curr) => acc + curr.amount, 0);
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([fetchCustomer(), fetchAllPurchaseOrder()]);
    };

    onMounted(async () => {
      // Get first and last day of current month
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      // Set the date range
      selectDateRage.value = [firstDay, lastDay];
      await Promise.allSettled([fetchCustomer(), fetchAllPurchaseOrder()]);

      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });

    return {
      handlePrintReport,
      handleExportToExcel,
      handleDownloadPDF,
      customersData,
      formatDate,

      handleSumAmountOfRice,
      selectedCustomer,
      selectDateRage,
      reportData,
      handleFilterReport,
      tableClasses,
      subClassesTable,
      navClasses,
      selectClasses,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Khmer:wght@100..900&display=swap");
</style>
