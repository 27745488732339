<template>
  <form class="w-fit bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">Add Payment</h2>
      <div @click="handleClose" class="closeform">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="p-5">
      <div class="space-y-4">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6 text-nowrap gap-3"
        >
          <h2>Created At</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6 text-nowrap gap-3"
        >
          <h2>Payment Slipt Ref</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6 text-nowrap gap-3"
        >
          <h2>Client</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6 text-nowrap gap-3"
        >
          <h2>Total Payment</h2>
          <h2>Cell data</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6 text-nowrap gap-3"
        >
          <h2>Due</h2>
          <h2>Cell data</h2>
        </div>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center gap-3 justify-between">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Amount</label
        >
        <input class="w-[250px]" required type="number" placeholder="Amount" />
      </div>
      <div class="flex items-center gap-3 justify-between">
        <label for="Description">Description</label>
        <textarea
          type="text"
          placeholder="Any Description"
          rows="4"
          class="w-[250px] px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
        />
      </div>
      <div class="flex w-full items-center gap-3">
        <button>Save</button>
        <button class="btncancel">Save & New</button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: [""],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    return {
      handleClose,
    };
  },
};
</script>

<style></style>
