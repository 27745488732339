<template>
  <div class="">
    <el-empty class="!mx-auto" description="No Data" />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
