<template>
    <div class="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 animate-fade-up animate-duration-300 animate-delay-100">
        <div class="max-w-md mx-auto">
            <!-- Card Container -->
            <div class="bg-white rounded-lg shadow-md p-6 space-y-6">
                <!-- Header -->
                <div class="text-center">
                    <h1 class="text-2xl font-bold text-gray-900">Set Up Telegram Token</h1>
                    <p class="mt-2 text-sm text-gray-600">
                        Enter your Telegram bot token to connect with the application
                    </p>
                </div>

                <!-- Form -->
                <form @submit.prevent="handleSubmit" class="space-y-4">
                    <div>
                        <label for="token" class="block text-sm font-medium text-gray-700">Bot Token</label>
                        <div class="mt-1">
                            <input id="token" v-model="token" :type="token ? 'password' : 'text'" required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary3 focus:border-primary3"
                                placeholder="Enter your Telegram bot token" />
                        </div>
                    </div>

                    <div class="flex items-center justify-between" v-if="authUserRole === 'Super Admin' || (authUserRole === 'User' && permissions?.module_setting?.telegram_token?.create )">
                        <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary3 hover:bg-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary3 transition-colors">
                            <span v-if="!isLoading">Save Token</span>
                            <span v-else class="flex items-center">
                                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                    </path>
                                </svg>
                                Saving...
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <component :is="currentComponent" :showToast="showToast" :informMessage="informMessage" @onClose="handleClearToast"
        :isSuccess="isSuccess" />
</template>

<script>
import apirURL from '@/services/apiURL';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import ToastVue from '@/components/ToastVue.vue';
import { decodeJwt } from '@/composables/decodeJWT';

export default {
    components: {
        ToastVue
    },
    setup() {
        const token = ref('');
        const currentComponent = ref(null);
        const showToast = ref(false);
        const informMessage = ref('');
        const isSuccess = ref(false);
        const isLoading = ref(false);

        const authUserRole = ref("");
        const permissions = ref(null);
        const fetchUserByID = async () => {
            try {

                const token = localStorage.getItem("token");
                const decodeJWTInfo = await decodeJwt(token);
                

                let table = "users";
                const response = await axios.get(
                    `${apirURL}/weighing/api/getDataByID/${table}/${decodeJWTInfo ? decodeJWTInfo.userId : null
                    }`
                );

                authUserRole.value = response.data[0]?.role;

                permissions.value = response.data[0]?.permissions;
            } catch (err) {
                console.log("failed to fetch data", err);
                

            }
        };



        onMounted(async () => {
            await fetchUserByID();
            const existingToken = await fetchExistingToken();

            if (existingToken.length > 0) {
                token.value = existingToken[0].bot_token;
            }

        })

        const handleClearToast = () => {
            informMessage.value = "";
            showToast.value = false;
            currentComponent.value = null;
            isSuccess.value = false;
            isLoading.value = false;

        }



        const fetchExistingToken = async () => {
            try {

                const params = {
                    tableName: "telegram_bot_config",
                    sortColumn: "id"
                };

                const response = await axios.get(
                    `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
                    {
                        params,
                    }
                );

                if (response.data.length > 0) {
                    return response.data;
                } else {
                    return [];
                }


            } catch (error) {
                console.error("Error fetching last record:", error)
            }
        }



        const handleSubmit = async () => {
            try {
                isLoading.value = true;
                const existingToken = await fetchExistingToken();
                console.log("existingToken", existingToken)
                const requestBody = {
                    tableName: 'telegram_bot_config',
                    fields: {
                        bot_token: token.value
                    }

                }

                if (existingToken.length > 0) {

                    console.log("existingToken part update")

                    //update token instead of insert
                    await axios.patch(
                        `${apirURL}/weighing/api/updateData/${existingToken[0]?.id}`,
                        requestBody
                    );

                    showToast.value = true;
                    currentComponent.value = "ToastVue";
                    informMessage.value = "Token updated successfully";
                    isSuccess.value = true;

                    setTimeout(() => {
                        isLoading.value = false;
                    }, 1000);



                } else {


                    await axios.post(
                        `${apirURL}/weighing/api/insertData`,
                        requestBody
                    );

                    showToast.value = true;
                    currentComponent.value = "ToastVue";
                    informMessage.value = "Token saved successfully";
                    isSuccess.value = true;

                    setTimeout(() => {
                        isLoading.value = false;
                    }, 1000);

                }


            } catch (error) {
                isLoading.value = false;
                console.log("failed to submit data", error);

            }
        }
        return {
            authUserRole,
            permissions,
            isLoading,
            currentComponent,
            showToast,
            informMessage,
            isSuccess,
            token,
            handleSubmit,
            handleClearToast
        }
    }
}
</script>

<style></style>