<template>
  <div
    class="w-fit overflow-hidden text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div class="w-full flex items-center justify-between py-2">
      <div>
        <h2>{{ t("message.list_truck_weighing") }}</h2>
      </div>
    </div>
    <div class="flex gap-3 items-start">
      <div :class="mainClassesTable" class="h-[600px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td
                @click="sortData('weight_id')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.no") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_id' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_id' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('supplier_name')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.supplier") }}

                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'supplier_name' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'supplier_name' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('plate_number')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.truck") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'plate_number' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'plate_number' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>
          <tr class="font-semibold">
            <td colspan="5" class="border-b">
              <div class="flex items-center justify-center gap-2">
                <IconField class="w-[80%] placeholder:text-body2">
                  <InputText
                    v-model="searchValue"
                    placeholder="Search | Supplier | Phone Number"
                  />
                  <InputIcon class="pi pi-search" />
                </IconField>
              </div>
            </td>
          </tr>
          <tbody v-if="filteredWeighingData.length > 0">
            <tr
              @click="handleBoatSelection(data)"
              class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
              v-for="data in filteredWeighingData"
              :key="data.id"
            >
              <td class="border-b text-nowrap">{{ data.id }}</td>
              <td class="border-b text-nowrap">
                {{ getSupplierName(data.supplier_id) }}
              </td>
              <td class="border-b text-nowrap">
                {{
                  data.plate_number
                    ? data.plate_number.plate_number
                    : data.trucktor
                }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div :class="subClassesTable" class="border h-[600px]">
        <table>
          <tbody v-if="filteredWeighingData.length > 0">
            <tr class="text-body text-primary5">
              <td colspan="9" class="border">
                <div class="text-start flex items-center justify-between">
                  <div class="text-body flex items-center gap-3">
                    <div
                      v-if="filteredWeighingData.length > 0"
                      class="flex items-center gap-3"
                    >
                      <h2>{{ t("message.status") }}</h2>
                      <el-button
                        :type="selectedBoatStatus ? 'success' : 'warning'"
                        round
                      >
                        {{ selectedBoatStatus ? "Completed" : "Processing" }}
                      </el-button>
                    </div>
                    <div class="flex items-center gap-3">
                      <h2>{{ t("message.total_weight") }}</h2>
                      <span class="text-primary2 font-semibold text-nowrap"
                        >{{ numeralFormat(IsWeighing, "0[.],00") }} Kg</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <IconField class="w-[50%] placeholder:text-body2">
                      <InputText placeholder="Search | Plate" />
                      <InputIcon class="pi pi-search" />
                    </IconField>
                    <button
                      @click="open(selectedBoatId)"
                      :disabled="!selectedBoatId"
                    >
                      {{ t("message.mark_as_complete") }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr class="sub_header">
              <td class="min-w-[100px] border-b text-start text-nowrap">
                {{ t("message.created_at") }}
              </td>
              <td
                @click="sortData('rice_name')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.rice") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'rice_name' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'rice_name' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_in')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.weight_in") }}

                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_in' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_in' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_out')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.weight_out") }}

                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_out' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_out' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>

              <td
                @click="sortData('amount_cut_off')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.amount_cut_off") }}

                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'amount_cut_off' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'amount_cut_off' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_drop')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.remain_weight") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'weight_drop' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'weight_drop' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('boat_enter_parking_id')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.boat") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'boat_enter_parking_id' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'boat_enter_parking_id' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>

          <tbody v-if="isBoatSelected && filteredWeighingData.length > 0">
            <tr
              @click="handleAdd(history)"
              class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
              v-for="history in weighing_rice_history"
              :key="history.id"
            >
              <td class="border-b text-nowrap">
                {{ formatDate2(history.created_at) }}
              </td>
              <td class="border-b text-nowrap">
                <p v-for="rice in history.rice" :key="rice.id">
                  {{ rice.rice_name }}
                </p>
              </td>
              <td class="border-b">
                {{ numeralFormat(history.weight_in, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(history.weight_out, "0,0[.]00") }} Kg
              </td>

              <td class="border-b">
                {{
                  history.amount_cut_off
                    ? numeralFormat(history.amount_cut_off, "0,0[.]00")
                    : 0
                }}
                Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(history.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ getParking(history.boat_enter_parking_id) }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="filteredWeighingData.length > 0"
      class="w-full bg-white flex items-center justify-center py-2"
    >
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        :totalRecord="totalRecord"
        isLastRecordOnPage="isLastRecordOnPage"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isCutOut" as="template">
      <Dialog as="div" @close="handleCloseCutOut" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseCutOut"
                    :is="currentComponent"
                    :isCutOutData="isCutOutData"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Toast ref="toast" />
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import Toast from "@/components/Toast.vue";
import CutOutForm from "@/form/CutOutForm.vue";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import TruckPreviewPopupDetails from "@/form/TruckPreviewPopupDetails.vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import toggleSort from "@/composables/sortData";
import SubPagination from "@/components/SubPagination.vue";
import moment from "moment-timezone";
import socket from "@/services/socket";
import { useI18n } from "vue-i18n";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    // WeighingForm,
    TruckPreviewPopupDetails,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    SubPagination,
    CutOutForm,
    Toast,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const isCutOut = ref(false);
    const isCutOutData = ref(null);
    const handleCutOut = (doc) => {
      isCutOut.value = true;
      isCutOutData.value = doc;
      currentComponent.value = "CutOutForm";
    };
    const handleCloseCutOut = () => {
      isCutOut.value = false;
      isCutOutData.value = null;
      currentComponent.value = "";
    };
    const handleAdd = (doc) => {
      isCutOutData.value = doc;
      isOpen.value = true;
      currentComponent.value = "TruckPreviewPopupDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      weighing_rice_history.value = [];
      selectedBoatId.value = null;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "OrderDetailsPopUpVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const selectedBoatId = ref(null);

    const selectedBoatStatus = ref(false);
    const IsWeighing = ref(null);

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const totalRecord = ref(0);
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("weighing");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction

    const searchValue = ref("");
    const weighing = ref([]);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      const params = {
        tableName: table.value,
        columnSearch: "supplier_name",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,

        columnAliases: JSON.stringify({
          weight_id: { table: table.value, column: "id" },
          supplier_name: { table: "suppliers", column: "name" },
          phone_number: { table: "suppliers", column: "phone_number" },
          supplier_id: { table: "suppliers", column: "id" },
        }),
        additionalJoins: JSON.stringify([
          {
            joinTableName: "suppliers",
            joinColumnChild: "supplier_id",
            joinColumnParent: "id",
            joinType: "LEFT JOIN",
          },
        ]),
        dynamicConditions: JSON.stringify([
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "is_supplier_invoice",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          {
            field: "status",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          {
            field: "created_by",
            operator: "=",
            value: decodeJWTInfo?.userId,
            typeTable: "table",
          },
          {
            field: "service_type",
            operator: "=",
            value: "internal",
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing.value = response.data.data;
      totalRecord.value = weighing.value.length;

      console.log("data weiging for mark as");

      totalPages.value = response.data.pagination.totalPages;
    };
    const isBoatSelected = ref(false);
    const truck = ref(null);
    const handleBoatSelection = async (data) => {
      selectedBoatId.value = data.weight_id;
      selectedBoatStatus.value = data.status;
      truck.value = data.plate_number?.plate_number;

      weighing_rice_history.value = [];

      await fetchWeighingHistory(selectedBoatId.value);

      // Now display the table data
      isBoatSelected.value = true;
    };
    watch(searchValue, () => {
      fetchWeighing();
    });

    const open = (id) => {
      ElMessageBox({
        title: "Confirming Mark Truck as Completed",
        message: h("p", null, [
          h(
            "span",
            null,
            `Are you sure you want to mark the Truck [${truck.value}] as Completed?`
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: "custom-confirm-box",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 1000);
          } else {
            done();
          }
        },
      })
        .then(async (action) => {
          if (action === "confirm") {
            try {
              const req = {
                tableName: table.value,
                fields: {
                  is_weight: false,
                  status: true,
                },
              };
              const res = await axios.patch(
                `${apirURL}/weighing/api/updateData/${id}`,
                req
              );

              if (res.status === 200) {
                ElMessage({
                  type: "success",
                  message: "Boat status marked as Completed successfully.",
                });
              } else {
                ElMessage({
                  type: "error",
                  message: "Failed to update boat status.",
                });
              }
            } catch (error) {
              ElMessage({
                type: "error",
                message: `Error updating status: ${error.message}`,
              });
            }
          }
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };

    const trucks = ref([]);
    // const plate_numbers = ref("");
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const parking = ref([]);
    const selectedParking = ref("");
    const fetchParking = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const rices = ref([]);
    const fetchRiceType = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        // Assuming response.data is your rice data array
        rices.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const suppliers = ref([]);
    const fetchSipplers = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    // Weighing rice history pagination states
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPageHistory = ref(1);
    const pageSizeHistory = ref(25);
    const totalPagesHistory = ref(0);
    const weighing_rice_history = ref([]);

    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "weighing_id",
              operator: "=",
              value: weighingId,
            },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        if (response.data.length > 0) {
          weighing_rice_history.value = response.data;
          IsWeighing.value = weighing_rice_history.value.reduce(
            (acc, curr) => acc + curr.weight_drop,
            0
          );
        } else {
          weighing_rice_history.value = [];
        }
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const handlePageChangeHistory = (newPage) => {
      currentPageHistory.value = newPage;
      fetchWeighingHistory(selectedBoatId);
    };

    const handlePageSizeChangeHistory = (newSize) => {
      pageSizeHistory.value = newSize;
      currentPageHistory.value = 1;
      fetchWeighingHistory(selectedBoatId);
    };
    const getSupplierName = (id) => {
      const name = suppliers.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getRice = (id) => {
      const name = rices.value.find((item) => item?.id === id);
      return name?.name;
    };
    const getPlatesName = (id) => {
      const name = trucks.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getParking = (id) => {
      const name = parking.value.find((item) => item?.id === id);
      return name?.name;
    };
    const handleDataChange = async () => {
      await fetchWeighingHistory(selectedBoatId.value);

      await Promise.allSettled([
        fetchWeighing(),
        fetchParking(),
        fetchTruck(),
        fetchRiceType(),
        fetchSipplers(),
      ]);
    };
    watch(selectedBoatId, async (newVal) => {
      if (newVal) {
        await fetchWeighingHistory(newVal);
      }
    });
    onMounted(async () => {
      console.log("onMounted");
      await Promise.allSettled([
        fetchWeighing(),
        fetchParking(),
        fetchTruck(),
        fetchRiceType(),
        fetchSipplers(),
      ]);

      socket.on("database_realTime", handleDataChange);
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighing();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighing();
    };
    const sortClient = (field, sortDirection) => {
      if (field === "supplier_name") {
        sortSupplierName(sortDirection);
      } else if (field === "id") {
        toggleSort(sortDirection, weighing.value, field);
      } else if (field === "plate") {
        sortByPlates(sortDirection);
      }
    };
    const sortSupplierName = (sortDirection) => {
      weighing.value.sort((a, b) => {
        const nameA = getSupplierName(a.supplier_id)?.toUpperCase() || "";
        const nameB = getSupplierName(b.supplier_id)?.toUpperCase() || "";
        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortByPlates = (sortDirection) => {
      weighing.value.sort((a, b) => {
        const nameA = getPlatesName(a.plate_number_id)?.toUpperCase() || "";
        const nameB = getPlatesName(b.plate_number_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortRice = (sortDirection) => {
      weighing_rice_history.value.sort((a, b) => {
        const nameA = getPlatesName(a.rice_id)?.toUpperCase() || "";
        const nameB = getPlatesName(b.rice_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortParking = (sortDirection) => {
      weighing_rice_history.value.sort((a, b) => {
        const nameA = getParking(a.boat_enter_parking_id)?.toUpperCase() || "";
        const nameB = getParking(b.boat_enter_parking_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };
    const sortHistory = (field, sortDirection) => {
      if (field === "supplier_name") {
        sortSupplierName(sortDirection);
      } else if (field === "id") {
        toggleSort(sortDirection, weighing_rice_history.value, field);
      } else if (field === "parking") {
        sortParking(sortDirection);
      } else if (field === "rice_name") {
        sortRice(sortDirection);
      } else if (field === "weight_in") {
        toggleSort(sortDirection, weighing_rice_history.value, field);
      } else if (field === "weight_out") {
        toggleSort(sortDirection, weighing_rice_history.value, field);
      } else if (field === "weight_drop") {
        toggleSort(sortDirection, weighing_rice_history.value, field);
      } else if (field === "amount_cut_off") {
        toggleSort(sortDirection, weighing_rice_history.value, field);
      }
    };
    const isFilterable = ref(false);
    const selectedSupplier = ref(null);
    const selectedTruck = ref(null);
    const selectedRice = ref(null);
    const filteredWeighingData = computed(() => {
      return weighing.value.filter((item) => {
        const supplierMatch = selectedSupplier.value
          ? item.supplier_id === selectedSupplier.value
          : true;
        const truckMatch = selectedTruck.value
          ? item.plate_number_id === selectedTruck.value
          : true;
        const riceMatch = selectedRice.value
          ? item.rice_id === selectedRice.value
          : true;
        const parkingMatch = selectedParking.value
          ? item.parking_id === selectedParking.value
          : true;

        return supplierMatch && truckMatch && riceMatch && parkingMatch;
      });
    });

    const toast = ref(null);
    const showSuccessToast = async (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            // handleBoatSelection();
            // handleDataChange();
            // console.log("boat", selectedBoatId.value);
            // await fetchWeighingHistory(selectedBoatId.value);

            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const getPlates = (id) => {
      const name = trucks.value.find((item) => item?.id === id);
      return name?.plate_numbers;
    };
    const getPlateId = (weighing_id) => {
      const weighings = weighing.value.find((w) => w.id === weighing_id);

      return weighings ? weighings.plate_number_id : "Unknown";
    };

    const sortPlateNumber = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA =
          field === "plate_number" ? a.plate_number.plate_number : a[field];
        const valueB =
          field === "plate_number" ? b.plate_number.plate_number : b[field];

        if (valueA < valueB) return direction === "asc" ? -1 : 1;
        if (valueA > valueB) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortRiceName = (direction) => {
      weighing_rice_history.value = [...weighing_rice_history.value].sort(
        (a, b) => {
          const riceA = a.rice.map((r) => r.rice_name).join(", ") || "";
          const riceB = b.rice.map((r) => r.rice_name).join(", ") || "";

          if (direction === "asc") {
            return riceA.localeCompare(riceB);
          } else {
            return riceB.localeCompare(riceA);
          }
        }
      );
    };

    const sortByParkingB = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const parkingA = getParking(a.boat_enter_parking_id) || "";
        const parkingB = getParking(b.boat_enter_parking_id) || "";

        if (direction === "asc") {
          return parkingA.localeCompare(parkingB);
        } else {
          return parkingB.localeCompare(parkingA);
        }
      });
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortData = (field) => {
      // Toggle the sorting direction
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";

      sortColumns.value = field;
      sortDirections.value = direction;

      // Apply sorting only for relevant fields
      if (field === "supplier_name") {
        weighing.value = toggleSort(direction, weighing.value, field);
      } else if (field === "weight_id") {
        weighing.value = toggleSort(direction, weighing.value, field);
      } else if (field === "plate_number") {
        weighing.value = sortPlateNumber(direction, weighing.value, field);
      } else if (field === "rice_name") {
        sortRiceName(direction);
      } else if (field === "weight_in") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weight_out") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weight_drop") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "amount_cut_off") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "boat_enter_parking_id") {
        weighing_rice_history.value = sortByParkingB(
          direction,
          weighing_rice_history.value,
          field
        );
      }
    };

    // translate
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      weighing,
      weighing_rice_history,
      formatDate2,
      getParking,
      getPlatesName,
      getRice,
      getSupplierName,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      sortClient,
      sortHistory,
      selectedBoatId,
      selectedBoatStatus,
      IsWeighing,
      currentPageHistory,
      pageSizeHistory,
      handlePageChangeHistory,
      handlePageSizeChangeHistory,
      totalPagesHistory,
      handleBoatSelection,
      isBoatSelected,
      isCutOut,
      handleCloseCutOut,
      handleCutOut,
      isCutOutData,
      isFilterable,
      selectedSupplier,
      selectedTruck,
      selectedRice,
      selectedParking,
      filteredWeighingData,

      suppliers,
      trucks,
      rices,
      parking,
      toast,
      showSuccessToast,
      getPlateId,
      getPlates,
      searchValue,
      sortData,
      sortDirections,
      sortColumns,
      totalRecord,
      // translate
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>

<style>
.custom-confirm-box .el-button--primary {
  background-color: #28a745 !important; /* Green */
  border-color: #28a745 !important; /* Green */
  color: #fff !important;
}

.custom-confirm-box .el-button--primary:hover {
  background-color: #218838 !important; /* Darker Green on Hover */
  border-color: #218838 !important;
}
</style>
