<template>
  <div
    class="w-full relative text-primary6 animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div class="w-full flex items-center justify-between py-3">
      <div>
        <h2>{{ t("message.boat_weighing_list") }}</h2>
      </div>
    </div>
    <div class="flex gap-3 items-start">
      <div :class="mainClassesTable" class="h-[600px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td
                @click="sortData('boat_id')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.no") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'boat_id' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'boat_id' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('customer_name')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.customer") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'customer_name' &&
                        sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'customer_name' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('parking')"
                class="border-b text-nowrap cursor-pointer toggle_sort"
              >
                {{ t("message.boat") }}
                <span class="ml-2">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortColumns === 'parking' && sortDirections === 'asc'
                          ? 'sort-up'
                          : sortColumns === 'parking' &&
                            sortDirections === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>
          <tr class="font-semibold">
            <td colspan="5" class="border-b">
              <div class="flex items-center justify-center gap-2">
                <IconField
                  v-model="searchValue"
                  class="w-[80%] placeholder:text-body2"
                >
                  <InputText
                    v-model="searchValue"
                    placeholder="Search | Customer"
                  />
                  <InputIcon v-model="searchValue" class="pi pi-search" />
                </IconField>
              </div>
            </td>
          </tr>
          <tbody v-if="boatEnterParking.length > 0">
            <tr
              @click="handleBoatSelection(data)"
              class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
              v-for="data in boatEnterParking"
              :key="data.id"
            >
              <td class="border-b text-nowrap">{{ data.id }}</td>
              <td class="border-b text-nowrap">
                {{ data.customer_name }}
              </td>
              <td class="border-b text-nowrap">
                {{ data.parking.name }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div>
          <div :class="subClassesTable" class="h-[600px] border">
            <table v-if="boatEnterParking.length > 0">
              <tbody>
                <tr class="text-body text-primary5">
                  <td colspan="9" class="border">
                    <div class="text-start flex items-center justify-between">
                      <div class="text-body flex items-center gap-3">
                        <div class="flex items-center gap-3">
                          <h2>Status</h2>
                          <el-button
                            :type="selectedBoatStatus ? 'success' : 'warning'"
                            round
                          >
                            {{
                              selectedBoatStatus ? "Completed" : "Processing"
                            }}
                          </el-button>
                        </div>
                        <div class="flex items-center gap-3">
                          <h2>Total Weight</h2>
                          <span class="text-primary2 font-semibold"
                            >{{ numeralFormat(IsWeighing, "0[.],00") }} Kg</span
                          >
                        </div>
                      </div>
                      <div class="flex items-center gap-3">
                        <button
                          v-if="
                            authUserRole === 'Super Admin' ||
                            (authUserRole === 'User' &&
                              permissions.module_weighing?.boat_storage
                                ?.update === true)
                          "
                          @click="open(selectedBoatId)"
                          :disabled="!selectedBoatId"
                        >
                          Mark Completed
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr class="sub_header">
                  <td
                    @click="sortData('weighing_history_id')"
                    class="border-b text-nowrap cursor-pointer toggle_sort"
                  >
                    {{ t("message.no") }}
                    <span class="ml-2">
                      <transition name="fade" mode="out-in">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            sortColumns === 'weighing_history_id' &&
                            sortDirections === 'asc'
                              ? 'sort-up'
                              : sortColumns === 'weighing_history_id' &&
                                sortDirections === 'desc'
                              ? 'sort-down'
                              : 'sort',
                          ]"
                        />
                      </transition>
                    </span>
                  </td>
                  <td class="min-w-[100px] border-b text-start text-nowrap">
                    {{ t("message.created_at") }}
                  </td>
                  <td
                    @click="sortData('rice_name')"
                    class="border-b text-nowrap cursor-pointer toggle_sort"
                  >
                    {{ t("message.rice") }}
                    <span class="ml-2">
                      <transition name="fade" mode="out-in">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            sortColumns === 'rice_name' &&
                            sortDirections === 'asc'
                              ? 'sort-up'
                              : sortColumns === 'rice_name' &&
                                sortDirections === 'desc'
                              ? 'sort-down'
                              : 'sort',
                          ]"
                        />
                      </transition>
                    </span>
                  </td>

                  <td
                    @click="sortData('weighing_history_weight_in')"
                    class="border-b text-nowrap cursor-pointer toggle_sort"
                  >
                    {{ t("message.weight_in") }}
                    <span class="ml-2">
                      <transition name="fade" mode="out-in">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            sortColumns === 'weighing_history_weight_in' &&
                            sortDirections === 'asc'
                              ? 'sort-up'
                              : sortColumns === 'weighing_history_weight_in' &&
                                sortDirections === 'desc'
                              ? 'sort-down'
                              : 'sort',
                          ]"
                        />
                      </transition>
                    </span>
                  </td>
                  <td
                    @click="sortData('weighing_rice_history_amount_cut_off')"
                    class="border-b text-nowrap cursor-pointer toggle_sort"
                  >
                    {{ t("message.amount_cut_off") }}

                    <span class="ml-2">
                      <transition name="fade" mode="out-in">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            sortColumns ===
                              'weighing_rice_history_amount_cut_off' &&
                            sortDirections === 'asc'
                              ? 'sort-up'
                              : sortColumns ===
                                  'weighing_rice_history_amount_cut_off' &&
                                sortDirections === 'desc'
                              ? 'sort-down'
                              : 'sort',
                          ]"
                        />
                      </transition>
                    </span>
                  </td>
                  <td
                    @click="sortData('weighing_history_weight_drop')"
                    class="border-b text-nowrap cursor-pointer toggle_sort"
                  >
                    {{ t("message.weight_out") }}

                    <span class="ml-2">
                      <transition name="fade" mode="out-in">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            sortColumns === 'weighing_history_weight_drop' &&
                            sortDirections === 'asc'
                              ? 'sort-up'
                              : sortColumns ===
                                  'weighing_history_weight_drop' &&
                                sortDirections === 'desc'
                              ? 'sort-down'
                              : 'sort',
                          ]"
                        />
                      </transition>
                    </span>
                  </td>
                  <td class="min-w-[100px] border-b text-start text-nowrap">
                    <div class="flex items-center justify-start gap-3">
                      <h2>{{ t("message.boat") }}</h2>
                    </div>
                  </td>

                  <td class="min-w-[100px] border-b text-start text-nowrap">
                    {{ t("message.truck") }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="isBoatSelected && weighing_rice_history">
                <tr
                  class="text-body font-normal text-primary2 bg-white"
                  v-for="history in weighing_rice_history"
                  :key="history.id"
                  @click="handlePopUp(history)"
                >
                  <td class="border-b">
                    {{ history.weighing_history_id }}
                  </td>
                  <td class="border-b">
                    {{ formatDate2(history.weighing_history_created_at) }}
                  </td>
                  <td class="border-b text-nowrap">
                    <p
                      v-for="(rice, index) in history.weighing_history_rice"
                      :key="index"
                    >
                      {{ rice.rice_name }}
                    </p>
                  </td>
                  <td class="border-b">
                    {{
                      numeralFormat(
                        history.weighing_history_weight_in,
                        "0,0[.]00"
                      )
                    }}
                    Kg
                  </td>

                  <td class="border-b">
                    {{
                      history.weighing_rice_history_amount_cut_off
                        ? numeralFormat(
                            history.weighing_rice_history_amount_cut_off,
                            "0,0[.]00"
                          )
                        : 0
                    }}
                    Kg
                  </td>
                  <td class="border-b">
                    {{
                      numeralFormat(
                        history.weighing_history_weight_drop -
                          history.weighing_rice_history_amount_cut_off,
                        "0,0[.]00"
                      )
                    }}
                    Kg
                  </td>

                  <td class="border-b">
                    {{
                      getParking(history.weighing_history_boat_enter_parking_id)
                    }}
                  </td>
                  <td class="border-b">
                    {{ history.weighing_history_rice[0].plate_number }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9">
                    <NoDataComponet />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="boatEnterParking.length > 0"
      class="w-full bg-white flex items-center justify-center py-2"
    >
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        @page-change="handlePageChange"
        :totalRecord="boatEnterParking.length"
        @page-size-change="handlePageSizeChange"
        isLastRecordOnPage="isLastRecordOnPage"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import ToParkingPreviewPopupDetails from "@/form/ToParkingPreviewPopupDetails.vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import SubPagination from "@/components/SubPagination.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import moment from "moment-timezone";
import socket from "@/services/socket";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { decodeJwt } from "@/composables/decodeJWT";
import { useI18n } from "vue-i18n";

export default {
  components: {
    // WeighingForm,
    ToParkingPreviewPopupDetails,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    SubPagination,
    NoDataComponet,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);

    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "ToParkingPreviewPopupDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    const datatoedit = ref(null);
    const handlePopUp = (doc) => {
      isPopup.value = true;
      currentComponent.value = "ToParkingPreviewPopupDetails";
      datatoedit.value = doc;
    };

    // Fetch Parking Data
    const parking = ref([]);

    const fetchParking = async () => {
      try {
        const req = {
          tableName: "parking_rice",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        parking.value = response.data;
      } catch (error) {
        console.error("Error fetching parking data:", error);
      }
    };
    const open = async (boatId) => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed",
        message: h("p", null, [
          h(
            "span",
            null,
            `Are you sure you want to mark the Boat [${getParking(
              boatId
            )}] as Completed?`
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: "custom-confirm-box",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 1000);
          } else {
            done();
          }
        },
      })
        .then(async (action) => {
          if (action === "confirm") {
            try {
              const reqBoat = {
                tableName: "boat_enter_parking",
                fields: {
                  is_complete: true,
                },
              };

              const resBoat = await axios.patch(
                `${apirURL}/weighing/api/updateData/${boatId}`,
                reqBoat
              );

              if (resBoat.status === 200) {
                // Step 2: Extract `parking_id` from the response
                const parkingId = boatEnterParking.value[0].parking.parking_id;

                if (parkingId) {
                  const reqParking = {
                    tableName: "parking_rice",
                    fields: {
                      is_available: true,
                      is_rental: true,
                    },
                  };

                  const resParking = await axios.patch(
                    `${apirURL}/weighing/api/updateData/${parkingId}`,
                    reqParking
                  );

                  if (resParking.status === 200) {
                    ElMessage({
                      type: "success",
                      message:
                        "Boat status marked as Completed and parking updated successfully.",
                    });

                    // Refresh boat data
                    await fetchBoatEnterParking();

                    // Adjust pagination if needed
                    if (
                      boatEnterParking.value.length === 0 &&
                      currentPage.value > 1
                    ) {
                      currentPage.value -= 1;
                      await fetchBoatEnterParking();
                    }
                  } else {
                    ElMessage({
                      type: "error",
                      message: "Failed to update parking status.",
                    });
                  }
                } else {
                  console.warn(
                    "Parking ID not found in response:",
                    resBoat.data
                  );
                  ElMessage({
                    type: "error",
                    message: "Parking ID not found in response.",
                  });
                }
              } else {
                console.error("Boat update failed:", resBoat);
                ElMessage({
                  type: "error",
                  message: "Failed to update boat status.",
                });
              }
            } catch (error) {
              console.error("Error during update operation:", error);
              ElMessage({
                type: "error",
                message: `Error updating status: ${error.message}`,
              });
            }
          }
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error("Unexpected error during confirmation:", err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("boat_enter_parking");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const totalRecord = ref(0);
    const searchValue = ref("");
    const boatEnterParking = ref([]);
    const fetchBoatEnterParking = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: "boat_enter_parking",
        columnSearch: "customer_name",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        columnAliases: JSON.stringify({
          customer_name: {
            table: "customers",
            column: "name",
          },
          boat_id: {
            table: "boat_enter_parking",
            column: "id",
          },
        }),
        additionalJoins: JSON.stringify([
          {
            joinTableName: "customers",
            joinColumnChild: "customer_id",
            joinColumnParent: "id",
            joinType: "LEFT JOIN",
          },
        ]),

        dynamicConditions: JSON.stringify([
          {
            field: "is_complete",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          {
            field: "is_customer_invoice",
            operator: "=",
            value: false,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      boatEnterParking.value = response.data.data;

      totalPages.value = response.data.pagination.totalPages;
    };
    const selectedBoatId = ref(null);
    const selectedBoatStatus = ref(null);
    const IsWeighing = ref(null);
    const isBoatSelected = ref(false);

    const handleBoatSelection = async (data) => {
      selectedBoatId.value = data.boat_id;
      selectedBoatStatus.value = data.is_complete;
      IsWeighing.value = data.weight_in;

      isBoatSelected.value = true;
      weighing_rice_history.value = [];

      await fetchWeighingHistory(data.boat_id);
    };
    watch(searchValue, () => {
      fetchBoatEnterParking();
    });

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const currentPageHistory = ref(1);
    const pageSizeHistory = ref(25);
    const totalPagesHistory = ref(0);
    const weighing_rice_history = ref([]);
    const fetchWeighingHistory = async (weighingId) => {
      try {
        const req = {
          tableName: "weighing_rice_history",
          columnAliases: JSON.stringify({
            weighing_history_id: {
              table: "weighing_rice_history",
              column: "id",
            },
            weighing_history_boat_enter_parking_id: {
              table: "weighing_rice_history",
              column: "boat_enter_parking_id",
            },
            weighing_history_rice: {
              table: "weighing_rice_history",
              column: "rice",
            },
            weighing_history_weight_in: {
              table: "weighing_rice_history",
              column: "weight_in",
            },
            weighing_rice_history_weight_out: {
              table: "weighing_rice_history",
              column: "weight_out",
            },
            weighing_rice_history_amount_cut_off: {
              table: "weighing_rice_history",
              column: "amount_cut_off",
            },
            weighing_history_weight_drop: {
              table: "weighing_rice_history",
              column: "weight_drop",
            },
            weighing_history_created_at: {
              table: "weighing_rice_history",
              column: "created_at",
            },
            weighings_id: { table: "weighing", column: "id" },
            is_customer_invoice: {
              table: "weighing",
              column: "is_customer_invoice",
            },
          }),
          sortColumn: "weighing_history_id",
          additionalJoins: JSON.stringify([
            {
              joinTableName: "weighing",
              joinColumnChild: "weighing_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
            {
              joinTableName: "boat_enter_parking",
              joinColumnChild: "boat_enter_parking_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "boat_enter_parking_id",
              operator: "=",
              value: weighingId,
              typeTable: "table",
            },

            // {
            //   field: "is_customer_invoice",
            //   operator: "=",
            //   value: false,
            //   typeTable: "joinTable",
            //   joinTableName: "weighing",
            // },
            // {
            //   field: "is_complete",
            //   operator: "=",
            //   value: false,
            //   typeTable: "joinTable",
            //   joinTableName: "boat_enter_parking",
            // },
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          {
            params: req,
          }
        );

        weighing_rice_history.value = response.data;
        console.log("weighing_rice_history", weighing_rice_history.value);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const weighingInHistory = computed(() => {
      return weighing_rice_history.value[0]?.weight_in;
    });

    const handlePageChangeHistory = async (newPage) => {
      currentPageHistory.value = newPage;
      await fetchWeighingHistory(selectedBoatId.value); // Ensure you have selectedWeighingId set when view details
    };

    const handlePageSizeChangeHistory = async (newSize) => {
      pageSizeHistory.value = newSize;
      currentPageHistory.value = 1;
      await fetchWeighingHistory(selectedBoatId.value); // Ensure you have selectedWeighingId set when view details
    };

    const weighingData = ref([]);
    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighingData.value = response.data;
        console.log(weighingData);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const handleUpdateDatabase = async () => {
      await Promise.allSettled([
        fetchBoatEnterParking(),
        fetchUserByID(),
        fetchWeighingData(),
        fetchParking(),
      ]);

      if (selectedBoatId.value) {
        await fetchWeighingHistory(selectedBoatId.value);
      }
    };
    const parkings = ref([]);

    const fetchparkings = async () => {
      try {
        const req = {
          tableName: "boat_enter_parking",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        parkings.value = res.data;
        console.log("parkings", parkings.value);
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchBoatEnterParking(),
        fetchUserByID(),
        fetchWeighingData(),
        fetchparkings(),
        fetchParking(),
      ]);

      if (selectedBoatId.value) {
        await fetchWeighingHistory(selectedBoatId.value);
      }

      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      socket.on("database_realTime", handleUpdateDatabase);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchBoatEnterParking();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchBoatEnterParking();
    };
    const sortClient = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, boatEnterParking.value, field);
      } else if (field === "parking") {
        sortParking(sortDirection);
      }
    };
    const getParking = (id) => {
      const parkingName = parkings.value.find((item) => item?.id === id);
      return parkingName?.name;
    };

    const sortParking = (sortDirection) => {
      weighing_rice_history.value.sort((a, b) => {
        const nameA = getParking(a.boat_enter_parking_id)?.toUpperCase() || "";
        const nameB = getParking(b.boat_enter_parking_id)?.toUpperCase() || "";

        if (sortDirection === "ASC") {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };

    const isFilterable = ref(false);

    const sortDirections = ref("asc");
    const sortColumns = ref(null);

    const sortRiceName = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const riceA =
          a.weighing_history_rice.map((r) => r.rice_name).join(", ") || "";
        const riceB =
          b.weighing_history_rice.map((r) => r.rice_name).join(", ") || "";

        if (direction === "asc") {
          return riceA.localeCompare(riceB);
        } else {
          return riceB.localeCompare(riceA);
        }
      });
    };
    const sortBoatName = (direction, data, field) => {
      return [...data].sort((a, b) => {
        const valueA = field === "parking" ? a.parking.name : a[field];
        const valueB = field === "parking" ? b.parking.name : b[field];

        if (valueA < valueB) return direction === "asc" ? -1 : 1;
        if (valueA > valueB) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortData = (field) => {
      // Toggle the sorting direction
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";

      sortColumns.value = field;
      sortDirections.value = direction;

      // Apply sorting only for relevant fields
      if (field === "customer_name") {
        boatEnterParking.value = toggleSort(
          direction,
          boatEnterParking.value,
          field
        );
      } else if (field === "weight_id") {
        boatEnterParking.value = toggleSort(
          direction,
          boatEnterParking.value,
          field
        );
      } else if (field === "boat_id") {
        boatEnterParking.value = toggleSort(
          direction,
          boatEnterParking.value,
          field
        );
      } else if (field === "parking") {
        boatEnterParking.value = sortBoatName(
          direction,
          boatEnterParking.value,
          field
        );
      } else if (field === "rice_name") {
        weighing_rice_history.value = sortRiceName(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weighing_history_weight_in") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weighing_rice_history_amount_cut_off") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weighing_history_weight_drop") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weighing_rice_history_amount_cut_off") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "boat_enter_parking_id") {
        weighing_rice_history.value = sortByParkingB(
          direction,
          weighing_rice_history.value,
          field
        );
      } else if (field === "weighing_history_id") {
        weighing_rice_history.value = toggleSort(
          direction,
          weighing_rice_history.value,
          field
        );
      }
    };
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };
    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      boatEnterParking,
      handleBoatSelection,
      weighing_rice_history,
      formatDate2,

      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      sortClient,
      selectedBoatId,
      selectedBoatStatus,
      IsWeighing,
      currentPageHistory,
      pageSizeHistory,
      handlePageChangeHistory,
      handlePageSizeChangeHistory,
      totalPagesHistory,
      weighingInHistory,

      isBoatSelected,
      isFilterable,
      datatoedit,
      sortData,
      sortDirections,
      sortColumns,
      searchValue,
      getParking,
      parking,
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>

<style>
.custom-confirm-box .el-button--primary {
  background-color: #28a745 !important; /* Green */
  border-color: #28a745 !important; /* Green */
  color: #fff !important;
}

.custom-confirm-box .el-button--primary:hover {
  background-color: #218838 !important; /* Darker Green on Hover */
  border-color: #218838 !important;
}
</style>
