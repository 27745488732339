<template>
  <form @submit.prevent="handleSubmit" class="bg-default text-primary6">
    <div class="form_title">
      <h2 class="ml-4">Rice Transfer</h2>
      <!-- {{ rice }} -->
      <div @click="handleClose" class="closeform">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex gap-4">
        <div
          class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3"
        >
          <div>
            <h2 class="text-start">Transfer From</h2>
          </div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span>Select Warehouse</label
            >
            <el-select
              v-model="selectWarehouse"
              placeholder="Select Warehouse"
              size="large"
              style="width: 250px"
            >
              <el-option
                :key="datatoedit.id"
                :label="datatoedit.name"
                :value="datatoedit.id"
              />
            </el-select>
          </div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Select Rice</label
            >
            <el-select
              v-model="selectRice"
              placeholder="Select Rice"
              size="large"
              style="width: 250px"
            >
              <el-option
                v-for="item in rice"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-start text-nowrap">
              <span class="text-red-500">*</span> Current Stock</label
            >
            <InputNumber
              v-model="currentStock"
              :maxFractionDigits="2"
              disabled
              class="h-[40px] w-[250px]"
              placeholder="Enter Amount"
              step="0.01"
              suffix=" Kg"
              required
            />
          </div>
          <div>
            <div class="flex items-center gap-3 justify-between">
              <label for="name" class="flex gap-1 text-start text-nowrap">
                <span class="text-red-500">*</span> Amount</label
              >
              <InputNumber
                v-model="amount"
                :maxFractionDigits="2"
                class="h-[40px] w-[250px]"
                placeholder="Enter Amount"
                step="0.01"
                suffix=" Kg"
              />
            </div>
            <p v-if="isAmount">
              {{ isAmount }}
            </p>
          </div>
        </div>
        <div
          class="w-full items-center gap-3 bg-white p-5 rounded border space-y-3"
        >
          <div>
            <h2 class="text-start">Receive by</h2>
          </div>
          <div class="flex items-center gap-3 justify-between">
            <label for="name" class="flex gap-1 text-nowrap">
              <span class="text-red-500">*</span> Select Warehouse</label
            >
            <el-select
              v-model="toWarehouse"
              placeholder="Select Warehouse"
              size="large"
              style="width: 250px"
            >
              <el-option
                v-for="item in warehouse"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </div>
          <div class="flex items-start gap-3 justify-between">
            <label for="name" class="flex gap-1 text-start text-nowrap">
              Note</label
            >
            <textarea
              type="text"
              placeholder="Any Description"
              rows="4"
              class="w-[250px] px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
            />
          </div>
        </div>
      </div>
      <div class="flex w-full items-center gap-3">
        <button>Save</button>
        <button class="btncancel">Save & New</button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { validateInputNUmber } from "@/composables/ValidateInput";
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import { decodeJwt } from "@/composables/decodeJWT";
import { fetchTimestamp } from "@/services/timestamp";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const selectWarehouse = ref(null);
    const currentStock = ref(null);
    const selectRice = ref(null);
    const toWarehouse = ref(null);
    const amount = ref(null);
    const rice = ref([]);
    const fetchWarebyId = async (id) => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "=",
              value: id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };
    const fetchRices = async () => {
      try {
        const req = {
          tableName: "rices",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "rice_id",
              operator: "@>",
              value: props.datatoedit[0]?.rice_id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        rice.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };
    const warehouse = ref([]);
    const fetchWarehouse = async () => {
      try {
        const req = {
          tableName: "warehouse",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "id",
              operator: "!=",
              value: props.datatoedit.id,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        warehouse.value = response.data;
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const updateLastRiceStock = (riceId, newWeight) => {
      // Find the last object with the given rice_id in the stockData array
      const lastRiceIndex = stockData.value
        .map((item) => item.rice_id)
        .lastIndexOf(riceId);

      if (lastRiceIndex !== -1) {
        // Update the current_weight for that specific rice_id
        stockData.value[lastRiceIndex].current_weight = newWeight;
      } else {
        console.error(`No rice with id ${riceId} found in the stock.`);
      }
    };

    const handleSubmit = async () => {
      if (!validateAmount()) {
        return;
      }
      try {
        const timestamp = await fetchTimestamp();

        // Step 1: Insert transaction into stock_rice_transaction_history
        const req = {
          tableName: "stock_rice_transaction_history",
          fields: {
            rice_id: selectRice.value,
            warehouse_id: props.datatoedit.id, // source warehouse ID
            from_warehouse_id: props.datatoedit.id, // source warehouse
            to_warehouse_id: toWarehouse.value, // destination warehouse
            created_at: timestamp,
            transaction_type: "transfer",
            updated_by: authUserId.value,
            created_by: authUserId.value,
            weight: currentStock.value - amount.value,
            rices: JSON.stringify([
              {
                rice_id: selectRice.value,
                weight: currentStock.value - amount.value,
              },
            ]),
          },
        };

        const res = await axios.post(`${apirURL}/weighing/api/insertData`, req);

        if (res.data.message) {
          // Update the last object of the selected rice_id with the new weight
          updateLastRiceStock(selectRice.value, amount.value);

          // Prepare the updated stock array to send back to the server
          const updatedStock = stockData.value;

          const requestBody = {
            tableName: "warehouse",
            fields: {
              stock: JSON.stringify(updatedStock),
            },
          };

          const patchRes = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );

          if (patchRes.data.message) {
            console.log("Warehouse update successful", requestBody);
          }
        }

        emit("toast", "transfer");
        console.log("Transfer completed successfully");
      } catch (error) {
        console.log("Error during stock transfer:", error);
      }
    };

    const authUserId = ref(null);
    const stockData = ref([]);
    const updateCurrentStock = (riceId) => {
      let totalStock = 0;
      stockData.value.forEach((item) => {
        if (item.rice_id === riceId && item.current_weight !== null) {
          totalStock += item.current_weight;
        }
      });
      currentStock.value = totalStock;
    };
    watch(selectRice, (newRiceId) => {
      updateCurrentStock(newRiceId);
    });
    onMounted(async () => {
      if (props.datatoedit) {
        console.log("datatoedit", props.datatoedit);
        selectWarehouse.value = props.datatoedit.id;
        props.datatoedit.stock.forEach((item) => {
          if (item.current_weight !== null) {
            currentStock.value += item.current_weight;
          }
        });
      }
      await Promise.allSettled([fetchRices(), fetchWarehouse()]);
      const token = localStorage.getItem("token");
      const decodeJWTInfo = await decodeJwt(token);
      if (decodeJWTInfo) {
        authUserId.value = decodeJWTInfo.userId;
      }
      const riceWeightMap = {};
      props.datatoedit.stock.forEach((item) => {
        if (item.current_weight !== null) {
          if (!riceWeightMap[item.rice_id]) {
            riceWeightMap[item.rice_id] = 0;
          }
          riceWeightMap[item.rice_id] += item.current_weight;
        }
      });

      // Now you can access the total current_weight for each rice_id
      console.log("Rice Weight Map (grouped by rice_id):", riceWeightMap);
      stockData.value = props.datatoedit.stock;
      if (selectRice.value) {
        updateCurrentStock(selectRice.value);
      }
    });
    const isAmount = ref("");
    const validateAmount = () => {
      if (amount.value > currentStock.value) {
        isAmount.value = "the amount must be <= current stock";
        return false;
      } else {
        isAmount.value = "";
        return true;
      }
    };
    watch(amount, () => {
      validateAmount();
    });
    return {
      handleClose,
      validateInputNUmber,
      selectWarehouse,
      selectRice,
      rice,
      warehouse,
      toWarehouse,
      currentStock,
      handleSubmit,
      amount,
      isAmount,
    };
  },
};
</script>

<style></style>
