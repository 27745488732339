<template>
  <div class="w-[750px] h-auto">
    <div class="details_title">
      <h2 class="ml-4">SackSlip</h2>
      <h2 class="mr-4">Preview</h2>
    </div>

    <div class="p-5">
      <div class="py-4 border-b-2 border-primary6 grid grid-cols-2">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ prevData[0]?.created_at }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ prevData[0]?.status }}</h2>
        </div>
      </div>
      <div class="text-start py-2">
        <h2>Transaction</h2>
      </div>
      <div class="relative overflow-x-auto border">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[100px] border-b text-start">No.</td>

              <td class="min-w-[100px] border-b text-start">Created At</td>
              <td class="min-w-[100px] border-b text-start">
                Total Rice Weight
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in prevData"
              :key="data"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">
                {{ numeralFormat(totalSackExpense(data.weighing), "0,0[.]00") }}
                ៛
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="text-body font-normal text-primary2 bg-white">
              <td>
                <div
                  class="flex items-center justify-center gap-3 font-bold text-start"
                >
                  <h2>Total</h2>
                </div>
              </td>
              <td colspan="2">
                <div
                  class="flex items-center justify-center gap-3 font-bold text-start"
                >
                  <h2>
                    {{ numeralFormat(grandTotalSackExpense, "0,0[.]00") }} ៛
                  </h2>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="flex gap-3 mt-6 ml-5 py-4">
      <button @click="handleGotprint">Print</button>
      <button @click="handleClose" class="btncancel">Close</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { formatDate2 } from "@/composables/formatDateTime";
export default {
  props: ["prevData"],
  setup(props, { emit }) {
    const router = useRouter();
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const handleGotprint = () => {
      router.push({ name: "printsackslip" });
    };
    onMounted(() => {
      if (props.prevData) {
        console.log("props.prevData", props.prevData);
      }
    });
    const totalSackExpense = (weighings) => {
      return weighings.reduce((total, item) => total + item.sack_expense, 0);
    };
    const grandTotalSackExpense = computed(() => {
      return props.prevData.reduce((total, data) => {
        return total + totalSackExpense(data.weighing);
      }, 0);
    });
    return {
      handleClose,
      setActiveTab,
      activeTab,
      handleGotprint,
      totalSackExpense,
      totalSackExpense,
      formatDate2,
      grandTotalSackExpense,
    };
  },
};
</script>

<style></style>
