<template>
  <div class="w-full relative text-primary6">
    <Toast ref="toast" />
    <div class="w-full flex items-center">
      <div class="w-1/2">
        <h2>List Due Customer</h2>
      </div>
      <div class="w-1/2 flex justify-end">
        <IconField class="w-[50%] placeholder:text-body2">
          <InputText
            v-model="searchValue"
            placeholder="Search | Name | Phone"
          />
          <InputIcon class="pi pi-search" />
        </IconField>
      </div>
    </div>
    <div
      :class="tableClasses"
      class="h-[600px] animate-fade-up animate-duration-300 animate-delay-100"
    >
      <table>
        <tbody>
          <tr class="header_table">
            <td class="min-w-[95px] border-b text-start">
              No
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>

            <td class="min-w-[180px] border-b text-start">
              Client
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] border-b text-start">
              Number Phone
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] border-b text-start">
              Total Amount
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] border-b text-start">
              Amount Paid
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>
            <td class="min-w-[180px] border-b text-start">
              Amount Owe
              <span class="ml-2 cursor-pointer"
                ><font-awesome-icon :icon="['fas', 'sort']"
              /></span>
            </td>

            <td class="min-w-[126px] border-b">Acion</td>
          </tr>
        </tbody>
        <tbody v-if="customerInvoiceDueOrPartialData.length > 0">
          <tr
            class="text-body font-normal text-primary2 border-b"
            v-for="data in customerInvoiceDueOrPartialData"
            :key="data.invoice_id"
          >
            <td class="border-b">{{ data.invoice_id }}</td>
            <td class="border-b">{{ data.customer_name }}</td>
            <td class="border-b">{{ data.customer_phone_number }}</td>

            <td class="border-b">
              {{
                numeralFormat(
                  data.repayments.length > 0
                    ? data.repayments[data.repayments.length - 1]
                        .total_amount_owed
                    : 0,
                  "0,0[.]00"
                )
              }}
              ៛
            </td>
            <td class="border-b">
              {{
                numeralFormat(
                  data.repayments.length > 0
                    ? data.repayments[data.repayments.length - 1].amount_paid
                    : 0,
                  "0,0[.]00"
                )
              }}
              ៛
            </td>
            <td class="border-b">
              {{
                numeralFormat(
                  data.repayments.length > 0
                    ? data.repayments[data.repayments.length - 1]
                        .total_amount_owed -
                        data.repayments[data.repayments.length - 1].amount_paid
                    : 0,
                  "0,0[.]00"
                )
              }}
              ៛
            </td>
            <td
              v-if="
                authUserRole === 'Super Admin' ||
                (authUserRole === 'User' &&
                  permissions.module_finance?.customer_payment?.create === true)
              "
            >
              <button @click="handleAdd(data)" class="pay_btn">
                <span>Pay</span>
                <span class="pi pi-money-bill text-md"></span>
              </button>
              <!-- <el-button type="primary" round> </el-button> -->
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">
              <NoDataComponet />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full flex items-center justify-between mt-8">
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :totalRecord="customerInvoiceDueOrPartialData.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        isLastRecordOnPage="isLastRecordOnPage"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[5rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :dataToRepayment="dataToRepayment"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import {
  getResponsiveTable,
  getResponsiveSelect,
  getResponsiveTableNav,
} from "@/composables/useResponsive";
import DouPaymentForm from "@/form/DouPaymentForm.vue";
import axios from "axios";
import socket from "@/services/socket";
import apirURL from "@/services/apiURL";
import { decodeJwt } from "@/composables/decodeJWT";
import Pagination from "@/components/Pagination.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import Toast from "@/components/Toast.vue";
export default {
  components: {
    DouPaymentForm,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    NoDataComponet,
    Toast,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const searchValue = ref("");
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const customerInvoiceDueOrPartialData = ref([]);

    const dataToRepayment = ref(null);
    const handleAdd = (data) => {
      isOpen.value = true;
      currentComponent.value = "DouPaymentForm";
      dataToRepayment.value = data;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
      dataToRepayment.value = null;
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    const fetchCustomerInvoiceDueOrPartial = async () => {
      const params = {
        tableName: "customer_invoice",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: "invoice_id",
        columnSearch: "customer_phone_number,customer_name",
        sortDirection: "ASC",
        columnAliases: JSON.stringify({
          invoice_id: { table: "customer_invoice", column: "id" },
          customer_id: { table: "customer_invoice", column: "customer_id" },
          invoice_date: { table: "customer_invoice", column: "created_at" },
          total_weight: {
            table: "customer_invoice",
            column: "total_weight_drop",
          },
          total_amount: { table: "customer_invoice", column: "total_amount" },
          amount_paid: { table: "customer_invoice", column: "amount_paid" },
          total_amount: { table: "customer_invoice", column: "total_amount" },
          customer_name: { table: "customers", column: "name" },
          customer_phone_number: { table: "customers", column: "phone_number" },
          customer_invoice_status: {
            table: "customer_invoice",
            column: "status",
          },
        }),
        additionalJoins: JSON.stringify([
          {
            joinTableName: "customers",
            joinColumnChild: "customer_id",
            joinColumnParent: "id",
            joinType: "LEFT JOIN",
          },
        ]),

        dynamicConditions: JSON.stringify([
          {
            field: "status",
            operator: "!=",
            value: "paid",
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(
        `${apirURL}/weighing/api/getPagination`,
        { params }
      );
      customerInvoiceDueOrPartialData.value = response.data.data;

      totalPages.value = response.data.pagination.totalPages;
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchCustomerInvoiceDueOrPartial(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      await Promise.allSettled([
        fetchCustomerInvoiceDueOrPartial(),
        fetchUserByID(),
      ]);
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount

      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      window.removeEventListener("resize", handleResize);
    });
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchCustomerInvoiceDueOrPartial();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchCustomerInvoiceDueOrPartial();
    };
    watch(searchValue, () => {
      fetchCustomerInvoiceDueOrPartial();
    });
    return {
      authUserRole,
      permissions,
      dataToRepayment,
      customerInvoiceDueOrPartialData,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      tableClasses,
      selectClasses,
      navClasses,
      searchValue,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      showSuccessToast,
      toast,
    };
  },
};
</script>

<style></style>
