<template>
  <div class="w-full bg-default h-auto">
    <div>
      <WarehouseList />
    </div>
  </div>
</template>

<script>
import WarehouseList from "@/warehouse/WarehouseList.vue";

export default {
  components: {
    WarehouseList,
  },
};
</script>

<style></style>
