<template>
  <div>
    <h2>{{ title }}</h2>
    <h2>User : {{ currentUser }}</h2>
    <h2>{{ formatDate(currentDate) }}</h2>
    <!-- Form to add a new user -->
    <form @submit.prevent="handleSubmit">
      <!-- Input fields for user details -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputEmail">Email</label>
          <input v-model="email" type="email" class="form-control" id="inputEmail" placeholder="Email" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputPassword">Password</label>
          <input v-model="password" type="text" class="form-control" id="inputPassword" placeholder="Password" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputUsername">Username</label>
          <input v-model="username" type="text" class="form-control" id="inputUsername" placeholder="Username" />
        </div>
        <div class="form-group col-md-6">
          <label for="phone_number">Phone Number</label>
          <input v-model="phoneNumber" type="text" class="form-control" id="inputUsername" placeholder="Phone Number" />
        </div>

        <div class="form-group col-md-6">
          <label for="phone_number">format</label>

          <input
            @input="(e) => handleFormatNumber(e)"
            pattern="^[0-9]{1,3}(,[0-9]{3})*([\.,][0-9]+)?$"
            v-model="phoneNumber"
            type="text"
            class="form-control"
            id="inputUsername"
            placeholder="Phone Number"
          />

          <input @input="(e) => handleFormatNumber(e)" pattern="^[0-9]{1,3}(,[0-9]{3})*([\.,][0-9]+)?$"
            v-model="phoneNumber" type="text" class="form-control" id="inputUsername" placeholder="Phone Number" />

        </div>
        <div class="form-group col-md-6">
          <label for="inputRole">Role</label>
          <input v-model="role" type="text" class="form-control" id="inputRole" placeholder="Role" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputImage">Image</label>
          <!-- Conditional rendering for file input -->
          <input type="file" @change="handleImageChange" class="form-control-file" id="inputImage" ref="inputRef" />
          <!-- Display image preview -->
          <div>
            <!-- Show preview of selected image or current user's image when editing -->
            <img :src="getImageUrl('users', imageURL)" alt="Selected Image" class="img-thumbnail" />
            <!-- Button to remove the selected image -->
            <button @click="removeImage" class="btn btn-sm btn-danger">
              Remove
            </button>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary">
        {{ title === "Create new user" ? "Create" : "Update" }}
      </button>
    </form>

    <label for="pageSizeSelect">Items per page:</label>
    <select v-model="pageSize" id="pageSizeSelect" @change="changePageSize">
      <option value="2">2</option>
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </select>

    <input type="text" v-model="searchQuery" id="searchInput" placeholder="Enter search query" />
    <button class="btn btn-sm btn-primary" @click="handleSearch">
      Search Now
    </button>

    <input type="text" v-model="searchValue" id="searchInput" placeholder="serach by name" />

    <label for="sortSelect">Sort by:</label>
    <select v-model="sortColumn" id="sortSelect" @change="sortUsers">
      <option value="username">Username</option>
      <option value="email">Email</option>
      <option value="role">Role</option>
    </select>

    <label for="filterSelect">Filter by Role:</label>
    <select v-model="filterValue" id="filterSelect">
      <option value="Admin">Admin</option>
      <option value="User">User</option>
    </select>

    <!-- Table to display existing users with edit and delete actions -->
    <table class="table mt-4">
      <thead>
        <tr>
          <th>Image</th>
          <th>Username</th>
          <th>Email</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="users.length > 0">
        <tr v-for="user in users" :key="user.id">
          <td>
            <img :src="getImageUrl('users', user.image_url)" class="medium-image" alt="" />
          </td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role }}</td>
          <td>
            <button @click="editUser(user)" class="btn btn-sm btn-primary">
              Edit
            </button>
            <button @click="deleteUser(user.id, user.image_url)" class="btn btn-sm btn-danger">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="text-center">No Data</td>
        </tr>
      </tbody>
    </table>

    <nav aria-label="Page navigation example" v-if="users.length > 0 && searchQuery == ''">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click="goToPage(currentPage - 1)">Previous</a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click="goToPage(currentPage + 1)">Next</a>
        </li>
      </ul>
    </nav>

    <button @click="handleLogout" type="button" class="btn btn-success">
      Logout
    </button>

    <button @click="deleteImage('users', 'tech_idea.jpg')" type="button" class="btn btn-success">
      Delete Image
    </button>

    <button style="margin-left: 20px" @click="updateJSONBSpecific" type="button" class="btn btn-success">
      update jsonb
    </button>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch, computed } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import socket from "@/services/socket";
import { fetchTimestamp } from "@/services/timestamp";
import { uploadImage, deleteImage } from "@/composables/upload";
import { getImageUrl } from "@/composables/getImage";
import apirURL from "@/services/apiURL";
import { formatDate } from "@/composables/formatDateTime";

export default {
  name: "HomeView",
  setup() {
    // Define reactive variables for form fields and user data
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const role = ref("");
    const users = ref([]);
    const imageFile = ref(null);
    const title = ref("Create new user");
    const userID = ref(null);
    const phoneNumber = ref("");
    const router = useRouter();
    const currentUser = ref("");
    const userAuthID = ref(null);
    // const inputNumber = ref("");


    const number = ref(null);
    const handleFormatNumber = (event) => {
      // Remove non-numeric characters and format as currency
      let value = event.target.value.replace(/[^\d.]/g, "");
      number.value = value;
      // Regular expression to add commas for every three digits
      phoneNumber.value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      console.log("phone number", Number(number.value));
    };


    let originalObj={
      supplier_owe:0,
      owe_supplier:20
    }

    function updateValue(obj, supplier_owe, owe_supplier, valueToAdd) {
      if (obj.hasOwnProperty(owe_supplier)) {
        if (obj[owe_supplier] > 0) {
          if (valueToAdd > obj[owe_supplier]) {

            obj[supplier_owe] += valueToAdd - obj[owe_supplier]
            obj[owe_supplier] = 0;
          } else {
            obj[owe_supplier] -= valueToAdd;
          }
        } else {
       

          if (obj.hasOwnProperty(supplier_owe)) {            
            // Add the new value to the existing value
            obj[supplier_owe] += valueToAdd;
          }

        }
      }
      return obj;
    }

    const updatedObject = updateValue(originalObj, 'supplier_owe','owe_supplier', 30);

    console.log("updated object",updatedObject)



    const searchQuery = ref("");

    // Function to fetch existing users
    const currentPage = ref(1);
    const pageSize = ref(2); // Default page size
    const totalPages = ref(0);
    const table = ref("users");
    const searchField = ref("username");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");

    watch(searchValue, async () => {
      await fetchUsers();
    });

    const fetchUsers = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: table.value,
          columnSearch: "username",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,
          dynamicConditions: JSON.stringify([]),
        };

        const response = await axios.get(apiUrl, { params });
        users.value = response.data.data;

        console.log("users data", users);

        totalPages.value = response.data.pagination.totalPages;
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };
    const Key1 = ref(null);
    const admin_fee = ref({});

    const fetchDataByID = async () => {
      try {
        const tableName = ref("weighing");
        const id = ref(15);
        let url = `http://localhost:3000/weighing/api/getDataByID/${tableName.value}/${id.value}`;
        const response = await axios.get(url);

        const data = response.data;

        console.log("data by id", data);
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const fetchAllData = async () => {
      try {
        let url = `http://localhost:3000/weighing/api/getAllData`;

        const columnAliases = {
          id: "id",
          title: "book_title",
        };

        const params = {
          tableName: "book",
          columnAliases: JSON.stringify(columnAliases),
          sortColumn: "id",
          sortDirection: "",
          joinTableName: "",
          joinColumnChild: "",
          joinColumnParent: "",
          joinType: "",
          // dynamicConditions: JSON.stringify([
          //   {
          //     field: "name",
          //     operator: "->>", //use for single object
          //     key: "key1",
          //     value: true, //use for nested object
          //     type: "jsonb",
          //   },
          //   // {
          //   //   field: "name",
          //   //   operator: "@>", //use for array
          //   //   key: "product",
          //   //   value: { name: "dara" }, //use for nested object
          //   //   type: "jsonb",
          //   // },
          // ]),
        };

        // dynamicConditions: JSON.stringify([
        //     {
        //       field: "name",
        //       operator: "@>",//use for array
        //       key: "product",
        //       value: { name: "dara" }, //use for nested object
        //       type: "jsonb",
        //     },
        //   ]),

        // {
        //   field: "plate_number",
        //   operator: "=",
        //   value: "AC-345",
        //   typeTable: "table",
        // },
        // { field: "weight_in", operator: "=", value: 8, typeTable: "table" },

        //     const dynamicConditions = [
        //   { field: "assign_task", operator: "ANY", value: "adds", type: "array" }, //query array multiple
        // ];

        // const dynamicConditions = [
        //   { field: "name", operator: "->>", key: "key1", value: "a", type: "jsonb" }, //query jsonb only one object
        // ];

        // dynamicConditions: JSON.stringify([  //query jsonb for boolean and number for multiple object of array
        //     {
        //       field: "name",
        //       operator: "@>",
        //       key: "key1",
        //       value: 4000,
        //       type: "jsonb",
        //     },
        //   ]),

        const response = await axios.get(url, { params });

        const data = response.data;

        console.log("data join all table", data);

        //console.log("All data without paginatin", data);

        // data.forEach((d) => {
        //   if (d.name) {
        //     d.name.forEach((n) => {
        //       if (n.product && typeof n.product === "object") {
        //         console.log("product", n.product.name);
        //       }

        //       if (n.admin_fee && typeof n.admin_fee === "object") {
        //         admin_fee.value = n.admin_fee;
        //       }

        //       // console.log("admin fee", n.admin_fee);
        //     });
        //   }
        // });
        //loop through column name jsonb array object
        // data.forEach((d) => {
        //   if (d.name && typeof d.name === "object") {
        //     if (d.name !== undefined) {
        //       // Key1.value = d.name.key1;

        //       // console.log("key1", Key1.value);
        //       console.log("address", d.name.address.city);
        //     }
        //   }
        // });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleSearch = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3000/weighing/api/searchSpecific`,
          {
            params: {
              tableName: "book",
              columnSearch: "id",
              valueSearch: 14,
            },
          }
        );
        users.value = response.data;
        console.log("user data by search", response.data);

        currentPage.value = 1;

        // Reset current page to 1 when performing a search
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };

    const fetchDataWithJoinSearch = async () => {
      try {
        // Make a GET request to the endpoint where your function is hosted
        const response = await axios.get(
          "http://localhost:3000/weighing/api/searchjoin",
          {
            params: {
              tableName: "book2",
              searchValue: "Stephen",
              joinTableName: "author",
              joinSearchColumn: "name",
              joinColumnChild: "author_id",
              joinType: "LEFT JOIN",
              joinColumnParent: "author_id",
            },
          }
        );

        // Handle the response data
        console.log("Data fetched successfully search:", response.data);
      } catch (error) {
        // Handle errors
        console.log("Failed to fetch data:", error);
      }
    };

    const fetchDataPaginationWithJoin = async () => {
      try {
        let apiUrl = `http://localhost:3000/weighing/api/getPaginationwithjoin`;

        const params = {
          tableName: "book2",
          columnSearch: "",
          search: "en",
          page: 1,
          pageSize: 10,
          sortColumn: "author_id",
          sortDirection: "ASC",
          filterColumn: "",
          filterValue: "",
          joinTableName: "author",
          joinColumnChild: "author_id",
          joinColumnParent: "author_id",
          joinColumnSearch: "name",
          dynamicConditions: [],
        };

        const response = await axios.get(apiUrl, {
          params,
        });

        // console.log("book data with pagination serach join table column", response.data.data);
        // console.log("response totalPage", response.data.pagination.totalPages);
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };

    watch(searchQuery, async () => {
      await fetchUsers();
    });

    watch(filterValue, async () => {
      await fetchUsers();
    });

    watch(sortColumn, async () => {
      await fetchUsers();
    });

    // Function to handle pagination actions
    const goToPage = async (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        await fetchUsers();
      }
    };

    const changePageSize = async () => {
      currentPage.value = 1; // Reset to the first page
    };

    watch(pageSize, async () => {
      await fetchUsers();
    });

    const updateJSONBSpecific = async () => {
      try {
        const requestBody = {
          tableName: "users",
          id: 419,
          columnNameJSONB: "json_col",
          keyToUpdateForCondition: "currency_id",
          valueKeyToUpdateCondition: 2,
          keyToUpdate: "key1",
          // valueToUpdate: JSON.stringify({ //nested object
          //   name:"sok dara"
          // }),
          valueToUpdate: JSON.stringify("seojin"), //not nested object
        };

        // Make a POST request to your backend endpoint
        const response = await axios.patch(
          "http://localhost:3000/weighing/api/updateDataJSONB",
          requestBody
        );

        // Handle the response if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error updating record:", error);
      }
    };

    const handleClearField = () => {
      username.value = null;
      password.value = null;
      oldOriginalPassword.value = null;
      oldPasswordHash.value = null;
      role.value = null;
      email.value = null;

      imageFile.value = null;
      imageURL.value = null;

      // Clear the value of the file input element to clear the displayed file name
      inputRef.value.value = "";
    };
    // Function to handle form submission
    const handleSubmit = async () => {
      try {
        const timestamp = await fetchTimestamp();

        const riceData = [
          { rice_id: "rice123", price: 10.99, amount: 5 },
          { rice_id: "rice456", price: 8.99, amount: 3 },
          { rice_id: "rice789", price: 12.99, amount: 7 },
        ];

        const requestBody = {
          tableName: "users",

          //   fields: {
          //   customer_id: 1,
          //   user_id: 1,
          //   type_of_rice: JSON.stringify(riceData),  //converts a JavaScript object or value to a JSON string
          //   status: true,
          //   note:"nothing",
          //   created_at: timestamp
          // },

          fields: {
            username: username.value,
            name_lowercase: username.value.toLowerCase(),
            password: password.value,
            email: email.value,
            role: role.value,
            //assign_task: [],
            status: true,
            image_url: imageURL.value ? imageURL.value : imgURL.value,
            created_at: timestamp,
            reset_token: "",
            reset_at: null,
          },
        };
        if (title.value === "Create new user") {
          const response = await axios.post(
            "http://localhost:3000/weighing/api/insertData",
            requestBody
          );
          console.log(response.data.message);
          handleClearField();
        } else {
          const response = await axios.patch(
            `http://localhost:3000/weighing/api/updateData/${userID.value}`,
            requestBody
          );
          console.log(response);
          title.value = "Create new user";
          handleClearField();
        }
      } catch (error) {
        console.error("Error inserting record:", error);
      }
    };

    const imagePreview = ref(null);

    const imageURL = ref(null);
    watch(imageFile, async (newV) => {
      if (newV !== null) {
        imageURL.value = await uploadImage(newV, "users", "AWS");

        console.log("image url", imageURL);
      }
    });

    const inputRef = ref(null);

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      // Set the selected image file
      imageFile.value = file;
    };

    const handleLogout = async () => {
      try {
        const response = await axios.post(
          "http://localhost:3000/weighing/api/logout"
        );
        console.log(response);
        // Clear the JWT token from local storage
        localStorage.removeItem("token");

        router.push("/login");
      } catch (err) {
        console.log("failed to logout", err);
      }
    };

    const removeImage = (event) => {
      event.preventDefault(); // Prevent default form submission
      deleteImage("users", imageURL.value);
      imageFile.value = null;
      imageURL.value = null;

      // Clear the value of the file input element to clear the displayed file name
      inputRef.value.value = "";
    };
    const imgURL = ref(null);
    const oldPasswordHash = ref(null);
    const assign_task = ref(null);
    const oldOriginalPassword = ref(null);
    // Function to handle edit action for a user
    const editUser = async (user) => {
      title.value = "Update User";

      oldOriginalPassword.value = user.original_password;
      oldPasswordHash.value = user.password;
      email.value = user.email;
      userID.value = user.id;
      username.value = user.username;
      // password.value = user.password;

      const responseDycrypt = await axios.post('http://localhost:3000/weighing/api/decryptPassword', {
        encryptedPassword: user.password
      });


      console.log("repsonse dycrypt", responseDycrypt)

      password.value = responseDycrypt.data.decryptedPassword



      //       assign_task.value=user.assign_task;

      //       console.log("assign_task",assign_task)
      //       let updatedTasks = assign_task.value.map(task => {
      //     // Check if the task has the 'reset_password' property
      //     if ('reset_password' in task) {
      //         // If yes, update only the 'reset_password' property
      //         task.reset_password = true;
      //     }
      //     if('users' in task){
      //       task.delete=true;
      //       task.update=false;
      //     }
      //     return task; // Return the task whether modified or not
      // });

      // console.log("update taks", updatedTasks);

      role.value = user.role;
      imgURL.value = user.image_url;
      // Check if the user has an image
      if (user.image_url) {
        // If yes, set the image preview to the current user's image
        imagePreview.value = user.image_url;
      }
    };

    const handleFetchByID = async () => {
      try {
        const encryptPassword = ref(null);
        const id = 1;
        const response = await axios.get(
          `http://localhost:3000/weighing/api/getDataByID/${"users"}/${id}`
        );
        // console.log("get data by id", response.data);
        response.data.forEach((d) => {
          // console.log("password", d.password);

          encryptPassword.value = d.password;
        });

        // const responseDycrypt = await axios.post('http://localhost:3000/weighing/api/decryptPassword', {
        //   encryptedPassword: encryptPassword.value
        // });
        // console.log("password decrypt",responseDycrypt.data)
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const cleardata = () => {
      email.value = null;
      userID.value = null;
      username.value = null;
      password.value = null;
      role.value = null;
      imageFile.value = null;
      phoneNumber.value = null;
    };
    // Function to handle delete action for a user
    const deleteUser = async (userId, image_url) => {
      try {
        const tableName = "users";
        // Check if the last record on the current page is being deleted
        const isLastRecordOnPage =
          users.value.length === 1 && currentPage.value !== 1;

        // Delete the user
        const response = await axios.delete(
          `http://localhost:3000/weighing/api/deleteData/${userId}`,
          {
            data: { tableName: tableName },
          }
        );
        console.log("delete success", response);
        if (image_url) {
          deleteImage("users", image_url);
        }

        // If the last record on the current page is being deleted, emit an event to the server
        if (isLastRecordOnPage) {
          socket.emit("lastRecordDeleted");
        }
      } catch (err) {
        console.error("Failed to delete user", err);
      }
    };

    const authUserID = ref(null);

    // Socket event handler for database updates
    const handleDatabaseUpdate = () => {
      fetchUsers(); // Call fetchUsers when database updates occur
    };

    const currentDate = ref(null);

    const updateTime = async () => {
      currentDate.value = await fetchTimestamp();

    };

    // Update the time every second
    let intervalId;


    // Clear the interval when the component is unmounted
    onUnmounted(() => {
      clearInterval(intervalId);
    });



    onMounted(async () => {

      updateTime(); // Initial call to set the current time
      intervalId = setInterval(updateTime, 1000);



      // Listen for 'lastRecordDeleted' event from server
      socket.on("lastRecordDeleted", () => {
        goToPage(currentPage.value - 1);
      });

      socket.on("database_realTime", handleDatabaseUpdate);

      // fetchAllData();

      handleFetchByID();
      // fetchDataWithJoinSearch();
      // fetchUsers();

      fetchDataByID();
      handleSearch();

      fetchUsers();

      // fetchDataPaginationWithJoin();


    });


    // Clean up: Unsubscribe from 'database_update' event on component unmount
    onUnmounted(() => {
      socket.off("database_realTime", handleDatabaseUpdate);
      clearInterval(intervalId);
    });
    return {
      formatDate,
      currentDate,
      searchValue,
      admin_fee,
      Key1,
      updateJSONBSpecific,
      deleteImage,
      inputRef,
      currentUser,
      phoneNumber,
      handleSearch,
      filterValue,
      sortColumn,
      handleLogout,

      // validateInput,

      // inputNumber,
      handleFormatNumber,
      searchQuery,
      currentPage,
      pageSize,
      totalPages,
      goToPage,
      changePageSize,

      removeImage,
      imagePreview,
      title,
      imageURL,

      getImageUrl,
      username,
      email,
      password,
      role,
      users,
      handleSubmit,
      editUser,
      deleteUser,
      handleImageChange,
    };
  },
};
</script>

<style>
.medium-image {
  width: 150px;
  /* Adjust width as needed */
  height: auto;
  /* Maintain aspect ratio */
}

/* Add custom styles here if needed */
</style>
