<template>
  <div class="w-full">
    <!-- supplier bill purchase  -->
    <div>
      <div class="relative overflow-x-auto border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <!-- <td class="min-w-[100px]  border-b">Acion</td> -->

              <td class="min-w-[95px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Bill No</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Created At</h2>
                  <!-- <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div> -->
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Weight</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Cost</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Sack Expense</h2>
                </div>
              </td>
              <!-- <td class="min-w-[120px]  border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Pre-Loan</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td>
              <td class="min-w-[120px]  border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>To Be Paid</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td> -->
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Status</h2>
                  <!-- <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div> -->
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="invoice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in invoice"
              :key="data"
            >
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">
                {{ numeralFormat(data.total_amount, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ numeralFormat(data.amount_paid, "0,0[.]00") }} ៛
              </td>
              <td class="border-b">
                {{
                  numeralFormat(
                    data.weighing[0]?.total_sack_expense,
                    "0,0[.]00"
                  )
                }}
                ៛
              </td>
              <!-- <td class="border-b">$2999</td>
              <td class="border-b">$2999</td> -->
              <td class="border-b">
                {{ data.status ? data.status : "no data" }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="6">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import { onMounted, ref } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import Pagination from "@/components/Pagination.vue";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: ["supplier_invoice", "datatoedit"],
  components: {
    Pagination,
    NoDataComponet,
  },
  setup(props, { emit }) {
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const sortSuppliers = (field, sortDirection) => {
      if (field === "total_sack_expense") {
        invoice.value.sort((a, b) => {
          // Check if type_of_rice exists and get the amount value for comparison
          const amountA = a.weighing[0]?.total_sack_expense || 0;
          const amountB = b.weighing[0]?.total_sack_expense || 0;

          if (sortDirection === "asc") {
            return amountA - amountB; // Ascending order
          } else {
            return amountB - amountA; // Descending order
          }
        });
      } else if (field === "id") {
        toggleSort(sortDirection, invoice.value, field);
      } else if (field === "total_amount") {
        toggleSort(sortDirection, invoice.value, field);
      } else if (field === "amount_paid") {
        toggleSort(sortDirection, invoice.value, field);
      }
    };

    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("supplier_invoice");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const invoice = ref([]);
    const fetch_invoice = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        // columnSearch: "name, phone_number",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "supplier_id",
            operator: "=",
            value: props.datatoedit.id,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      invoice.value = response.data.data;
      totalPages.value = response.data.pagination.totalPages;
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetch_invoice();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetch_invoice();
    };
    onMounted(async () => {
      await Promise.allSettled([fetch_invoice()]);
    });
    return {
      formatDate2,
      sortSuppliers,
      invoice,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
    };
  },
};
</script>

<style></style>
