<template>
  <div>
    <form @submit.prevent="handleResetPassword">
      <div class="mb-3">
        <label for="exampleInputPassword1" class="form-label"
          >New Password</label
        >
        <input
          type="password"
          v-model="newPassword"
          class="form-control"
          id="exampleInputPassword1"
        />
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label"
          >Confrim Password</label
        >
        <input
          type="password"
          v-model="confirmPassword"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import axios from "axios";
import { fetchTimestamp } from "@/services/timestamp";

export default {
  setup() {
    const route = useRoute();
    const reset_token = ref(route.params.reset_token);

    console.log("reset token", reset_token);
    const confirmPassword = ref(null);
    const newPassword = ref(null);

    const handleResetPassword = async () => {
      try {
        if (confirmPassword.value != newPassword.value) {
          console.log("confirm password or password must be match");
        } else {
          const timestamp = await fetchTimestamp();
          const response = await axios.patch(
            `http://localhost:3000/weighing/api/resetPassword/${reset_token.value}`,
            {
              newPassword: newPassword.value,
              reset_at: timestamp,
            }
          );
          console.log("password update success", response);
        }
      } catch (err) {
        if (err.response.status === 401) {
          console.log(
            "this reset link has been expired ,Please requset reset again "
          );
        } else {
          console.log("failed to update password", err);
        }
      }
    };

    return {
      handleResetPassword,
      confirmPassword,
      newPassword,
    };
  },
};
</script>
  
  <style>
</style>