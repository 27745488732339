<template>
  <!--  from trucl transition -->
  <div class="w-full">
    <div>
      <div class="relative overflow-x-auto border h-[400px]">
        <table>
          <tbody>
            <tr class="header_table">
              <!-- <td class="min-w-[100px]  border-b">Acion</td> -->

              <td class="min-w-[95px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>No</h2>
                  <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Created At</h2>
                  <!-- <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('id', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div> -->
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Supplier</h2>
                  <!-- <div class="flex flex-col -space-y-1 items-center">
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('name', 'asc')"
                      :icon="['fas', 'caret-up']"
                      class="cursor-pointer"
                    />
                    <font-awesome-icon
                      @click.prevent="sortSuppliers('name', 'desc')"
                      :icon="['fas', 'caret-down']"
                      class="cursor-pointer"
                    />
                  </div> -->
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Truck</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Total Weight</h2>
                </div>
              </td>
              <td class="min-w-[120px] border-b text-start">
                <div class="flex items-center justify-start gap-3">
                  <h2>Status</h2>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="weighing.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing"
              :key="data"
            >
              <!-- <td class="border-b">
                <div class="flex items-center gap-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-7 text-green-600 cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather cursor-pointer feather-trash-2 text-primary4"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </div>
              </td> -->
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ getName(data.supplier_id) }}</td>
              <td class="border-b">{{ data.plate_number.plate_number }}</td>
              <td class="border-b">
                {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                {{ data.status ? "hold" : "don't hold" }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate2 } from "@/composables/formatDateTime";
import { ref, onMounted } from "vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import moment from "moment-timezone";
import NoDataComponet from "@/components/NoDataComponet.vue";
export default {
  props: ["formTruck", "datatoedit"],
  components: {
    Pagination,
    NoDataComponet,
  },
  setup(props, { emit }) {
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const supplier = ref([]);
    const fetchSupplier = async () => {
      try {
        const req = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        supplier.value = res.data;
        console.log("customers data", supplier);
      } catch (error) {
        console.log(error);
      }
    };
    const plate_numbers = ref([]);
    const fetchPlates = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const res = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });
        plate_numbers.value = res.data;
        console.log(" data", plate_numbers);
      } catch (error) {
        console.log(error);
      }
    };

    const getName = (id) => {
      const suppliers = supplier.value.find((item) => item?.id === id);
      return suppliers?.name;
    };
    const getPlateName = (id) => {
      const plate = plate_numbers.value.find((item) => item?.id === id);
      return plate?.plate_numbers;
    };
    onMounted(async () => {
      await Promise.allSettled([
        fetchSupplier(),
        fetchPlates(),
        fetchWeighing(),
      ]);
    });

    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("weighing");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const weighing = ref([]);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        // columnSearch: "name, phone_number",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "supplier_id",
            operator: "=",
            value: props.datatoedit.id,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: ">=",
            value: fromDateFormatted.value,
            typeTable: "table",
          },
          {
            field: "created_at",
            operator: "<=",
            value: toDateFormatted.value,
            typeTable: "table",
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing.value = response.data.data;
      totalPages.value = response.data.pagination.totalPages;
    };
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighing();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighing();
    };

    const sortSuppliers = (feild, sortDirection) => {
      if (feild === "is_hold") {
        toggleSort(sortDirection, weighing.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, weighing.value, feild);
      } else if (feild === "weight_in") {
        toggleSort(sortDirection, weighing.value, feild);
      } else if (feild === "plate_number_id") {
        toggleSort(sortDirection, weighing.value, feild);
      }
    };

    return {
      formatDate2,
      getName,
      getPlateName,
      sortSuppliers,
      weighing,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
    };
  },
};
</script>

<style></style>
