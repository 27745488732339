<template>
  <div class="w-full relative text-primary6">
    <div class="w-full flex items-center justify-between">
      <div>
        <h2>{{ t("message.parking_list") }}</h2>
      </div>
      <div
        v-if="
          authUserRole === 'Super Admin' ||
          (authUserRole === 'User' &&
            permissions.module_parking?.parking_list?.create === true)
        "
      >
        <button @click="handleAdd">{{ t("message.new_parking") }}</button>
      </div>
    </div>

    <div :class="tableClasses" class="h-[600px]">
      <table>
        <tbody>
          <tr class="header_table">
            <td class="border-b text-start">Created At</td>

            <td
              @click="sortData('name')"
              class="text-start text-nowrap toggle_sort border-b"
            >
              {{ t("message.name") }}
              <span class="ml-2 cursor-pointer">
                <transition name="fade" mode="out-in">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      sortColumns === 'name' && sortDirections === 'asc'
                        ? 'sort-up'
                        : sortColumns === 'name' && sortDirections === 'desc'
                        ? 'sort-down'
                        : 'sort',
                    ]"
                  />
                </transition>
              </span>
            </td>
            <td class="border-b text-start">
              <div class="flex items-center justify-between">
                {{ t("message.status") }}
                <div>
                  <!-- <input
                    type="text"
                    v-model="searchValue"
                    placeholder="Search by name"
                  /> -->
                </div>
              </div>
            </td>
            <td class="border">{{ t("message.action") }}</td>
          </tr>
        </tbody>

        <tbody v-if="parking.length > 0">
          <tr
            class="text-body font-normal text-primary2 bg-white"
            v-for="data in parking"
            :key="data.id"
          >
            <td class="border-b">{{ formatDate2(data.created_at) }}</td>

            <td class="border-b">{{ data.name }}</td>
            <td class="border-b">
              <el-button
                @click="handleUpdateStatus(data)"
                v-if="data.status === true"
                type="success"
                round
                >Active</el-button
              >
              <el-button
                @click="handleUpdateStatus(data)"
                v-else
                type="warning"
                round
              >
                Inactive
              </el-button>
            </td>
            <td class="border-b w-[100px] border">
              <div class="flex items-center gap-3">
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_parking?.parking_list?.update === true)
                  "
                  @click="handleEditdata(data)"
                  type="mdi"
                  :path="mdiPencil"
                  class="edit"
                ></svg-icon>
                <svg-icon
                  v-if="
                    authUserRole === 'Super Admin' ||
                    (authUserRole === 'User' &&
                      permissions.module_parking?.parking_list?.delete === true)
                  "
                  type="mdi"
                  @click="openDeleteConfirmation(data)"
                  :path="mdiTrashCanOutline"
                  class="delete"
                ></svg-icon>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center"><NoDataComponet /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-full flex items-center justify-between mt-8">
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :totalRecord="parking.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
        isLastRecordOnPage="isLastRecordOnPage"
        :tableName="tableName"
      />
    </div>
  </div>

  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="handleClose" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed top-[7rem] inset-0 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-[360px] bg-default">
              <div>
                <component
                  @close="handleClose"
                  :is="currentComponent"
                  :datatoedit="datatoedit"
                  @toast="showSuccessToast"
                ></component>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot appear :show="isUpdateStatus" as="template">
    <Dialog as="div" @close="handleCloseStatus" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed top-[7rem] inset-0 overflow-y-auto">
        <div class="flex min-h-full items-start justify-center text-center">
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="bg-default">
              <div>
                <component
                  @close="handleCloseStatus"
                  :is="currentComponent"
                  :isStatus="isStatus"
                  @toast="showSuccessToast"
                ></component>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <DeleteConfrimation
    :isOpen="isDeleteOpen"
    :itemId="itemIdToDelete"
    :tableName="table"
    :deleteDoc="deletedata"
    @close="closeDeleteDialog"
    @confirm="handleConfirmDelete"
    @toast="showSuccessToast"
  />
  <Toast ref="toast" />
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import ParkingFormVue from "@/form/ParkingForm.vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import Pagination from "@/components/Pagination.vue";
import socket from "@/services/socket";
import toggleSort from "@/composables/sortData";
import { formatTime, formatDate2 } from "@/composables/formatDateTime";
import Toast from "@/components/Toast.vue";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { mdiTrashCanOutline, mdiPencil } from "@mdi/js";
import { decodeJwt } from "@/composables/decodeJWT";
import { useI18n } from "vue-i18n";

export default {
  components: {
    ParkingFormVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Pagination,
    DeleteConfrimation,
    Toast,
    UpdateStatus,
    NoDataComponet,
  },
  setup() {
    const parking = ref([]);
    const currentComponent = ref("");
    const isOpen = ref(false);

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("parking_rice");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");

    const FetchParking = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: table.value,
          columnSearch: "name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(apiUrl, { params });
        parking.value = response.data.data;
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.log(error);
      }
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);
        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );
        authUserRole.value = response.data[0]?.role;
        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const datatoedit = ref(null);
    const handleEditdata = (item) => {
      datatoedit.value = item;
      currentComponent.value = "ParkingFormVue";
      console.log(item, currentComponent.value);
      isOpen.value = true;
    };
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "ParkingFormVue";
      datatoedit.value = null;
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([FetchParking(), fetchUserByID()]);
    };
    onMounted(async () => {
      await Promise.allSettled([FetchParking(), fetchUserByID()]);
      tableName.value = "parking_rice";
      socket.on("database_realTime", handleDatabaseUpdate);

      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    watch(searchValue, () => {
      FetchParking();
    });
    const tableName = ref("");
    const handlePageChange = async (page) => {
      currentPage.value = page;
      await FetchParking();
    };

    const handlePageSizeChange = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await FetchParking();
    };
    const sortSuppliers = (feild, sortDirection) => {
      if (feild === "name") {
        toggleSort(sortDirection, parking.value, feild);
      } else if (feild === "id") {
        toggleSort(sortDirection, parking.value, feild);
      }
    };
    const isLastRecordOnPage = ref(null);

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      socket.off("database_realTime");
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    // toast
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "cannotupdate":
            message = "You are not allowed to updated , Contact your admin";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };

    const deletedata = ref(null);
    const openDeleteConfirmation = (doc) => {
      deletedata.value = doc;
      isDeleteOpen.value = true;
    };
    const isDeleteOpen = ref(false);
    const itemIdToDelete = ref(null);
    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deletedata.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(`${apirURL}/weighing/api/deleteData/${doc.id}`, {
          data: { tableName: table.value },
        });
        await FetchParking();
        if (isLastPage && customer.value.length === 0) {
          currentPage.value = 1;
          await FetchParking(); // Fetch data for page 1
        }

        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };

    const isUpdateStatus = ref(false);
    const isStatus = ref(null);
    const handleUpdateStatus = (doc) => {
      if (
        authUserRole.value === "Super Admin" ||
        (authUserRole.value === "User" &&
          permissions.value.module_parking?.parking_list?.update === true)
      ) {
        isUpdateStatus.value = true;
        isStatus.value = doc;
        currentComponent.value = "UpdateStatus";
      } else {
        showSuccessToast("cannotupdate", "error");
      }
    };
    const handleCloseStatus = () => {
      isUpdateStatus.value = false;
      currentComponent.value = "";
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;
      parking.value = toggleSort(direction, parking.value, field);
    };
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return {
      authUserRole,
      permissions,
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      parking,
      searchValue,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      tableName,
      isLastRecordOnPage,
      table,
      sortSuppliers,
      formatTime,
      formatDate2,
      tableClasses,
      handleEditdata,
      datatoedit,
      toast,
      showSuccessToast,
      handleConfirmDelete,
      openDeleteConfirmation,
      closeDeleteDialog,
      itemIdToDelete,
      isDeleteOpen,
      deletedata,

      // update Status
      handleUpdateStatus,
      handleCloseStatus,
      isStatus,
      isUpdateStatus,
      mdiTrashCanOutline,
      mdiPencil,
      sortColumns,
      sortDirections,
      sortData,
      // translate functions
      fontClass,
      t,
      changeLanguage,
    };
  },
};
</script>

<style></style>
