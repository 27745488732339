<template>
  <div
    class="bg-default overflow-hidden relative animate-fade-up animate-duration-300 animate-delay-100"
  >
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"><span class="text-primary4 mr-2"></span></label>
        <div class="flex items-center justify-center gap-2">
          <IconField class="w-[80%] placeholder:text-body2">
            <InputText v-model="integerSearchValue" placeholder="Search | ID" />
            <InputIcon class="pi pi-search" />
          </IconField>
        </div>
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div>
      <div>
        <h2 class="py-2">Sack Invoice Transaction</h2>
      </div>
      <div :class="tableClasses">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[130px] text-nowrap border-b text-start">
                Created At
              </td>
              <td
                @click="sortData('id')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Sack Ref
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'id' && sortDirections === 'asc'
                          ? ['fas', 'sort-up']
                          : ['fas', 'sort-down']
                      "
                    />
                  </transition>
                </span>
              </td>

              <td
                @click="sortData('amount_paid')"
                class="text-start text-nowrap border-b toggle_sort"
              >
                Amount
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'amount_paid' &&
                        sortDirections === 'asc'
                          ? ['fas', 'sort-up']
                          : ['fas', 'sort-down']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="min-w-[130px] text-nowrap border-b text-start">
                Note
              </td>
              <td class="border-b">Status</td>
              <td class="border">Acion</td>
            </tr>
          </tbody>
          <tbody v-if="invoice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in invoice"
              :key="data"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">
                {{ numeralFormat(data.amount_paid, "0,0[.]00") }} ៛
              </td>
              <td class="border-b">{{ data.note ? data.note : "No Data" }}</td>

              <td class="border-b">
                <el-button type="warning" round>Due</el-button>
              </td>
              <td class="border-b">
                <div class="flex items-center gap-3">
                  <svg-icon
                    @click="handlePrint(data.id)"
                    type="mdi"
                    :path="mdiEyeOutline"
                    class="info"
                  ></svg-icon>
                  <svg
                    @click="openModal(data)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-primary4 delete"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="6">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="invoice.length > 0"
        class="w-full flex items-center justify-between mt-8"
      >
        <Pagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          :pageSize="pageSize"
          :totalRecord="totalRecord"
          @pageChange="handlePageChange"
          @pageSizeChange="handlePageSizeChange"
        />
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[7rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-[460px] bg-white rounded-lg p-4">
                <DialogTitle
                  class="text-lg text-start font-medium text-gray-900"
                >
                  Confirm Delete
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-nowrap text-start">
                    Are You sure you want to delete sack invoice ID [
                    {{ selectedDoc.id }} ]
                  </p>
                </div>
                <div class="mt-4 flex justify-end gap-2">
                  <button
                    class="bg-gray-200 px-4 py-2 rounded text-primary2"
                    @click="closeModal"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-red-600 text-white px-4 py-2 rounded"
                    @click="confirmDelete"
                  >
                    Delete
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
  <Toast ref="toast" />
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import SackSlipTransactionDetails from "./SackSlipTransactionDetails.vue";
import { mdiEyeOutline, mdiPencil } from "@mdi/js";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  watch,
} from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { formatDate2 } from "@/composables/formatDateTime";
import apirURL from "@/services/apiURL";
import axios from "axios";
import moment from "moment-timezone";
import Pagination from "@/components/Pagination.vue";
import toggleSort from "@/composables/sortData";
import { useRouter } from "vue-router";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SackSlipTransactionDetails,
    Pagination,
    Toast,
    NoDataComponet,
  },
  setup() {
    const router = useRouter();
    const currentComponent = ref("");
    const isOpen = ref(false);
    const handleAdd = () => {
      isOpen.value = true;
      currentComponent.value = "SackSlipTransactionDetails";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });
    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();
    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("id");
    const sortDirection = ref("DESC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const table = ref("fee_bag_invoice");
    const searchValue = ref("");
    const integerSearchValue = ref(null);
    const invoice = ref([]);
    const totalRecord = ref(0);
    const fetchInvoice = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: table.value,
          // columnSearch: "",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,
          sortDirection: sortDirection.value,
          sortColumn: sortColumn.value,

          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                  },
                ]
              : []),

            ...(integerSearchValue.value
              ? [
                  {
                    field: "id",
                    operator: "=",
                    value: parseInt(integerSearchValue.value, 10),
                  },
                ]
              : []),
          ]),
        };
        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          {
            params,
          }
        );
        invoice.value = response.data.data;
        totalRecord.value = invoice.value.length;
        console.log("invoice", invoice.value);
      } catch (error) {
        console.log("error fetching invoice", error);
      }
    };
    const handlePrint = (id) => {
      router.push({
        name: "printsackslip",
        params: { id },
      });
    };
    const handleFilter = async () => {
      await fetchInvoice();
    };
    watch(integerSearchValue, async () => {
      await fetchInvoice();
    });

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchInvoice();
    };
    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      fetchInvoice();
    };
    const sortColumns = ref(null);
    const sortDirections = ref("asc");
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortColumns.value = field;
      sortDirections.value = direction;

      invoice.value = toggleSort(direction, invoice.value, field);
    };

    const isModalOpen = ref(false);
    const selectedDoc = ref(null);
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const openModal = (doc) => {
      console.log("doc", doc);
      if (doc.id) {
        selectedDoc.value = doc;
        isModalOpen.value = true;
      } else {
        toast.value.showToast("Can not delete ", "error");
        return;
      }
    };
    const invoice_last_record = ref([]);
    const fetchInvoiceLastRecord = async () => {
      try {
        const params = {
          tableName: "fee_bag_invoice",
          sortColumn: "id",
          sortDirection: "DESC",
          limit: 1,
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getAllDataWithJoinMultiple`,
          { params }
        );
        invoice_last_record.value = response.data;
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const confirmDelete = async () => {
      if (!selectedDoc.value) return;
      try {
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${selectedDoc.value.id}`,
          {
            data: { tableName: "fee_bag_invoice" },
          }
        );
        if (
          selectedDoc.value.weighing &&
          Array.isArray(selectedDoc.value.weighing)
        ) {
          for (const item of selectedDoc.value.weighing) {
            if (item.supplier_invoice) {
              const req_update_weight = {
                tableName: "supplier_invoice",
                fields: { is_sack_slip: false },
              };
              await axios.patch(
                `${apirURL}/weighing/api/updateData/${item.supplier_invoice}`,
                req_update_weight
              );
            }
          }
          closeModal();
          console.log("Supplier invoice deleted successfully");
          toast.value.showToast("Delete Success", "error");
        }
      } catch (error) {
        showSuccessToast("delete", "error");
        console.error("Error deleting supplier invoice:", error);
      } finally {
        closeModal();
      }
    };

    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchInvoice(),
        fetchInvoiceLastRecord(),
        fetchUserByID(),
      ]);
    };
    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize();
      await Promise.allSettled([
        fetchInvoice(),
        fetchInvoiceLastRecord(),
        fetchUserByID(),
      ]);

      socket.on("database_realTime", handleDatabaseUpdate);
    });
    return {
      handleAdd,
      isOpen,
      currentComponent,
      handleClose,
      tableClasses,
      selectClasses,
      navClasses,
      invoice,
      formatDate2,
      mdiEyeOutline,
      selectDateRage,
      integerSearchValue,
      handleFilter,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      totalRecord,
      sortData,
      sortColumns,
      sortDirections,
      handlePrint,
      toast,
      isModalOpen,
      openModal,
      closeModal,
      confirmDelete,
      selectedDoc,
      invoice_last_record,
      permissions,
      authUserRole,
    };
  },
};
</script>

<style></style>
