<template>
  <Toast ref="toast" />
  <!-- {{ weighing_match }} -->
  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>

    <div class="w-full flex items-center justify-between py-3">
      <div>
        <h2>Customer Invoice</h2>
      </div>
    </div>

    <div class="flex items-start w-full gap-4">
      <div>
        <div :class="mainClassesTable" class="h-[500px]">
          <table>
            <tbody>
              <tr class="header_table">
                <td class="w-[100px] border-b text-start">Date In</td>
                <td
                  @click="sortData('name')"
                  class="border-b text-start toggle_sort w-[120px]"
                >
                  Supplier
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortColumns === 'name' && sortDirections === 'asc'
                            ? 'sort-up'
                            : sortColumns === 'name' &&
                              sortDirections === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="font-semibold">
                <td colspan="5" class="border">
                  <div class="flex items-center justify-end gap-2">
                    <IconField class="placeholder:text-body2">
                      <InputText
                        v-model="searchValue"
                        placeholder="Search | Supplier | Phone Number"
                      />
                      <InputIcon v-model="searchValue" class="pi pi-search" />
                    </IconField>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="weighing_match.length > 0">
              <tr
                @click="handleBoatSelection(data)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="data in weighing_match"
                :key="data.id"
              >
                <td class="border-b">{{ formatDate2(data.created_at) }}</td>

                <td class="border-b">
                  {{ data.name }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="2">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="weighing_match.length > 0"
          class="w-full bg-white flex items-center justify-center py-2"
        >
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            :pageSize="pageSize"
            :totalRecord="matchedDataSupplier.length"
            @page-change="handlePageChange"
            @page-size-change="handlePageSizeChange"
          />
        </div>
      </div>
      <div>
        <div :class="subClassesTable" class="h-[500px] border">
          <table>
            <tbody>
              <tr v-if="weighing_rice.length > 0" class="text-body">
                <td colspan="9" class="border-b sticky top-0">
                  <div class="text-start flex items-center justify-between">
                    <div class="text-body flex items-center gap-3">
                      <div class="flex items-center gap-3 text-primary6">
                        <h2>Status</h2>
                        <el-button
                          :type="
                            weighing_rice[0]?.status === false
                              ? 'warning'
                              : 'success'
                          "
                          round
                        >
                          {{
                            weighing_rice[0]?.status === false
                              ? "Procecssing"
                              : "Complete"
                          }}
                        </el-button>
                      </div>
                      <div class="flex items-center gap-3">
                        <h2>Total Weight</h2>
                        <span class="text-primary2 font-semibold">
                          {{ numeralFormat(totalWeightIn, "0,0[.]0") }} Kg</span
                        >
                      </div>
                    </div>
                    <div
                      v-if="weighing_rice[0]?.status === true"
                      class="flex items-center gap-3"
                    >
                      <!-- <IconField class="w-[80%] placeholder:text-body2">
                        <InputText placeholder="Search " />
                        <InputIcon class="pi pi-search" />
                      </IconField> -->
                      <button
                        v-if="weighing_rice[0]?.status === true"
                        @click="handleAdd"
                      >
                        Generate Invoice
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-if="weighing_rice.length > 0" class="sub_header">
                <td class="min-w-[100px] border-b text-start">Created At</td>
                <td
                  @click="sortHistoryData('id')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  No
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'id' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'id' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistoryData('plate_number')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  Truck
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'plate_number' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'plate_number' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td
                  @click="sortHistoryData('weight_in')"
                  class="min-w-[95px] border-b text-start toggle_sort"
                >
                  Total Weight
                  <span class="ml-2 cursor-pointer">
                    <transition name="fade" mode="out-in">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          sortHistoryColumns === 'weight_in' &&
                          sortHistoryDirection === 'asc'
                            ? 'sort-up'
                            : sortHistoryColumns === 'weight_in' &&
                              sortHistoryDirection === 'desc'
                            ? 'sort-down'
                            : 'sort',
                        ]"
                      />
                    </transition>
                  </span>
                </td>
                <td class="min-w-[100px] border-b text-start">Status</td>
              </tr>
            </tbody>
            <tbody v-if="isBoatSelected && weighing.length > 0">
              <tr
                @click="handleTruck(history)"
                class="text-body font-normal text-primary2 bg-white hover:bg-primary/10 active:bg-white/35 duration-300 cursor-pointer"
                v-for="history in weighing_rice"
                :key="history.id"
              >
                <td class="border-b">{{ formatDate2(history.created_at) }}</td>
                <td class="border-b">{{ history.id }}</td>
                <td class="border-b">
                  {{ history.plate_number.plate_number }}
                </td>
                <td class="border-b">
                  {{ numeralFormat(history.weight_in, "0,0[.]00") }}
                  Kg
                </td>
                <td class="border-b">
                  <el-button
                    :type="history.status === false ? 'warning' : 'success'"
                    round
                  >
                    {{ history.status === false ? "Processing" : "Completed" }}
                  </el-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <NoDataComponet />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed -top-[12rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                    :truckProp="truckProp"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isDetailsPopup" as="template">
      <Dialog as="div" @close="handleCloseDetails" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseDetails"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isTruck" as="template">
      <Dialog as="div" @close="handleCloseTruck" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed top-[4rem] inset-0 overflow-y-auto">
          <div class="flex min-h-full items-start justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleCloseTruck"
                    :is="currentComponent"
                    :truckData="truckData"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import Toast from "@/components/Toast.vue";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import SubPagination from "@/components/SubPagination.vue";
import { decodeJwt } from "@/composables/decodeJWT";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import BillPreviewVue from "@/form/BillPreview.vue";
import GenerateWeighingServiceIvoice from "@/form/GenerateWeighingServiceIvoice.vue";
import SupplierListPopUpVue from "@/form/SupplierListPopUp.vue";
import UpdateStatus from "@/components/UpdateStatus.vue";
import { formatDate2 } from "@/composables/formatDateTime";
import moment from "moment-timezone";
import TruckDetails from "@/form/TruckDetails.vue";
import socket from "@/services/socket";
export default {
  components: {
    GenerateWeighingServiceIvoice,
    BillPreviewVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    SupplierListPopUpVue,
    UpdateStatus,
    Toast,
    NoDataComponet,
    TruckDetails,
    Pagination,
    SubPagination,
  },
  setup() {
    const currentComponent = ref("");
    const isTruck = ref(false);
    const isOpen = ref(false);
    const isPopup = ref(false);
    const truckData = ref(null);
    const handleTruck = (doc) => {
      isTruck.value = true;
      truckData.value = doc;
      currentComponent.value = "TruckDetails";
    };
    const handleCloseTruck = () => {
      isTruck.value = false;
      currentComponent.value = "";
    };
    const datatoedit = ref(null);
    const truckProp = ref(null);
    const handleAdd = () => {
      isOpen.value = true;
      datatoedit.value = weighing_rice.value;
      truckProp.value = trucks.value;
      currentComponent.value = "GenerateWeighingServiceIvoice";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      isPopup.value = true;
      currentComponent.value = "BillPreviewVue";
    };

    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const open = () => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed ",
        message: h("p", null, [
          h(
            "span",
            null,
            "Are you sure wanting to mark the Boat [Boat Name] as Completed"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      })
        .then((action) => {
          ElMessage({
            type: "info",
            message: `action: ${action}`,
          });
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };
    const isDetailsPopup = ref(false);
    const handlePopUpDeltail = () => {
      isDetailsPopup.value = true;
      currentComponent.value = "SupplierListPopUpVue";
    };
    const handleCloseDetails = () => {
      isDetailsPopup.value = false;
      currentComponent.value = "";
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the field";
            type = "error";
            break;
          case "loanamount":
            message = "Please enter the loan amount";
            type = "error";
          case "amount":
            message = "Please enter the  amount";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);
      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);
      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);
      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);
      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("suppliers");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const filterValue = ref("");
    const searchValue = ref("");
    const weighing = ref([]);
    const fetchWeighing = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: table.value,
          columnSearch: "name",
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          dynamicConditions: JSON.stringify([]),
        };
        const response = await axios.get(apiUrl, { params });
        weighing.value = response.data.data;
        console.log("data ", weighing.value);
        totalPages.value = response.data.pagination.totalPages;
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    watch(searchValue, () => {
      fetchWeighing();
    });
    const selectedBoatId = ref(null);

    const selectedBoatStatus = ref(false);
    const IsWeighing = ref(null);
    const isBoatSelected = ref(false);

    const handleBoatSelection = async (data) => {
      selectedBoatId.value = data.id;
      selectedBoatStatus.value = data.status;
      IsWeighing.value = data.weight_in;

      weighing_rice.value = [];

      await fetchWeighingHistory(data.id);
      console.log("selectedBoatId.value", selectedBoatId.value);

      isBoatSelected.value = true;
    };

    const weighingData = ref([]);
    const fetchWeighingData = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "is_external_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighingData.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const totalWeightIn = computed(() => {
      return weighing_rice.value.reduce((total, record) => {
        return total + parseFloat(record.weight_in || 0);
      }, 0);
    });
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();

    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const weighing_match = ref([]);
    const fetchWeighingMatch = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        const params = {
          tableName: "weighing",
          sortColumn: "id",
          columnAliases: JSON.stringify({
            created_at: {
              table: "weighing",
              column: "created_at",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                    typeTable: "table",
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                    typeTable: "table",
                  },
                ]
              : []),
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },
            {
              field: "is_external_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(
          `${apirURL}/weighing/api/getPagination`,
          {
            params,
          }
        );

        weighing_match.value = response.data.data;
        console.log("weighing_match", weighing_match.value);
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };
    const matchedDataSupplier = computed(() => {
      const supplierIdsInWeighing = new Set(
        (Array.isArray(weighing_match.value) ? weighing_match.value : []).map(
          (item) => item.supplier_id
        )
      );
      return weighing.value.filter((supplier) =>
        supplierIdsInWeighing.has(supplier.id)
      );
    });

    console.log("matchedDataSupplier", matchedDataSupplier.value);
    const SubCurrentPage = ref(1);
    const SubpageSize = ref(50);
    const SubtotalPages = ref(0);
    const SubsortColumn = ref("id");
    const SubsortDirection = ref("ASC");
    const SubfilterColumn = ref("role");
    const Subtable = ref("weighing");

    const weighing_rice = ref([]);
    const fetchWeighingHistory = async (id) => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: Subtable.value,
          page: SubCurrentPage.value,
          pageSize: SubpageSize.value,
          sortColumn: SubsortColumn.value,
          sortDirection: SubsortDirection.value,
          filterColumn: SubfilterColumn.value,
          filterValue: filterValue.value,
          columnAliases: JSON.stringify({
            id: { table: Subtable.value, column: "id" },
            supplier_id: { table: "suppliers", column: "id" },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            {
              field: "supplier_id",
              operator: "=",
              value: id,
            },
            // {
            //   field: "created_at",
            //   operator: ">=",
            //   value: fromDateFormatted.value,
            //   typeTable: "table",
            // },
            // {
            //   field: "created_at",
            //   operator: "<=",
            //   value: toDateFormatted.value,
            //   typeTable: "table",
            // },
            {
              field: "service_type",
              operator: "=",
              value: "external",
              typeTable: "table",
            },

            {
              field: "service_external_type",
              operator: "=",
              value: "weighing_rental",
              typeTable: "table",
            },
            {
              field: "is_external_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(apiUrl, { params });

        weighing_rice.value = response.data.data;
        SubtotalPages.value = response.data.pagination.totalPages;
        console.log("weighing_rice", weighing_rice.value);
      } catch (error) {
        console.error("Error fetching rice history:", error);
      }
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        fetchWeighing(),
        fetchTruck(),
        fetchWeighingData(),
        fetchWeighingMatch(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
      await Promise.allSettled([
        fetchWeighing(),
        fetchTruck(),
        fetchWeighingData(),
        fetchWeighingMatch(),
        fetchUserByID(),
      ]);
      socket.on("database_realTime", handleDatabaseUpdate);
    });
    const sortSupplier = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing.value, field);
      } else if (field === "name") {
        toggleSort(sortDirection, weighing.value, field);
      }
    };
    const sortHistory = (field, sortDirection) => {
      if (field === "id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "plate_number_id") {
        toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "weight_in") {
        toggleSort(sortDirection, weighing_rice.value, field);
      }
    };
    const handlePageChange = async (page) => {
      currentPage.value = page;
      await fetchWeighingData();
    };

    const handlePageSizeChange = async (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      await fetchWeighingData();
    };
    const handleSubPageChange = async (page) => {
      SubCurrentPage.value = page;
      await fetchWeighingHistory();
    };

    const handleSubPageSizeChange = async (size) => {
      SubpageSize.value = size;
      SubCurrentPage.value = 1;
      await fetchWeighingHistory();
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      if (field === "id") {
        weighing.value = toggleSort(direction, weighing.value, field);
      } else if (field === "name") {
        weighing.value = toggleSort(direction, weighing.value, field);
      }
    };
    const sortPlate = (direction, array, field) => {
      array.sort((a, b) => {
        const plateA = a.plate_number.plate_number.toUpperCase(); // assuming plate_number is nested
        const plateB = b.plate_number.plate_number.toUpperCase();
        if (direction === "asc") {
          return plateA.localeCompare(plateB);
        } else {
          return plateB.localeCompare(plateA);
        }
      });
    };
    const sortHistoryDirection = ref("asc");
    const sortHistoryColumns = ref(null);
    const sortHistoryData = (field) => {
      const direction =
        sortHistoryColumns.value === field &&
        sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";
      sortHistoryDirection.value = direction;
      sortHistoryColumns.value = field;
      if (field === "id") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "weight_in") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "plate_number") {
        sortPlate(direction, weighing_rice.value, field);
      }
    };
    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      handlePopUpDeltail,
      isDetailsPopup,
      handleCloseDetails,
      windowWidth,
      tableClasses,
      navClasses,
      selectClasses,
      mainClassesTable,
      subClassesTable,
      weighing,
      getPlatesName,
      formatDate2,
      handleBoatSelection,
      weighing_rice,
      isBoatSelected,
      datatoedit,
      truckProp,
      toast,
      showSuccessToast,
      totalWeightIn,
      handleTruck,
      handleCloseTruck,
      truckData,
      isTruck,
      handleTruck,
      // matchingWeighings,
      sortSupplier,
      sortHistory,
      // handleUpdateStatus,
      // handleCloseStatus,

      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      isLastRecordOnPage,
      // subpagination

      Subtable,
      SubCurrentPage,
      SubpageSize,
      SubtotalPages,
      handleSubPageSizeChange,
      handleSubPageChange,
      searchValue,
      matchedDataSupplier,
      sortData,
      sortDirections,
      sortColumns,
      sortHistoryDirection,
      sortHistoryColumns,
      sortHistoryData,
      selectDateRage,
      authUserRole,
      weighing_match,
    };
  },
};
</script>

<style></style>
