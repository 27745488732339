<template>
  <div class="w-[792px]">
    <div class="details_title">
      <h2 class="ml-4">Truck: {{ datatoedit.plate_number.plate_number }}</h2>

      <div class="flex items-center gap-2">
        <div>
          <h2 class="mr-4">Truck Preview</h2>
        </div>
        <div @click="handleClose" class="closePopUp">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full flex">
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3 border-b-primary3 border-b-4': activeTab === 'details',
        }"
        @click="setActiveTab('details')"
      >
        Details
      </div>
      <div
        :class="{
          'tab2 cursor-pointer': true,
          'text-primary3 border-b-primary3 border-b-4': activeTab === 'Summary',
        }"
        @click="setActiveTab('Summary')"
      >
        Summary
      </div>
    </div>
    <div v-if="activeTab === 'details'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>{{ datatoedit.supplier_name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck</h2>
          <h2>{{ datatoedit.plate_number.plate_number }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck In</h2>
          <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck Out</h2>
          <h2>{{ formatDate2(datatoedit.updated_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit.is_hold ? "Completed" : "Uncompleted" }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction</h2>
        <div class="w-full border h-[240px] overflow-auto">
          <table>
            <tbody>
              <tr class="text-body header_table">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>
                <td class="border-b text-start text-nowrap">Weight</td>
                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in weighing"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">
                  {{ data.id }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ data.rice[0].rice_name }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">total</td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_amount_cut_off, "0,0[.]00") }}
                  Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handlePrint(datatoedit.weight_id)">Print</button>

          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
    <div v-else-if="activeTab === 'Summary'" class="p-5">
      <div class="border-b-2 grid grid-cols-2 border-primary2 gap-3 py-1">
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Supplier Name</h2>
          <h2>{{ datatoedit.supplier_name }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Created At</h2>
          <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck</h2>
          <h2>{{ datatoedit.plate_number.plate_number }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck In</h2>
          <h2>{{ formatDate2(datatoedit.created_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Truck Out</h2>
          <h2>{{ formatDate2(datatoedit.updated_at) }}</h2>
        </div>
        <div
          class="w-[80%] flex items-center justify-between text-body text-primary6"
        >
          <h2>Status</h2>
          <h2>{{ datatoedit.is_hold ? "Completed" : "Uncompleted" }}</h2>
        </div>
      </div>
      <div class="mt-4">
        <h2 class="text-start text-primary5 py-2">Transaction</h2>
        <div class="w-full h-[240px] overflow-auto border">
          <table>
            <tbody>
              <tr class="text-body header_table">
                <td class="border-b text-start text-nowrap">No .</td>
                <td class="border-b text-start text-nowrap">Created At</td>
                <td class="border-b text-start text-nowrap">Rice Type</td>
                <td class="border-b text-start text-nowrap">Weigh In</td>
                <td class="border-b text-start text-nowrap">Weigh out</td>
                <td class="border-b text-start text-nowrap">Cut out</td>
                <td class="border-b text-start text-nowrap">Remain</td>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="text-body font-normal text-primary2"
                v-for="data in weighing"
                :key="data"
              >
                <td class="border-b text-start text-nowrap">
                  {{ data.id }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ formatDate2(data.created_at) }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ data.rice[0].rice_name }}
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight_out, "0,0[.]00") }} Kg
                </td>

                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.amount_cut_off, "0,0[.]00") }} Kg
                </td>
                <td class="border-b text-start text-nowrap">
                  {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="text-body text-primary2 font-bold">
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">Total</td>
                <td class="text-start text-nowrap"></td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_in, "0,0[.]00") }} Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_out, "0,0[.]00") }} Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_amount_cut_off, "0,0[.]00") }}
                  Kg
                </td>
                <td class="text-start text-nowrap">
                  {{ numeralFormat(total_weight_drop, "0,0[.]00") }} Kg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-start mt-4 gap-3">
          <button @click="handlePrint(datatoedit.weight_id)">Print</button>

          <button @click="handleClose" class="btncancel">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { formatDate2 } from "@/composables/formatDateTime";
import { useRouter } from "vue-router";

export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const activeTab = ref("details");

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(25);
    const totalPages = ref(0);
    const table = ref("weighing_rice_history");
    const sortColumn = ref("id"); // default sort column
    const sortDirection = ref("ASC"); // Default sort direction
    const filterColumn = ref("role");
    const filterValue = ref("");
    const searchValue = ref("");
    const weighing = ref([]);
    const total_weight_drop = ref(0);
    const total_weight_amount_cut_off = ref(0);
    const total_weight_out = ref(0);
    const fetchWeighing = async () => {
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        columnSearch: "",
        search: searchValue.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          {
            field: "weighing_id",
            operator: "=",
            value: props.datatoedit.weight_id,
          },
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing.value = response.data.data;
      total_weight_drop.value = weighing.value.reduce(
        (acc, curr) => acc + curr.weight_drop,
        0
      );
      total_weight_amount_cut_off.value = weighing.value.reduce(
        (acc, curr) => acc + curr.amount_cut_off,
        0
      );
      total_weight_out.value = weighing.value.reduce(
        (acc, curr) => acc + curr.weight_out,
        0
      );
      console.log("date ", weighing.value);
      totalPages.value = response.data.pagination.totalPages;
    };
    onMounted(() => {
      if (props.datatoedit) {
        console.log("datatoedit ", props.datatoedit);
      }
      fetchWeighing();
    });
    const router = useRouter();
    const handlePrint = (id) => {
      const params = {
        id: id,
      };
      router.push({
        name: "printtrucktransaction",
        params: params,
      });
    };
    return {
      handleClose,
      setActiveTab,
      activeTab,
      weighing,
      formatDate2,
      total_weight_drop,
      total_weight_amount_cut_off,
      total_weight_out,
      handlePrint,
    };
  },
};
</script>

<style></style>
