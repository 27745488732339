<template>
  <Toast ref="toast" />
  <div class="w-full relative text-primary6">
    <div v-if="authUserRole === 'Super Admin'" :class="selectClasses">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2">*</span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
          show-button-bar
          :manualInput="false"
        />
      </div>

      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0">*</span>Supplier</label
        >
        <el-button @click="handleFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div class="w-full flex items-center justify-between mt-3">
      <div>
        <h2>Truck Weighing Transaction</h2>
      </div>
    </div>
    <div class="flex items-center w-full gap-4">
      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody>
            <tr v-if="weighing_rice.length > 0" class="text-body text-primary5">
              <td colspan="9" class="border-b sticky top-0">
                <div class="text-start flex items-center justify-between">
                  <div class="text-body flex items-center gap-3">
                    <!-- <div class="flex items-center gap-3">
                      <h2>Status</h2>
                      <el-button type="warning" round>Processing</el-button>
                    </div> -->
                    <div class="flex items-center gap-3">
                      <h2>Total Weight</h2>
                      <span class="text-primary2 font-semibold"
                        >{{
                          grandTotal
                            ? numeralFormat(grandTotal, "0,0[.]00")
                            : "---"
                        }}
                        Kg</span
                      >
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <button class="btncancel border" @click="handleAdd">
                      Preview
                    </button>
                    <button @click="handlePopUp">Generate Bill</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[70px] border-b text-start">Created At</td>

              <td
                @click="sortHistoryData('id')"
                class="border-b text-start toggle_sort min-w-[100px]"
              >
                No
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortHistoryColumns === 'id' &&
                        sortHistoryDirection === 'asc'
                          ? 'sort-up'
                          : sortHistoryColumns === 'id' &&
                            sortHistoryDirection === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortHistoryData('plate_number')"
                class="border-b text-start toggle_sort min-w-[100px]"
              >
                Truck
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortHistoryColumns === 'plate_number' &&
                        sortHistoryDirection === 'asc'
                          ? 'sort-up'
                          : sortHistoryColumns === 'plate_number' &&
                            sortHistoryDirection === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortHistoryData('totalWeightDrop')"
                class="border-b text-start toggle_sort min-w-[100px]"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="[
                        'fas',
                        sortHistoryColumns === 'totalWeightDrop' &&
                        sortHistoryDirection === 'asc'
                          ? 'sort-up'
                          : sortHistoryColumns === 'totalWeightDrop' &&
                            sortHistoryDirection === 'desc'
                          ? 'sort-down'
                          : 'sort',
                      ]"
                    />
                  </transition>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="weighing_rice.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing_rice"
              :key="data.id"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">{{ data.id }}</td>
              <td class="border-b">
                {{ data.weighing[0].plate_number.plate_number || "N/A" }}
              </td>
              <td class="border-b">
                {{ numeralFormat(getTotalWeightDrop(data.rices), "0,0[.]00") }}
                Kg
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <td colspan="4">
              <NoDataComponet />
            </td>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="weighing_rice.length > 0"
      class="w-full flex items-center justify-between mt-8"
    >
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :pageSize="pageSize"
        :totalRecord="weighing_rice.length"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :dataPrev="dataPrev"
                    :is="currentComponent"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot appear :show="isPopup" as="template">
      <Dialog as="div" @close="ClosePopup" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="ClosePopup"
                    :is="currentComponent"
                    :dataPrev="dataPrev"
                    @toast="showSuccessToast"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import {
  getResponsiveFilter,
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import { h } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import SlipLaborPreviewVue from "@/form/SlipLaborPreview.vue";
import GenerateLabelSlipVue from "@/form/GenerateLabelSlip.vue";
import axios from "axios";
import apirURL from "@/services/apiURL";
import moment from "moment-timezone";
import { formatDate2 } from "@/composables/formatDateTime";
import toggleSort from "@/composables/sortData";
import Pagination from "@/components/Pagination.vue";
import socket from "@/services/socket";
import NoDataComponet from "@/components/NoDataComponet.vue";
import { decodeJwt } from "@/composables/decodeJWT";
import Toast from "@/components/Toast.vue";
export default {
  components: {
    SlipLaborPreviewVue,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    GenerateLabelSlipVue,
    Pagination,
    NoDataComponet,
    Toast,
  },
  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const isPopup = ref(false);
    const dataPrev = ref(null);
    const handleAdd = () => {
      isOpen.value = true;
      dataPrev.value = weighing_rice.value;
      currentComponent.value = "SlipLaborPreviewVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };
    const handlePopUp = () => {
      currentComponent.value = "GenerateLabelSlipVue";
      isPopup.value = true;
      dataPrev.value = weighing_rice.value;
    };

    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";
        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const ClosePopup = () => {
      isPopup.value = false;
      currentComponent.value = "";
    };
    const open = () => {
      ElMessageBox({
        title: "Confirming Mark Boat as Completed ",
        message: h("p", null, [
          h(
            "span",
            null,
            "Are you sure wanting to mark the Boat [Boat Name] as Completed"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      })
        .then((action) => {
          ElMessage({
            type: "info",
            message: `action: ${action}`,
          });
        })
        .catch((err) => {
          if (err === "cancel") {
            ElMessage({
              type: "info",
              message: "Action canceled",
            });
          } else {
            console.error(err);
            ElMessage({
              type: "error",
              message: `Unexpected error: ${err}`,
            });
          }
        });
    };
    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };
    const authUserRole = ref("");
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);

        let table = "users";
        const response = await axios.get(
          `${apirURL}/weighing/api/getDataByID/${table}/${
            decodeJWTInfo ? decodeJWTInfo.userId : null
          }`
        );

        authUserRole.value = response.data[0]?.role;

        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };
    const handleDatabaseUpdate = async () => {
      await Promise.allSettled([
        handleResize(),
        fetchWeighingHistory(),
        fetchWeighing(),
        fetchTruck(),
        fetchUserByID(),
      ]);
    };

    onMounted(async () => {
      window.addEventListener("resize", handleResize);
      await Promise.allSettled([
        handleResize(),
        fetchWeighingHistory(),
        fetchWeighing(),
        fetchTruck(),
        fetchUserByID(),
      ]);
      mapWeighingIdToPlateNumber();
      socket.on("database_realTime", handleDatabaseUpdate);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    // Get the current date and set it to the start of the day
    const currentDate = new Date();

    // Function to format date with timezone
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighingHistory();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighingHistory();
    };
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const weighing_rice = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const table = ref("supplier_invoice");
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const fetchWeighingHistory = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      let apiUrl = `${apirURL}/weighing/api/getPagination`;
      const params = {
        tableName: table.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortColumn: sortColumn.value,
        sortDirection: sortDirection.value,
        filterColumn: filterColumn.value,
        filterValue: filterValue.value,
        dynamicConditions: JSON.stringify([
          // {
          //   field: "created_at",
          //   operator: ">=",
          //   value: fromDateFormatted.value,
          //   typeTable: "table",
          // },
          // {
          //   field: "created_at",
          //   operator: "<=",
          //   value: toDateFormatted.value,
          //   typeTable: "table",
          // },
          {
            field: "is_labor_invoice",
            operator: "=",
            value: false,
            typeTable: "table",
          },
          ...(fromDate && toDate
            ? [
                {
                  field: "created_at",
                  operator: ">=",
                  value: fromDate,
                },
                {
                  field: "created_at",
                  operator: "<=",
                  value: toDate,
                },
              ]
            : []),
        ]),
      };
      const response = await axios.get(apiUrl, { params });
      weighing_rice.value = response.data.data;
      console.log("date ", weighing_rice);
      totalPages.value = response.data.pagination.totalPages;
    };
    const handleFilter = async () => {
      await fetchWeighingHistory();
    };
    const weighing = ref([]);

    const fetchWeighing = async () => {
      try {
        const req = {
          tableName: "weighing",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "created_at",
              operator: ">=",
              value: fromDateFormatted.value,
              typeTable: "table",
            },
            {
              field: "created_at",
              operator: "<=",
              value: toDateFormatted.value,
              typeTable: "table",
            },
          ]),
        };

        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        weighing.value = response.data;
        mapWeighingIdToPlateNumber();
      } catch (error) {
        console.error("Error fetching weighing data:", error);
      }
    };

    const getPlatesName = (plate_id) => {
      const truck = trucks.value.find((t) => t.id === plate_id);
      return truck ? truck.plate_numbers : "Unknown Plate";
    };
    const trucks = ref([]);
    const fetchTruck = async () => {
      try {
        const req = {
          tableName: "plates",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            {
              field: "status",
              operator: "=",
              value: true,
              typeTable: "table",
            },
          ]),
        };
        const response = await axios.get(`${apirURL}/weighing/api/getAllData`, {
          params: req,
        });

        trucks.value = response.data;
      } catch (error) {
        console.error("Error fetching rice types:", error);
      }
    };

    const mapWeighingIdToPlateNumber = () => {
      const weighingMap = {};
      weighing.value.forEach((weigh) => {
        weighingMap[weigh.id] = weigh.plate_number_id;
      });

      // Replace weighing_id with the corresponding plate_number_id
      weighing_rice.value = weighing_rice.value.map((riceHistory) => {
        return {
          ...riceHistory,
          plate_number_id: weighingMap[riceHistory.weighing_id] || null, // Replace or assign null if not found
        };
      });

      console.log("Updated weighing_rice:", weighing_rice.value);
    };

    const sortClient = (field, sortDirection) => {
      let sortedData;

      if (field === "id") {
        sortedData = toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "weight_drop") {
        sortedData = toggleSort(sortDirection, weighing_rice.value, field);
      } else if (field === "plate_number_id") {
        // Sort by plate_number_id
        sortedData = [...weighing_rice.value].sort((a, b) => {
          const plateA = String(a.plate_number_id || "");
          const plateB = String(b.plate_number_id || "");

          if (sortDirection === "asc") {
            return plateA.localeCompare(plateB); // Ascending
          } else {
            return plateB.localeCompare(plateA); // Descending
          }
        });
      }

      // Update the weighing_rice.value with sorted data
      weighing_rice.value = sortedData;
    };

    const grandTotal = computed(() => {
      return weighing_rice.value.reduce((total, item) => {
        return (
          total +
          item.rices.reduce((subTotal, rice) => subTotal + rice.weight_drop, 0)
        );
      }, 0);
    });
    const getTotalWeightDrop = (rices) => {
      return rices.reduce((sum, rice) => sum + rice.weight_drop, 0);
    };

    console.log("Total Weight Drop:", getTotalWeightDrop);

    const sortHistoryDirection = ref("asc");
    const sortHistoryColumns = ref(null);
    const sortHistoryData = (field) => {
      const direction =
        sortHistoryColumns.value === field &&
        sortHistoryDirection.value === "asc"
          ? "desc"
          : "asc";
      sortHistoryDirection.value = direction;
      sortHistoryColumns.value = field;
      if (field === "id") {
        weighing_rice.value = toggleSort(direction, weighing_rice.value, field);
      } else if (field === "plate_number") {
        weighing_rice.value.sort((a, b) => {
          const plateA = a.weighing[0]?.plate_number?.plate_number || "";
          const plateB = b.weighing[0]?.plate_number?.plate_number || "";
          if (direction === "asc") {
            return plateA.localeCompare(plateB);
          } else {
            return plateB.localeCompare(plateA);
          }
        });
      } else if (field === "totalWeightDrop") {
        weighing_rice.value.sort((a, b) => {
          const totalWeightA = getTotalWeightDrop(a.rices);
          const totalWeightB = getTotalWeightDrop(b.rices);
          return direction === "asc"
            ? totalWeightA - totalWeightB
            : totalWeightB - totalWeightA;
        });
      }
    };

    return {
      currentComponent,
      handleAdd,
      handleClose,
      isOpen,
      isPopup,
      handlePopUp,
      ClosePopup,
      open,
      tableClasses,
      weighing_rice,
      formatDate2,
      getPlatesName,
      dataPrev,
      sortClient,
      currentPage,
      totalPages,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      grandTotal,
      getTotalWeightDrop,
      sortHistoryDirection,
      sortHistoryColumns,
      sortHistoryData,
      selectDateRage,
      handleFilter,
      selectClasses,
      authUserRole,
      toast,
      showSuccessToast,
    };
  },
};
</script>

<style></style>
