<template>
  <div class="w-full flex items-start h-screen relative">
    <div class="w-full bg-white flex">
      <!-- Sidebar -->
      <div :class="isWrapSidebar === false
          ? 'sidebar w-[350px] duration-300'
          : 'sidebar w-[100px] duration-300'
        " class="hidden-print">
        <div class="sidebar_title bg-main hidden-print">
          <div class="mx-auto flex items-center justify-between space-x-4">
            <div class="size-10 rounded-full overflow-hidden">
              <img src="../assets/images/default_profile.png" alt="" />
            </div>
            <div :class="isWrapSidebar === false ? 'block' : 'hidden'">
              <h1 class="text-heading1 font-bold text-default">Weighing</h1>
              <p class="text-[10px] text-default leading-none">
                System Management
              </p>
            </div>
          </div>
        </div>
        <div class="h-[90vh] bg-white overflow-auto w-auto hidden-print">
          <router-link to="/">
            <div class="sidebar_content" :class="{
              'text-primary3': isActiveRoute('/dashboard'),
            }">
              <div class="w-full flex flex-col items-center group justify-between">
                <div class="w-[90%] flex items-center justify-between group-hover:bg-main/30 duration-300 px-3 py-4">
                  <div :class="isWrapSidebar === false
                      ? 'flex w-[80%] items-center justify-start space-x-4 py-1'
                      : 'flex w-[150px] items-center justify-center'
                    ">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                        <path
                          d="M6 3a3 3 0 0 0-3 3v2.25a3 3 0 0 0 3 3h2.25a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6ZM15.75 3a3 3 0 0 0-3 3v2.25a3 3 0 0 0 3 3H18a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3h-2.25ZM6 12.75a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h2.25a3 3 0 0 0 3-3v-2.25a3 3 0 0 0-3-3H6ZM17.625 13.5a.75.75 0 0 0-1.5 0v2.625H13.5a.75.75 0 0 0 0 1.5h2.625v2.625a.75.75 0 0 0 1.5 0v-2.625h2.625a.75.75 0 0 0 0-1.5h-2.625V13.5Z" />
                      </svg>
                    </span>
                    <h2 :class="isWrapSidebar === false ? 'block' : 'hidden'">
                      Dashboard
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          <div v-for="(item, index) in filteredMenuItems" :key="index" class="h-fit">
            <div @click="
              item.name === 'Pre-Loan'
                ? $router.push(item.path)
                : item.is_dropdown
                  ? toggleDropdown(item)
                  : addTab(item.name, item.path)
              " class="sidebar_content" :class="{ 'text-primary3': isActiveRoute(item.path) }">
              <div class="w-full flex flex-col items-center group justify-between">
                <div class="w-[90%] flex items-center justify-between group-hover:bg-main/30 duration-300 px-3 py-4">
                  <div :class="isWrapSidebar === false
                      ? 'flex w-[80%] items-center justify-start space-x-4 py-1'
                      : 'flex items-center justify-center w-[150px] '
                    ">
                    <font-awesome-icon :class="isWrapSidebar === false ? 'block ' : 'flex mx-auto'
                      " :icon="['fas', item.icon]" class="size-6 text-primary6 group-hover:text-main duration-300" />
                    <div class="text-nowrap group-hover:text-main duration-300"
                      :class="isWrapSidebar === false ? 'block' : 'hidden'">
                      {{ item.name }}
                    </div>
                  </div>
                  <div v-if="item.name !== 'Pre-Loan'" :class="isWrapSidebar === false
                      ? 'opacity-100 '
                      : 'opacity-0 hidden'
                    ">
                    <svg v-if="!dropdownOpen.includes(item.id)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="currentColor" class="size-[20px] text-primary5 active:text-primary3">
                      <path fill-rule="evenodd"
                        d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                        clip-rule="evenodd" />
                    </svg>
                    <svg v-else @click.stop="closeDropdown(item)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                      fill="currentColor" class="size-[20px] text-primary5 active:text-primary3">
                      <path fill-rule="evenodd"
                        d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="animate-fade-down animate-duration-100 animate-delay-100" v-if="dropdownOpen.includes(item.id)">
              <div class="w-[90%] overflow-hidden">
                <ul class="mt-2 space-y-2 ml-6">
                  <!-- Render Group Name and Children if groups exist -->
                  <li v-for="(group, groupIndex) in item.groups" :key="groupIndex"
                    class="font-semibold text-sm px-4 py-2 text-gray-600">
                    <h3 class="text-slate-400">{{ group.groupName }}</h3>
                    <ul>
                      <li v-for="(child, childIndex) in group.children" :key="childIndex"
                        @click.stop="addTab(child.name, child.path)" class="cursor-pointer py-2">
                        <div class="flex items-center gap-2 px-4 py-1 cursor-pointer">
                          <span class="text-primary5 flex items-center">
                            <svg-icon type="mdi" :path="mdiLabel" v-if="isActiveRoute(child.path)"
                              class="text-primary3"></svg-icon>
                            <div v-else class="size-2 rounded-full overflow-hidden bg-primary5/50"></div>
                          </span>
                          <router-link :to="child.path" class="text-sm text-start text-nowrap hover:text-primary3"
                            :class="{
                              'text-primary3 font-bold': isActiveRoute(
                                child.path
                              ),
                            }">
                            {{ child.name }}
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <!-- Handle direct children without groups -->
                  <template v-if="!item.groups">
                    <li v-for="(child, childIndex) in item.children" :key="childIndex"
                      @click.stop="addTab(child.name, child.path)" class="cursor-pointer py-2">
                      <div class="flex items-center gap-2 px-4 py-1 cursor-pointer">
                        <span class="text-primary5 flex items-center">
                          <svg-icon type="mdi" :path="mdiLabel" v-if="isActiveRoute(child.path)" :icon="['fas', 'play']"
                            class="text-primary3"></svg-icon>
                          <div v-else class="size-2 rounded-full overflow-hidden bg-primary5/50"></div>
                        </span>
                        <router-link @click.stop="addTab(child.name, child.path)" :to="child.path"
                          class="text-sm text-start text-nowrap hover:text-primary3" :class="{
                            'text-primary3 font-bold': isActiveRoute(
                              child.path
                            ),
                          }">
                          {{ child.name }}
                        </router-link>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="navbar hidden-print">
          <div class="navbar_children">
            <div class="main_navbar">
              <div class="flex items-center space-x-2 cursor-pointer" @click="toggleWrapSidebar">
                <font-awesome-icon :icon="['fas', 'bars']" class="size-6 text-primary3" />
                <h2 class="text-heading1 font-bold text-primary3">
                  Weighing /
                  <span class="text-primary3 text-sm"> Weighing </span>
                </h2>
              </div>
              <div class="flex items-center gap-3">
                <div class="text-primary2">
                  <h2>{{ formatDate(currentDate) }}</h2>
                </div>
                <div class="relative">
                  <div class="absolute size-2 right-0 bottom-0 bg-primary4 rounded-full"></div>
                  <div class="size-[30px] rounded-full border border-primary3 overflow-hidden">
                    <img v-if="userData[0]?.image_url" :src="getImageUrl('users', userData[0]?.image_url)" alt="" />

                    <img v-else src="@/assets/images/default_profile.png" class="object-cover" alt="" />
                  </div>
                </div>
                <div class="relative" ref="dropdownRef">
                  <button @click="isLanguageOpen = !isLanguageOpen"
                    class="flex items-center space-x-2 px-4 py-1.5 bg-white border border-primary3 rounded-md hover:bg-gray-50 transition-colors duration-200">
                    <img :src="currentLanguage.flagUrl" :alt="currentLanguage.name"
                      class="w-6 h-4 object-cover shadow-sm" />
                    <span class="text-sm font-medium text-gray-700">{{
                      currentLanguage.name
                    }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-gray-500 transition-transform duration-200"
                      :class="{ 'rotate-180': isLanguageOpen }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>

                  <!-- Dropdown menu -->
                  <transition enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <div v-if="isLanguageOpen"
                      class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <div class="py-1">
                        <button v-for="lang in languages" :key="lang.code" @click="selectLanguage(lang)"
                          class="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-50 transition-colors duration-200 relative group"
                          :class="{
                            'bg-white text-gray-700':
                              currentLanguage.code !== lang.code,
                            'bg-white text-primary3 font-medium':
                              currentLanguage.code === lang.code,
                          }">
                          <img :src="lang.flagUrl" :alt="lang.name" class="w-6 h-4 object-cover shadow-sm" />
                          <span class="ml-3 relative">
                            {{ lang.name }}
                            <span
                              class="absolute bottom-0 left-0 w-0 h-[1px] bg-primary3 transition-all duration-300 group-hover:w-full"></span>
                          </span>
                          <svg v-if="currentLanguage.code === lang.code" class="ml-auto h-4 w-4 text-primary3"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
                <div>
                  <button @click="handleLogout">Logout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div :class="elementClasses" class="ml-2 rounded-md">
            <div :class="{
              'bg-main text-white': $route.path === '/dashboard',
              'text-primary2': $route.path !== '/dashboard',
            }" class="tab flex items-center p-2">
              <router-link to="/" class="rounded-none">Dashboard</router-link>
            </div>
            <div v-for="(tab, index) in openTabs.slice(1)" :key="tab.path" class="tab flex items-center p-2" :class="{
              'bg-main text-white': $route.path === tab.path,
              'text-primary2': $route.path !== tab.path,
            }">
              <router-link :to="tab.path" :class="{
                'text-white': $route.path === tab.path,
                'text-primary2': $route.path !== tab.path,
              }">
                <i :class="tab.icon"></i>
                {{ tab.name }}
              </router-link>
              <span @click="removeTab(index + 1)" :class="{
                'text-white cursor-pointer': $route.path === tab.path,
                'text-primary2 cursor-pointer': $route.path !== tab.path,
              }">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-4 hover:bg-primary4 duration-300 rounded hover:text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </span>
            </div>
          </div>
          <div class="p-4 bg-white w-full">
            <router-view class="animate-fade-up animate-duration-300 animate-delay-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import NavbarVue from "../components/Navbar.vue";
import RadioButton from "primevue/radiobutton";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { fetchTimestamp } from "@/services/timestamp";
import { formatDate } from "@/composables/formatDateTime";
import socket from "@/services/socket";
import { decodeJwt } from "@/composables/decodeJWT";
import axios from "axios";
import { mdiLabel } from "@mdi/js";
import apirURL from "@/services/apiURL";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { getImageUrl } from "@/composables/getImage";
import enFlag from "@/assets/images/flags/en.png";
import khFlag from "@/assets/images/flags/kh.png";
import vnFlag from "@/assets/images/flags/vn.jpg";
import { getResponsiveElementClass } from "@/composables/useResponsive";
export default {
  components: {
    NavbarVue,
    RadioButton,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  setup() {
    const dialogVisible = ref(false);
    const dropdownOpen = ref([]);
    const isPending = ref(false);
    const route = useRoute();
    const router = useRouter();
    const windowWidth = ref(window.innerWidth);
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };
    const currentDate = ref(null);

    // Update the time every second
    let intervalId;
    const updateTime = async () => {
      currentDate.value = await fetchTimestamp();
    };
    const elementClasses = computed(() => {
      const classes = getResponsiveElementClass(windowWidth.value);
      return classes;
    });
    const state = reactive({
      openTabs: [],
      menuItems: [
        {
          id: 1,
          name: "Weighing",
          path: "weighing",
          icon: "weight-scale",
          permissions: ["module_weighing"],
          children: [
            {
              name: "Weighing",
              path: "/weighinglist",
              permissionKey: "weight",
            },
            {
              name: "Truck Storage",
              path: "/formtruck",
              permissionKey: "truck_storage",
            },
            {
              name: "Boat Storage",
              path: "/toparking",
              permissionKey: "boat_storage",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 5,
          name: "Parking",
          path: "/parking",
          icon: "road",
          permissions: ["module_parking"],
          children: [
            {
              name: "Boat Display",
              path: "/viewparking",
              permissionKey: "parking_display",
            },
            {
              name: "Parking Register",
              path: "/parking",
              permissionKey: "parking_list",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 2,
          name: "Ordering Book",
          path: "/ordering-book",
          icon: "box",
          permissions: ["module_ordering_book"],
          children: [
            {
              name: "Customer Order",
              path: "/sale-order",
              permissionKey: "customer_order",
            },
            {
              name: "Supplier Order",
              path: "/purchase-order",
              permissionKey: "purchase_order",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 4,
          name: "Finance",
          path: "/finance",
          icon: "money-bill",
          permissions: ["module_finance"],
          children: [
            {
              name: "Supplier Invoice",
              path: "supplierlist",
              permissionKey: "supplier_invoice",
            },
            {
              name: "Customer Invoice",
              path: "clientinvoicelist",
              permissionKey: "customer_invoice",
            },
            {
              name: "Customer Payment",
              path: "/clientreypayment",
              permissionKey: "customer_payment",
            },
            {
              name: "Payment Record",
              path: "/paymentrecord",
              permissionKey: "customer_repayment_record",
            },
            {
              name: "Labor Invoice",
              path: "laborslip",
              permissionKey: "labor_invoice",
            },
            {
              name: "Sack Invoice",
              path: "sackslip",
              permissionKey: "sack_invoice",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 3,
          name: "Wallet",
          path: "/wallet",
          icon: "wallet",
          permissions: ["module_wallet"],
          children: [
            { name: "Wallet", path: "wallet", permissionKey: "partner" },
            {
              name: "All Transaction",
              path: "walletTransaction",
              permissionKey: "transaction_list",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 6,
          name: "Warehouse",
          path: "/warehouse",
          icon: "warehouse",
          permissions: ["module_warehouse"],
          children: [
            { name: "Stock", path: "/ricestock", permissionKey: "rice_stock" },
            {
              name: "History",
              path: "/history",
              permissionKey: "stock_history",
            },
            {
              name: "Warehouse Registration",
              path: "/warehouse",
              permissionKey: "warehouse_list",
            },
          ],
          is_dropdown: true,
        },
        {
          id: 10,
          name: "Pre-Loan",
          path: "preLoan",
          icon: "money-bill",
          permissions: ["module_pre_loan"],
          permissionKey: "pre_loan",
          is_dropdown: false,
        },
        {
          id: 13,
          name: "Contact",
          path: "/contact",
          icon: "address-book",
          permissions: ["module_contact"],
          children: [
            { name: "Customers", path: "/client", permissionKey: "customer" },
            { name: "Suppliers", path: "/supplier", permissionKey: "supplier" },
          ],
          is_dropdown: true,
        },
        {
          id: 7,
          name: "Transactions",
          path: "/transactions",
          permissions: ["module_transaction"],
          icon: "file-invoice",
          is_dropdown: true,
          groups: [
            {
              groupName: "Weight",
              children: [
                {
                  name: "Truck Go",
                  path: "/formtrucktransction",
                  permissionKey: "truck_go",
                },
                {
                  name: "On Boat",
                  path: "/toparkingtransaction",
                  permissionKey: "on_boat",
                },
              ],
            },
            {
              groupName: "Finance",
              children: [
                {
                  name: "Supplier Invoice",
                  path: "/supplilerbilltransaction",
                  permissionKey: "supplier_invoice",
                },
                {
                  name: "Customer Invoice",
                  path: "/invoicetransaction",
                  permissionKey: "customer_invoice",
                },
                {
                  name: "Labor Invoice",
                  path: "/laborsliptransaction",
                  permissionKey: "labor_invoice",
                },
                {
                  name: "Sack Invoice",
                  path: "/sacksliptransaction",
                  permissionKey: "sack_invoice",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          name: "External Service",
          path: "/externalservice",
          icon: "chart-gantt",
          is_dropdown: true,
          permissions: ["module_external_weighing", "module_parking_rental"],
          groups: [
            {
              groupName: "Weighing",
              children: [
                {
                  name: "Weight",
                  path: "/weighingservice",
                  permissionKey: "weighing_external",
                },
                {
                  name: "Truck Go External",
                  path: "/truckgo",
                  permissionKey: "truck_go_external",
                },
                {
                  name: "Invoice",
                  path: "/serviceexternalinvoice",
                  permissionKey: "weighing_invoice_external",
                },
                {
                  name: "Transaction Truck",
                  path: "/transactiontruck",
                  permissionKey: "transaction_truck_external",
                },
                {
                  name: "Transaction Invoice",
                  path: "/transactioninvoiceparkingrental",
                  permissionKey: "weighing_invoice_transaction_external",
                },
              ],
            },
            {
              groupName: "Rental",
              children: [
                {
                  name: "Parking Rental",
                  path: "/rentalparking",
                  permissionKey: "weighing_external_rental_parking",
                },
              ],
            },
          ],
        },
        {
          id: 11,
          name: "Report",
          path: "/report",
          icon: "file-excel",
          permissions: ["module_report"],
          is_dropdown: true,
          groups: [
            {
              groupName: "Supplier",
              children: [
                {
                  name: "Purchase Order Report",
                  path: "/reportpurchaseorder",
                  permissionKey: "purchase_order_report",
                },
                {
                  name: "Supplier Invoice Report",
                  path: "/supplierinvoice",
                  permissionKey: "supplier_invoice",
                },
                // {
                //   name: "Supplier Payment Report",
                //   path: "/supplierrepaymentreport",
                //   permissionKey: "supplier_payment",
                // },
                {
                  name: "Pre-Loan Report",
                  path: "/preloanreport",
                  permissionKey: "preloan_repayment",
                },

                //{ name: "Loan Repayment", path: "/loanrepaymentreport", permissionKey: "loan_repayment" },
              ],
            },
            {
              groupName: "Customer",
              children: [
                {
                  name: "Customer Order Report",
                  path: "/saleorderreport",
                  permissionKey: "customer_order_report",
                },
                {
                  name: "Customer Invoice Report",
                  path: "/customerinvoice",
                  permissionKey: "customer_invoice",
                },
                {
                  name: "Customer Repayment Report",
                  path: "/invoicepaymentreport",
                  permissionKey: "customer_payment",
                },
              ],
            },
            {
              groupName: "Out",
              children: [
                {
                  name: "Labor Invoice Report",
                  path: "/laborslipreport",
                  permissionKey: "labor_invoice",
                },
                //{ name: "Labor Customer Payment", path: "/laborcustomerrepaymentreport", permissionKey: "labor_customer_payment" },
                {
                  name: "Sack Invoice Report",
                  path: "/sackllipreport",
                  permissionKey: "sack_invoice",
                },
                {
                  name: "Weighing Expense Report",
                  path: "/weighingexspencereport",
                  permissionKey: "weighing_expense",
                },

                //{ name: "Sack Customer Payment", path: "/sackcustomerrepaymentreport", permissionKey: "sack_customer_payment" },
              ],
            },
            {
              groupName: "Wallet",
              children: [
                {
                  name: "Wallet Transaction Report",
                  path: "/wallettransactionreport",
                  permissionKey: "wallet_transaction",
                },
                // {
                //   name: "Wallet History Report",
                //   path: "/wallethistoryreport",
                //   permissionKey: "wallet_history",
                // },
              ],
            },
            {
              groupName: "External",
              children: [
                {
                  name: "Ex Weighing Expense Report",
                  path: "/externalweighingslipreport",
                  permissionKey: "weighing_external_expense",
                },
                {
                  name: "Ex Weighing Invoice Report",
                  path: "/externalweighingsinvoicereport",
                  permissionKey: "external_weighing_invoice",
                },
                //{ name: "Ex Weighting Customer Payment", path: "/externalweighinginvoicereport", permissionKey: "external_weighing_customer_payment" },
                {
                  name: "Parking Rental Report",
                  path: "/parkingrantal",
                  permissionKey: "parking_rental",
                },
                {
                  name: "Parking Rental Payment Report",
                  path: "/parkingrentalpayment",
                  permissionKey: "parking_rental_payment",
                },
              ],
            },
          ],
        },
        {
          id: 12,
          name: "Setting",
          path: "/setting",
          icon: "gear",
          permissions: ["module_setting"],
          children: [
            { name: "User", path: "/users", permissionKey: "user" },
            {
              name: "Rice Registration",
              path: "/ricetyesetting",
              permissionKey: "rice",
            },
            {
              name: "Plate Registration",
              path: "/plates",
              permissionKey: "plate",
            },
            {
              name: "Set Up Telegram Token",
              path: "/setup-telegram-token",
              permissionKey: "telegram_token",
            },
          ],
          is_dropdown: true,
        },
      ],
    });

    const getPermissionKey = (name) => {
      // Define a mapping of names to permission keys
      const permissionMap = {
        "Set Up Telegram Token": "telegram_token",
        Weight: "weight",
        "Truck Storage": "truck_storage",
        "Ex Weighing Expense Report": "external_weighing_expense",
        Wallet: "partner",
        "Weighing Expense Report": "weighing_expense",
        "Labor Invoice": "labor_invoice",
        "Sack Invoice": "sack_invoice",
        "Truck Go External": "truck_go_external",
        "Transaction Invoice": "weighing_invoice_transaction_external",
        "Boat Storage": "boat_storage",
        "Boat Display": "parking_display",
        "Parking Register": "parking_list",
        "Customer Order": "customer_order",
        "Supplier Order": "purchase_order",
        "Supplier Invoice": "supplier_invoice",
        "Customer Invoice": "customer_invoice",
        "Customer Payment": "customer_payment",
        "Payment Record": "payment_record",
        "Purchase Order Report": "purchase_order_report",
        "Supplier Payment Report": "supplier_payment",
        "Pre-Loan Report": "preloan_repayment",
        "Supplier Invoice Report": "supplier_invoice",
        "Labor Invoice Report": "labor_invoice",
        "Sack Invoice Report": "sack_invoice",
        "Customer Order Report": "customer_order_report",
        "Customer Invoice Report": "customer_invoice",
        "Customer Repayment Report": "customer_payment",
        "Weighing Expense Report": "weighing_expense",
        "Ex Weighing": "external_weighing_invoice",
        "Parking Rental Report": "parking_rental",
        "Parking Rental Payment Report": "parking_rental_payment",
        "Wallet Transaction Report": "wallet_transaction",
        "Wallet History Report": "wallet_history",
        "All Transaction": "transaction_list",
        Stock: "rice_stock",
        History: "stock_history",
        "Warehouse Registration": "warehouse_list",
        "Pre-Loan": "pre_loan",
        Customers: "customer",
        Suppliers: "supplier",
        "Truck Go": "truck_go",
        "On Boat": "on_boat",
        "Rental Parking Invoice": "rental_parking_invoice",
        Weight: "weighing_external",
        Weighing: "weight",
        Invoice: "weighing_invoice_external",
        "Transaction Truck": "transaction_truck_external",
        "Parking Rental": "weighing_external_rental_parking",
        "Weighting Expense": "weighing_expense",
        "Ex Weighting Invoice": "external_weighing_invoice",
        "Wallet Transaction": "wallet_transaction",
        "Wallet History": "wallet_history",
        "Pre-Loan Repayment": "preloan_repayment",
        User: "user",
        "Rice Registration": "rice",
        "Plate Registration": "plate",
        "Payment Record": "customer_repayment_record",
      };

      return permissionMap[name] || null;
    };

    const filteredMenuItems = computed(() => {
      // Early return if permissions are null/undefined
      if (!permissions.value && userData.value[0]?.role !== "Super Admin") {
        return [];
      }

      return state.menuItems
        .map((item) => {
          if (userData.value[0]?.role === "Super Admin") {
            return item; // Super Admin sees all items
          }

          const newItem = JSON.parse(JSON.stringify(item));

          // Check module level permission
          const hasModuleAccess = item.permissions?.some(
            (permission) => permissions.value?.[permission]
          );

          if (!hasModuleAccess) {
            return null; // Hide item if no access
          }

          // For items with groups
          if (item.groups) {
            newItem.groups = item.groups
              .map((group) => ({
                groupName: group.groupName,
                children: group.children.filter((child) => {
                  const permKey = getPermissionKey(child.name);
                  return (
                    permKey &&
                    item.permissions.some(
                      (modulePermission) =>
                        permissions.value?.[modulePermission]?.[permKey]
                          ?.view === true
                    )
                  );
                }),
              }))
              .filter((group) => group.children.length > 0);

            return newItem.groups.length > 0 ? newItem : null;
          }

          // For items with direct children
          if (item.children) {
            newItem.children = item.children.filter((child) => {
              const permKey = getPermissionKey(child.name);
              return (
                permKey &&
                item.permissions.some(
                  (modulePermission) =>
                    permissions.value?.[modulePermission]?.[permKey]?.view ===
                    true
                )
              );
            });

            return newItem.children.length > 0 ? newItem : null;
          }

          // Handle items without children or dropdowns
          if (!item.children && !item.groups) {
            const permKey = getPermissionKey(item.name);
            if (
              permKey &&
              permissions.value?.[item.permissions[0]]?.[permKey]?.view
            ) {
              return newItem; // Return the item if it has access
            }
            return null; // Hide item if no access
          }

          return hasModuleAccess ? newItem : null; // Return item if it has access
        })
        .filter(Boolean); // Remove null values
    });

    // Toggle dropdown visibility for each menu item
    const isWrapSidebar = ref(false);

    // Function to toggle the sidebar
    const toggleWrapSidebar = () => {
      isWrapSidebar.value = !isWrapSidebar.value;
    };

    const toggleDropdown = (item) => {
      const isOpen = dropdownOpen.value.includes(item.id); // Check if the dropdown is already open
      if (isOpen) {
        dropdownOpen.value = dropdownOpen.value.filter((id) => id !== item.id);
      } else {
        dropdownOpen.value.push(item.id);
        isWrapSidebar.value = false;
      }
    };

    watch(isWrapSidebar, (newValue) => {
      if (newValue === true) {
        dropdownOpen.value = [];
      }
    });
    const closeDropdown = (item) => {
      dropdownOpen.value = dropdownOpen.value.filter((id) => id !== item.id); // Close the dropdown
    };
    const addTab = (name, path) => {
      if (route.path === path) return;

      const isTabOpen = state.openTabs.some((tab) => tab.path === path);

      if (!isTabOpen) {
        state.openTabs.push({ name, path });
        updateLocalStorageTabs(); // Update local storage after modifying openTabs
      }

      const existingTab = state.openTabs.find((tab) => tab.path === path);

      if (!existingTab) {
        state.openTabs.push({ name, path });
        localStorage.setItem("openTabs", JSON.stringify(state.openTabs));
      }

      router.push(path);
      isOpen.value = false;
      isSetting.value = false;
    };
    const updateLocalStorageTabs = () => {
      localStorage.setItem("openTabs", JSON.stringify(state.openTabs));
    };
    const isActiveRoute = (path) =>
      route.path === `/${path}` || route.path === path;

    const removeTab = (index) => {
      if (state.openTabs[index].name === "Dashboard") return;

      if (
        state.openTabs[index].path === route.path &&
        state.openTabs.length > 1
      ) {
        const newPath = state.openTabs[index > 0 ? index - 1 : index + 1].path;
        router.push(newPath);
      }
      state.openTabs.splice(index, 1);
      updateLocalStorageTabs();
    };

    // Function to check if a route is active
    const { t, locale } = useI18n();
    const fontClass = computed(() => {
      switch (locale.value) {
        case "km":
          return "font-NotoSerifKhmer";
        case "vi":
          return "font-BeVietnamPro";

        default:
          return "";
      }
    });
    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    const isOpen = ref(false);
    const handleReport = () => {
      isOpen.value = true;
    };
    const handleClose = () => {
      isOpen.value = false;
    };
    const isSetting = ref(false);
    const handleSetting = () => {
      isSetting.value = true;
    };
    const CloseSetting = () => {
      isSetting.value = false;
    };

    const userData = ref([]);
    const permissions = ref(null);
    const fetchUserByID = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodeJWTInfo = await decodeJwt(token);
        const tableName = ref("users");
        let url = `${apirURL}/weighing/api/getDataByID/${tableName.value}/${decodeJWTInfo.userId}`;
        const response = await axios.get(url);

        userData.value = response.data;
        permissions.value = response.data[0]?.permissions;
      } catch (err) {
        console.log("failed to fetch data", err);
      }
    };

    const handleDatabaseUpdate = async () => {
      await fetchUserByID();
      if (
        userData.value[0]?.role === "User" &&
        userData.value[0]?.status === false
      ) {
        handleLogout();
      }
      state.menuItems = [...state.menuItems];
    };

    const dropdownRef = ref(null);

    const handleClickOutside = (event) => {
      if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        isLanguageOpen.value = false;
      }
    };

    // Function to check user status
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) return;

        const decodeJWTInfo = await decodeJwt(token);
        const url = `${apirURL}/weighing/api/getDataByID/users/${decodeJWTInfo.userId}`;
        const response = await axios.get(url);

        userData.value = response.data;
        permissions.value = response.data[0]?.permissions;

        // Check if user is deactivated
        if (
          userData.value[0]?.role === "User" &&
          userData.value[0]?.status === false
        ) {
          handleLogout();
        }
      } catch (err) {
        console.log("Failed to fetch user data", err);
        handleLogout();
      }
    };

    // Logout handler
    const handleLogout = async () => {
      try {
        await axios.post(`${apirURL}/weighing/api/logout`);
        localStorage.removeItem("token");
        localStorage.removeItem("openTabs");
        router.push("/login");
      } catch (err) {
        console.log("Failed to logout", err);
        localStorage.removeItem("token");
        router.push("/login");
      }
    };

    onMounted(async () => {
      updateTime(); // Initial call to set the current time
      intervalId = setInterval(updateTime, 1000);
      const savedTabs = JSON.parse(localStorage.getItem("openTabs"));
      if (savedTabs && savedTabs.length) {
        state.openTabs = savedTabs;
      } else {
        state.openTabs = [{ name: "Dashboard", path: "/" }];
        localStorage.setItem("openTabs", JSON.stringify(state.openTabs));
      }

      document.addEventListener("click", handleClickOutside);
      await fetchUserByID();
      if (
        userData.value[0]?.role === "User" &&
        userData.value[0]?.status === false
      ) {
        handleLogout();
      }
      socket.on("database_realTime", handleDatabaseUpdate);

      state.openTabs =
        JSON.parse(localStorage.getItem("openTabs")) || state.openTabs;

      // Initial user status check
      await checkUserStatus();

      router.beforeEach(async (to, from, next) => {
        await checkUserStatus();
        next();
      });
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      socket.off("database_realTime");
      clearInterval(intervalId);
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);

      // Remove socket listener
      socket.off("database_realTime", handleDatabaseUpdate);

      // Remove navigation guard (if possible)
      // Note: This might require a more complex approach depending on your router setup
    });

    const isLanguageOpen = ref(false);
    const languages = [
      { code: "en", name: "English", flagUrl: enFlag },
      { code: "km", name: "ខ្មែរ", flagUrl: khFlag },
      { code: "vi", name: "Việt Nam", flagUrl: vnFlag },
    ];

    const currentLanguage = ref(languages[0]); // Default to English

    const selectLanguage = (lang) => {
      currentLanguage.value = lang;
      changeLanguage(lang.code);
      isLanguageOpen.value = false;
    };

    return {
      dropdownRef,
      filteredMenuItems,
      permissions,
      getImageUrl,
      ...toRefs(state),
      addTab,
      removeTab,
      isActiveRoute,
      fontClass,
      t,
      changeLanguage,
      currentDate,
      handleReport,
      handleClose,
      isOpen,
      isSetting,
      CloseSetting,
      handleSetting,
      formatDate,
      state,
      dialogVisible,
      userData,
      handleLogout,
      isLanguageOpen,
      languages,
      currentLanguage,
      selectLanguage,

      isPending,
      toggleDropdown,
      closeDropdown,
      dropdownOpen,
      toggleWrapSidebar,
      isWrapSidebar,
      mdiLabel,
      elementClasses,
    };
  },
};
</script>

<style scoped>
/* Hide scrollbar */
::-webkit-scrollbar {
  display: none;
}
</style>
