<template>
  <form
    @submit.prevent="handleSubmit"
    class="h-auto w-[400px] bg-default text-primary6"
  >
    <div class="form_title">
      <h2 class="ml-4">{{ isEdit ? "Update Warehouse" : "New Warehouse" }}</h2>
      <div @click="handleClose" class="ClosePopup">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="w-full p-5 space-y-4">
      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Name</label
        >
        <input
          class="w-[250px]"
          required
          v-model="name"
          type="text"
          placeholder="Enter Name"
        />
      </div>
      <div class="flex items-center justify-between gap-3">
        <label for="name" class="flex gap-1">
          <span class="text-red-500">*</span> Capacity</label
        >
        <div class="flex flex-col">
          <InputNumber
            class="w-full md:w-56 h-2 text-start flex items-center"
            suffix=" Kg"
            style="width: 250px"
            v-model="capacity"
            type="text"
            placeholder="Enter Capacity"
          />
          <div v-if="is_ValidateCapaciy" class="w-[250px] mt-5">
            <el-alert
              title="Please Enter the amount"
              type="error"
              :closable="false"
            />
          </div>
        </div>
      </div>
      <div class="flex items-center gap-3">
        <label for="name" class="flex gap-1 text-nowrap"> Status</label>

        <el-switch style="--el-switch-on-color: #4caf50" v-model="status" />
      </div>
      <!-- <div class="flex items-start justify-between gap-3">
        <label for="Description">Description</label>
        <textarea
          type="text"
          placeholder="Any Description"
          rows="4"
          class="!w-[250px] px-[12px] bg-none border rounded placeholder:text-primary5 outline-none"
        />
      </div> -->
      <div class="flex w-full items-center gap-3">
        <button :disabled="isPending">
          {{ isEdit ? "Update" : "Save" }}
          <span v-if="isPending" class="is_pending"></span>
        </button>
        <button
          :disabled="is_SavePending"
          type="button"
          @click="saveAndNew"
          class="btncancel"
        >
          {{ isEdit ? "Update& New" : "Save & New" }}
          <span
            v-if="is_SavePending"
            :class="{ is_pending_save: is_SavePending }"
          ></span>
        </button>
        <button class="btncancel" @click="handleClose">Cencel</button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import apirURL from "@/services/apiURL";
import axios from "axios";
import { fetchTimestamp } from "@/services/timestamp";
import { InputNumber } from "primevue";
import { handleValidate } from "@/composables/checkValidatefeild";
export default {
  props: ["datatoedit"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
    };
    const isPending = ref(false);
    const is_SavePending = ref(false);
    const name = ref("");
    const capacity = ref(null);
    const status = ref(true);
    const handleClear = () => {
      name.value = "";
      status.value = "";
      capacity.value = null;
    };
    const is_ValidateCapaciy = handleValidate(capacity.value, capacity);
    const handleSubmit = async () => {
      if (!capacity.value) {
        is_ValidateCapaciy.value = true;
        return;
      }
      try {
        isPending.value = true;
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "warehouse",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            capacity: capacity.value,
            status: status.value,
            created_at: timestamp,
          },
        };
        if (props.datatoedit) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
          handleClose();
          console.log("data updated:", response.data);
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          console.log("ensert:", response.data);
          emit("close");
          emit("toast", "create");
          handleClear();
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      if (props.datatoedit) {
        name.value = props.datatoedit.name;
        capacity.value = props.datatoedit.capacity;
        status.value = props.datatoedit.status;
      }
    });
    const isEdit = props.datatoedit;
    const saveAndNew = async () => {
      if (!capacity.value) {
        is_ValidateCapaciy.value = true;
        return;
      }
      try {
        setTimeout(() => {
          is_SavePending.value = true;
        }, 1000);
        const timestamp = await fetchTimestamp();
        const requestBody = {
          tableName: "warehouse",
          fields: {
            name: name.value,
            name_lowercase: name.value.toLowerCase(),
            capacity: capacity.value,
            status: status.value,
            created_at: timestamp,
          },
        };
        if (props.datatoedit) {
          const response = await axios.patch(
            `${apirURL}/weighing/api/updateData/${props.datatoedit.id}`,
            requestBody
          );
          emit("toast", "update");
          handleClear();
          console.log("data updated:", response.data);
        } else {
          const response = await axios.post(
            `${apirURL}/weighing/api/insertData`,
            requestBody
          );
          console.log("ensert:", response.data);
          emit("toast", "create");
          handleClear();
        }
      } catch (error) {
        console.log(error);
      }
    };
    return {
      handleClose,
      status,
      handleSubmit,
      name,
      capacity,
      isEdit,
      saveAndNew,
      is_ValidateCapaciy,
      isPending,
      is_SavePending,
    };
  },
};
</script>

<style></style>
