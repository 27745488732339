<template>
  <div class="bg-default h-auto relative">
    <div class="filter_type border rounded">
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Supplier</label
        >

        <Select
          class="w-full md:w-56 h-9 text-start flex items-center"
          v-model="selectSupplier"
          :options="suppliers"
          filter
          placeholder="Select Supplier"
          option-label="name"
          option-value="id"
          show-clear
          checkmark
          size="medium"
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier"
          ><span class="text-primary4 mr-2"></span>Date Range</label
        >
        <DatePicker
          v-model="selectDateRage"
          selectionMode="range"
          show-button-bar
          class="w-full md:w-56 h-1 text-start flex items-center"
          placeholder="Select Date "
        />
      </div>
      <div class="filter_type_wrap">
        <label for="Supplier" class="opacity-0"
          ><span class="text-primary4 mr-2 opacity-0"></span>Supplier</label
        >
        <el-button @click="handleApplyFilter" size="large">Filter</el-button>
      </div>
    </div>
    <div>
      <div>
        <h2 class="py-2">Truck Storage Transition</h2>
      </div>
      <div :class="tableClasses" class="h-[500px]">
        <table>
          <tbody>
            <tr class="header_table">
              <td class="min-w-[180px] border-b text-start">Created At</td>
              <td
                @click="sortData('supplier_name')"
                class="min-w-[180px] border-b text-start toggle_sort"
              >
                Supplier
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'supplier_name' &&
                        sortDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('plate_number.plate_number')"
                class="min-w-[180px] border-b text-start toggle_sort"
              >
                Truck
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'plate_number.plate_number' &&
                        sortDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td
                @click="sortData('weight_drop')"
                class="min-w-[180px] border-b text-start toggle_sort"
              >
                Total Weight
                <span class="ml-2 cursor-pointer">
                  <transition name="fade" mode="out-in">
                    <font-awesome-icon
                      :icon="
                        sortColumns === 'weight_drop' && sortDirection === 'asc'
                          ? ['fas', 'sort']
                          : ['fas', 'sort']
                      "
                    />
                  </transition>
                </span>
              </td>
              <td class="min-w-[200px] border-b">Status</td>
              <td class="border">Acion</td>
            </tr>
          </tbody>
          <tbody v-if="weighing.length > 0">
            <tr
              class="text-body font-normal text-primary2 bg-white"
              v-for="data in weighing"
              :key="data"
            >
              <td class="border-b">{{ formatDate2(data.created_at) }}</td>
              <td class="border-b">
                {{ data.supplier_name }}
              </td>
              <td class="border-b">{{ data.plate_number.plate_number }}</td>
              <td class="border-b">
                {{ numeralFormat(data.weight_drop, "0,0[.]00") }} Kg
              </td>
              <td class="border-b">
                <el-button type="success" round>{{
                  data.is_hold ? "Completed" : "Uncompleted"
                }}</el-button>
              </td>
              <td class="border">
                <div class="flex items-center gap-3">
                  <svg-icon
                    type="mdi"
                    :path="mdiEyeOutline"
                    class="info"
                    @click="handleAdd(data)"
                  >
                  </svg-icon>

                  <svg-icon
                    @click.stop="openDeleteConfirmation(data)"
                    type="mdi"
                    :path="mdiArrowULeftTop"
                    class="delete"
                  ></svg-icon>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <NoDataComponet />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="weighing.length > 0" class="w-full mt-4">
        <div class="">
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            :pageSize="pageSize"
            :totalRecord="totalRecords"
            @page-change="handlePageChange"
            @page-size-change="handlePageSizeChange"
            isLastRecordOnPage="isLastRecordOnPage"
          />
        </div>
      </div>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleClose" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-200 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="bg-default">
                <div>
                  <component
                    @close="handleClose"
                    :is="currentComponent"
                    :datatoedit="datatoedit"
                  ></component>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <DeleteConfrimation
      :isOpen="isDeleteOpen"
      :tableName="table"
      :deleteDoc="deleteDoc"
      @close="closeDeleteDialog"
      @confirm="handleConfirmDelete"
      @toast="showSuccessToast"
    />
  </div>
  <Toast ref="toast" />
</template>

<script>
import FromTruckTransitionDetailsVue from "@/form/FromTruckTransitionDetails.vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import Toast from "@/components/Toast.vue";
import { ref, computed, onMounted, onUnmounted } from "vue";
import {
  getResponsiveTableNav,
  getResponsiveSelect,
  getResponsiveTable,
  getResponsiveSubTable,
  getResponsiveMainTable,
} from "@/composables/useResponsive";
import apirURL from "@/services/apiURL";
import axios from "axios";
import moment from "moment-timezone";
import { formatDate2 } from "@/composables/formatDateTime";
import Pagination from "@/components/Pagination.vue";
import { mdiArrowULeftTop, mdiEyeOutline } from "@mdi/js";
import NoDataComponet from "@/components/NoDataComponet.vue";
import toggleSort from "@/composables/sortData";
import DeleteConfrimation from "@/form/DeleteConfrimation.vue";
import socket from "@/services/socket";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    FromTruckTransitionDetailsVue,
    Pagination,
    NoDataComponet,
    DeleteConfrimation,
    Toast,
  },

  setup() {
    const currentComponent = ref("");
    const isOpen = ref(false);
    const datatoedit = ref(null);
    const handleAdd = (data) => {
      isOpen.value = true;
      datatoedit.value = data;
      currentComponent.value = "FromTruckTransitionDetailsVue";
    };
    const handleClose = () => {
      currentComponent.value = "";
      isOpen.value = false;
    };

    // responsive
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      console.log("Window width:", windowWidth.value);
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      handleResize(); // Initialize on mount
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const tableClasses = computed(() => {
      const classes = getResponsiveTable(windowWidth.value);

      return classes;
    });

    const selectClasses = computed(() => {
      const classes = getResponsiveSelect(windowWidth.value);

      return classes;
    });

    const navClasses = computed(() => {
      const classes = getResponsiveTableNav(windowWidth.value);

      return classes;
    });
    const mainClassesTable = computed(() => {
      const classes = getResponsiveMainTable(windowWidth.value);

      return classes;
    });
    const subClassesTable = computed(() => {
      const classes = getResponsiveSubTable(windowWidth.value);

      return classes;
    });

    // Define your ref variables
    const fromDateFormatted = ref(null);
    const toDateFormatted = ref(null);

    const currentDate = new Date();
    const isLastRecordOnPage = ref(null);
    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalPages = ref(0);
    const sortColumn = ref("id");
    const sortDirection = ref("ASC");
    const filterColumn = ref("role");
    const filterValue = ref("");
    const table = ref("weighing");
    const searchValue = ref("");

    const weighing = ref([]);
    const selectSupplier = ref(null);
    const selectDateRage = ref([
      moment(currentDate).tz("Asia/Phnom_Penh").startOf("day").toDate(),
      moment(currentDate).tz("Asia/Phnom_Penh").endOf("day").toDate(),
    ]);
    const formatDateWithTimezone = (date) => {
      return moment(date).tz("Asia/Phnom_Penh").format("YYYY-MM-DD HH:mm:ssZ");
    };

    // Set the start and end of the current day
    fromDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).startOf("day")
    );
    toDateFormatted.value = formatDateWithTimezone(
      moment(currentDate).endOf("day")
    );
    const totalRecords = ref(0);
    const fetchWeighing = async () => {
      const fromDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[0])
        : null;
      const toDate = selectDateRage.value
        ? formatDateWithTimezone(selectDateRage.value[1])
        : null;
      try {
        let apiUrl = `${apirURL}/weighing/api/getPagination`;
        const params = {
          tableName: table.value,
          search: searchValue.value,
          page: currentPage.value,
          pageSize: pageSize.value,
          columnSearch: "supplier_name",
          sortColumn: sortColumn.value,
          sortDirection: sortDirection.value,
          filterColumn: filterColumn.value,
          filterValue: filterValue.value,
          columnAliases: JSON.stringify({
            weight_id: { table: table.value, column: "id" },
            created_by: { table: table.value, column: "created_by" },
            status_weighing: { table: table.value, column: "status" },
            updated_at: { table: table.value, column: "updated_at" },
            created_at: { table: table.value, column: "created_at" },
            weight_in: { table: table.value, column: "weight_in" },
            is_hold: { table: table.value, column: "is_hold" },
            supplier_name: { table: "suppliers", column: "name" },
            phone_number: { table: "suppliers", column: "phone_number" },
            boat_enter_parking_id: {
              table: table.value,
              column: "boat_enter_parking_id",
            },
          }),
          additionalJoins: JSON.stringify([
            {
              joinTableName: "suppliers",
              joinColumnChild: "supplier_id",
              joinColumnParent: "id",
              joinType: "LEFT JOIN",
            },
          ]),
          dynamicConditions: JSON.stringify([
            ...(fromDate && toDate
              ? [
                  {
                    field: "created_at",
                    operator: ">=",
                    value: fromDate,
                    typeTable: "table",
                  },
                  {
                    field: "created_at",
                    operator: "<=",
                    value: toDate,
                    typeTable: "table",
                  },
                ]
              : []),
            // {
            //   field: "created_at",
            //   operator: ">=",
            //   value: fromDateFormatted.value,
            //   typeTable: "table",
            // },
            // {
            //   field: "created_at",
            //   operator: "<=",
            //   value: toDateFormatted.value,
            //   typeTable: "table",
            //   joinTableName: table.value,
            // },
            {
              field: "warehouse_id",
              operator: "IS NULL",
              typeTable: "table",
            },
            {
              field: "service_type",
              operator: "=",
              value: "internal",
              typeTable: "table",
            },
            {
              field: "is_hold",
              operator: "=",
              value: true,
              typeTable: "table",
            },
            {
              field: "is_customer_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },
            {
              field: "is_supplier_invoice",
              operator: "=",
              value: false,
              typeTable: "table",
            },

            ...(selectSupplier.value
              ? [
                  {
                    field: "supplier_id",
                    operator: "=",
                    value: selectSupplier.value,
                    typeTable: "table",
                  },
                ]
              : []),
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        weighing.value = response.data.data;
        totalRecords.value = weighing.value.length;
        console.log("weighing", weighing.value);

        totalPages.value = response.data.pagination.totalPages;
      } catch (err) {
        console.error("Failed to fetch data", err);
      }
    };

    // Function to apply filters
    const handleApplyFilter = () => {
      fromDateFormatted.value = formatDateWithTimezone(
        moment(selectDateRage.value[0]).startOf("day")
      );
      toDateFormatted.value = formatDateWithTimezone(
        moment(selectDateRage.value[1]).endOf("day")
      );
      fetchWeighing();
    };

    const suppliers = ref([]);
    const fetchSupplier = async () => {
      try {
        let apiUrl = `${apirURL}/weighing/api/getAllData`;
        const params = {
          tableName: "suppliers",
          sortColumn: "id",
          dynamicConditions: JSON.stringify([
            // {
            //   field: "supplier_id",
            //   operator: "=",
            //   value: id,
            // },
          ]),
        };

        const response = await axios.get(apiUrl, { params });
        suppliers.value = response.data;
      } catch (error) {
        console.error("Error fetching supplier data:", error);
        return null;
      }
    };
    const sortDirections = ref("asc");
    const sortColumns = ref(null);
    const sortPlate = (direction, data, field) => {
      return data.slice().sort((a, b) => {
        const fieldPath = field.split("."); // Split field path by "."

        // Function to get nested value
        const getValue = (obj, path) =>
          path.reduce((acc, part) => acc && acc[part], obj);

        const aValue = getValue(a, fieldPath);
        const bValue = getValue(b, fieldPath);

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      });
    };
    const sortData = (field) => {
      const direction =
        sortColumns.value === field && sortDirections.value === "asc"
          ? "desc"
          : "asc";
      sortDirections.value = direction;
      sortColumns.value = field;
      weighing.value = toggleSort(direction, weighing.value, field);
      weighing.value = sortPlate(direction, weighing.value, field);
    };

    // delete
    const itemIdToDelete = ref(null);
    const deleteDoc = ref(null);
    const isDeleteOpen = ref(false);
    const openDeleteConfirmation = (doc) => {
      deleteDoc.value = doc;
      itemIdToDelete.value = doc;
      isDeleteOpen.value = true;
    };

    const closeDeleteDialog = () => {
      isDeleteOpen.value = false;
      itemIdToDelete.value = null;
      deleteDoc.value = null;
    };

    const handleConfirmDelete = async (doc) => {
      try {
        const isLastPage = currentPage.value === totalPages.value;
        await axios.delete(
          `${apirURL}/weighing/api/deleteData/${doc.weight_id}`,
          {
            data: { tableName: table.value },
          }
        );

        if (isLastPage && weighing.value.length === 0) {
          currentPage.value = 1;
        }
        closeDeleteDialog();
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    };
    const toast = ref(null);
    const showSuccessToast = (action) => {
      if (toast.value) {
        let message = "";
        let type = "";

        switch (action) {
          case "create":
            message = "Add Success";
            type = "infor";
            break;
          case "update":
            message = "Update Success";
            type = "success";
            break;
          case "delete":
            message = "Delete Success";
            type = "error";
            break;
          case "cannotdelete":
            message = "Can not delete";
            type = "error";
            break;
          case "select":
            message = "Please Select the feild";
            type = "error";
            break;
          case "send_to_parking":
            message = "Please send to parking first";
            type = "error";
            break;
          default:
            message = "Success";
            type = "infor";
        }
        toast.value.showToast(message, type);
      }
    };
    const handleDatabaseUpdate = async () => {
      await fetchWeighing();
      await fetchSupplier();
    };
    onMounted(async () => {
      await fetchWeighing();
      await fetchSupplier();
      socket.on("database_realTime", handleDatabaseUpdate);
    });
    // paginatetion
    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchWeighing();
    };

    const handlePageSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchWeighing();
    };

    return {
      handleAdd,
      isOpen,
      currentComponent,
      handleClose,
      selectClasses,
      navClasses,
      tableClasses,
      weighing,
      formatDate2,
      currentPage,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      totalPages,
      isLastRecordOnPage,
      mdiArrowULeftTop,
      mdiEyeOutline,
      totalRecords,
      selectSupplier,
      selectDateRage,
      suppliers,
      handleApplyFilter,
      sortData,
      sortDirection,
      sortColumns,
      datatoedit,
      openDeleteConfirmation,
      closeDeleteDialog,
      handleConfirmDelete,
      itemIdToDelete,
      deleteDoc,
      isDeleteOpen,
      showSuccessToast,
      toast,
    };
  },
};
</script>

<style></style>
